import { ColumnSort } from '@tanstack/react-table'

export const getSortString = (sort: ColumnSort | undefined, useDefault = true) => {
    // console.log({ sort })
    if (!sort) {
        if (useDefault) {
            return 'createdAt:desc'
        }
        return ''
    }

    return `${sort.id}:${sort.desc ? 'desc' : 'asc'}`
}
