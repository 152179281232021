import { DeleteIcon } from '@/assets/assets.export'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { MilestoneType } from '@/types/payroll'
import { formatAmount, formatAmountWithSymbolOfChoice, formatAmountWithoutSymbols } from '@/utils/money'
import { format, isDate } from 'date-fns'
import React, { useRef } from 'react'
import { ReactDatePicker } from 'react-datepicker'
import { TbCurrencyNaira } from 'react-icons/tb'

type Props = {
    fieldDetail: MilestoneType
    setFieldDetails: React.Dispatch<React.SetStateAction<MilestoneType[]>>
    total_pay: string
    mileStones: MilestoneType[]
    date_details: {
        start_date: string
        end_date: string
    }
}
export default function MilestoneExtrafield({
    fieldDetail,
    setFieldDetails,
    total_pay,
    mileStones,
    date_details,
}: Props) {
    const isDisabled = fieldDetail?.payment_status === 'paid' || fieldDetail?.progress_status === 'completed'
    // console.log({ fieldDetail, mileStones })

    const ref = useRef<ReactDatePicker<never, undefined>>(null)

    return (
        <section className={`py-2 px-4 rounded-lg `}>
            <div className={`flex gap-2 lg:gap-5 items-end `}>
                <div className={`flex flex-col flex-1 gap-3 max-w-[8rem] md:max-w-full`}>
                    <div
                        className={` flex items-center   w-full h-[3rem] rounded-lg overflow-hidden border  ${
                            isDisabled
                                ? 'bg-[#f1f0f0] cursor-not-allowed border-[#d5d5d6]'
                                : 'border border-[#DADCE0] bg-white'
                        }`}
                    >
                        <input
                            type='text'
                            placeholder={`Enter milestone title`}
                            className='outline-none w-full lg:w-[90%] h-full ml-1 placeholder:text-sm px-2 lg:px-4 disabled:bg-transparent'
                            value={fieldDetail?.title}
                            disabled={
                                fieldDetail?.payment_status === 'paid' || fieldDetail?.progress_status === 'completed'
                            }
                            onChange={e => {
                                setFieldDetails(field => {
                                    return field.map(data => {
                                        if (
                                            (data?._id ?? data?.id ?? data?.uniqueId) ===
                                            (fieldDetail?._id ?? fieldDetail?.id ?? fieldDetail?.uniqueId)
                                        ) {
                                            data.title = e.target.value
                                            return data
                                        }
                                        return data
                                    })
                                })
                            }}
                            required
                        />
                    </div>
                </div>
                <div
                    className={`flex flex-col flex-1 gap-3 text-sm w-[10rem] ${
                        isDisabled ? 'bg-[#f1f0f0] cursor-not-allowed border-[#d5d5d6]' : 'rounded-lg bg-white'
                    }`}
                >
                    <div
                        className={`${
                            isDisabled ? 'bg-[#f1f0f0] cursor-not-allowed border-[#d5d5d6]' : 'border border-[#DADCE0] '
                        } flex w-full h-[3rem] rounded-lg overflow-hidden`}
                    >
                        <span className='flex items-center justify-center text-[#838383] ml-2'>
                            <TbCurrencyNaira fontSize={20} />
                        </span>
                        <input
                            type='text'
                            placeholder={`Enter Amount`}
                            className='outline-none w-[90%] h-full ml-1 pl-2 placeholder:text-sm '
                            required
                            disabled={
                                fieldDetail?.payment_status === 'paid' || fieldDetail?.progress_status === 'completed'
                            }
                            min={0}
                            value={formatAmount(Number(fieldDetail.amount.replaceAll('.', ''))).replaceAll('₦', '')} //converts to kobo since we formatAMount converts to naira
                            onChange={e => {
                                const value_entered = formatAmountWithoutSymbols(e.target.value)

                                //checks against total milestones being more than the total_pay
                                //total pay and value entered are in kobo because we removed the dot(.)
                                const checkedAmount = mileStones.reduce(
                                    (acc, val) => {
                                        if (
                                            (val?._id ?? val?.id ?? val?.uniqueId) ===
                                            (fieldDetail?._id ?? fieldDetail?.id ?? fieldDetail?.uniqueId)
                                        ) {
                                            return Number(acc) - Number(value_entered) / 100
                                        }
                                        return Number(acc) - Number(val?.amount)
                                    },
                                    Number(total_pay) / 100
                                )

                                //-================================================================
                                if (Number(checkedAmount) < 0) return

                                // console.log(
                                //     formatAmountWithSymbolOfChoice(formatAmount(value_entered), ['₦', ',', '.'])
                                // )
                                setFieldDetails(field => {
                                    return field.map(data => {
                                        if (
                                            (data?._id ?? data?.id ?? data?.uniqueId) ===
                                            (fieldDetail?._id ?? fieldDetail?.id ?? fieldDetail?.uniqueId)
                                        ) {
                                            data.amount = formatAmountWithSymbolOfChoice(formatAmount(value_entered), [
                                                '₦',
                                                ',',
                                            ])
                                            // data.amount = value_entered

                                            //converts to naira since we removed the dot(.) earlier

                                            return data
                                        }
                                        return data
                                    })
                                })
                            }}
                        />

                        <div className='w-[13.5rem] text-xs inline-flex h-full items-center '>
                            <div
                                className={`w-full bg-[#F9F9F9] rounded-md   flex justify-center items-center gap-1  cursor-pointer ${
                                    fieldDetail?.due_date ? 'w-[60%] p-2 mr-0 lg:mr-2' : 'w-[1rem] p-0 mr-0 lg:mr-4'
                                }`}
                            >
                                <CustomDatePicker
                                    placeholderText='Set due date'
                                    ref={ref}
                                    disabled={
                                        fieldDetail?.payment_status === 'paid' ||
                                        fieldDetail?.progress_status === 'completed' ||
                                        fieldDetail?.progress_status === 'in progress'
                                    }
                                    className={`placeholder:text-[0.65rem]  placeholder:text-gray7 bg-[#F9F9F9] w-full cursor-pointer ${
                                        fieldDetail?.due_date.length > 0 ? '' : ''
                                    }`}
                                    wrapperClassName='border-none ocus:border-none'
                                    minDate={new Date(date_details?.start_date)}
                                    maxDate={new Date(date_details?.end_date)}
                                    value={
                                        fieldDetail?.due_date && isDate(new Date(fieldDetail?.due_date))
                                            ? format(new Date(fieldDetail?.due_date), 'yyyy-MM-dd')
                                            : undefined
                                    }
                                    onChange={e => {
                                        setFieldDetails(field => {
                                            return field.map(data => {
                                                if (
                                                    (data?._id ?? data?.id ?? data?.uniqueId) ===
                                                    (fieldDetail?._id ?? fieldDetail?.id ?? fieldDetail?.uniqueId)
                                                ) {
                                                    // data.due_date = e.target.value
                                                    data.due_date = e as unknown as string
                                                    return data
                                                }
                                                return data
                                            })
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <DeleteIcon
                        className='bg-[#FFEDEC] rounded-md scale-125 relative bottom-2 cursor-pointer p-1'
                        onClick={() => {
                            if (fieldDetail?.payment_status === 'paid' || fieldDetail?.progress_status === 'completed')
                                return
                            setFieldDetails(field => {
                                return field.filter(
                                    data =>
                                        (data?._id ?? data?.id ?? data?.uniqueId) !==
                                        (fieldDetail?._id ?? fieldDetail?.id ?? fieldDetail?.uniqueId)
                                )
                            })
                        }}
                    />
                </div>
            </div>
        </section>
    )
}
