import { Button } from '@/components'
import ProgressBar from '@/components/ProgressBar'
import PageContent from '@/layout/PageContent'
import { X } from 'lucide-react'
import { FaArrowLeft } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import notFound from '@/assets/not-found.svg'
import { useAuthContext } from '@/context'
import { useGetEligibleEmployees } from '@/services/payroll/queries'
import { useMemo, useState } from 'react'
import GeneratePayrollTable from '../../components/GeneratePayrollTable'
import { prodEnvironment } from '@/utils/utils'
import { PaymentProfile } from '@/types/payroll'
import { capitalizeText } from '@/utils/string'
import { AnimatePresence, motion } from 'framer-motion'
import { calculateBonusAndDeductionSum } from '../../utils'
import { formatAmount } from '@/utils/money'
import { useGeneratePayroll } from '@/services/payroll/mutations'
import { toastHandler } from '@/components/utils/Toast'

const GeneratePayroll = () => {
    const navigate = useNavigate()
    const { selectedAccount } = useAuthContext()

    const [searchQuery, setSearchQuery] = useState('')
    const [selectedPayrollDetails, setSelectedPayrollDetails] = useState<PaymentProfile[]>([])

    const { data, isRefetching, isLoading } = useGetEligibleEmployees({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    const { mutate: manualGeneratePayroll, isLoading: isGeneratingPayroll } = useGeneratePayroll({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Successfully generated', state: 'success' })
            navigate(-1)
        },
    })

    const togglePayrollDetail = (payroll: PaymentProfile) => {
        const rowId = payroll._id ?? payroll.id
        const isSelected = selectedPayrollDetails.some(profile => (profile._id ?? profile.id) === rowId)

        if (isSelected) {
            setSelectedPayrollDetails(selectedPayrollDetails.filter(profile => (profile._id ?? profile.id) !== rowId))
        } else {
            setSelectedPayrollDetails([...selectedPayrollDetails, payroll])
        }
    }
    const manualPayrollData = useMemo(() => data ?? [], [data])

    const handleSearch = () => {
        return manualPayrollData?.filter(payroll => {
            const first_name = payroll?.employee?.user?.first_name.toLowerCase()
            const last_name = payroll?.employee?.user?.last_name.toLowerCase()

            return (
                `${first_name} ${last_name}`.includes(searchQuery.toLowerCase()) ||
                `${last_name} ${first_name}`.includes(searchQuery.toLowerCase())
            )
        })
    }

    const totalPayout = selectedPayrollDetails.reduce(
        (acc, cur) =>
            acc +
            (Number(cur?.gross_salary + calculateBonusAndDeductionSum(cur?.bonuses, 'amount', cur.gross_salary)) -
                calculateBonusAndDeductionSum(cur?.deductions, 'amount', cur.gross_salary)),
        0
    )

    if (isLoading) return <ProgressBar />
    if (prodEnvironment()) navigate(-1)
    return (
        <PageContent>
            <div className='mb-4 mt-6'>
                <button
                    onClick={() => navigate(-1)}
                    className='flex items-center text-sm gap-1.5 text-gray7'
                    aria-label='button'
                >
                    <FaArrowLeft size={20} className='stroke-1' />
                    back
                </button>
            </div>
            <div className='bg-white rounded-xl p-6'>
                <div className='flex justify-between border-b border-gray6 pb-4'>
                    <div>
                        <h2 className='font-medium text-lg'>Generate payroll manually</h2>
                    </div>
                    <div className=''>
                        <ul className='flex gap-6'>
                            <li>
                                <h3 className='font-medium mb-1'>Employees</h3>
                                <p className='text-gray7'>{selectedPayrollDetails?.length}</p>
                            </li>
                            <li>
                                <h3 className='font-medium mb-1'>Total salary</h3>
                                <p className='text-gray7'>{formatAmount(totalPayout)}</p>
                            </li>
                            <li>
                                <h3 className='font-medium mb-1'>Total payout</h3>
                                <p className='text-gray7'>{formatAmount(totalPayout)}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='py-4'>
                    <AnimatePresence>
                        <motion.div layout className='flex gap-4 '>
                            {selectedPayrollDetails?.length > 0 &&
                                selectedPayrollDetails.map(profile => (
                                    <motion.button
                                        layoutId={(profile?._id ?? profile?.id) as string}
                                        key={(profile?._id ?? profile?.id ?? '') as string}
                                        type='button'
                                        className='cursor-default justify-between flex gap-3 items-center bg-gray4/30 rounded-md p-2 px-2.5 whitespace-nowrap '
                                    >
                                        <span className='flex flex-col text-sm font-medium'>
                                            {capitalizeText(profile?.employee?.user?.first_name)} {'   '}
                                            {capitalizeText(profile?.employee?.user?.last_name)}
                                        </span>

                                        <X
                                            onClick={() => {
                                                togglePayrollDetail(profile)
                                            }}
                                            className='stroke-errorRed w-4 h-4 cursor-pointer'
                                        />
                                    </motion.button>
                                ))}
                        </motion.div>
                    </AnimatePresence>

                    {selectedPayrollDetails?.length < 1 && (
                        <div className='text-center border p-4 border-gray6 rounded text-sm font-medium text-gray7 mt-16'>
                            No employees added to payout list yet. Click the + button to add employees.
                        </div>
                    )}
                </div>
                <div className='mt-8'>
                    <Button
                        loading={isGeneratingPayroll}
                        onClick={() =>
                            manualGeneratePayroll({
                                profiles: selectedPayrollDetails.map(profile => profile._id ?? profile?.id),
                            })
                        }
                        disabled={selectedPayrollDetails?.length < 1}
                        className='min-w-[120px] ml-auto'
                    >
                        Generate payroll
                    </Button>
                </div>
            </div>
            <section className='bg-white p-6 rounded-2xl mt-5'>
                <div className='flex justify-between items-center'>
                    <div className='border border-[#DADCE0] h-[40px] w-[60%] lg:w-[30%] rounded flex items-center overflow-hidden px-3 '>
                        <input
                            type='text'
                            placeholder='Search user by name'
                            className='h-full w-[90%] outline-none text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>

                <div className='mt-6 overflow-y-scroll'>
                    {handleSearch()?.length === 0 && isRefetching ? (
                        <ProgressBar />
                    ) : (handleSearch()?.length === 0 && searchQuery?.length < 1) ||
                      (handleSearch()?.length > 0 && searchQuery?.length >= 0) ? (
                        <section
                            className={` transition-all rounded-lg ${isRefetching ? 'opacity-30' : 'opacity-100'} `}
                        >
                            <GeneratePayrollTable
                                togglePayrollDetail={togglePayrollDetail}
                                selectedPayrollDetails={selectedPayrollDetails}
                                setSelectedPayrollDetails={setSelectedPayrollDetails}
                                payrolls={handleSearch()}
                            />
                        </section>
                    ) : (
                        <div className='h-[300px] flex flex-col text-center justify-center items-center'>
                            {searchQuery && handleSearch()?.length === 0 ? (
                                <>
                                    <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                    <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                    <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                        "Oops! It seems we couldn't find any matching results for your search. Please
                                        try again with different keywords or refine your filter criteria for better
                                        results.
                                    </span>
                                </>
                            ) : (
                                <ProgressBar />
                            )}
                        </div>
                    )}
                </div>
            </section>
        </PageContent>
    )
}

export default GeneratePayroll
