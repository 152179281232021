import { Button } from '@/components'
import ProgressBar from '@/components/ProgressBar'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthorizeCompanyKYB, useCreateExternalCustomer } from '@/services/auth/mutations'
import { useGetCompanyKYB } from '@/services/auth/queries'
import { OrgType } from '@/types/org'
import { format } from 'date-fns'
import { DownloadCloud } from 'lucide-react'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import OfficersTable from '../components/OfficersTable'

type Props = {
    org: OrgType
}
enum AuthorizeKYB {
    APPROVE = 'approved',
    REJECT = 'rejected',
}

const BusinessDirectoryCompliance = ({ org }: Props) => {
    const [authorize, setAuthorize] = useState<AuthorizeKYB.APPROVE | AuthorizeKYB.REJECT | null>()

    const { user_id: org_id } = useParams()

    const { data: company_kyb, isLoading: isLoadingKYB } = useGetCompanyKYB({
        queryParams: {
            org_id: org_id as string,
        },
    })
    const { mutate: authorizeKYBFn, isLoading } = useAuthorizeCompanyKYB({
        onSuccess() {
            toastHandler({
                message:
                    authorize === AuthorizeKYB.REJECT
                        ? company_kyb?.org.kyb_completed
                            ? 'KYB revoked successfully'
                            : 'KYB declined successfully'
                        : 'KYB approved successfully',
                state: 'success',
            })
        },
    })

    const { mutate: createFn, isLoading: creatingCustomer } = useCreateExternalCustomer({
        onSuccess: () => {
            toastHandler({ message: 'External Customer created', state: 'success' })
        },
    })

    const business_info = [
        { name: 'Legal business name', desc: company_kyb?.legal_business_name },
        {
            name: 'Industry',
            desc: company_kyb?.industry,
        },
        {
            name: 'Sub Industry',
            desc: company_kyb?.sub_industry,
        },

        {
            name: 'Number of employees',
            desc: company_kyb?.number_of_employees,
        },
        { name: 'Website url', desc: company_kyb?.website },
        {
            name: 'Company description',
            desc: company_kyb?.description,
        },
    ]
    const contact_info = [
        {
            name: 'Country',
            desc: company_kyb?.country,
        },
        {
            name: 'State',
            desc: company_kyb?.state,
        },

        { name: 'City', desc: company_kyb?.city },
        {
            name: 'Business phone number',
            desc: company_kyb?.business_phone,
        },
        {
            name: 'Business email address',
            desc: company_kyb?.business_email,
        },
        { name: 'Postal code', desc: company_kyb?.postal_code },
        {
            name: 'Street address',
            desc: `${company_kyb?.address?.main?.addressLine_1}`,
        },
    ]
    const incorporation_info = [
        { name: 'Business type', desc: company_kyb?.business_type },
        { name: 'RC Number', desc: company_kyb?.rc_number },
        { name: 'BN Number', desc: company_kyb?.bn_number },
        { name: 'CAC IT Number', desc: company_kyb?.cac_it_number },
        {
            name: 'Company registration date',
            desc: company_kyb?.registration_date && format(new Date(company_kyb?.registration_date), 'dd/MM/yyyy'),
        },
        { name: 'Tax ID number', desc: company_kyb?.tax_identification_number },
        { name: 'Officers', desc: company_kyb?.officers },
        { name: 'CAC-BN1', desc: company_kyb?.cac_bn1 },
        { name: 'CAC IT form 1', desc: company_kyb?.cac_it_form },
        { name: ' SCUML Certificate', desc: company_kyb?.scuml_certificate },
        { name: 'Proof of address', desc: company_kyb?.proof_of_address },
        { name: 'Certification of incorporation', desc: company_kyb?.certificate_of_incorporation },
        { name: 'Certification of Business name', desc: company_kyb?.certificate_of_business_name },
        { name: 'Memorandum and articles of association', desc: company_kyb?.memorandum_of_association },
    ]

    const orgKYBDetails = [
        {
            type: 'Business information',
            children: business_info,
        },
        {
            type: 'Contact details',
            children: contact_info,
        },
        {
            type: 'Incorporation details',
            children: incorporation_info.slice(0, 6),
        },
    ]

    const handleApproval = (result: AuthorizeKYB.APPROVE | AuthorizeKYB.REJECT) => {
        authorizeKYBFn({ result, org_id: org?._id ?? org?.id })
    }

    if (isLoadingKYB) return <ProgressBar />
    return (
        <div className='mb-24'>
            <section className='bg-white rounded-xl px-5 p-3 shadow-sm mt-5'>
                {orgKYBDetails?.map(kyb => (
                    <div className=' mb-12'>
                        <h1 className='text-base font-semibold py-3 border-b border-gray5'>{kyb?.type}</h1>
                        <div className='flex flex-wrap max-w-xl gap-4 lg:gap-y-6 lg:gap-x-16'>
                            {kyb?.children.map(data => {
                                if (
                                    data.desc === 'null' ||
                                    (typeof data.desc === 'string' && data?.desc?.length === 0) ||
                                    // data.desc === undefined ||
                                    data?.desc === 'undefined'
                                )
                                    return

                                return (
                                    <div className='flex flex-col flex-wrap mt-5'>
                                        <span className=' font-normal text-sm text-[#838383]'>{data?.name}</span>
                                        <div className='flex gap-3 items-center mt-2 truncate'>
                                            <span className=' font-medium text-base truncate max-w-[12rem]'>
                                                {data?.desc === 'undefined' ? undefined : data?.desc}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))}
                {!!company_kyb?.officers && (
                    <div className=' mb-12'>
                        <h1 className='text-base font-semibold py-3 border-b border-gray5'>Officers</h1>
                        <div className='mt-3'>
                            <OfficersTable data={company_kyb?.officers as any[]} />
                        </div>
                    </div>
                )}
                {incorporation_info.slice(6).map(incorporation_documents => {
                    if (!incorporation_documents.desc || incorporation_documents.name === 'Officers') return

                    return (
                        <div className='flex justify-between items-center bg-[#F9F9F9] rounded-lg mb-4 p-3'>
                            <div className='flex flex-col flex-wrap my-1'>
                                <span className=' font-normal text-sm text-[#838383]'>
                                    {incorporation_documents.name}
                                </span>
                                <div className='flex gap-3 items-center mt-2 truncate'>
                                    <span className=' font-medium text-base truncate max-w-[18rem] lg:max-w-xs'>
                                        {incorporation_documents.desc}
                                    </span>
                                </div>
                            </div>
                            <Link
                                to={incorporation_documents?.desc as string}
                                target='_blank'
                                className='text-gray7 flex items-center gap-2 bg-white  rounded-md p-2 px-3 text-xs font-semibold'
                                style={{
                                    pointerEvents:
                                        (incorporation_documents?.desc as string)?.length > 0 ? 'auto' : 'none',
                                    opacity: (incorporation_documents?.desc as string)?.length > 0 ? '100%' : '50%',
                                }}
                            >
                                <DownloadCloud className='w-3 h-3' /> <p className='hidden lg:block'>Download</p>
                            </Link>
                        </div>
                    )
                })}
            </section>
            <section className='flex justify-end gap-4 items-center mt-10 pb-5'>
                <Button
                    className='lg:mr-4  min-w-[130px] min-h-[35px] '
                    variant={'secondaryOutline'}
                    disabled={
                        (authorize === AuthorizeKYB.APPROVE && isLoading) ||
                        company_kyb?.verification_status === 'REJECTED'
                    }
                    loading={authorize === AuthorizeKYB.REJECT && isLoading}
                    onClick={() => {
                        setAuthorize(AuthorizeKYB.REJECT)
                        handleApproval(AuthorizeKYB.REJECT)
                    }}
                >
                    {company_kyb?.org.kyb_completed ? 'Revoke' : 'Decline'}
                </Button>

                <Button
                    className='bg-[#19C37D]  min-w-[130px] min-h-[35px]'
                    disabled={
                        (authorize === AuthorizeKYB.REJECT && isLoading) ||
                        company_kyb?.org.kyb_completed ||
                        company_kyb?.verification_status === 'REJECTED'
                    }
                    loading={authorize === AuthorizeKYB.APPROVE && isLoading}
                    onClick={() => {
                        setAuthorize(AuthorizeKYB.APPROVE)
                        handleApproval(AuthorizeKYB.APPROVE)
                    }}
                >
                    Approve
                </Button>
                {!org?.anchor_customer_id && (
                    <Button
                        className='ml-auto'
                        loading={creatingCustomer}
                        onClick={() => {
                            createFn({
                                org_id: org?._id ?? org?.id,
                            })
                        }}
                    >
                        Create External Customer
                    </Button>
                )}
            </section>
        </div>
    )
}

export default BusinessDirectoryCompliance
