import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { useAuthContext } from '@/context'
import { useGeneratePaymentOTP } from '@/services/auth/mutations'
import { useGetOrgBudget } from '@/services/budget/queries'
import { useMilestonePaymentOtp } from '@/services/payroll/mutations'
import { Budget } from '@/types/budget'
import { ContractPayrollType } from '@/types/payroll'
import { formatAmount } from '@/utils/money'
import React, { useEffect, useMemo, useState } from 'react'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    setBudgetId: React.Dispatch<React.SetStateAction<string>>
    setSteps: React.Dispatch<React.SetStateAction<number>>
    budgetId: string
    totalPayrollAmount: number
    setSelectedBudget: React.Dispatch<React.SetStateAction<Budget | undefined>>
    selectedBudget: Budget | undefined
    isMileStone?: boolean
    contract?: ContractPayrollType
}
export default function PayrollProcess1({
    closeModal,
    totalPayrollAmount,
    setBudgetId,
    setSteps,
    budgetId,
    setSelectedBudget,
    selectedBudget,
    isMileStone,
    contract,
}: Props) {
    const { mutate: generateOtp } = useGeneratePaymentOTP({})

    const { selectedAccount } = useAuthContext()

    const {
        data: org_budget,
        isLoading,
        isFetched,
    } = useGetOrgBudget({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            account: contract?.employee?._id ?? (contract?.employee?.id as string),
        },
    })

    const [linkBudget, setLinkBudget] = useState('no_link')
    const orgBudget = useMemo(() => org_budget?.budgets ?? [], [org_budget])
    const { mutate: mileStoneOtpFn } = useMilestonePaymentOtp({})

    useEffect(() => {
        if (linkBudget === 'no_link') {
            setSelectedBudget(undefined)
            setBudgetId('')
        }
    }, [linkBudget])

    useEffect(() => {
        if (budgetId) {
            setSelectedBudget(orgBudget?.find(budget => budget?._id === budgetId))
        }
    }, [budgetId, orgBudget, setSelectedBudget])

    return (
        <section className='w-[35rem] p-7'>
            <div>
                <h3 className='text-lg font-medium'>Do you want to link this payout to a budget?</h3>
                <p className='text-sm font-medium mt-3 mb-5'>
                    Linking your payout to a budget can help to further monitor and control spending for your company.
                </p>
            </div>
            <div>
                <RadioGroup className='flex flex-col gap-3 mb-6' onValueChange={setLinkBudget} value={linkBudget}>
                    <label
                        htmlFor={'link'}
                        className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm cursor-pointer'
                    >
                        <RadioGroupItem value={'link'} id={'link'} />
                        Yes, link to a budget
                    </label>
                    <label
                        htmlFor={'no_link'}
                        className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm cursor-pointer'
                    >
                        <RadioGroupItem value={'no_link'} id={'no_link'} />
                        No, don’t link to any budget
                    </label>
                </RadioGroup>
            </div>
            {linkBudget === 'link' && (
                <div className='w-full'>
                    <label htmlFor='budget' className='text-sm'>
                        {' '}
                        Select budget *
                    </label>
                    <Select name='budget' onValueChange={setBudgetId}>
                        <SelectTrigger className=' border-[#DADCE0] text-base h-[3.5rem]  text-[#000] font-medium mt-2'>
                            <SelectValue placeholder={'Choose a budget to link'} />
                        </SelectTrigger>
                        <SelectContent className='min-w-[100%] max-h-[600px] overflow-scroll'>
                            {isLoading
                                ? 'Please wait...'
                                : orgBudget?.map(budget => (
                                      <SelectItem value={budget?._id}>
                                          {budget.name} (Balance: {formatAmount(budget?.available_amount)})
                                      </SelectItem>
                                  ))}
                            {isFetched && orgBudget?.length === 0 && 'No budget affiliated with the user'}
                        </SelectContent>
                    </Select>
                </div>
            )}

            {Number(totalPayrollAmount) > Number(selectedBudget?.available_amount) && (
                <div className='bg-[#F8F1DE] rounded-lg mt-8 text-sm font-medium p-4 px-5'>
                    The selected budget balance ({formatAmount(selectedBudget?.available_amount)}) is less than the
                    total payout amount ({formatAmount(totalPayrollAmount)}). You need a balance of{' '}
                    <span className='font-semibold'>
                        {formatAmount(Number(totalPayrollAmount) - Number(selectedBudget?.available_amount))}
                    </span>{' '}
                    to process this payroll
                    <span className='block mt-2 text-brand'>Please Increase your budget and try again</span>
                </div>
            )}
            <div>
                <div className=' flex gap-4 justify-end mt-8 '>
                    <Button
                        onClick={() => closeModal(false)}
                        className='border-none min-w-[120px] bg-transparent text-[black] hover:bg-transparent'
                    >
                        Cancel
                    </Button>
                    <Button
                        className='min-w-[120px]'
                        onClick={() => {
                            generateOtp({})
                            if (isMileStone) {
                                setSteps(4)
                                mileStoneOtpFn({})
                                return
                            }
                            setSteps(2)
                        }}
                        disabled={
                            (linkBudget === 'link' && !selectedBudget) ||
                            Number(totalPayrollAmount) > Number(selectedBudget?.available_amount)
                        }
                    >
                        Proceed
                    </Button>
                </div>
            </div>
        </section>
    )
}
