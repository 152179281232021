import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useState } from 'react'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { useCreateVaultCategory, useUpdateVaultCategory } from '@/services/vault/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { categoryProps } from '@/modules/dashboard/Vault/partials/VaultSettings/VaultCategories'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    update?: boolean
    category?: categoryProps
    setSelectedCategory: React.Dispatch<React.SetStateAction<categoryProps | undefined>>
}

export const CreateCategoryModal = ({ closeModal, update = false, category, setSelectedCategory }: Props) => {
    const [formState, setFormState] = useState({
        name: category ? category.name : '',
    })
    const allow = formState.name.length > 0

    const { mutate: updateFn, isLoading: isUpdating } = useUpdateVaultCategory({
        onSuccess: () => {
            toastHandler({ message: `Category updated successfully` })
            setSelectedCategory(undefined)
            closeModal(false)
        },
    })

    const { mutate: createFn, isLoading: isCreating } = useCreateVaultCategory({
        onSuccess: () => {
            toastHandler({ message: `Category created successfully`, state: 'success' })
            closeModal(false)
        },
    })

    const handleButton = () => {
        if (update) {
            updateFn({
                name: formState.name,
                id: category?.id as string,
            })
            return
        }

        if (!update) {
            createFn({
                name: formState.name,
            })
            return
        }
    }
    return (
        <ModalWrapper
            variants={undefined}
            formId='categoryForm'
            closeModal={() => closeModal(false)}
            shouldConfirmClose
        >
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>{update ? 'Update' : 'Create new'} category</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <form id='categoryForm' onSubmit={e => e.preventDefault()}>
                    <div className='px-4 lg:px-4 p-7 pt-5 overflow-y-scroll'>
                        <div className={`flex flex-col flex-1 gap-3 mt-1`}>
                            <p className='w-full text-right -mb-10 text-sm text-gray7'>
                                {formState.name.length}/50 characters
                            </p>
                            <label className='flex items-center gap-1'>
                                Category name
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='text'
                                placeholder={`Name this category`}
                                name='name'
                                value={formState.name}
                                onChange={e => {
                                    if (e.target.value.length === 51) return
                                    setFormState(prev => ({ ...prev, name: e.target.value }))
                                }}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>
                    </div>
                </form>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        disabled={update ? formState.name.length <= 0 || isUpdating : !allow || isCreating}
                        onClick={handleButton}
                        loading={isCreating || isUpdating}
                    >
                        {update ? 'Update' : 'Create'} category
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
