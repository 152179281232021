import { Button, Input } from '@/components'
import { emailPattern } from '@/const/regex'
import { useAuthContext } from '@/context'
import { Controller, useForm } from 'react-hook-form'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import LoginImage2 from '@/assets/Side bar image - Login.png'
import Logo from '@/assets/bizpendlogo.png'
import * as Sentry from '@sentry/react'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import {} from 'posthog-js'
import { usePostHog } from 'posthog-js/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/pagination'
import { authRouteConst } from '@/routes/auth.route'
import { useLoginUser } from '@/services/auth/mutations'

type FormValues = {
    email: string
    password: string
}

const Login = () => {
    const posthog = usePostHog()
    const navigate = useNavigate()
    const { setLoggedIn, logoutUser } = useAuthContext()

    useEffect(() => {
        logoutUser()
    }, [])

    const {
        formState: { isValid, errors },
        control,
        handleSubmit,
    } = useForm<FormValues>({
        reValidateMode: 'onBlur',
    })

    const [showPassword, setShowPassword] = useState(false)
    const handleShowPassword = () => {
        setShowPassword(prev => !prev)
    }

    const { mutate: loginUser, isLoading } = useLoginUser({
        onSuccess: data => {
            if (data.account) {
                posthog.identify(data.account.id, {
                    first_name: data.user.first_name,
                    last_name: data.user.last_name,
                    email: data.user.email,
                    account_id: data.account.id,
                    org_id: data.account.org?.id,
                    org_name: data.account.org_name,
                })
            }
            Sentry.setUser({
                email: data?.user?.email,
                id: data?.user?.id,
                name: `${data?.user?.first_name} ${data?.user?.last_name}`,
            })
            if (!data?.user?.email_verified) {
                navigate(`${authRouteConst.verifyEmail}?email=${data?.user?.email}`)
                return
            }

            setLoggedIn(true)
        },
    })

    const onSubmit = async (values: FormValues) => {
        const payload = {
            email: values.email,
            password: values.password,
        }

        loginUser(payload)
    }

    useEffect(() => {
        posthog.reset()
    }, [])

    return (
        <div className='w-full max-w-[100%] h-full min-h-[100vh] grid grid-cols-2 overflow-hidden'>
            <div className='border-r border-[#DADCE0] h-full hidden bg-[#EFEFF9] lg:flex lg:flex-col justify-between'>
                <div className=''>
                    <div className='p-8 py-3 border-b border-[#DADCE0]'>
                        <div className=''>
                            <img src={Logo} alt='logo' className='w-[137px]' />
                        </div>
                    </div>

                    <div className='p-8 mb-4 h-[300px]'>
                        <Swiper slidesPerView={1} modules={[Autoplay]} autoplay={{ delay: 6000 }}>
                            <SwiperSlide>
                                <p className='text-[24px] font-medium'>
                                    Using Bizpend has revolutionized our financial processes, making expense tracking
                                    effortless and efficient.
                                </p>
                                <div className='flex flex-col gap-1 mt-4'>
                                    <span>-- Prodevs</span>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <p className='text-[24px] font-medium'>
                                    Using Bizpend has revolutionized our financial processes, making expense tracking
                                    effortless and efficient.
                                </p>

                                <div className='flex flex-col gap-1 mt-4'>
                                    <span>-- Prodevs</span>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

                <div>
                    <img src={LoginImage2} alt='Login image' className='w-[90%] h-auto mt-auto' />
                </div>
            </div>

            <div className='flex items-center col-span-2 lg:col-span-1'>
                <form onSubmit={handleSubmit(onSubmit)} className='w-[90%] md:w-[70%] mx-auto'>
                    <h2 className='text-[#31254B] text-3xl mb-4 font-semibold'>Log in </h2>
                    <p className='text-[#202020] mb-6 text-[18px]'>Welcome back! Continue where you stopped.</p>

                    <div className='flex flex-col flex-1 gap-3 mb-6'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Email
                        </label>
                        <Controller
                            name='email'
                            rules={{
                                required: true,
                                validate: value => {
                                    return emailPattern.test(value)
                                },
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-14 md:h-16'
                                    name='email'
                                    type='email'
                                    placeholder='Please enter email address'
                                    data-testid='email'
                                />
                            )}
                        />
                        {errors.email && <p className='text-red-500 text-[14px]'>{errors.email?.message}</p>}
                    </div>

                    <div className='flex flex-col flex-1 gap-3 mb-6'>
                        <label htmlFor='password' className='text-[#202020] text-[14px]'>
                            Password
                        </label>
                        <Controller
                            name='password'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter your password'
                                    className='h-14 md:h-16'
                                    rightIcon={
                                        showPassword ? (
                                            <EyeIcon onClick={handleShowPassword} className='cursor-pointer' />
                                        ) : (
                                            <EyeOffIcon onClick={handleShowPassword} className='cursor-pointer' />
                                        )
                                    }
                                    data-testid='password'
                                    {...field}
                                />
                            )}
                        />
                        {errors.password && <p className='text-red-500 text-[14px]'>{errors.password?.message}</p>}
                    </div>

                    <div className='flex justify-end text-[14px] mb-4'>
                        <Link to='/forgot-password'>Forgot password?</Link>
                    </div>

                    <div className='text-center text-[14px] hidden'>
                        <span className='flex items-center gap-2'>
                            Don’t have an account?
                            <Link to='/sign-up' className='text-[#454ADE] font-semibold'>
                                Sign up
                            </Link>
                        </span>
                    </div>

                    <div className='mt-12 flex justify-end'>
                        <Button loading={isLoading} disabled={!isValid} variant='default' role='button' name='Log in'>
                            Log in
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login
