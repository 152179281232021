import { useFeatureFlagEnabled } from 'posthog-js/react'
import { FeatureFlagType } from './types'

type Flag = keyof FeatureFlagType

const useFeatureFlag = (flag: Flag) => {
    const enabled = useFeatureFlagEnabled(flag)

    return enabled
}

export default useFeatureFlag
