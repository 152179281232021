import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../../ModalWrapper'
import { CiSearch } from 'react-icons/ci'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import SinglePaymentRequest from './SinglePaymentRequest'
import { useState } from 'react'

type PaymentRequestsModal = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
const PaymentRequests = ({ closeModal }: PaymentRequestsModal) => {
    const [filter, setFilter] = useState('pending')
    return (
        <ModalWrapper variants={undefined}>
            <div className='w-[48rem] min-h-[85vh] relative'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                    <div className='flex items-center w-[58%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B]'>Payment requests</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer mr-8'
                            onClick={() => {
                                closeModal(false)
                            }}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='px-10 py-8 max-h-[400px] lg:max-h-[71vh] overflow-scroll'>
                    <div className='border border-[#DADCE0] h-[56px] rounded-lg flex items-center overflow-hidden '>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input type='text' placeholder='Search expense list' className='h-full w-[90%] outline-none' />
                    </div>
                    <div className='mt-6'>
                        <Select onValueChange={e => setFilter(e)} value={filter}>
                            <SelectTrigger className='bg-[#F9F9F9] border-none w-[9rem]'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[9rem]'>
                                <SelectItem value='pending'>Pending</SelectItem>
                                <SelectItem value='approved'>Approved</SelectItem>
                                <SelectItem value='3 months'>Last 3 Months</SelectItem>
                                <SelectItem value='6 months'>Last 6 Months</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className='mt-8'>
                        <SinglePaymentRequest />
                        <SinglePaymentRequest />
                        <SinglePaymentRequest />
                    </div>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default PaymentRequests
