import useFeatureFlag from '@/lib/feature-flags/useFeatureFlag'

export const useCheckIfAdminBizpendEmail = (email?: string, access?: boolean) => {
    const show_biz_dir = useFeatureFlag('show_biz_dir')

    if (!email) return false

    const adminemails = import.meta.env.VITE_APP_BIZ_DIR_EMAILS?.split(',') ?? []

    if (adminemails.includes(email) && (access ? access : show_biz_dir)) return true

    return false
}

export const useAllowAccountImitation = () => {
    const emulate_user = useFeatureFlag('allow-account-imitation')
    return emulate_user
}
