import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import Step2 from '../SignUp/Step2'

export default function VerifyEmail() {
    const [_, searchParamDetails] = useAddQueryParamsToURL()
    const loggedInEmail = searchParamDetails.get('email') as string

    return (
        <section>
            <Step2 emailForOtp={loggedInEmail} />
            <button className='hidden' onClick={() => console.warn(_)}></button>
        </section>
    )
}
