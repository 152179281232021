import ProgressBar from '@/components/ProgressBar'
import BusinessDirectoryTable from '../components/BusinessDirectoryTable'
import { CiSearch } from 'react-icons/ci'
import { useMemo, useState } from 'react'
import { useGetAllOrgs } from '@/services/imitating-users/queries'
import { QueryAllOrgs } from '@/services/imitating-users/types'

export default function OrgsInBusinessDirectory() {
    const { data: all_orgs, isLoading } = useGetAllOrgs({})
    const allOrgs = useMemo(() => all_orgs ?? [], [all_orgs])
    const [searchQuery, setSearchQuery] = useState('')
    function filterOrganizations(query: string, organizations: QueryAllOrgs): QueryAllOrgs {
        return organizations.filter(
            org => org._id.includes(query) || org.org_name.toLowerCase().includes(query.toLowerCase())
        )
    }

    const filteredOrganizations = filterOrganizations(searchQuery, allOrgs)
    return (
        <section>
            <div className='my-7'>
                <div className=''>
                    <h1 className='text-xl font-semibold'>Manage businesses</h1>
                    <p className='text-base text-[#5E5E5E] font-medium mt-2'>
                        This is a list of all businesses that have registered under bizpend
                    </p>
                </div>
            </div>
            <div className='border border-[#DADCE0] h-[56px] w-auto lg:w-[35rem] rounded-lg flex items-center overflow-hidden'>
                <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                    <CiSearch fontSize={25} />
                </div>
                <input
                    type='text'
                    placeholder='Search by Org name'
                    className='flex-1 outline-none bg-[#FAFAFD]'
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
            </div>
            <div className='mt-5 pb-32'>
                {isLoading ? (
                    <ProgressBar />
                ) : (
                    <div className='overflow-x-scroll'>
                        <BusinessDirectoryTable orgs={filteredOrganizations} />
                    </div>
                )}
            </div>
        </section>
    )
}
