import { useAuthContext } from '@/context'
import { BillType } from '@/services/bill-payment/types'
import { useGetOrgMembers } from '@/services/employees/queries'
import { APPROVERSTIMELINE_CONST, BillStatus } from '@/types/bill-payments'
import StatusColor from '@/components/utils/StatusColor'
import { format } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { Check, Clock, ThumbsUp } from 'lucide-react'
import { TimerIcon } from '@/assets/assets.export'

export default function BillDetailsTimeLine({ bill }: { bill: BillType }) {
    const { selectedAccount } = useAuthContext()
    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const rearrangeApprovers = () => {
        return bill?.approvers?.sort?.(
            (approver1, approver2) => Number(approver2.approved) - Number(approver1.approved)
        )
    }
    const lastApprover = rearrangeApprovers()[bill?.approvers?.length - 1]
    const reimburser = org_members?.find(
        memb => memb?.user?._id === (bill?.transactions?.[0]?.initiated_by as unknown as string)
    )

    return (
        <div className='flex flex-col text-[#202020]'>
            <h1 className='text-lg font-semibold border-b border-[#DADCE0] py-3 mb-7'>Bill journey timeline</h1>
            <div className='flex items-start gap-6'>
                <div className='relative'>
                    <span className='py-[9px] px-[9px] bg-[#D5D5F5] rounded-lg flex'>
                        <Clock strokeWidth={1.5} />
                    </span>
                    <span className='px-[.5px] py-9 bg-[#A8A8A8] absolute left-[20px] top-[41px]'></span>
                </div>
                <div className='-mt-1'>
                    <div className='max-w-[200px] mb-3 text-sm'>
                        <StatusColor status={'pending' as BillStatus} />
                    </div>
                    <div className='flex gap-6 text-base mb-5'>
                        <div className=''>
                            <h2 className='text-[#838383] text-sm'>Created by</h2>
                            <p className='font-semibold mt-1 text-sm mb-1'>
                                {bill?.initiator?.user?.first_name ?? 'N/A'} {bill?.initiator?.user?.last_name ?? ''}
                            </p>
                        </div>
                        <div className=''>
                            <h2 className='text-[#838383] text-sm'>Date</h2>
                            <p className='font-semibold mt-1 text-sm mb-1'>
                                {bill?.createdAt ? format(new Date(bill?.createdAt), 'dd/MM/yyyy') : 'N/A'}
                            </p>
                        </div>
                        <div className=''>
                            <h2 className='text-[#838383] text-sm'>Time</h2>
                            <p className='font-semibold mt-1 text-sm mb-1'>
                                {' '}
                                {bill?.createdAt ? format(new Date(bill?.createdAt), 'hh:mm a') : 'N/A'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {rearrangeApprovers()
                .slice(0, bill?.approvers?.length - 1)
                .map((approver, idx) => (
                    <div className='flex items-start gap-6 mb-5'>
                        <div className='relative'>
                            <span
                                className={`flex py-[9px] px-[9px] ${
                                    approver?.approved ? 'bg-[#D5D5F5]' : 'bg-[#FAFAFD]'
                                }  rounded-lg`}
                            >
                                <TimerIcon className={approver?.approved ? 'opacity-100' : 'opacity-10'} />
                            </span>
                            <span
                                className={`px-[.5px] py-9  ${
                                    approver?.approved ? 'bg-[#A8A8A8]' : 'bg-[#FAFAFD]'
                                } absolute left-[20px] top-[41px]`}
                            ></span>
                        </div>
                        <div className=''>
                            <div className='flex gap-6 text-base'>
                                <div className=''>
                                    <h2 className='text-[#838383] text-sm'>
                                        {APPROVERSTIMELINE_CONST[idx as keyof typeof APPROVERSTIMELINE_CONST]} approver
                                    </h2>
                                    <p className='font-semibold mb-1'>
                                        {capitalizeText(approver?.account?.user?.first_name)}{' '}
                                        {capitalizeText(approver?.account?.user?.last_name)}
                                    </p>
                                </div>
                            </div>
                            <div className='max-w-[200px] mb-1 text-sm'>
                                <StatusColor disable={!approver?.approved} status={bill?.status} />
                            </div>
                        </div>
                    </div>
                ))}
            <div className='flex items-start gap-6 mb-5'>
                <div className='relative'>
                    {bill?.approvers?.length === 0 && bill?.status !== BillStatus.declined ? (
                        <>
                            <span className={`flex py-[9px] px-[9px] bg-[#D5D5F5] rounded-lg`}>
                                <ThumbsUp strokeWidth={1.5} />
                            </span>
                            <span className={`px-[.5px] py-9 bg-[#A8A8A8] absolute left-[20px] top-[41px]`}></span>
                        </>
                    ) : bill?.approvers?.length > 0 && bill?.status !== BillStatus.declined ? (
                        <>
                            <span
                                className={`flex py-[9px] px-[9px] ${
                                    lastApprover?.approved ? 'bg-[#D5D5F5]' : 'bg-[#FAFAFD]'
                                }  rounded-lg`}
                            >
                                <ThumbsUp
                                    strokeWidth={1.5}
                                    className={lastApprover?.approved ? 'opacity-100' : 'opacity-10'}
                                />
                            </span>
                            {bill.status === BillStatus.paid && (
                                <span
                                    className={`px-[.5px] py-9  ${
                                        lastApprover?.approved ? 'bg-[#A8A8A8]' : 'bg-[#FAFAFD]'
                                    } absolute left-[20px] top-[41px]`}
                                ></span>
                            )}
                        </>
                    ) : (
                        <>
                            <span className={`flex py-[9px] px-[9px] bg-[#D5D5F5] rounded-lg`}>
                                <ThumbsUp strokeWidth={1.5} />
                            </span>
                        </>
                    )}
                </div>
                <div className=''>
                    <div className='flex gap-6 text-base'>
                        <div className=''>
                            <h2 className='text-[#838383] text-sm'>
                                {bill?.status !== BillStatus.declined ? 'Approved' : 'Denied'} by (Final)
                            </h2>

                            <p className='font-semibold mb-1'>
                                {bill?.approvers?.length === 0
                                    ? 'Auto Approval'
                                    : `
                                ${capitalizeText(lastApprover?.account?.user?.first_name)}
                                 ${capitalizeText(lastApprover?.account?.user?.last_name)}`}
                            </p>
                        </div>
                    </div>
                    <div className='max-w-[200px] mb-1 text-sm'>
                        {bill?.approvers?.length === 0 && bill?.status !== BillStatus.declined ? (
                            <StatusColor status={BillStatus.approved} />
                        ) : bill?.approvers?.length > 0 && bill?.status !== BillStatus.declined ? (
                            <StatusColor disable={!lastApprover?.approved} status={BillStatus.approved} />
                        ) : (
                            <StatusColor status={BillStatus.declined} />
                        )}
                    </div>
                </div>
            </div>
            {bill.status === BillStatus.paid && (
                <div className='flex items-start gap-6 mb-5'>
                    <div className='relative'>
                        <span
                            className={`flex py-[9px] px-[9px] ${
                                bill?.status === BillStatus.paid ? 'bg-[#D5D5F5]' : 'bg-[#FAFAFD]'
                            }  rounded-lg`}
                        >
                            <Check className={bill?.status === BillStatus.paid ? 'opacity-100' : 'opacity-10'} />
                        </span>
                    </div>
                    <div className=''>
                        <div className='flex gap-6 text-base'>
                            <div className=''>
                                <h2 className='text-[#838383] text-sm'>Paid by (Final)</h2>
                                <p className='font-semibold mb-1'>
                                    {reimburser ? (
                                        <>
                                            {reimburser?.user?.first_name} {reimburser?.user?.last_name}
                                        </>
                                    ) : (
                                        <>
                                            {capitalizeText(lastApprover?.account?.user?.first_name)}{' '}
                                            {capitalizeText(lastApprover?.account?.user?.last_name ?? 'Not Available')}
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className='max-w-[200px] mb-1 text-sm'>
                            <StatusColor disable={bill?.status !== BillStatus.paid} status={BillStatus.paid} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
