import moneyIcon from '@/assets/money.svg'
import { OverviewInsight } from '@/services/auth/types'

import { formatAmount } from '@/utils/money'

type Props = {
    orgInsight: OverviewInsight
}
const TotalExpenses = ({ orgInsight }: Props) => {
    const percentage = (Number(orgInsight?.total_expenses) / 500000000) * 100

    return (
        <div className='row-span-1 rounded-xl bg-white border border-[#DADCE0] p-3 xl:p-6 mb-3 xl:mb-6'>
            <div className='flex gap-3 xl:gap-5 items-center'>
                <div className=''>
                    <img src={moneyIcon} alt='icon' className='w-9 xl:w-12' />
                </div>
                <div>
                    <h3 className=' text-[#5E5E5E]'>Total expenses</h3>
                    <p className='font-medium text-xl xl:text-2xl'>
                        {' '}
                        {formatAmount(Number(orgInsight?.total_expenses) ?? 0)}
                    </p>
                </div>
            </div>

            <div className='h-[16px] w-full mt-4 xl:mt-6 rounded flex items-center bg-[#f5f5f5] relative mb-6 xl:mb-8'>
                <div
                    className='h-full bg-[#19C37D] rounded-l'
                    style={{ width: `${percentage > 39 ? '40' : percentage}%` }}
                ></div>
                <p className='text-[12px] text-[#5E5E5E] translate-y-7 w-[40%] text-left absolute'>{/* On Track */}</p>

                {percentage > 39 && (
                    <div
                        className='h-full  bg-[#DEB145]'
                        style={{ width: `${percentage > 69 ? '30' : Number(percentage - 39)}%` }}
                    ></div>
                )}
                <p className='text-[12px] w-[30%]  text-[#5E5E5E] translate-y-7 text-center absolute left-[40%]'>
                    {/* Warning */}
                </p>
                {percentage > 69 && (
                    <div
                        className={`h-full bg-[#CF491E] ${
                            percentage > 100 ? 'rounded-r' : ''
                        } overflow-hidden rounded-r `}
                        style={{ width: `${percentage > 100 ? '30' : Number(percentage - 69)}%` }}
                    ></div>
                )}
                <p className='text-sm xl:text-[16px] font-medium w-[30%]  text-[#5E5E5E] translate-y-5 xl:translate-y-7 text-right absolute left-[70%]'>
                    ₦ 5,000,000
                </p>
            </div>
        </div>
    )
}

export default TotalExpenses
