import { createMutation } from '../api/mutation'
import { orgInsightkey } from '../auth/queries'
import { orgTransactionKey } from '../transactions/queries'

export const useVerifyFundingMutation = createMutation({
    url: '/verify-funding',
    keysToRefetch: [orgInsightkey, orgTransactionKey],
    method: 'POST',
})
export const useSendTransferOtp = createMutation({
    url: '/send-transfer-otp',
    method: 'POST',
})
