import React, { createContext, useState } from 'react'
import { Vendor } from '@/types/vendor'
import { Customers } from '@/types/customers'

type SortColumn = { id: string | undefined; desc: boolean | undefined }
export type defaultVendorProps = {
    selectedVendor: Vendor | undefined
    setSelectedVendor: React.Dispatch<React.SetStateAction<Vendor | undefined>>
    selectedCustomer: Customers | undefined
    setSelectedCustomer: React.Dispatch<React.SetStateAction<Customers | undefined>>
    sort: SortColumn | undefined
    setSort: React.Dispatch<React.SetStateAction<SortColumn | undefined>> | undefined
    filterFormState: formProps
    setFilterFormState: React.Dispatch<React.SetStateAction<formProps>>
}

export const advancedFilteringProps = {
    status: '',
    period: { from: null, to: null, time_in_words: '' },
    amount: { min: '', max: '', amount_in_words: '' },
    category: '',
}

export type formProps = {
    status: string
    period: { from: Date | null; to: Date | null; time_in_words: string }
    amount: { min: string; max: string; amount_in_words: string }
    filter_now?: boolean
    category: string
}

const defaultVendorState: defaultVendorProps = {
    selectedVendor: undefined,
    setSelectedVendor: () => undefined,
    selectedCustomer: undefined,
    setSelectedCustomer: () => undefined,
    sort: undefined,
    setSort: () => undefined,
    filterFormState: advancedFilteringProps,
    setFilterFormState: () => undefined,
}

export const VendorContext = createContext(defaultVendorState)

export const VendorsContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedVendor, setSelectedVendor] = useState<Vendor>()
    const [selectedCustomer, setSelectedCustomer] = useState<Customers>()
    const [sort, setSort] = useState<SortColumn>()
    const [filterFormState, setFilterFormState] = useState<formProps>(advancedFilteringProps)

    const value = {
        selectedVendor,
        setSelectedVendor,
        sort,
        setSort,
        filterFormState,
        setFilterFormState,
        selectedCustomer,
        setSelectedCustomer,
    }
    return <VendorContext.Provider value={value}>{children}</VendorContext.Provider>
}
