import React from 'react'
import { useSearchParams } from 'react-router-dom'

const ImageViewr = () => {
    const [searchParams] = useSearchParams()

    const fileUrl = decodeURIComponent(searchParams.get('imgUrl') as string)

    return (
        <div className='grid place-items-center p-10'>
            <h1 className='text-2xl font-medium text-gray7 mb-2'>Image viewer</h1>
            <img src={fileUrl} alt='Viewed' className='max-w-md' />
        </div>
    )
}

export default ImageViewr
