import { Button } from '@/components'
import { useMutateAddMemberToTeam } from '@/services/employees/mutations'
import { EmployeeType } from '@/types/org'
import { useState } from 'react'
import { BsCheck2 } from 'react-icons/bs'
import { CgAddR } from 'react-icons/cg'
import { useEmployeesContext } from '@/context'

type Props = {
    member: EmployeeType
}

export default function SingleInvite({ member }: Props) {
    const [addMemberState, setAddMemberState] = useState(false)
    const { teamsObj } = useEmployeesContext()

    const { mutate: addMemberFn, isLoading } = useMutateAddMemberToTeam({
        queryParams: {
            team_id: teamsObj?.id as string,
        },
        onSuccess: () => {
            setAddMemberState(true)
        },
    })

    const handleAddMember = () => {
        addMemberFn({
            member: member?.id,
        })
    }

    return (
        <div className='flex gap-3 border-b border-b-gray2/30 items-center pb-5'>
            <div className=''>
                <span className='block text-[16px] font-semibold text-[#202020] mb-2 w-[200px] truncate'>
                    {member?.user?.first_name} {member?.user?.last_name}
                </span>
                <span className='block text-gray1 text-[14px]'>{member?.role?.name}</span>
            </div>
            <div className='flex justify-end border-l border-l-gray2/30 w-[45%]'>
                {addMemberState ? (
                    <span className='text-[#31254B] flex items-center gap-2'>
                        <BsCheck2 color='blue' />
                        Added to team!
                    </span>
                ) : (
                    <Button
                        variant={'default'}
                        className='min-w-[135px] px-3 w-[135px] '
                        onClick={handleAddMember}
                        loading={isLoading}
                    >
                        <CgAddR />
                        Add to team
                    </Button>
                )}
            </div>
        </div>
    )
}
