import { Vendor } from '@/types/vendor'
import { createMutation } from '../api/mutation'
import { orgVendorsKey } from './queries'
import { keyUser } from '../auth/queries'

const key = ['/org-vendor']

export const useMutationCreateVendor = createMutation({
    url: '/vendors',
    keysToRefetch: [orgVendorsKey, keyUser],
    method: 'POST',
})

export const useMutationDeleteVendor = createMutation({
    url: '/vendor',
    keysToRefetch: [orgVendorsKey],
    method: 'DELETE',
})

export const useMutationUpdateVendor = createMutation<Vendor>({
    url: '/vendor',
    keysToRefetch: [orgVendorsKey, key],
    method: 'PATCH',
})

export const useMutationSuspendVendor = createMutation<Vendor>({
    url: '/suspend-vendor',
    keysToRefetch: [orgVendorsKey, key],
    method: 'POST',
})

export const useMutationActivateVendor = createMutation<Vendor>({
    url: '/activate-vendor',
    keysToRefetch: [orgVendorsKey, key],
    method: 'POST',
})
