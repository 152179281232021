import { PayrollContextProvider } from '@/context/payroll'
import useFeatureFlag from '@/lib/feature-flags/useFeatureFlag'
import ContractMoreDetails from '@/modules/dashboard/Contract/components/ContractMoreDetails/ContractMoreDetails'
import Contract from '@/modules/dashboard/Contract/Contract'
import OverviewDashboard from '@/modules/dashboard/Overview/OverviewDashboard'
import LeaveMoreDetails from '@/modules/dashboard/Payroll/components/LeaveMoreDetails/LeaveMoreDetailsPage'
import NewBenefitPages from '@/modules/dashboard/Payroll/components/NewBenefit/NewBenefitPageRoot'
import RunPayrollDetailsPage from '@/modules/dashboard/Payroll/components/RunPayrollDetailsPage'
import BenefitDetails from '@/modules/dashboard/Payroll/partials/BenefitDetails/BenefitDetails'
import GeneratePayroll from '@/modules/dashboard/Payroll/partials/GeneratePayroll'
import LeaveModule from '@/modules/dashboard/Payroll/partials/LeaveManagement'
import { PayHistoryDetails } from '@/modules/dashboard/Payroll/partials/Payhistory/PayHistoryDetails'
import Payroll from '@/modules/dashboard/Payroll/Payroll'
import UserContract from '@/modules/dashboard/UserContract/UserContract'
import UserContractMoreDetails from '@/modules/dashboard/UserPayroll/components/MoreDetailsContracts/UserMoreDetailsContracts'
import MoreDetailsPayHistory from '@/modules/dashboard/UserPayroll/components/MoreDetailsPayHistory/MoreDetailsPayHistory'
import UserLeaveManagement from '@/modules/dashboard/UserPayroll/partials/LeaveManagement/UserLeaveManagement'
import UserBenefitDetails from '@/modules/dashboard/UserPayroll/partials/UserBenefitDetails/UserBenefitDetails'
import UserPayroll from '@/modules/dashboard/UserPayroll/UserPayroll'
import { Outlet, Route } from 'react-router-dom'

const payrollRoutes = {
    user: {
        payrollSubRoute: 'payroll/:payroll_subRoute',
        contractSubRoute: 'contract/:contract_subRoute',
        contractDetails: 'contract/:payroll_subRoute/:contract_id',
        payHistory: 'payroll/pay_history/:pay_history_id',
        leaveDetails: 'payroll/user-leave/:leave_id',
        leave: 'payroll/user-leave',
        benefitDetails: 'payroll/my_benefits/:benefit_id',
    },
}

export const useUserPayrollContractRoutes = () => {
    return (
        <Route
            element={
                <PayrollContextProvider>
                    <Outlet />
                </PayrollContextProvider>
            }
        >
            <Route path={payrollRoutes.user.leave} element={<UserLeaveManagement />} />

            <Route path={payrollRoutes.user.payrollSubRoute} element={<UserPayroll />} />
            <Route path={payrollRoutes.user.contractSubRoute} element={<UserContract />} />
            <Route path={payrollRoutes.user.contractDetails} element={<UserContractMoreDetails />} />
            <Route path={payrollRoutes.user.payHistory} element={<MoreDetailsPayHistory />} />
            <Route path={payrollRoutes.user.leaveDetails} element={<LeaveMoreDetails />} />
            <Route path={payrollRoutes.user.benefitDetails} element={<UserBenefitDetails />} />
        </Route>
    )
}

export const useCompanyPayrollContractRoutes = () => {
    const payrollFlagEnabled = useFeatureFlag('show_payroll')
    const contractFlagEnabled = useFeatureFlag('show_contract')

    if (!payrollFlagEnabled && location.href.includes('payroll'))
        return <Route path='/dashboard/overview' element={<OverviewDashboard />} />
    if (!contractFlagEnabled && location.href.includes('contract'))
        return <Route path='/dashboard/overview' element={<OverviewDashboard />} />
    return (
        <Route
            element={
                <PayrollContextProvider>
                    <Outlet />
                </PayrollContextProvider>
            }
        >
            {/* User */}
            <Route path={payrollRoutes.user.contractDetails} element={<UserContractMoreDetails />} />
            <Route path={payrollRoutes.user.payHistory} element={<MoreDetailsPayHistory />} />
            <Route path={payrollRoutes.user.leaveDetails} element={<LeaveMoreDetails />} />
            <Route path={payrollRoutes.user.leave} element={<UserLeaveManagement />} />

            {/*Admin  */}
            <Route path='payroll/:payroll_subRoute' element={<Payroll />} />
            <Route path='payroll/run_payroll/generate-payroll' element={<GeneratePayroll />} />
            <Route path='payroll/leave/:leave_subRoute' element={<LeaveModule />} />
            <Route path='contract/:contract_subRoute' element={<Contract />} />
            <Route path='contract/:contract_subRoute/:contract_id' element={<ContractMoreDetails />} />
            <Route path='payroll/:payroll_subRoute/:payroll_freq/:payroll_id' element={<RunPayrollDetailsPage />} />
            <Route path='payroll/:payroll_subRoute/:payroll_id' element={<PayHistoryDetails />} />
            <Route path='payroll/benefits/new_benefit' element={<NewBenefitPages />} />
            <Route path='payroll/leave/requests/:leave_id' element={<LeaveMoreDetails />} />
            <Route path='payroll/benefits/:benefit_id' element={<BenefitDetails />} />
        </Route>
    )
}
