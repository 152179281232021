import { Button } from '@/components'
import { useMutateBulkDeleteEmployees } from '@/services/employees/mutations'

import { toastHandler } from '@/components/utils/Toast'
import ModalWrapper from '../../ModalWrapper'
import { EmployeeType } from '@/types/org'
import { useAuthContext } from '@/context'

interface BulkDeleteEmployeeProps {
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    bulkEmployeeDetails: EmployeeType[]
}

export default function BulkDeleteEmployee({ setIsDeleteDialogOpen, bulkEmployeeDetails }: BulkDeleteEmployeeProps) {
    const { selectedAccount } = useAuthContext()

    const { mutate: deleteMembersFn, isLoading: isDeleteMembersLoading } = useMutateBulkDeleteEmployees({
        queryParams: {
            org_id: selectedAccount?.id as string,
        },
        onSuccess: () => {
            setIsDeleteDialogOpen(false)
            toastHandler({ message: 'Users Deleted', state: 'success' })
        },
    })
    const handleDeleteEmployees = () => {
        const membersId = bulkEmployeeDetails.map(detail => detail.id)
        deleteMembersFn({ employees: [...membersId] })
    }

    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>Are you sure you want to deactivate this user(s)</p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isDeleteMembersLoading}
                        onClick={handleDeleteEmployees}
                    >
                        Yes, deactivate this user
                    </Button>
                    <button
                        type='button'
                        className='min-h-[50px] px-5 mx-3'
                        onClick={() => setIsDeleteDialogOpen(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}
