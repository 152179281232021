const RequestAccessPending = () => {
    return (
        <div className='w-full h-full min-h-[100vh] max-w-[100%] overflow-hidden flex flex-col justify-center items-center px-5'>
            <h2 className='text-[#202020] text-2xl font-semibold mb-4'>
                Your access request has been sent successfully. Thank you!
            </h2>
            <p className='text-[#202020] text-[16px] text-center'>
                You will receive an email soon to help you onboard your business to our platform once your information
                is confirmed. Thank you for your patience!
            </p>

            <div className='h-[250px] border-b-2 border-[#A8A8A8] w-[350px] lg:w-[600px] my-8 flex items-center gap-4 overflow-hidden justify-center '>
                <div className='w-[90px] h-[255px] rounded-t-[1rem] mb-auto bg-[#F5D484] bar-1'></div>
                <div className='w-[90px] h-[255px] rounded-t-[1rem] mb-auto bg-[#7E82FB] bar-2'></div>
                <div className='w-[90px] h-[255px] rounded-t-[1rem] mb-auto bg-[#FFAB90] bar-3'></div>
                <div className='w-[90px] h-[255px] rounded-t-[1rem] mb-auto bg-[#9FDA96] bar-4'></div>
            </div>
        </div>
    )
}

export default RequestAccessPending
