import { Invoice } from '@/types/invoice'
import { createMutation } from '../api/mutation'
import { keyUser } from '../auth/queries'

export const InvoiceKey = ['invoice-key']
export const InvoiceItemKey = ['invoice-item-key']
export const useCreateInvoice = createMutation({
    url: '/create-invoice',
    method: 'POST',
    keysToRefetch: [InvoiceKey, keyUser],
})

export const useCreateInvoiceDraft = createMutation({
    url: '/draft-invoice',
    method: 'POST',
    keysToRefetch: [InvoiceKey, keyUser],
})

export const useGenerateInvoiceReceipt = createMutation<Invoice>({
    url: '/generate-invoice-receipt',
    method: 'POST',
    // keysToRefetch: [InvoiceKey],
})
export const useUpdateInvoice = createMutation({
    url: '/update-invoice',
    method: 'PATCH',
    keysToRefetch: [InvoiceKey],
})

export const useDeleteInvoice = createMutation({
    url: '/delete-invoice',
    method: 'DELETE',
    keysToRefetch: [InvoiceKey],
})

export const useConfirmInvoicePayment = createMutation({
    url: '/confirm-invoice-payment',
    method: 'PATCH',
    keysToRefetch: [InvoiceKey],
})

export const useSendInvoiveReminder = createMutation({
    url: '/send-invoice-reminder',
    method: 'POST',
    keysToRefetch: [InvoiceKey],
})

export const useMadeOfflinePayment = createMutation({
    url: '/send-invoice-payment-alert',
    method: 'POST',
    keysToRefetch: [InvoiceKey],
})

export const useMutateCreateINvoiceItem = createMutation({
    url: '/create-invoice-item',
    method: 'POST',
    keysToRefetch: [InvoiceItemKey, keyUser],
})
export const useMutateDeleteINvoiceItem = createMutation({
    url: '/invoice-item',
    method: 'DELETE',
    keysToRefetch: [InvoiceItemKey],
})

export const useMutateSendReminder = createMutation({
    url: '/send-invoice-reminder',
    method: 'POST',
    keysToRefetch: [InvoiceKey],
})

export const useInitiateInvoiceCheckout = createMutation({
    url: '/initiate-invoice-checkout',
    method: 'POST',
    keysToRefetch: [InvoiceKey],
})

export const useMatchInvoiceToTransaction = createMutation({
    url: '/match-transaction',
    method: 'POST',
    keysToRefetch: [['possible-transaction']],
})

export const useApproveInvoice = createMutation({
    url: '/approve-invoice',
    method: 'PATCH',
    keysToRefetch: [InvoiceKey],
})
export const useDeclineInvoice = createMutation({
    url: '/decline-invoice',
    method: 'PATCH',
    keysToRefetch: [InvoiceKey],
})
export const useSendinvoice = createMutation({
    url: '/send-invoice',
    method: 'POST',
    keysToRefetch: [InvoiceKey],
})
