import { useAuthContext, useCurrencyContext } from '@/context'
import { FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { Customers } from '@/types/customers'
import { formatAmount } from '@/utils/money'
import { differenceInDays, format } from 'date-fns'
import { FormStateType } from './utils'

type Props = {
    formState?: FormStateType
    logoFile?: FileUploadFormatProps
    orgCustomers: Customers[]
    sub_total: number
}
const InvoicePreview = ({ formState, logoFile, orgCustomers, sub_total }: Props) => {
    const { selectedCurrencyInfo } = useCurrencyContext()
    const currencySymbol = selectedCurrencyInfo?.(formState?.currency)?.symbol
    const { selectedAccount } = useAuthContext()
    const daysToPay = differenceInDays(new Date(formState ? formState?.due_date : ''), new Date())

    const selectedCustomer = orgCustomers?.find(customer => formState?.customer === customer?.id)

    return (
        <section className='font-poppins p-4 border border-[#DADCE0] rounded-lg mb-7'>
            <h1 className='text-sm font-semibold mb-2'>Preview</h1>
            <div className='bg-[#D5D5F5] p-2 flex justify-between items-center mb-2'>
                <div className='title'>
                    <h1 className='text-base font-semibold'>INVOICE</h1>
                    <p className='text-[#5E6470] text-[10px]'>#AB2324-01</p>
                </div>
                <div>
                    {logoFile?.fileUrl ? (
                        <img src={logoFile?.fileUrl} alt='Logo' className='w-[5rem] object-cover' />
                    ) : (
                        <img
                            src={'https://d1sb16rjggut15.cloudfront.net/logos/bizpend.svg'}
                            alt='Logo'
                            className='h-[42px] pr-2'
                        />
                    )}
                </div>
            </div>
            <div className=''>
                <div className='grid grid-cols-1 md:grid-cols-5 pb-2'>
                    <div className='flex flex-col justify-between text-[10px] font-semibold col-span-1 pr-4'>
                        <div className=''>
                            <p className='text-[#202020] '>Issued</p>
                            <p className='text-[#5E6470]'>
                                {formState?.issue_date && format(new Date(formState?.issue_date), 'MMM dd, yyyy')}
                            </p>
                        </div>
                        <div>
                            <p className='text-[#202020]'>Due</p>
                            <p className='text-[#5E6470]'>
                                {formState?.due_date && format(new Date(formState?.due_date), 'MMM dd, yyyy')}
                            </p>
                        </div>
                    </div>
                    <div className='border-l-[0.5px] border-[#DADCE0] pl-4 col-span-2 text-[10px]'>
                        <p className=' text-[#202020] font-semibold mb-[6px]'>Billed to</p>
                        <div>
                            <p className='mb-1 text-[#5E5E5E]'>{selectedCustomer?.name}</p>
                            <p className='text-[#838383]'>
                                {selectedCustomer?.address}, {selectedCustomer?.city}, {selectedCustomer?.state},{' '}
                                {selectedCustomer?.country} - {selectedCustomer?.zipcode}
                                <br />
                                {selectedCustomer?.email} <br />
                                {selectedCustomer?.phone}
                            </p>
                        </div>
                    </div>
                    <div className='border-l-[0.5px] border-[#DADCE0] pl-4 col-span-2 text-[10px]'>
                        <p className=' text-[#202020] font-semibold mb-[6px]'>From</p>
                        <div>
                            <p className='mb-1 text-[#5E5E5E]'>{selectedAccount?.org?.org_name}</p>
                            <p className='text-[#838383]'>
                                {selectedAccount?.org?.address && (
                                    <>
                                        {selectedAccount.org.address}
                                        {(selectedAccount.org.state ||
                                            selectedAccount.org.country ||
                                            selectedAccount.org?.postal_code) &&
                                            ','}
                                    </>
                                )}
                                {selectedAccount?.org?.state && (
                                    <>
                                        {selectedAccount.org.state}
                                        {(selectedAccount.org.country || selectedAccount.org.postal_code) && ','}
                                    </>
                                )}
                                {selectedAccount?.org?.country && (
                                    <>
                                        {selectedAccount.org.country}
                                        {selectedAccount.org.postal_code && ' - '}
                                    </>
                                )}
                                {selectedAccount?.org?.postal_code && selectedAccount.org.postal_code}
                                <br />
                                {selectedAccount?.org?.email && (
                                    <>
                                        {selectedAccount.org.email}
                                        <br />
                                    </>
                                )}
                                {selectedAccount?.org?.website && (
                                    <>
                                        {selectedAccount.org.website}
                                        <br />
                                    </>
                                )}
                                {selectedAccount?.org?.phone && selectedAccount.org.phone}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='my-5'>
                    <table className='w-full text-[10px] text-[#202020]'>
                        <thead className='border-b-[0.5px] border-[#DADCE0]'>
                            <tr>
                                <th className='w-[45%] text-left pb-2'>Item</th>
                                <th className='text-left pb-2'>Qty</th>
                                <th className='text-right pb-2'>Unit cost </th>
                                <th className='text-right pb-2'>Total cost</th>
                            </tr>
                        </thead>
                        <tbody className='border-b-[0.5px] border-[#DADCE0] py-3'>
                            {formState?.item?.map(item => (
                                <tr>
                                    <td className='pt-[7px]'>
                                        <div>
                                            <p className='font-semibold mb-1'>{item?.name}</p>
                                            <p className='text-[#5E6470] w-[90%]'>{item.description}</p>
                                        </div>
                                    </td>
                                    <td className='pt-[7px]'>{item.quantity}</td>
                                    <td className='text-right py-[7px]'>
                                        {currencySymbol} {formatAmount(item?.unit_cost).replaceAll('₦', '')}
                                    </td>
                                    <td className='text-right py-[7px]'>
                                        {currencySymbol}{' '}
                                        {formatAmount(Number(item?.unit_cost) * Number(item?.quantity)).replaceAll(
                                            '₦',
                                            ''
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='mt-4 md:flex justify-end'>
                        <div className='md:w-[55%] text-[10px]'>
                            <div className='flex justify-between border-b-[0.5px] border-[#D7DAE0] pb-3'>
                                <h3 className='font-semibold'>Subtotal</h3>
                                <p className='font-medium text-gray-500'>
                                    {currencySymbol} {formatAmount(sub_total).replaceAll('₦', '')}
                                </p>
                            </div>
                            <div className='flex justify-between border-b-[0.5px] border-[#D7DAE0] pb-3 pt-2'>
                                <h3 className='font-semibold'>Tax</h3>
                                <p className='font-medium text-gray-500'>
                                    {currencySymbol} {formatAmount(formState?.tax).replaceAll('₦', '')}
                                </p>
                            </div>
                            <div className='flex justify-between pb-3 pt-2'>
                                <h3 className='font-semibold'>Total</h3>
                                <p className='font-medium'>
                                    {' '}
                                    {currencySymbol}{' '}
                                    {formatAmount(Number(formState?.tax ?? 0) + Number(sub_total)).replaceAll('₦', '')}
                                </p>
                            </div>
                            <div className='flex justify-between border-b-[1.5px] border-t-[1.5px] border-[#454ADE] py-[10px]'>
                                <h3 className='font-bold text-[#454ADE]'>Amount due</h3>
                                <p className='text-[#454ADE]'>
                                    {currencySymbol}{' '}
                                    {formatAmount(Number(formState?.tax ?? 0) + Number(sub_total)).replaceAll('₦', '')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-16 pb-5 border-b-[0.5px] border-[#D7DAE0]'>
                    <p className='font-semibold text-[10px] mb-1'>Thank you for the business!</p>
                    <div className='flex gap-2 items-center'>
                        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'>
                            <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M2 0C0.895431 0 0 0.89543 0 2V8C0 9.10457 0.89543 10 2 10H8C9.10457 10 10 9.10457 10 8V2C10 0.895431 9.10457 0 8 0H2ZM4.72221 2.95508C4.72221 2.7825 4.58145 2.64014 4.41071 2.66555C3.33092 2.82592 2.5 3.80797 2.5 4.99549V7.01758C2.5 7.19016 2.63992 7.33008 2.8125 7.33008H4.40971C4.58229 7.33008 4.72221 7.19016 4.72221 7.01758V5.6021C4.72221 5.42952 4.58229 5.2896 4.40971 5.2896H3.61115V4.95345C3.61115 4.41687 3.95035 3.96422 4.41422 3.82285C4.57924 3.77249 4.72221 3.63715 4.72221 3.4645V2.95508ZM7.5 2.95508C7.5 2.7825 7.35924 2.64014 7.18849 2.66555C6.1087 2.82592 5.27779 3.80797 5.27779 4.99549V7.01758C5.27779 7.19016 5.41771 7.33008 5.59029 7.33008H7.1875C7.36008 7.33008 7.5 7.19016 7.5 7.01758V5.6021C7.5 5.42952 7.36008 5.2896 7.1875 5.2896H6.38885V4.95345C6.38885 4.41695 6.72813 3.96422 7.19193 3.82285C7.35703 3.77249 7.5 3.63715 7.5 3.4645V2.95508Z'
                                fill='#838383'
                            />
                        </svg>
                        <p className='text-[#5E5E5E] text-[10px]'>
                            {formState?.footer ? (
                                formState?.footer
                            ) : (
                                <>Please pay within {daysToPay} days of receiving this invoice.</>
                            )}
                        </p>
                    </div>
                </div>
                <div className='flex items-center text-[10px] text-[#5E5E5E] font-medium justify-between mt-7 flex-wrap'>
                    <p className='w-1/2 lg:w-1/3 text-left'>{selectedAccount?.org?.org_name}</p>
                    <p className='border-l-[0.5px] border-r-[0.5px] border-[#DADCE0] w-1/3 text-center'>
                        Made with <span className='text-[#454ADE] font-semibold'>Bizpend</span>
                    </p>
                    <p className='w-1/3 text-right'>{selectedAccount?.org?.email}</p>
                </div>
            </div>
        </section>
    )
}

export default InvoicePreview
