/**
 * Each permission contains 3 parts. module_action_scope.
 *
 * e.g EXPENSE_CREATE_OWN means the user can create an expense for themselves.
 * EXPENSE_CREATE_ALL means the user can create an expense for any user.
 */

export enum ACCESS_PERMISSIONS {
    ACCESS_DASHBOARD = 'ACCESS_DASHBOARD',
    ACCESS_USER_PAYROLL = 'ACCESS_USER_PAYROLL',
    ACCESS_VAULTS = 'ACCESS_VAULTS',
    ACCESS_SETTINGS = 'ACCESS_SETTINGS',

    ACCOUNTING_CREATE_ALL = 'accounting_create_all',
    ACCOUNTING_READ_ALL = 'accounting_read_all',
    ACCOUNTING_UPDATE_ALL = 'accounting_update_all',
    ACCOUNTING_DELETE_ALL = 'accounting_delete_all',

    WALLET_CREATE_ALL = 'wallet_create_all',
    WALLET_READ_ALL = 'wallet_read_all',
    WALLET_UPDATE_ALL = 'wallet_update_all',
    WALLET_DELETE_ALL = 'wallet_delete_all',

    BANK_CREATE_ALL = 'bank_create_all',
    BANK_READ_ALL = 'bank_read_all',
    BANK_UPDATE_ALL = 'bank_update_all',
    BANK_DELETE_ALL = 'bank_delete_all',

    BANK_CREATE_OWN = 'bank_create_own',
    BANK_READ_OWN = 'bank_read_own',
    BANK_UPDATE_OWN = 'bank_update_own',
    BANK_DELETE_OWN = 'bank_delete_own',

    INVOICE_CREATE_ALL = 'invoice_create_all',
    INVOICE_READ_ALL = 'invoice_read_all',
    INVOICE_UPDATE_ALL = 'invoice_update_all',
    INVOICE_DELETE_ALL = 'invoice_delete_all',

    EXPENSE_CREATE_ALL = 'expense_create_all',
    EXPENSE_READ_ALL = 'expense_read_all',
    EXPENSE_UPDATE_ALL = 'expense_update_all',
    EXPENSE_DELETE_ALL = 'expense_delete_all',
    EXPENSE_REIMBURSE_ALL = 'expense_reimburse_all',

    EXPENSE_CREATE_OWN = 'expense_create_own',
    EXPENSE_READ_OWN = 'expense_read_own',
    EXPENSE_UPDATE_OWN = 'expense_update_own',
    EXPENSE_DELETE_OWN = 'expense_delete_own',
    EXPENSE_REIMBURSE_OWN = 'expense_reimburse_own',

    BUDGET_CREATE_ALL = 'budget_create_all',
    BUDGET_READ_ALL = 'budget_read_all',
    BUDGET_UPDATE_ALL = 'budget_update_all',
    BUDGET_DELETE_ALL = 'budget_delete_all',

    PAYROLL_CREATE_ALL = 'payroll_create_all',
    PAYROLL_READ_ALL = 'payroll_read_all',
    PAYROLL_UPDATE_ALL = 'payroll_update_all',
    PAYROLL_DELETE_ALL = 'payroll_delete_all',

    PAYROLL_CREATE_OWN = 'payroll_create_own',
    PAYROLL_READ_OWN = 'payroll_read_own',
    PAYROLL_UPDATE_OWN = 'payroll_update_own',
    PAYROLL_DELETE_OWN = 'payroll_delete_own',

    PAYMENTPROFILE_CREATE_ALL = 'paymentprofile_create_all',
    PAYMENTPROFILE_READ_ALL = 'paymentprofile_read_all',
    PAYMENTPROFILE_UPDATE_ALL = 'paymentprofile_update_all',
    PAYMENTPROFILE_DELETE_ALL = 'paymentprofile_delete_all',

    PAYMENTPROFILE_CREATE_OWN = 'paymentprofile_create_own',
    PAYMENTPROFILE_READ_OWN = 'paymentprofile_read_own',
    PAYMENTPROFILE_UPDATE_OWN = 'paymentprofile_update_own',
    PAYMENTPROFILE_DELETE_OWN = 'paymentprofile_delete_own',

    CONTRACT_CREATE_ALL = 'contract_create_all',
    CONTRACT_READ_ALL = 'contract_read_all',
    CONTRACT_UPDATE_ALL = 'contract_update_all',
    CONTRACT_DELETE_ALL = 'contract_delete_all',

    CONTRACT_CREATE_OWN = 'contract_create_own',
    CONTRACT_READ_OWN = 'contract_read_own',
    CONTRACT_UPDATE_OWN = 'contract_update_own',
    CONTRACT_DELETE_OWN = 'contract_delete_own',

    VENDOR_CREATE_ALL = 'vendor_create_all',
    VENDOR_READ_ALL = 'vendor_read_all',
    VENDOR_UPDATE_ALL = 'vendor_update_all',
    VENDOR_DELETE_ALL = 'vendor_delete_all',

    CUSTOMER_CREATE_ALL = 'customer_create_all',
    CUSTOMER_READ_ALL = 'customer_read_all',
    CUSTOMER_UPDATE_ALL = 'customer_update_all',
    CUSTOMER_DELETE_ALL = 'customer_delete_all',

    PEOPLE_CREATE_ALL = 'people_create_all',
    PEOPLE_READ_ALL = 'people_read_all',
    PEOPLE_UPDATE_ALL = 'people_update_all',
    PEOPLE_DELETE_ALL = 'people_delete_all',

    BILL_CREATE_ALL = 'bill_create_all',
    BILL_READ_ALL = 'bill_read_all',
    BILL_UPDATE_ALL = 'bill_update_all',
    BIL_DELETE_ALL = 'bill_delete_all',

    TRANSACTION_CREATE_ALL = 'transaction_create_all',
    TRANSACTION_READ_ALL = 'transaction_read_all',
    TRANSACTION_UPDATE_ALL = 'transaction_update_all',
    TRANSACTION_DELETE_ALL = 'transaction_delete_all',

    TRANSACTION_CREATE_OWN = 'transaction_create_own',
    TRANSACTION_READ_OWN = 'transaction_read_own',
    TRANSACTION_UPDATE_OWN = 'transaction_update_own',
    TRANSACTION_DELETE_OWN = 'transaction_delete_own',

    VAULT_CREATE_ALL = 'vault_create_all',
    VAULT_READ_ALL = 'vault_read_all',
    VAULT_UPDATE_ALL = 'vault_update_all',
    VAULT_DELETE_ALL = 'vault_delete_all',

    LEAVE_CREATE_ALL = 'leave_create_all',
    LEAVE_DELETE_ALL = 'leave_delete_all',
    LEAVE_READ_ALL = 'leave_read_all',
    LEAVE_UPDATE_ALL = 'leave_update_all',
    LEAVE_CREATE_OWN = 'leave_create_own',
    LEAVE_DELETE_OWN = 'leave_delete_own',
    LEAVE_READ_OWN = 'leave_read_own',
    LEAVE_UPDATE_OWN = 'leave_update_own',
    LEAVESETTINGS_CREATE_ALL = 'leave_settings_create_all',
    LEAVESETTINGS_DELETE_ALL = 'leave_settings_delete_all',
    LEAVESETTINGS_READ_ALL = 'leave_settings_read_all',
    LEAVESETTINGS_UPDATE_ALL = 'leave_settings_update_all',
}
export const ADMIN_EXPENSE_PERMISSIONS = [
    ACCESS_PERMISSIONS.EXPENSE_CREATE_ALL,
    ACCESS_PERMISSIONS.EXPENSE_DELETE_ALL,
    ACCESS_PERMISSIONS.EXPENSE_READ_ALL,
    ACCESS_PERMISSIONS.EXPENSE_UPDATE_ALL,
]
export const ADMIN_TRANSACTION_PERMISSIONS = [
    ACCESS_PERMISSIONS.TRANSACTION_CREATE_ALL,
    ACCESS_PERMISSIONS.TRANSACTION_DELETE_ALL,
    ACCESS_PERMISSIONS.TRANSACTION_READ_ALL,
    ACCESS_PERMISSIONS.TRANSACTION_UPDATE_ALL,
]

export const ADMIN_PAYROLL_PERMISSIONS = [
    ACCESS_PERMISSIONS.PAYROLL_CREATE_ALL,
    ACCESS_PERMISSIONS.PAYROLL_DELETE_ALL,
    ACCESS_PERMISSIONS.PAYROLL_READ_ALL,
    ACCESS_PERMISSIONS.PAYROLL_UPDATE_ALL,
]

export const ADMIN_CONTRACT_PERMISSIONS = [
    ACCESS_PERMISSIONS.CONTRACT_CREATE_ALL,
    ACCESS_PERMISSIONS.CONTRACT_DELETE_ALL,
    ACCESS_PERMISSIONS.CONTRACT_READ_ALL,
    ACCESS_PERMISSIONS.CONTRACT_UPDATE_ALL,
]

export const ADMIN_ACCOUNTS_PERMISSIONS = [
    ACCESS_PERMISSIONS.BANK_CREATE_ALL,
    ACCESS_PERMISSIONS.BANK_DELETE_ALL,
    ACCESS_PERMISSIONS.BANK_READ_ALL,
    ACCESS_PERMISSIONS.BANK_UPDATE_ALL,
]

export const ADMIN_BILLS_PERMISSIONS = [
    ACCESS_PERMISSIONS.BILL_CREATE_ALL,
    ACCESS_PERMISSIONS.BIL_DELETE_ALL,
    ACCESS_PERMISSIONS.BILL_READ_ALL,
    ACCESS_PERMISSIONS.BILL_UPDATE_ALL,
]

export const ADMIN_LEAVE_PERMISSIONS = [
    ACCESS_PERMISSIONS.LEAVE_CREATE_ALL,
    ACCESS_PERMISSIONS.LEAVE_DELETE_ALL,
    ACCESS_PERMISSIONS.LEAVE_READ_ALL,
    ACCESS_PERMISSIONS.LEAVE_UPDATE_ALL,

    ACCESS_PERMISSIONS.LEAVE_CREATE_OWN,
    ACCESS_PERMISSIONS.LEAVE_DELETE_OWN,
    ACCESS_PERMISSIONS.LEAVE_READ_OWN,
    ACCESS_PERMISSIONS.LEAVE_UPDATE_OWN,

    ACCESS_PERMISSIONS.LEAVESETTINGS_CREATE_ALL,
    ACCESS_PERMISSIONS.LEAVESETTINGS_DELETE_ALL,
    ACCESS_PERMISSIONS.LEAVESETTINGS_READ_ALL,
    ACCESS_PERMISSIONS.LEAVESETTINGS_UPDATE_ALL,
]
