import { DedicatedBankAccountType } from '@/types/accounts'
import { createQuery } from '../api/query'
import { QueryBankAccountsRes } from '../accounts/types'
import { bankAccounts } from './mutations'

export const accountId = (id: string) => ['bank-account', id]
export const useGetDedicatedBankAccounts = createQuery<QueryBankAccountsRes>({
    url: '/bank-accounts',
    key: bankAccounts,
})

export const useGetDedicatedBankAccount = createQuery<DedicatedBankAccountType>({
    url: '/bank-account',
    key: accountId,
})
