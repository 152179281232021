import { Button } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useMadeOfflinePayment } from '@/services/invoice/mutations'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import RedirectModal from './RedirectModal'
import { Invoice } from '@/types/invoice'
import CheckoutPaymentModal from '@/components/Modals/Invoice/CheckoutPaymentModal'

export default function OfflineInvoiceCheckout({ invoiceDetails }: { invoiceDetails?: Invoice }) {
    const { invoice_id } = useParams()
    const [showRedirect, setShowRedirect] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [amount, setAmount] = useState('')

    const { mutate: offlinePaymentFn, isLoading } = useMadeOfflinePayment({
        queryParams: {
            invoice_id: invoice_id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Payment successful', state: 'success' })
            setShowRedirect(true)
            setOpenModal(false)
        },
    })

    const handlePayment = async () => {
        if (!(invoiceDetails?.status === 'PAID')) {
            offlinePaymentFn({})
        } else {
            toastHandler({ message: 'Invoice had previously been paid', state: 'error' })
        }
    }

    return (
        <div className='border border-[#DADCE0] grid place-items-center p-4 rounded-2xl'>
            <p className='text-[#5E5E5E] my-4'> Offline payments mean you intend on paying cash to settle this bill.</p>
            <div className='space-y-3 my-4 mb-8 w-full'>
                <div className='flex justify-between'>
                    <h5 className='text-gray7 text-sm'>Bank name</h5>
                    <p className='font-medium'>{invoiceDetails?.payout_bank_account?.bank_name}</p>
                </div>
                <div className='flex justify-between'>
                    <h5 className='text-gray7 text-sm'>Account name</h5>
                    <p className='font-medium'>{invoiceDetails?.payout_bank_account?.account_name}</p>
                </div>
                <div className='flex justify-between'>
                    <h5 className='text-gray7 text-sm'>Account number </h5>
                    <p className='font-medium'>{invoiceDetails?.payout_bank_account?.account_number}</p>
                </div>
            </div>
            <Button className='bg-[#19C37D] hover:bg-[#19C37D]/80' onClick={() => setOpenModal(true)}>
                {invoiceDetails?.status === 'PAID' ? 'Invoice has already been paid' : 'Make payment now'}
            </Button>
            {showRedirect && <RedirectModal />}

            {openModal && (
                <CheckoutPaymentModal
                    loading={isLoading}
                    handleBtnClick={() => handlePayment()}
                    invoiceDetails={invoiceDetails}
                    closeModal={() => setOpenModal(false)}
                    amount={amount}
                    setAmount={setAmount}
                />
            )}
        </div>
    )
}
