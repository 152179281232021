import { Button } from '@/components'

import { useMutationDeleteApprovalPolicy } from '@/services/settings/mutations'
import { toastHandler } from '@/components/utils/Toast'
import ModalWrapper from '../ModalWrapper'
interface DeletePolicyPromptProps {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    policyId?: string
}

export default function DeletePolicyPrompt({ closeModal, policyId }: DeletePolicyPromptProps) {
    const { mutate: deleteApprovalPolicy, isLoading: isPolicyDeleted } = useMutationDeleteApprovalPolicy({
        queryParams: {
            policy_id: policyId as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Policy deleted successfully', state: 'success' })

            closeModal(false)
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <div className=' w-[97vw] sm:w-[30rem] p-5 '>
                <h1 className='text-[#CF491E] text-xl font-semibold mb-3'>Attention</h1>
                <p className='text-gray7 font-medium text-sm mb-5  mx-auto leading-7'>
                    Deleting the only approval policy restores the default approval policy. Any admin will be able to
                    approve and reimburse expenses. Are you sure you want to proceed?
                </p>
                {/* Buttons */}
                <div className=' flex justify-end'>
                    <Button
                        type='button'
                        className='bg-transparent text-[#202020] px-5 py-2 h-[2rem] rounded-lg text-xs hover:bg-transparent min-w-[100px]'
                        onClick={() => closeModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E text-white px-6 h-[2rem] rounded-lg text-[14px] min-w-[100px] py-2'
                        onClick={() => deleteApprovalPolicy({ policyId })}
                        loading={isPolicyDeleted}
                    >
                        Yes, delete this policy
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
