import CompanyCompliance from '@/modules/dashboard/Settings/partials/CompanyCompliance/CompanyCompliance'
import CompanyNotifications from '@/modules/dashboard/Settings/partials/CompanyNotifications/CompanyNotifications'
import { CompanyPrivacyPolicy } from '@/modules/dashboard/Settings/partials/CompanyPrivacyPolicy/CompanyPrivacyPolicy'
import CompanyProfileSettings from '@/modules/dashboard/Settings/partials/CompanyProfileSettings/CompanyProfileSettings'
import CompanySecuritySettings from '@/modules/dashboard/Settings/partials/CompanySecuritySettings/CompanySecuritySettings'
import CompanySettingsContainer from '@/modules/dashboard/Settings/partials/CompanySettings/CompanySettingsContainer'
import { CompanyTOS } from '@/modules/dashboard/Settings/partials/CompanyTOS/CompanyTOS'
import HelpAndSupport from '@/modules/dashboard/Settings/partials/HelpAndSupport/HelpAndSupport'
import PricingPage from '@/modules/dashboard/Settings/partials/PlansAndPricing'
import { PlanPaymentHistory } from '@/modules/dashboard/Settings/partials/PlansAndPricing/components/PlanPaymentHistory'
import Settings from '@/modules/dashboard/Settings/Settings'
import UserBankSettings from '@/modules/dashboard/UserSettings/partials/UserBankSettings'
import UserProfileSettings from '@/modules/dashboard/UserSettings/partials/UserProfileSettings'
import UserSecuritySettings from '@/modules/dashboard/UserSettings/partials/UserSecuritySettings'
import UserSettingsContainer from '@/modules/dashboard/UserSettings/partials/UserSettingsContainer'
import UserSettings from '@/modules/dashboard/UserSettings/UserSettings'
import { Route } from 'react-router-dom'

const settingsRouteConst = {
    user: {
        settings: 'settings/user',
        settingsSubroute: 'settings/user/:settings_subRoute',
    },
    company: {
        settingsSubroute: 'settings/:settings_subRoute',
        paymentHistory: 'settings/company/payment/history',
        companySubroute: 'settings/company/:company_subRoute',
    },
}

export const useUserSettingsRoute = () => {
    return (
        <>
            <Route path={settingsRouteConst.user.settings} element={<UserSettings />} />
            <Route
                path={settingsRouteConst.user.settingsSubroute}
                element={
                    <UserSettingsContainer>
                        <UserProfileSettings />
                        <UserSecuritySettings />
                        <UserBankSettings />
                        <HelpAndSupport />
                        <CompanyTOS />
                        <CompanyPrivacyPolicy />
                    </UserSettingsContainer>
                }
            />
        </>
    )
}

export const useCompanySettingsRoute = () => {
    return (
        <>
            <Route path={settingsRouteConst.company.settingsSubroute} element={<Settings />} />
            <Route path={settingsRouteConst.company.paymentHistory} element={<PlanPaymentHistory />} />
            <Route
                path={settingsRouteConst.user.settingsSubroute}
                element={
                    <UserSettingsContainer>
                        <UserProfileSettings />
                        <UserSecuritySettings />
                        <UserBankSettings />
                        <HelpAndSupport />
                        <CompanyTOS />
                        <CompanyPrivacyPolicy />
                    </UserSettingsContainer>
                }
            />
            <Route
                path={settingsRouteConst.company.companySubroute}
                element={
                    <CompanySettingsContainer>
                        <CompanyProfileSettings />
                        <CompanyCompliance />
                        <CompanySecuritySettings />
                        <PricingPage />
                        <HelpAndSupport />
                        <CompanyNotifications />
                        <CompanyTOS />
                        <CompanyPrivacyPolicy />
                    </CompanySettingsContainer>
                }
            />
        </>
    )
}
