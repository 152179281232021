import { createMutation } from '../api/mutation'
import { keyQueryExpenseApprovalPolicies, keyQueryOrgSetting } from './queries'

export const useMutationCreateApprovalPolicy = createMutation({
    url: '/create-expense-approval-policy',
    keysToRefetch: [keyQueryExpenseApprovalPolicies],
    method: 'POST',
})

export const useMutationDeleteApprovalPolicy = createMutation({
    url: '/delete-expense-approval-policy',
    keysToRefetch: [keyQueryExpenseApprovalPolicies],
    method: 'DELETE',
})

export const useMutationUpdateApprovalPolicy = createMutation({
    url: '/update-expense-approval-policy',
    keysToRefetch: [keyQueryExpenseApprovalPolicies],
    method: 'PATCH',
})
export const useMutationToggleApprovalPolicyStatus = createMutation({
    url: '/toggle-expense-approval-status',
    keysToRefetch: [keyQueryExpenseApprovalPolicies],
    method: 'PATCH',
})

export const useMutationGrantOwnerAccess = createMutation({
    url: '/grant-access-to-users',
    keysToRefetch: [keyQueryOrgSetting],
    method: 'PATCH',
})
export const useMutationRevokeOwnerAccess = createMutation({
    url: '/revoke-access',
    keysToRefetch: [keyQueryOrgSetting],
    method: 'PATCH',
})
