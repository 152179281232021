import { OrgType } from '@/types/org'
import { createQuery } from '../api/query'
import { QueryAllOrgs } from './types'

export const getAllOrgsKey = ['get-orgs-key']
export const getOrgKey = (id: string) => ['get-orgs-key', id]

export const useGetAllOrgs = createQuery<QueryAllOrgs>({
    url: '/get-orgs',
    key: getAllOrgsKey,
})

export const useGetOrg = createQuery<OrgType>({
    url: '/get-org',
    key: getOrgKey,
})
