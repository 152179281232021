import { Budget } from '@/types/budget'
import { isBefore, startOfToday } from 'date-fns'

export const ComputePercent = (budget: Budget) => {
    const percentage = 100 - (Number(budget?.available_amount) / Number(budget?.allocated_amount)) * 100
    const color = percentage > 79 ? '#CF491E' : percentage > 59 ? '#DEB145' : '#19C37D'
    const bgColor = percentage > 79 ? '#FFDBD6' : percentage > 59 ? '#FDFCDB' : '#EFFFED'

    return { color, bgColor, percentage }
}

export const handleCheckIfBudgetIsExpired = (budget: Budget) => {
    if (budget?.reset === 'one-off') {
        const today = startOfToday()
        return isBefore(new Date(budget?.end_date), today)
    }
    return false
}
