import { createMutation } from '../api/mutation'

export const bankAccounts = ['bank-account']
export const useCreateDepositAccount = createMutation({
    url: '/bank-accounts/create',
    method: 'POST',
    keysToRefetch: [bankAccounts],
})

export const useUpdateDepositAccount = createMutation({
    url: '/bank-account',
    method: 'PATCH',
    keysToRefetch: [bankAccounts],
})

export const useTransferInternally = createMutation({
    url: '/transfers/internal',
    method: 'POST',
    keysToRefetch: [bankAccounts, ['bank-accounts']],
})

export const useRefreshBankBalance = createMutation({
    url: '/bank-account/sync-balance',
    method: 'POST',
    keysToRefetch: [bankAccounts],
})

export const useLinkAccount = createMutation({
    url: '/authorize-mono-account',
    method: 'POST',
    keysToRefetch: [bankAccounts],
})
