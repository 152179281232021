import { Button } from '@/components'

import { toastHandler } from '@/components/utils/Toast'
import ModalWrapper from '../../ModalWrapper'
import { useMutateFlagTransaction } from '@/services/transactions/mutations'

interface FlagTransactionProps {
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    trasactionId: string
}

export default function FlagTransaction({ setIsDeleteDialogOpen, trasactionId }: FlagTransactionProps) {
    const { mutate: flagTransactionFn, isLoading: isFlaggingTransaction } = useMutateFlagTransaction({
        queryParams: {
            transaction_id: trasactionId,
        },
        onSuccess: () => {
            setIsDeleteDialogOpen(false)
            toastHandler({ message: 'Transaction Flagged', state: 'success' })
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>Are you sure you want to flag this trasaction </p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isFlaggingTransaction}
                        onClick={() => {
                            flagTransactionFn({})
                        }}
                    >
                        Yes, Flag it
                    </Button>
                    <button
                        type='button'
                        className='min-h-[50px] px-5 mx-3'
                        onClick={() => setIsDeleteDialogOpen(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}
