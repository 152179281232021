import { cn } from '@/lib/styling'
import * as SelectPrimitive from '@radix-ui/react-select'
import { VariantProps, cva } from 'class-variance-authority'
import { Check, ChevronDown } from 'lucide-react'
import * as React from 'react'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

const selectTriggerVariants = cva(
    `flex w-full items-center justify-between rounded-md  bg-transparent text-sm data-[placeholder]:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-slate-400`,
    {
        variants: {
            variant: {
                default: 'py-2 px-3 border border-gray1 text-black',
                new: 'py-2 px-3 border border-gray1 text-slate-400',
                // default2: 'py-2 px-3 border border-gray1 text-slate-400',
                lg: 'py-4 px-5 h-[72px] border border-base_gray_100 text-black', // Modify this as needed
                selected: 'py-2 px-3 border border-gray1 text-black', // Style when an option is selected
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    }
)

export interface SelectTriggerProps
    extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>,
        VariantProps<typeof selectTriggerVariants> {}

const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, SelectTriggerProps>(
    ({ className, variant, children, ...props }, ref) => {
        return (
            <SelectPrimitive.Trigger ref={ref} className={cn(selectTriggerVariants({ variant, className }))} {...props}>
                {children}
                <ChevronDown className='h-4 w-4 opacity-50' />
            </SelectPrimitive.Trigger>
        )
    }
)
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectContent = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Content
        position='popper'
        ref={ref}
        className={cn(
            'animate-in pointer-events-auto fade-in-80 relative z-[100] min-w-[8rem] w-[19.5rem] overflow-hidden rounded-md border border-gray1 bg-white text-slate-700 shadow-md',
            className
        )}
        asChild
        {...props}
    >
        <SelectPrimitive.Viewport className='p-1'>{children}</SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn('py-1.5 pr-2 pl-8 text-sm font-semibold text-gray1', className)}
        {...props}
    />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            'w-full relative flex cursor-default select-none items-center rounded-sm py-1.5 px-2 text-sm font-medium outline-none focus:bg-slate-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
            className
        )}
        {...props}
    >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>

        <span className='absolute right-2 flex h-3.5 w-3.5 items-center justify-center'>
            <SelectPrimitive.ItemIndicator>
                <Check className='h-4 w-4' />
            </SelectPrimitive.ItemIndicator>
        </span>
    </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-slate-100', className)} {...props} />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectSeparator, SelectTrigger, SelectValue }
