import React, { createContext, useEffect, useState } from 'react'
import { ExpenseType, formProps } from '@/types/expense'
import { Pagination } from '@/types/utils'
import { advancedFilteringProps } from '@/modules/dashboard/Expenses/const'
import { EmployeeType } from '@/types/org'
import { CategoryType } from '@/types/categories'

type SortColumn = { id: string | undefined; desc: boolean | undefined }

export type defaultExpenseProps = {
    expenses: ExpenseType[] | []
    pagination_details?: Pagination
    setExpenses: React.Dispatch<React.SetStateAction<ExpenseType[] | []>>
    sort: SortColumn | undefined
    setSort: React.Dispatch<React.SetStateAction<SortColumn | undefined>>
    setPaginationDetails: React.Dispatch<React.SetStateAction<Pagination | undefined>>
    selectedExpense: ExpenseType | undefined
    setSelectedExpense: React.Dispatch<React.SetStateAction<ExpenseType | undefined>>
    categories: CategoryType[] | []
    setCategories: React.Dispatch<React.SetStateAction<CategoryType[] | []>>
    filterExpense: formProps
    setFilterExpense: React.Dispatch<React.SetStateAction<formProps>>
    orgMembers: EmployeeType[]
    setOrgMembers: React.Dispatch<React.SetStateAction<EmployeeType[]>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    moreDetailsPage: boolean
    setMoreDetailsPage: React.Dispatch<React.SetStateAction<boolean>>
    editModal: boolean
    setEditModal: React.Dispatch<React.SetStateAction<boolean>>
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    headerText: string
    setHeaderText: React.Dispatch<React.SetStateAction<string>>
}

const defaultExpenseState: defaultExpenseProps = {
    expenses: [],
    selectedExpense: undefined,
    pagination_details: undefined,
    setPaginationDetails: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
    setExpenses: () => undefined,
    sort: undefined,
    setSort: () => undefined,
    setSelectedExpense: () => undefined,
    categories: [],
    setCategories: () => undefined,
    filterExpense: advancedFilteringProps,
    setFilterExpense: () => undefined,
    orgMembers: [],
    setOrgMembers: () => undefined,
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    moreDetailsPage: false,
    setMoreDetailsPage: () => undefined,
    editModal: false,
    setEditModal: () => undefined,
    showModal: false,
    setShowModal: () => undefined,
    headerText: 'Dashboard',
    setHeaderText: () => undefined,
}

export const ExpenseContext = createContext(defaultExpenseState)

export const ExpenseContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [sort, setSort] = useState<SortColumn>()
    const [showModal, setShowModal] = useState(false)
    const [expenses, setExpenses] = useState<ExpenseType[] | []>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pagination_details, setPaginationDetails] = useState<Pagination | undefined>()
    const [pageNumberClicked, setPageNumberClicked] = useState(false)
    const [selectedExpense, setSelectedExpense] = useState<ExpenseType | undefined>()
    const [categories, setCategories] = useState<CategoryType[] | []>([])
    const [filterExpense, setFilterExpense] = useState<formProps>(advancedFilteringProps)
    const [orgMembers, setOrgMembers] = useState<EmployeeType[]>([])
    const [moreDetailsPage, setMoreDetailsPage] = useState(true)
    const [editModal, setEditModal] = useState(false)
    const [headerText, setHeaderText] = useState<string>('Dashboard')

    useEffect(() => {
        setSelectedExpense(selectedExpense)
    }, [selectedExpense])

    const value = {
        pageNumber,
        setExpenses,
        expenses,
        setPageNumber,
        pagination_details,
        setPaginationDetails,
        sort,
        setSort,
        selectedExpense,
        setSelectedExpense,
        setCategories,
        categories,
        filterExpense,
        setFilterExpense,
        orgMembers,
        setOrgMembers,
        pageNumberClicked,
        setPageNumberClicked,
        moreDetailsPage,
        setMoreDetailsPage,
        editModal,
        setEditModal,
        showModal,
        setShowModal,
        headerText,
        setHeaderText,
    }
    return <ExpenseContext.Provider value={value}>{children}</ExpenseContext.Provider>
}
