import SelectWIthChip from '@/components/SelectWithChip'
import { useAuthContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { capitalizeText } from '@/utils/string'
import { useEffect, useState } from 'react'
import { plans } from './NewBenefit2'
import { formatAmount } from '@/utils/money'

type ChipProps = {
    label: string
    value: string
}

type Step3Props = {
    selectedPlan: string
    setSelectedMembers: React.Dispatch<React.SetStateAction<ChipProps[]>>
    selectedMembers: ChipProps[]
}

const NewBenefitStep3 = ({ selectedPlan, setSelectedMembers, selectedMembers }: Step3Props) => {
    const [orgMembersData, setOrgMembersData] = useState<{ label: string; value: string }[]>([])

    const { selectedAccount } = useAuthContext()

    const { data } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    useEffect(() => {
        if (data) {
            setOrgMembersData(
                data.map(member => ({
                    label: `${capitalizeText(member?.user?.first_name)} ${capitalizeText(member?.user?.last_name)}`,
                    value: member?.id,
                }))
            )
        }
    }, [data])
    return (
        <div className='p-4 md:p-8 md:px-12'>
            <div className='mb-5'>
                <h1 className='text-xl font-semibold text-[#202020]'>Select beneficiaries</h1>
                <p className='text-base font-medium text-[#5E5E5E]'>Beneficiaries are users on your payroll list</p>
            </div>
            <div className='bg-[#F9F9F9] rounded-xl p-5 flex items-end justify-between mb-8'>
                {plans
                    .filter(plan => plan?.id === selectedPlan)
                    .map(sPlan => (
                        <div className=''>
                            <h1 className='text-xl font-semibold text-[#202020] mb-3'>{sPlan.name}</h1>
                            <div className='bg-[#EFEFF9] rounded-full py-2 px-3 text-brand text-xs w-[200px]'>
                                <span className='text-xl font-semibold'>{formatAmount(sPlan?.price * 100)}</span> / year
                                / life
                            </div>
                        </div>
                    ))}

                <p className='font-semibold text-[#5E5E5E]'>Selected plan</p>
            </div>
            <div className='flex flex-col gap-4 mb-10'>
                <label htmlFor='firstName' className='text-[#202020] text-[14px] font-medium'>
                    Beneficiaries <span className=''>*</span>
                </label>
                <div className=''>
                    <SelectWIthChip
                        options={orgMembersData}
                        placeholder='Click to see users'
                        onChange={members_details => {
                            setSelectedMembers(members_details)
                        }}
                        value={selectedMembers}
                    />
                </div>
            </div>
        </div>
    )
}

export default NewBenefitStep3
