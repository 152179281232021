import { Customers } from '@/types/customers'
import { createQuery } from '../api/query'
import { QueryOrgCustomers } from './types'

export const orgCustomersKey = ['/org-customers']
export const topOrgCustomersKey = ['/top-org-customers']
export const orgCustomerKey = (arg: string) => ['/org-customers', arg]

export const useGetOrgCustomers = createQuery<QueryOrgCustomers>({
    url: '/customers',
    key: orgCustomersKey,
})

export const useGetOrgCustomer = createQuery<Customers>({
    url: '/customer',
    key: orgCustomerKey,
})

export const useGetTopOrgCustomer = createQuery<Customers[]>({
    url: '/top-invoice-customer',
    key: topOrgCustomersKey,
})
