import { Button } from '@/components'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext, useVendorContext } from '@/context'
// import downArrow from '@/assets/down-arrow.svg'
import { ArrowLeft } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetOrgCustomer } from '@/services/customers/queries'
import CreateNewCustomer from '@/components/Modals/Vendors/CreateNewCustomer/CreateNewCustomer'
import { Customers } from '@/types/customers'
import DeleteCustomer from '@/components/Modals/Vendors/DeleteCustomer/DeleteCustomer'
import LinkedTransactionsTable from './LinkedTransactionsTable'

export default function CustomerDetails() {
    const [openModal, setOpenModal] = useState(false)
    const [deletePrompt, setDeletePrompt] = useState(false)
    const navigate = useNavigate()
    const { selectedCustomer } = useVendorContext()
    const { selectedAccount } = useAuthContext()
    const { customer_id } = useParams()

    const { data, isLoading } = useGetOrgCustomer({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            id: customer_id as string,
        },
    })
    const customerDetails = useMemo(
        () =>
            data ?? {
                ...selectedCustomer,
            },
        [data, selectedCustomer]
    ) as Customers
    const { email, name, contact, address, city, country, state, zipcode } = customerDetails

    const customer_details = [
        { name: 'Customer name', desc: name },

        { name: 'Email address', desc: email },
        {
            name: 'First name',
            desc: `${contact?.first_name} `,
        },
        {
            name: 'Last name',
            desc: `${contact?.last_name}`,
        },
        { name: 'Address', desc: address },
        { name: 'City', desc: city },
        { name: 'Country', desc: country },
        { name: 'Phone number', desc: contact?.phone },
        { name: 'Zipcode', desc: zipcode },
        { name: 'State', desc: state },
    ]

    if (isLoading && !selectedCustomer) return <ProgressBar />
    return (
        <section className='mx-[1.4rem] lg:mx-[2.9rem] p-3 pt-2 pl-0'>
            <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                <ArrowLeft />
                back
            </div>
            <section className=' bg-white rounded-xl p-4 lg:p-7 py-6 '>
                <div className='border-b border-[#DADCE0] pb-3 flex items-center justify-between'>
                    <h2 className=' font-semibold text-xl'>{name ?? selectedCustomer?.name}</h2>
                    {/* <span className='px-4 py-2 cursor-pointer flex flex-col lg:flex-row items-end lg:items-center text-right gap-2 hover:bg-[#f3f3f3]'>
                        <div className='h-[32px] w-[32px] border border-[#DADCE0] rounded-[5px] flex items-center justify-center p-1 '>
                            <img src={downArrow} alt='button' />
                        </div>
                        Download customer details
                    </span> */}
                </div>
                <div className='mt-5'>
                    <h4 className='text-md font-semibold'>Description</h4>
                    <span>{customerDetails.description ?? 'N/A'}</span>
                </div>

                <div className='flex flex-wrap max-w-[60rem] gap-x-[5rem] mt-5 gap-y-[1.3rem]'>
                    {customer_details.map((customer, idx) => {
                        const { desc, name } = customer
                        return (
                            <div className='flex flex-col w-[10rem]' key={idx}>
                                <h4 className='text-md font-semibold'>{name}</h4>
                                <span>{desc ?? 'N/A'}</span>
                            </div>
                        )
                    })}
                </div>
                <div className='flex flex-col lg:flex-row justify-end max-w-[53rem] gap-4 mt-7'>
                    <Button
                        className='bg-transparent hover:bg-transparent text-[#202020]'
                        onClick={() => setOpenModal(true)}
                        variant={'outline'}
                    >
                        Edit
                    </Button>
                    <Button
                        variant={'error'}
                        onClick={() => {
                            setDeletePrompt(true)
                        }}
                    >
                        Delete Customer
                    </Button>
                </div>
            </section>
            {openModal && (
                <CreateNewCustomer update={true} customerDetails={customerDetails} setCloseModal={setOpenModal} />
            )}
            {deletePrompt && (
                <DeleteCustomer customer={customerDetails} closeModal={setDeletePrompt} fromDetails={true} />
            )}

            <section className='bg-white rounded-xl p-3 my-10 mb-28'>
                <h4 className=' border-b border-gray8 p-5 font-medium'>Linked transaction(s)</h4>
                <div className='overflow-y-scroll'>
                    <LinkedTransactionsTable />
                </div>
            </section>
        </section>
    )
}
