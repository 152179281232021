import { BizpendAccounts, CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useTransferInternally } from '@/services/accounts/mutations'
import { useGetDedicatedBankAccounts } from '@/services/accounts/queries'
import { DedicatedBankAccountType } from '@/types/accounts'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TbCurrencyNaira } from 'react-icons/tb'
import ModalWrapper from '../ModalWrapper'

type Props = {
    closeModal: () => void
    account: DedicatedBankAccountType
}

type FormType = {
    receiving_account: string
    amount: string
}
export default function TransferModal({ closeModal, account }: Props) {
    const { selectedAccount } = useAuthContext()
    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
    } = useForm<FormType>({})

    const { data } = useGetDedicatedBankAccounts({
        queryParams: { entity_id: selectedAccount?.org?.id as string },
    })
    const bankAccounts = useMemo(() => data ?? ([] as DedicatedBankAccountType[]), [data])

    const { mutate: transferFn, isLoading } = useTransferInternally({
        onSuccess: () => {
            toastHandler({ message: 'Transaction processing', state: 'success' })
            closeModal()
        },
    })

    const onSubmit = (values: FormType) => {
        transferFn({
            amount: Number(values.amount) / 100,
            destination_account_id: values?.receiving_account,
            source_account_id: account?.id,
        })
    }
    const receivingAccount = watch('receiving_account')

    return (
        <ModalWrapper>
            <form onSubmit={handleSubmit(onSubmit)} className=' min-h-[18rem] w-[25rem] md:w-[33rem] overflow-scroll'>
                <div className='flex items-center border-b border-[#DADCE0] p-3 px-5 justify-between'>
                    <h2 className='text-center text-lg font-medium opacity-80'>Make transfer</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='mx-5 mt-4'>
                    <fieldset className='mt-5 flex flex-col  flex-1 '>
                        <label className='mb-1.5 ' htmlFor='funding_method'>
                            Transfer funds from this account
                        </label>
                        <div className='flex items-center justify-between border border-gray4 rounded-lg p-3 px-4 py-2'>
                            <div className='flex gap-3 items-center'>
                                <BizpendAccounts className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`} />
                                <div className='text-sm  flex flex-col gap-1'>
                                    <span>
                                        {account?.nickname ?? account?.account_name}
                                        {!account.id && 'Main Wallet'}
                                    </span>
                                    {account.id ? (
                                        <span className='text-gray7 font-medium text-xs'>
                                            {formatAmount(account?.balance)}
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <input type='radio' className='accent-brand' checked />
                        </div>
                    </fieldset>

                    <fieldset className='mt-5 flex flex-col flex-1 '>
                        <label className='mb-1.5' htmlFor='funding_method'>
                            To this account
                        </label>
                        <Controller
                            name='receiving_account'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Select name='receiving_account' onValueChange={field.onChange}>
                                    <SelectTrigger
                                        className={`text-base ${receivingAccount ? 'h-[3.5rem]' : 'h-[3rem]'} border-gray3 flex `}
                                    >
                                        {receivingAccount ? (
                                            <SelectValue placeholder={'Select receiving account'} />
                                        ) : (
                                            <span className='text-gray/80'>Select receiving account</span>
                                        )}
                                    </SelectTrigger>
                                    <SelectContent className='h-56'>
                                        {bankAccounts
                                            ?.filter(bank => bank?._id !== account?.id)
                                            .map(bank => (
                                                <SelectItem value={bank?._id} key={bank?._id} className='pl-3'>
                                                    <div className='flex items-center gap-3'>
                                                        <BizpendAccounts
                                                            className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                                        />
                                                        <div className='flex flex-col items-start'>
                                                            <h6 className='text sm'>
                                                                {bank?.nickname ?? bank?.account_name}
                                                            </h6>
                                                            <p className='text-xs text-gray1 font-medium'>
                                                                {formatAmount(bank?.balance)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </fieldset>
                    {receivingAccount && (
                        <fieldset className='mt-5 flex flex-col flex-1 '>
                            <label className='mb-1.5' htmlFor='amount'>
                                Amount
                            </label>
                            <Controller
                                name='amount'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <div className='flex border border-[#DADCE0] w-full h-[48px] rounded-lg overflow-hidden'>
                                        <div className='w-[10%] flex items-center justify-center text-[#838383]'>
                                            <TbCurrencyNaira fontSize={25} />
                                        </div>
                                        <input
                                            {...field}
                                            type='text'
                                            placeholder='0.00'
                                            className='outline-none w-[90%] h-full'
                                            required
                                            min={0}
                                            value={formatAmount(field.value).replaceAll('₦', '')}
                                            onChange={e => {
                                                const value_entered = formatAmountWithoutSymbols(e.target.value)
                                                field.onChange(value_entered)
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </fieldset>
                    )}
                </div>

                <div className='border-t border-gray4 flex mt-6 mb-3 justify-end items-center pt-2.5 px-4'>
                    <Button loading={isLoading} className='bg-[#19C37D]' disabled={!isValid}>
                        Fund account
                    </Button>
                </div>
            </form>
        </ModalWrapper>
    )
}
