import { ArrowRight } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const InvoiceSettings = () => {
    const navigate = useNavigate()
    return (
        <section className='px-4 lg:px-0'>
            <div className='mb-2 lg:mb-6'>
                <h1 className='text-dark font-medium text-lg'>Settings</h1>
                <p className='text-gray7 text-sm'>Manage appearance and approval policies for invoice issuing</p>
            </div>

            <ul className='flex gap-8 mt-20'>
                <li
                    onClick={() => navigate('policy')}
                    className='cursor-pointer bg-white p-4 rounded-xl flex justify-between items-center gap-4 flex-1 max-w-lg'
                >
                    <div>
                        <h3 className='text-dark font-medium text-base'>Approval Policy</h3>
                        <p className='text-gray7 text-[0.8rem]'>
                            Set admins to approve invoices before they are sent to customers
                        </p>
                    </div>
                    <ArrowRight />
                </li>
                <li
                    onClick={() => navigate('appearance')}
                    className='cursor-pointer bg-white p-4 rounded-xl flex justify-between items-center gap-4 flex-1 max-w-lg'
                >
                    <div>
                        <h3 className='text-dark font-medium text-base'>Appearance</h3>
                        <p className='text-gray7 text-[0.8rem]'>Customize the look of the invoices you want to issue</p>
                    </div>
                    <ArrowRight />
                </li>
            </ul>
        </section>
    )
}

export default InvoiceSettings
