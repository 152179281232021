import React from 'react'
import Select, { StylesConfig } from 'react-select'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()

type SelectWIthChipProps = {
    options: {
        value: string
        label: string
        isDisabled?: boolean
    }[]
    colourStyles?: StylesConfig
    placeholder: string
    onChange: (e: any) => void
    isDisabled?: boolean
    value?: {
        value: string
        label: string
    }[]
    className?: string
}

export const styles: StylesConfig = {
    control: styles => ({
        ...styles,
        backgroundColor: 'white',
        cursor: 'pointer',
        fontSize: '14px',
        overflow: 'scroll',
        minHeight: '3.5rem',
        ':disabled': { color: '#5e5e5e', opacity: '40%' },
        // border: 'none',
    }),
    option: styles => {
        return {
            ...styles,
            color: 'black',
            cursor: 'pointer',
            fontSize: '14px',
            ':disabled': { color: '#5e5e5e', opacity: '40%' },
            // border: 'none',
        }
    },
    input: styles => ({ ...styles, cursor: 'text', ':disabled': { color: '#5e5e5e', opacity: '40%' } }),
    placeholder: styles => ({ ...styles, color: '#A7B4C4', fontSize: '14px' }),
    multiValueRemove: styles => ({
        ...styles,
        ':hover': { backgroundColor: '#CF491E', color: 'white' },
        ':disabled': { color: '#5e5e5e', opacity: '40%' },
    }),
    menuPortal: base => ({
        ...base,
        zIndex: 9999,
        height: 500,
        overflow: 'scroll',
        ':disabled': { color: '#5e5e5e', opacity: '40%' },
    }),
    menu: base => ({
        ...base,
        maxHeight: '900px',
        overflow: 'scroll',
        ':disabled': { color: '#5e5e5e', opacity: '40%' },
    }),
}

const SelectWIthChip = React.forwardRef<any, SelectWIthChipProps>(
    ({ options, colourStyles, placeholder, isDisabled, value, ...props }, ref) => {
        return (
            <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={options}
                styles={colourStyles ?? styles}
                placeholder={placeholder}
                ref={ref}
                isDisabled={isDisabled}
                className={``}
                value={value}
                {...props}
            />
        )
    }
)

export default SelectWIthChip
