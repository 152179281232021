import React, { useState } from 'react'
import { CancelIcon, CategoryIcon, ProfileIcon } from '@/assets/assets.export'
import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import moneyIcon from '@/assets/money.svg'
import { CheckIcon, SearchIcon } from 'lucide-react'
import { modalVariants } from '../Employee/utils'
import ModalWrapper from '../ModalWrapper'
import xIcon from '@/assets/x.svg'
import { DeclineBudgetRequest } from './DeclineRequest'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function BudgetRequest({ closeModal }: Props) {
    return (
        <ModalWrapper variants={modalVariants} className='justify-end right-8'>
            <section className='h-[100vh] overflow-scroll'>
                <div className='flex items-center my-4 border-b border-[#DADCE0] w-full pb-2'>
                    <h2 className='flex-1 text-center font-semibold text-xl'>Budget Requests</h2>
                    <CancelIcon
                        className='w-9 h-9 bg-[#F2F2F2] rounded-full p-2 inline-block mr-3 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>
                <section className='w-[28rem] mx-4 mt-9 mb-3'>
                    <Input
                        leftIcon={<SearchIcon className='text-[#A8A8A8]' />}
                        placeholder='Search budget requests'
                        className='h-[3.5rem] border-[#DADCE0]'
                    />
                    <div className='flex gap-6 items-center mt-4'>
                        <label htmlFor='sorting' className='text-base font-medium text-[#838383]'>
                            Sort by:
                        </label>
                        <Select name='filter_request' value='pending'>
                            <SelectTrigger className=' border-none text-base h-[2.5rem] w-[10rem] bg-[#F9F9F9] text-[#5E5E5E]'>
                                <SelectValue placeholder={''} />
                            </SelectTrigger>
                            <SelectContent className='w-[10rem]'>
                                <SelectItem value='pending'>Pending</SelectItem>
                                <SelectItem value='approved'>Approved</SelectItem>
                                <SelectItem value='declined'>Declined</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <section className='mt-9'>
                        <SingleBudgetRequest />
                        <SingleBudgetRequest />
                        <SingleBudgetRequest />
                        <SingleBudgetRequest />
                        <SingleBudgetRequest />
                    </section>
                </section>
            </section>
        </ModalWrapper>
    )
}

const SingleBudgetRequest = () => {
    const [declineModal, setDeclineModal] = useState(false)
    return (
        <div className='mt-2 border-[#DADCE0] border-b pb-4'>
            <h2 className='font-semibold text-[#202020]'>Marketing Budget Request</h2>
            <div className='text-[#5E5E5E] flex gap-5 text-base mt-3'>
                <span className='flex  items-center w-[155px]'>
                    <ProfileIcon className='' />
                    <span className='truncate'>Alex Anderson (Admin)</span>
                </span>
                <span className='flex items-center w-[60px]'>
                    <CategoryIcon className='mr-2 scale-125' />
                    <span className='truncate'>Marketing</span>
                </span>
                <span className='flex gap-1  items-center'>
                    <img src={moneyIcon} alt='icon' className='w-[22px]' />
                    ₦50,000.00 / Month
                </span>
            </div>
            <div className='text-right px-2 pt-3 flex items-center justify-end text-sm'>
                <button className='mr-5 text-[#19C37D] flex items-center gap-2'>
                    <CheckIcon />
                    Approve
                </button>
                <button className='text-errorRed flex items-center gap-2' onClick={() => setDeclineModal(true)}>
                    <img src={xIcon} alt='' />
                    Decline
                </button>
            </div>
            {declineModal && <DeclineBudgetRequest closeModal={setDeclineModal} />}
        </div>
    )
}
