import { BizpendAccounts, CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { Button } from '@/components/Button'
import { Controller, useForm } from 'react-hook-form'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { TbCurrencyNaira } from 'react-icons/tb'
import { useTransferInternally } from '@/services/accounts/mutations'
import { DedicatedBankAccountType } from '@/types/accounts'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { Copy } from 'lucide-react'
import { handleCopyClick } from '@/utils/utils'
import { useAuthContext } from '@/context'
import { useGetDedicatedBankAccounts } from '@/services/accounts/queries'
import { useMemo } from 'react'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    closeModal: () => void
    account: DedicatedBankAccountType
    accountDetails: {
        name: string
        desc: string
    }[]
}
enum ACCOUNTS_ENUM {
    internal = 'internal',
    bank = 'bank',
}

type FormType = {
    funding_method: string
    amount: string
    fund_source: string
}
export default function FundAccountModal({ closeModal, account, accountDetails }: Props) {
    const { selectedAccount } = useAuthContext()
    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
    } = useForm<FormType>({})

    const { data } = useGetDedicatedBankAccounts({
        queryParams: { entity_id: selectedAccount?.org?.id as string },
    })
    const bankAccounts = useMemo(() => data ?? ([] as DedicatedBankAccountType[]), [data])

    const { mutate: transferFn, isLoading } = useTransferInternally({
        onSuccess: () => {
            toastHandler({ message: 'Transaction processing', state: 'success' })
            closeModal()
        },
    })
    const fundingMethod = watch('fund_source')

    const onSubmit = (values: FormType) => {
        if (values.fund_source === ACCOUNTS_ENUM.bank) {
            closeModal()
            return
        }
        transferFn({
            amount: Number(values.amount) / 100,
            source_account_id: values?.fund_source,
            destination_account_id: account?.id,
        })
    }

    return (
        <ModalWrapper>
            <form onSubmit={handleSubmit(onSubmit)} className=' min-h-[18rem] w-[25rem] md:w-[33rem] overflow-scroll'>
                <div className='flex items-center border-b border-[#DADCE0] p-3 px-5 justify-between'>
                    <h2 className='text-center text-lg font-medium opacity-80'>Add funds to this account</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='mx-5 mt-4'>
                    <fieldset className='mt-5 flex flex-col  flex-1 '>
                        <label className='mb-1.5 ' htmlFor='funding_method'>
                            Add funds to this account
                        </label>
                        <div className='flex items-center justify-between border border-gray4 rounded-lg p-3 px-4'>
                            <div className='text-sm  flex flex-col gap-1'>
                                <span>{account?.nickname ?? account?.account_name}</span>
                                <span className='text-gray7 font-medium text-xs'>{formatAmount(account?.balance)}</span>
                            </div>
                            <input type='radio' className='accent-brand' checked />
                        </div>
                    </fieldset>
                    <>
                        <fieldset className='mt-5 flex flex-col flex-1 '>
                            <label className='mb-1.5' htmlFor='funding_method'>
                                From this account
                            </label>
                            <Controller
                                name='fund_source'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Select name='fund_source' onValueChange={field.onChange}>
                                        <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                                            <SelectValue placeholder={'Select account source'} />
                                        </SelectTrigger>
                                        <SelectContent className='h-56'>
                                            <SelectItem value={ACCOUNTS_ENUM.bank} className='pl-3'>
                                                <div className='flex items-center gap-3'>
                                                    <BizpendAccounts
                                                        className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                                    />
                                                    <div className='flex flex-col items-start'>
                                                        <h6 className='text sm'>External transfer</h6>
                                                        {/* <p className='text-xs text-gray1 font-medium'>
                                                                {formatAmount(bank?.balance)}
                                                            </p> */}
                                                    </div>
                                                </div>
                                            </SelectItem>
                                            {bankAccounts
                                                ?.filter(acct => acct?._id !== account?.id)
                                                ?.map(bank => (
                                                    <SelectItem
                                                        value={bank?._id}
                                                        key={bank?._id}
                                                        className='pl-3'
                                                        disabled={bank?.status !== 'active' || bank.balance <= 0}
                                                    >
                                                        <div className='flex items-center gap-3'>
                                                            <BizpendAccounts
                                                                className={`w-8 h-8 rounded-lg  fill-brand bg-brand/10 p-1.5`}
                                                            />
                                                            <div className='flex flex-col items-start'>
                                                                <h6 className='text sm'>
                                                                    {bank?.nickname ?? bank?.account_name}
                                                                </h6>
                                                                <p className='text-xs text-gray1 font-medium'>
                                                                    {formatAmount(bank?.balance)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </SelectItem>
                                                ))}
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </fieldset>
                        {fundingMethod !== ACCOUNTS_ENUM.bank && (
                            <fieldset className='mt-5 flex flex-col flex-1 '>
                                <label className='mb-1.5' htmlFor='funding_method'>
                                    Amount
                                </label>
                                <Controller
                                    name='amount'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className='flex border border-[#DADCE0] w-full h-[48px] rounded-lg overflow-hidden'>
                                            <div className='w-[10%] flex items-center justify-center text-[#838383]'>
                                                <TbCurrencyNaira fontSize={25} />
                                            </div>
                                            <input
                                                {...field}
                                                type='text'
                                                placeholder='0.00'
                                                className='outline-none w-[90%] h-full'
                                                required
                                                min={0}
                                                value={formatAmount(field.value).replaceAll('₦', '')}
                                                onChange={e => {
                                                    const value_entered = formatAmountWithoutSymbols(e.target.value)
                                                    field.onChange(value_entered)
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </fieldset>
                        )}
                    </>

                    {fundingMethod === ACCOUNTS_ENUM.bank && (
                        <div className=' mt-5'>
                            <h2 className='font-medium opacity-80'>Fund this account with these details</h2>

                            <ul className='mt-1 space-y-2'>
                                {accountDetails?.map((detail, idx) => (
                                    <li key={idx} className='text-sm flex items-center justify-between'>
                                        <div>
                                            <h3 className='text-gray7 '>{detail.name}</h3>
                                            <p className='font-medium'>{detail.desc}</p>
                                        </div>

                                        <button
                                            className='text-gray7 text-xs flex items-center font-semibold gap-1'
                                            onClick={() => {
                                                handleCopyClick(detail?.desc)
                                            }}
                                        >
                                            <span className=''>Copy</span>
                                            <Copy size={12} className='text-gray7' />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className='border-t border-gray4 flex mt-6 mb-3 justify-end items-center pt-2.5 px-4'>
                    <Button
                        loading={isLoading}
                        className='bg-[#19C37D]'
                        disabled={fundingMethod === ACCOUNTS_ENUM.bank ? false : !isValid}
                    >
                        {fundingMethod === ACCOUNTS_ENUM.bank ? 'Close' : 'Fund account'}
                    </Button>
                </div>
            </form>
        </ModalWrapper>
    )
}
