import { TimeLineType } from '@/utils/timeline'
import { DedicatedBankAccountType } from './accounts'
import { CurrencyType } from './currency'
import { Customers } from './customers'
import { OrgType, UserOrgAccount } from './org'
import { TransactionType } from './transactions'

export enum InvoiceStatus {
    PAID = 'PAID',
    PARTLY_PAID = 'PARTLY PAID',
    PARTIALLY_PAID = 'PARTIALLY PAID',
    UNPAID = 'UNPAID',
    OVERDUE = 'OVERDUE',
    PENDING = 'PENDING',
    DRAFT = 'DRAFT',
    SENT = 'SENT',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
}
export type AddItemProps = {
    quantity: string | number
    description: string
    unit_cost: string | number
    total_cost?: string | number
    uniq_id?: string
    _id?: string
    id?: string
    name: string
    account?: string
}

export type Invoice = {
    _id: string
    timeline: TimeLineType
    id: string
    org: OrgType
    issuer: UserOrgAccount
    customer: Customers
    issue_date: Date
    createdAt: Date
    due_date: Date
    total_amount: string
    tax: string
    currency: CurrencyType
    approvers: {
        account: UserOrgAccount
        approved: boolean
        id: string
        _id: string
    }[]
    items: {
        unit_cost: string
        name: string
        quantity: number
        _id: string
        description?: string
    }[]
    receipt_url?: string
    status: InvoiceStatus
    message: string
    invoice_id: string
    payment_method?: string
    accept_part_payment: boolean
    logo?: string
    invoice_attachment_url: string
    transactions: TransactionType[]
    metadata?: {
        payment_method: string
        amount: string
        payment_date: string
        createdAt: string
        updatedAt: string
        id: string
    }
    payout_bank_account: DedicatedBankAccountType
    paid: string
    footer?: string
}
export type InvoiceSummary = {
    _id: number | null
    total_payments_received: string
    total_invoices: number
    paid: number
    pending: number
    unpaid: number
    drafts: number
}

export type InvoiceItemType = {
    quantity: string | number
    description: string
    unit_cost: { $numberDecimal: string | number }
    total_cost?: string | number
    uniq_id?: string
    _id?: string
    id?: string
    currency?: CurrencyType
    name: string
    account?: string
}
