import { Button } from '@/components'
import { useAuthContext, useImpersonationContext } from '@/context'
import { queryClient } from '@/queryClient'
import { orgInsightkey } from '@/services/auth/queries'
import { keyQueryOrgExpenses } from '@/services/expenses/queries'
import { useImitateUser } from '@/services/imitating-users/mutations'
import { orgTransactionKey } from '@/services/transactions/queries'
import { capitalizeText } from '@/utils/string'
import React from 'react'
import ModalWrapper from '../ModalWrapper'

type Props = {
    setImitatingUser: React.Dispatch<
        React.SetStateAction<{
            openModal: boolean
            id: string
        }>
    >
    imitatingUser: {
        openModal: boolean
        id: string
    }
}
export default function ImpersonationPopup({ setImitatingUser, imitatingUser }: Props) {
    const { loginUser, selectedAccount } = useAuthContext()
    const { setImpersonationMode, user, orgDetails } = useImpersonationContext()

    const { mutate: imitateUserFn, isLoading } = useImitateUser({
        onSuccess: data => {
            setImitatingUser({ openModal: false, id: '' })
            queryClient.invalidateQueries({ queryKey: orgInsightkey })
            queryClient.invalidateQueries({ queryKey: keyQueryOrgExpenses })
            queryClient.invalidateQueries({ queryKey: orgTransactionKey })
            queryClient.invalidateQueries({ queryKey: ['/me'] })

            setImpersonationMode(true)
            loginUser({ user: data.user, accounts: data.accounts, selectedAccount: null })
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <section className='w-[30rem] min-h-[30vh] max-h-[45vh]  relative p-5 overflow-y-scroll'>
                <div className=' text-[#5E5E5E] font-normal mb-4'>
                    By doing this, you would be switching from your org,
                    <span className='text-brand font-medium'> {selectedAccount?.org?.org_name}</span>, to{' '}
                    <span className='text-brand font-medium'>{orgDetails?.org_name}</span> in a bid to impersonate the
                    account of{' '}
                    <span className='text-[#cf491E]'>
                        {capitalizeText(user?.user?.first_name)} {capitalizeText(user?.user?.last_name)}.
                    </span>
                </div>
                <div className='bg-[#EFEFF9] rounded-xl p-3'>
                    Please note that this is done with the intent of solving technical issues and some functionalities
                    for this account may not be present or active.
                </div>

                <div className='flex pt-5 items-center justify-end gap-4 px-6 py-2   w-full rounded-b-lg mt-6'>
                    <Button
                        className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-base hover:bg-transparent min-w-[100px]'
                        onClick={() => {
                            setImitatingUser({ openModal: false, id: '' })
                        }}
                    >
                        cancel
                    </Button>
                    <Button
                        type='submit'
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        loading={isLoading}
                        onClick={() => {
                            // setImpersonationMode(prev => !prev)

                            imitateUserFn({ org_account_id: imitatingUser?.id })
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
