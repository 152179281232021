import { ProfileIcon } from '@/assets/assets.export'
import { formatAmount, formatAmountWithSymbolOfChoice, formatAmountWithoutSymbols } from '@/utils/money'
import { PercentIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Control, Controller, UseFormWatch } from 'react-hook-form'
import { plans } from './NewBenefit2'
import { BenefitForm, calculatePercentageAmountInNaira, convertToPercentage } from './helper'

type Step4Props = {
    selectedPlan: string
    selectedMembers: {
        label: string
        value: string
    }[]
    control: Control<BenefitForm, any>
    watch: UseFormWatch<BenefitForm>
}

const NewBenefitStep4 = ({ selectedPlan, selectedMembers, control, watch }: Step4Props) => {
    const [benefitPercentageDetails, setBenefitPercentageDetails] = useState<{ user: string; company: string }>()
    const watchSplitPaymentPercentageForCompany = watch('split_payment_company')
    // const watchSplitPaymentPercentageForUser = watch('split_payment_user')

    const selectedPlanData = plans.filter(plan => plan?.id === selectedPlan)?.[0]
    const percentage = Number(Number(watchSplitPaymentPercentageForCompany) / 100)

    useEffect(() => {
        const data = calculatePercentageAmountInNaira(selectedPlanData?.price * 100, percentage.toString())
        setBenefitPercentageDetails({ company: data?.company, user: data?.user })
    }, [selectedPlanData?.price, watchSplitPaymentPercentageForCompany, percentage])

    const disabledUserSharingField = (100 - percentage).toFixed(2).toString()

    return (
        <div className='p-4 md:p-8 md:px-12 relative'>
            <div className='mb-5'>
                <h1 className='text-xl font-semibold text-[#202020]'>Set payment</h1>
                <p className='text-sm md:text-base font-medium text-[#5E5E5E]'>
                    Specify how you want to split payment of this benefit between the company and users enrolled.
                </p>
            </div>
            <div className='bg-[#F9F9F9] rounded-xl p-5  mb-8'>
                <div className='flex items-end justify-between mb-6'>
                    {plans
                        .filter(plan => plan?.id === selectedPlan)
                        .map(sPlan => (
                            <div className=''>
                                <h1 className='text-xl font-semibold text-[#202020] mb-3'>{sPlan.name}</h1>
                                <div className='bg-[#EFEFF9] rounded-full py-2 px-3 text-brand text-xs min-w-[210px]'>
                                    <span className='text-xl font-semibold'>
                                        {formatAmount(sPlan.price * selectedMembers.length * 100)} / month
                                    </span>
                                </div>
                            </div>
                        ))}
                    <p className='font-semibold text-[#5E5E5E] flex gap-1'>
                        <ProfileIcon />
                        {selectedMembers.length}
                    </p>
                </div>
                <div className='bg-white rounded-xl p-5 flex flex-wrap gap-5'>
                    {selectedMembers.map(member => (
                        <p className='bg-[#F2F2F2] px-3 py-1 rounded'>{member.label}</p>
                    ))}
                </div>
            </div>
            <div className='flex flex-col gap-4 mb-10'>
                <label htmlFor='firstName' className='text-[#202020] text-[14px] font-medium'>
                    Split payment options <span className=''>*</span>
                </label>

                <div className='flex flex-col sm:flex-row items-center  gap-8'>
                    <div className='w-full sm:w-1/3'>
                        <label htmlFor='' className='text-[#202020] text-[12px] font-medium mb-2 block'>
                            Company <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name={'split_payment_company'}
                            control={control}
                            render={({ field }) => (
                                <div
                                    className='px-2 flex items-center border border-[#DADCE0] outline-none rounded-md

'
                                >
                                    <input
                                        {...field}
                                        className={
                                            'w-full h-[3.5rem] text-dark  py-2 px-3 text-sm placeholder:text-slate-400 disabled:cursor-not-allowed pr-12 outline-none text-[13px]'
                                        }
                                        placeholder={"company's coverage"}
                                        value={formatAmount(field.value?.replaceAll('.', ''))?.replaceAll('₦', '')}
                                        onChange={e => {
                                            e.target.value = formatAmountWithoutSymbols(e.target.value)
                                            if (Number(convertToPercentage(e.target.value.replaceAll('.', ''))) > 100)
                                                return
                                            e.target.value = formatAmountWithSymbolOfChoice(e.target.value, ['.'])
                                            field.onChange(e)
                                        }}
                                    />
                                    <PercentIcon />
                                </div>
                            )}
                        />
                    </div>
                    {/* <span className='bg-[#5e5e5e]/60 w-[6rem] inline-flex items-center h-[1px] relative top-3'></span> */}
                    <div className='w-full sm:w-1/3'>
                        <label htmlFor='' className='text-[#202020] text-[12px] font-medium mb-2 block'>
                            User <span className='text-[red]'>*</span>
                        </label>
                        <div
                            className='px-2 flex items-center border border-[#DADCE0] focus:border-brand focus:border-2 rounded-md bg-slate-100

'
                        >
                            <input
                                className={
                                    'w-full h-[3.5rem] text-dark  py-2 px-3 text-sm placeholder:text-slate-400 disabled:cursor-not-allowed pr-12  text-[13px]'
                                }
                                placeholder={"user's coverage"}
                                value={isNaN(Number(disabledUserSharingField)) ? '0.00' : disabledUserSharingField} //
                                disabled
                            />
                            <PercentIcon />
                        </div>
                    </div>
                </div>
                <p className='text-[#5E5E5E] text-xs'>
                    Company to cover <span className='text-[#19C37D]'>{benefitPercentageDetails?.company}</span>. Users
                    to cover <span className='text-[#19C37D]'>{benefitPercentageDetails?.user}</span>, deducted from
                    users gross salary
                </p>
            </div>
        </div>
    )
}

export default NewBenefitStep4
