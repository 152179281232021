import PageContent from '@/layout/PageContent'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Tabs, TabTriggersContainer, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import { motion } from 'framer-motion'
import BusinessBillingPlan from './partials/BusinessBillingPlan'
import { Button } from '@/components'
import CreateBillingPlan from '../../../components/Modals/Business-directory/CreateBillingPlan'
import OrgsInBusinessDirectory from './partials/OrgsInBusinessDirectory'
import BusinessDirectoryRequestAccess from './partials/BusinessDirectoryRequestAccess'
import { useCheckIfAdminBizpendEmail } from '@/utils/bizpend'
import { useAuthContext } from '@/context'

const route_const = {
    plans: 'plans',
    manage_business: 'manage_business',
    request_access: 'request_access',
    true: 'true',
}

export default function BusinessDirectory() {
    const [openCreatePlan, setOpenCreatePlan] = useState(false)
    const { selectedAccount } = useAuthContext()
    const location = useLocation()

    const { subRoute } = useParams()
    const currentRoute = location.pathname.split('/').slice(0, -1).join('/')
    const showCreatePlanBtn = location.pathname.includes('plans')
    const navigate = useNavigate()

    const isBizpendAdmin = useCheckIfAdminBizpendEmail(selectedAccount?.user?.email, location.state?.show)

    useEffect(() => {
        if (!isBizpendAdmin) {
            navigate('/dashboard/overview')
        }
    }, [])

    return (
        <>
            {' '}
            <Tabs defaultValue={subRoute} value={subRoute}>
                <TabsList
                    className={`border-none overflow-x-scroll  flex justify-between ${showCreatePlanBtn ? 'mx-3 lg:mx-10' : 'mx-0'} items-center mt-10`}
                >
                    <TabTriggersContainer className='w-[96%]'>
                        <TabsTrigger
                            value={route_const.manage_business}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.manage_business}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.manage_business === subRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.manage_business === subRoute && (
                                <motion.div
                                    layoutId='directory_btn'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Manage
                            </motion.span>
                        </TabsTrigger>

                        <TabsTrigger
                            value={route_const.plans}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.plans}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.plans === subRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.plans === subRoute && (
                                <motion.div
                                    layoutId='directory_btn'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Plans
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={route_const.request_access}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.request_access}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.request_access === subRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.request_access === subRoute && (
                                <motion.div
                                    layoutId='directory_btn'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative whitespace-nowrap'>
                                Request Access
                            </motion.span>
                        </TabsTrigger>
                    </TabTriggersContainer>
                    {showCreatePlanBtn && (
                        <Button onClick={() => setOpenCreatePlan(true)} className='text-xs min-w-[100px] p-2 min-h-fit'>
                            Create Plan
                        </Button>
                    )}
                </TabsList>
                <TabsContent value={route_const.manage_business}>
                    <PageContent>
                        <OrgsInBusinessDirectory />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.plans}>
                    <PageContent>
                        <BusinessBillingPlan />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.request_access}>
                    <PageContent>
                        <BusinessDirectoryRequestAccess />
                    </PageContent>
                </TabsContent>
            </Tabs>
            {openCreatePlan && <CreateBillingPlan closeModal={() => setOpenCreatePlan(false)} />}
        </>
    )
}
