import { useAuthContext } from '@/context'
import { useCheckIfAdminBizpendEmail } from '@/utils/bizpend'
import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

export default function ImpersonationRouteWrapper({ children }: { children: ReactNode }) {
    const { selectedAccount } = useAuthContext()

    return useCheckIfAdminBizpendEmail(selectedAccount?.user?.email) ? (
        <>{children}</>
    ) : (
        <Navigate to='/login' replace />
    )
}
