import { useExpenseContext } from '@/context'

import { EmployeeType } from '@/types/org'
import { format } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { X } from 'lucide-react'
import { CategoryType } from '@/types/categories'

type Props = { categories: CategoryType[]; orgMembers: EmployeeType[] }

const key_types = {
    period: 'period',
    amount: 'amount',
    expense_category: 'expense_category',
    approved_by: 'approved_by',
    reimbursed_by: 'reimbursed_by',
    created_by: 'created_by',
}

export default function FilterExpenseChip({ categories, orgMembers }: Props) {
    const { filterExpense, setFilterExpense } = useExpenseContext()

    const { period, amount } = filterExpense

    const getFilteredDetails = () => {
        const data = Object.keys(filterExpense).map(key => {
            const value = filterExpense[key as keyof typeof filterExpense]
            if (key === key_types.period) {
                return {
                    [key]:
                        period.from || period.to
                            ? `${format(new Date(period?.from as unknown as Date), 'do MMM')} -
                                  ${format(period?.to ? new Date(period?.to as unknown as Date) : new Date(), 'do MMM')}

                              )}`
                            : period.time_in_words
                              ? `${format(new Date(period.time_in_words as unknown as Date), 'do MMM')} - ${format(new Date(), 'do MMM')}`
                              : '',
                }
            }
            if (key === key_types.amount) {
                return {
                    [key]:
                        amount.min || amount.max
                            ? `₦${amount.min}- ₦${amount.max}`
                            : amount.amount_in_words
                              ? amount.amount_in_words
                              : '',
                }
            }
            if (key === key_types.expense_category) {
                return { [key]: categories.find(category => category.id === value)?.label }
            }
            if (key === key_types.approved_by || key === key_types.reimbursed_by || key === key_types.created_by) {
                const found_user = orgMembers.find(members => members?.id === value)?.user
                const key_title = key.split('_')
                return {
                    [key]:
                        found_user?.first_name &&
                        `${capitalizeText(key_title[0])} ${key_title[1]}: ${capitalizeText(
                            found_user?.first_name as string
                        )} ${capitalizeText(found_user?.last_name as string)}`,
                }
            }
            return { [key]: value as string }
        })
        return data
    }

    const handleDeleteChip = (key: string) => {
        setFilterExpense(filter => ({ ...filter, [key]: '', filter_now: true }))
    }

    return (
        <section className='flex gap-5 flex-wrap'>
            {getFilteredDetails().map(details => (
                <>
                    {Object.keys(details).map(filter => {
                        const value = details[filter as keyof typeof details]
                        return (
                            value?.length !== 0 &&
                            value !== undefined &&
                            typeof value !== 'boolean' && (
                                <button
                                    type='button'
                                    className='cursor-pointer flex gap-4 px-3 bg-[#EFEFF9] text-[#454ADE] min-h-[20px] items-center justify-between py-2 rounded-md text-xs'
                                >
                                    <span className=' hover:bg-[#EFEFF9]'>
                                        <>{value === 'denied' ? 'Declined' : value}</>
                                    </span>
                                    <X className='w-3 md:w-4 h-3 md:h-4' onClick={() => handleDeleteChip(filter)} />
                                </button>
                            )
                        )
                    })}
                </>
            ))}
        </section>
    )
}
