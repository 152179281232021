import { Input } from '@/components'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import { motion } from 'framer-motion'
import { Search } from 'lucide-react'
import AccountingTable from '../components/AccountingTable'
import { useGetOrgTransactions } from '@/services/transactions/queries'
import { useAccountingContext, useAuthContext } from '@/context'
import ProgressBar from '@/components/ProgressBar'
import { useEffect, useMemo } from 'react'
import { QueryOrgTransactions } from '@/services/transactions/types'

const filterType = {
    all: 'all',
    review: 'needs_review',
    ready: 'ready',
}

export default function AccountingMain() {
    const { all, ready, review } = filterType
    const { selectedAccount } = useAuthContext()
    const [setAccoutingFilter, searchParamDetails] = useAddQueryParamsToURL<{ type: keyof typeof filterType }>()
    const subRoute = searchParamDetails.get('type') ?? all
    const { pageNumber, pageNumberClicked, setPageNumberClicked } = useAccountingContext()

    const { data, isLoading, refetch, isRefetching } = useGetOrgTransactions({
        queryParams: {
            page: pageNumber.toString(),
            org_id: selectedAccount?.org?.id as string,
            accounting_status: subRoute.toUpperCase(),
            limit: '15',
        },
    })

    const orgTransactions = useMemo(() => data ?? ({} as QueryOrgTransactions), [data])

    useEffect(() => {
        if (pageNumber && pageNumberClicked) {
            refetch()
            setPageNumberClicked(false)
        }
    }, [pageNumber, pageNumberClicked])
    useEffect(() => {
        refetch()
    }, [subRoute])

    const filterDetailsBtn = [
        {
            desc: 'All',
            id: all,
        },
        {
            desc: 'Needs review',
            filterVal: 201,
            id: review,
        },
        {
            desc: 'Ready for export',
            filterVal: 12,
            id: ready,
        },
    ]
    if (isLoading) return <ProgressBar />
    return (
        <section className='mt-8'>
            <div>
                <h2 className='text-xl font-semibold'>Accounting</h2>
                <p className='text-sm text-gray7'>Export your transactions to your preferred accounting platform</p>
            </div>
            <section className='mt-8 mb-4 bg-white p-4 rounded-xl'>
                <ul className='flex items-center gap-4 mb-5'>
                    {filterDetailsBtn.map(btnDetails => (
                        <li
                            className={`relative ${btnDetails.id === subRoute ? 'text-brand ' : 'bg-transparent text-[#5E5E5E]'} text-sm rounded-md p-2 px-3`}
                            onClick={() => setAccoutingFilter('type', btnDetails.id)}
                        >
                            <button className='flex gap-2'>
                                {btnDetails.desc}{' '}
                                {btnDetails?.filterVal && (
                                    <span
                                        className={`${btnDetails.id === subRoute ? 'bg-brand ' : ' bg-gray7 '} text-white text-xs px-3 p-0.5 rounded-xl`}
                                    >
                                        {btnDetails?.filterVal}
                                    </span>
                                )}
                            </button>
                            {btnDetails.id === subRoute && (
                                <motion.div
                                    layoutId='accounting-filter'
                                    className={
                                        'bg-brand/10 rounded  text-sm  w-full left-[1px] top-0  bottom-0 absolute'
                                    }
                                />
                            )}
                        </li>
                    ))}
                </ul>
                <Input
                    placeholder='Search'
                    className='max-w-xs h-11 border-gray4'
                    leftIcon={<Search className='text-gray' />}
                />
                <div className={`mt-5 ${isRefetching ? 'opacity-60' : ''}`}>
                    <AccountingTable orgTransactions={orgTransactions} />
                </div>
            </section>
        </section>
    )
}
