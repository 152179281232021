import { ProfileUser } from '@/assets/assets.export'
import { useBudgetContext } from '@/context'
import { formatAmount } from '@/utils/money'
import { useNavigate, useParams } from 'react-router-dom'
import { ComputePercent } from '../utils'
import { Budget } from '@/types/budget'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'

type BudgetCardProps = {
    budget: Budget
}
const BudgetCards = ({ budget }: BudgetCardProps) => {
    const navigate = useNavigate()
    const { budget_id } = useParams()

    const { color, bgColor, percentage } = ComputePercent(budget)

    const { setBudgetObj } = useBudgetContext()
    const handleSetBudget = () => {
        if (budget_id) return
        setBudgetObj(budget)
        navigate(`/dashboard/budget/${budget._id}`)
    }

    return (
        <div
            className='border border-[#DADCE0] rounded-2xl px-4 py-[28px] cursor-pointer overflow-hidden'
            onClick={handleSetBudget}
        >
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-2 relative'>
                    <h1 className='text-lg font-medium'>{budget.name ?? 'N/A'}</h1>
                </div>
                <div className='flex items-center gap-4'>
                    <span className='px-3 py-1 rounded-full text-sm' style={{ color: color, backgroundColor: bgColor }}>
                        {percentage.toFixed(2)}%
                    </span>
                </div>
            </div>
            <div className='mt-[30px] mb-8 bg-[#EFEFF9] rounded h-4'>
                <div
                    className={`bg-green h-4  rounded`}
                    style={{ width: `${percentage > 100 ? 100 : percentage}%`, backgroundColor: color }}
                ></div>
            </div>
            <div className='flex items-center justify-between flex-wrap gap-4'>
                <div className='flex items-center gap-5'>
                    <div className=''>
                        <h2 className='text-[#5E5E5E] mb-2  text-sm'>Allocated</h2>
                        <p className='font-semibold '>{formatAmount(budget?.allocated_amount)}</p>
                    </div>
                    <div className=''>
                        <h2 className='text-[#5E5E5E] mb-2 text-sm'>Spent</h2>
                        <p className=''>
                            {formatAmount(Number(budget?.allocated_amount) - Number(budget?.available_amount))}
                        </p>
                    </div>
                    <div className=''>
                        <h2 className='text-[#5E5E5E] mb-2 text-sm'>Available</h2>
                        <p className=''>{formatAmount(budget?.available_amount)}</p>
                    </div>
                </div>
                {budget?.accounts && (
                    <TooltipProvider delayDuration={10}>
                        <Tooltip>
                            <TooltipTrigger>
                                <span className='flex bg-[#EFEFF9] rounded-full p-2 px-3 items-center gap-3'>
                                    <ProfileUser />
                                    <span className='bg-[#454ADE] rounded-full text-white text-sm box-content inline-flex items-center justify-center w-6 h-6 p-1'>
                                        {budget?.accounts?.length}
                                    </span>
                                </span>
                            </TooltipTrigger>
                            <TooltipContent className=''>
                                <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>Budget users</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                )}
            </div>
        </div>
    )
}

export default BudgetCards
