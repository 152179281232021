import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { CiSearch } from 'react-icons/ci'
import SingleMember from './SingleMember'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext } from '@/context'
import { LoaderIcon } from 'react-hot-toast'
import { TeamMembersProp } from '@/types/employee'
import { useMemo, useState } from 'react'
import { modalVariants } from '../../Expense/utils'
import EmptyStateUserIcon from '@/assets/EmptyStateUser.svg'
import notFound from '@/assets/not-found.svg'

interface AddMemberProps {
    setAddMemberModal: React.Dispatch<React.SetStateAction<boolean>>
    currentTeamData: TeamMembersProp[]
}

const AddMemberModal = ({ setAddMemberModal, currentTeamData }: AddMemberProps) => {
    const { selectedAccount } = useAuthContext()
    const [searchQuery, setSearchQuery] = useState('')
    const {
        data,
        isLoading: isOrgMemberLoading,
        isRefetching,
    } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })

    //====filtering so that an user cannot be added to the same team twice
    const orgMemberData = useMemo(
        () => data?.filter(member => !currentTeamData.some(team_member => team_member?.account?.id === member?.id)),
        [currentTeamData, data]
    )

    const filteredData =
        orgMemberData &&
        orgMemberData?.filter(item =>
            (item?.user?.first_name.toLowerCase() + ' ' + item?.user?.last_name.toLowerCase()).includes(
                searchQuery.toLowerCase()
            )
        )

    return (
        <ModalWrapper className='justify-end right-8' variants={modalVariants} modalClassName='rounded-none bg-[red]'>
            <div className='min-w-[420px] h-[100vh]'>
                <div className='border-b border-[#DADCE0] py-4 px-2  '>
                    <div className='flex items-center'>
                        <h2 className='font-semibold text-[#31254B] flex-1 text-center'>Add new member</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setAddMemberModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='border border-[#DADCE0] h-[56px] w-[93%] mt-8 mb-4 mx-auto rounded-lg flex items-center overflow-hidden'>
                    <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                        <CiSearch fontSize={25} />
                    </div>
                    <input
                        type='text'
                        placeholder='Search user'
                        className='h-full w-[90%] outline-none'
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className='p-6 pb-0 overflow-scroll'>
                    <div className='flex flex-col mb-6 gap-6'>
                        {isOrgMemberLoading || isRefetching ? (
                            <section className='w-[20rem]  flex justify-center items-center h-[80vh]'>
                                <LoaderIcon className='w-[3rem] h-[3rem]' />
                            </section>
                        ) : Array.isArray(orgMemberData) && orgMemberData.length > 0 ? (
                            searchQuery === '' ? (
                                orgMemberData?.map((data, idx) => <SingleMember member={data} key={idx} />)
                            ) : (
                                filteredData?.map((data, idx) => <SingleMember member={data} key={idx} />)
                            )
                        ) : (
                            <section className='w-[370px]  flex flex-col justify-center items-center h-[50vh]'>
                                <img src={EmptyStateUserIcon} alt='widget' className='scale-75' />
                                <span className='text-xl font-medium text-[#5E5E5E] mt-3'>
                                    There are no users to be added
                                </span>
                                {/* <span className='text-gray7 mt-2 text-sm w-[90%] mx-auto'>
                                    Oops! It seems we couldn't find any matching results for your search. Please try
                                    again with different keywords or refine your search criteria for better results.
                                </span> */}
                            </section>
                        )}
                        {(orgMemberData?.length as number) > 0 &&
                            searchQuery.length > 0 &&
                            filteredData?.length === 0 && (
                                <div className='h-[400px] w-[370px] mx-auto flex flex-col text-center justify-center items-center'>
                                    <img src={notFound} alt='widget' className='scale-50' />
                                    <span className='text-xl font-medium text-[#5E5E5E] mt-3'>
                                        Sorry! No result found :(
                                    </span>
                                    <span className='text-gray7 mt-2 text-sm w-[90%] mx-auto'>
                                        Oops! It seems we couldn't find any matching results for your search. Please try
                                        again with different keywords or refine your search criteria for better results.
                                    </span>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default AddMemberModal
