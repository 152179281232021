import React, { useMemo } from 'react'

import { useQueryOrgExpenses } from '@/services/expenses/queries'
import { useAuthContext } from '@/context'
import LinkedExpenseTable from '@/components/LinkedExpensesTable'

type Props = {
    employee_id: string
}
export default function LinkedExpenses({ employee_id }: Props) {
    const { selectedAccount } = useAuthContext()
    const { data } = useQueryOrgExpenses({
        queryParams: {
            id: `${selectedAccount?.org.id as string}`,
            limit: '7',
            submitter: employee_id as string,
        },
    })
    const expenses_overview = useMemo(() => data?.expenses ?? [], [data])

    return (
        <section className='bg-white rounded-xl px-7 pt-2 pb-6 mb-[5rem]'>
            <h4 className='border-b border-[#DADCE0] pb-4 font-medium'>Linked expense(s)</h4>
            <div className='overflow-y-scroll'>
                <LinkedExpenseTable expenses={expenses_overview} />
            </div>
        </section>
    )
}
