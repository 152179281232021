import React, { useMemo } from 'react'
import { ColumnDef, ColumnSort, createColumnHelper } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import { useState } from 'react'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { EmployeeType } from '@/types/org'
import { MdDelete } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import BulkDeleteEmployee from '@/components/Modals/Employee/BulkDeleteEmployee/BulkDeleteEmployee'
import { teamBadge } from '../teamBadgeColors'
import { useEmployeesContext, useImpersonationContext } from '@/context'
import { User } from 'lucide-react'
import ImpersonationPopup from '@/components/Modals/Impersonation'
import EmptyStateUserIcon from '@/assets/EmptyStateUser.svg'
import StatusColor from '@/components/utils/StatusColor'
import { useAllowAccountImitation } from '@/utils/bizpend'

interface EmployeesListProps {
    employees: EmployeeType[]
    setIsDeleteDialogOpenForUser?: (userId: string) => void
}

const EmployeesListTable = ({ employees }: EmployeesListProps) => {
    const [openModal, setOpenModal] = useState(false)
    const [imitatingUser, setImitatingUser] = useState({ openModal: false, id: '' })
    const { filterOrgMembersBy, setFilterOrgMembersBy, setSort } = useEmployeesContext()

    const { setUser } = useImpersonationContext()
    const hasAccess = useAllowAccountImitation()

    const navigate = useNavigate()

    const [rowSelection, setRowSelection] = React.useState({})

    const handleMoreDetails = (employee: EmployeeType) => {
        navigate(`/dashboard/employees/members/${employee?.id}`)
    }

    const handleSort = (sort: ColumnSort) => {
        if (sort !== filterOrgMembersBy?.bySort) {
            setFilterOrgMembersBy(prev => ({ ...prev, bySort: sort }))
            setSort(sort)
        }
    }

    const sortedEmployees = employees
        .filter(employee => employee.user)
        .sort((a, b) => a.user.first_name.localeCompare(b.user.first_name))

    const columnHelper = createColumnHelper<EmployeeType>()
    const [seeMore, setSeeMore] = useState(0)
    const handleSeeMore = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation()
        if (seeMore > 0) {
            setSeeMore(0)
        } else setSeeMore(Number.MAX_SAFE_INTEGER)
    }

    //=====the getrow model for the table caused too many renders and that's why i opted for this solution=====
    const selectedMember = useMemo(() => {
        const selectedRow = Object.keys(rowSelection)
        return sortedEmployees.filter((_, idx) => selectedRow.includes(String(idx)))
    }, [rowSelection])

    const columns: ColumnDef<EmployeeType, any>[] = [
        columnHelper.accessor('employee_id', {
            header: () => {
                return <p>Member ID</p>
            },
            cell: cell => cell.renderValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('user', {
            header: () => <>Name</>,
            cell: cell => {
                return (
                    <div className='flex items-center gap-3'>
                        {cell.row.original?.user?.profile_photo ? (
                            <img
                                src={cell.row.original.user?.profile_photo}
                                alt=''
                                className='w-[30px] h-[30px] rounded-full hidden lg:block'
                            />
                        ) : (
                            <p className='w-[30px] h-[30px] bg-[#D9D9D9] text-[#838383] hidden lg:flex justify-center items-center rounded-full tracking-wider'>
                                {capitalizeText(cell?.renderValue()?.first_name as string)?.substring(0, 1)}
                                {capitalizeText(cell?.renderValue()?.last_name as string)?.substring(0, 1)}
                            </p>
                        )}
                        {capitalizeText(cell?.renderValue()?.first_name as string)}{' '}
                        {capitalizeText(cell?.renderValue()?.last_name as string)}
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('role.name', {
            header: () => <>Role</>,
            cell: cell => capitalizeText(cell.renderValue() as string),
            enableSorting: true,
        }),
        columnHelper.accessor('teams', {
            header: () => <>Team </>,
            cell: cell => (
                <div>
                    {cell.renderValue()?.length > 0
                        ? cell.renderValue()?.map((team: any, i: number) => {
                              if (i > seeMore) return
                              return (
                                  <span
                                      style={{
                                          color: teamBadge[team.name[0].toLowerCase() as keyof typeof teamBadge],
                                          display: 'block',
                                      }}
                                  >
                                      <span className='block text-xs mt-1'>{team.name}</span>
                                  </span>
                              )
                          })
                        : 'N/A'}
                    {cell.renderValue()?.length > 1 && (
                        <span className='mt-2 block' onClick={e => handleSeeMore(e)}>
                            {cell.renderValue()?.length > seeMore ? 'see more' : 'see less'}
                        </span>
                    )}
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('user.email', {
            header: () => <p>Email Address</p>,
            cell: cell => cell.renderValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('user.createdAt', {
            header: () => <>Date Joined</>,
            cell: cell => format(new Date(cell.renderValue()!), 'MMM dd, yyyy'),
            enableSorting: true,
        }),
        columnHelper.accessor('status', {
            header: () => <>Status</>,
            cell: cell => <StatusColor status={cell.renderValue()} />,
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'actions',

            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                method: () => {
                                    handleMoreDetails(row?.row.original)
                                },
                            },
                            {
                                label: 'Log in as this user',
                                icon: User,
                                method: () => {
                                    setUser(row?.row.original)
                                    setImitatingUser({ id: row?.row.original?.id, openModal: true })
                                },
                                actionColor: 'text-[#CF491E]',
                                hidden: !hasAccess,
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    const handleRowClick = (employee: EmployeeType) => {
        navigate(`/dashboard/employees/members/${employee?.id}`)
    }

    return (
        <main className=''>
            {selectedMember.length > 1 && (
                <section className='flex items-end  mb-4'>
                    <div className='flex-1 whitespace-nowrap text-[#838383] text-sm'>
                        <span>All {selectedMember.length} users on this page are selected. </span>
                        <span>Select all {employees.length} users</span>
                    </div>
                    <div className='flex items-center gap-8'>
                        {/* <span className='inline-flex gap-1 items-center text-[#5E5E5E] cursor-pointer'>
                            <User />
                            Suspend
                        </span> */}
                        <button
                            className='inline-flex gap-1 items-center text-[#CF491E] cursor-pointer'
                            onClick={() => setOpenModal(true)}
                        >
                            <MdDelete />
                            Delete
                        </button>
                    </div>
                </section>
            )}
            {openModal && (
                <BulkDeleteEmployee bulkEmployeeDetails={selectedMember} setIsDeleteDialogOpen={setOpenModal} />
            )}
            <Table
                data={employees}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                handleSort={handleSort}
                emptyStateImg={EmptyStateUserIcon}
                emptyStateDescription="Simplify your bill Processing - handle invoices and utility payments with ease. Click on the 'Create Bill' button at the top right corner to get started"
                emptyStateTitle='Streamlined Management of Invoices and Utility Bills'
            />

            {imitatingUser.openModal && (
                <ImpersonationPopup imitatingUser={imitatingUser} setImitatingUser={setImitatingUser} />
            )}
        </main>
    )
}

export default EmployeesListTable
