import { cn } from '@/lib/styling'
import { cva } from 'class-variance-authority'
import { Variants, motion } from 'framer-motion'
import { ReactNode, useEffect, useRef, useState } from 'react'

const modalVariants: Variants = {
    hidden: {
        opacity: 0,
        y: '-100%',
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.3,
        },
    },
    exit: {
        opacity: 0,
        y: '100%',
    },
}

type Props = {
    children: ReactNode
    className?: string
    modalClassName?: any
    variants?: any
    exit?: { y: string; opacity: number }
    shouldConfirmClose?: boolean
    closeModal?: () => void
    formId?: string
}

const ModalWrapper = ({
    children,
    className,
    modalClassName,
    variants,
    exit,
    closeModal,
    shouldConfirmClose = false,
    formId,
}: Props) => {
    const overlayRef = useRef<HTMLDivElement>(null)
    const [isConfirming, setIsConfirming] = useState(false)
    const [isFormDirty, setIsFormDirty] = useState(false)

    useEffect(() => {
        const form = formId && (document.getElementById(formId) as HTMLFormElement)

        if (form) {
            const handleFormChange = () => {
                setIsFormDirty(true)
            }
            form.addEventListener('change', handleFormChange)
            return () => {
                form.removeEventListener('change', handleFormChange)
            }
        }
    }, [formId])

    useEffect(() => {
        const handleClickOnOverlay = (event: any) => {
            if (overlayRef.current && overlayRef.current === event.target) {
                if (shouldConfirmClose && isFormDirty) {
                    setIsConfirming(true)
                } else {
                    closeModal?.()
                }
            }
        }
        overlayRef?.current?.addEventListener('click', handleClickOnOverlay)
        return () => {
            if (overlayRef.current) {
                overlayRef.current.removeEventListener('click', handleClickOnOverlay)
            }
        }
    }, [shouldConfirmClose, closeModal, isFormDirty])

    const defaultclassName = cva(`fixed top-0 left-0 w-full h-full ${'flex'} items-center justify-center z-[1000]`)

    const defaultModalClass = cva(`w-max min-h-max bg-white rounded-lg z-[1000] shadow-xl modal`)

    return (
        <>
            <motion.div
                className={cn(defaultclassName({ className }))}
                style={{
                    backgroundColor: `${'rgba(0, 0, 0, 0.6)'} `,
                }}
                ref={overlayRef}
                aria-label='modal_wrapper'
                exit={exit ?? { opacity: 0 }}
            >
                <motion.div
                    className={cn(defaultModalClass({ ...modalClassName }))}
                    initial='hidden'
                    animate='visible'
                    variants={variants ?? modalVariants}
                    aria-label='modal_content'
                    onClick={e => {
                        e.stopPropagation()
                    }}
                    exit={exit ?? { y: '100%', opacity: 0 }}
                >
                    {children}
                </motion.div>

                {isConfirming && (
                    <motion.div
                        className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1100]'
                        onClick={() => setIsConfirming(false)}
                    >
                        <div className='bg-white p-6 rounded shadow-xl' onClick={e => e.stopPropagation()}>
                            <h3 className=''>Are you sure you want to close?</h3>
                            <div className='mt-4 flex justify-end gap-3'>
                                <button onClick={() => setIsConfirming(false)} className='text-errorRed text-sm'>
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        setIsConfirming(false)
                                        closeModal?.()
                                    }}
                                    className='text-brand text-sm'
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )}
            </motion.div>
        </>
    )
}

export default ModalWrapper
