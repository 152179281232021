import { Table } from '@tanstack/react-table'
import { Pagination as PaginationType } from '@/types/utils'
import { MoveRight } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select'
import React from 'react'

interface PaginationProps {
    setCurrentPageNumber: (arg: number) => void
    table?: Table<any>
    paginationOption?: number[]
    pagination: PaginationType
    setIsPageNumberClicked?: React.Dispatch<React.SetStateAction<boolean>>
}

const V2Pagination = ({
    setCurrentPageNumber,
    table,
    pagination,
    setIsPageNumberClicked,
    paginationOption,
}: PaginationProps) => {
    const { currentPage } = pagination

    return (
        <div className='flex justify-between items-center  gap-3 sm:gap-5'>
            <div>
                <Select
                    name='page number'
                    value={currentPage?.toString()}
                    onValueChange={val => {
                        setIsPageNumberClicked?.(true)
                        setCurrentPageNumber(Number(val))
                    }}
                >
                    <SelectTrigger className=' border-none text-xs h-8 w-24 bg-[#F9F9F9] text-[#5E5E5E]'>
                        <SelectValue placeholder={''} />
                    </SelectTrigger>
                    <SelectContent className='w-20 text-xs'>
                        {(paginationOption ?? table?.getPageOptions())?.map((page: number) => (
                            <SelectItem value={(page + 1).toString()} className='text-sm'>
                                Page {page + 1}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className='flex gap-2 justify-center'>
                {table && table?.getState()?.pagination?.pageIndex + 1 >= 4 && (
                    <button
                        className={`w-8 h-8 rounded-md border border-[#A8A8A8] `}
                        key={1}
                        title='pagination button'
                        type='button'
                        onClick={() => {
                            table.setPageIndex(0)
                            setCurrentPageNumber(1)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        1
                    </button>
                )}
                {table && table.getState().pagination.pageIndex + 1 >= 5 && (
                    <button
                        disabled
                        className={`w-8 h-8 rounded-md border border-[#A8A8A8] `}
                        key={'ellipse-1'}
                        title='pagination button'
                    >
                        ...
                    </button>
                )}
                {/* 2 pages before */}
                {table && table.getState().pagination.pageIndex - 1 > 0 && (
                    <button
                        className={`w-8 h-8 rounded-md border border-[#A8A8A8] `}
                        key={'num-0'}
                        title='pagination button'
                        type='button'
                        onClick={() => {
                            table.setPageIndex(table.getState().pagination.pageIndex - 2)
                            setCurrentPageNumber(table.getState().pagination.pageIndex - 1)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        {table.getState().pagination.pageIndex - 1}
                    </button>
                )}
                {/* 1 page before */}
                {table && table.getState().pagination.pageIndex > 0 && (
                    <button
                        className={`w-8 h-8 rounded-md border border-[#A8A8A8] `}
                        key={'num-1'}
                        title='pagination button'
                        type='button'
                        onClick={() => {
                            table.setPageIndex(table.getState().pagination.pageIndex - 1)
                            setCurrentPageNumber(table.getState().pagination.pageIndex)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        {table.getState().pagination.pageIndex}
                    </button>
                )}
                <button
                    className={`w-8 h-8 rounded-md  bg-[#454ADE] text-white`}
                    key={'num-2'}
                    title='pagination button'
                    type='button'
                >
                    {table && table.getState().pagination.pageIndex + 1}
                </button>
                {/* 1 page after */}
                {table && table.getState().pagination.pageIndex + 2 <= table?.getPageCount() && (
                    <button
                        className={`w-8 h-8 rounded-md border border-[#A8A8A8] `}
                        key={'num-3'}
                        title='pagination button'
                        type='button'
                        onClick={() => {
                            table.setPageIndex(table.getState().pagination.pageIndex + 1)
                            setCurrentPageNumber(table.getState().pagination.pageIndex + 2)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        {table.getState().pagination.pageIndex + 2}
                    </button>
                )}
                {/* 2 page after */}
                {table && table.getState().pagination.pageIndex + 3 <= table?.getPageCount() && (
                    <button
                        className={`w-8 h-8 rounded-md border border-[#A8A8A8] `}
                        key={'num-4'}
                        title='pagination button'
                        type='button'
                        onClick={() => {
                            table.setPageIndex(table.getState().pagination.pageIndex + 2)
                            setCurrentPageNumber(table.getState().pagination.pageIndex + 3)
                            setIsPageNumberClicked?.(true)
                        }}
                    >
                        {table.getState().pagination.pageIndex + 3}
                    </button>
                )}

                {table && table.getState().pagination.pageIndex + 3 < table?.getPageCount() - 1 && (
                    <button
                        className={`w-8 h-8 rounded-md border border-[#A8A8A8] `}
                        key={'num-5'}
                        disabled
                        title='pagination button'
                        type='button'
                    >
                        ...
                    </button>
                )}
                {table && table.getState().pagination.pageIndex + 3 < table?.getPageCount() && (
                    <>
                        <button
                            className={`w-8 h-8 rounded-md border border-[#A8A8A8] `}
                            key={'num-6'}
                            title='pagination button'
                            type='button'
                            onClick={() => {
                                table.setPageIndex(table.getPageCount())
                                setCurrentPageNumber(table.getPageCount())
                                setIsPageNumberClicked?.(true)
                            }}
                        >
                            {table?.getPageCount()}
                        </button>
                    </>
                )}
            </div>

            <div>
                {((paginationOption && paginationOption?.length > 0) ||
                    (table && table?.getPageOptions()?.length > 1)) && (
                    <div className='flex gap-5'>
                        <button
                            className='flex items-center justify-center gap-1 text-xs rounded-md  disabled:opacity-40 disabled:cursor-not-allowed disabled:text-gray2'
                            disabled={+currentPage === 1}
                            onClick={() => {
                                table?.previousPage()
                                setCurrentPageNumber(+currentPage - 1)
                                setIsPageNumberClicked?.(true)
                            }}
                            title='pagination button'
                            type='button'
                        >
                            <MoveRight className='rotate-180' />
                            Previous
                        </button>
                        <button
                            className='flex items-center justify-center gap-1 text-xs rounded-md  disabled:opacity-40 disabled:cursor-not-allowed disabled:text-gray2'
                            disabled={+currentPage === pagination?.totalPages}
                            onClick={() => {
                                table?.nextPage()
                                setCurrentPageNumber(+currentPage + 1)
                                setIsPageNumberClicked?.(true)
                            }}
                            title='pagination button'
                            type='button'
                        >
                            Next
                            <MoveRight />
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default V2Pagination
