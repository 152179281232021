import { useGetRoles } from '@/services/auth/queries'
import { RoleType } from '@/types/roles'
import { ReactNode, createContext } from 'react'
import { useAuthContext } from '.'

export type AuthContextDefaultTypes = {
    roles: RoleType[]
}

const defaultValues: AuthContextDefaultTypes = {
    roles: [],
}

export const RolesContext = createContext(defaultValues)

export default function RolesContextProvider({ children }: { children: ReactNode }) {
    const { loggedIn } = useAuthContext()
    const { data: roles = [] } = useGetRoles({ enabled: !!loggedIn })

    return <RolesContext.Provider value={{ roles }}>{children}</RolesContext.Provider>
}
