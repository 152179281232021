import React, { Dispatch, SetStateAction, useEffect } from 'react'
import {
    ColumnDef,
    ColumnSort,
    RowSelectionState,
    SortingState,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { SortIcon, SortIcon2 } from '@/assets/assets.export'
import { Pagination as PaginationType } from '@/types/utils'
import V2Pagination from './V2Pagination'

interface TableProps<TData, TColumns> {
    columns: TColumns[]
    data: TData[]
    handleSort?: (arg: ColumnSort) => void
    handlePageChange?: (pageIndex: number) => void
    currentPageNumber?: number
    setCurrentPageNumber?: Dispatch<SetStateAction<number>>
    pagination_data?: PaginationType
    rowSelection?: RowSelectionState
    setRowSelection?: Dispatch<SetStateAction<RowSelectionState>>
    setIsPageNumberClicked?: React.Dispatch<React.SetStateAction<boolean>>
    handleRowClicked?: (data: any) => void
    rowClickable?: boolean
    emptyStateImg?: string
    emptyStateTitle?: string
    emptyStateDescription?: string
}

const V2Table = <TData, TColumns extends ColumnDef<TData, unknown>>({
    data,
    columns,
    handleSort,
    setCurrentPageNumber,
    pagination_data,
    rowSelection,
    setRowSelection,
    setIsPageNumberClicked,
    handleRowClicked,
    rowClickable = false,
    emptyStateTitle,
    emptyStateDescription,
    emptyStateImg,
}: TableProps<TData, TColumns>) => {
    const [sorting, setSorting] = React.useState<SortingState>([])

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            rowSelection,
        },
        enableRowSelection: rowSelection ? true : false,
        manualPagination: true,
        onRowSelectionChange: rowSelection ? setRowSelection : undefined,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        pageCount: pagination_data?.totalPages,
    })

    const handleRowData = (data: any) => {
        handleRowClicked?.(data)
    }

    useEffect(() => {
        handleSort?.(sorting[0])
    }, [sorting, handleSort])

    return (
        <div className='inline-block  border border-[#DADCE0] rounded-lg min-w-full'>
            <table className='w-full leading-normal rounded-lg border-collapse '>
                <thead className=''>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className='bg-[#f9f9f9] border-b border-[#DADCE0]'>
                            {headerGroup.headers.map(header => {
                                if (header?.id === 'actions') return <span>&nbsp;</span>
                                return (
                                    <th
                                        className='px-3 py-3 font-semibold  text-left text-xs tracking-wider text-dark'
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none flex items-center gap-2'
                                                        : '',
                                                }}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getCanSort() ? (
                                                    sorting.length > 0 && sorting[0].id === header.id ? (
                                                        sorting[0].desc ? (
                                                            <SortIcon className='rotate-180' />
                                                        ) : (
                                                            <SortIcon />
                                                        )
                                                    ) : (
                                                        <SortIcon2 />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                {table.getRowModel().rows.length > 0 ? (
                    <tbody>
                        {table.getRowModel().rows.map((row, idx) => (
                            <tr
                                key={row.id}
                                className={`border-[#DADCE0] ${idx % 2 ? 'bg-gray-50' : 'bg-white'}  ${
                                    rowSelection && (table?.getIsAllRowsSelected() ? '!bg-[#EFEFF9] ' : '!bg-white')
                                } hover:bg-[#EFEFF9] ${rowClickable ? 'cursor-pointer' : 'cursor-default'} ${
                                    pagination_data ? 'border-t ' : 'border-b last:border-none'
                                }`}
                                onClick={() => {
                                    handleRowData(row.original)
                                }}
                            >
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td className={`px-3 py-3 text-sm   `} key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td colSpan={100}>
                                <div className='h-[400px] flex flex-col text-center justify-center items-center'>
                                    {emptyStateImg && (
                                        <img src={emptyStateImg} alt='widget' className=' max-h-[180px]' />
                                    )}
                                    <span className='text-xl font-medium text-[#5E5E5E] mt-3'>{emptyStateTitle}</span>
                                    <span className='text-gray7 mt-2 text-sm w-[90%] mx-auto'>
                                        {emptyStateDescription}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>
            {pagination_data?.totalPages && table && setCurrentPageNumber ? (
                <div className='px-5 py-3 border-t border-[#DADCE0]'>
                    <V2Pagination
                        table={table}
                        pagination={pagination_data}
                        setCurrentPageNumber={setCurrentPageNumber}
                        setIsPageNumberClicked={setIsPageNumberClicked}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default V2Table
