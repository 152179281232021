
export type filter_by_Props = {
    [key: string]: any
}

export const modalVariants = {
    hidden: {
        opacity: 0,
        x: '100%',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.3,
        },
    },
}

//----------------------------------------------------
const date = new Date()
export const FilterVendorUtils = {
    status_types: ['active', 'inactive', 'suspended'],

    periods: [
        {
            period: '24 Hours ago',
            type: '24hrs',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)}`
            },
        },
        {
            period: 'Last 7 days',
            type: '7days',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)}`
            },
        },
        {
            period: 'Last month',
            type: 'last_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 1, date.getDate() - 1)}`
            },
        },
        {
            period: 'Last 6 months',
            type: '6_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 6, date.getDate() - 1)}`
            },
        },
    ],
    amount: [
        {
            amount: 'Less than ₦50,000.00',
            type: 'lessthan_50k',
            method: () => {
                return {
                    max: '49,999',
                    min: '0',
                }
            },
        },
        {
            amount: '₦50,000.00 - ₦200,000.00 ',
            type: 'lessthan_200k',
            method: () => {
                return {
                    max: '199,999',
                    min: '50,000',
                }
            },
        },
        {
            amount: '₦201,000.00 - ₦500,000.00 ',
            type: 'lessthan_500k',
            method: () => {
                return {
                    max: '499,999',
                    min: '201,000',
                }
            },
        },
        {
            amount: '₦501,000.00 - ₦1,000,000.00 ',
            type: 'lessthan_1m',
            method: () => {
                return {
                    max: '999,999',
                    min: '501,000',
                }
            },
        },
        {
            amount: '₦1,001,000.00 - ₦5,000,000.00',
            type: 'lessthan_5m',
            method: () => {
                return {
                    max: '4,999,999',
                    min: '1,001,000',
                }
            },
        },
        {
            amount: '₦5,001,000.00 - ₦10,000,000.00',
            type: 'lessthan_10m',
            method: () => {
                return {
                    max: '9,999,999',
                    min: '5,000,000',
                }
            },
        },
        {
            amount: '₦10,000,000.00+',
            type: 'greaterthan_10m',
            method: () => {
                return {
                    max: '9,999,999,999,999,999,999,999,999,999,999',
                    min: '10,000,000',
                }
            },
        },
    ],
}

export const filter_consts = {
    initial: 'initial',
    max: 'max',
    min: 'min',
}
