// import { Vendor } from "./vendor"

type Vendor = {
    name: string
    address: string
    contact: string
}
export const APPROVERSTIMELINE_CONST = {
    0: 'First',
    1: 'Second',
    2: 'Third',
    3: 'Fourth',
    4: 'Fifth',
    5: 'Sixth',
    6: 'Seventh',
}

export type BillRequest = {
    id: string
    vendor: Vendor
    amount: number
    date: string
    type: 'INVOICE' | 'UTILITY'
    description: string
    status: 'PENDING' | 'APPROVED' | 'DECLINED' | 'PAID'
}

export enum BillStatus {
    draft = 'DRAFT',
    paid = 'PAID',
    pending = 'PENDING',
    approved = 'APPROVED',
    scheduled = 'SCHEDULED',
    declined = 'DECLINED',
}
