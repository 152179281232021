import { useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { Button } from '@/components'
import SingleEmployeeTeamTable from '../../components/SingleEmployeeTeamTable'
import { useEmployeesContext } from '@/context'
import { useNavigate } from 'react-router-dom'

export default function EmployeeTeam() {
    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false)
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState('')

    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)

    const { setShowTeamBreadCrumbs, teamsObj, amountOfEmployee } = useEmployeesContext()
    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }

    return (
        <main className=''>
            <section className='text-lg text-[#838383] border-b border-[#DADCE0] pt-0 py-5 px-[2.63rem]'>
                <span
                    className='font-semibold text-[#202020] cursor-pointer'
                    onClick={() => {
                        setShowTeamBreadCrumbs(false)
                        navigate('/dashboard/employees/teams')
                    }}
                >
                    Teams
                </span>
                {' > '} {teamsObj?.name}
                <span className={`bg-[#D5D5F5] ml-2  rounded-full px-3 text-md`}>
                    {amountOfEmployee?.current_team_size}
                </span>
            </section>
            <div className='lg:w-[93%] mx-auto mt-10 px-2 lg:px-0'>
                <section className='flex justify-between flex-col lg:flex-row gap-4 lg:gap-0'>
                    <div className='border border-[#DADCE0] h-[56px] w-full lg:w-[25rem] rounded-lg flex items-center overflow-hidden'>
                        <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='Search team members'
                            className='flex-1 outline-none bg-[#FAFAFD]'
                            onKeyDown={handleKeyDown}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className='flex gap-6'>
                        <Button className='w-full lg:w-auto' onClick={() => setIsAddMemberModalOpen(true)}>
                            Add new member
                        </Button>
                    </div>
                </section>
                <section className='overflow-x-scroll'>
                    <SingleEmployeeTeamTable
                        searchQuery={searchQuery}
                        openModal={isAddMemberModalOpen}
                        onCloseModal={setIsAddMemberModalOpen}
                        backspace={backspace}
                        backspaceClicked={backspaceClicked}
                        setBackSpace={setBackSpace}
                        setBackSpaceClicked={setBackSpaceClicked}
                    />
                </section>
            </div>
        </main>
    )
}
