import { Button, Input } from '@/components'
import { useConfirmToken } from '@/services/auth/mutations'
import { Controller, Control, FieldValues, UseFormGetValues, UseFormWatch } from 'react-hook-form'
import { AiOutlineArrowLeft } from 'react-icons/ai'

interface Step2Props {
    toggleStep: (step: number) => void
    control: Control<FieldValues, any>
    values: UseFormGetValues<FieldValues>
    watch: UseFormWatch<FieldValues>
}

const ResetStep2 = ({ toggleStep, control, values, watch }: Step2Props) => {
    const watchOtp = watch('otp')?.length > 3
    const { mutate: confirmTokenFn, isLoading } = useConfirmToken({
        onSuccess: () => {
            toggleStep(3)
        },
    })
    const nextStep = () => {
        confirmTokenFn({
            email: values()?.email,
            token: values()?.otp,
        })
    }

    return (
        <div className='w-[90%] md:w-[45%] max-w-[1100px] mx-auto my-12'>
            <div className='flex justify-end mb-8'>
                <span className='py-2 px-4 bg-[#EFEFF9] rounded-2xl flex items-center gap-2'>
                    2 / 3 <span className='font-semibold'> steps</span>
                </span>
            </div>

            <section className='pt-4'>
                <h2 className='text-[#31254B] text-2xl mb-4 font-semibold'>Verify Your Email</h2>
                <p className='text-[#202020] mb-3 text-base'>Simply enter the OTP sent to verify your email address</p>
                <span className='text-[#454ADE] bg-[#EFEFF9] rounded-3xl px-4 p-2 font-medium inline-block mb-10'>
                    {values()?.email}
                </span>

                <div className='flex flex-col md:flex-row mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Enter OTP
                        </label>
                        <Controller
                            name='otp'
                            rules={{
                                required: true,
                                minLength: 4,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input type='number' className='h-16' {...field} placeholder='Enter your OTP' />
                            )}
                        />
                    </div>
                </div>

                <div className='mt-[160px] flex justify-end gap-6'>
                    <button type='button' onClick={() => toggleStep(1)} className='flex items-center gap-3 text-[14px]'>
                        <AiOutlineArrowLeft fontSize={15} className='mt-1' />
                        go back
                    </button>
                    <Button disabled={!watchOtp} variant='default' onClick={nextStep} loading={isLoading}>
                        Next
                    </Button>
                </div>
            </section>
        </div>
    )
}

export default ResetStep2
