import { MilestoneType } from '@/types/payroll'
import { intervalToDuration } from 'date-fns'

export const getCompletedMileStone = (data: MilestoneType[]) => {
    return data?.filter(milestone => milestone?.payment_status === 'paid')
}
export const getCompletedMileStoneAmount = (data: MilestoneType[]) => {
    return data
        ?.filter(milestone => milestone?.payment_status === 'paid')
        .reduce((sum, milestone) => sum + Number(milestone?.amount), 0)
}

export const convertintervalToDurationObjToString = (startDate: string, endDate: string) => {
    const dateObj = intervalToDuration({
        start: new Date(endDate),
        end: new Date(startDate),
    })
    let formattedDateInWords = ''
    for (const date in dateObj) {
        const dateNum = dateObj?.[date as keyof Duration]
        if (dateNum !== 0) {
            formattedDateInWords = `${formattedDateInWords} ${dateNum} ${
                dateNum === 1 ? date.replaceAll('s', '') : date
            }`
        }
    }
    return { formattedDateInWords }
}
