import { Dispatch, SetStateAction } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { Button } from '@/components'
import { useSuspendBenefitPlan } from '@/services/benefits/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { OrgBenefitProps } from '@/types/benefits'
import { useParams } from 'react-router-dom'

type Props = {
    closeModal: Dispatch<SetStateAction<boolean>>
    benefit: OrgBenefitProps
}
export default function SuspendBenefitPlanPrompt({ closeModal }: Props) {
    const { benefit_id } = useParams()
    const { mutate: suspendBenefitPlan, isLoading: isSuspendingPlan } = useSuspendBenefitPlan({
        queryParams: {
            id: benefit_id as string,
        },
        onSuccess: () => {
            closeModal(false)
            toastHandler({ message: 'Plan suspended sucessfully', state: 'success' })
        },
    })

    return (
        <ModalWrapper>
            <section className='w-96 max-h-80 overflow-scroll p-3 px-4'>
                <h2 className='text-[#CF491E] text-2xl mb-5'>Attention</h2>
                <p className='whitespace-pre-wrap mb-5 text-sm font-medium'>
                    Are you sure you want to suspend this plan?
                </p>
                <div className='flex justify-end gap-4 mt-10'>
                    <Button variant={'outline'} size={'lg'} onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant={'error'}
                        size={'lg'}
                        onClick={() => suspendBenefitPlan({})}
                        loading={isSuspendingPlan}
                    >
                        Yes
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
