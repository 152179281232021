import ProgressBar from '@/components/ProgressBar'
import { CiSearch } from 'react-icons/ci'
import LinkedBudgetTransactionsTable from '../components/LinkedBudgetTransactionsTable'
import { useMemo } from 'react'
import { useGetBudgetTransactions } from '@/services/budget/queries'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '@/context'
import EmptyStates from '@/components/EmptyStates'
import emptyTransaction from '@/assets/empty-state-transactions.svg'

export default function TransactionsLinkedToBudget() {
    const { budget_id } = useParams()
    const { selectedAccount } = useAuthContext()
    const { data: budgetTransactionData, isLoading: isLoadingBudgetTransaction } = useGetBudgetTransactions({
        queryParams: {
            budget_id: budget_id as string,
            org: selectedAccount?.org.id as string,
        },
    })

    const budgetTransaction = useMemo(() => budgetTransactionData ?? [], [budgetTransactionData])

    if (isLoadingBudgetTransaction) return <ProgressBar />
    return (
        <section className='bg-white pb-[7rem] h-full overflow-y-scroll'>
            <section className='mt-6'>
                <div className='border border-[#DADCE0] h-[56px] w-[80%] lg:w-[30%] rounded-lg flex items-center overflow-hidden '>
                    <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                        <CiSearch fontSize={25} />
                    </div>
                    <input
                        type='text'
                        placeholder='Search by transaction ID or initiator'
                        className='h-full w-[90%] outline-none'
                        // onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
            </section>
            {budgetTransaction.length > 0 && (
                <h2 className='font-medium text-lg text-[#5E5E5E] my-6'>
                    These are transactions linked to this budget
                </h2>
            )}

            <section className='mt-6 mx-[2px]'>
                {budgetTransaction.length > 0 ? (
                    <div className='overflow-y-scroll'>
                        <LinkedBudgetTransactionsTable transactions={budgetTransaction} />
                    </div>
                ) : (
                    <EmptyStates
                        title='No Recent Transactions'
                        description="You haven't linked any transaction yet to this budget. Start exploring our services and link a budget to the transaction to see it listed here."
                        image={emptyTransaction}
                    />
                )}
            </section>
        </section>
    )
}
