import { RoleType } from '@/types/roles'
import { createQuery } from '../api/query'
import {
    LoginResponse,
    OverviewInsight,
    QueryOrgKYB,
    QueryOrgRequestAccessRes,
    UserTransactionExpenseType,
} from './types'

export const keyUser = [`/me`]
export const userInsightkey = ['user-insights']
export const userExpenseTransaction = ['/user-expense-transaction-data']
export const orgInsightkey = ['org-insights']
export const orgKYBkey = ['org-kyb']
export const orgRequestAccess = ['access-requests']
export const orgSingleRequestAccess = (id: string) => [id, 'access-requests']

export const useQueryMe = createQuery<LoginResponse>({
    key: keyUser,
    url: '/me',
})

export const useGetRoles = createQuery<RoleType[]>({
    key: ['roles'],
    url: '/roles',
})

export const useGetOrgInsight = createQuery<OverviewInsight>({
    key: orgInsightkey,
    url: '/get-company-insights',
})
export const useGetOrgWallet = createQuery<OverviewInsight>({
    key: orgInsightkey,
    url: '/get-org-wallet',
})
export const useGetUserInsight = createQuery<OverviewInsight>({
    key: userInsightkey,
    url: '/get-user-insights',
})
export const useGetUserAccountData = createQuery<UserTransactionExpenseType>({
    key: userExpenseTransaction,
    url: '/user-account-data',
})

export const useGetCompanyKYB = createQuery<QueryOrgKYB>({
    url: '/get-org-kyb',
    key: orgKYBkey,
})

export const useGetAllRequestAccess = createQuery<QueryOrgRequestAccessRes>({
    url: '/access-requests',
    key: orgRequestAccess,
})
export const useGetSingleRequestAccess = createQuery<QueryOrgRequestAccessRes>({
    url: '/access-request',
    key: orgSingleRequestAccess,
})
