import { FILE_TYPE } from './enums'

export const NairaToKobo = (amount: number | string) => {
    const amt = Number(amount)
    return amt
}

export const handleShowImage = (fileUrl: string, type: FILE_TYPE, admin: boolean) => {
    if (type === FILE_TYPE.image) {
        window.open(`${admin ? '/admin/' : '/'}dashboard/view-file?imgUrl=${encodeURI(fileUrl)}`, '_blank')
        return
    } else if (type === FILE_TYPE.pdf) {
        window.open(fileUrl, '_blank')
        return
    } else {
        console.error('Unsupported file type')
    }
}
