export enum ModalType {
    manualModal = 'manual',
    scannedModal = 'scan',
}
export enum ExpenseStatus {
    approved = 'approved',
    pending = 'pending',
    denied = 'denied',
    closed = 'closed',
    reimbursed = 'reimbursed',
    draft = 'draft',
    partialApproval = 'partially approved',
    processing = 'processing',
    disabled = 'disabled',
}

export enum expenseStatus {
    approved = 'approved',
    pending = 'pending',
    denied = 'denied',
    closed = 'closed',
    reimbursed = 'reimbursed',
    draft = 'draft',
}

export const advancedFilteringProps = {
    status: '',
    period: { from: null, to: null, time_in_words: '' },
    expense_type: '',
    expense_category: '',
    created_by: '',
    approved_by: '',
    reimbursed_by: '',
    amount: { min: '', max: '', amount_in_words: '' },
}

export const memberAdvancedFilteringProps = {
    role: '',
    period: { from: null, to: null, time_in_words: '' },
    amount: { min: '', max: '', amount_in_words: '' },
}
