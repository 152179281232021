import { createContext, useState } from 'react'

export type settingsStateProps = {
    spendControlOption: string
    setSpendControlOption: React.Dispatch<React.SetStateAction<string>>
}

const settingsState: settingsStateProps = {
    spendControlOption: 'Home',
    setSpendControlOption: () => undefined,
}

export const SettingsContext = createContext(settingsState)

export const SettingsContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [spendControlOption, setSpendControlOption] = useState<string>('Home')

    const value = {
        spendControlOption,
        setSpendControlOption,
    }
    return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}
