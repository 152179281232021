import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import ContractPayroll from './partials/ContractPayroll/ContractPayroll'
import { motion } from 'framer-motion'
import OrgContractPayHistoryPage from './partials/OrgContractPayHistory/OrgContractPayHistory'
import { NewViewSwitch } from '@/components/SwitchOrgInView/NewViewSwitch'
import { ADMIN_CONTRACT_PERMISSIONS } from '@/utils/permisson-helper'
import { AccessPermissionHOC } from '@/hoc/AccessPermissionHOC'
import UserContract from '../UserContract/UserContract'

const contract_routes_const = {
    list: 'contract_list',
    pay_history: 'pay_history',
    contract: 'contract',
}
export default function Contract() {
    const { contract_subRoute } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)
    const navigateRoute = (path: string) => {
        const queryParams = location.search

        navigate(`/dashboard/contract/${path}${queryParams}`)
    }
    const hasOrgContractAccess = queryParams.get('accountType') === 'company'

    return (
        <>
            <AccessPermissionHOC requiredPermissions={ADMIN_CONTRACT_PERMISSIONS}>
                <section className=' px-2 md:px-0'>
                    <section className='border-b border-gray4'>
                        <NewViewSwitch
                            personalRoute='/dashboard/contract/contract_list'
                            companyRoute='/dashboard/contract/contract_list'
                        />
                    </section>
                    {hasOrgContractAccess && (
                        <section className='h-full mt-5 px-2 md:px-0'>
                            <Tabs defaultValue={contract_subRoute} value={contract_subRoute} className='border-none'>
                                <TabsList className='border-none'>
                                    <TabTriggersContainer>
                                        <TabsTrigger
                                            value={contract_routes_const.list}
                                            onClick={() => navigateRoute(`${contract_routes_const.list}`)}
                                            className={`relative border-none ${
                                                contract_routes_const.list === contract_subRoute ? '!text-white' : ''
                                            } text-sm`}
                                        >
                                            {contract_routes_const.list === contract_subRoute && (
                                                <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                            )}
                                            <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                                Contracts
                                            </motion.span>
                                        </TabsTrigger>
                                        <TabsTrigger
                                            value={contract_routes_const.pay_history}
                                            onClick={() => navigateRoute(`${contract_routes_const.pay_history}`)}
                                            className={`relative border-none ${
                                                contract_routes_const.pay_history === contract_subRoute
                                                    ? '!text-white'
                                                    : ''
                                            } text-sm`}
                                        >
                                            {contract_routes_const.pay_history === contract_subRoute && (
                                                <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                            )}
                                            <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                                Pay history
                                            </motion.span>
                                        </TabsTrigger>
                                    </TabTriggersContainer>
                                </TabsList>
                                <TabsContent value={contract_routes_const.list}>
                                    <PageContent>
                                        <ContractPayroll />
                                    </PageContent>
                                </TabsContent>

                                <TabsContent value={contract_routes_const.pay_history}>
                                    <PageContent>
                                        <OrgContractPayHistoryPage />
                                    </PageContent>
                                </TabsContent>
                            </Tabs>
                        </section>
                    )}
                </section>
            </AccessPermissionHOC>

            {!hasOrgContractAccess && <UserContract />}
        </>
    )
}
