import TimelineJourney from '@/components/TimelineJourney'
import PageContent from '@/layout/PageContent'
import { Invoice } from '@/types/invoice'
import React from 'react'

type Props = {
    invoiceDetails?: Invoice
}
const InvoiceTimeLine = ({ invoiceDetails }: Props) => {
    const timelines = invoiceDetails?.timeline?.events ?? []
    return (
        <PageContent>
            <section className='mt-10  p-6 bg-white rounded-2xl space-y-8'>
                <h2 className='border-gray6 font-medium mb-6 text-lg border-b pb-2'>Invoice Journey timeline</h2>
                <TimelineJourney timelines={timelines} />
            </section>
        </PageContent>
    )
}

export default InvoiceTimeLine
