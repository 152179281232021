import { Button } from '@/components'
import DeclineLeaveRequest from '@/components/Modals/Payroll/LeaveRequest/DeclineLeaveRequest'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, usePayrollContext } from '@/context'
import { useApproveLeave, useCancelLeave } from '@/services/payroll/mutations'
import { LeaveRequestType } from '@/services/payroll/types'
import { LeaveStatus, UserTypeLeave } from '@/types/payroll'
import { useState } from 'react'

type Props = {
    leaveData?: LeaveRequestType | UserTypeLeave
    loading: boolean
}
const LeavePermissionWrapper = ({ leaveData, loading }: Props) => {
    const [declineLeave, setDeclineLeave] = useState(false)
    const { selectedAccount } = useAuthContext()
    const { setSelectedLeaveData, setAssignLeaveModal } = usePayrollContext()

    const { mutate: approveLeaveFn, isLoading: isApprovingLeave } = useApproveLeave({
        queryParams: {
            leave_id: (leaveData?._id ?? leaveData?.id) as string,
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: data => {
            setSelectedLeaveData(prev => {
                return prev ? { ...prev, status: (data as LeaveRequestType).status } : undefined
            })
            toastHandler({ message: 'Leave has been approved', state: 'success' })
        },
    })
    const { mutate: cancelLeaveFn, isLoading: isCanceling } = useCancelLeave({
        queryParams: {
            leave_id: (leaveData?._id ?? leaveData?.id) as string,
        },
        onSuccess: data => {
            setSelectedLeaveData(prev => {
                return prev ? { ...prev, status: (data as LeaveRequestType).status } : undefined
            })
            toastHandler({ message: 'Leave has been canceled', state: 'success' })
        },
    })

    const leaveApprovers = leaveData?.approvers ?? []
    const isApprover = leaveApprovers?.some(
        approver => (approver?.account?.id ?? approver?.account?._id) === (selectedAccount?.id ?? selectedAccount?._id)
    )
    const canEditLeave =
        (leaveData?.account?.id ?? leaveData?.account?._id) === (selectedAccount?.id ?? selectedAccount?._id) &&
        leaveData?.status === LeaveStatus.PENDING

    return (
        <>
            {
                <div className='flex flex-col lg:flex-row gap-3 lg:gap-0 justify-between mt-10 w-full lg:w-3/4'>
                    {canEditLeave && (
                        <div className='flex flex-1 w-full lg:w-auto lg:justify-end gap-5'>
                            <Button
                                className='bg-transparent text-[#202020] border border-[#202020] hover:bg-transparent  min-w-[130px] min-h-[35px] hover'
                                onClick={() => setAssignLeaveModal(true)}
                                disabled={isCanceling || loading}
                            >
                                Edit
                            </Button>
                            <Button
                                variant={'error'}
                                className=' w-fit lg:w-auto  min-w-[130px] min-h-[35px]'
                                onClick={() => cancelLeaveFn({})}
                                loading={isCanceling || loading}
                            >
                                Cancel
                            </Button>
                        </div>
                    )}
                    {isApprover &&
                        (leaveData?.status == LeaveStatus.PENDING ||
                            leaveData?.status == LeaveStatus.PARTIALLY_APPROVED) && (
                            <div className='flex flex-1 w-full lg:w-auto lg:justify-end gap-5'>
                                <Button
                                    className='bg-[#CF491E] w-full lg:w-auto  min-w-[130px] min-h-[35px] bg-transparent text-errorRed hover:bg-transparent border-[#CF491E] border disabled:bg-transparent disabled:opacity-70 disabled:text-'
                                    onClick={() => setDeclineLeave(true)}
                                    disabled={isApprovingLeave || loading}
                                >
                                    Decline
                                </Button>
                                <Button
                                    loading={isApprovingLeave}
                                    disabled={isApprovingLeave || loading}
                                    className='w-fit lg:w-auto min-w-[130px] min-h-[35px] hover'
                                    onClick={() => {
                                        approveLeaveFn({})
                                    }}
                                >
                                    Approve
                                </Button>
                            </div>
                        )}
                </div>
            }
            {declineLeave && <DeclineLeaveRequest closeModal={() => setDeclineLeave(false)} leaveData={leaveData} />}
        </>
    )
}

export default LeavePermissionWrapper
