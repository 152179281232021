import { useEffect } from 'react'

const AccountingSetupSuccessModal = ({ closeModal }: { closeModal: () => void }) => {
    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            closeModal()
        }, 2000)
        return () => {
            clearTimeout(redirectTimeout)
        }
    }, [])

    return (
        <div className=' flex flex-col justify-center items-center px-5 min-h-[20rem] w-[22rem]'>
            <div className='h-[100px] border-b border-gray2  w-[85%] my-4 flex items-center gap-4 overflow-hidden justify-center '>
                <div className='w-[40px] h-[255px] rounded-t-md mb-auto bg-[#F5D484] bar-1'></div>
                <div className='w-[40px] h-[255px] rounded-t-md mb-auto bg-[#7E82FB] bar-2'></div>
                <div className='w-[40px] h-[255px] rounded-t-md mb-auto bg-[#FFAB90] bar-3'></div>
                <div className='w-[40px] h-[255px] rounded-t-md mb-auto bg-[#9FDA96] bar-4'></div>
            </div>
            <p className='text-center text-sm text-gray7'>Setting up your account</p>
        </div>
    )
}

export default AccountingSetupSuccessModal
