import { useRef, useState, useCallback } from 'react'
import Cropper, { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../../ModalWrapper'
import { Button } from '@/components'
import { FileUploadFormatProps, fileEmptyState, useSinglePicUpload } from '@/hooks/useSingleFileUpload'
import { useMutateProfileImg } from '@/services/auth/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { useDropzone } from 'react-dropzone'

type Props = {
    src: string
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UpdateProfilePicture({ closeModal }: Props) {
    const cropperRef = useRef<ReactCropperElement>(null)
    const [croppedImg, setCroppedImg] = useState<string>()
    const [profilePic, setProfilePic] = useState<FileUploadFormatProps>()
    const { mutate, isLoading } = useMutateProfileImg({
        onSuccess: () => {
            closeModal(false)
            toastHandler({ message: 'Profile image updated', state: 'success' })
        },
    })
    const [croppedfile, setCroppedFile] = useState<File>()

    const { handleDrop } = useSinglePicUpload({
        fileUpload: profilePic,
        setFileUpload: setProfilePic,
    })

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper
        cropper?.getCroppedCanvas().toBlob(blob => {
            const file = new File([blob as Blob], 'profile_dp.png', { lastModified: 1534584790000, type: 'image/png' })
            setCroppedFile(file)
        })

        setCroppedImg(cropper?.getCroppedCanvas().toDataURL())
    }
    const handleUploadProfile = () => {
        const formdata = new FormData()
        formdata.append('file', croppedfile as File)
        mutate(formdata)
    }
    const onDrop = useCallback((acceptedFiles: File[]) => {
        setProfilePic(fileEmptyState)
        setCroppedImg(undefined)
        handleDrop(acceptedFiles)
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false })

    return (
        <ModalWrapper>
            <section className='w-[22rem] p-[1rem] '>
                <div className='flex justify-end mb-3'>
                    <CancelIcon onClick={() => closeModal(false)} className='cursor-pointer h-5 w-5' />
                </div>
                <div>
                    {profilePic ? (
                        <>
                            <div
                                className='w-[310px] h-[34px] mx-auto text-base rounded-[5px] text-center cursor-pointer pt-1'
                                {...getRootProps()}
                                style={{
                                    border: `2px dashed ${isDragActive ? '#ccc' : 'transparent'}`,
                                    // lineHeight: '200px',
                                    backgroundColor: isDragActive ? '#f0f0f0' : '#dadce0',
                                }}
                            >
                                {isDragActive ? 'Drop files here' : 'Click or Drag to change file'}
                                <input {...getInputProps()} />
                            </div>
                            <p className='mb-5 mt-2 ml-1'>file name : {profilePic?.file_name}</p>
                        </>
                    ) : (
                        <div
                            className='w-[310px] h-[214px] mx-auto text-base rounded-[5px] text-center cursor-pointer'
                            {...getRootProps()}
                            style={{
                                border: `2px dashed ${isDragActive ? '#ccc' : 'transparent'}`,
                                lineHeight: '200px',
                                backgroundColor: isDragActive ? '#f0f0f0' : '#dadce0',
                            }}
                        >
                            {isDragActive ? 'Drop files here' : 'Click here or Drag file here'}
                            <input {...getInputProps()} />
                        </div>
                    )}
                    {!croppedImg ? (
                        <>
                            <Cropper
                                src={profilePic?.fileUrl}
                                autoCrop={false}
                                initialAspectRatio={16 / 9}
                                guides={false}
                                ref={cropperRef}
                                background={false}
                                modal={false}
                                zoomable={false}
                            />
                        </>
                    ) : (
                        <img src={croppedImg} alt='Cropped version of dp' className='h-[14rem] w-full object-cover' />
                    )}
                </div>
            </section>
            {!croppedImg ? (
                <>
                    {profilePic?.fileUrl && (
                        <Button onClick={onCrop} className='ml-auto mb-4 min-w-[100px] mr-3  min-h-[40px]'>
                            Crop Now
                        </Button>
                    )}
                </>
            ) : (
                <Button
                    onClick={handleUploadProfile}
                    className='ml-auto mb-4 min-w-[100px] mr-3  min-h-[40px]'
                    loading={isLoading}
                >
                    Save
                </Button>
            )}
        </ModalWrapper>
    )
}
