import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { toastHandler } from '@/components/utils/Toast'
import { useCreateDepositAccount, useUpdateDepositAccount } from '@/services/accounts/mutations'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ModalWrapper from '../ModalWrapper'

type Props = {
    closeModal: () => void
    edit?: boolean
    nameOfAccount?: string
}
export default function CreateAccountModal({ closeModal, edit, nameOfAccount }: Props) {
    const { accountId } = useParams()
    const [accountName, setAccountName] = useState(nameOfAccount ?? '')
    const { mutate: createDepositAccountFn, isLoading } = useCreateDepositAccount({
        onSuccess: () => {
            closeModal()
            toastHandler({ state: 'success', message: 'Account creation successful' })
        },
    })
    const { mutate: updateAccountFn, isLoading: isUpdating } = useUpdateDepositAccount({
        onSuccess: () => {
            closeModal()
            toastHandler({ state: 'success', message: 'Account update successful' })
        },
    })
    const handleSubmit = () => {
        if (!edit) {
            createDepositAccountFn({
                currency: 'NGN',
                nickname: accountName,
            })
            return
        }
        updateAccountFn({
            id: accountId as string,
            payload: {
                nickname: accountName,
            },
        })
    }
    return (
        <ModalWrapper formId='createAccount' closeModal={() => closeModal()} shouldConfirmClose>
            <section className='min-h-[15rem] w-[25rem] sm:w-[27rem] overflow-scroll'>
                <div className='flex items-center border-b border-[#DADCE0] p-3 px-4 justify-between'>
                    <h2 className='text-center text-lg font-medium opacity-80'>
                        {edit ? 'Edit' : 'Create new'} account
                    </h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <form onSubmit={e => e.preventDefault()} id='createAccount'>
                    <div className='mx-4 mt-3'>
                        <h3 className='mb-2 text-sm text-gray7'>Account name</h3>
                        <Input
                            value={accountName}
                            onChange={e => setAccountName(e.target.value)}
                            className='h-12 border-gray4'
                        />
                    </div>
                </form>
                <div className='border-t border-gray4 flex mt-6 justify-end items-center pt-2.5 px-4'>
                    <Button disabled={!accountName} onClick={handleSubmit} loading={isLoading || isUpdating}>
                        {edit ? 'Update' : 'Create'} account
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
