import { ExpenseType } from '@/types/expense'
import { createMutation } from '../api/mutation'
import { userInsightkey, orgInsightkey, keyUser } from '../auth/queries'
import { keyQueryOrgExpenses, keyQueryPendingOrgExpenses } from './queries'

export const useMutationScanReceipt = createMutation({
    url: '/create-expense-from-receipt',
    keysToRefetch: [keyQueryOrgExpenses, keyUser],
    method: 'POST',
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})
export const useMutationScanReceiptFromCreateExpense = createMutation({
    url: '/create-expense-from-receipt',
    keysToRefetch: [keyQueryOrgExpenses, keyUser],
    method: 'POST',
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useMutationDeleteExpense = createMutation({
    url: '/delete-expense',
    keysToRefetch: [keyQueryOrgExpenses],
    method: 'DELETE',
})

export const useMutationAuthorizeExpense = createMutation<ExpenseType>({
    url: '/authorize-expense',
    keysToRefetch: [keyQueryOrgExpenses, userInsightkey, orgInsightkey, keyQueryPendingOrgExpenses],
    method: 'POST',
})

export const useOwnerOverrideExpense = createMutation<ExpenseType>({
    url: '/approve-expense',
    keysToRefetch: [keyQueryOrgExpenses, userInsightkey, orgInsightkey, keyQueryPendingOrgExpenses],
    method: 'POST',
})
// approve-expense
export const useMutationCreateSingleExpense = createMutation({
    url: '/create-expense',
    keysToRefetch: [keyQueryOrgExpenses, keyUser],
    method: 'POST',
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useMutateDrafttExpense = createMutation({
    url: '/draft-expense',
    keysToRefetch: [keyQueryOrgExpenses, keyUser],
    method: 'POST',
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useMutationUpdateExpense = createMutation({
    url: '/expense',
    keysToRefetch: [keyQueryOrgExpenses, userInsightkey, orgInsightkey, ['/expense-id']],
    method: 'PATCH',
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})
export const useMutateSubmitDraftExpense = createMutation<ExpenseType>({
    url: '/submit-draft-expense',
    keysToRefetch: [keyQueryOrgExpenses, userInsightkey, orgInsightkey, ['/expense-id']],
    method: 'PATCH',
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useMutateTableToCSV = createMutation<{ data: SharedArrayBuffer }>({
    url: '/create-doc-from-expenses',
    method: 'POST',
})

export const useMutateResolveOCRDiscrepancy = createMutation<ExpenseType>({
    url: '/resolve-ocr-discrepancy',
    method: 'POST',
    keysToRefetch: [keyQueryOrgExpenses, ['/expense-id']],
})
export const useScanExpenseReceipt = createMutation<ExpenseType>({
    url: '/scan-expense-receipt',
    method: 'POST',
    keysToRefetch: [keyQueryOrgExpenses, ['/expense-id']],
})
