import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'

export const useAddQueryParamsToURL = <T extends Record<string, string>>(): [
    (key: keyof T, filterValue: string) => void,
    URLSearchParams,
] => {
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const pathName = location.pathname

    const updateSearchParams = (key: keyof T, filterValue: string) => {
        const searchParamsConstructor = new URLSearchParams(searchParams?.toString())
        searchParamsConstructor.set(key as string, filterValue)
        navigate(
            `${pathName}${searchParamsConstructor.toString().length ? '?' : ''}${searchParamsConstructor.toString()}`
        )
    }
    return [updateSearchParams, searchParams]
}
