import { Button } from '@/components'
import { ChangeEvent, useRef, useState } from 'react'

import { useCreateSignedUrl } from '@/services/file-upload/mutation'
import { ModuleNameEnum, generateFileName } from '@/hooks/useSingleFileUpload'
import { useAuthContext } from '@/context'

type Props = {
    label?: string
    name: string
    setValue: (data: string) => void
    moduleName: ModuleNameEnum
}

export const UploadFileToS3 = ({ name, setValue, label, moduleName }: Props) => {
    const { selectedAccount } = useAuthContext()

    const [fileData, setFileData] = useState<File>()

    const { mutate, isLoading } = useCreateSignedUrl({
        onSuccess: async dataFromSer => {
            await fetch(dataFromSer?.[0]?.signedUrl as string, {
                method: 'PUT',
                body: fileData,
                headers: {
                    'Content-Type': fileData?.type as string,
                    'Content-Length': String(fileData?.size),
                },
            })

            setValue(dataFromSer?.[0]?.url)
        },
    })

    const handleGetSignedURL = (e: ChangeEvent<HTMLInputElement>) => {
        mutate({
            files: [
                {
                    fileName: generateFileName({
                        module: moduleName,
                        unique_identifier: `${name}-${e.target.files?.[0]?.name?.split('.')?.[0]}`,
                        org_id: selectedAccount?.org?.id ?? '',
                        selectedFileName: e.target.files?.[0]?.name as string,
                    }),
                    contentType: e.target.files?.[0]?.type,
                },
            ],
            module: moduleName,
        })

        setFileData(e.target.files?.[0])
    }

    const ref = useRef<HTMLInputElement>(null)

    return (
        <section className={`${isLoading ? 'opacity-60' : ''}`}>
            {label && <h5 className='text-gray7 font-normal text-sm mb-2'>{label}</h5>}
            <div className='border border-[#DADCE0] rounded-xl p-4'>
                <label className='cursor-pointer flex flex-col md:flex-row items-center justify-between gap-4 md:gap-16'>
                    <div className=''>
                        {!fileData?.name && (
                            <span className='text-gray7/80 text-sm text-medium'>
                                Click to upload file. Max 2MB | Accepts JPEG, PNG, and PDF formats
                            </span>
                        )}
                        {fileData?.name && (
                            <span className='font-medium break-all'>{fileData?.name ?? 'File uploaded'}</span>
                        )}
                    </div>

                    <input
                        disabled={isLoading}
                        name={name}
                        type='file'
                        hidden
                        ref={ref}
                        accept='.pdf, .jpeg, .jpg, .png'
                        onChange={handleGetSignedURL}
                    />
                    <div className='w-full md:w-auto'>
                        <Button
                            type='button'
                            disabled={isLoading}
                            className='min-w-full md:min-w-auto bg-transparent text-brand border-brand border whitespace-nowrap hover:text-white'
                            onClick={() => ref.current?.click()}
                        >
                            {fileData?.name ? 'Change document' : 'Upload document'}
                        </Button>
                    </div>
                </label>
            </div>
        </section>
    )
}
