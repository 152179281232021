import { format } from 'date-fns'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

export const CompanyTOS = () => {
    const [selectedTerm, setSelectedTerm] = useState('services')

    const targetRef = useRef<(HTMLDivElement | null)[]>([])
    const scrollRef = useRef<HTMLDivElement>(null)
    const handleScroll = (i: number) => {
        targetRef.current[i]?.scrollIntoView({ behavior: 'smooth' })
    }
    const scroll = (scrollOffset: number) => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += scrollOffset
        }
    }
    return (
        <div className='mr-6'>
            <section className='font-medium  mt-5 text-xl'>
                <h3> Terms of service </h3>
                <p className='text-[#838383] text-sm mt-1'>
                    Kindly review our terms of service and get familiar with them.
                </p>
            </section>
            <h2 className='text-lg font-semibold my-6'>
                <span className='text-gray7'>Updated</span> {format(new Date(), 'MMMM dd, yyyy')}
            </h2>
            <div className='flex flex-col lg:flex-row gap-6 justify-between '>
                <div
                    className={`lg:w-[30%] bg-white rounded-xl border-2 border-[#cfcfcf] lg:px-7 px-2 py-7 flex gap-1 justify-between  items-center `}
                >
                    <ChevronLeft size={30} onClick={() => scroll(-100)} className='lg:hidden' />
                    <div className=' flex flex-row lg:flex-col overflow-x-scroll gap-6 w-4/5 lg:w-full' ref={scrollRef}>
                        {tosList.map((term, idx) => (
                            <Link
                                to={`#${term.id}`}
                                className={`${selectedTerm === term.id ? 'text-brand font-semibold' : 'font-medium'} text-center lg:text-left w-fit whitespace-nowrap`}
                                onClick={() => {
                                    setSelectedTerm(term.id)
                                    handleScroll(idx)
                                }}
                            >
                                {term.title}
                            </Link>
                        ))}
                    </div>
                    <ChevronRight size={30} onClick={() => scroll(100)} className='lg:hidden' />
                </div>

                <div className='bg-white px-5 md:px-8 p-8 rounded-xl lg:w-[70%] h-[530px] overflow-y-scroll '>
                    <h2 className='text-lg font-semibold text-gray7'>
                        {/* This Terms of Service Agreement is entered into between Bizpend, and you and governs the use of
                        Company's services and products . */}
                        Welcome to Bizpend Technologies. These Terms of Service govern your use of our website located
                        at{' '}
                        <Link className='text-brand hover:underline' to={'https://bizpend.com'} target='_blank'>
                            bizpend.com
                        </Link>
                        . Please read these Terms carefully before using our Website and Services.
                    </h2>
                    {tosList.map((term, idx) => (
                        <div className='pt-4' id={term.id} key={term.id} ref={el => (targetRef.current[idx] = el)}>
                            <div
                                className={`mt-6 mb-10 ${term.id === selectedTerm ? 'bg-[#fdfce2] p-2 rounded-md' : ''}`}
                            >
                                <h2 className='py-2 border-b border-[#838383] text-base font-semibold'>{term.title}</h2>
                                <p className='mt-4'>{formatText(term.body)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const tosList = [
    {
        title: 'Using Our Services',
        id: 'services',
        body: `Eligibility: You must be at least 18 years old to use our Services, or the age of majority in your jurisdiction, whichever is higher.
                Account Registration: You may need to register an account to access certain features of our Services. You are responsible for maintaining the confidentiality of your account and password.
                Acceptable Use: You agree not to use the Services for any unlawful purpose or in any way that interrupts, damages, or impairs the service. Specifically, you must:
                Provide accurate and complete information during registration and in all interactions with our Services.
                Not engage in fraudulent activities, scams, or any action that could harm other users or the integrity of our Services.`,
    },
    {
        title: 'Intellectual Property',
        id: 'property',
        body: `Ownership: All content on the Website, including text, graphics, logos, images, as well as the compilation thereof, and any software used on the Website, is our property or the property of our licensors and is protected by copyright and intellectual property laws.
        Restrictions: You are granted a limited, non-exclusive, non-transferable license to access and use the Website and Services for your personal,   non-commercial purposes.`,
    },
    {
        title: 'Content and User Contributions',
        id: 'content',
        body: `User Content: You may be able to submit content or information ("User Content") to the Services. You retain all rights in, and are solely responsible for, the User Content you post to the Website.
        License to User Content: By posting User Content, you grant us a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform that User Content in connection with the Services.`,
    },
    {
        title: 'Account Suspension and Termination',
        id: 'account',
        body: `Violations: We reserve the right to suspend or terminate your account if you breach these Terms, provide false information, engage in fraudulent or illegal activities, or misuse the Services.
        Process: In the event of suspension or termination, we will notify you via email. You may appeal the decision by contacting us at admin@bizpend.com.`,
    },
    {
        title: 'Data Handling and Privacy',
        id: 'privacy',
        body: `Refer to our Privacy Policy for detailed information on how we collect, use, and protect your data. We adhere to strict data protection practices, including:
        Data Retention: We retain your personal data only as long as necessary to provide you with our Services or as required by law.
        Data Deletion: You can request the deletion of your personal data by contacting us at admin@bizpend.com.`,
    },
    {
        title: 'Third-Party Links and Content',
        id: '3rdparty',
        body: `Third-Party Services: Our Website and Services may contain links to third-party websites or services that are not owned or controlled by Bizpend Technologies.
        No Endorsement: We do not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services.`,
    },
    {
        title: 'Disclaimers and Limitations of Liability',
        id: 'disclaimer',
        body: `No Warranties: The Services are provided on an "as is" and "as available" basis. Bizpend Technologies expressly disclaims any warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        Limitation of Liability: In no event shall Bizpend Technologies, nor its directors, users, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including, without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.`,
    },
    {
        title: 'Governing Law',
        id: 'law',
        body: `These Terms shall be governed and construed in accordance with the laws of Nigeria, without regard to its conflict of law provisions.`,
    },
    {
        title: 'Changes to the Terms',
        id: 'changes',
        body: `We reserve the right to modify or replace these Terms at any time. You will be notified of any changes through email and by an updated posting on our website.`,
    },
    {
        title: 'Contact Information',
        id: 'contact',
        body: `For any questions or concerns regarding these Terms or the Services, please contact us at admin@bizpend.com.`,
    },
]

export const formatText = (text: string) => {
    const parts = text.split('\n').map((part, index) => {
        const formattedPart = part.replace(
            /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/g,
            `<a href="mailto:$1" class='text-brand hover:underline'>$1</a>`
        )
        return (
            <span key={index}>
                {index > 0 && <br />}
                <span dangerouslySetInnerHTML={{ __html: formattedPart }} />
            </span>
        )
    })
    return parts
}
