import { createQuery } from '../api/query'
import { NotificationType } from './types'

export const personalNotificationsKey = ['personal-notifications']
export const orgNotificationsKey = ['org-notifications']
export const useGetPersonalNotifications = createQuery<NotificationType[]>({
    key: personalNotificationsKey,
    url: '/notifications',
})

export const useGetOrgNotifications = createQuery<NotificationType[]>({
    key: orgNotificationsKey,
    url: '/notifications',
})
