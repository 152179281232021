import React, { useEffect } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'
import { ChevronDown, PercentIcon, PlusSquare } from 'lucide-react'
import { AiOutlineClose } from 'react-icons/ai'
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { Input } from '@/components/Input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { TbCurrencyNaira } from 'react-icons/tb'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { Switch } from '@headlessui/react'
import { BulkProfileCSVType, defaultAdditionProps } from '@/types/payroll'

type Props = {
    closeModal: () => void
    setSelectedProfiles: (data: BulkProfileCSVType[]) => void
    selectedProfiles: BulkProfileCSVType[]
    rowData?: BulkProfileCSVType
}

type FormValues = {
    deductions: {
        name: string
        amount: string
        type: 'amount' | 'percentage'
        toggle: boolean
    }[]
    bonuses: {
        name: string
        amount: string
        type: 'amount' | 'percentage'
        toggle: boolean
    }[]
}

const BonusDeductionModal = ({ closeModal, rowData, selectedProfiles, setSelectedProfiles }: Props) => {
    const selectedProfile = selectedProfiles?.find(profile => profile?.['Email Address'] === rowData?.['Email Address'])

    const methods = useForm<FormValues>({
        defaultValues: {
            deductions: selectedProfile?.deductions?.map(deduction => ({
                toggle: deduction.toggle,
                amount: deduction?.amount.toString(),
                name: deduction?.name,
                type: deduction?.type,
            })),
            bonuses: selectedProfile?.bonuses?.map(bonus => ({
                toggle: bonus.toggle,
                amount: bonus?.amount.toString(),
                name: bonus?.name,
                type: bonus?.type,
            })),
        },
    })

    const { fields: deductionsField, append: appendDeductions } = useFieldArray({
        control: methods.control,
        name: 'deductions',
    })
    const { fields: bonusField, append: appendBonuses } = useFieldArray({
        control: methods.control,
        name: 'bonuses',
    })

    return (
        <ModalWrapper>
            <section className='w-[30rem]'>
                <div className='border-b border-[#DADCE0] py-4 px-2 '>
                    <div className='flex justify-between'>
                        <h2 className='font-semibold '>{rowData?.['Full Name']} - Deduction & Bonuses</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal()}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <Accordion type='single' collapsible className='m-3 mt-5 space-y-4'>
                        <AccordionItem value='item-1' className='border border-gray1/60 p-3 rounded-md'>
                            <AccordionTrigger righticon={<ChevronDown className='w-4 h-4 text-gray1' />}>
                                Deductions
                            </AccordionTrigger>
                            <AccordionContent className='border-t mt-4 pt-4 border-gray1/60 '>
                                {deductionsField.map((_, idx) => (
                                    <>
                                        <FieldComponent
                                            selectedProfiles={selectedProfiles}
                                            idx={idx}
                                            name='deductions'
                                            setSelectedProfiles={setSelectedProfiles}
                                            selectedProfile={selectedProfile}
                                        />
                                        <div className='border-t border-gray7/20 mt-5'>
                                            <button
                                                onClick={() =>
                                                    appendDeductions({
                                                        amount: '',
                                                        name: '',
                                                        toggle: false,
                                                        type: 'amount',
                                                    })
                                                }
                                                className='mt-3 flex items-center gap-2 rounded border border-gray2 text-xs font-medium p-2 text-black/80'
                                            >
                                                <PlusSquare size={15} className='mr-1' /> Add Bonus
                                            </button>
                                        </div>
                                    </>
                                ))}
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value='item-2' className='border border-gray1/60 p-3 rounded-md'>
                            <AccordionTrigger righticon={<ChevronDown className='w-4 h-4 text-gray1' />}>
                                Bonuses
                            </AccordionTrigger>
                            <AccordionContent className='border-t mt-4 pt-4 border-gray1/60 '>
                                {bonusField.map((_, idx) => (
                                    <>
                                        <FieldComponent
                                            idx={idx}
                                            name='bonuses'
                                            selectedProfile={selectedProfile}
                                            selectedProfiles={selectedProfiles}
                                            setSelectedProfiles={setSelectedProfiles}
                                        />
                                        <div className='border-t border-gray7/20 mt-5'>
                                            <button
                                                onClick={() =>
                                                    appendBonuses({
                                                        amount: '',
                                                        name: '',
                                                        toggle: false,
                                                        type: 'amount',
                                                    })
                                                }
                                                className='mt-3 flex items-center gap-2 rounded border border-gray2 text-xs font-medium p-2 text-black/80'
                                            >
                                                <PlusSquare size={15} className='mr-1' /> Add Bonus
                                            </button>
                                        </div>
                                    </>
                                ))}
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </FormProvider>
            </section>
        </ModalWrapper>
    )
}

export default BonusDeductionModal

type FieldProps = {
    idx: number
    name: 'deductions' | 'bonuses'
    selectedProfile?: BulkProfileCSVType
    selectedProfiles: BulkProfileCSVType[]
}
const FieldComponent = ({
    idx,
    name,
    setSelectedProfiles,
    selectedProfile,
    selectedProfiles,
}: FieldProps & Partial<Props>) => {
    const { control, watch } = useFormContext<FormValues>()
    const watchAmountType = watch(`${name}.${idx}.type`)

    const watchAll = watch(`${name}`) as unknown as defaultAdditionProps[]

    useEffect(() => {
        setSelectedProfiles?.(
            selectedProfiles?.map(profile => {
                if (profile?.['Email Address'] === selectedProfile?.['Email Address']) {
                    return {
                        ...profile,
                        deductions: name === 'deductions' ? watchAll : profile?.deductions,
                        bonuses: name === 'bonuses' ? watchAll : profile?.bonuses,
                    }
                }
                return profile
            })
        )
    }, [name, setSelectedProfiles, watchAll])

    return (
        <div className='flex items-center justify-between mt-4'>
            <div className='flex gap-4'>
                <Controller
                    control={control}
                    name={`${name}.${idx}.name`}
                    render={({ field }) => (
                        <Input
                            {...field}
                            className='h-10 w-28 border border-[#DADCE0] text-xs '
                            bodyClassName='xl:min-w-[7rem] xl:max-w-[7rem]'
                        />
                    )}
                />
                <div className='flex border border-[#DADCE0] w-32 h-10 rounded-lg overflow-hidden bg-white'>
                    <Controller
                        control={control}
                        name={`${name}.${idx}.type`}
                        render={({ field }) => (
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger className='bg-[#F9F9F9] border-none w-9 px-1.5 py-2 gap-1 justify-between h-max flex self-center mx-1 pl-1 placeholder:text-sm '>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className='w-[8rem] m-0.5'>
                                    <SelectItem value='amount'>
                                        {' '}
                                        <TbCurrencyNaira fontSize={12} className='text-[#838383]' />
                                    </SelectItem>
                                    <SelectItem value='percentage'>
                                        {' '}
                                        <PercentIcon size={12} className='text-[#838383]' />
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        )}
                    />

                    <Controller
                        control={control}
                        name={`${name}.${idx}.amount`}
                        render={({ field }) => (
                            <input
                                {...field}
                                type='text'
                                placeholder={`Enter Value`}
                                className='outline-none w-[90%] h-full ml-1 placeholder:text-sm text-xs'
                                required
                                value={formatAmount(field.value).replaceAll('₦', '')}
                                onChange={e => {
                                    const value_entered = formatAmountWithoutSymbols(e.target.value)

                                    if (watchAmountType === 'percentage' && Number(value_entered) > 10000) return

                                    field.onChange(Number(value_entered))
                                }}
                            />
                        )}
                    />
                </div>
            </div>
            <Controller
                control={control}
                name={`${name}.${idx}.toggle`}
                render={({ field }) => (
                    <Switch
                        checked={field.value}
                        onChange={field.onChange}
                        className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                            field.value ? 'bg-brand' : 'bg-[#EFEFF9]'
                        }`}
                    >
                        <span
                            className={`${
                                !field.value ? 'translate-x-1 bg-brand' : 'translate-x-6 bg-white'
                            } inline-block h-4 w-4 transform rounded-full  transition`}
                        />
                    </Switch>
                )}
            />
        </div>
    )
}
