import { Button } from '@/components'
import { CiSearch } from 'react-icons/ci'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAuthContext, useVendorContext } from '@/context'
import { ColumnSort, Pagination } from '@/types/utils'
import ProgressBar from '@/components/ProgressBar'
import { debounce } from 'lodash'
import { getSortString } from '@/components/Table/utils'
import notFound from '@/assets/not-found.svg'
import CustomerListTable from './components/CustomerListTable'
import CreateNewCustomer from '@/components/Modals/Vendors/CreateNewCustomer/CreateNewCustomer'
import { useGetOrgCustomers } from '@/services/customers/queries'
import { ModuleNameEnum } from '@/types/billing'
import useAccessToModule from '@/hooks/useAccessToModule'
import PageContent from '@/layout/PageContent'

const Customers = () => {
    const [isCreateCustomerModalOpen, setIsCreateCustomerModalOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)

    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const { sort, filterFormState, setFilterFormState } = useVendorContext()

    const { enabled: isCustomersEnabled } = useAccessToModule({ module: ModuleNameEnum.CUSTOMERS })

    const {
        data,
        isLoading: isOrgCustomersLoading,
        refetch: refetchAllOrgCustomers,
        isRefetching,
    } = useGetOrgCustomers({
        queryParams: {
            org: selectedAccount?.org.id as string,
            search: searchQuery,
            sort: getSortString(sort as ColumnSort),
            status: filterFormState?.status,
            start_date: (filterFormState?.period?.from ?? filterFormState?.period?.time_in_words) as unknown as string,
            end_date: (filterFormState?.period?.to ?? new Date()) as unknown as string,
            min_amount: filterFormState?.amount?.min?.replaceAll(',', ''),
            max_amount: filterFormState?.amount?.max?.replaceAll(',', ''),
            category: filterFormState?.category,
        },
        onSuccess: () => {
            setFilterFormState(prev => ({ ...prev, filter_now: false }))
            setBackSpace(false)
        },
    })
    // console.log(data)
    const orgCustomers = useMemo(() => data?.customers ?? [], [data])

    const orgCustomersPagination = useMemo(() => (data?.pagination ?? {}) as Pagination, [data])

    const debouncedSearch = useRef(
        debounce(() => {
            refetchAllOrgCustomers()
        }, 300)
    ).current

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }

    useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgCustomers()
            setBackSpaceClicked(false)
        }
        if (sort) {
            refetchAllOrgCustomers()
        }
        if (filterFormState.filter_now) {
            refetchAllOrgCustomers()
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, sort, filterFormState.filter_now, searchQuery, refetchAllOrgCustomers, debouncedSearch])

    useEffect(() => {
        if (location.pathname.includes('quick_actions')) {
            setIsCreateCustomerModalOpen(true)
        }
    }, [])

    if (isOrgCustomersLoading) return <ProgressBar />
    return (
        <PageContent>
            <section className='pb-28 mt-10'>
                <div className='block lg:flex justify-between mb-6 lg:mb-0'>
                    <div className='mb-3 lg:mb-6 '>
                        <h1 className='text-dark font-medium text-lg'>Customers</h1>
                        <p className='text-gray7 text-sm'>Create and manage customers you do business with</p>
                    </div>
                    <Button
                        className='min-w-full lg:min-w-[120px] text-sm'
                        onClick={() => {
                            if (!isCustomersEnabled) {
                                setShowUpgradeModal(true)
                                setCurrentModule(ModuleNameEnum.CUSTOMERS)

                                return
                            }
                            setIsCreateCustomerModalOpen(true)
                        }}
                    >
                        Add new customer
                    </Button>
                </div>
                <section className='bg-white p-4 py-0 rounded-2xl'>
                    <div className='w-full py-6 block'>
                        <div className='flex items-center justify-between mb-6'>
                            <div className='border border-[#DADCE0] h-[45px] w-[70%] lg:w-[30%] rounded-lg flex items-center overflow-hidden '>
                                <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 lg:mx-0'>
                                    <CiSearch fontSize={25} />
                                </div>
                                <input
                                    type='text'
                                    name='query'
                                    id='bg-input'
                                    placeholder='Search by customer name or email'
                                    className='h-full w-[90%] outline-none placeholder:text-xs'
                                    onChange={e => setSearchQuery(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    autoComplete='new-value'
                                />
                            </div>
                        </div>

                        <div className={isRefetching ? 'opacity-50' : 'opacity-100'}>
                            {orgCustomers.length === 0 && isRefetching ? (
                                <ProgressBar />
                            ) : (orgCustomers.length === 0 && searchQuery?.length < 1 && !backspace) ||
                              (orgCustomers.length > 0 && searchQuery?.length >= 0 && !backspace) ? (
                                <div className='overflow-y-scroll'>
                                    <CustomerListTable
                                        customers={orgCustomers}
                                        pagination_data={orgCustomersPagination}
                                    />
                                </div>
                            ) : searchQuery.length > 0 && orgCustomers?.length === 0 ? (
                                <div className='h-450px] flex flex-col text-center justify-center items-center'>
                                    <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                    <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                    <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                        {orgCustomers &&
                                            'Customers not found in this record. Please try a different filter or contact support for assistance.'}
                                    </span>
                                </div>
                            ) : isRefetching ? (
                                <ProgressBar />
                            ) : null}
                        </div>
                    </div>

                    {isCreateCustomerModalOpen && <CreateNewCustomer setCloseModal={setIsCreateCustomerModalOpen} />}
                </section>
            </section>
        </PageContent>
    )
}

export default Customers
