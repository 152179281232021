import { CalendarIcon, ProfileIcon } from '@/assets/assets.export'
import moneyIcon from '@/assets/money.svg'
import xIcon from '@/assets/x.svg'
import { CheckIcon } from 'lucide-react'
import { useState } from 'react'

const SinglePaymentRequest = () => {
    const [viewMore, setViewMore] = useState(true)
    const text = `To facilitate efficient business operations, reimbursement for Bolt ride to the client's place for a sales meeting and subsequent SLA approval is an essential.To facilitate efficient business operations, reimbursement for Bolt ride to the client's place for a sales meeting and subsequent SLA approval is an essential. To facilitate efficient business operations, reimbursement for Bolt ride to the client's place for a sales meeting and subsequent SLA approval is an essential.`
    const truncatedText = text.slice(0, 145) + '...'

    return (
        <div className='border-b border-[#DADCE0] mt-2'>
            <h1 className='mb-4'>Emergency</h1>
            <div className='flex items-center gap-3 mb-3'>
                <div className='flex items-center text-[#5E5E5E]'>
                    <ProfileIcon className='mr-1 scale-90' />
                    <p className='text-base'>Alex Anderson (Admin)</p>
                </div>
                <div className='flex items-center text-[#5E5E5E]'>
                    <img src={moneyIcon} alt='' className='mr-1 scale-90' />
                    <p className='text-base'>₦560,000.00</p>
                </div>
                <div className='flex items-center text-[#5E5E5E]'>
                    <CalendarIcon className='mr-1 ' />
                    <p className='text-base'>01/09/023 - 15/09/2023</p>
                </div>
            </div>
            <div className='bg-[#EFEFF9] p-3 mb-3 rounded-xl'>
                <p className='text-[#838383]'>
                    {viewMore ? truncatedText : text}
                    <span className='text-brand cursor-pointer' onClick={() => setViewMore(!viewMore)}>
                        {viewMore ? 'View more' : 'View less'}
                    </span>
                </p>
            </div>
            <div className='text-right px-2 py-3 flex items-center justify-end'>
                <button className='mr-5 text-[#19C37D] flex items-center gap-2'>
                    <CheckIcon />
                    Approve
                </button>
                <button className='text-errorRed flex items-center gap-2'>
                    <img src={xIcon} alt='' />
                    Decline
                </button>
            </div>
            {/* <div className='text-right px-2 py-3 flex items-center justify-end'>
                <button className='mr-5 text-[#19C37D] px-4 py-1 bg-[#EFFFED] rounded'>Approved</button>
                <button className='mr-5 text-[#5E5E5E] bg-[#F9F6FF] px-4 py-1 rounded'>Not paid</button>
                <button className='text-[#5C9BFF] bg-[#EBF5FF] px-4 py-1 rounded'>Paid</button>
            </div> */}
        </div>
    )
}

export default SinglePaymentRequest
