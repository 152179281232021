import { InviteIcon } from '@/assets/assets.export'
import React from 'react'

export default function InviteMemberSuccesful() {
    return (
        <main className='grid place-items-center my-8 text-[#202020]'>
            <span className='w-[12rem] h-[12rem] bg-[rgb(233,243,255)] rounded-full relative overflow-hidden '>
                <InviteIcon className='absolute left-[3rem] bottom-[3rem] diagonal_move' />
            </span>
            <h3 className='font-medium text-[1.2rem] mt-2'>Invite Sent Successfully!</h3>
            <p className='text-[#5E5E5E] text-center w-[80%] my-3'>
                Your invitation has been successfully sent. Soon, you'll be sharing the benefits of our expense
                management platform together
            </p>
        </main>
    )
}

export function MultipleInviteMemberSuccesful() {
    return (
        <main className='grid place-items-center my-8 text-[#202020]'>
            <span className='w-[12rem] h-[12rem] bg-[rgb(233,243,255)] rounded-full relative overflow-hidden '>
                <InviteIcon className='absolute left-[3rem] bottom-[3rem] diagonal_move' />
            </span>
            <h3 className='font-medium text-[1.2rem] mt-2'>Invites Sent Successfully!</h3>
            <p className='text-[#5E5E5E] text-center w-[80%] my-3'>
                Your invitations have been successfully sent. Soon, you'll be sharing the benefits of our expense
                management platform together
            </p>
        </main>
    )
}
