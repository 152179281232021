import { createMutation } from '../api/mutation'
import { keyUser } from '../auth/queries'

export const keyPendingEmployee = ['pending-employees']

export const keyTeamById = ['get-team-by-id']
export const keyOrgTeams = ['/get-teams-in-org']
export const keyOrgMembers = ['/get-org-members']
export const keyUpcomingBirthdays = ['/get-org-birthdays']
export const keyApprovalPolicy = ['/get-user-approval-policy']

export const useMutationInviteEmployee = createMutation({
    url: '/invite-employees',
    keysToRefetch: [keyOrgMembers, keyPendingEmployee, keyUser],
    method: 'POST',
})

export const useMutationDeleteInvite = createMutation({
    url: 'delete-invite',
    keysToRefetch: [keyPendingEmployee, keyOrgMembers],
    method: 'DELETE',
})

export const useMutationSendInviteReminder = createMutation({
    url: 'invite-reminder',
    method: 'POST',
})

export const useMutationCreateTeam = createMutation({
    url: 'create-team',
    keysToRefetch: [keyOrgTeams, keyUser],
    method: 'POST',
})

export const useMutationUpdateTeam = createMutation({
    url: 'update-team',
    keysToRefetch: [keyOrgTeams],
    method: 'PATCH',
})

export const useMutationDeleteTeam = createMutation({
    url: 'delete-team',
    keysToRefetch: [keyOrgTeams],
    method: 'DELETE',
})

export const useMutateRemoveMemberFromTeam = createMutation({
    url: '/remove-member-from-team',
    method: 'DELETE',
    keysToRefetch: [keyTeamById],
})

export const useMutateAddMemberToTeam = createMutation({
    url: '/add-member-to-team',
    method: 'POST',
    keysToRefetch: [keyTeamById, keyOrgTeams, keyUser],
})

export const useMutateDeactivateEmployee = createMutation({
    url: '/deactivate-employee',
    method: 'POST',
    keysToRefetch: [keyTeamById, keyOrgTeams, keyOrgMembers],
})
export const useMutateSuspendEmployee = createMutation({
    url: '/suspend-employee',
    method: 'POST',
    keysToRefetch: [keyTeamById, keyOrgTeams, keyOrgMembers],
})

export const useMutateBulkDeleteEmployees = createMutation({
    url: '/delete-employees',
    method: 'DELETE',
    keysToRefetch: [keyTeamById, keyOrgTeams, keyOrgMembers],
})

export const useMutateChangeEmployeeRole = createMutation({
    url: '/change-employee-role',
    method: 'POST',
    keysToRefetch: [['/get-teams-by-org-id']],
})
export const useMutateMakeTeamLead = createMutation({
    url: '/set-team-lead',
    method: 'POST',
    keysToRefetch: [['/get-teams-by-org-id'], keyTeamById, keyOrgTeams],
})
