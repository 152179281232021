import { Button, Input } from '@/components'
import ProgressBar from '@/components/ProgressBar'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { useAuthContext } from '@/context'
import { useLinkAccount } from '@/services/accounts/mutations'
import { useGetDedicatedBankAccounts } from '@/services/accounts/queries'
import { BankAccountConnectionEnum, DedicatedBankAccountType } from '@/types/accounts'
import { formatAmount } from '@/utils/money'
import MonoConnect from '@mono.co/connect.js'
import { AlertCircleIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import AccountCard from '../components/AccountCard'

const LinkedAccounts = () => {
    const { selectedAccount } = useAuthContext()
    const [searchInput, setSearchInput] = useState('')

    const { data, isLoading, isRefetching } = useGetDedicatedBankAccounts({
        queryParams: { entity_id: selectedAccount?.org?.id as string },
    })
    const bankAccounts = useMemo(
        () =>
            data?.filter(account => account.connection_type === BankAccountConnectionEnum.LINK) ??
            ([] as DedicatedBankAccountType[]),
        [data]
    )

    const handleSearchAccounts = (text: string) => {
        if (!text) {
            return bankAccounts
        } else {
            return bankAccounts.filter(account => {
                const isNickNameMatch = account?.nickname?.toLowerCase().includes(text)
                const isAccountNumberMatch = account?.account_number?.toString().includes(text)
                const isNameMatch = account?.account_name?.toLowerCase().includes(text)
                const isBankNameMatch = account?.bank_name?.toLowerCase().includes(text)
                return isNameMatch || isAccountNumberMatch || isNickNameMatch || isBankNameMatch
            })
        }
    }

    const { mutate: linkAccount, isLoading: isLinkAccountLoading } = useLinkAccount({})

    const monoConnect = useMemo(() => {
        const monoInstance = new MonoConnect({
            onClose: () => console.log('Widget closed'),
            onLoad: () => console.log('Widget loaded successfully'),
            onSuccess: ({ code }: { code: string }) => {
                linkAccount({
                    code,
                })
            },
            key: import.meta.env.VITE_MONO_PUBLIC_KEY,
            ...(selectedAccount?.org.mono_customer_id && {
                data: {
                    customer: { id: selectedAccount?.org.mono_customer_id },
                },
            }),
        })

        monoInstance.setup()

        return monoInstance
    }, [])

    const totalAmount = bankAccounts?.reduce((acc, cur) => acc + cur?.balance, 0)

    if (isLoading) return <ProgressBar />

    return (
        <div>
            <div className='flex flex-col md:flex-row md:items-center justify-between gap-5'>
                <div>
                    <h2 className='text-lg font-semibold'>Bizpend Accounts</h2>
                    <p className='text-sm text-gray7'>
                        These are bank accounts linked to Bizpend for financial transactions
                    </p>
                </div>
                <Button
                    onClick={() => monoConnect.open()}
                    loading={isLinkAccountLoading}
                    disabled={isLinkAccountLoading}
                >
                    Link account with Mono
                </Button>
            </div>
            <div className='mt-5 bg-white rounded-xl p-3 md:p-5 flex items-center justify-between'>
                <div>
                    <p className='flex items-center gap-1 text-gray7'>
                        Account balance
                        <TooltipProvider delayDuration={10}>
                            <Tooltip>
                                <TooltipTrigger className='mr-2' type='button'>
                                    <AlertCircleIcon size={15} />
                                </TooltipTrigger>

                                <TooltipContent>
                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded max-w-sm'>
                                        The total amount is the sum of all bank account balances
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </p>
                    <h3 className='text-3xl text-gray7 font-bold'>{formatAmount(totalAmount)}</h3>
                </div>
                <div>
                    <Input
                        onChange={e => setSearchInput(e.target.value)}
                        placeholder='search accounts'
                        className='h-10 border-gray6 drop-shadow-sm'
                    />
                </div>
            </div>
            <div
                className={`grid pb-16  grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 gap-y-10 mt-10 ${isRefetching ? 'opacity-60' : ''}`}
            >
                {handleSearchAccounts(searchInput.toLowerCase())?.map(account => <AccountCard account={account} />)}
            </div>
        </div>
    )
}

export default LinkedAccounts
