import { Tabs, TabTriggersContainer, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import AccountingMain from './partials/AccountingMain'
import AccountingHistory from './partials/AccountingHistory'
import AccountingSettings from './partials/AccountingSettings'
import { AccountingEmptyState } from '@/assets/assets.export'
import { Button } from '@/components'
import { useState } from 'react'
import AccountingSetupModal from '@/components/Modals/Accounting/AccountingSetupModal'
import AssignAdminSetupModal from '@/components/Modals/Accounting/AssignAdminSetupModal'
import { useAccountingContext } from '@/context'
import { useGetGLCode } from '@/services/accounting/queries'

const route_const = {
    accounts: 'main',
    history: 'history',
    settings: 'settings',
}
export default function Accounting() {
    const { setHasGlCode, setOrgGlCode } = useAccountingContext()
    const { data } = useGetGLCode({
        onSuccess: data => {
            if (data?.codes?.length > 0) {
                setHasGlCode(true)
                setOrgGlCode(data)
            }
        },
    })

    return <>{(data?.codes?.length as number) > 0 ? <AccountingFilledPage /> : <AccountingEmptyPage />}</>
}

const AccountingFilledPage = () => {
    const { accountingSubRoute } = useParams()
    const navigate = useNavigate()

    const currentRoute = location.pathname.split('/').slice(0, -1).join('/')

    return (
        <section className='mt-4'>
            <Tabs defaultValue={accountingSubRoute} value={accountingSubRoute}>
                <TabsList className='border-none flex justify-between mx-auto overflow-scroll items-center'>
                    <TabTriggersContainer>
                        <TabsTrigger
                            value={route_const.accounts}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.accounts}?type=all`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.accounts === accountingSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.accounts === accountingSubRoute && (
                                <motion.div
                                    layoutId='accounting'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Accounting
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={route_const.history}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.history}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.history === accountingSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.history === accountingSubRoute && (
                                <motion.div
                                    layoutId='accounting'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                History
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={route_const.settings}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.settings}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.settings === accountingSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.settings === accountingSubRoute && (
                                <motion.div
                                    layoutId='accounting'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Settings
                            </motion.span>
                        </TabsTrigger>
                    </TabTriggersContainer>
                </TabsList>
                <TabsContent value={route_const.accounts}>
                    <PageContent>
                        <AccountingMain />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.history}>
                    <PageContent>
                        <AccountingHistory />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.settings}>
                    <PageContent>
                        <AccountingSettings />
                    </PageContent>
                </TabsContent>
            </Tabs>
        </section>
    )
}
const AccountingEmptyPage = () => {
    const [openSetupModal, setOpenSetupModal] = useState(false)
    const [openAssignModal, setOpenAssignModal] = useState(false)
    return (
        <section className='grid place-items-center h-full'>
            <div className='space-y-6 bg-white p-8  rounded-xl'>
                <AccountingEmptyState className='mx-auto' />
                <p className='text-center font-medium text-gray7 max-w-sm mx-auto'>
                    Export your transactions to your preferred accounting platform
                </p>
                <div className='flex gap-4 justify-center'>
                    <Button onClick={() => setOpenSetupModal(true)}>Get started now</Button>
                    <Button onClick={() => setOpenAssignModal(true)} variant={'outline'}>
                        Assign an admin to set this up
                    </Button>
                </div>
            </div>
            {openSetupModal && <AccountingSetupModal closeModal={() => setOpenSetupModal(false)} />}
            {openAssignModal && <AssignAdminSetupModal closeModal={() => setOpenAssignModal(false)} />}
        </section>
    )
}
