import { Button } from '@/components'
import { CiSearch } from 'react-icons/ci'
import VendorsListTable from './components/VendorsListTable'
import { useGetOrgVendors } from '@/services/vendors/queries'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAuthContext, useVendorContext } from '@/context'
import { ColumnSort, Pagination } from '@/types/utils'
import ProgressBar from '@/components/ProgressBar'
import CreateNewVendor from '@/components/Modals/Vendors/CreateNewVendor/CreateNewVendor'
import { useGetOrgMembers } from '@/services/employees/queries'

import { useGetListOfBanks } from '@/services/payout/queries'
import { debounce } from 'lodash'
import { getSortString } from '@/components/Table/utils'
import FilterVendorChip from './FilterVendorChip'
import notFound from '@/assets/not-found.svg'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'
import PageContent from '@/layout/PageContent'
import { useGetOrgCategories } from '@/services/categories/queries'

const Vendors = () => {
    const [isCreateVendorModalOpen, setIsCreateVendorModalOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)

    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const { sort, filterFormState, setFilterFormState } = useVendorContext()
    const {
        data,
        isLoading: isOrgVendorsLoading,
        refetch: refetchAllOrgVendors,
        isRefetching,
    } = useGetOrgVendors({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
            search: searchQuery,
            sort: getSortString(sort as ColumnSort),
            status: filterFormState?.status,
            start_date: (filterFormState?.period?.from ?? filterFormState?.period?.time_in_words) as unknown as string,
            end_date: (filterFormState?.period?.to ?? new Date()) as unknown as string,
            min_amount: filterFormState?.amount?.min?.replaceAll(',', ''),
            max_amount: filterFormState?.amount?.max?.replaceAll(',', ''),
            category: filterFormState?.category,
        },
        onSuccess: () => {
            setFilterFormState(prev => ({ ...prev, filter_now: false }))
            setBackSpace(false)
        },
    })
    const orgVendors = useMemo(() => data?.vendors ?? [], [data])

    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])

    const { data: org_categories } = useGetOrgCategories()
    const categories = useMemo(() => org_categories ?? [], [org_categories])

    const orgVendorsPagination = useMemo(() => (data?.pagination ?? {}) as Pagination, [data])

    const { data: bank_details } = useGetListOfBanks()
    const allBankList = useMemo(() => bank_details ?? [], [bank_details])

    const debouncedSearch = useRef(
        debounce(() => {
            refetchAllOrgVendors()
        }, 300)
    ).current

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }

    useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgVendors()
            setBackSpaceClicked(false)
        }
        if (sort) {
            refetchAllOrgVendors()
        }
        if (filterFormState.filter_now) {
            refetchAllOrgVendors()
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, sort, filterFormState.filter_now, searchQuery, refetchAllOrgVendors, debouncedSearch])

    const { enabled: isVendorsEnabled } = useAccessToModule({ module: ModuleNameEnum.VENDORS })

    if (isOrgVendorsLoading) return <ProgressBar />
    return (
        <PageContent>
            <section className='mt-2 px-1'>
                <div className='block lg:flex justify-between mb-6'>
                    <div className='mb-4 lg:mb-0'>
                        <h1 className='text-dark font-medium text-lg'>Vendors</h1>
                        <p className='text-gray7 text-sm'>Create and manage vendors you do business with</p>
                    </div>
                    <Button
                        className='w-full lg:w-auto'
                        onClick={() => {
                            if (!isVendorsEnabled) {
                                setShowUpgradeModal(true)
                                setCurrentModule(ModuleNameEnum.VENDORS)

                                return
                            }
                            setIsCreateVendorModalOpen(true)
                        }}
                    >
                        Add new vendor
                    </Button>
                </div>

                <section className='bg-white rounded-2xl px-4 py-0'>
                    <div className='w-full py-6 block'>
                        <div className='flex items-center justify-between mb-6'>
                            <div className='border border-[#DADCE0] h-[45px] w-[70%] lg:w-[30%] rounded-lg flex items-center overflow-hidden '>
                                <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                                    <CiSearch fontSize={25} className='mx-1 lg:mx-0' />
                                </div>
                                <input
                                    type='text'
                                    placeholder='Search by vendor name or email'
                                    className='h-full w-[90%] outline-none  placeholder:text-xs'
                                    onChange={e => setSearchQuery(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className='my-4'>
                            <FilterVendorChip categories={categories} />
                        </div>
                        <div>
                            {
                                <div>
                                    {orgVendors.length === 0 && isRefetching ? (
                                        <ProgressBar />
                                    ) : (orgVendors.length === 0 && searchQuery?.length < 1 && !backspace) ||
                                      (orgVendors.length > 0 && searchQuery?.length >= 0 && !backspace) ? (
                                        <div
                                            className={` ${isRefetching ? 'opacity-50' : 'opacity-100'} w-full overflow-x-scroll`}
                                        >
                                            <VendorsListTable
                                                vendors={orgVendors}
                                                pagination_data={orgVendorsPagination}
                                            />
                                        </div>
                                    ) : (
                                        <div className='h-[300px] flex flex-col text-center justify-center items-center'>
                                            {searchQuery && orgVendors.length < 1 ? (
                                                <>
                                                    <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                                    <span className='text-xl font-bold text-[#5E5E5E]'>
                                                        Sorry! No result found :(
                                                    </span>
                                                    <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                                        "Oops! It seems we couldn't find any matching results for your
                                                        search. Please try again with different keywords or refine your
                                                        filter criteria for better results.
                                                    </span>
                                                </>
                                            ) : (
                                                <ProgressBar />
                                            )}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>

                    {isCreateVendorModalOpen && (
                        <CreateNewVendor
                            setCloseModal={setIsCreateVendorModalOpen}
                            orgMembers={orgMembers}
                            categories={categories}
                            allBankList={allBankList}
                        />
                    )}
                </section>
            </section>
        </PageContent>
    )
}

export default Vendors
