import { Button } from '@/components'
import { useAuthContext } from '@/context'
import { capitalizeText } from '@/utils/string'
import React from 'react'

type StartKYCProps = {
    setKycInfoStep: React.Dispatch<React.SetStateAction<number>>
}

export default function StartKYCModal({ setKycInfoStep }: StartKYCProps) {
    const { selectedAccount } = useAuthContext()

    return (
        <section className='my-10'>
            <h1 className='font-bold text-3xl'>
                Hi {capitalizeText(selectedAccount?.user?.first_name.toLocaleLowerCase() ?? '')},
            </h1>
            <p className='text-[#5E5E5E] my-4'>
                To ensure a secure environment for everyone , you’ll need to complete your KYC form . We appreciate your
                cooperation in providing the required information.
            </p>
            <Button className='mx-auto w-[55%] mt-14 h-16' onClick={() => setKycInfoStep(1)}>
                {' '}
                Complete KYC form now
            </Button>
        </section>
    )
}
