export enum EmployeeRole {
    all = 'all',
    owner = 'owner',
    admin = 'admin',
    user = 'user',
}

export const advancedFilteringProps = {
    role: '',
    period: { from: null, to: null, time_in_words: '' },
}
