import React from 'react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import filter from '@/assets/filter.svg'
import { AiOutlineEdit } from 'react-icons/ai'
import { capitalizeText } from '@/utils/string'
import { useNavigate } from 'react-router-dom'
import { LeaveRequestType } from '@/services/payroll/types'
import ExpenseStatusBadge from '../../Expenses/components/ExpenseStatus'
import { ExpenseStatus } from '../../Expenses/const'
import { differenceInDays, format, isDate } from 'date-fns'
import { usePayrollContext } from '@/context'
import { UserTypeLeave } from '@/types/payroll'
import { ColumnSort, Pagination } from '@/types/utils'
import LeaveEmptyState from '@/assets/leave-empty-state.svg'

interface LeaveRequestProps {
    leaveRequests: LeaveRequestType[]
    pagination?: Pagination
}

const LeaveRequestsTable = ({ leaveRequests, pagination }: LeaveRequestProps) => {
    const { setSelectedLeaveData, setSortLeave, setPageNumber, setPageNumberClicked, pageNumber } = usePayrollContext()

    const resultObject: any = {}

    leaveRequests.forEach(obj => {
        resultObject[obj.id] = {
            start_date: obj.start_date,
            end_date: obj.end_date,
        }
    })
    const navigate = useNavigate()

    const [rowSelection, setRowSelection] = React.useState({})

    const handleMoreDetails = (leave: UserTypeLeave) => {
        setSelectedLeaveData(leave)
        navigate(`/dashboard/payroll/leave/requests/${leave?._id}`)
    }
    const handleSort = (sort: ColumnSort) => {
        setSortLeave?.(sort)
    }

    const columnHelper = createColumnHelper<LeaveRequestType>()

    const columns: ColumnDef<LeaveRequestType, any>[] = [
        columnHelper.accessor('account', {
            header: () => <>Name</>,
            cell: cell => (
                <>
                    {cell.renderValue()?.user?.first_name} {cell.renderValue()?.user?.last_name}
                </>
            ),
        }),
        columnHelper.accessor('leave_type', {
            header: () => <>Type</>,
            cell: cell => capitalizeText(cell.renderValue() as string),
            enableSorting: true,
        }),
        columnHelper.accessor('start_date', {
            header: () => <>Leave period</>,
            cell: cell => {
                const startDate = new Date(cell.row.original?.start_date?.slice(-1)?.[0])
                const endDate = new Date(cell.row.original?.end_date?.slice(-1)?.[0])

                return (
                    <>
                        {Array.isArray(cell.row.original?.end_date) && Array.isArray(cell.row.original?.start_date) && (
                            <div className='text-xs font-medium'>
                                {isDate(startDate) && format(startDate, 'dd/MM/yyyy')} -{' '}
                                {isDate(endDate) && format(endDate, 'dd/MM/yyyy')}
                                {endDate && startDate && (
                                    <p className='mt-1 text-[#5E5E5E]'>{differenceInDays(endDate, startDate)} days</p>
                                )}
                            </div>
                        )}
                    </>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('reason', {
            header: () => <p>Description</p>,
            cell: cell => (
                <span className='max-w-[13rem] truncate block'>
                    {cell.renderValue()?.length > 0 ? cell.renderValue() : 'N/A'}
                </span>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('status', {
            header: () => <>Status</>,
            cell: cell => {
                if (cell.renderValue() === 'declined') {
                    return <ExpenseStatusBadge status={ExpenseStatus.denied} />
                }
                return <ExpenseStatusBadge status={cell.renderValue()} />
            },
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={filter} alt='button' />
                    </div>
                </div>
            ),
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                icon: AiOutlineEdit,
                                method: () => {
                                    handleMoreDetails(row?.row.original as unknown as UserTypeLeave)
                                },
                                actionColor: 'text-[#CF491E]',
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    const handleRowClick = (leave: UserTypeLeave) => {
        setSelectedLeaveData(leave)
        navigate(`/dashboard/payroll/leave/requests/${leave?._id}`)
    }

    return (
        <main className='mt-10'>
            <Table
                data={leaveRequests}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                handleSort={handleSort}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                setIsPageNumberClicked={setPageNumberClicked}
                pagination_data={pagination}
                emptyStateDescription='Streamline Leave Requests and Assignments with Real-time Visibility'
                emptyStateImg={LeaveEmptyState}
                emptyStateTitle='Start Tracking and Assignment of Leaves'
            />
        </main>
    )
}

export default LeaveRequestsTable
