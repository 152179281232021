import { CancelIcon } from '@/assets/assets.export'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import ModalWrapper from '@/components/Modals/ModalWrapper'
import { capitalizeText } from '@/utils/string'
import { useMemo, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'

import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { useCreateSubscription } from '@/services/billing/mutations'
import { useGetDirectoryPlans } from '@/services/billing/queries'
import { useParams } from 'react-router-dom'

type FormType = {
    plan_id: string
    start_date: string
}

type Props = {
    closeModal: () => void
    business_plan: string
}

const CreateSubscription = ({ closeModal, business_plan }: Props) => {
    const { user_id: org_id } = useParams()
    const ref = useRef<DatePicker>(null)

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<FormType>({})

    const { data } = useGetDirectoryPlans()
    const orgPlans = useMemo(() => data ?? [], [data])

    const { mutate, isLoading } = useCreateSubscription({
        onSuccess: () => {
            closeModal()
        },
    })

    const onSubmit = (values: FormType) => {
        const data_to_server = {
            plan: values?.plan_id,
            business_plan,
            org: org_id,
            start_date: values.start_date.toString(),
        }

        mutate(data_to_server)
    }
    return (
        <ModalWrapper closeModal={closeModal}>
            <section className='w-screen lg:w-[28rem] min-h-fit max-h-[90vh] overflow-scroll relative'>
                <form action='' onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex justify-between items-center border-b border-[#DADCE0] py-4'>
                        <h2 className='text-lg font-medium flex-1 text-center'>Create subscription</h2>

                        <button
                            className='bg-[#F2F2F2] rounded-full p-1 w-6 h-6 appearance-none inline-flex justify-center items-center mr-3'
                            aria-label='Close'
                            onClick={() => {
                                closeModal()
                            }}
                        >
                            <CancelIcon />
                        </button>
                    </div>

                    <div className='sm:grid grid-cols-1  gap-6  mx-2 sm:mx-6 mt-6 mb-24'>
                        <fieldset>
                            <label className='text-sm' htmlFor='plan_id'>
                                Plan name
                            </label>
                            <Controller
                                name={`plan_id`}
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <SelectTrigger className='h-[2.5rem] inline-flex justify-between border-[#DADCE0]  border rounded-md p-2 text-xs  py-4'>
                                            {!field.value ? (
                                                <span>Select plan</span>
                                            ) : (
                                                <SelectValue placeholder='Select feature' />
                                            )}
                                        </SelectTrigger>

                                        <SelectContent
                                            position='popper'
                                            className='shadow-md bg-white rounded-md px-3 text-sm text-[#202020]'
                                        >
                                            {orgPlans.map(plan => (
                                                <SelectItem
                                                    value={plan.id}
                                                    key={plan.id}
                                                    className='cursor-pointer flex item-center gap-2'
                                                >
                                                    {capitalizeText(plan.name)}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </fieldset>

                        <fieldset className='mb-[15px] flex flex-col flex-1  gap-1'>
                            <label className='text-sm' htmlFor='start_date'>
                                Start date
                            </label>
                            <Controller
                                name='start_date'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        {...field}
                                        ref={ref}
                                        dateFormat={'dd/MM/yyyy'}
                                        onChange={date => {
                                            field.onChange(date)
                                        }}
                                        selected={field.value ? new Date(field.value) : undefined}
                                        value={field.value ? new Date(field.value).toDateString() : undefined}
                                    />
                                )}
                            />
                        </fieldset>
                    </div>

                    <div className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                        <Button loading={isLoading} disabled={!isValid} className='my-1 ml-auto mr-6' type='submit'>
                            Create
                        </Button>
                    </div>
                </form>
            </section>
        </ModalWrapper>
    )
}

export default CreateSubscription
