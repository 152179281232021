import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import { PayrollAnalytics } from './partials/Analytics/PayrollAnalytics'
// import Benefits from './partials/Benefits/Benefits'
import Payhistory from './partials/Payhistory/Payhistory'
import PayrollList from './partials/PayrollList/PayrollList'
import RunPayroll from './partials/RunPayroll/RunPayroll'
import PayrollSettings from './partials/Settings/PayrollSettings'
import { prodEnvironment } from '@/utils/utils'
import { NewViewSwitch } from '@/components/SwitchOrgInView/NewViewSwitch'
import { AccessPermissionHOC } from '@/hoc/AccessPermissionHOC'
import { ADMIN_PAYROLL_PERMISSIONS } from '@/utils/permisson-helper'
import UserPayroll from '../UserPayroll/UserPayroll'
import { useGetOrgPayrolls } from '@/services/payroll/queries'
import { useAuthContext } from '@/context'

const payroll_routes_const = {
    list: 'payroll_list',
    run_payroll: 'run_payroll',
    // benefits: 'benefits',
    analytics: 'analytics',
    settings: 'setings',
    pay_history: 'pay_history',
    contract: 'contract',
}
export default function Payroll() {
    const { payroll_subRoute } = useParams()
    const navigate = useNavigate()

    const { selectedAccount } = useAuthContext()

    const { data: orgPayrolls } = useGetOrgPayrolls({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })

    const queryParams = new URLSearchParams(location.search)
    const navigateRoute = (path: string) => {
        const queryParams = location.search
        navigate(`/dashboard/payroll/${path}${queryParams}`)
    }
    const hasOrgPayrollAccess = queryParams.get('accountType') === 'company'

    return (
        <section className='h-full'>
            <AccessPermissionHOC requiredPermissions={ADMIN_PAYROLL_PERMISSIONS}>
                <section className='border-b border-gray4'>
                    <NewViewSwitch
                        personalRoute='/dashboard/payroll/pay_history'
                        companyRoute='/dashboard/payroll/payroll_list'
                    />
                </section>
                {hasOrgPayrollAccess && (
                    <section className=' mt-5 '>
                        <Tabs defaultValue={payroll_subRoute} value={payroll_subRoute}>
                            <TabsList className='border-none w-full overflow-y-scroll  overflow-x-scroll'>
                                <TabTriggersContainer className='min-w-[40rem]'>
                                    <TabsTrigger
                                        value={payroll_routes_const.list}
                                        onClick={() => navigateRoute(`${payroll_routes_const.list}`)}
                                        className={`relative border-none ${
                                            payroll_routes_const.list === payroll_subRoute ? '!text-white' : ''
                                        } text-sm`}
                                    >
                                        {payroll_routes_const.list === payroll_subRoute && (
                                            <motion.div layoutId='selected_btn' className={'v2_tab_styles !w-[115%]'} />
                                        )}
                                        <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                            Payment Profiles
                                        </motion.span>
                                    </TabsTrigger>

                                    <TabsTrigger
                                        value={payroll_routes_const.run_payroll}
                                        onClick={() => navigateRoute(`${payroll_routes_const.run_payroll}`)}
                                        className={`relative border-none ${
                                            payroll_routes_const.run_payroll === payroll_subRoute ? '!text-white' : ''
                                        } text-sm`}
                                    >
                                        {payroll_routes_const.run_payroll === payroll_subRoute && (
                                            <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                        )}
                                        <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                            Run payroll
                                        </motion.span>
                                    </TabsTrigger>

                                    <TabsTrigger
                                        value={payroll_routes_const.pay_history}
                                        onClick={() => navigateRoute(`${payroll_routes_const.pay_history}`)}
                                        className={`relative border-none ${
                                            payroll_routes_const.pay_history === payroll_subRoute ? '!text-white' : ''
                                        } text-sm`}
                                    >
                                        {payroll_routes_const.pay_history === payroll_subRoute && (
                                            <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                        )}
                                        <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                            Pay history
                                        </motion.span>
                                    </TabsTrigger>

                                    {!prodEnvironment() ? (
                                        <TabsTrigger
                                            value={payroll_routes_const.analytics}
                                            onClick={() => navigateRoute(`${payroll_routes_const.analytics}`)}
                                            className={`relative border-none ${
                                                payroll_routes_const.analytics === payroll_subRoute ? '!text-white' : ''
                                            } text-sm`}
                                        >
                                            {payroll_routes_const.analytics === payroll_subRoute && (
                                                <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                            )}
                                            <motion.span layout='position' className='z-20 relative left-1 bottom-1.5'>
                                                Analytics
                                            </motion.span>
                                        </TabsTrigger>
                                    ) : null}
                                    {Number(orgPayrolls?.profiles?.length) > 0 && (
                                        <TabsTrigger
                                            value={payroll_routes_const.settings}
                                            onClick={() => navigateRoute(`${payroll_routes_const.settings}`)}
                                            className={`relative border-none ${
                                                payroll_routes_const.settings === payroll_subRoute ? '!text-white' : ''
                                            } text-sm`}
                                        >
                                            {payroll_routes_const.settings === payroll_subRoute && (
                                                <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                            )}
                                            <motion.span
                                                layout='position'
                                                className='z-20 relative left-1.5 bottom-1.5'
                                            >
                                                Settings
                                            </motion.span>
                                        </TabsTrigger>
                                    )}
                                </TabTriggersContainer>
                            </TabsList>
                            <TabsContent value={payroll_routes_const.list}>
                                <PageContent>
                                    <PayrollList />
                                </PageContent>
                            </TabsContent>
                            <TabsContent value={payroll_routes_const.run_payroll}>
                                <PageContent>
                                    <RunPayroll />
                                </PageContent>
                            </TabsContent>

                            <TabsContent value={payroll_routes_const.pay_history}>
                                <PageContent>
                                    <Payhistory />
                                </PageContent>
                            </TabsContent>

                            <TabsContent value={payroll_routes_const.analytics}>
                                <PageContent>
                                    <PayrollAnalytics />
                                </PageContent>
                            </TabsContent>
                            <TabsContent value={payroll_routes_const.settings}>
                                <PageContent>
                                    <PayrollSettings />
                                </PageContent>
                            </TabsContent>
                        </Tabs>
                    </section>
                )}
            </AccessPermissionHOC>

            {!hasOrgPayrollAccess && <UserPayroll />}
        </section>
    )
}
