import { signedUrlContext, VaultItem } from '@/services/vault/types'
import React, { createContext, useState } from 'react'

export type formProps = {
    type: string
    date_created: { from: Date | null; to: Date | null; time_in_words: string }
    owner: string
    categories: string
    location: string
    approvals: string
    // access_type: string
}
export const advancedFilteringConsts = {
    type: '',
    date_created: { from: null, to: null, time_in_words: '' },
    owner: '',
    categories: '',
    location: '',
    approvals: '',
    // access_type: '',
}

export type defaultVaultProps = {
    selectedFolder: VaultItem | undefined
    setSelectedFolder: React.Dispatch<React.SetStateAction<VaultItem | undefined>>
    filterVault: formProps
    setFilterVault: React.Dispatch<React.SetStateAction<formProps>>
    signedURLData: signedUrlContext[] | undefined
    setSignedUrlData: React.Dispatch<React.SetStateAction<signedUrlContext[] | undefined>>
}

const defaultVaultState: defaultVaultProps = {
    selectedFolder: undefined,
    setSelectedFolder: () => undefined,
    filterVault: advancedFilteringConsts,
    setFilterVault: () => undefined,
    signedURLData: undefined,
    setSignedUrlData: () => undefined,
}

export const VaultContext = createContext(defaultVaultState)

export const VaultContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedFolder, setSelectedFolder] = useState<VaultItem | undefined>()
    const [filterVault, setFilterVault] = useState<formProps>(advancedFilteringConsts)
    const [signedURLData, setSignedUrlData] = useState<signedUrlContext[]>()

    const value = {
        selectedFolder,
        setSelectedFolder,
        filterVault,
        setFilterVault,
        signedURLData,
        setSignedUrlData,
    }
    return <VaultContext.Provider value={value}>{children}</VaultContext.Provider>
}
