import React, { useMemo, useState } from 'react'
import PayHistoryEmptyState from '@/assets/pay-history-empty-state.svg'
import { CiSearch } from 'react-icons/ci'
import { useAuthContext } from '@/context'
import { useNavigate } from 'react-router-dom'
import notFound from '@/assets/not-found.svg'

import { useGetorgContractPayrollHistory } from '@/services/payroll/queries'
import ProgressBar from '@/components/ProgressBar'
import { OrgContractPayHistory } from '@/types/payroll'
import OrgContractTable from '../ContractPayroll/components/OrgContractTable'

export default function OrgContractPayHistoryPage() {
    const [searchQuery, setSearchQuery] = useState('')
    const { selectedAccount } = useAuthContext()
    const {
        data: contract_payhistory,
        isLoading,
        isRefetching,
    } = useGetorgContractPayrollHistory({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            // owner: selectedAccount?.id  as string,
        },
    })
    const contractPayHistory = useMemo(
        () => contract_payhistory ?? ({} as OrgContractPayHistory),
        [contract_payhistory]
    )

    const navigate = useNavigate()

    if (isLoading) return <ProgressBar />
    return (
        <section className={`${contractPayHistory?.history?.length > 0 ? '' : 'pb-6'} `}>
            <div className='w-full py-6 block bg-white p-4 rounded-2xl'>
                <div className='flex items-center justify-between mb-6'>
                    {contractPayHistory?.history?.length > 0 && (
                        <div className='border border-[#DADCE0] h-[45px] w-[80%] lg:w-[30%] rounded-lg flex items-center overflow-hidden '>
                            <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                                <CiSearch fontSize={25} className='mx-1 lg:mx-0' />
                            </div>
                            <input
                                type='text'
                                placeholder='Search by amount, contract or milestone title'
                                className='h-full w-full outline-none placeholder:text-xs'
                                onChange={e => setSearchQuery(e.target.value)}
                            />
                        </div>
                    )}
                    <div className=''>&nbsp;</div>
                </div>
                {contractPayHistory?.history?.length > 0 ? (
                    <section
                        className={`${isRefetching ? 'opacity-50' : 'opacity-100'} transition-all rounded-lg overflow-x-scroll`}
                    >
                        <OrgContractTable contractHistory={contractPayHistory} />
                    </section>
                ) : (
                    <div className='h-450px] flex flex-col text-center justify-center items-center'>
                        {searchQuery ? (
                            <>
                                <img src={notFound} alt='Not found' className='mt-6 h-[270px]' />
                                <span className='text-3xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-[16px] w-[45%] mx-auto mt-3 font-medium'>
                                    "Oops! It seems we couldn't find any matching results for your search. Please try
                                    again with different keywords or refine your filter criteria for better results.
                                </span>
                            </>
                        ) : (
                            <>
                                <h3
                                    className='text-2xl font-bold text-[#5E5E5E] mt-14'
                                    onClick={() => navigate('1234')}
                                >
                                    No Payment made yet!
                                </h3>
                                <span className='text-[#202020] text-[14px] w-[45%] mx-auto mt-3 font-normal'>
                                    Monitor payments that are being paid to your bank account
                                </span>
                                <img
                                    src={PayHistoryEmptyState}
                                    alt='Payroll EMpty state'
                                    className='mt-6 h-[200px] aspect-square'
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
        </section>
    )
}
