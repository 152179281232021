import Table from '@/components/Table'
import { BulkProfileCSVType } from '@/types/payroll'
import { capitalizeText } from '@/utils/string'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import React, { useState } from 'react'

import { Button } from '@/components/Button'
import { formatAmount } from '@/utils/money'
import { QueryPayrollOrgSettings } from '@/services/payroll/types'
import { calculateBonusAndDeductionSum } from '@/modules/dashboard/Payroll/utils'
import { DeleteIcon } from '@/assets/assets.export'
import { useAuthContext } from '@/context'
import { useCreatePayrollFromDoc } from '@/services/payroll/mutations'
import { Edit } from 'lucide-react'
import BonusDeductionModal from './BonusDeductionModal'

type Props = {
    setSelectedProfiles: (data: BulkProfileCSVType[]) => void
    selectedProfiles: BulkProfileCSVType[]
    orgPayrollSettings?: QueryPayrollOrgSettings
    closeModal: () => void
}

export default function BulkPayrollPreviewTable({ selectedProfiles, setSelectedProfiles, closeModal }: Props) {
    const columnHelper = createColumnHelper<BulkProfileCSVType>()
    const [rowSelection, setRowSelection] = React.useState({})

    const [openBonusDeductionModal, setOpenBonusDeductionModal] = useState(false)
    const [rowData, setRowData] = useState<BulkProfileCSVType>()
    const { selectedAccount } = useAuthContext()

    const { mutate, isLoading } = useCreatePayrollFromDoc({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: () => {
            closeModal()
        },
    })

    const handleCreateBulkProfile = () => {
        const data = selectedProfiles.map(profile => ({
            email: profile?.['Email Address'],
            employment_type: profile?.['Employment Type'].toLowerCase(),
            start_date: profile?.['Start Date'],
            gross_salary: profile?.['Gross Salary'],
            pay_frequency: profile?.['Pay Frequency'].toLowerCase(),
            bonuses: profile?.bonuses?.map(bonus => ({
                amount: Number(bonus?.amount) / 100,
                name: bonus?.name,
                type: bonus?.type,
                org: selectedAccount?.org?.id,
            })),
            deductions: profile?.deductions.map(deduction => ({
                amount: Number(deduction?.amount) / 100,
                name: deduction?.name,
                type: deduction?.type,
                org: selectedAccount?.org?.id,
            })),
        }))

        mutate({ data })
    }

    const columns: ColumnDef<BulkProfileCSVType, any>[] = [
        columnHelper.accessor('Full Name', {
            header: () => <>Users</>,
            cell: cell => {
                return <>{capitalizeText(cell?.renderValue())}</>
            },
            enableSorting: false,
        }),

        columnHelper.accessor('Gross Salary', {
            header: () => <p>Gross Salary</p>,
            cell: cell => {
                return <span>{formatAmount(Number(cell.renderValue()) * 100)}</span>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('Org Deductions', {
            header: () => <p>Deductions</p>,
            cell: cell => {
                const deductions = cell.row.original?.deductions
                    .filter(deduction => deduction.toggle)
                    .map(deduction => ({
                        ...deduction,
                        amount: deduction.type === 'percentage' ? Number(deduction.amount) / 100 : deduction.amount,
                    }))

                const amount = calculateBonusAndDeductionSum(
                    deductions ?? [],
                    'amount',
                    cell.row.original?.['Gross Salary']
                )

                return (
                    <span className='bg-[#EFEFF9] p-2  rounded  flex items-center justify-between gap-8'>
                        {formatAmount(Number(amount))}
                        <button
                            aria-label='Icon'
                            onClick={() => {
                                setOpenBonusDeductionModal(true)
                                setRowData(cell.row.original)
                            }}
                        >
                            <Edit size={16} strokeWidth={1.8} />
                        </button>
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('Org Bonuses', {
            header: () => <p>Bonuses</p>,
            cell: cell => {
                const bonuses = cell.row.original?.bonuses
                    .filter(bonus => bonus.toggle)
                    .map(bonus => ({
                        ...bonus,
                        amount: bonus.type === 'percentage' ? Number(bonus.amount) / 100 : bonus.amount,
                    }))

                const amount = calculateBonusAndDeductionSum(
                    bonuses ?? [],
                    'amount',
                    cell.row.original?.['Gross Salary']
                )
                return (
                    <span className='bg-[#EFEFF9] p-2 rounded flex items-center justify-between gap-8'>
                        {formatAmount(Number(amount))}

                        <button
                            aria-label='Icon'
                            onClick={() => {
                                setOpenBonusDeductionModal(true)
                                setRowData(cell.row.original)
                            }}
                        >
                            <Edit size={16} strokeWidth={1.8} />
                        </button>
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('Employment Type', {
            header: () => <p>Employment Type</p>,
            cell: cell => cell.renderValue(),
            enableSorting: false,
        }),

        columnHelper.display({
            id: 'actions',
            cell: cell => {
                return (
                    <button aria-label='icon'>
                        <DeleteIcon
                            onClick={() =>
                                setSelectedProfiles(
                                    selectedProfiles.filter(
                                        profile => profile?.['Email Address'] !== cell.row.original?.['Email Address']
                                    )
                                )
                            }
                        />
                    </button>
                )
            },
        }),
    ]

    return (
        <>
            <div className='mx-8 mt-5'>
                <h3 className='font-medium text-base'>Preview payroll import</h3>
                <p className='text-sm text-gray7 max-w-md mt-1.5'>
                    Kindly preview the imported payroll list to make sure all information are entered correctly.
                </p>
            </div>

            <section className='h-full text-gray7 font-medium m-8'>
                <Table
                    data={selectedProfiles}
                    columns={columns}
                    rowSelection={rowSelection}
                    rowClickable
                    setRowSelection={setRowSelection}
                />
            </section>
            <div className='mx-8 my-6 flex justify-end'>
                <Button onClick={handleCreateBulkProfile} loading={isLoading} size={'lg'} className='text-xs'>
                    Import payroll list
                </Button>
            </div>

            {openBonusDeductionModal && (
                <BonusDeductionModal
                    rowData={rowData}
                    closeModal={() => {
                        setOpenBonusDeductionModal(false)
                    }}
                    selectedProfiles={selectedProfiles}
                    setSelectedProfiles={setSelectedProfiles}
                />
            )}
        </>
    )
}
