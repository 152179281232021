import { ProfileEditIcon } from '@/assets/assets.export'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { useGetPayrollSettingsForOrg } from '@/services/payroll/queries'
import { defaultAdditionProps } from '@/types/payroll'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import moment from 'moment'
import { useState } from 'react'
import { formatDate } from '../../helper'

import PayDaySettingsEdit from './partials/PayDaySettings'
import PayrollFormCustoms from './partials/PayrollFormCustoms'
import PayrollGenerationEdit from './partials/PayrollGenerationSettings'

const PayrollSettings = () => {
    const [editPayrollGenSettings, setEditPayrollGenSettings] = useState(false)
    const [editPayDaySettings, setEditPayDaySettings] = useState(false)
    const [editPayrollForm, setEditPayrollForm] = useState(false)

    const { selectedAccount } = useAuthContext()

    const { data: orgPayrollSettings, isLoading } = useGetPayrollSettingsForOrg({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    if (isLoading) return <ProgressBar />
    return (
        <section className='overflow-scroll pb-20'>
            <div className='my-8'>
                <div className='mb-6'>
                    <h1 className='text-xl font-medium'>Payroll settings</h1>
                    <p className='text-[15px] text-[#5E5E5E] font-base mt-1'>
                        Fine-Tuning Your Payroll Processes for Efficiency and Accuracy
                    </p>
                </div>
                {!editPayDaySettings && (
                    <div className='p-6 rounded-lg border border-[#DADCE0] mb-5'>
                        <div className='flex justify-between items-center mb-6'>
                            <div className=''>
                                <h2 className='text-lg font-medium'>Pay day</h2>
                                <p className='text-[14px] text-[#5E5E5E] mt-1'>
                                    Select pay day for monthly, bi-weekly and weekly pay frequency
                                </p>
                            </div>
                            <span
                                className='flex items-center gap-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-2 border px-4 cursor-pointer'
                                onClick={() => setEditPayDaySettings(true)}
                            >
                                <ProfileEditIcon />
                                <button type='button'>Edit</button>
                            </span>
                        </div>
                        <div className='flex gap-6 lg:gap-12 flex-wrap'>
                            <div className=''>
                                <h2 className='text-[#838383] text-[15px]'>Monthly</h2>
                                <h2 className='text-base font-medium mt-2'>
                                    {`${orgPayrollSettings?.monthly_payment_day}${formatDate(
                                        orgPayrollSettings?.monthly_payment_day
                                    )} `}{' '}
                                    of every month
                                </h2>
                                <div className='mt-2'>
                                    <p className='text-[#838383] text-[13px]'>Current Period:</p>
                                    <small>
                                        {moment(orgPayrollSettings?.monthly_period_start).format('DD/MM/YYYY')} -{' '}
                                        {moment(orgPayrollSettings?.monthly_period_end).format('DD/MM/YYYY')}
                                    </small>
                                </div>
                            </div>
                            <div className=''>
                                <h2 className='text-[#838383] text-[15px]'>Weekly</h2>
                                <h2 className='text-base font-medium mt-2'>
                                    Every {capitalizeText(orgPayrollSettings?.weekly_payment_day)}
                                </h2>
                                <div className='mt-2'>
                                    <p className='text-[#838383] text-[13px]'>Current Period:</p>
                                    <small>
                                        {moment(orgPayrollSettings?.weekly_period_start).format('DD/MM/YYYY')} -{' '}
                                        {moment(orgPayrollSettings?.weekly_period_end).format('DD/MM/YYYY')}
                                    </small>
                                </div>
                            </div>
                            <div className=''>
                                <h2 className='text-[#838383] text-[15px]'>Bi-weekly</h2>
                                <h2 className='text-base font-medium mt-2'>
                                    Every 2 {`${capitalizeText(orgPayrollSettings?.biweekly_payment_day)}s `}
                                    of the month
                                </h2>
                                <div className='mt-2'>
                                    <p className='text-[#838383] text-[13px]'>Current Period:</p>
                                    <small>
                                        {moment(orgPayrollSettings?.biweekly_period_start).format('DD/MM/YYYY')} -{' '}
                                        {moment(orgPayrollSettings?.biweekly_period_end).format('DD/MM/YYYY')}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {editPayDaySettings && (
                    <PayDaySettingsEdit
                        id={orgPayrollSettings?.id as string}
                        setEditPayDaySettings={setEditPayDaySettings}
                        monthlyDate={orgPayrollSettings?.monthly_payment_day}
                        biWeeklyDate={orgPayrollSettings?.biweekly_payment_day?.toString()}
                        weeklyDate={orgPayrollSettings?.weekly_payment_day}
                        biWeeklyDay={orgPayrollSettings?.biweekly_payment_start_date}
                    />
                )}
                {!editPayrollForm && (
                    <div className='p-6 pb-0 rounded-lg border border-[#DADCE0] my-4'>
                        <div className='flex justify-between items-center mb-4'>
                            <div className=''>
                                <h2 className='text-lg font-medium'>Payroll form</h2>
                                <p className='text-[14px] text-[#5E5E5E]  mt-1'>
                                    Add bonus and deduction types to the payroll form
                                </p>
                            </div>
                            <span
                                className='flex items-center gap-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-2 border px-4 cursor-pointer'
                                onClick={() => setEditPayrollForm(true)}
                            >
                                <ProfileEditIcon />
                                <button type='button'>Edit</button>
                            </span>
                        </div>

                        {orgPayrollSettings?.default_deductions &&
                            orgPayrollSettings?.default_deductions.length > 0 && (
                                <div>
                                    <p className='mb-2 text-sm text-black font-semibold  mt-2'>Deduction types</p>
                                    {
                                        <div className='flex gap-12 mb-8'>
                                            {orgPayrollSettings?.default_deductions?.map(deduction => (
                                                <div className=''>
                                                    <h2 className='text-[#838383] text-[15px]'>{deduction?.name}</h2>
                                                    <h2 className='text-base font-medium mt-2'>
                                                        {deduction.type === 'percentage'
                                                            ? `${deduction.amount}%`
                                                            : formatAmount(deduction.amount)}
                                                        {/* {deduction?.amount} {deduction?.type === 'percentage' && '%'} */}
                                                    </h2>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            )}
                        {orgPayrollSettings?.default_bonuses && orgPayrollSettings?.default_bonuses.length > 0 && (
                            <div>
                                <p className='mb-2 text-sm text-black font-semibold  mt-2'>Bonus types</p>
                                {
                                    <div className='flex gap-12 mb-8'>
                                        {orgPayrollSettings?.default_bonuses?.map(bonus => (
                                            <div className=''>
                                                <h2 className='text-[#838383] text-[15px]'>{bonus?.name}</h2>
                                                <h2 className='text-base font-medium mt-2'>
                                                    {bonus.type === 'percentage'
                                                        ? `${bonus.amount}%`
                                                        : formatAmount(bonus.amount)}
                                                </h2>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                )}
                {editPayrollForm && (
                    <PayrollFormCustoms
                        id={orgPayrollSettings?.id as string}
                        setEditPayrollCustoms={setEditPayrollForm}
                        defaultBonuses={orgPayrollSettings?.default_bonuses as defaultAdditionProps[]}
                        defaultDeductions={orgPayrollSettings?.default_deductions as defaultAdditionProps[]}
                    />
                )}

                {!editPayrollGenSettings && (
                    <div className='p-6 rounded-lg border border-[#DADCE0]'>
                        <div className='flex justify-between items-center mb-4'>
                            <div className=''>
                                <h2 className='text-lg font-medium'>Payroll generation</h2>
                                <p className='text-[14px] text-[#5E5E5E]  mt-1'>
                                    Select the day you want payroll to be generated for monthly, bi-weekly or weekly
                                    salaries.
                                </p>
                            </div>
                            <span
                                className='flex items-center gap-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-2 border px-4 cursor-pointer'
                                onClick={() => setEditPayrollGenSettings(true)}
                            >
                                <ProfileEditIcon />
                                <button type='button'>Edit</button>
                            </span>
                        </div>

                        <p className='mb-4 text-[14px] text-[#5E5E5E] font-medium  mt-1'>
                            When should automated payroll be generated?
                        </p>
                        <div className='flex gap-5 lg:gap-12 flex-wrap'>
                            <div className=''>
                                <h2 className='text-[#838383] text-[15px]'>Monthly</h2>
                                <h2 className='text-base font-medium mt-2'>
                                    {orgPayrollSettings?.monthly_lead_days}{' '}
                                    {orgPayrollSettings?.monthly_lead_days
                                        ? orgPayrollSettings?.monthly_lead_days > 1
                                            ? 'days'
                                            : 'day'
                                        : ''}{' '}
                                    before payday
                                </h2>
                            </div>
                            <div className=''>
                                <h2 className='text-[#838383] text-[15px]'>Weekly</h2>
                                <h2 className='text-base font-medium mt-2'>
                                    {orgPayrollSettings?.weekly_lead_days}{' '}
                                    {orgPayrollSettings?.weekly_lead_days
                                        ? orgPayrollSettings?.weekly_lead_days > 1
                                            ? 'days'
                                            : 'day'
                                        : ''}{' '}
                                    before payday
                                </h2>
                            </div>
                            <div className=''>
                                <h2 className='text-[#838383] text-[15px]'>BiWeekly</h2>
                                <h2 className='text-base font-medium mt-2'>
                                    {orgPayrollSettings?.biweekly_lead_days}{' '}
                                    {orgPayrollSettings?.biweekly_lead_days
                                        ? orgPayrollSettings?.biweekly_lead_days > 1
                                            ? 'days'
                                            : 'day'
                                        : ''}{' '}
                                    before payday
                                </h2>
                            </div>
                        </div>
                    </div>
                )}
                {editPayrollGenSettings && (
                    <PayrollGenerationEdit
                        setEditPayrollGenSettings={setEditPayrollGenSettings}
                        id={orgPayrollSettings?.id as string}
                        biweekly_lead_days={orgPayrollSettings?.biweekly_lead_days}
                        monthly_lead_days={orgPayrollSettings?.monthly_lead_days}
                        weekly_lead_days={orgPayrollSettings?.weekly_lead_days}
                    />
                )}
            </div>
        </section>
    )
}

export default PayrollSettings
