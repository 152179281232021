import { Button } from '@/components'
import React, { useRef, useState } from 'react'
import { ArrowLeft } from 'lucide-react'
import useSingleFileUpload, { FileUploadFormatProps, fileEmptyState } from '@/hooks/useSingleFileUpload'
import { TbReload } from 'react-icons/tb'
import { XLSX_SVG } from '@/assets/assets.export'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
}

export default function CSVTransfer2({ setStep }: Props) {
    const fileRef = useRef<HTMLInputElement>(null)
    const [bulkTransferFile, setBultTransferFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)

    const { handleFile: uploadBulfTransferFn } = useSingleFileUpload({
        fileUpload: bulkTransferFile,
        setFileUpload: setBultTransferFile,
    })

    const handleUploadBulkTransfer = (e: React.ChangeEvent<HTMLInputElement>) => {
        uploadBulfTransferFn(e)
    }
    return (
        <div className='w-[30rem]'>
            <div className='flex gap-1 my-6 cursor-pointer mb-12' onClick={() => setStep(1)}>
                <ArrowLeft />
                back
            </div>
            <div className='h-[70vh] overflow-scroll bg-white'>
                <h4 className='font-medium text-lg opacity-80 mb-5'>Step 3: Upload the Completed CSV File</h4>
                <p className='text-[#5e5e5e] text-base font-medium mb-8'>
                    Follow the steps below to successfully upload bulk recipients
                </p>
                <ul className='mt-4 text-[#5e5e5e] flex flex-col gap-5'>
                    <li className='flex gap-2'>
                        <span className='mr-2'>1.</span>
                        <span>
                            Click on the{' '}
                            <span className='text-[#454ADE] font-medium'>"Click here to upload CSV file"</span> button
                            below to obtain the pre-formatted CSV file.
                            <span className='font-medium text-[black]'>
                                {' '}
                                tab below to upload the completed CSV file from your computer.
                            </span>
                        </span>
                    </li>
                    <li className='flex gap-2'>
                        <span className='mr-2'>2.</span>
                        Locate and select the file you saved earlier, ensuring it has the ".csv or .xlsx" file
                        extension.
                    </li>

                    <li className='flex gap-2'>
                        <span className='mr-2'>3.</span>
                        <span>
                            After selecting the file, click on the{' '}
                            <span className='text-[#454ADE] font-medium'>"Upload recipients"</span> button to initiate
                            the process.
                        </span>
                    </li>
                    <li className='flex gap-2 mt-8'>
                        {' '}
                        <div
                            className='border-dashed border border-[#DADCE0] rounded-lg px-5 py-3 w-[25rem] mx-auto mb-8 cursor-pointer'
                            onClick={() => fileRef?.current?.click()}
                        >
                            <input
                                type='file'
                                name='csv_file'
                                id='csv_file'
                                aria-label='Upload your csv file here'
                                hidden
                                ref={fileRef}
                                accept='.csv, .xls, .xlsx'
                                onChange={handleUploadBulkTransfer}
                            />

                            {!bulkTransferFile?.file ? (
                                <label
                                    htmlFor='profile_img'
                                    className={`${'bg-[#DADCE0'} block cursor-pointer text-lg font-medium text-[#A8A8A8] w-full `}
                                >
                                    <>
                                        <div className='my-8 text-base'>
                                            <span className='block text-center font-normal '>
                                                Click here to upload CSV file
                                            </span>
                                            <span className='block text-center font-normal '>
                                                only .csv, .xls files. Max size: 2MB
                                            </span>
                                        </div>
                                    </>
                                </label>
                            ) : (
                                <div className='w-[10rem] border border-[#DADCE0] rounded-xl '>
                                    <XLSX_SVG className=' w-[70%] mx-auto  p-2 opacity-80' />
                                    <span className='text-center w-full text-xs block mb-5'>
                                        {bulkTransferFile?.file_name}
                                    </span>
                                    <span className='flex gap-2 mx-2 items-center mb-3 mt-1 text-[#5E5E5E] justify-center'>
                                        <button
                                            type='button'
                                            className='text-sm text-[#CF491E] flex items-center justify-center gap-1 cursor-pointer'
                                            onClick={() => {
                                                fileRef?.current?.click()
                                            }}
                                        >
                                            <TbReload /> Change
                                        </button>
                                    </span>
                                </div>
                            )}
                        </div>
                    </li>
                </ul>
            </div>
            <Button className='w-full'>Next</Button>
        </div>
    )
}
