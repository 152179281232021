import { Popover } from '@headlessui/react'
import { ReactNode } from 'react'

type Props = {
    children: ReactNode
}
export default function ThreeDotsPopOver({ children }: Props) {
    return (
        <Popover className='relative'>
            <Popover.Button className='text-brand font-medium'>More options</Popover.Button>
            <Popover.Panel className='-right-4 lg:right-4 absolute z-10 bg-white shadow-md rounded-lg p-5 py-3 lg:py-6'>
                <div className='text-[#5E5E5E] font-medium'>{children}</div>
            </Popover.Panel>
        </Popover>
    )
}
