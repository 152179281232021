import { PlansCheckmark } from '@/assets/assets.export'
import { Button } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useToggleArchivePlan } from '@/services/billing/mutations'
import { Billing } from '@/types/billing'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { Archive, Edit, Loader2 } from 'lucide-react'
import { useState } from 'react'
import CreateBillingPlan from '../../../../components/Modals/Business-directory/CreateBillingPlan'

type Props = {
    plan: Billing
    business_directory?: boolean
}

export default function PlansCard({ plan, business_directory }: Props) {
    const [editPlan, setEditPlan] = useState(false)
    const isBusinessDirectory = location.pathname.includes('business-directory')

    const { mutate: togglePlanFn, isLoading } = useToggleArchivePlan({
        queryParams: {
            id: plan?.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Plan updated' })
        },
    })
    return (
        <section className='bg-white rounded-xl p-5 py-3 h-fit w-full'>
            <div className=' mb-6'>
                <div className='flex justify-between items-center mb-2'>
                    <h4 className='font-semibold text-lg'>{capitalizeText(plan?.name)}</h4>
                    {isBusinessDirectory && (
                        <div className='flex items-center gap-4'>
                            <button
                                type='button'
                                className='flex items-center gap-2 text-blue-700 rounded-md cursor-pointer text-sm'
                                onClick={() => {
                                    setEditPlan(true)
                                }}
                            >
                                <Edit className='w-4 h-4' />
                                <span>Edit</span>
                            </button>

                            <button
                                type='button'
                                className={`flex items-center gap-2 text-sm rounded-md cursor-pointer ${
                                    plan?.archived ? 'text-green' : 'text-errorRed'
                                }`}
                                onClick={() => togglePlanFn({ archived: plan?.archived ? false : true })}
                            >
                                {isLoading ? (
                                    <Loader2 className='w-4 h-4 animate-spin' />
                                ) : (
                                    <Archive className='w-4 h-4' />
                                )}
                                <span>{plan?.archived ? 'Activate' : 'Archive'}</span>
                            </button>
                        </div>
                    )}
                </div>
                <p className='text-[#5E5E5E] text-sm'>{plan?.description}</p>
            </div>

            <div className='text-3xl font-semibold mb-2'>
                {formatAmount(plan?.price)}
                <span className='font-normal text-sm'>/ month</span>
            </div>

            <span
                className={`mb-6 inline-block text-xs p-1 px-2  ${plan?.archived ? 'text-errorRed bg-errorRed/10' : 'text-[#19C37D] bg-[#EFFFED]'}  rounded-[2rem]`}
            >
                {plan?.archived ? 'Inctive' : 'Active'}
            </span>

            {!business_directory && (
                <Button className='w-full min-h-[48px] mb-6 disabled:bg-[#F9F9F9] disabled:text-[#A8A8A8]'>
                    Current Plan
                </Button>
            )}
            <ul className='flex flex-col gap-3'>
                {plan.metadata.map(plan => (
                    <li className='flex items-center gap-1 text-xs font-medium  '>
                        <PlansCheckmark className='w-3 h-3' /> {plan}
                    </li>
                ))}
            </ul>

            {editPlan && <CreateBillingPlan edit={true} planDetails={plan} closeModal={() => setEditPlan(false)} />}
        </section>
    )
}
