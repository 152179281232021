import { Button } from '@/components'

import { AiOutlineClose } from 'react-icons/ai'
import { Textarea } from '@/components'
import { useState } from 'react'

import { toastHandler } from '@/components/utils/Toast'
import ModalWrapper from '../../ModalWrapper'
import { UserTypeLeave } from '@/types/payroll'
import { LeaveRequestType } from '@/services/payroll/types'
import { useDeclineLeave } from '@/services/payroll/mutations'
import { usePayrollContext } from '@/context'

type Props = {
    closeModal: () => void
    leaveData?: UserTypeLeave | LeaveRequestType
}

export default function DeclineLeaveRequest({ closeModal, leaveData }: Props) {
    const [note, setNote] = useState('')

    const { setSelectedLeaveData } = usePayrollContext()
    const { mutate, isLoading } = useDeclineLeave({
        onSuccess: data => {
            setSelectedLeaveData(prev => {
                return prev ? { ...prev, status: (data as LeaveRequestType).status } : undefined
            })
            toastHandler({ message: 'Leave has been declined', state: 'success' })
        },
    })

    return (
        <ModalWrapper variants={undefined} closeModal={() => closeModal()}>
            <div className='w-screen lg:w-[28rem] rounded-[20px]'>
                <div className='border-b border-[#DADCE0] py-3 p-2  flex justify-end'>
                    <div className='flex items-center  justify-between h-full flex-1'>
                        <h2 className='font-semibold text-[#31254B] text-lg text-center flex-1'>Decline leave</h2>
                        <div
                            className='bg-[#F2F2F2] w-6 h-6 p-1.5 rounded-full flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal()}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>

                <div className='px-3 mt-5  pb-3  max-h-[400px] lg:max-h-[80vh] overflow-scroll'>
                    <div className={`flex flex-col gap-8 mb-12`}>
                        <div className='flex flex-col flex-1 gap-1.5'>
                            <label className='text-[#202020] text-sm font-medium'>Reason</label>
                            <Textarea
                                name='note'
                                placeholder='Explain further if you wish to.'
                                className='border border-[#DADCE0]  resize-none placeholder:text-sm text-sm'
                                value={note}
                                onChange={e => setNote(e.target.value)}
                            />
                        </div>
                    </div>
                    <Button
                        className={`min-h-0 w-full  text-sm font-normal mb-3 disabled:opacity-30 bg-errorRed hover:bg-errorRed/50 disabled:bg-errorRed/50`}
                        loading={isLoading}
                        onClick={() => {
                            mutate({
                                leave_id: leaveData?.id ?? leaveData?._id,
                                reason: note,
                            })
                        }}
                    >
                        Decline leave
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
