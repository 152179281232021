// import CheckBox from '@/components/Table/CheckBox'
import TableActions from '@/components/Table/TableActions'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import React, { useState } from 'react'
import PayrollEmptyState from '@/assets/payroll-empty-state.svg'
import Table from '@/components/Table'
import { ContractPayrollType } from '@/types/payroll'
import { usePayrollContext } from '@/context'
import { useNavigate, useParams } from 'react-router-dom'
import { QueryOrgContractPayRollType } from '@/services/payroll/types'
import { capitalizeText } from '@/utils/string'
import { formatAmount } from '../../../../utils/money'
import { format } from 'date-fns'
import StatusColor from '@/components/utils/StatusColor'
import { convertintervalToDurationObjToString, getCompletedMileStoneAmount } from '../utils'
import { ColumnSort } from '@/types/utils'

type ContractTableProps = {
    data: QueryOrgContractPayRollType
}
const ContractPayrollTable = ({ data }: ContractTableProps) => {
    const columnHelper = createColumnHelper<ContractPayrollType>()
    const { setSelectedContract, setSortContract } = usePayrollContext()
    const navigate = useNavigate()

    const [rowSelection, setRowSelection] = React.useState({})

    const [pageNumber, setPageNumber] = useState(1)
    const { contract_subRoute } = useParams()
    const handleSort = (sort: ColumnSort) => {
        // console.log(sort)
        setSortContract?.(sort)
    }

    const columns: ColumnDef<ContractPayrollType, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('contract_title', {
            header: () => <p>Contract title</p>,
            cell: cell => cell.renderValue(),
            // enableSorting: true,
        }),
        columnHelper.accessor('employee.user.first_name', {
            header: () => <>Contractor</>,
            cell: cell => {
                return (
                    <>
                        {capitalizeText(cell?.renderValue())}{' '}
                        {capitalizeText(cell.row.original?.employee?.user?.last_name)}
                    </>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('start_date', {
            header: () => <p>Duration</p>,
            cell: cell => {
                const { formattedDateInWords } = convertintervalToDurationObjToString(
                    cell.row.original?.end_date,
                    cell.renderValue()
                )

                return (
                    <div className=''>
                        <span className='font-medium'>{formattedDateInWords}</span>

                        <p className='mt-1 text-[#5E5E5E]'>
                            {format(new Date(cell.renderValue()), 'dd/MM/yyyy')} -{' '}
                            {format(new Date(cell.row.original?.end_date), 'dd/MM/yyyy')}
                        </p>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('total_pay', {
            header: () => <p>Total pay</p>,
            cell: cell => {
                const totalMileStoneAmt = getCompletedMileStoneAmount(cell?.row.original?.milestones ?? [])
                const CalculatePercentageOfWorkDone = Number(totalMileStoneAmt) / Number(cell?.row.original.total_pay)

                return (
                    <div>
                        <span>{formatAmount(cell.renderValue())}</span>
                        <span className='block text-[#5E5E5E]'>
                            {CalculatePercentageOfWorkDone.toString().includes('.')
                                ? (CalculatePercentageOfWorkDone * 100).toFixed(2)
                                : CalculatePercentageOfWorkDone * 100}
                            % Paid
                        </span>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Contract Status</p>,
            cell: cell => {
                return (
                    <div className=''>
                        <StatusColor status={cell.renderValue()} />
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                method: () => {
                                    handleRowClick(row?.row.original as unknown as ContractPayrollType)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    const handleRowClick = (contract: ContractPayrollType) => {
        setSelectedContract(contract)
        navigate(`/dashboard/contract/${contract_subRoute}/${contract.id}`)
    }
    return (
        <section className='h-full'>
            <Table
                data={data?.payrolls}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                pagination_data={data?.pagination}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                rowClickable={true}
                handleSort={handleSort}
                emptyStateImg={PayrollEmptyState}
                emptyStateDescription={'Manage your contract staffs here'}
                emptyStateTitle={'Contracts'}
            />
        </section>
    )
}

export default ContractPayrollTable
