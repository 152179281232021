import { EmptyFolder } from '@/assets/assets.export'
import { Button } from '@/components'
import { AddNewFileModal } from '@/components/Modals/Vault/AddNewFileModal'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { useAuthContext } from '@/context'
import useAccessToModule from '@/hooks/useAccessToModule'
import { useGetAllVaults, useGetVaultCatgeories } from '@/services/vault/queries'
import { ModuleNameEnum } from '@/types/billing'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const FileEmptyState = ({ type }: { type: string }) => {
    const [showAddNewFile, setShowAddNewFile] = useState(false)
    const { setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const { enabled: isVaultEnabled } = useAccessToModule({ module: ModuleNameEnum.VAULT })

    const { data: vaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]
    const [searchParams] = useSearchParams()
    const { data: vaultCategories } = useGetVaultCatgeories({
        enabled: !!vaults,
    })

    return (
        <div className='w-full flex h-full items-center justify-center flex-col col-span-4 mt-10'>
            <EmptyFolder className='mb-8' />
            <p className='text-[20px] text-gray7 font-medium mb-5'>
                {searchParams.get('access') === 'shared'
                    ? 'No file or folder shared yet'
                    : 'No file uploaded or folder created yet'}
            </p>
            <Button
                onClick={() => {
                    if (!isVaultEnabled) {
                        setShowUpgradeModal(true)
                        setCurrentModule(ModuleNameEnum.VAULT)
                        return
                    }
                    setShowAddNewFile(true)
                }}
                className={searchParams.get('access') === 'personal' ? '' : 'hidden'}
            >
                Upload files or create folder
            </Button>
            {showAddNewFile &&
                (type === 'folder' ? (
                    <CreateNewFolder closeModal={setShowAddNewFile} vault={singleVault} />
                ) : (
                    <AddNewFileModal
                        closeModal={setShowAddNewFile}
                        vault={singleVault}
                        vaultCategories={vaultCategories}
                    />
                ))}
        </div>
    )
}
