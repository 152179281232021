import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import { OverviewInsight } from '@/services/auth/types'

type Props = {
    orgInsight: OverviewInsight
}
const GetStarted = ({ orgInsight }: Props) => {
    const isActiveEmployee = orgInsight?.no_of_employees && orgInsight?.no_of_employees > 1
    const isActiveTeam = orgInsight?.teams && orgInsight?.teams > 0
    return (
        <section className='pb-0 overflow-hidden'>
            {orgInsight?.wallet_balance < 0 && (
                <div className='mt-4'>
                    <Swiper slidesPerView={2.5} spaceBetween={15}>
                        <SwiperSlide>
                            <div className='border border-[#DADCE0] bg-[#ffffff] p-4 rounded h-[230px]'>
                                <div className='py-2 px-4 w-fit mb-3 bg-[#F9F6FF] rounded-[50%] text-[#454ADE]'>
                                    <span>1</span>
                                </div>
                                <h4 className='mb-2 font-semibold'>Fund Your Account</h4>
                                <p className='text-[#5E5E5E]'>
                                    Start managing your expenses by funding your account now!
                                </p>

                                <div className='flex justify-end mt-6'>
                                    <button className='bg-[#454ADE] text-white py-2 px-4 rounded'>Fund wallet</button>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className={`border border-[#DADCE0] bg-[#ffffff] p-4 rounded h-[230px]`}>
                                <div
                                    className={`py-2 px-4 w-fit mb-3 ${
                                        isActiveEmployee ? 'bg-[#F2F2F2] text-[#838383]' : 'bg-[#F9F6FF] text-[#454ADE]'
                                    } rounded-[50%]`}
                                >
                                    <span>2</span>
                                </div>
                                <h4
                                    className={`mb-2 font-semibold ${
                                        isActiveEmployee ? 'text-[#838383]' : ' text-[#202020]'
                                    }`}
                                >
                                    Invite Users
                                </h4>
                                <p className={` ${isActiveEmployee ? 'text-[#A8A8A8]' : ' text-[#5E5E5E]'}`}>
                                    Empower your users to monitor and manage company expenses together!
                                </p>

                                <div className='flex justify-end mt-6'>
                                    <button
                                        disabled={isActiveEmployee as boolean}
                                        className={`${
                                            isActiveEmployee ? 'bg-[#DADCE0] ' : 'bg-[#454ADE]'
                                        } text-white py-2 px-4 rounded`}
                                    >
                                        Invite now
                                    </button>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className={`border border-[#DADCE0] bg-[#ffffff] p-4 rounded h-[230px]`}>
                                <div
                                    className={`py-2 px-4 w-fit mb-3 ${
                                        isActiveTeam ? 'bg-[#F2F2F2] text-[#838383]' : 'bg-[#F9F6FF] text-[#454ADE]'
                                    } rounded-[50%]`}
                                >
                                    <span>3</span>
                                </div>
                                <h4
                                    className={`mb-2 font-semibold ${
                                        isActiveTeam ? 'text-[#838383]' : ' text-[#202020]'
                                    }`}
                                >
                                    Create Team
                                </h4>

                                <p className='text-[#5E5E5E]'>
                                    Group users into teams to easily manage your company’s finance on every level.
                                </p>

                                <div className='flex justify-end mt-6'>
                                    <button
                                        disabled={isActiveTeam as boolean}
                                        className={`${
                                            isActiveTeam ? 'bg-[#DADCE0] ' : 'bg-[#454ADE]'
                                        } text-white py-2 px-4 rounded`}
                                    >
                                        Create Team
                                    </button>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            )}
        </section>
    )
}

export default GetStarted
