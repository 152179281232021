import { ReactNode, createContext, useState } from 'react'
import { OrgGlCodeType } from '@/types/gl-code'

export type AccountingContextDefaultTypes = {
    orgGlCode?: OrgGlCodeType
    hasGlCode: boolean
    setHasGlCode: React.Dispatch<React.SetStateAction<boolean>>
    setOrgGlCode: React.Dispatch<React.SetStateAction<OrgGlCodeType | undefined>>
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
}

const defaultValues: AccountingContextDefaultTypes = {
    orgGlCode: undefined,
    hasGlCode: false,
    setHasGlCode: () => undefined,
    setOrgGlCode: () => {},
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
}

export const AccountingContext = createContext(defaultValues)

export default function AccountingContextProvider({ children }: { children: ReactNode }) {
    const [hasGlCode, setHasGlCode] = useState(false)
    const [orgGlCode, setOrgGlCode] = useState<OrgGlCodeType>()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)

    const values = {
        hasGlCode,
        setHasGlCode,
        orgGlCode,
        setOrgGlCode,
        pageNumber,
        setPageNumber,
        pageNumberClicked,
        setPageNumberClicked,
    }
    return <AccountingContext.Provider value={values}>{children}</AccountingContext.Provider>
}
