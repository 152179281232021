import { createMutation } from '../api/mutation'
import { keyUser } from '../auth/queries'

export const orgPlans = ['subscription/user-plans']
export const directoryPlans = ['subscription/plans']
export const orgPlan = (id: string) => [id, 'subscription/plan']

export const orgBusinessPlans = ['subscription/business-plan']
export const orgBusinessPlan = (id: string) => [id, 'subscription/business-plan']
export const orgSubscription = (id: string) => [id, 'subscription/subscription']
export const orgusageRecord = (id: string) => [id, 'subscription/usage-record']

export const useCreatePlan = createMutation({
    url: '/subscriptions/plan',
    method: 'POST',
    keysToRefetch: [orgPlans, directoryPlans],
})
export const useUpdatePlan = createMutation({
    url: '/subscriptions/plan',
    method: 'PATCH',
    keysToRefetch: [orgPlans, keyUser, directoryPlans],
})
export const useToggleArchivePlan = createMutation({
    url: '/subscriptions/toggle-archive-plan',
    method: 'POST',
    keysToRefetch: [orgPlans, directoryPlans],
})

export const useCreateBusinessPlan = createMutation({
    url: '/subscriptions/business-plan',
    method: 'POST',
    keysToRefetch: [orgBusinessPlans],
})
export const useUpdateBusinessPlan = createMutation({
    url: '/subscriptions/business-plan',
    method: 'PATCH',
    keysToRefetch: [orgBusinessPlans, keyUser],
})

export const useUpgradePlan = createMutation({
    url: '/subscriptions/upgrade-plan',
    method: 'POST',
    keysToRefetch: [orgBusinessPlans, keyUser],
})

export const useCreateSubscription = createMutation({
    url: '/subscriptions/subscription',
    method: 'POST',
    keysToRefetch: [orgBusinessPlans, keyUser, ['subscription/subscription'], ['subscription/usage-record']],
})
