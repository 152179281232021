import { EmployeesContextProvider } from '@/context/employees'
import Employees from '@/modules/dashboard/Employees/Employees'
import EmployeeDetails from '@/modules/dashboard/Employees/partials/EmployeeDetails/EmployeeDetails'
import { Outlet, Route } from 'react-router-dom'

export const userRoutes = {
    baseUrl: 'employees',
    userSubRoute: ':employee_subRoute',
    teamPage: ':employee_subRoute/:team_id',
    employeeDetails: 'members/:employee_id',
}
export const useUserRoutes = () => {
    const { baseUrl, employeeDetails, teamPage, userSubRoute } = userRoutes
    const routes = [teamPage, userSubRoute]
    return (
        <Route
            element={
                <EmployeesContextProvider>
                    <Outlet />
                </EmployeesContextProvider>
            }
        >
            <Route path={baseUrl}>
                <Route index element={<Employees />} />
                {routes.map(route => (
                    <Route key={route} path={route} element={<Employees />} />
                ))}
                <Route path={employeeDetails} element={<EmployeeDetails />} />
            </Route>
        </Route>
    )
}
