import { TransactionContextProvider } from '@/context/transactions'
import TransactionDetails from '@/modules/dashboard/Transaction/partials/TransactionDetails'
import Transactions from '@/modules/dashboard/Transactions/Transactions'
import { Outlet, Route } from 'react-router-dom'

export const transactionRouteConst = {
    transactionBaseUrl: 'transactions',
    transactionsubRoute: 'transactions/:transactions_subRoute',
    transactionDetails: 'transactions/:transactions_subRoute/:transaction_id',
    personalTransactionRoute: 'transactions/:transactions_subRoute/personal',
    companyTransactionRoute: 'transactions/:transactions_subRoute/company',
    company: 'company', //for admin only
}

export const useTransactionsRoute = () => {
    const { companyTransactionRoute, transactionBaseUrl, transactionsubRoute, personalTransactionRoute } =
        transactionRouteConst
    const routes = [companyTransactionRoute, transactionBaseUrl, transactionsubRoute, personalTransactionRoute]
    return (
        <Route
            element={
                <TransactionContextProvider>
                    <Outlet />
                </TransactionContextProvider>
            }
        >
            {routes.map(route => (
                <Route path={route} key={route} element={<Transactions />} />
            ))}
            <Route path={transactionRouteConst.transactionDetails} element={<TransactionDetails />} />
        </Route>
    )
}
