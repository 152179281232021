import { MissingReceipt } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import { useAccountingContext } from '@/context'
import { QueryOrgTransactions } from '@/services/transactions/types'
import TransactionEmptyStateIcon from '@/assets/v2dashboard/recent-transactions.svg'
import { TransactionType } from '@/types/transactions'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { Check } from 'lucide-react'
import AddMappingRuleModal from '@/components/Modals/Accounting/AddMappingRuleModal'
import { useState } from 'react'

// import { useFieldArray, useForm } from 'react-hook-form'

// type FormType = {
//     accounting: {
//         gl_code: string
//         ready: boolean
//         receipt: string
//     }[]
// }

type Props = {
    orgTransactions: QueryOrgTransactions
}
export default function AccountingTable({ orgTransactions }: Props) {
    const columnHelper = createColumnHelper<TransactionType>()
    const { orgGlCode, pageNumber, setPageNumberClicked, setPageNumber } = useAccountingContext()
    const [addMappingRule, setAddMappingRule] = useState(false)
    const [addFavGlcodeToMappingRule, setAddFavGlcodeToMappingRule] = useState('')

    // const {
    //     handleSubmit,
    //     control,
    //     watch,
    //     formState: { isValid },
    // } = useForm<FormType>({})

    // const { fields } = useFieldArray({
    //     control,
    //     name: 'accounting',
    // })

    const columns: ColumnDef<TransactionType, any>[] = [
        columnHelper.accessor('credit.account.user.first_name', {
            header: () => <p>Destination</p>,
            cell: cell => {
                const owner =
                    cell.row.original.recipient_type === 'vendors' ? undefined : cell.row.original?.credit.account
                const vendorName = cell.row.original?.metadata?.recipient?.name
                const userName =
                    capitalizeText(cell.row.original?.credit?.account.user?.first_name) +
                    ' ' +
                    capitalizeText(cell.row.original?.credit?.account.user?.last_name?.[0])
                return (
                    <span className=' font-medium text-gray7'>
                        {' '}
                        {cell.row.original.onModel !== 'Org'
                            ? userName
                            : owner?.user?.first_name
                              ? `${capitalizeText(owner?.user?.first_name)} ${capitalizeText(
                                    owner?.user?.last_name?.[0]
                                )}`
                              : vendorName
                                ? capitalizeText(vendorName)
                                : capitalizeText(cell?.row.original?.owner?.org_name)}
                    </span>
                )
            },
            enableSorting: false,
        }),

        columnHelper.accessor('createdAt', {
            header: () => <p className='whitespace-nowrap'>Date & time</p>,
            cell: cell => (
                <div className='text-gray7  font-medium'>
                    <p className='mb-1 text-sm'>{format(new Date(cell.renderValue()), 'dd/MM/yyyy')}</p>
                    <p className='mb-1 text-sm'>{format(new Date(cell.renderValue()), 'hh:mm a')}</p>
                </div>
            ),
            enableSorting: true,
        }),

        columnHelper.accessor('debit.amount', {
            header: () => <p>Amount</p>,
            cell: cell => {
                const debitAmt = cell.renderValue()
                return (
                    <span className=' font-medium whitespace-nowrap'>
                        {cell.row.original.ownerTransactionType === 'DEBIT' ? (
                            <span className='text-[#CF491E]'>{formatAmount(debitAmt)}</span>
                        ) : (
                            <span className='text-[#19C37D]'>{formatAmount(cell.row.original.credit.amount)}</span>
                        )}
                    </span>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('debit.account.user.first_name', {
            header: () => <p>Initiated by</p>,
            cell: cell => {
                const owner = cell.row.original.initiated_by

                return (
                    <span className='font-medium text-gray7 '>
                        {owner?.first_name &&
                            `${capitalizeText(owner?.first_name)} ${capitalizeText(owner?.last_name?.[0])}`}
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('type', {
            header: () => <p>Type</p>,
            cell: cell => <span className='text-gray7 font-medium'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'gl_code',
            header: () => <p>GL code</p>,
            cell: () => (
                <Select
                    name='gl_code'
                    onValueChange={data => {
                        setAddMappingRule(true)
                        setAddFavGlcodeToMappingRule(data)
                    }}
                >
                    <SelectTrigger className=' border-none font-medium text-xs h-[2.5rem] w-[10rem] bg-[#F9F9F9] text-[#5E5E5E] '>
                        <SelectValue className='' placeholder={'Select category'} />
                    </SelectTrigger>
                    <SelectContent className='w-[13rem] '>
                        {orgGlCode?.codes?.map(code => (
                            <SelectItem className='text-xs cursor-pointer' value={code?.id}>
                                {code?.code}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            ),
        }),
        columnHelper.display({
            header: () => <p className='text-center'>Ready</p>,
            id: 'ready',
            cell: () => (
                <button className='rounded-sm p-1 bg-green/20 text-green mx-auto flex'>
                    <Check strokeWidth={2} size={20} />
                </button>
            ),
        }),
        columnHelper.display({
            header: () => <p className='text-center'>Receipt</p>,
            id: 'receipt',
            cell: () => (
                <div className='text-xs text-gray7 flex flex-col gap-0.5 items-center'>
                    <span>
                        <MissingReceipt />
                    </span>
                    Missing
                </div>
            ),
        }),
        columnHelper.display({
            id: 'actions',
            cell: () => <TableActions rotate actions={[]} />,
        }),
    ]
    return (
        <div className='rounded-lg'>
            {addMappingRule && (
                <AddMappingRuleModal
                    selectedGlCodeId={addFavGlcodeToMappingRule}
                    closeModal={() => setAddMappingRule(false)}
                />
            )}
            <Table
                data={orgTransactions.results}
                pagination_data={orgTransactions?.pagination}
                columns={columns}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                setIsPageNumberClicked={setPageNumberClicked}
                rowClickable={true}
                emptyStateTitle='No transactions'
                emptyStateImg={TransactionEmptyStateIcon}
            />
        </div>
    )
}
