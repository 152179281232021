import { Budget } from '@/types/budget'
import { Pagination } from '@/types/utils'
import React, { createContext, useState } from 'react'

export type defaultBudgetProps = {
    budgetObj: Budget | undefined
    setBudgetObj: React.Dispatch<React.SetStateAction<Budget | undefined>>
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    pagination_details?: Pagination
    setPaginationDetails: React.Dispatch<React.SetStateAction<Pagination | undefined>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
}

const defaultBudgetsState: defaultBudgetProps = {
    budgetObj: undefined,
    setBudgetObj: () => undefined,
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    pagination_details: undefined,
    setPaginationDetails: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
}

export const BudgetsContext = createContext(defaultBudgetsState)

export const BudgetsContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [budgetObj, setBudgetObj] = useState<Budget>()
    const [pagination_details, setPaginationDetails] = useState<Pagination | undefined>()
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)

    const value = {
        budgetObj,
        setBudgetObj,
        pagination_details,
        setPaginationDetails,
        pageNumber,
        setPageNumber,
        pageNumberClicked,
        setPageNumberClicked,
    }
    return <BudgetsContext.Provider value={value}>{children}</BudgetsContext.Provider>
}
