import { ExpenseContextProvider } from '@/context/expense'
import { SettingsContextProvider } from '@/context/settings'
import ExpenseDetailsPage from '@/modules/dashboard/Expenses/components/ExpenseDetailsPage'
import Expenses from '@/modules/dashboard/Expenses/Expenses'
import { Outlet, Route } from 'react-router-dom'

export const expensesRouteConst = {
    expensesBaseUrl: 'expenses',
    expenseSubRoute: 'expenses/:expense_subRoute',
    expenseId: 'expenses/:expense_subRoute/:expense_id',
    personalExpenseRoute: 'expenses/:expense_subRoute/personal',
    companyExpenseRoute: 'expenses/:expense_subRoute/company',
    company: 'company', //only for admin view
}

export const useExpensesRoute = () => {
    const { companyExpenseRoute, expensesBaseUrl, expenseSubRoute, personalExpenseRoute } = expensesRouteConst
    const routes = [companyExpenseRoute, expensesBaseUrl, expenseSubRoute, personalExpenseRoute]
    return (
        <Route
            element={
                <SettingsContextProvider>
                    <ExpenseContextProvider>
                        <Outlet />
                    </ExpenseContextProvider>
                </SettingsContextProvider>
            }
        >
            {routes.map(route => (
                <Route path={route} key={route} element={<Expenses />} />
            ))}
            <Route path={expensesRouteConst.expenseId} element={<ExpenseDetailsPage />} />
        </Route>
    )
}
