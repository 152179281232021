import React, { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { modalVariants } from '../../Expense/utils'
import { CancelIcon } from '@/assets/assets.export'
import CSVTransfer1 from './CSVTransfer1'
import CSVTransfer2 from './CSVTransfer2'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function CSVTransferRootModal({ closeModal }: Props) {
    const [steps, setSteps] = useState(1)

    return (
        <ModalWrapper variants={modalVariants} className='justify-end right-8'>
            <section className='h-[100vh] overflow-scroll'>
                <div className='flex items-center my-4 border-b border-[#DADCE0] w-full pb-2'>
                    <h2 className='flex-1 text-center font-semibold text-xl'>Make transfer</h2>
                    <CancelIcon
                        className='w-9 h-9 bg-[#F2F2F2] rounded-full p-2 inline-block mr-3 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>

                <div className='mx-3'>
                    {steps === 1 && <CSVTransfer1 closeModal={closeModal} setStep={setSteps} />}
                    {steps === 2 && <CSVTransfer2 setStep={setSteps} />}
                </div>
            </section>
        </ModalWrapper>
    )
}
