import { createMutation } from '../api/mutation'
import { LoginResponse } from '../auth/types'

export const useImitateUser = createMutation<LoginResponse>({
    url: '/auth/imitate-user',
    method: 'POST',
})

export const useRevokeImitation = createMutation<LoginResponse>({
    url: '/auth/revoke-imitation',
    method: 'POST',
})
