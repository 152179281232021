import { ArrowRight } from 'lucide-react'
import StatusColor from '@/components/utils/StatusColor'
// import { Cell, Pie, PieChart, Tooltip } from 'recharts'
// import { CustomPieTooltipNum } from '@/components/Tooltips/CustomTooltip'
import { useAuthContext } from '@/context'
import { useGetExpenseSummary, useQueryOrgPendingExpenses } from '@/services/expenses/queries'
import { useMemo } from 'react'
import { formatAmount } from '@/utils/money'
import { format, getYear } from 'date-fns'
import { QueryExpenseSummaryResponseType } from '@/services/expenses/types'
import { capitalizeText } from '../../../../../utils/string'
import { RecentExpensesIcon, TransactionMetricIcon } from '@/assets/assets.export'
import { useNavigate } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { PieChartNew } from '@/components/utils/PieChartNew'

const expenseSummaryKey = {
    partiallyApproved: 'partiallyApproved',
    partially: 'partially',
    denied: 'denied',
    declined: 'declined',
    totalExpenses: 'totalExpenses',
    totalApprovedAmount: 'totalApprovedAmount',
}
export default function RecentExpenses() {
    const { selectedAccount } = useAuthContext()
    const navigate = useNavigate()
    const { data, isLoading } = useQueryOrgPendingExpenses({
        queryParams: {
            id: `${selectedAccount?.org.id as string}`,
            limit: '6',
        },
    })
    const expenses_overview = useMemo(() => data?.expenses ?? [], [data])

    const { data: expense_summary, isLoading: isLoadingSummary } = useGetExpenseSummary({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            year: getYear(new Date()).toString(),
        },
    })
    const expenseSummary = useMemo(() => expense_summary ?? ({} as QueryExpenseSummaryResponseType), [expense_summary])

    const COLORS = ['#19C37D', '#FF9152', '#DEB145', '#CF491E', '#5C9BFF']
    const data01 = Object.entries(expenseSummary)
        ?.filter(([key]) => key !== expenseSummaryKey.totalExpenses && key !== expenseSummaryKey.totalApprovedAmount)
        ?.map(([key, value], idx: number) => {
            if (key === expenseSummaryKey.partiallyApproved) key = expenseSummaryKey.partially
            if (key === expenseSummaryKey.denied) key = expenseSummaryKey.declined
            return { title: capitalizeText(key) as string, value: Number(value), color: COLORS[idx] }
        })
    const total = data01?.reduce((accumulator, currentValue) => accumulator + (currentValue.value ?? 0), 0)
    const isSkeleton = isLoading || isLoadingSummary

    return (
        <section className='grid xl:grid-cols-2 grid-cols-1 justify-between my-8 gap-9'>
            <section
                className={`rounded-lg  flex flex-col ${isSkeleton ? 'justify-between' : 'justify-center'} bg-white p-4`}
            >
                <h1 className='text-center font-semibold text-base text-gray7'>This month’s metrics</h1>
                {isSkeleton ? (
                    <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                        <Skeleton />
                    </SkeletonTheme>
                ) : (
                    <>
                        <div className={`relative min-h-[408px] mx-auto`}>
                            {data01.length > 0 ? (
                                <>
                                    {/* <PieChart width={400} height={400}>
                                <Pie
                                    data={data01}
                                    dataKey='value'
                                    nameKey='name'
                                    cx='50%'
                                    cy='50%'
                                    outerRadius={180}
                                    fill='#8884d8'
                                    innerRadius={120}
                                    height={300}
                                />
                                {data01.map((_, indexs) => (
                                    <Cell key={`celll-${indexs}`} fill={COLORS[indexs]} />
                                ))}
                                <Tooltip offset={1} content={<CustomPieTooltipNum />} wrapperStyle={{ zIndex: '10' }} />
                            </PieChart> */}
                                    <PieChartNew data={data01} />
                                    {data01.length > 0 && (
                                        <h1 className='absolute top-[210px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-medium'>
                                            Expenses submitted
                                            <br />{' '}
                                            <span className='font-extrabold text-[40px]'>{total.toLocaleString()}</span>
                                        </h1>
                                    )}
                                </>
                            ) : (
                                <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                    <TransactionMetricIcon />
                                    <h2 className='text-gray7 font-medium mt-5'>No expense metrics yet</h2>
                                    <p className='text-sm'>No expense has been submitted yet</p>
                                </div>
                            )}
                        </div>

                        {data01.length > 0 && (
                            <div className='flex w-fit mx-auto gap-6 flex-wrap'>
                                {data01.map((data, i) => (
                                    <div className='' key={i}>
                                        <span
                                            className=' h-1 block mr-2 rounded'
                                            style={{ backgroundColor: `${COLORS[i]}` }}
                                        >
                                            &nbsp;
                                        </span>
                                        <h2 className='text-base font-medium mt-1'>{data.title}</h2>
                                        <p className='font-extrabold text-[32px]'>{data.value}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </section>
            <section className='bg-white rounded-xl p-4 '>
                <div className='flex items-center justify-between rounded text-dark'>
                    <div className=''>
                        <span className=' font-semibold text-base'>Recent Expenses</span>
                        <p className='text-sm text-gray7 mt-1'>See latest expense reports submitted</p>
                    </div>

                    {!isSkeleton && (
                        <button
                            onClick={() => navigate('/dashboard/expenses/expense_list')}
                            className='bg-transparent hover:bg-transparent flex items-center text-sm gap-2'
                        >
                            View all
                            <ArrowRight size={15} />
                        </button>
                    )}
                </div>
                <div className='flex flex-col gap-3 mt-5 h-full' id='recent-expenses'>
                    {isSkeleton ? (
                        <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                            <Skeleton />
                        </SkeletonTheme>
                    ) : (
                        <>
                            {expenses_overview?.length > 0 ? (
                                expenses_overview?.slice(0, 6)?.map(expense => (
                                    <div
                                        onClick={() => navigate(`/dashboard/expenses/expense_list/${expense?.id}`)}
                                        className='border border-gray5 p-4 py-5 rounded-xl flex items-center justify-between'
                                    >
                                        <div className='flex items-center gap-3'>
                                            <div>
                                                <h5 className='text-sm font-semibold'>
                                                    {expense?.submitter?.user?.first_name}{' '}
                                                    {expense?.submitter?.user?.last_name}
                                                </h5>
                                                <p className='text-xs text-gray7'>
                                                    {expense?.reimbursable ? 'Reimbursable' : 'Non-reimbursable'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex gap-3'>
                                            <div className=''>
                                                <p className={`text-sm font-semibold`}>
                                                    {formatAmount(expense.amount)}
                                                </p>
                                                <p className='text-gray7 text-xs text-right'>
                                                    {expense?.createdAt &&
                                                        format(new Date(expense?.createdAt), 'dd/MM/yyyy')}
                                                </p>
                                            </div>
                                            <div className='w-[103px] flex items-center justify-center text-xs'>
                                                <StatusColor status={expense.status} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                    <RecentExpensesIcon />
                                    <h2 className='text-gray7 font-medium mt-5'>No expenses</h2>
                                    <p className='text-sm'>No expense has been submitted yet</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
        </section>
    )
}
