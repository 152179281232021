import { VaultItem } from '@/services/vault/types'
import { FolderContainer } from './FolderContainer'
import { FileEmptyState } from '../FileEmptyState'

type FolderProps = {
    folders: VaultItem[]
}

export const FolderLists = ({ folders }: FolderProps) => {
    return (
        <>
            {folders.length > 0 ? (
                folders?.map(folder => <FolderContainer key={folder?.id} folder={folder} />)
            ) : (
                <FileEmptyState type='folder' />
            )}
        </>
    )
}
