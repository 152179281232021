import React, { useEffect, useMemo, useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { modalVariants } from '../../Expense/utils'
import { CancelIcon } from '@/assets/assets.export'
import TransferPage1 from './TransferPage1'
import { useForm } from 'react-hook-form'
import { formProps } from './utils'
import { useAuthContext } from '@/context'
import { useTransferFunds } from '@/services/payout/mutations'
import { Button } from '@/components'
import TransferPage2 from './TransferPage2'
import ConfirmTransfer from './ConfirmTransfer'
import { useSendTransferOtp } from '@/services/payout/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetOrgVendors } from '@/services/vendors/queries'
import { useGetListOfBanks } from '@/services/payout/queries'
import { calculateTransferFee } from '@/utils/money'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function TransferFundsRoot({ closeModal }: Props) {
    const [steps, setSteps] = useState(1)
    const { selectedAccount } = useAuthContext()
    const [selectedEmployees, setSelectedEmployees] = useState<Record<'label' | 'value' | 'amount', string>[]>([])
    const [showMultipleBtn, setShowMultipleBtn] = useState(false)
    const [resolvedBank, setResolvedBank] = useState({
        account_number: '',
        account_name: '',
    })

    // console.log(selectedEmployees)
    const {
        handleSubmit,
        formState: { isValid },
        control,
        watch,
        getValues,
    } = useForm<formProps>({ defaultValues: { sameAmountCheckBox: 'true', desc: 'Transfer' } })

    const recipient = watch('recipient')

    const employeesNum = watch('employeeNum')

    const { data } = useGetListOfBanks({ enabled: recipient === 'recipient' })
    const allBankList = useMemo(() => data ?? [], [data])

    const { mutate: transferFundsFn, isLoading: isTransferring } = useTransferFunds({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess() {
            toastHandler({ message: 'Processing payment', state: 'success' })
            closeModal(false)
        },
    })

    const { data: orgMembers, isLoading: isOrgMemberLoading } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
        enabled: recipient === 'employees',
    })

    // console.log(orgMembers)

    const { data: orgVendors, isLoading: isOrgVendorsLoading } = useGetOrgVendors({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
        enabled: recipient === 'vendors',
    })

    const { mutate: sendOtpFn } = useSendTransferOtp({})

    const handleTransferFund = (values: formProps) => {
        const rearrangedEmployees = selectedEmployees.map(employee => ({
            account: employee.value,
            amount: values.sameAmountCheckBox ? Number(values.amount) / 100 : Number(employee.amount) / 100,
        }))

        const vendorDetails = orgVendors?.vendors?.find(vendor => vendor?.id === values?.vendor)

        // console.log({ recipient, values })
        transferFundsFn({
            amount: Number(values?.amount) / 100,
            fee: calculateTransferFee(values?.amount),
            recipient_type: recipient === 'recipient' ? 'external' : recipient, // employees, vendors or external
            recipient_accounts:
                employeesNum === 'multiple'
                    ? rearrangedEmployees
                    : [
                          {
                              // if recipient_type is external, account should be left empty
                              account:
                                  recipient === 'employees'
                                      ? values.employee
                                      : recipient === 'vendors'
                                        ? values?.vendor
                                        : undefined,
                              account_number:
                                  recipient === 'recipient'
                                      ? values?.accnt_number
                                      : recipient === 'vendors'
                                        ? vendorDetails?.bankAccount.account_number
                                        : undefined,
                              amount: Number(values.amount) / 100,
                              bank_code:
                                  recipient === 'recipient'
                                      ? values.accnt_name
                                      : recipient === 'vendors'
                                        ? vendorDetails?.bankAccount.bank_code
                                        : undefined,
                          },
                      ],
            memo: values.desc,
            otp: values?.otp,
        })
    }

    useEffect(() => {
        if (steps === 3) {
            sendOtpFn({})
        }
    }, [steps])

    const otp = watch('otp')
    const watchedAMount = watch('amount')

    return (
        <ModalWrapper variants={modalVariants} className='justify-end right-8'>
            <section className='h-[100vh] overflow-scroll'>
                <div className='flex items-center my-4 border-b border-[#DADCE0] w-full pb-2'>
                    <h2 className='flex-1 text-center font-semibold text-xl'>Make transfer</h2>
                    <CancelIcon
                        className='w-9 h-9 bg-[#F2F2F2] rounded-full p-2 inline-block mr-3 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>

                <form className='w-[25rem] mx-4 mt-9 mb-3' onSubmit={handleSubmit(handleTransferFund)}>
                    <div className='h-[75vh] overflow-scroll bg-white'>
                        {steps === 1 && (
                            <TransferPage1
                                watch={watch}
                                control={control}
                                selectedEmployees={selectedEmployees}
                                setSelectedEmployees={setSelectedEmployees}
                                orgMembers={orgMembers}
                                orgVendors={orgVendors}
                                isOrgMemberLoading={isOrgMemberLoading}
                                isOrgVendorsLoading={isOrgVendorsLoading}
                                setResolvedBank={setResolvedBank}
                                allBankList={allBankList}
                                values={() => getValues()}
                            />
                        )}
                        {steps === 2 && (
                            <TransferPage2
                                selectedMembers={selectedEmployees}
                                setSelectedMembers={setSelectedEmployees}
                                control={control}
                                setStep={setSteps}
                                showPage={() => setShowMultipleBtn(false)}
                                values={() => getValues()}
                                watchedAMount={watchedAMount}
                                watch={watch}
                            />
                        )}
                        {steps === 3 && (
                            <ConfirmTransfer
                                values={() => getValues()}
                                selectedMembers={selectedEmployees}
                                control={control}
                                orgMembers={orgMembers}
                                orgVendors={orgVendors}
                                resolvedBank={resolvedBank}
                                allBankList={allBankList}
                            />
                        )}
                    </div>
                    {!showMultipleBtn && steps !== 3 && (
                        <Button
                            className='mt-[2rem] w-full'
                            loading={isTransferring}
                            type={employeesNum === 'multiple' ? 'button' : 'submit'}
                            onClick={() => {
                                if (employeesNum === 'multiple') {
                                    setShowMultipleBtn(true)
                                    setSteps(2)
                                    return
                                }
                                setSteps(3)
                            }}
                            disabled={!isValid || recipient == undefined}
                        >
                            Next
                        </Button>
                    )}
                    {showMultipleBtn && steps !== 3 && (
                        <Button
                            className='mt-[2rem] w-full'
                            loading={isTransferring}
                            type={'submit'}
                            onClick={() => {
                                if (steps === 1) {
                                    setShowMultipleBtn(false)
                                    return
                                }
                                setSteps(3)
                            }}
                            disabled={!isValid}
                        >
                            Next
                        </Button>
                    )}

                    {steps === 3 && (
                        <div className='flex justify-end gap-6 mt-12'>
                            <Button
                                className='bg-transparent min-w-[100px] text-[#202020] hover:bg-transparent'
                                onClick={() => {
                                    if (showMultipleBtn) {
                                        setSteps(2)
                                        return
                                    }
                                    setSteps(1)
                                    setShowMultipleBtn(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='bg-[#19C37D] hover:bg-[#255541] min-w-[100px] px-4'
                                loading={isTransferring}
                                type='submit'
                                disabled={otp?.length ? otp?.length < 4 : true}
                            >
                                Proceed
                            </Button>
                        </div>
                    )}
                </form>
            </section>
        </ModalWrapper>
    )
}
