import { PayrollSingleList } from '@/services/payroll/types'
import { Budget } from '@/types/budget'

export function cumulativeBalanceForUser(budgets: Budget[], userId: string): number {
    if (!budgets) {
        return 0
    }
    return budgets.reduce((cumulativeBalance, budget) => {
        if (budget.manager.some(manager => manager.user?._id === userId)) {
            return cumulativeBalance + Number(budget?.available_amount)
        }
        return cumulativeBalance
    }, 0)
}

export function cumulativePayroll(payrolls: PayrollSingleList[], userId: string): number {
    if (!payrolls) {
        return 0
    }
    return payrolls.reduce((cumulativeBalance, payroll) => {
        if (payroll.account.user?._id === userId) {
            return cumulativeBalance + Number(payroll.current_payout)
        }
        return cumulativeBalance
    }, 0)
}
