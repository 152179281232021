import React, { ChangeEvent, FormEvent, useState } from 'react'
import { CalendarIcon, CancelIcon } from '@/assets/assets.export'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { capitalizeText } from '@/utils/string'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useBillPaymentContext } from '@/context'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { isAfter } from 'date-fns'
import ModalWrapper from '../ModalWrapper'
import { filter_by_Props, filter_consts, FilterBillUtils, modalVariants } from './utils'
import { advancedFilteringProps } from '@/context/bill-payment'
import { EmployeeType } from '@/types/org'
import { Vendor } from '@/types/vendor'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    vendors: Vendor[]
    orgMembers: EmployeeType[]
}

export default function FilterBill({ closeModal, vendors: orgVendors, orgMembers }: Props) {
    const {
        setFilterBillRequest: setFilterFormState,
        filterBillRequest: filterFormState,
        setPageNumber,
    } = useBillPaymentContext()
    const [errInAmount, setErrInAmount] = useState<string | null>(null)
    const [errInDate, setErrInDate] = useState<string | null>(null)

    const checkIfMaxIsGreaterThanMin = (e: ChangeEvent<HTMLInputElement>, type: string) => {
        const { value } = e.target

        const formattedValue = new Intl.NumberFormat('en-US').format(Number(value.replaceAll(',', '')))

        const min = filterFormState?.amount?.min.replaceAll(',', '')
        const max = filterFormState?.amount?.max.replaceAll(',', '')
        const enteredValue = Number(formattedValue.replaceAll(',', ''))

        const noEnteredAmount = !min || !max
        const isMaxType = type === filter_consts.max
        const isMinType = type === filter_consts.min
        const isMaxLessThanMin = isMaxType && enteredValue < Number(min)
        const isMinGreaterThanMax = isMinType && enteredValue > Number(max)

        let errorMessage = null

        if (noEnteredAmount) {
            errorMessage = `Please enter a ${isMaxType ? 'max' : 'min'} amount`
        } else if (isMaxLessThanMin || isMinGreaterThanMax) {
            errorMessage = 'Max amount should be greater than min'
        }

        setErrInAmount(errorMessage)

        setFilterFormState(prev => ({
            ...prev,
            amount: { ...prev.amount, [type]: formattedValue },
        }))
    }

    const checkIfDateIsPastToday = (date: Date, type: string) => {
        const result = isAfter(new Date(date), new Date())
        if (result) {
            setErrInDate('Date cannot be past today')
            return
        }
        setFilterFormState(prev => ({
            ...prev,
            period: { ...prev.period, [type]: date },
        }))
        setErrInDate('')
    }

    const checkAndParseAmount = (data: string) => {
        const amt = FilterBillUtils.amount.find(amt => amt.type === data)
        setFilterFormState(prev => ({
            ...prev,
            amount: {
                ...prev.amount,
                min: amt?.method()?.min as unknown as string,
                max: amt?.method()?.max as unknown as string,
            },
        }))
    }
    const handleFilterForm = (event: FormEvent<HTMLFormElement | HTMLSelectElement | HTMLInputElement>) => {
        event.preventDefault()
        setPageNumber(1)
        setFilterFormState(prev => ({ ...prev, filter_now: true }))
        closeModal(false)
    }

    const handleClearFilter = () => {
        setPageNumber(1)
        setFilterFormState(() => ({ ...advancedFilteringProps, filter_now: true }))
        closeModal(false)
    }
    return (
        <ModalWrapper
            className='justify-end right-8 rounded-none z-50'
            variants={modalVariants}
            modalClassName='rounded-none'
        >
            <main className='h-[100vh] overflow-scroll w-[25rem] relative'>
                <div className='flex items-center  border-b border-[#DADCE0] py-2 pb-3 fixed bg-[white] w-full z-50 pt-10 lg:pt-2'>
                    <h1 className='flex-1 text-xl font-medium text-center text-[#31254B]'>Advanced filter</h1>
                    <CancelIcon
                        className='bg-[#F2F2F2] rounded-full p-2 w-[2rem] h-[2rem] mr-4 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>
                <form className='mx-6 mt-24 lg:mt-14' onSubmit={handleFilterForm}>
                    <div className='py-2 pb-5'>
                        <span className='text-lg font-medium mb-4 inline-block'>Status</span>
                        <RadioGroup
                            onValueChange={status => {
                                setFilterFormState(prev => ({ ...prev, status }))
                            }}
                            defaultValue={filterFormState.status}
                            className='flex gap-6 items-center flex-wrap'
                        >
                            {FilterBillUtils.status_types.map(types => (
                                <label
                                    htmlFor={types}
                                    className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm cursor-pointer'
                                    key={types}
                                >
                                    <RadioGroupItem value={types} id={types} />
                                    {capitalizeText(types)}
                                </label>
                            ))}
                        </RadioGroup>
                    </div>
                    <div className='border-b border-[#DADCE0] py-2 pb-5'>
                        <span className='text-lg font-medium mb-4 inline-block'>Type</span>
                        <RadioGroup
                            onValueChange={bill_type => {
                                setFilterFormState(prev => ({ ...prev, bill_type }))
                            }}
                            defaultValue={filterFormState.status}
                            className='flex gap-6 items-center'
                        >
                            {FilterBillUtils.bill_types.map(types => (
                                <label
                                    htmlFor={types}
                                    className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm cursor-pointer'
                                    key={types}
                                >
                                    <RadioGroupItem value={types} id={types} />
                                    {capitalizeText(types)} bill
                                </label>
                            ))}
                        </RadioGroup>
                    </div>
                    <div className='mt-4'>
                        <span className='text-lg font-medium mb-4 inline-block'>Period</span>
                        <div className='mb-5'>
                            <div className='flex gap-5 '>
                                <label className='relative cursor-pointer flex items-center ' htmlFor='from_date '>
                                    <DatePicker
                                        selected={filterFormState?.period?.from}
                                        onChange={date => checkIfDateIsPastToday(date!, 'from')}
                                        placeholderText='From'
                                        className='border-[#DADCE0]  placeholder:text-[#A8A8A8] h-[3rem] text-dark rounded-md border focus:border-brand bg-transparent py-2 px-3 text-sm  cursor-pointer w-[8rem] '
                                    />
                                    <CalendarIcon className='absolute right-3 -z-20' />
                                </label>
                                <hr className='w-[60px] relative top-[1.5rem] text-[#A8A8A8] bg-[#A8A8A8]' />
                                <label className='relative cursor-pointer flex items-center ' htmlFor='from_date '>
                                    <DatePicker
                                        selected={filterFormState?.period?.to}
                                        onChange={date => checkIfDateIsPastToday(date!, 'to')}
                                        placeholderText='To'
                                        className='border-[#DADCE0]  placeholder:text-[#A8A8A8] h-[3rem] text-dark rounded-md border focus:border-brand bg-transparent py-2 px-3 text-sm  cursor-pointer w-[8rem]'
                                    />
                                    <CalendarIcon className='absolute right-3 -z-20' />
                                </label>
                            </div>
                            {!!errInDate && <span className='text-[red] text-xs mt-1 block'> {errInDate}</span>}
                        </div>
                        <RadioGroup
                            onValueChange={period =>
                                setFilterFormState(prev => ({
                                    ...prev,
                                    period: { ...prev.period, time_in_words: period },
                                }))
                            }
                            value={filterFormState?.period?.time_in_words}
                        >
                            {FilterBillUtils.periods.map(types => (
                                <label
                                    htmlFor={types.type}
                                    key={types.type}
                                    className={`flex gap-5 items-center text-[#5E5E5E] font-medium text-sm ${
                                        !!filterFormState.period?.from || !!filterFormState.period?.to
                                            ? 'cursor-not-allowed'
                                            : 'cursor-pointer'
                                    }`}
                                >
                                    <RadioGroupItem
                                        value={types.getDate()}
                                        id={types.type}
                                        disabled={!!filterFormState.period?.from || !!filterFormState.period?.to}
                                    />
                                    {capitalizeText(types.period)}
                                </label>
                            ))}
                        </RadioGroup>
                    </div>
                    <div className=''>
                        <span className='text-lg font-medium mb-2 inline-block mt-5'>Due time</span>
                        <RadioGroup
                            onValueChange={due_time => setFilterFormState(prev => ({ ...prev, due_time }))}
                            value={filterFormState?.due_time}
                        >
                            {FilterBillUtils.due_time.map(types => (
                                <label
                                    htmlFor={`${types.type}_due`}
                                    key={`${types.type}_due`}
                                    className={`flex gap-5 items-center text-[#5E5E5E] font-medium text-sm ${
                                        !!filterFormState.period?.from || !!filterFormState.period?.to
                                            ? 'cursor-not-allowed'
                                            : 'cursor-pointer'
                                    }`}
                                >
                                    <RadioGroupItem
                                        value={types.getDate()}
                                        id={`${types.type}_due`}
                                        disabled={!!filterFormState.period?.from || !!filterFormState.period?.to}
                                    />
                                    {capitalizeText(types.period)}
                                </label>
                            ))}
                        </RadioGroup>
                    </div>
                    <div>
                        {FilterBillUtils.filter_by.map((filterables: filter_by_Props) => (
                            <>
                                {Object.keys(filterables).map(filter_header => {
                                    const filter_with = filterFormState[
                                        filterables[filter_header]?.type as keyof typeof filterFormState
                                    ] as string

                                    return (
                                        <>
                                            <span></span>
                                            <span className='text-lg font-medium mb-2 inline-block mt-7'>
                                                {filter_header}
                                            </span>

                                            <Select
                                                name={filterables[filter_header].type}
                                                onValueChange={filter_by =>
                                                    setFilterFormState(prev => ({
                                                        ...prev,
                                                        [filterables[filter_header].type]: filter_by,
                                                    }))
                                                }
                                                value={filter_with?.length == 0 ? filter_consts.initial : filter_with}
                                            >
                                                <SelectTrigger className=' h-[3.5rem] border-[#DADCE0] text-sm'>
                                                    <SelectValue
                                                        placeholder={
                                                            filterables[filter_header].type === filter_consts.vendor
                                                                ? 'Select vendor'
                                                                : 'Select approver'
                                                        }
                                                        className='text-sm'
                                                    />
                                                </SelectTrigger>
                                                <SelectContent className='max-h-[20rem]'>
                                                    {filterables[filter_header].type === filter_consts.vendor && (
                                                        <SelectItem value={filter_consts.initial}>
                                                            Select vendor
                                                        </SelectItem>
                                                    )}
                                                    {filterables[filter_header].type === filter_consts.approver && (
                                                        <SelectItem value={filter_consts.initial}>
                                                            Select approver
                                                        </SelectItem>
                                                    )}
                                                    {filterables[filter_header].type === filter_consts.vendor &&
                                                        orgVendors.map(vendor => (
                                                            <SelectItem key={vendor.id} value={vendor.id}>
                                                                {vendor.name}
                                                            </SelectItem>
                                                        ))}
                                                    {filterables[filter_header].type === filter_consts.approver &&
                                                        orgMembers?.map(member => (
                                                            <SelectItem key={member?.id} value={member?.id}>
                                                                {capitalizeText(member?.user?.first_name)}{' '}
                                                                {capitalizeText(member?.user?.last_name)}
                                                            </SelectItem>
                                                        ))}
                                                </SelectContent>
                                            </Select>
                                        </>
                                    )
                                })}
                            </>
                        ))}
                    </div>
                    <div className='mt-8'>
                        <span className='text-lg font-medium mb-4 inline-block'>Amount</span>
                        <div className='mb-5'>
                            <div className='flex gap-5 '>
                                <input
                                    type='text'
                                    placeholder='min'
                                    className=' border-[#DADCE0]  placeholder:text-[#A8A8A8] h-[3rem] text-dark rounded-md border focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm w-full'
                                    onChange={e => checkIfMaxIsGreaterThanMin(e, filter_consts.min)}
                                    value={filterFormState?.amount?.min}
                                />
                                <hr className='w-[200px] relative top-[1.5rem] text-[#A8A8A8] bg-[#A8A8A8]' />
                                <input
                                    type='text'
                                    placeholder='max'
                                    className=' border-[#DADCE0]  placeholder:text-[#A8A8A8] h-[3rem] text-dark rounded-md border focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm w-full'
                                    onChange={e => checkIfMaxIsGreaterThanMin(e, filter_consts.max)}
                                    value={filterFormState?.amount?.max}
                                />
                            </div>
                            {!!errInAmount && <span className='text-[red] text-xs'> {errInAmount}</span>}
                        </div>
                        <RadioGroup
                            onValueChange={checkAndParseAmount}
                            defaultValue={filterFormState?.amount?.amount_in_words}
                        >
                            {FilterBillUtils.amount.map(types => (
                                <label
                                    htmlFor={types.type}
                                    className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm mt-2 cursor-pointer'
                                >
                                    <RadioGroupItem value={types.type} id={types.type} />
                                    {capitalizeText(types.amount)}
                                </label>
                            ))}
                        </RadioGroup>
                    </div>
                    <div className='flex gap-3 justify-end mt-10 mb-5'>
                        <Button
                            className='bg-transparent text-[#5E5E5E] hover:bg-transparent'
                            type='button'
                            onClick={handleClearFilter}
                        >
                            Clear filters
                        </Button>
                        <Button className='min-w-[130px]'>Apply filters</Button>
                    </div>
                </form>
            </main>
        </ModalWrapper>
    )
}
