import { ProfileEditIcon } from '@/assets/assets.export'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import ToggleSwitch from '@/components/Switch'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { capitalizeText } from '@/utils/string'

import { useEffect, useMemo, useState } from 'react'
import WalletAccessCard from './WalletAccessCard'
import { useMutationGrantOwnerAccess } from '@/services/settings/mutations'
import { useGetOrgSettings } from '@/services/settings/queries'
import { prodEnvironment } from '@/utils/utils'

export default function CompanySecuritySettings() {
    const [changeWalletAcces, setChangeWalletAccess] = useState(false)
    const { selectedAccount } = useAuthContext()
    const [selectedMembers, setSelectedMembers] = useState<string[]>([])
    const [selectedMember, setSelectedMember] = useState('')

    const {
        data: orgMembers,
        isLoading: isOrgMemberLoading,
        isRefetching,
    } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const { data: wallet_access_data, isLoading: isLoadingAllowedUsers } = useGetOrgSettings({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })
    const walletAccessUsers = useMemo(() => wallet_access_data ?? [], [wallet_access_data])
    const { mutate: addUsersToWalletFn, isLoading: isAddingUsers } = useMutationGrantOwnerAccess({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            setting_id: walletAccessUsers?.[0]?.id,
        },
        onSuccess: () => {
            setChangeWalletAccess(false)
            toastHandler({ message: 'Access updated', state: 'success' })
        },
    })

    useEffect(() => {
        setSelectedMembers(() => walletAccessUsers?.[0]?.wallet_access?.map(priviledgeUser => priviledgeUser?.id))
    }, [walletAccessUsers])
    return (
        <>
            {!prodEnvironment() && (
                <section className='ml-0 lg:ml-6 mx-6 mt-5 border rounded-xl p-4 border-[#DADCE0] pt-4 hidden'>
                    <div className='flex justify-between mb-3'>
                        <span className='font-medium text-xl'>2-Factor Authentication settings</span>
                        <ToggleSwitch checked={false} />
                    </div>
                    <div className='flex justify-between text-[#5E5E5E]'>
                        <p className='text-sm w-[65%]'>
                            2-FA adds significantly more security to your account by requiring not only your email and
                            password when signing in, but also a verification code sent to your mobile phone.
                        </p>
                        <span className='font-medium text-sm'>Enable 2-FA</span>
                    </div>
                </section>
            )}
            {selectedAccount?.role?.name === 'OWNER' && (
                <>
                    <h3 className='font-medium ml-0 lg:ml-6 mx-6 mt-5 text-xl'> Permissions </h3>
                    <section
                        className={`ml-0 lg:ml-6 mx-6 mt-5 border rounded-xl p-3 border-[#DADCE0] pt-4 ${
                            isRefetching || isLoadingAllowedUsers ? 'opacity-50' : 'opacity-100'
                        }`}
                    >
                        <div className='flex justify-between lg:items-center flex-col lg:flex-row gap-4'>
                            <div>
                                <span className='font-medium'>Wallet access</span>
                                <p className='text-sm w-[80%] lg:w-[100%] text-[#5E5E5E] mt-2'>
                                    Choose who can have access to your company’s wallet
                                </p>
                                <div className='flex gap-2 flex-wrap'>
                                    {walletAccessUsers?.length > 0 ? (
                                        walletAccessUsers?.[0]?.wallet_access?.map(user => (
                                            <span className='bg-[#DEB145] rounded-lg px-3 py-[.4rem] text-white text-sm inline-block mt-2'>
                                                {capitalizeText(user?.user?.first_name)}{' '}
                                                {capitalizeText(user?.user?.last_name)}
                                            </span>
                                        ))
                                    ) : (
                                        <span className='bg-[#DEB145] rounded-lg px-3 py-[.4rem] text-white text-sm inline-block mt-2'>
                                            N/A
                                        </span>
                                    )}
                                </div>
                            </div>
                            {!changeWalletAcces && (
                                <span
                                    className='flex items-center gap-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-2 border px-4 cursor-pointer w-fit'
                                    onClick={() => setChangeWalletAccess(true)}
                                >
                                    <ProfileEditIcon />
                                    <button type='button'>Edit</button>
                                </span>
                            )}
                        </div>
                        <div className='mt-4'>
                            {changeWalletAcces && (
                                <>
                                    <p className='text-[14px] font-normal'>
                                        Add more admins (
                                        <span className='text-[#5E5E5E]'>you can’t select more than 5 Admins</span>)
                                    </p>
                                    <Select
                                        name='org_members'
                                        value=''
                                        onValueChange={data => {
                                            if (selectedMembers.length === 5) {
                                                toastHandler({ message: 'cannot exceed 5 members ', state: 'error' })
                                                return
                                            }
                                            setSelectedMember(() => {
                                                const user = orgMembers?.filter(member => member.id === data)[0].user
                                                return `${capitalizeText(user?.first_name)} ${capitalizeText(
                                                    user?.last_name
                                                )}`
                                            })
                                            setSelectedMembers(prev => [...prev, data])
                                        }}
                                    >
                                        <SelectTrigger
                                            className={`mt-4 max-w-[22rem] h-[3rem] bg-[#F9F9F9] border-[#DADCE0] bg-base_gray_100 text-[13px]  text-[#202020] ${
                                                selectedMember ? 'opacity-100' : 'opacity-75'
                                            }`}
                                        >
                                            <SelectValue placeholder='Share access with' className='text-[red]' />
                                            {selectedMember ? (
                                                <span className='flex-1 text-left'>{selectedMember}</span>
                                            ) : (
                                                <span className='flex-1 text-left text-[#A8A8A8]'>
                                                    Share access with
                                                </span>
                                            )}
                                        </SelectTrigger>
                                        <SelectContent className='w-[22rem] max-h-[17rem] overflow-scroll'>
                                            {isOrgMemberLoading ? (
                                                <SelectItem value='wait'>Please Wait</SelectItem>
                                            ) : (
                                                orgMembers
                                                    ?.filter(orgMember => !selectedMembers?.includes(orgMember?.id))
                                                    ?.map(member => (
                                                        <SelectItem value={member.id}>
                                                            {capitalizeText(member?.user?.first_name)}{' '}
                                                            {capitalizeText(member?.user?.last_name)}
                                                        </SelectItem>
                                                    ))
                                            )}
                                        </SelectContent>
                                    </Select>
                                    <div
                                        className={`flex gap-3 mt-4 flex-wrap ${
                                            changeWalletAcces ? 'justify-between' : ''
                                        }  max-w-[58rem]`}
                                    >
                                        {orgMembers
                                            ?.filter(orgMember => selectedMembers?.includes(orgMember?.id))
                                            ?.map(member => (
                                                <WalletAccessCard
                                                    orgMember={member}
                                                    setSelectedMembers={setSelectedMembers}
                                                    editWalletAccess={changeWalletAcces}
                                                    setting_id={walletAccessUsers?.[0]?.id}
                                                />
                                            ))}
                                    </div>
                                </>
                            )}
                        </div>
                        {changeWalletAcces && (
                            <Button
                                className='ml-auto mt-6 min-w-[120px]'
                                onClick={() => {
                                    addUsersToWalletFn({
                                        users: selectedMembers,
                                    })
                                }}
                                loading={isAddingUsers}
                                disabled={selectedMembers?.length < 1}
                            >
                                Save changes
                            </Button>
                        )}
                    </section>
                </>
            )}
        </>
    )
}
