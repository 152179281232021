import { Instagram, MessageCircleIcon, TwitterIcon } from 'lucide-react'
import { ReactNode } from 'react'
import { MdCall } from 'react-icons/md'
import { Link } from 'react-router-dom'

export default function HelpAndSupport() {
    return (
        <section>
            <div>
                <h2 className='font-semibold text-lg'>Help and support</h2>
                <p className='text-gray7 text-sm mt-2'>Reach out to our support team. Customer support is up 24/7.</p>
            </div>
            <div className='mt-10'>
                <div className='flex flex-wrap gap-6'>
                    <AllYouNeedBox
                        description='Get all the support you need. Chat with our customer support live and resolve issues'
                        call_to_action={
                            <div className='flex items-center gap-3'>
                                <Link
                                    to={'https://www.instagram.com/bizpend'}
                                    target='_blank'
                                    className='text-brand text-xs flex items-start font-semibold flex-col justify-start'
                                >
                                    <Instagram width={20} strokeWidth={2} />
                                    @bizpendtech
                                </Link>
                                <Link
                                    to={'https://x.com/usebizpend'}
                                    target='_blank'
                                    className='text-brand text-xs flex items-start font-semibold flex-col justify-start'
                                >
                                    <TwitterIcon width={20} strokeWidth={2} />
                                    @usebizpend
                                </Link>
                            </div>
                        }
                        icon={<MessageCircleIcon className='text-gray7 w-7 h-7' />}
                        title='Live chat and support '
                    />
                    <AllYouNeedBox
                        call_to_action={
                            <button className='text-brand text-xs flex items-center gap-2 font-semibold'>
                                09060078379
                            </button>
                        }
                        icon={<MdCall className='text-gray7 w-7 h-7' />}
                        title='Talk to us'
                        description='Speak with our support team any time, any day. 24/7 access to speak with our support representative.'
                    />
                </div>
            </div>
        </section>
    )
}

type Props = {
    icon: ReactNode
    title: string
    description?: string
    call_to_action: ReactNode
}

const AllYouNeedBox = ({ description, icon, title, call_to_action }: Props) => {
    return (
        <div
            className={`bg-white rounded-xl p-5 border-2 border-[#dddddd] max-w-[22rem] w-full shadow-sm flex flex-col justify-between `}
        >
            <div>
                {icon}
                <h3 className='text-base font-medium mt-3'>{title}</h3>
            </div>
            <p className='text-gray7 text-xs my-3'>{description}</p>
            {call_to_action}
        </div>
    )
}
