export enum BankAccountConnectionEnum {
    LINK = 'link',
    CREATE = 'create',
    MANUAL = 'manual',
}

export type DedicatedBankAccountType = {
    account: string
    accountType: string
    account_name: string
    account_number: string
    balance: number
    bank_account_type: string
    bank_code: string
    bank_name: string
    createdAt: string
    created_by: string
    currency: 'NGN'
    frozen: boolean
    id: string
    _id: string
    is_primary: boolean
    nickname: string
    nip_code: string
    provider: 'anchor' | 'user'
    provider_id: string
    setup: 'complete' | 'incomplete'
    status: 'active' | 'inactive'
    updatedAt: string
    transactionsCount: number
    connection_type: BankAccountConnectionEnum
}

export enum ACCOUNT_PAYABLE_FROM {
    DepositAccount = 'DepositAccount',
    Wallet = 'Wallet',
}
