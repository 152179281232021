import VendorDetails from './partials/VendorDetails'
// import LinkedExpenses from './partials/LinkedExpenses'
import LinkedTransactions from './partials/LinkedTransactions'

export default function Vendor() {
    return (
        <main>
            <VendorDetails />
            {/* <LinkedExpenses /> */}

            <LinkedTransactions />
        </main>
    )
}
