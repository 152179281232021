import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { PercentIcon } from 'lucide-react'
import { TbCurrencyNaira } from 'react-icons/tb'
import DeleteBtn from '@/assets/delete-btn.svg'
import { PlusSquare } from 'lucide-react'
import { defaultAdditionProps } from '@/types/payroll'
import { v4 as uuidv4 } from 'uuid'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { useUpdatePayrollSettings } from '@/services/payroll/mutations'
import { useState } from 'react'
import { useAuthContext } from '@/context'
import { toastHandler } from '@/components/utils/Toast'

type EditProps = {
    setEditPayrollCustoms: React.Dispatch<React.SetStateAction<boolean>>
    id: string
    defaultBonuses: defaultAdditionProps[]
    defaultDeductions: defaultAdditionProps[]
}

const defaultAdditions = {
    reason: '',
    amount: 0,
    type: '',
    id: uuidv4(),
    amountType: '',
}

const PayrollFormCustoms = ({ setEditPayrollCustoms, id, defaultBonuses, defaultDeductions }: EditProps) => {
    const [deductions, setDeductions] = useState<Partial<defaultAdditionProps>[]>(defaultDeductions)
    const [bonuses, setBonuses] = useState<Partial<defaultAdditionProps>[]>(defaultBonuses)
    const { selectedAccount } = useAuthContext()

    const { mutate: updatePayrollSettingsFn, isLoading } = useUpdatePayrollSettings({
        queryParams: {
            setting_id: id as string,
        },
        onSuccess() {
            setEditPayrollCustoms(false)
            toastHandler({ message: 'Changes saved', state: 'success' })
        },
    })

    const handleClick = () => {
        const default_deductions = deductions.map(data => ({
            name: data?.name,
            type: data.type,
            amount: data.type === 'amount' ? Number(data?.amount) / 100 : data.amount,
            org: selectedAccount?.org.id,
        }))

        const default_bonuses = bonuses.map(data => ({
            name: data?.name,
            type: data.type,
            amount: data.type === 'amount' ? Number(data?.amount) / 100 : data.amount,
            org: selectedAccount?.org.id,
        }))

        updatePayrollSettingsFn({ default_deductions, default_bonuses })
    }
    return (
        <div className='p-6 rounded-lg border border-[#DADCE0] mb-5'>
            <div className='flex justify-between items-center mb-6'>
                <div className=''>
                    <h2 className='text-lg font-medium'>Payroll form</h2>
                    <p className='text-sm text-[#5E5E5E] mt-1'>Add bonus and deduction types to the payroll form</p>
                </div>
            </div>
            <div className='border-b border-gray8 pb-4'>
                <h2 className='text-lg font-medium mb-4'>Bonuses</h2>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-5 lg:gap-y-12 gap-x-20 items-center'>
                    {bonuses?.map(bonus => (
                        <PayrolFormReusable fieldDetail={bonus} setFieldDetails={setBonuses} key={bonus.id} />
                    ))}
                </div>

                <button
                    className='flex items-center gap-2 text-sm text-[#454ADE] bg-[#D5D5F5] rounded-md p-2 mt-4'
                    onClick={() =>
                        setBonuses([
                            ...bonuses,
                            {
                                ...defaultAdditions,
                                id: uuidv4(),
                                type: 'amount',
                                field_type: 'bonus',
                            },
                        ])
                    }
                >
                    <PlusSquare size={15} color='#454ADE' />
                    New bonus type
                </button>
            </div>

            <div className='mt-8'>
                <h2 className='text-lg font-medium mb-4'>Deductions</h2>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-5 lg:gap-y-12 gap-x-20 items-center'>
                    {deductions?.map(deduction => (
                        <PayrolFormReusable
                            fieldDetail={deduction}
                            setFieldDetails={setDeductions}
                            key={deduction.id}
                        />
                    ))}
                </div>

                <button
                    className='flex items-center gap-2 text-sm text-[#454ADE] bg-[#D5D5F5] rounded-md p-2 mt-4'
                    onClick={() =>
                        setDeductions([
                            ...deductions,
                            {
                                ...defaultAdditions,
                                id: uuidv4(),
                                type: 'amount',
                                field_type: 'deduction',
                            },
                        ])
                    }
                >
                    <PlusSquare size={15} color='#454ADE' />
                    New deduction type
                </button>
            </div>

            <div className='flex gap-3 justify-end mt-7 lg:mt-0'>
                <Button
                    className='bg-transparent hover:bg-transparent hover:border hover:border-[#838383] text-[#202020]'
                    onClick={() => setEditPayrollCustoms(false)}
                    disabled={isLoading}
                >
                    cancel
                </Button>
                <Button loading={isLoading} onClick={handleClick}>
                    Save changes
                </Button>
            </div>
        </div>
    )
}

export default PayrollFormCustoms

export const PayrolFormReusable = ({
    fieldDetail,
    setFieldDetails,
}: {
    fieldDetail: Partial<defaultAdditionProps>
    setFieldDetails: React.Dispatch<React.SetStateAction<Partial<defaultAdditionProps>[]>>
}) => {
    return (
        <div className='flex items-end gap-2 lg:gap-4'>
            <div className='w-1/2 lg:w-auto'>
                <div>
                    {' '}
                    <h2 className='text-black text-sm font-medium mb-1'>Name</h2>
                    <input
                        type='text'
                        className='outline-none w-[10rem] lg:w-[15rem] ml-1 placeholder:text-sm h-[3rem] border border-[#DADCE0] rounded-lg px-3 placeholder:truncate'
                        required
                        placeholder='E.g employment bonus, sales bonus etc.'
                        value={fieldDetail?.name}
                        onChange={e => {
                            setFieldDetails(field => {
                                return field.map(data => {
                                    if (data.id === fieldDetail?.id) {
                                        data.name = e.target.value
                                        return data
                                    }
                                    return data
                                })
                            })
                        }}
                    />
                </div>
            </div>
            <div>
                <p className='text-sm font-medium mb-1'>Default value</p>

                <div className='flex border border-[#DADCE0] w-full h-[3rem] rounded-lg overflow-hidden bg-white'>
                    <Select
                        name='type'
                        defaultValue='amount'
                        value={fieldDetail?.type}
                        onValueChange={e => {
                            setFieldDetails(field => {
                                return field.map(data => {
                                    if (data.id === fieldDetail?.id) {
                                        data.type = e as any
                                        data.amount = 0
                                        return data
                                    }
                                    return data
                                })
                            })
                        }}
                    >
                        <SelectTrigger className='bg-[#F9F9F9] text-[10px] border-none w-[3rem] px-2 py-2 justify-between h-max flex self-center mx-1 pl-1 placeholder:text-sm '>
                            <SelectValue placeholder={''} />
                        </SelectTrigger>
                        <SelectContent className='w-[8rem]'>
                            <SelectItem value='amount'>
                                {' '}
                                <span className='flex items-center justify-center text-[#838383] ml-2'>
                                    <TbCurrencyNaira fontSize={20} />
                                </span>
                            </SelectItem>
                            <SelectItem value='percentage'>
                                {' '}
                                <span className='flex items-center justify-center text-[#838383] ml-2'>
                                    <PercentIcon fontSize={20} />
                                </span>
                            </SelectItem>
                        </SelectContent>
                    </Select>

                    <input
                        type='text'
                        className='outline-none w-[90%] h-full ml-1 placeholder:text-sm '
                        required
                        value={
                            fieldDetail.type === 'amount'
                                ? formatAmount(fieldDetail.amount).replaceAll('₦', '')
                                : formatAmount((fieldDetail?.amount as number) * 100).replaceAll('₦', '')
                        }
                        onChange={e => {
                            let value_entered = formatAmountWithoutSymbols(e.target.value)
                            setFieldDetails(field => {
                                return field.map(data => {
                                    if (data.id === fieldDetail?.id) {
                                        value_entered =
                                            fieldDetail.type === 'amount'
                                                ? value_entered
                                                : `${Number(value_entered) / 100}`
                                        data.amount = Number(value_entered)
                                        return data
                                    }
                                    return data
                                })
                            })
                        }}
                    />
                </div>
            </div>
            <div
                className='relative cursor-pointer mr-1'
                onClick={() => {
                    setFieldDetails(field => {
                        return field.filter(data => data.id !== fieldDetail?.id)
                    })
                }}
            >
                <img src={DeleteBtn} alt='Icon' className='scale-110 w-14 lg:w-12 h-[36px] lg:h-[46px]' />
            </div>
        </div>
    )
}
