import { useAuthContext } from '@/context'
import { ModuleNameEnum } from '@/types/billing'

type Props = {
    module:
        | ModuleNameEnum.BUDGETS
        | ModuleNameEnum.CONTRACTS
        | ModuleNameEnum.CUSTOMERS
        | ModuleNameEnum.EXPENSES
        | ModuleNameEnum.INVOICE
        | ModuleNameEnum.OCR_SCAN
        | ModuleNameEnum.PAYROLL
        | ModuleNameEnum.PEOPLE
        | ModuleNameEnum.VENDORS
        | ModuleNameEnum.VAULT
}

export default function useAccessToModule({ module }: Props) {
    const { selectedAccount } = useAuthContext()
    const orgUsageRecord = selectedAccount?.org?.usage_record

    if (Number(orgUsageRecord?.modules?.[module]?.available) > 0 || orgUsageRecord?.modules?.[module]?.is_unlimited)
        return { enabled: true }
    return { enabled: false }
}
