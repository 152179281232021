import { Button } from '@/components'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { useGetOrgMemberById, useGetUserApprovalPolicies } from '@/services/employees/queries'
import { EmployeeType, RoleEnum, UserOrgAccount } from '@/types/org'
import { format } from 'date-fns'
import { ArrowLeft } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LinkedExpenses from './LinkedExpense'
import { ProfileImage } from '@/assets/assets.export'
import { capitalizeText } from '@/utils/string'
import EditEmployeeModal from '@/components/Modals/Employee/EditEmployeeModal/EditEmployeeModal'

import DeactivateEmployee from '@/components/Modals/Employee/DeactivateEmployee/DeactivateEmployee'
import { IsOwner } from '@/utils/permissions'
import { teamBadge } from '../../teamBadgeColors'

export default function EmployeeDetails() {
    const [employeeDetail, setEmployeeDetails] = useState<EmployeeType>()
    const [openModal, setOpenModal] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const navigate = useNavigate()
    const { employee_id } = useParams()

    const { selectedAccount } = useAuthContext()

    const { data: employee, isLoading } = useGetOrgMemberById({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            account: employee_id as string,
        },
    })

    const orgMember = useMemo(
        () => (employeeDetail?.status ? [{ ...employee?.[0], status: employeeDetail?.status }] : employee ?? []),
        [employee, employeeDetail]
    )

    const { data } = useGetUserApprovalPolicies({
        enabled: orgMember?.[0]?.role?.name !== RoleEnum.USER && orgMember?.[0]?.role?.name !== RoleEnum.EMPLOYEE,
        queryParams: {
            account_id: employee_id as string,
        },
    })

    const userApprovalPolicies = useMemo(() => data ?? [], [data])

    const userStatus = orgMember[0]?.status

    const org_member = [
        { name: 'Name', desc: `${orgMember[0]?.user?.first_name} ${orgMember[0]?.user?.last_name}` },
        { name: 'Role', desc: capitalizeText(orgMember[0]?.role?.name ?? 'N/A') },
        { name: 'Email address', desc: orgMember[0]?.email ?? 'N/A' },
        {
            name: 'Team(s)',
            desc: (
                <>
                    {orgMember[0]?.teams && orgMember[0]?.teams?.length > 0
                        ? orgMember[0]?.teams?.map(team => (
                              <div className='w-max m-1 rounded-md overflow-hidden text-[.8rem]'>
                                  <span
                                      style={{
                                          color: teamBadge[team.name[0].toLowerCase() as keyof typeof teamBadge],
                                          // backgroundColor: `${
                                          //     teamBadge[team.name[0].toLowerCase() as keyof typeof teamBadge]
                                          // }25`,
                                          display: 'inline-block',
                                          padding: '2px 5px',
                                      }}
                                  >
                                      <span className={`block text`}>{team.name}</span>
                                  </span>
                              </div>
                          ))
                        : 'N/A'}
                </>
            ),
        },
        { name: 'Phone number', desc: orgMember[0]?.user?.phone ?? 'N/A' },
        {
            name: 'Join date',
            desc: orgMember[0]?.createdAt && format(new Date(orgMember[0]?.createdAt), 'dd/MM/yyyy'),
        },
        {
            name: 'Status',
            desc: (
                <span
                    className={`${
                        userStatus === 'ACTIVE'
                            ? 'text-[#19C37D]'
                            : userStatus === 'DEACTIVATED'
                              ? 'text-[#cf1e33]'
                              : 'text-[#838383]'
                    }`}
                >
                    {capitalizeText(userStatus === 'SUSPENDED' ? 'Inactive' : userStatus) ?? 'N/A'}
                </span>
            ),
        },

        {
            name: 'KYC status',
            desc: (
                <span className={`${orgMember[0]?.user?.is_kyc_complete ? 'text-[#19C37D]' : 'text-[#cf1e33]'}`}>
                    {capitalizeText(orgMember[0]?.user?.is_kyc_complete ? 'Completed' : 'Incomplete')}
                </span>
            ),
        },
        { name: 'Bank Status', desc: orgMember[0]?.bank ? 'Linked' : 'N/A' },
    ]

    if (isLoading) return <ProgressBar />
    return (
        <section className='mx-[1.4rem] lg:mx-[2.9rem] pb-3 pt-2'>
            <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                <ArrowLeft />
                back
            </div>
            <section className=' bg-white rounded-xl px-7 py-6 '>
                <div className='w-[8rem] border rounded-lg p-2 border-gray3'>
                    <img
                        src={orgMember[0]?.user?.profile_photo ?? ProfileImage}
                        alt='profile'
                        className=' w-full  opacity-80'
                    />
                </div>
                <div className='flex flex-wrap max-w-[60rem] gap-x-[2.5rem] mt-8 gap-y-[1.3rem] '>
                    {org_member.map((member, idx) => {
                        const { desc, name } = member
                        return (
                            <div className='flex flex-col w-[12rem]' key={idx}>
                                <h4 className='text-md font-medium'>{name}</h4>
                                <div className='text-[#5E5E5E] text-base break-words'>{desc}</div>
                            </div>
                        )
                    })}
                </div>

                <div className='flex flex-col lg:flex-row justify-between gap-4 mt-7 lg:items-center'>
                    {userStatus !== 'DEACTIVATED' && (
                        <>
                            <div className='flex justify-between '>
                                <Button
                                    className='text-[#5E5E5E] bg-transparent border rounded-md p-0 hover:bg-transparent w-full lg:min-w-[130px] min-h-[35px] '
                                    onClick={() => setEditModal(true)}
                                >
                                    Edit
                                </Button>
                            </div>
                            {!IsOwner(orgMember?.[0] as unknown as UserOrgAccount) && (
                                <div className='flex flex-col lg:flex-row justify-center gap-4'>
                                    {
                                        <Button
                                            className='bg-transparent whitespace-nowrap hover:bg-transparent text-[#CF491E] min-w-[100px] min-h-[20px] border border-[#CF491E]/50 focus:ring-[#CF491E]/50'
                                            onClick={() => {
                                                setOpenModal(true)
                                            }}
                                        >
                                            {' Deactivate Account'}
                                        </Button>
                                    }
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
            <section className='mt-4'>
                <LinkedExpenses employee_id={employee_id as string} />
            </section>

            {openModal && (
                <DeactivateEmployee
                    employeeDetails={orgMember?.[0] as EmployeeType}
                    employeeName={`${orgMember[0]?.user?.first_name} ${orgMember[0]?.user?.last_name}`}
                    setIsDeleteDialogOpen={setOpenModal}
                    setEmployeeDetails={setEmployeeDetails}
                    userApprovalPolicies={userApprovalPolicies}
                />
            )}
            {editModal && (
                <EditEmployeeModal closeModal={setEditModal} selectedEmployee={orgMember as EmployeeType[]} />
            )}
        </section>
    )
}
