import { Tabs, TabsContent, TabsList, TabTriggersContainer, TabsTrigger } from '@/components/Tabs/Tabs'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import LeaveManagement from './LeaveManagement'
import LeaveSettings from '../LeaveSettings/LeaveSettings'
import PageContent from '@/layout/PageContent'

const route_const = {
    request: 'requests',
    settings: 'settings',
}

const LeaveModule = () => {
    const { leave_subRoute } = useParams()
    const navigate = useNavigate()

    return (
        <section className=' h-full'>
            <Tabs defaultValue={leave_subRoute} value={leave_subRoute}>
                <TabsList className='border-none'>
                    <TabTriggersContainer className='gap-3 pb-1 border-none mt-5'>
                        <TabsTrigger
                            value={route_const.request}
                            onClick={() => navigate(`/dashboard/payroll/leave/${route_const.request}`)}
                            className={`relative border-none p-2 px-4 ${
                                route_const.request === leave_subRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.request === leave_subRoute && (
                                <motion.div
                                    layoutId='selected_btn'
                                    className='bg-tabBg rounded !text-white absolute inset-0 w-full p-2 h-full text-xs'
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Requests
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={route_const.settings}
                            onClick={() => navigate(`/dashboard/payroll/leave/${route_const.settings}`)}
                            className={`relative border-none p-2 px-4 ${
                                route_const.settings === leave_subRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.settings === leave_subRoute && (
                                <motion.div
                                    layoutId='selected_btn'
                                    className='bg-tabBg rounded !text-white absolute inset-0 w-full p-1 h-full text-xs'
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Settings
                            </motion.span>
                        </TabsTrigger>
                    </TabTriggersContainer>
                </TabsList>
                <TabsContent value={route_const.request}>
                    <PageContent>
                        <LeaveManagement />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.settings}>
                    <PageContent>
                        <LeaveSettings />
                    </PageContent>
                </TabsContent>
            </Tabs>
        </section>
    )
}

export default LeaveModule
