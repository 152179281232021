import { ProfileEditIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import { useAuthContext } from '@/context'
import { IsOrgCreator, IsOwner } from '@/utils/permissions'
import { capitalizeText } from '@/utils/string'
import { useState } from 'react'
import CompanyProfileEdit from './partials/CompanyProfileEdit'

export default function CompanyProfileSettings() {
    const { selectedAccount } = useAuthContext()
    const orgDetails = selectedAccount?.org
    const [editProfileDetails, setEditProfileDetails] = useState(false)

    const profiles = [
        { name: 'Name', desc: capitalizeText(orgDetails?.org_name), type: 'name' },
        { name: 'Email address', desc: orgDetails?.email, type: 'email' },
    ]
    return (
        <main className='mt-7 mr-5'>
            {!editProfileDetails && (
                <section className='border border-[#DADCE0] rounded-xl p-4'>
                    <div className='flex justify-between'>
                        <h3 className='font-medium text-md'>Company profile</h3>
                        {IsOwner() && (
                            <span
                                className='flex items-center gap-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-2 border px-4 cursor-pointer'
                                onClick={() => setEditProfileDetails(true)}
                            >
                                <ProfileEditIcon />
                                <button type='button'>Edit</button>
                            </span>
                        )}
                    </div>

                    <div className=' mt-3'>
                        <div className='flex flex-wrap gap-x-[4.5rem] gap-5'>
                            {profiles.map(profile => (
                                <div className='flex flex-col min-w-[10rem]'>
                                    <span className='text-[#838383] font-normal text-sm'>{profile.name}</span>
                                    <span className='font-medium mt-2 text-[.9rem]'>{profile.desc}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}

            {editProfileDetails && <CompanyProfileEdit setCloseEditProfile={setEditProfileDetails} />}
            {IsOrgCreator() && (
                <Button className='bg-transparent hover:bg-transparent text-[#CF491E] mx-auto mt-20 mb-10'>
                    Deactivate account
                </Button>
            )}
        </main>
    )
}
