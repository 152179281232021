import { Edit } from 'lucide-react'
import { useMemo, useState } from 'react'
// import { useBudgetContext } from '@/context'
import { CalendarIcon, CrownLogo, GreaterThan } from '@/assets/assets.export'
import { useNavigate, useParams } from 'react-router-dom'
import { formatAmount } from '@/utils/money'
import { ComputePercent } from '../utils'
import { useGetOrgBudgetById } from '@/services/budget/queries'
import ProgressBar from '@/components/ProgressBar'
import CreateBudgetModal from '@/components/Modals/Budget/CreateBudgetRoot'
import TransactionsLinkedToBudget from './TransactionsLinkedToBudget'
import { capitalizeText } from '@/utils/string'
import { format, parseISO } from 'date-fns'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { useAuthContext } from '@/context'

const BudgetDetails = () => {
    const [createModal, setCreateModal] = useState(false)
    const { selectedAccount } = useAuthContext()
    const navigate = useNavigate()
    const { budget_id } = useParams()

    const { data: budGetByIdData, isLoading } = useGetOrgBudgetById({
        queryParams: {
            budget_id: budget_id as string,
        },
    })
    // const resetDate = budg
    const getNextDate = (startDate: string, option: string) => {
        const startDateParsed = parseISO(startDate) // Parse the start date string into a Date object

        const nextDate = new Date(startDateParsed)

        if (option === 'weekly') {
            nextDate.setDate(startDateParsed.getDate() + 7)
        } else if (option === 'monthly') {
            nextDate.setMonth(startDateParsed.getMonth() + 1)
        }

        return nextDate
    }

    const budgetObj = useMemo(() => budGetByIdData, [budGetByIdData])
    const isBudgetManager = budgetObj?.manager.some(manager => manager?._id === selectedAccount?.id)

    if (isLoading) return <ProgressBar />

    const { bgColor, color, percentage } = ComputePercent(budgetObj!)
    return (
        <>
            <section className='bg-white pb-[1rem] px-2 lg:px-0'>
                {/* <div className='w-full py-6 block'> */}
                <section className='font-medium py-5 pl-[.5rem] lg:pl-[2.9rem] mx-auto text-xl flex gap-1 border-b border-[#DADCE0] mb-6'>
                    <span onClick={() => navigate('/dashboard/budget')} className='cursor-pointer'>
                        Budgets
                    </span>
                    <span className='text-[#838383] flex gap-1'>
                        {' '}
                        <GreaterThan />
                        {budgetObj?.name}
                    </span>
                </section>
                <div className='lg:w-[93%] mx-auto'>
                    <div className='border border-[#DADCE0] rounded-2xl px-4 lg:px-6 pt-6 pb-[20px]'>
                        <div className='flex justify-between items-center gap-3'>
                            <div className='flex items-center gap-2 relative'>
                                <h1 className='text-xl font-medium'>{budgetObj?.name}</h1>
                                {isBudgetManager && (
                                    <TooltipProvider delayDuration={10}>
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <Edit
                                                    className='cursor-pointer w-4 h-4'
                                                    color='#838383'
                                                    onClick={() => {
                                                        setCreateModal(true)
                                                    }}
                                                />
                                            </TooltipTrigger>
                                            <TooltipContent className=''>
                                                <p className='bg-[#EFEFF9] p-2 text-[#202020] text-xs rounded'>
                                                    Edit budget
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                )}
                            </div>
                            <div className='flex items-center gap-4'>
                                <span
                                    className='px-3 py-1 rounded-full text-base'
                                    style={{ color: color, backgroundColor: bgColor }}
                                >
                                    {percentage.toFixed(2)}%
                                </span>
                            </div>
                        </div>
                        <div className='mt-[30px] mb-8 bg-[#EFEFF9] rounded h-4'>
                            <div
                                className={`w-0 h-4 rounded`}
                                style={{ width: `${percentage > 100 ? 100 : percentage}%`, backgroundColor: color }}
                            ></div>
                        </div>
                        <div className='flex items-center justify-between flex-wrap'>
                            <div className='flex items-center gap-5 flex-wrap'>
                                <div className=''>
                                    <h2 className='text-[#5E5E5E] mb-2'>Allocated</h2>
                                    <p className='font-medium text-xl'>{formatAmount(budgetObj?.allocated_amount)}</p>
                                </div>
                                <div className=''>
                                    <h2 className='text-[#5E5E5E] mb-2'>Spent</h2>
                                    <p className='text-xl'>
                                        {' '}
                                        {formatAmount(
                                            Number(budgetObj?.allocated_amount) - Number(budgetObj?.available_amount)
                                        )}
                                    </p>
                                </div>
                                <div className=''>
                                    <h2 className='text-[#5E5E5E] mb-2'>Available</h2>
                                    <p className='text-xl'>{budgetObj && formatAmount(budgetObj?.available_amount)}</p>
                                </div>

                                <div className=''>
                                    <h2 className='text-[#5E5E5E] mb-2'>Budget Users</h2>
                                    <p className='text-xl'>{budgetObj?.team?.name ?? 'All'}</p>
                                </div>
                            </div>
                            <div className='flex  gap-8 mt-5 lg:mt-0'>
                                {
                                    <div className=''>
                                        <TooltipProvider delayDuration={1}>
                                            <Tooltip>
                                                <TooltipTrigger className='mr-4'>
                                                    <h2 className='text-[#5E5E5E] mb-2 flex items-center'>
                                                        <CalendarIcon className='mr-2' />
                                                        {budgetObj?.reset === 'one-off' ? 'Expires' : 'Next Renewal'}
                                                    </h2>
                                                </TooltipTrigger>

                                                <TooltipContent className=''>
                                                    <p className='bg-[#FFF9EB] p-2 text-[#202020] text-sm rounded'>
                                                        {budgetObj?.reset === 'one-off'
                                                            ? 'Indicates when the budget is set to expire'
                                                            : '  Indicates when the budget is set to renew'}
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                        <p className='font-bold text-lg'>
                                            {budgetObj?.end_date && format(new Date(budgetObj?.end_date), 'dd/MM/yyyy')}
                                            {budgetObj?.createdAt && budgetObj?.reset !== 'one-off'
                                                ? format(
                                                      getNextDate(
                                                          budgetObj ? budgetObj?.createdAt : '',
                                                          budgetObj ? budgetObj?.reset : ''
                                                      ),
                                                      'dd/MM/yyyy'
                                                  )
                                                : ''}
                                        </p>
                                    </div>
                                }
                                <div className=''>
                                    <TooltipProvider delayDuration={10}>
                                        <Tooltip>
                                            <TooltipTrigger className='mr-4'>
                                                <h2 className='text-[#5E5E5E] mb-2 flex items-center'>
                                                    <CrownLogo className='mr-2 scale-[1.2]' />
                                                    Manager
                                                </h2>
                                            </TooltipTrigger>

                                            <TooltipContent className=''>
                                                <p className='bg-[#FFF9EB] p-2 text-[#202020] text-sm rounded'>
                                                    The budget manager(s) has exclusive budget authority.
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>

                                    <p className='font-bold text-lg'>
                                        {budgetObj?.manager?.map?.(manager => (
                                            <span className='block'>
                                                {capitalizeText(manager?.user?.first_name)}{' '}
                                                {capitalizeText(manager?.user?.last_name)}
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-2 bg-white'>
                        <TransactionsLinkedToBudget />
                    </div>

                    {/* </div> */}
                </div>
            </section>

            {createModal && (
                <CreateBudgetModal updateBudget={true} budgetProps={budgetObj} closeModal={setCreateModal} />
            )}
        </>
    )
}

export default BudgetDetails
