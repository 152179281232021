import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { contractSidebarPermissions } from '@/layout/LayoutComponents/utils'
import { ContractPayrollType } from '@/types/payroll'
import { ReactNode } from 'react'

type Props = {
    contract: ContractPayrollType
    children: ReactNode
}
export default function CheckToEnableEditContractButton({ contract, children }: Props) {
    const hasPermission = useHasAccessPermisson(contractSidebarPermissions)
    if (hasPermission && contract?.status === 'active') return <>{children}</>
    return <></>
}
