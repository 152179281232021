import { useEffect, useMemo, useState } from 'react'
// import downArrow from '@/assets/down-arrow.svg'
import PlaceHolderImg from '@/assets/relianceHMO.svg'
import PageContent from '@/layout/PageContent'
import { Button } from '@/components'
import { X } from 'lucide-react'

import BeneficiaryRemovalPrompt from '@/components/Modals/Payroll/Benefits/BeneficiaryRemovalPrompt'
import { useGetOrgBenefitsById } from '@/services/benefits/queries'
import ProgressBar from '@/components/ProgressBar'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'
import { EmployeeType, UserOrgAccount } from '@/types/org'
import { OrgBenefitProps } from '@/types/benefits'
import { capitalizeText } from '@/utils/string'
import AddUsersToBenefit from '@/components/Modals/Payroll/Benefits/AddUsersToBenefit'
import { formatAmount } from '@/utils/money'
import { addMonths, addYears, format } from 'date-fns'
import SuspendBenefitPlanPrompt from '@/components/Modals/Payroll/Benefits/SuspendBenefitPlanPrompt'
// import StatusColor from '@/components/utils/StatusColor'

export default function BenefitDetails() {
    const { benefit_id } = useParams()
    const navigate = useNavigate()
    const [openSuspendPlanprompt, setOpenSuspendPlanPrompt] = useState(false)
    const { data: benefit_details, isLoading } = useGetOrgBenefitsById({
        queryParams: {
            benefit_id: benefit_id as string,
        },
    })

    const benefitDetails = useMemo(() => benefit_details ?? ({} as OrgBenefitProps), [benefit_details])

    const effectiveDate = format(isLoading ? new Date() : new Date(benefitDetails?.plan?.createdAt), 'dd/MM/yyyy')
    const nextDate = isLoading
        ? benefitDetails?.plan?.frequency === 'monthly'
            ? addMonths(new Date(benefitDetails?.plan?.createdAt || new Date()), 1)
            : addYears(new Date(benefitDetails?.plan?.createdAt || new Date()), 1)
        : new Date()

    const acct_manager_details = benefitDetails?.manager as EmployeeType
    const details = [
        {
            name: 'HMO vendor',
            desc: 'N/A',
        },
        {
            name: 'Plan',
            desc: benefitDetails?.plan?.name,
        },
        {
            name: 'Coverage type',
            desc: 'Single',
        },
        {
            name: 'Benefit cost',
            desc: `${formatAmount(benefitDetails?.plan?.amount)} / ${benefitDetails?.plan?.frequency}`,
        },
        {
            name: 'Cost sharing',
            desc: `${benefitDetails?.cost_sharing?.company}% company, ${benefitDetails?.cost_sharing?.employee}% users (salary deduction)`,
            id: 'cost_sharing',
        },
        {
            name: 'Effective date',
            desc: effectiveDate,
            // desc : ''
        },
        {
            name: 'Next payment',
            desc: format(nextDate, 'dd/MM/yyyy'),
            // desc: ''
        },
        {
            name: 'Status',
            desc: (
                <span className={`${benefitDetails?.status === 'cancelled' ? 'text-errorRed' : 'text-[#19C37D]'}`}>
                    {capitalizeText(benefitDetails?.status === 'cancelled' ? 'Inactive' : 'Active')}
                </span>
            ),
            // desc : <StatusColor status={benefitDetails?.status} />
        },
        {
            name: 'Account manager ',
            desc: `${acct_manager_details?.user?.first_name} ${acct_manager_details?.user?.last_name}`,
        },
        {
            name: 'Account  manager contact ',
            desc: `${acct_manager_details?.user?.phone}, ${acct_manager_details?.user?.email}`,
            id: 'manager_contact',
        },
    ]

    const [beneficiaries, setBeneficiaries] = useState<UserOrgAccount[]>()
    const [openAddBeneficiary, setOpenAddBeneficiary] = useState(false)

    useEffect(() => {
        setBeneficiaries(() => benefitDetails?.beneficiaries)
    }, [benefitDetails])

    const handleBeneficiaryRemoval = (id: string) => {
        setBeneficiaries(prev => prev?.filter(user => user?.id !== id))
    }

    if (isLoading) return <ProgressBar />
    return (
        <PageContent>
            <div className='flex gap-4 items-center mt-8' onClick={() => navigate(-1)}>
                <ArrowLeft role='button' className='' /> go back
            </div>
            <section className='bg-white p-5 rounded-xl mt-7 px-8'>
                <div className='border-b border-[#DADCE0] pb-3 flex flex-col lg:flex-row gap-5 lg:items-center justify-between mt-3'>
                    <div className=''>
                        <img src={PlaceHolderImg} alt='Benefit Company logo' />
                    </div>
                    {/* <div className='flex gap-2 cursor-pointer items-center'>
                        <div className='h-[28px] w-[28px] border-2 border-[#DADCE0] rounded-[5px] flex items-center justify-center p-2 '>
                            <img src={downArrow} alt='button' className='scale-[2]' />
                        </div>
                        <p className='font-semibold text-base'>Download all details on this page</p>
                    </div> */}
                </div>
                <section className='flex flex-wrap gap-4 gap-y-6 max-w-[50rem] mt-5'>
                    {details.map((detail, idx) => (
                        <div
                            className={`flex flex-col  ${
                                detail?.id === 'cost_sharing'
                                    ? 'w-[30rem]'
                                    : detail?.id === 'manager_contact'
                                      ? 'w-[15rem]'
                                      : 'w-[10rem]'
                            } `}
                            key={idx}
                        >
                            <h4 className='text-md font-medium'>{detail.name}</h4>
                            <span className='text-[#5E5E5E] text-[1rem]'>{detail.desc}</span>
                        </div>
                    ))}
                </section>
                <div className='flex lg:justify-end gap-5 mt-5 lg:mt-0'>
                    <Button variant={'outline'} size={'lg'} onClick={() => setOpenAddBeneficiary(true)}>
                        {' '}
                        Add user
                    </Button>
                    {benefitDetails?.status !== 'cancelled' && (
                        <Button variant={'secondary'} size={'lg'} onClick={() => setOpenSuspendPlanPrompt(true)}>
                            Suspend plan
                        </Button>
                    )}
                </div>
            </section>
            <section className='bg-white p-5 rounded-xl mt-10 px-8'>
                <h3 className='border-b pb-3 mb-5 border-[#DADCE0] text-xl font-medium'>Beneficiaries</h3>
                <div className='my-10 flex gap-4 flex-wrap'>
                    {beneficiaries?.map(beneficiary => (
                        <Chip
                            beneficiary={beneficiary}
                            handleBeneficiaryRemoval={handleBeneficiaryRemoval}
                            benefit_id={benefit_id as string}
                        />
                    ))}
                </div>
                <h3 className='border-b pb-3 mb-5 border-[#DADCE0] text-xl font-medium mt-16'>Coverage</h3>
                <ul className='grid grid-cols-2 list-disc list-inside gap-y-4 mt-6 max-w-[60rem]'>
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>{' '}
                    <li>Combined medical cover of ₦1.8 Million per year</li>
                </ul>
                {/* <div className='col-span-2 flex justify-end gap-4 mt-8'>
                    <div className='h-[28px] w-[28px] border-2 border-[#DADCE0] rounded-[5px] flex items-center justify-center p-2 '>
                        <img src={downArrow} alt='button' className='scale-[2]' />
                    </div>
                    <p className='font-medium text-base'>Download all details on this page</p>
                </div> */}
            </section>
            {/* <section>
                <LinkedTransactionsTable />
            </section> */}
            {openSuspendPlanprompt && (
                <SuspendBenefitPlanPrompt benefit={benefitDetails!} closeModal={setOpenSuspendPlanPrompt} />
            )}
            {openAddBeneficiary && <AddUsersToBenefit closeModal={setOpenAddBeneficiary} benefit={benefitDetails} />}
        </PageContent>
    )
}

const Chip = ({
    beneficiary,
    handleBeneficiaryRemoval,
    benefit_id,
}: {
    beneficiary: UserOrgAccount
    handleBeneficiaryRemoval: (id: string) => void
    benefit_id: string
}) => {
    const [showPromptModal, setShowPromptModal] = useState(false)
    return (
        <div
            className='center relative inline-block select-none whitespace-nowrap rounded-md  bg-[#F2F2F2]  py-2 px-3.5 align-baseline text-xs leading-none text-black font-medium'
            data-dismissible='chip'
        >
            <div className='mr-5 mt-px'>
                {capitalizeText(beneficiary?.user?.first_name)} {capitalizeText(beneficiary?.user?.last_name)}
            </div>
            <div
                className='absolute top-1 right-1 mx-px mt-[0.5px] w-max rounded-md  transition-colors '
                data-dismissible-target='chip'
            >
                <X
                    role='button'
                    className='h-5 w-5 p-1 hover:bg-[#CF491E] transition-all duration-75 hover:stroke-[#fff]'
                    strokeWidth={4}
                    onClick={() => setShowPromptModal(true)}
                />
            </div>
            {showPromptModal && (
                <BeneficiaryRemovalPrompt
                    beneficiary={beneficiary}
                    handleBeneficiaryRemoval={handleBeneficiaryRemoval}
                    closeModal={setShowPromptModal}
                    benefit_id={benefit_id}
                />
            )}
        </div>
    )
}
