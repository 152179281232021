import { CancelIcon } from '@/assets/assets.export'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import ModalWrapper from '@/components/Modals/ModalWrapper'
import { capitalizeText } from '@/utils/string'
import { Check, Plus } from 'lucide-react'
import { useState } from 'react'

import { useCreatePlan, useUpdatePlan } from '@/services/billing/mutations'
import { Billing, ModuleNameEnum, ModuleType } from '@/types/billing'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { toastHandler } from '@/components/utils/Toast'

type FormFeatureType = {
    name: string
    amount: string
    unlimited: boolean
}

type FormType = {
    plan_name: string
    plan_amount: number
    plan_description: string
    metadata: string
    feature: FormFeatureType[]
}

type Props = {
    closeModal: () => void
    edit?: boolean
    planDetails?: Billing
}

const CreateBillingPlan = ({ closeModal, edit, planDetails }: Props) => {
    const [features, setFeatures] = useState([
        { name: 'Invoice', id: ModuleNameEnum.INVOICE },
        { name: 'Expenses', id: ModuleNameEnum.EXPENSES },
        { name: 'Budgets', id: ModuleNameEnum.BUDGETS },
        { name: 'Customers', id: ModuleNameEnum.CUSTOMERS },
        { name: 'People', id: ModuleNameEnum.PEOPLE },
        { name: 'Payroll', id: ModuleNameEnum.PAYROLL },
        { name: 'Contracts', id: ModuleNameEnum.CONTRACTS },
        { name: 'Vendors', id: ModuleNameEnum.VENDORS },
        { name: 'OCR scan', id: ModuleNameEnum.OCR_SCAN },
        { name: 'Vaults', id: ModuleNameEnum.VAULT },
        { name: 'Leave', id: ModuleNameEnum.LEAVE },
    ])
    const [newFeatureName, setNewFeatureName] = useState<string | null>(null)
    console.log(planDetails)
    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
    } = useForm<FormType>({
        defaultValues: {
            plan_amount: Number(planDetails?.price ?? 0),
            plan_name: planDetails?.name,
            plan_description: planDetails?.description,
            feature:
                planDetails?.modules &&
                Object.keys(planDetails?.modules)?.map(key => ({
                    name: key,
                    amount: planDetails?.modules?.[key as ModuleType]?.allocated?.toString() ?? '',
                    unlimited: planDetails?.modules?.[key as ModuleType]?.is_unlimited,
                })),
            metadata: planDetails?.metadata.join('\n'),
        },
    })

    const watchFeature = watch('feature')

    const {
        fields: featureFields,
        remove: removeFeature,
        append: appendFeature,
    } = useFieldArray({
        control,
        name: 'feature',
    })

    const { mutate: createPlanFn, isLoading } = useCreatePlan({
        onSuccess: () => {
            closeModal()
            toastHandler({ message: 'Plan creation successful' })
        },
    })
    const { mutate: updatePlanFn, isLoading: isUpdatingPlan } = useUpdatePlan({
        queryParams: {
            id: planDetails?.id as string,
        },
        onSuccess: () => {
            closeModal()
            toastHandler({ message: 'Plan updated successfully' })
        },
    })

    const onSubmit = (values: FormType) => {
        const data_to_server = {
            name: values?.plan_name,
            description: values?.plan_description,
            price: Number(values?.plan_amount ?? 0) / 100, //converts back to naira
            modules: values.feature.reduce((acc, obj) => {
                const newEntry =
                    obj.amount !== undefined
                        ? { allocated: Number(obj.amount), is_unlimited: obj.unlimited }
                        : { is_unlimited: obj.unlimited }

                return { ...acc, [obj.name]: newEntry }
            }, {}),

            metadata: values.metadata.split('\n'),
        }

        if (edit) {
            updatePlanFn(data_to_server)
            return
        }

        createPlanFn(data_to_server)
    }
    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[42rem] h-[80vh] lg:h-auto  max-h-[90vh]  overflow-scroll relative'>
                <form action='' onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex justify-between items-center border-b border-[#DADCE0] py-4'>
                        <h2 className='text-lg font-medium flex-1 text-center'>{edit ? 'Edit' : 'Create new'} plan</h2>

                        <button
                            className='bg-[#F2F2F2] rounded-full p-1 w-6 h-6 appearance-none inline-flex justify-center items-center mr-3'
                            aria-label='Close'
                            onClick={() => {
                                closeModal()
                            }}
                        >
                            <CancelIcon />
                        </button>
                    </div>
                    <form onSubmit={e => e.preventDefault()} id='createBillingForm'>
                        <div className='sm:grid grid-cols-1  sm:grid-cols-2 gap-6 gap-y-1 mx-2 sm:mx-6 mt-6'>
                            <fieldset className='mb-[15px] flex flex-col flex-1  gap-1'>
                                <label className='text-sm' htmlFor='plan_name'>
                                    Plan name
                                </label>
                                <Controller
                                    name='plan_name'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            placeholder='Enter plan name'
                                            className='border-[#DADCE0] h-[2.5rem] placeholder:text-xs'
                                        />
                                    )}
                                />
                            </fieldset>
                            <fieldset className='mb-[15px] flex flex-col flex-1  gap-1'>
                                <label className='text-sm' htmlFor='plan_name'>
                                    Plan amount
                                </label>
                                <Controller
                                    name='plan_amount'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            min={0}
                                            value={formatAmount(field.value).replaceAll('₦', '')}
                                            onChange={e => {
                                                const value_entered = formatAmountWithoutSymbols(e.target.value)
                                                field.onChange(value_entered)
                                            }}
                                            placeholder='Enter plan amount'
                                            className='border-[#DADCE0] h-[2.5rem] placeholder:text-xs'
                                        />
                                    )}
                                />
                            </fieldset>
                            <fieldset className='mb-[15px] flex flex-col flex-1  gap-1 col-span-2'>
                                <label className='text-sm' htmlFor='plan_name'>
                                    Plan description
                                </label>
                                <Controller
                                    name='plan_description'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Textarea
                                            {...field}
                                            placeholder='Enter plan description'
                                            rows={1}
                                            className='border-[#DADCE0] placeholder:text-xs resize-none min-h-[70px]'
                                        />
                                    )}
                                />
                            </fieldset>
                            <p className='block font-semibold text-lg text-[#31254B] col-span-2'>Features</p>
                            <div className='max-h-[150px] overflow-y-scroll col-span-2 p-3'>
                                {featureFields?.map((field, idx) => (
                                    <div
                                        key={field.id}
                                        className='col-span-2 grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-6 bg-gray4/10 p-3 rounded-md relative'
                                    >
                                        <button
                                            aria-label='Icon'
                                            className='absolute -top-2 -right-2'
                                            onClick={() => removeFeature(idx)}
                                        >
                                            <CancelIcon className='w-5 h-5 stroke-red-500 rounded-full p-1  bg-red-50' />
                                        </button>
                                        <fieldset>
                                            <label className='text-xs block font-medium mb-1' htmlFor='plan_name'>
                                                Feature type
                                            </label>
                                            <Controller
                                                name={`feature.${idx}.name`}
                                                rules={{
                                                    required: true,
                                                    minLength: 2,
                                                }}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                        <SelectTrigger className='h-[2.5rem] inline-flex justify-between border-[#DADCE0]  border rounded-md p-2 text-xs  py-4'>
                                                            {!field.value ? (
                                                                <span>Select feature</span>
                                                            ) : (
                                                                <SelectValue placeholder='Select feature' />
                                                            )}
                                                        </SelectTrigger>

                                                        <SelectContent
                                                            position='popper'
                                                            className='shadow-md bg-white rounded-md w-max px-3 text-sm text-[#202020]'
                                                        >
                                                            {features.map(feature => (
                                                                <SelectItem
                                                                    value={feature.id}
                                                                    key={feature.id}
                                                                    className='cursor-pointer flex item-center gap-2'
                                                                >
                                                                    {capitalizeText(feature.name)}
                                                                </SelectItem>
                                                            ))}

                                                            {!newFeatureName ? (
                                                                <button
                                                                    value='new_feature'
                                                                    className='cursor-pointer flex item-center gap-2 rounded border border-gray4 p-1 text-xs mt-3'
                                                                    onClick={() => setNewFeatureName('name')}
                                                                >
                                                                    + Add new feature
                                                                </button>
                                                            ) : (
                                                                <div className='flex items-center justify-center gap-1 mt-3 border border-gray3 rounded'>
                                                                    <input
                                                                        aria-label='check mark'
                                                                        type='text'
                                                                        className='p-1 outline-none'
                                                                        onChange={e =>
                                                                            setNewFeatureName(e.target.value)
                                                                        }
                                                                    />
                                                                    <Check
                                                                        className='cursor-pointer'
                                                                        onClick={() => {
                                                                            setFeatures(prev => [
                                                                                ...prev,
                                                                                {
                                                                                    name: newFeatureName,
                                                                                    id: newFeatureName as ModuleNameEnum,
                                                                                },
                                                                            ])
                                                                            setNewFeatureName(null)
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </SelectContent>
                                                    </Select>
                                                )}
                                            />
                                        </fieldset>
                                        <div className='flex items-center gap-4 -mt-1'>
                                            <fieldset className='flex-1'>
                                                <label
                                                    className='text-xs block font-medium mb-1 whitespace-nowrap'
                                                    htmlFor='plan_name'
                                                >
                                                    Feature amount
                                                </label>
                                                <Controller
                                                    name={`feature.${idx}.amount`}
                                                    control={control}
                                                    rules={{
                                                        required: false,
                                                    }}
                                                    render={({ field: fielde }) => (
                                                        <Input
                                                            {...fielde}
                                                            disabled={watchFeature?.[idx].unlimited}
                                                            min={0}
                                                            type='number'
                                                            placeholder='Enter feature amount'
                                                            className='disabled:opacity-60 border-[#DADCE0] h-[2.5rem] placeholder:text-xs'
                                                        />
                                                    )}
                                                />
                                            </fieldset>
                                            <fieldset className='w-fit'>
                                                <label className='text-xs block font-medium mb-1' htmlFor='plan_name'>
                                                    unlimited?
                                                </label>
                                                <Controller
                                                    name={`feature.${idx}.unlimited`}
                                                    control={control}
                                                    rules={{
                                                        required: false,
                                                    }}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            value={field.value.toString()}
                                                            checked={field.value}
                                                            onChange={e => field.onChange(e.target.checked)}
                                                            type='checkbox'
                                                            className='cursor-pointer p-3 accent-brand border-[#DADCE0] h-[2.2rem] lg:h-10 placeholder:text-xs'
                                                        />
                                                    )}
                                                />
                                            </fieldset>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </form>
                    <button
                        onClick={() => {
                            appendFeature({ amount: '0', name: '', unlimited: false })
                        }}
                        className='mt-6 mx-6  border border-gray3 rounded-md p-2 flex items-center justify-center gap-2 text-xs'
                    >
                        <Plus className='opacity-50 w-4 h-4' />
                        Add new feature
                    </button>

                    <div className='mx-2 sm:mx-6 mt-6'>
                        <p className='block font-semibold text-lg text-[#31254B] col-span-2'>Metadata</p>

                        <div className='col-span-2 grid grid-cols-2 gap-6 bg-gray4/10 p-3 rounded-md relative mb-24'>
                            <fieldset className='col-span-2'>
                                <Controller
                                    name={`metadata`}
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Textarea
                                            {...field}
                                            placeholder='use enter for a new metadata'
                                            className='border-[#DADCE0]  placeholder:text-xs'
                                        />
                                    )}
                                />
                            </fieldset>
                        </div>
                    </div>
                    <div className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                        <Button
                            loading={isLoading || isUpdatingPlan}
                            disabled={!isValid}
                            className='my-1 ml-auto mr-6'
                            type='submit'
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </section>
        </ModalWrapper>
    )
}

export default CreateBillingPlan
