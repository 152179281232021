import { DeleteIcon } from '@/assets/assets.export'
import { ArrowLeft } from 'lucide-react'
import { Control, Controller, UseFormWatch } from 'react-hook-form'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { TbCurrencyNaira } from 'react-icons/tb'
import { formProps } from './utils'

type Step2Props = {
    selectedMembers: Record<'label' | 'value' | 'amount', string>[]
    setSelectedMembers: React.Dispatch<React.SetStateAction<Record<'label' | 'value' | 'amount', string>[]>>
    control: Control<formProps, any>
    setStep: React.Dispatch<React.SetStateAction<number>>
    showPage: () => void
    values: () => formProps
    watchedAMount: string
    watch: UseFormWatch<formProps>
}

const TransferPage2 = ({
    selectedMembers,
    control,
    setSelectedMembers,
    setStep,
    showPage,
    watchedAMount,
    watch,
}: Step2Props) => {
    const deleteMember = (i: number) => {
        const updatedSelectedMembers = selectedMembers.slice(0, i).concat(selectedMembers.slice(i + 1))
        setSelectedMembers(updatedSelectedMembers)
    }

    const checkboxAmt = watch('sameAmountCheckBox')

    return (
        <div className=''>
            <div
                className='flex gap-1 my-6 cursor-pointer mb-12'
                onClick={() => {
                    setStep(1)
                    showPage()
                }}
            >
                <ArrowLeft />
                back
            </div>
            <div className='mb-6'>
                <Controller
                    name='sameAmountCheckBox'
                    control={control}
                    render={({ field }) => (
                        <>
                            <input
                                {...field}
                                id='sameAmountCheckBox'
                                type='checkbox'
                                className='accent-[#454ADE] cursor-pointer'
                                onChange={e => {
                                    return field.onChange(e)
                                }}
                                defaultChecked
                            />
                            <label htmlFor='sameAmountCheckBox' className='font-medium cursor-pointer text-brand ml-2'>
                                Transfer same amount to all recipients
                            </label>
                        </>
                    )}
                />
            </div>
            {/* {selectedMembers.length > 0 &&} */}
            <div className='bg-[#F9F9F9] rounded-xl px-3 py-4'>
                {selectedMembers.map((member, idx) => (
                    <div className=' flex justify-between items-end' key={idx}>
                        <p className='bg-white px-2 border border-[#DADCE0] py-[14.5px] rounded-lg text-xs w-[8rem] truncate'>
                            {member.label}
                        </p>
                        <div className='flex justify-between gap-4 items-end'>
                            <div className='flex border border-[#DADCE0] w-[90% h-[45px] rounded-lg mt-2 overflow-hidden px-2'>
                                <div className='w-[10%] flex items-center justify-center text-[#838383] mx-[2px]'>
                                    <TbCurrencyNaira fontSize={20} />
                                </div>
                                {/* {JSON.stringify(values?.()?.sameAmountCheckBox ? values?.()?.amount : member.amount)} */}
                                <input
                                    value={formatAmount(checkboxAmt ? watchedAMount : member.amount).replaceAll(
                                        '₦',
                                        ''
                                    )}
                                    onChange={e => {
                                        e.target.value = formatAmountWithoutSymbols(e.target.value)
                                        setSelectedMembers(prev => {
                                            return prev.map(memberInState => {
                                                if (member.value === memberInState.value) {
                                                    return {
                                                        ...memberInState,
                                                        amount: e.target.value,
                                                    }
                                                }
                                                return memberInState
                                            })
                                        })
                                    }}
                                    className='w-[8.5rem] outline-none bg-transparent placeholder:relative placeholder:top-[.1rem] placeholder:text-[#A8A8A8]/90 placeholder:text-xl h-[45px] pl-0 disabled:opacity-30'
                                    placeholder='0.00'
                                    disabled={(checkboxAmt as unknown as boolean) ?? true}
                                />
                            </div>

                            <div>
                                <DeleteIcon
                                    className='bg-[#FFEDEC] rounded-md scale-125 relative bottom-1 cursor-pointer p-1'
                                    onClick={() => deleteMember(idx)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TransferPage2
