import { createMutation } from '../api/mutation'

export const keyGetAllCards = ['/get-org-cards']
export const keyGetUserCards = ['/get-user-cards']
export const useMutationCreateCard = createMutation({
    url: '/create-card',
    method: 'POST',
    keysToRefetch: [keyGetAllCards],
})

export const useMutationFreezeCard = createMutation({
    url: '/freeze-card',
    method: 'PATCH',
    keysToRefetch: [keyGetAllCards],
})
export const useMutationUnFreezeCard = createMutation({
    url: '/unfreeze-card',
    method: 'PATCH',
    keysToRefetch: [keyGetAllCards],
})

export const useMutationFundCard = createMutation({
    url: '/fund-card',
    method: 'POST',
    keysToRefetch: [keyGetAllCards],
})
