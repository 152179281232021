import { DefaultToastOptions, toast } from 'react-hot-toast'

interface TP extends DefaultToastOptions {
    message: string
    state?: 'success' | 'error'
}

export const toastHandler = ({ message, state = 'success', ...props }: TP) => {
    return toast[state](message, {
        duration: 4000,
        position: 'top-right',
        style: {
            color: state === 'success' ? '#19C37D' : '#CF491E',
            backgroundColor: state === 'success' ? '#EFFFED' : '#FFDBD6',
            borderRadius: '0.25rem',
        },
        ...props,
    })
}
