import { format } from 'date-fns'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatText } from '../CompanyTOS/CompanyTOS'
import { ChevronLeft, ChevronRight } from 'lucide-react'

export const CompanyPrivacyPolicy = () => {
    const [selectedTerm, setSelectedTerm] = useState('services')

    const targetRef = useRef<(HTMLDivElement | null)[]>([])
    const handleScroll = (i: number) => {
        targetRef.current[i]?.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollRef = useRef<HTMLDivElement>(null)
    const scroll = (scrollOffset: number) => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += scrollOffset
        }
    }
    return (
        <div className='mr-6'>
            <section className='font-medium  mt-5 text-xl'>
                <h3> Privacy Policy </h3>
                <p className='text-[#838383] text-sm mt-1'>
                    Kindly review our privacy policy and get familiar with them.
                </p>
            </section>
            <h2 className='text-lg font-semibold my-6'>
                <span className='text-gray7'>Updated</span> {format(new Date(), 'MMMM dd, yyyy')}
            </h2>
            <div className='flex flex-col lg:flex-row gap-6 justify-between'>
                <div
                    className={`lg:w-[30%] bg-white rounded-xl lg:px-7 px-2 py-7 flex gap-1 justify-between border-2 border-[#cfcfcf]  items-center`}
                >
                    <ChevronLeft size={30} onClick={() => scroll(-100)} className='lg:hidden' />
                    <div className=' flex flex-row lg:flex-col overflow-x-scroll gap-6 w-4/5 lg:w-full' ref={scrollRef}>
                        {privacyList.map((term, idx) => (
                            <Link
                                to={`#${term.id}`}
                                className={`${selectedTerm === term.id ? 'text-brand font-semibold' : 'font-medium'} text-center lg:text-left w-fit whitespace-nowrap`}
                                onClick={() => {
                                    setSelectedTerm(term.id)
                                    handleScroll(idx)
                                }}
                            >
                                {term.title}
                            </Link>
                        ))}
                    </div>
                    <ChevronRight size={30} onClick={() => scroll(100)} className='lg:hidden' />
                </div>
                <div className='bg-white px-5 md:px-8 p-8 rounded-xl lg:w-[70%]  h-[560px] overflow-y-scroll'>
                    <h2 className='text-lg font-semibold text-gray7'>
                        Welcome to Bizpend Technologies. We are committed to protecting your privacy and ensuring the
                        security of your personal data. This Privacy Policy outlines our practices regarding the
                        collection, use, disclosure, and protection of your information when you visit our website at{' '}
                        <Link className='text-brand hover:underline' to={'https://bizpend.com'} target='_blank'>
                            bizpend.com
                        </Link>
                        , use our services, or interact with us in other capacities.
                    </h2>
                    {privacyList.map((term, idx) => (
                        <div className='pt-4' id={term.id} key={term.id} ref={el => (targetRef.current[idx] = el)}>
                            <div
                                className={`mt-6 mb-10 ${term.id === selectedTerm ? 'bg-[#fdfce2] p-2 rounded-md' : ''}`}
                            >
                                <h2 className='py-2 border-b border-[#838383] text-base font-semibold'>{term.title}</h2>
                                <p className='mt-4'>{formatText(term.body)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const privacyList = [
    {
        title: 'Information We Collect',
        id: 'services',
        body: `We collect various types of information to provide and improve our services:\n
        Personal Identification Information: Including but not limited to your name, email address, and phone number, which you provide when registering or interacting with our services.
        Financial Information: Such as bank account details, credit card numbers, and other payment-related information required to process transactions securely.
        KYC Details: Necessary personal and financial details to comply with Know Your Customer (KYC) regulations, including but not limited to government-issued ID numbers, photographs for identity verification, and additional background information as required by law.
`,
    },
    {
        title: 'Purpose of Data Collection',
        id: 'property',
        body: `Your data is collected for specific, legitimate purposes:\n
                KYC and Security: To verify your identity, comply with legal obligations, and ensure the security of your transactions and our platform.
                Enhanced User Experience: To provide you with personalized services, support, and a seamless user experience.
                Service Improvement and Development: To analyze how our services are used and to develop new features and functionalities.`,
    },
    {
        title: 'Data Storage and Access',
        id: 'content',
        body: `Storage Location: Your data is securely stored in cloud services with our primary database hosted in Frankfurt, ensuring compliance with European data protection regulations.
        Access Control: Only authorized personnel, including our Head of Engineering, have access to your personal data, strictly for business and operational purposes.`,
    },
    {
        title: 'Third-Party Sharing',
        id: 'account',
        body: `We respect your privacy and are cautious about sharing your data:\n
        No Unnecessary Sharing: We do not sell, rent, or trade your personal information with third parties for their marketing purposes.
        Service Providers: We may share your information with trusted partners who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.`,
    },
    {
        title: 'User Rights',
        id: 'privacy',
        body: `You have comprehensive rights regarding your data:\n
Access and Transparency: You can request access to any personal information we hold about you.
Correction and Deletion: You have the right to ask for corrections, updates, or deletions of your personal information.
Consent Withdrawal: You can withdraw your consent at any time, subject to legal or contractual restrictions.`,
    },
    {
        title: 'Data Security',
        id: '3rdparty',
        body: `To protect your personal data, we implement robust security measures:\n
Encryption and Security Protocols: We use encryption and secure protocols to protect data during transmission and storage.
Regular Audits and Updates: Our systems and practices are regularly reviewed and updated to address new security threats.`,
    },
    {
        title: 'International Data Transfer',
        id: 'disclaimer',
        body: `Data Transfer Practices: Data is transferred from our Frankfurt-based servers directly to the user, adhering to strict data protection and privacy regulations.`,
    },
    {
        title: 'Cookies and Tracking Technologies',
        id: 'law',
        body: `Use of Cookies: We use cookies to enhance site navigation, analyze site usage, and assist in our marketing efforts.
User Control: You can manage and control cookie settings through your browser preferences.`,
    },
    {
        title: `Children's Privacy`,
        id: 'changes',
        body: `Age Limitations: We do not knowingly collect personal information from children under 13. If we learn that we have collected such information, we will take steps to delete it promptly.`,
    },
    {
        title: ' Policy Updates',
        id: 'contact',
        body: `Notification of Changes: Any changes to our Privacy Policy will be communicated through email and posted on our website, ensuring you are always informed about what information we collect, how we use it, and under what circumstances, if any, we disclose it.`,
    },
    {
        title: ' Contact Information',
        id: 'info',
        body: `For any questions or concerns about our Privacy Policy or your personal data, please contact us at admin@bizpend.com.`,
    },
]
