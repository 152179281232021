import { BillPaymentContextProvider } from '@/context/bill-payment'
import { TransactionContextProvider } from '@/context/transactions'
import { VendorsContextProvider } from '@/context/vendors'
import { BillPaymentDetails } from '@/modules/dashboard/BillPayments/partials/BillPaymentDetails.tsx/BillPaymentDetails'
import Vendor from '@/modules/dashboard/Vendor/Vendor'
import VendorManagement from '@/modules/dashboard/Vendors/VendorManagement/VendorManagement'
import { Outlet, Route } from 'react-router-dom'

export const partnerRoute = {}
export const billPaymentsRouteConst = {
    billPaymentBaseUrl: 'bill_payment',
    billPaymentsubRoute: 'bill_payment/:bill_payment_subRoute',
    billPaymentDetails: 'bill_payment/:bill_payment_subRoute/:bill_id',
    personalBillPaymentRoute: 'bill_payment/:bill_payment_subRoute/personal',
    companyBillPaymentRoute: 'bill_payment/:bill_payment_subRoute/company',
    company: 'company', //for admin only
}
export const vendorMgmtRouteConst = {
    vendorMgmtBaseUrl: 'vendor_management',
    vendorMgmtsubRoute: 'vendor_management/:vendor_management_subRoute',
    vendorBillPaymentsubRoute: 'vendor_management/:vendor_management_subRoute/:bill_payment_subRoute',
    vendorBillPaymentDetails: 'vendor_management/:vendor_management_subRoute/:bill_payment_subRoute/:bill_id',
}

export const useVendorManagementRoutes = () => {
    const { vendorMgmtBaseUrl, vendorMgmtsubRoute, vendorBillPaymentDetails, vendorBillPaymentsubRoute } =
        vendorMgmtRouteConst
    return (
        <Route
            element={
                <TransactionContextProvider>
                    <BillPaymentContextProvider>
                        <VendorsContextProvider>
                            <Outlet />
                        </VendorsContextProvider>
                    </BillPaymentContextProvider>
                </TransactionContextProvider>
            }
        >
            {/* Vendors */}
            {[vendorMgmtBaseUrl, vendorMgmtsubRoute, vendorBillPaymentDetails, vendorBillPaymentsubRoute].map(route => (
                <Route path={route} key={route} element={<VendorManagement />} />
            ))}
            <Route path='vendor_management/vendors/:vendor_id' element={<Vendor />} />
            <Route
                path={`vendor_management/${billPaymentsRouteConst.billPaymentDetails}`}
                element={<BillPaymentDetails />}
            />
        </Route>
    )
}
