import { useAuthContext } from '@/context'
import { UserOrgAccount } from '@/types/org'
import { isAdmin } from '@/utils/permissions'
import { useEffect } from 'react'
import './index.css'

const NotFound = () => {
    const { selectedAccount } = useAuthContext()

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isAdmin(selectedAccount as UserOrgAccount)) {
                window.location.href = '/dashboard/overview'
            } else {
                window.location.href = '/login'
            }
        }, 3000)

        return () => clearTimeout(timer)
    }, [history])

    return (
        <main className='not-found grid place-items-center'>
            <div>
                <div className='text-center space-y-3'>
                    <h1 className='font-bold text-8xl '>404</h1>
                    <p className='font-sans text-5xl font-light opacity-80'>Page Not Found</p>
                    <p className='text-gray7 text-sm w-96'>
                        Well, this is awkward. The page you're looking for isn't here. Let's get you back on track!
                    </p>
                </div>
                <p className='mt-10 text-center'>You will be redirected shortly ...</p>
            </div>
        </main>
    )
}

export default NotFound
