import { createMutation } from '../api/mutation'
import { orgBenefits } from './queries'

export const useCreateBenefit = createMutation({
    url: '/create-benefit',
    method: 'POST',
    keysToRefetch: [orgBenefits],
})
export const useUpdateBenefit = createMutation({
    url: '/update-benefit',
    method: 'PATCH',
    keysToRefetch: [orgBenefits],
})

export const useAddUsersToBenefits = createMutation({
    url: '/add-users-to-benefit',
    method: 'PATCH',
    keysToRefetch: [orgBenefits],
})
export const useRemoveUsersFromBenefits = createMutation({
    url: '/remove-user-from-benefit',
    method: 'DELETE',
    keysToRefetch: [orgBenefits],
})

export const useSuspendBenefitPlan = createMutation({
    url: '/suspend-benefit',
    method: 'POST',
    keysToRefetch: [orgBenefits],
})
