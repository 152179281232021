export type filter_by_Props = {
    [key: string]: any
}

export const modalVariants = {
    hidden: {
        opacity: 0,
        x: '100%',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.3,
        },
    },
}

const date = new Date()

export const FilterVaultUtils = {
    periods: [
        {
            period: 'Today',
            type: 'today',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate())}`
            },
        },
        {
            period: 'Yesterday',
            type: 'yesterday',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)}`
            },
        },
        {
            period: '3 days ago',
            type: '3days',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 3)}`
            },
        },
        {
            period: '2 weeks ago',
            type: '2weeks',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 14)}`
            },
        },
    ],
    due_time: [
        {
            period: '24 Hours ago',
            type: '24hrs',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)}`
            },
        },
        {
            period: 'Last 7 days',
            type: '7days',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)}`
            },
        },
        {
            period: 'Last month',
            type: 'last_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 1, date.getDate() - 1)}`
            },
        },
        {
            period: 'Last 6 months',
            type: '6_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 6, date.getDate() - 1)}`
            },
        },
    ],
    filter_by: [
        { Type: { type: 'type' } },
        { Owner: { type: 'owner' } },
        { Categories: { type: 'categories' } },
        { Location: { type: 'location' } },
        { 'Date Created': { type: 'date_created' } },
        // { 'Access Type': { type: 'access_type' } },
    ],
}

export const VaultsDropdowns = {
    type: ['Any', 'PDF', 'DOCX', 'XLSX', 'PNG', 'JPG'],
    // access_type: ['View only', 'View and edit'],
    categories: ['Bill', 'Expense', 'Receipt', 'Contract', 'SLA'],
    date_created: FilterVaultUtils.periods,
}

export const filter_consts = {
    owner: 'owner',
    location: 'location',
    reimbursable: 'reimbursable',
    initial: 'initial',
    max: 'max',
    min: 'min',
}
