import React, { useEffect } from 'react'
import { BusinessInfo } from '../components/BusinessInfo'
import { FormProvider, useForm } from 'react-hook-form'
import { AddressInfo } from '../components/AddressInfo'
import { IncorporationInfo } from '../components/IncorporationInfo'
import { BizCompanyComplianceType } from '../utils'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
    step: number
}

const key = 'kybFormData'

export const CompleteCompanyCompliance = ({ setStep, step }: Props) => {
    const storedData = localStorage.getItem(key)
    const kybData = storedData ? JSON.parse(storedData) : null

    const methods = useForm<Partial<BizCompanyComplianceType>>({
        defaultValues: kybData ? kybData : undefined,
    })

    const watchVals = methods.watch()

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(watchVals))
    }, [watchVals])

    return (
        <FormProvider {...methods}>
            <form onSubmit={e => e.preventDefault()} className='flex-1 bg-white rounded-xl p-5 md:px-8 shadow-sm'>
                {step === 1 && <BusinessInfo setStep={setStep} />}
                {step === 2 && <AddressInfo setStep={setStep} />}
                {step === 3 && <IncorporationInfo setStep={setStep} />}
            </form>
        </FormProvider>
    )
}
