import { createMutation } from '../api/mutation'
import { keyQueryOrgExpenses } from '../expenses/queries'
import { orgBudgetForTeam, orgBudgetKey } from './queries'

export const useCreateBudget = createMutation({
    url: '/create-budget',
    method: 'POST',
    keysToRefetch: [orgBudgetKey],
})
export const useUpdateBudget = createMutation({
    url: '/update-budget',
    method: 'PATCH',
    keysToRefetch: [orgBudgetForTeam, orgBudgetKey],
})

export const useToggleLinkBudget = createMutation({
    url: '/toggle-link-budget',
    method: 'POST',
    keysToRefetch: [orgBudgetForTeam, orgBudgetKey, keyQueryOrgExpenses, ['/expense-id']],
})
