import { useAuthContext } from '@/context'
import { ACCESS_PERMISSIONS } from '@/utils/permisson-helper'
import { useMemo } from 'react'

export const useHasAccessPermisson = (requiredPermissions: ACCESS_PERMISSIONS[]) => {
    const { selectedAccount } = useAuthContext()

    const userPermissions = selectedAccount?.role?.permissions
    return useMemo(() => {
        return requiredPermissions.every(permission => userPermissions?.includes(permission))
    }, [requiredPermissions, selectedAccount])
}
