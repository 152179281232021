import { Budget } from '@/types/budget'
import { createQuery } from '../api/query'
import { QueryOrgBudgetType } from './types'
import { TransactionType } from '@/types/transactions'

export const orgBudgetKey = ['get-budget-key']
export const orgBudgetForTeam = ['get-budget-team-key']
export const orgBudgetKeyById = (arg: string) => ['get-budget-key', 'get-budget-team-key', arg]
export const orgBudgetTransaction = ['get-budget-transactions-key']

export const useGetOrgBudget = createQuery<QueryOrgBudgetType>({
    url: '/get-org-budgets',
    key: orgBudgetKey,
})

export const useGetOrgBudgetForTeam = createQuery<[]>({
    url: '/get-budget-for-team',
    key: orgBudgetForTeam,
})

export const useGetOrgBudgetById = createQuery<Budget>({
    url: '/get-budget-by-id',
    key: orgBudgetKeyById,
})
export const useGetBudgetTransactions = createQuery<TransactionType[]>({
    url: '/get-budget-transactions',
    key: orgBudgetTransaction,
})
