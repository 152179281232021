
export const countWords = (text: string) => {
    const words = text
        .trim()
        .split(/\s+/)
        .filter(word => word !== '')
    return words.length
}

export type helperType = {
    title?: string
    fieldType: 'text' | 'dropdown' | 'radio' | 'date' | 'textarea' | 'file' | 'checkbox'
    placeholder?: string
    name: string
    fieldOptions?: Record<'value' | 'id', string>[]
}

export type ExpenseFieldProps = {
    amount: string
    attact_budget: 'link' | 'no_link'
    budget?: string
    category: string
    description: string
    expense_type: 'reimbursable' | 'non-reimbursable'
    merchant: string
    date: string
}

export const createExpenseHelper: helperType[] = [
    {
        title: 'Merchant name',
        fieldType: 'text',
        placeholder: 'Enter name of merchant',
        name: 'merchant',
    },
    {
        title: 'Date',
        fieldType: 'date',
        placeholder: 'Choose date',
        name: 'date',
    },
    {
        title: 'Category',
        fieldType: 'dropdown',
        placeholder: 'Choose category',
        name: 'category',
    },
    {
        title: 'Amount',
        fieldType: 'text',
        placeholder: 'Enter amount',
        name: 'amount',
    },
    {
        title: 'Description',
        fieldType: 'textarea',
        placeholder: 'Enter description',
        name: 'description',
    },

    {
        title: 'Upload Receipt',
        fieldType: 'file',
        name: 'receipt',
    },
    { fieldType: 'checkbox', name: 'scan' },
    {
        title: 'Expense type',
        fieldType: 'radio',
        fieldOptions: [
            { value: 'Reimbursable', id: 'reimbursable' },
            { value: 'Non-reimbursable', id: 'non-reimbursable' },
        ],
        name: 'expense_type',
    },
    {
        title: 'Attach this expense to a budget?',
        fieldType: 'radio',
        fieldOptions: [
            { value: 'Yes', id: 'link' },
            { value: 'No', id: 'no_link' },
        ],
        name: 'attact_budget',
    },
    {
        title: 'Select budget',
        fieldType: 'dropdown',
        name: 'budget',
    },
]

export function allFalsy(obj: any) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && key !== 'filter_now' && obj[key]) {
            return false
        }
    }
    return true
}

