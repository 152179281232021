import { ProfileEditIcon } from '@/assets/assets.export'
import ProgressBar from '@/components/ProgressBar'
import StatusColor from '@/components/utils/StatusColor'
import { useGetOrgBusinessPlan, useGetOrgSuscription } from '@/services/billing/queries'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BusinessPlanBilling } from '@/types/billing'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'

import CreateBusinessPlan from '@/components/Modals/Business-directory/CreateBusinessPlan'
import { capitalizeText } from '@/utils/string'

export default function DirectoryOrgBusinessPlan() {
    const { user_id: org_id } = useParams()
    const { isLoading, data } = useGetOrgBusinessPlan({
        queryParams: {
            org: org_id as string,
        },
    })
    const { data: orgSubscription, isLoading: isGettingSub } = useGetOrgSuscription({
        queryParams: {
            org: org_id as string,
        },
    })

    const [editPlan, setEditPlan] = useState(false)

    const orgBusinessPlan = useMemo(() => data ?? ({} as BusinessPlanBilling), [data])

    const sub_info = [
        {
            name: 'Plan',
            desc: typeof orgBusinessPlan?.plan === 'string' ? orgBusinessPlan?.plan : orgBusinessPlan?.plan?.name,
        },
        {
            name: 'Price',
            desc: formatAmount(orgBusinessPlan?.amount),
        },
        {
            name: 'Pay frequency',
            desc: 'monthly',
        },
        {
            name: 'Last payment',
            desc: orgSubscription?.start_date && format(new Date(orgSubscription?.start_date), 'dd/MM/yyyy hh:mm aa'),
        },
        {
            name: 'Next payment',
            desc:
                orgBusinessPlan?.next_renewal_date &&
                format(new Date(orgBusinessPlan?.next_renewal_date), 'dd/MM/yyyy hh:mm aa'),
        },
        {
            name: 'Date onboarded',
            desc: orgBusinessPlan?.createdAt && format(new Date(orgBusinessPlan?.createdAt), 'dd/MM/yyyy hh:mm aa'),
        },
        {
            name: 'Status',
            desc: <StatusColor disableBgColor status={orgSubscription?.status as string} />,
        },
    ]

    if (isLoading || isGettingSub) return <ProgressBar />
    return (
        <>
            <section className='flex-1 bg-white rounded-xl p-5 shadow-sm mt-10'>
                <div className='flex justify-between border-b border-gray5 pb-2 items-center'>
                    <h3 className='font-medium text-md'>Subscription information</h3>

                    <span
                        className='flex items-center gap-2 text-[#5E5E5E]  rounded-md cursor-pointer text-sm'
                        onClick={() => setEditPlan(true)}
                    >
                        <ProfileEditIcon className='w-4 h-4' />
                        <button type='button'>Edit</button>
                    </span>
                </div>

                <div className=' mt-5'>
                    <div className='flex flex-wrap gap-x-[4.5rem] gap-5 gap-y-7'>
                        {sub_info?.map(data => {
                            return (
                                <div className='flex flex-col flex-wrap    '>
                                    <span className='text-[#838383] font-normal text-sm'>{data.name}</span>
                                    <div className='flex gap-3 items-center mt-2 truncate'>
                                        <span className='font-medium text-[.9rem] truncate max-w-[12rem]'>
                                            {data.desc}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className='flex-1 bg-white rounded-xl p-5 shadow-sm mt-10'>
                <div className='flex justify-between border-b border-gray5 pb-2 items-center'>
                    <h3 className='font-medium text-md'>Addons</h3>

                    <span
                        className='flex items-center gap-2 text-[#5E5E5E]  rounded-md cursor-pointer text-sm'
                        onClick={() => setEditPlan(true)}
                    >
                        <ProfileEditIcon className='w-4 h-4' />
                        <button type='button'>Edit</button>
                    </span>
                </div>

                <div className=' mt-5'>
                    <div className='flex flex-wrap gap-x-[4.5rem] gap-5 gap-y-7'>
                        {Object.keys(orgBusinessPlan?.addons)
                            ?.filter(key => !key.includes('id'))
                            ?.map(key => {
                                return (
                                    <div className='flex flex-col flex-wrap    '>
                                        <span className='text-[#838383] font-normal text-sm'>
                                            {capitalizeText(key)}
                                        </span>
                                        <div className='flex gap-3 items-center mt-2 truncate'>
                                            <span className='font-medium text-[.9rem] truncate max-w-[12rem]'>
                                                {orgBusinessPlan?.addons?.[key]}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
                {editPlan && (
                    <CreateBusinessPlan
                        closeModal={() => setEditPlan(false)}
                        planDetails={orgBusinessPlan}
                        edit={true}
                    />
                )}
            </section>
        </>
    )
}
