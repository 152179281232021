import { Clock } from 'lucide-react'
import TransactionStatusBadge from '../../Transactions/components/TransactionStatusBadge'
import { CategoryIcon, ProfileIcon, TransactionMinus } from '@/assets/assets.export'
import { TransactionType } from '@/types/transactions'
import { format } from 'date-fns'
import { formatAmount } from '@/utils/money'
import { TransactionStatus } from '../../Transactions/const'

type Props = {
    last: boolean
    transaction: TransactionType
}
const OverviewTransactionsView = ({ transaction }: Props) => {
    return (
        <div className={`flex items-center justify-between py-4 pl-5 pr-4 border-b last:border-0 border-[#DADCE0] `}>
            <div className=''>
                <h1 className=' text-md font-medium text-[#20202] mb-3 '>{transaction.description}</h1>
                <div className='flex text-[#5E5E5E] items-center gap-5 text-sm'>
                    <p>{transaction.transaction_id}</p>
                    <div className='flex items-center'>
                        <Clock color='#5E5E5E' size={20} className='mr-2' />

                        <span> {format(new Date(transaction.createdAt), 'dd/MM/yyyy hh:mm a')}</span>
                    </div>
                    <div className='flex items-center'>
                        <ProfileIcon className='mr-2 scale-90' />
                        <p>{}</p>
                    </div>
                    <div className='flex items-center'>
                        <TransactionMinus className='mr-2 scale-90' />
                        <span>{transaction?.debit?.account?.user?.last_name}</span>
                    </div>
                    <div className='flex items-center'>
                        <CategoryIcon className='mr-2 scale-90' />
                        {transaction.type}
                    </div>
                </div>
            </div>
            <div className='text-sm'>
                <p className='text-md font-medium text-[#20202] mb-2'>{formatAmount(transaction.credit?.amount)}</p>

                <TransactionStatusBadge status={transaction.status as TransactionStatus} />
            </div>
        </div>
    )
}

export default OverviewTransactionsView
