import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { ModuleNameEnum } from '@/types/billing'
import { capitalize } from 'lodash'

type Props = {
    closeModal: () => void
    module: ModuleNameEnum
}
export default function UserViewUpgradeModal({ closeModal, module }: Props) {
    return (
        <ModalWrapper closeModal={() => closeModal()}>
            <section className='min-h-[10rem] w-[30rem] overflow-scroll'>
                <div className='flex items-center p-3 px-4 justify-between'>
                    <h2 className='text-center text-base font-medium opacity-80 text-errorRed'>
                        {capitalize(module.endsWith('s') ? module.slice(0, -1) : module)} Limit Reached
                    </h2>
                    <CancelIcon
                        className='w-6 h-6 rounded-full bg-[#F2F2F2] p-1.5  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <p className='text-gray7 mt-2 mx-4 text-sm'>
                    You have reached the maximum number of {module} allowed under your company's current subscription
                    plan. To create additional {module}, please contact your administrator to discuss upgrading your
                    plan.
                </p>
            </section>
        </ModalWrapper>
    )
}
