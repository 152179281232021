export const kyb = {
    industries: [
        {
            title: 'Agriculture',
            children: ['Agricultural Cooperatives', 'Agricultural Services'],
        },
        {
            title: 'Commerce',
            children: [
                'Automobiles',
                'Digital Goods',
                'Physical Goods',
                'Real Estate',
                'Digital Services',
                'Legal Services',
                'Physical Services',
                'Professional Services',
                'Other Professional Services',
            ],
        },
        {
            title: 'Education',
            children: [
                'Nursery Schools',
                'Primary Schools',
                'Secondary Schools',
                'Tertiary Institutions',
                'Vocational Training',
                'Virtual Learning',
                'Other Educational Services',
            ],
        },
        {
            title: 'Gaming',
            children: ['Betting', 'Lotteries', 'Prediction Services'],
        },
        {
            title: 'Financial Services',
            children: [
                'Financial Cooperatives',
                'Corporate Services',
                'Payment Solution Service Providers',
                'Insurance',
                'Investments',
                'Agricultural Investments',
                'Lending',
                'Bill Payments',
                'Payroll',
                'Remittances',
                'Savings',
                'Mobile Wallets',
            ],
        },
        {
            title: 'Health',
            children: ['Gyms', 'Hospitals', 'Pharmacies', 'Herbal Medicine', 'Telemedicine', 'Medical Laboratories'],
        },
        {
            title: 'Hospitality',
            children: ['Hotels', 'Restaurants'],
        },
        {
            title: 'Nonprofits',
            children: [
                'Professional Associations',
                'Government Agencies',
                'NGOs',
                'Political Parties',
                'Religious Organizations',
                'Leisure And Entertainment',
                'Cinemas',
                'Nightclubs',
                'Events',
                'Press And Media',
                'Recreation Centres',
                'Streaming Services',
            ],
        },
        {
            title: 'Logistics',
            children: ['Courier Services', 'Freight Services'],
        },
        {
            title: 'Travel',
            children: ['Airlines', 'Ridesharing', 'Tour Services', 'Transportation', 'Travel Agencies'],
        },
        {
            title: 'Utilities',
            children: ['Cable Television', 'Electricity', 'Garbage Disposal', 'Internet', 'Telecoms', 'Water'],
        },
        {
            title: 'Retail',
        },
        {
            title: 'Wholesale',
        },
        {
            title: 'Restaurants',
        },
        {
            title: 'Construction',
        },
        {
            title: 'Unions',
        },
        {
            title: 'Real Estate',
        },
        {
            title: 'Freelance Professional',
        },
        {
            title: 'Other Professional Services',
        },
        {
            title: 'Online Retailer',
        },
        {
            title: 'Other Education Services',
        },
    ],
    businessTypes: [
        {
            title: 'Sole Proprietorship',
            value: 'SOLE_PROPRIETORSHIP',
        },
        {
            title: 'Public Limited Company',
            value: 'PUBLIC_LIMITED_COMPANY',
        },
        {
            title: 'Private Limited Company',
            value: 'PRIVATE_LIMITED_COMPANY',
        },
        {
            title: 'NGO',
            value: 'NGO',
        },
    ],
    role: [
        {
            title: 'OWNER',
            value: 'OWNER',
        },
        {
            title: 'DIRECTOR',
            value: 'DIRECTOR',
        },
    ],
    officer_title: [
        {
            title: 'CEO',
            value: 'CEO',
        },
        {
            title: 'COO',
            value: 'COO',
        },
        {
            title: 'CFO',
            value: 'CFO',
        },
        {
            title: 'CIO',
            value: 'CIO',
        },
        {
            title: 'PRESIDENT',
            value: 'PRESIDENT',
        },
        {
            title: 'CONTROLLER',
            value: 'CONTROLLER',
        },
        {
            title: 'MEMBER',
            value: 'MEMBER',
        },
        {
            title: 'VP',
            value: 'VP',
        },
        {
            title: 'MANAGER',
            value: 'MANAGER',
        },
        {
            title: 'PARTNER',
            value: 'PARTNER',
        },
        {
            title: 'TREASURER',
            value: 'TREASURER',
        },
    ],
    countries: [
        {
            title: 'NG',
            value: 'NG',
        },
    ],
}

export type complianceDetailsType = {
    name: string
    deet: { name: string; desc: string }[]
}

export type BizCompanyComplianceType = {
    legal_business_name: string
    industry: string
    sub_industry: string
    number_of_employees: string
    business_email: string
    business_phone: number
    website: string
    description: string
    hasRegisteredAddress: boolean
    main: {
        country: string
        state: string
        city: string
        lga: string
        postalCode: string
        addressLine_1: string
    }
    registered: {
        country: string
        state: string
        city: string
        lga: string
        postalCode: string
        addressLine_1: string
    }
    company_type: string
    bn_number: number
    rc_number: string
    tax_identification_number: string
    cac_it_number: string
    registration_date: string
    officers: {
        fullName: {
            firstName?: string
            lastName?: string
        }
        address: {
            country: string
            state: string
            city: string
            lga: string
            addressLine_1: string
            postalCode: string
        }
        data: {
            role: string
            nationality: string
            email: string
            phoneNumber: string
            bvn: string
            percentageOwned: string
            dateOfBirth: string
            title: string
        }
    }[]
    proof_of_address: string
    certificate_of_business_name: string
    certificate_of_incorporation: string
    memorandum_of_association: string
    cac_it_form: string
    cac_bn1: string
    scuml_certificate: string
}

export const officersEmptyState = {
    fullName: { firstName: '', lastName: '' },
    data: {
        role: '',
        bvn: '',
        email: '',
        nationality: '',
        title: '',
        phoneNumber: '',

        percentageOwned: '',
        dateOfBirth: '',
    },

    address: {
        country: '',
        state: '',
        city: '',
        lga: '',
        addressLine_1: '',
        postalCode: '',
    },
}
