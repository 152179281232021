import { Button } from '@/components'
import { Dispatch, SetStateAction } from 'react'

export default function KYCSuccessModal({ setShowKYCModal }: { setShowKYCModal: Dispatch<SetStateAction<boolean>> }) {
    return (
        <section className='grid place-items-center   '>
            <h1 className='text-3xl font-semibold'>KYC completed!</h1>
            <p className='text-[#5E5E5E] w-[60%] text-center my-8'>
                Congratulations! Your KYC process is complete. Thank you for verifying your identity successfully.
            </p>
            <Button onClick={() => setShowKYCModal(false)}>Go to my dashboard</Button>
        </section>
    )
}
