import { formatAmount } from '@/utils/money'

export type BenefitForm = {
    category: string
    vendor: string
    selectedPlan: string
    split_payment_company: string
    agree_terms: boolean
    // split_payment_user: string
}

export const calculatePercentageAmountInNaira = (planAmount: string | number, company: string) => {
    const amount = Number(planAmount)
    const calculatedCompanyAmount = (Number(company?.replaceAll('%', '')) / 100) * amount

    return {
        company: formatAmount(calculatedCompanyAmount),
        user: formatAmount(amount - calculatedCompanyAmount),
    }
}

export const convertToPercentage = (amount: string | number) => {
    return (Number(amount) / 100).toString()
}
