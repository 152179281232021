import { VaultItem } from '@/services/vault/types'
import VaultListTable from '../VaultListTable'
import { FolderContainer } from '../Folders/FolderContainer'
import { FileContainer } from '../Files/FileContainer'
import { EmptyFolder } from '@/assets/assets.export'
import { useGetVaultTrash } from '@/services/vault/queries'

type TrashType = {
    listStyle: boolean
    items: VaultItem[]
}

export const TrashView = ({ listStyle }: TrashType) => {
    const { data: trashItems } = useGetVaultTrash({})
    return (
        <div>
            {listStyle && <VaultListTable data={trashItems as VaultItem[]} />}
            {!listStyle && (
                <>
                    <div className='grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-y-3 lg:gap-y-8 gap-x-4 2xl:gap-y-5'>
                        {trashItems
                            ?.filter(item => item.type === 'folder')
                            ?.map(folder => <FolderContainer key={folder?.id} folder={folder} />)}
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-4'>
                        {trashItems
                            ?.filter(item => item.type === 'file')
                            ?.map(file => <FileContainer key={file.id} file={file} />)}
                    </div>
                </>
            )}
            {!listStyle && trashItems?.length === 0 && (
                <div className='w-full flex h-full items-center justify-center flex-col col-span-4 mt-16'>
                    <EmptyFolder className='mb-8' />
                    <p className='text-[20px] text-gray7 font-medium mb-5'>No file or folder moved to trash yet</p>
                </div>
            )}
        </div>
    )
}
