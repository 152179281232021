import { Button } from '@/components'
import { useAuthContext, useImpersonationContext } from '@/context'
import { queryClient } from '@/queryClient'
import { useRevokeImitation } from '@/services/imitating-users/mutations'
import React from 'react'
import ModalWrapper from '../ModalWrapper'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function RevokeImpersonation({ closeModal }: Props) {
    const { loginUser } = useAuthContext()
    const { setImpersonationMode, user } = useImpersonationContext()
    const { mutate: revokeImitationFn, isLoading } = useRevokeImitation({
        onSuccess: data => {
            closeModal(false)
            queryClient.invalidateQueries()
            setImpersonationMode(false)
            loginUser({ user: data.user, accounts: data.accounts, selectedAccount: null })
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <section className='w-[30rem]   relative p-5 overflow-y-scroll'>
                <div className=' text-[#5E5E5E] font-normal mb-4'>
                    By doing this, you would be leaving this org,
                    <span className='text-brand font-medium'> {user?.org?.org_name} </span>, to return back to your org{' '}
                    <span className='text-brand font-medium'>{'Bizpend'}</span>
                </div>

                <div className='flex pt-5 items-center justify-end gap-4 px-6 py-2   w-full rounded-b-lg mt-6'>
                    <Button
                        className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-base hover:bg-transparent min-w-[100px]'
                        onClick={() => {
                            closeModal(false)
                        }}
                    >
                        cancel
                    </Button>
                    <Button
                        type='submit'
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        loading={isLoading}
                        onClick={() => {
                            revokeImitationFn({})
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
