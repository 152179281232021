import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { states_and_lgas } from '@/utils/states_and_lgas'
import { Controller, useFormContext } from 'react-hook-form'
import { BizCompanyComplianceType } from '../utils'
import { ErrorText } from './ErrorText'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
}
export const AddressInfo = ({ setStep }: Props) => {
    const { control, watch, trigger } = useFormContext<BizCompanyComplianceType>()
    const watchCountry = watch('main.country')
    const watchState = watch('main.state')
    const watchLGA = watch('main.lga')
    const watchRegCountry = watch('registered.country')
    const watchRegState = watch('registered.state')
    const watchRegLGA = watch('registered.lga')
    const watchHasRegisteredAddress = watch('hasRegisteredAddress')

    const handleNext = async () => {
        let isValid
        if (!watchHasRegisteredAddress) {
            isValid = await trigger([
                'main.country',
                'main.state',
                'main.lga',
                'main.city',
                'main.postalCode',
                'main.addressLine_1',
            ])
        }
        if (watchHasRegisteredAddress) {
            isValid = await trigger([
                'main.country',
                'main.state',
                'main.lga',
                'main.city',
                'main.postalCode',
                'main.addressLine_1',
                'registered.country',
                'registered.state',
                'registered.lga',
                'registered.city',
                'registered.postalCode',
                'registered.addressLine_1',
            ])
        }
        if (isValid) {
            setStep(prev => prev + 1)
        }
    }
    return (
        <>
            <section>
                <div className='flex justify-between border-b border-gray5 pb-2 items-center'>
                    <h3 className='font-medium text-md'>Address Information</h3>
                </div>
                <h3 className='font-medium text-md mt-3 mb-1'>Main address</h3>
                <div className='grid grid-cols-1 md:grid-cols-2  gap-x-10 gap-5 gap-y-5 mt-4'>
                    <Controller
                        name='main.country'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label htmlFor={'main.country'} className='text-gray7 font-normal text-sm mb-1'>
                                    Country
                                </label>
                                <Select {...field} name={'main.country'} onValueChange={field.onChange}>
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchCountry && <span className='text-gray1'>Select Country</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        <SelectItem value={'NG'}>Nigeria</SelectItem>
                                    </SelectContent>
                                </Select>
                                {errors?.main?.country && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name='main.state'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label htmlFor={'main.state'} className='text-gray7 font-normal text-sm mb-1'>
                                    State
                                </label>
                                <Select {...field} name={'main.state'} onValueChange={field.onChange}>
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchState && <span className='text-gray1'>Select State</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        {states_and_lgas.map(state => (
                                            <SelectItem value={state.alias}>{state.state}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                {errors?.main?.state && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name='main.lga'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label htmlFor={'main.lga'} className='text-gray7 font-normal text-sm mb-1'>
                                    LGA
                                </label>
                                <Select
                                    {...field}
                                    name={'main.lga'}
                                    onValueChange={field.onChange}
                                    disabled={!watchState}
                                >
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchLGA && <span className='text-gray1'>Select LGA</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        {states_and_lgas
                                            .filter(state => state.alias === watchState)[0]
                                            ?.lgas.map(lga => <SelectItem value={lga}>{lga}</SelectItem>)}
                                    </SelectContent>
                                </Select>
                                {errors?.main?.lga && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name='main.city'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label htmlFor={'main.city'} className='text-gray7 font-normal text-sm mb-1'>
                                    City
                                </label>
                                <Input
                                    {...field}
                                    placeholder='Enter closest landmark'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {errors?.main?.city && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name='main.postalCode'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label htmlFor={'main.postalCode'} className='text-gray7 font-normal text-sm mb-1'>
                                    Postal code
                                </label>
                                <Input
                                    {...field}
                                    placeholder='Enter postal code'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {errors?.main?.postalCode && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name='main.addressLine_1'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label htmlFor={'main.addressLine_1'} className='text-gray7 font-normal text-sm mb-1'>
                                    Street address
                                </label>
                                <Input
                                    {...field}
                                    placeholder='Enter address'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {errors?.main?.addressLine_1 && <ErrorText />}
                            </div>
                        )}
                    />
                </div>
                <div className={`flex gap-2 item-center text-gray7 mt-3 col-span-2`}>
                    <Controller
                        name='hasRegisteredAddress'
                        control={control}
                        render={({ field }) => (
                            <input
                                type='checkbox'
                                name='hasRegisteredAddress'
                                id='hasRegisteredAddress'
                                className='accent-brand cursor-pointer'
                                onChange={e => field.onChange(e.target.checked)}
                                checked={watchHasRegisteredAddress}
                            />
                        )}
                    />
                    <label htmlFor='hasRegisteredAddress' className='text-sm font-medium cursor-pointer'>
                        Main Address is different from Registered Address
                    </label>
                </div>

                {watchHasRegisteredAddress && (
                    <>
                        <h3 className='font-medium text-md mt-10 mb-1'>Registered address</h3>
                        <div className='grid grid-cols-1 md:grid-cols-2  gap-x-10 gap-5 gap-y-5 mt-4'>
                            <Controller
                                name='registered.country'
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field, formState: { errors } }) => (
                                    <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                        <label htmlFor={'country'} className='text-gray7 font-normal text-sm mb-1'>
                                            Country
                                        </label>
                                        <Select {...field} name={'registered.country'} onValueChange={field.onChange}>
                                            <SelectTrigger className='h-12 border-gray5 text-sm'>
                                                {!watchRegCountry && <span className='text-gray1'>Select Country</span>}
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                                <SelectItem value={'nigeria'}>Nigeria</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        {errors?.registered?.country && <ErrorText />}
                                    </div>
                                )}
                            />
                            <Controller
                                name='registered.state'
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field, formState: { errors } }) => (
                                    <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                        <label
                                            htmlFor={'registered.state'}
                                            className='text-gray7 font-normal text-sm mb-1'
                                        >
                                            State
                                        </label>
                                        <Select {...field} name={'registered.state'} onValueChange={field.onChange}>
                                            <SelectTrigger className='h-12 border-gray5 text-sm'>
                                                {!watchRegState && <span className='text-gray1'>Select State</span>}
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                                {states_and_lgas.map(state => (
                                                    <SelectItem value={state.alias}>{state.state}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        {errors?.registered?.state && <ErrorText />}
                                    </div>
                                )}
                            />
                            <Controller
                                name='registered.lga'
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field, formState: { errors } }) => (
                                    <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                        <label
                                            htmlFor={'registered.lga'}
                                            className='text-gray7 font-normal text-sm mb-1'
                                        >
                                            LGA
                                        </label>
                                        <Select
                                            {...field}
                                            name={'registered.lga'}
                                            onValueChange={field.onChange}
                                            disabled={!watchState}
                                        >
                                            <SelectTrigger className='h-12 border-gray5 text-sm'>
                                                {!watchRegLGA && <span className='text-gray1'>Select LGA</span>}
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                                {states_and_lgas
                                                    .filter(state => state.alias === watchState)[0]
                                                    ?.lgas.map(lga => <SelectItem value={lga}>{lga}</SelectItem>)}
                                            </SelectContent>
                                        </Select>
                                        {errors?.registered?.lga && <ErrorText />}
                                    </div>
                                )}
                            />
                            <Controller
                                name='registered.city'
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field, formState: { errors } }) => (
                                    <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                        <label
                                            htmlFor={'registered.city'}
                                            className='text-gray7 font-normal text-sm mb-1'
                                        >
                                            City
                                        </label>
                                        <Input
                                            {...field}
                                            placeholder='Enter closest landmark'
                                            className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                        />
                                        {errors?.registered?.city && <ErrorText />}
                                    </div>
                                )}
                            />
                            <Controller
                                name='registered.postalCode'
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field, formState: { errors } }) => (
                                    <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                        <label
                                            htmlFor={'registered.postalCode'}
                                            className='text-gray7 font-normal text-sm mb-1'
                                        >
                                            Postal code
                                        </label>
                                        <Input
                                            {...field}
                                            placeholder='Enter postal code'
                                            className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                        />
                                        {errors?.registered?.postalCode && <ErrorText />}
                                    </div>
                                )}
                            />
                            <Controller
                                name='registered.addressLine_1'
                                rules={{
                                    required: true,
                                }}
                                control={control}
                                render={({ field, formState: { errors } }) => (
                                    <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                        <label
                                            htmlFor={'registered.addressLine_1'}
                                            className='text-gray7 font-normal text-sm mb-1'
                                        >
                                            Street address
                                        </label>
                                        <Input
                                            {...field}
                                            placeholder='Enter address'
                                            className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                        />
                                        {errors?.registered?.addressLine_1 && <ErrorText />}
                                    </div>
                                )}
                            />
                        </div>
                    </>
                )}
            </section>
            <div className='flex items-center justify-end gap-6 mt-10'>
                <Button
                    variant={'outline'}
                    className='min-w-[120px]  whitespace-nowrap '
                    onClick={() => setStep(prev => prev - 1)}
                >
                    Back
                </Button>
                <Button onClick={handleNext} className=' whitespace-nowrap min-w-[130px]'>
                    Next
                </Button>
            </div>
        </>
    )
}
