import { useVendorContext } from '@/context'
import { format } from 'date-fns'
import { X } from 'lucide-react'
import { motion } from 'framer-motion'
import { CategoryType } from '@/types/categories'

const key_types = {
    period: 'period',
    amount: 'amount',
    status: 'status',
    category: 'category',
}

type Props = { categories: CategoryType[] }

export default function FilterVendorChip({ categories }: Props) {
    const { filterFormState, setFilterFormState } = useVendorContext()

    const { period, amount } = filterFormState

    const getFilteredDetails = () => {
        const data = Object.keys(filterFormState).map(key => {
            const value = filterFormState[key as keyof typeof filterFormState]

            if (key === key_types.period) {
                return {
                    [key]:
                        period.from || period.to
                            ? `${format(new Date(period?.from as unknown as Date), 'do MMM')} -
                                  ${format(period?.to ? new Date(period?.to as unknown as Date) : new Date(), 'do MMM')}

                              )}`
                            : period.time_in_words
                              ? `${format(new Date(period.time_in_words as unknown as Date), 'do MMM')} - ${format(new Date(), 'do MMM')}`
                              : '',
                }
            }
            if (key === key_types.amount) {
                return {
                    [key]:
                        amount.min || amount.max
                            ? `₦${amount.min}- ₦${amount.max}`
                            : amount.amount_in_words
                              ? amount.amount_in_words
                              : '',
                }
            }
            if (key === key_types.category) {
                return { [key]: categories?.find(category => category.id === value)?.label }
            }

            return { [key]: value as string }
        })
        return data
    }

    const handleDeleteChip = (key: string) => {
        setFilterFormState(filter => ({ ...filter, [key]: '', filter_now: true }))
    }

    return (
        <motion.section className='flex gap-5 flex-wrap'>
            {getFilteredDetails().map(details => (
                <>
                    {Object.keys(details).map((filter, idx) => {
                        const value = details[filter as keyof typeof details]
                        return (
                            value?.length !== 0 &&
                            value !== undefined &&
                            typeof value !== 'boolean' && (
                                <motion.button
                                    type='button'
                                    className='cursor-pointer flex gap-4 px-3 bg-[#EFEFF9] text-[#454ADE] min-h-[20px] items-center justify-between py-2 rounded-md text-xs'
                                    layout='position'
                                    key={idx}
                                >
                                    <span className=' hover:bg-[#EFEFF9]'>
                                        <>{value}</>
                                    </span>
                                    <X className='w-4 h-4' onClick={() => handleDeleteChip(filter)} />
                                </motion.button>
                            )
                        )
                    })}
                </>
            ))}
        </motion.section>
    )
}
