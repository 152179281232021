import { useAuthContext } from '@/context'
import { ReactElement, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

const PrivateRouteWrapper = ({ component }: { component: ReactNode }): ReactElement => {
    const { loggedIn } = useAuthContext()

    return loggedIn ? <>{component}</> : <Navigate to='/login' replace />
}

export default PrivateRouteWrapper
