import { plans } from './NewBenefit2'
import { formatAmount } from '@/utils/money'
import { ProfileIcon } from '@/assets/assets.export'
import { BenefitForm } from './helper'
import { Control, Controller } from 'react-hook-form'

type Step5Props = {
    selectedPlan: string
    selectedMembers: {
        label: string
        value: string
    }[]
    control: Control<BenefitForm, any>
}

const NewBenefitStep5 = ({ selectedPlan, selectedMembers, control }: Step5Props) => {
    return (
        <div className='p-4 md:p-8 md:px-12'>
            <div className='mb-5'>
                <h1 className='text-xl font-semibold text-[#202020]'>Make payment</h1>
                <p className='text-sm md:text-base font-medium text-[#5E5E5E]'>
                    Payments will be made to your selected HMO vendor and an email to set up all beneficiaries.
                </p>
            </div>
            <div className='bg-[#F9F9F9] rounded-xl p-5  mb-8'>
                <div className='flex items-end justify-between mb-6'>
                    {plans
                        .filter(plan => plan.name.substring(7, 11) === selectedPlan)
                        .map(sPlan => (
                            <div className=''>
                                <h1 className='text-xl font-semibold text-[#202020] mb-3'>{sPlan.name}</h1>
                                <div className='bg-[#EFEFF9] rounded-full py-2 px-3 text-brand text-xs min-w-[210px]'>
                                    <span className='text-xl font-semibold'>
                                        {formatAmount(sPlan.price * selectedMembers.length)} / month
                                    </span>
                                </div>
                            </div>
                        ))}
                    <p className='font-semibold text-[#5E5E5E] flex gap-1'>
                        <ProfileIcon />
                        {selectedMembers.length}
                    </p>
                </div>
                <div className='bg-white rounded-xl p-5 '>
                    <h1 className='text-xl font-semibold text-[#202020] mb-3'>
                        {plans
                            .filter(plan => plan.name.substring(7, 11) === selectedPlan)
                            .map(sPlan => (
                                <h1 className='text-xl font-semibold text-[#202020] mb-9'>{sPlan.name}</h1>
                            ))}
                    </h1>
                    <div className='flex flex-wrap gap-5'>
                        {selectedMembers.map(member => (
                            <p className='bg-[#F2F2F2] px-3 py-1 rounded'>{member.label}</p>
                        ))}
                    </div>
                </div>
            </div>
            <div className='bg-[#F9F9F9] rounded-xl px-5 py-3'>
                <p className='mb-[10px] text-brand font-medium text-sm'>Note</p>
                <p className='text-xs text-[#5E5E5E] mb-6 leading-6'>
                    Upon payment of this plan, your payment will be securely transmitted to this HMO vendor. Details of
                    the selected beneficiaries like Name, email, phone number, address will also be sent to them for
                    easy onboarding.
                    <br /> Subsequently, the HMO vendor will initiate the onboarding process by sending an email
                    containing a quick onboarding link to all the designated beneficiaries. They can complete the
                    onboarding in just 5 minutes through the HMO vendor's platform.{' '}
                </p>
                <p className='text-xs text-[#5E5E5E] leading-6'>
                    We kindly request you to ensure that all beneficiaries complete the onboarding process at the
                    earliest to expedite access to HMO benefits.
                </p>
            </div>
            <div className={`flex gap-2 item-center mt-8`}>
                <Controller
                    name={'agree_terms'}
                    control={control}
                    render={({ field }) => (
                        <input
                            {...field}
                            type='checkbox'
                            name='agree_terms'
                            id='agree_terms'
                            className='accent-[#454ADE] cursor-pointer'
                            onChange={e => field.onChange(e.target.checked)}
                            checked={field.value}
                            value={field.value as unknown as string}
                        />
                    )}
                />
                <label htmlFor='agree_terms' className='text-sm font-medium cursor-pointer text-[#5E5E5E]'>
                    I agree with the terms of service.{' '}
                    <span className='text-[#202020]'>Download Reliance HMO’s terms of service.</span>
                </label>
            </div>
        </div>
    )
}

export default NewBenefitStep5
