import { ProfileEditIcon } from '@/assets/assets.export'
import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import StatusColor from '@/components/utils/StatusColor'
import { CiSearch } from 'react-icons/ci'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useGetOrgBusinessPlan, useGetOrgSuscription } from '@/services/billing/queries'
import { BusinessPlanBilling } from '@/types/billing'
import { formatAmount } from '@/utils/money'
import ProgressBar from '@/components/ProgressBar'
import CreateBusinessPlan from '@/components/Modals/Business-directory/CreateBusinessPlan'
import PaymentHistoryTable from '@/modules/dashboard/Settings/partials/PlansAndPricing/components/PaymentHistoryTable'
import { useGetOrgTransactions } from '@/services/transactions/queries'
import { TransactionType } from '@/types/transactions'

export default function BusinessSubscriptionInfo() {
    const [searchQuery, setSearchQuery] = useState('')
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)
    const [editPlan, setEditPlan] = useState(false)

    const { user_id: org_id } = useParams()
    const { isLoading, data } = useGetOrgBusinessPlan({
        queryParams: {
            org: org_id as string,
        },
    })

    const { data: orgTransaction, isLoading: isFetchingTransasctions } = useGetOrgTransactions({
        queryParams: {
            org_id: org_id as string,
            type: 'Subscription Payment',
            limit: '15',
        },
    })
    const { data: orgSubscription, isLoading: isGettingSub } = useGetOrgSuscription({
        queryParams: {
            org: org_id as string,
        },
    })

    const orgBusinessPlan = useMemo(() => data ?? ({} as BusinessPlanBilling), [data])

    const sub_info = [
        {
            name: 'Plan',
            desc: typeof orgBusinessPlan?.plan === 'string' ? orgBusinessPlan?.plan : orgBusinessPlan?.plan?.name,
        },
        {
            name: 'Price',
            desc: formatAmount(orgBusinessPlan?.amount),
        },
        {
            name: 'Pay frequency',
            desc: 'monthly',
        },
        {
            name: 'Last payment',
            desc: orgSubscription?.start_date && format(new Date(orgSubscription?.start_date), 'dd/MM/yyyy hh:mm aa'),
        },
        {
            name: 'Next payment',
            desc:
                orgSubscription?.next_renewal_date &&
                format(new Date(orgSubscription?.next_renewal_date), 'dd/MM/yyyy hh:mm aa'),
        },
        {
            name: 'Date onboarded',
            desc: orgBusinessPlan?.createdAt && format(new Date(orgBusinessPlan?.createdAt), 'dd/MM/yyyy hh:mm aa'),
        },
        {
            name: 'Status',
            desc: <StatusColor disableBgColor status={orgSubscription?.status as string} />,
        },
    ]

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
        console.group({ searchQuery, backspace, backspaceClicked })
    }

    if (isLoading || isGettingSub || isFetchingTransasctions) return <ProgressBar />
    return (
        <section className='mb-5'>
            <section className='flex-1 bg-white rounded-xl p-5 shadow-sm '>
                <div className='flex justify-between border-b border-gray5 pb-2 items-center'>
                    <h3 className='font-medium text-md'>Subscription information</h3>

                    <span
                        onClick={() => setEditPlan(true)}
                        className='flex items-center gap-2 text-[#5E5E5E]  rounded-md cursor-pointer text-sm'
                    >
                        <ProfileEditIcon className='w-4 h-4' />
                        <button type='button'>Edit</button>
                    </span>
                </div>

                <div className=' mt-5'>
                    <div className='flex flex-wrap gap-x-[4.5rem] gap-5 gap-y-7'>
                        {sub_info?.map(data => {
                            return (
                                <div className='flex flex-col flex-wrap    '>
                                    <span className='text-[#838383] font-normal text-sm'>{data.name}</span>
                                    <div className='flex gap-3 items-center mt-2 truncate'>
                                        <span className='font-medium text-[.9rem] truncate max-w-[12rem]'>
                                            {data.desc}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className='flex-1 bg-white rounded-xl p-5 shadow-sm mt-5'>
                <div className='flex justify-between items-center'>
                    <div className='flex flex-col flex-wrap'>
                        <span className='text-[#838383] font-normal text-sm'>Total amount paid</span>
                        <span className='font-medium text-base truncate  mt-1'>N/A</span>
                    </div>
                    <Select name='sub_time' defaultValue='all'>
                        <SelectTrigger className='w-28 h-fit border-[#DADCE0] bg-base_gray_100 text-xs'>
                            <SelectValue placeholder={''} />
                        </SelectTrigger>
                        <SelectContent className='w-fit '>
                            <SelectItem value='all'>All time</SelectItem>
                            <SelectItem value='3'>3 months</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                {/*  */}
                <h3 className='font-medium text-base border-b border-gray5 mt-10 mb-3 pb-2'>Subscription history</h3>
                <div className='my-6'>
                    <Input
                        leftIcon={<CiSearch />}
                        className='w-72 h-10 border-gray5'
                        type='text'
                        placeholder='Search by transaction ID, name'
                        onChange={e => setSearchQuery(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div className='overflow-x-scroll'>
                    <PaymentHistoryTable payments={orgTransaction?.results as TransactionType[]} />
                </div>
            </section>
            {editPlan && (
                <CreateBusinessPlan closeModal={() => setEditPlan(false)} planDetails={orgBusinessPlan} edit={true} />
            )}
        </section>
    )
}
