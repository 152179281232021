import CompleteKYBModal from '@/components/Modals/Accounts/CompleteKYBModal'
import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import { useAuthContext } from '@/context'
import PageContent from '@/layout/PageContent'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import BizpendAccounts from './partials/BizpendAccounts'
import LinkedAccounts from './partials/LinkedAccounts'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { ADMIN_ACCOUNTS_PERMISSIONS } from '@/utils/permisson-helper'
import NotAuthorized from '@/layout/NotAuthorized'

const route_const = {
    bizpend: 'bizpend_accounts',
    linked: 'linked_accounts',
}
export default function Accounts() {
    const { accountsSubRoute } = useParams()
    const navigate = useNavigate()
    const { selectedAccount } = useAuthContext()

    const permission = useHasAccessPermisson(ADMIN_ACCOUNTS_PERMISSIONS)
    const currentRoute = location.pathname.split('/').slice(0, -1).join('/')

    if (!permission) return <NotAuthorized />
    return (
        <section className='my-4'>
            <Tabs defaultValue={accountsSubRoute} value={accountsSubRoute}>
                <TabsList className='border-none flex justify-between mx-auto overflow-scroll items-center'>
                    <TabTriggersContainer className='mx-1 lg:mx-auto'>
                        <TabsTrigger
                            value={route_const.bizpend}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.bizpend}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.bizpend === accountsSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.bizpend === accountsSubRoute && (
                                <motion.div
                                    layoutId='account'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Bizpend Accounts
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={route_const.linked}
                            onClick={() => {
                                navigate(`${currentRoute}/${route_const.linked}`)
                            }}
                            className={`relative border-none px-3 ${
                                route_const.linked === accountsSubRoute ? '!text-white' : ''
                            } text-sm`}
                        >
                            {route_const.linked === accountsSubRoute && (
                                <motion.div
                                    layoutId='account'
                                    className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                                />
                            )}
                            <motion.span layout='position' className='z-20 relative'>
                                Linked accounts
                            </motion.span>
                        </TabsTrigger>
                    </TabTriggersContainer>
                </TabsList>
                <TabsContent value={route_const.bizpend}>
                    <PageContent>
                        <BizpendAccounts />
                    </PageContent>
                </TabsContent>
                <TabsContent value={route_const.linked}>
                    <PageContent>
                        <LinkedAccounts />
                    </PageContent>
                </TabsContent>
            </Tabs>

            {!selectedAccount?.org?.kyb_completed && <CompleteKYBModal />}
        </section>
    )
}
