import React from 'react'
import { ColumnDef, ColumnSort, createColumnHelper } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
// import CheckBox from '@/components/Table/CheckBox'
import filter from '@/assets/filter.svg'
import { AiOutlineEdit } from 'react-icons/ai'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { OrgType } from '@/types/org'
import { useEmployeesContext, useImpersonationContext } from '@/context'
import { useNavigate } from 'react-router-dom'

interface OrgTypeProps {
    orgs: OrgType[]
}

const BusinessDirectoryTable = ({ orgs }: OrgTypeProps) => {
    const { filterOrgMembersBy, setFilterOrgMembersBy, setSort } = useEmployeesContext()
    const navigate = useNavigate()

    const { setOrgDetails } = useImpersonationContext()

    const [rowSelection, setRowSelection] = React.useState({})
    const handleMoreDetails = (orgDetails: OrgType) => {
        navigate(`${orgDetails?._id ?? orgDetails?.id}/summary`)
        setOrgDetails(orgDetails)
    }

    const handleSort = (sort: ColumnSort) => {
        if (sort !== filterOrgMembersBy?.bySort) {
            setFilterOrgMembersBy(prev => ({ ...prev, bySort: sort }))
            setSort(sort)
        }
    }

    const columnHelper = createColumnHelper<OrgType>()

    const columns: ColumnDef<OrgType, any>[] = [
        columnHelper.accessor('org_name', {
            header: () => <>Org name</>,
            cell: cell => capitalizeText(cell.renderValue()),
            enableSorting: true,
        }),
        columnHelper.accessor('employees', {
            header: () => <>Users </>,
            cell: cell => <div>{cell.renderValue()}</div>,
            enableSorting: true,
        }),
        columnHelper.accessor('email', {
            header: () => <p>Company mail</p>,
            cell: cell => cell.renderValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('plan', {
            header: () => <>Plan</>,
            cell: cell => cell.renderValue() ?? 'N/A',
            enableSorting: true,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <>Date created</>,
            cell: cell => format(new Date(cell.renderValue()!), 'MMM dd, yyyy'),
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div className='h-[30px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1 cursor-pointer'>
                        <img src={filter} alt='button' />
                    </div>
                </div>
            ),
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                icon: AiOutlineEdit,
                                method: () => {
                                    handleMoreDetails(row?.row.original)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    return (
        <main>
            <Table
                data={orgs}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleMoreDetails}
                rowClickable={true}
                handleSort={handleSort}
            />
        </main>
    )
}

export default BusinessDirectoryTable
