import { Button, Input, Textarea } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useUpdateBudget } from '@/services/budget/mutations'
import { Budget } from '@/types/budget'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { ChevronDown } from 'lucide-react'
import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { TbCurrencyNaira } from 'react-icons/tb'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    setSteps: React.Dispatch<React.SetStateAction<number>>
    selectedBudget: Budget | undefined
    setSelectedBudget?: React.Dispatch<React.SetStateAction<Budget | undefined>>
}
type fieldNames = 'amount' | 'purpose'

export default function RequestBudgetIncrease({ closeModal, setSteps, selectedBudget, setSelectedBudget }: Props) {
    const [fieldDetails, setFieldDetails] = useState<Partial<Record<fieldNames, string>>>({})

    const { mutate: updateBudgetFn, isLoading } = useUpdateBudget({
        queryParams: {
            budget_id: selectedBudget?._id as string,
        },
        onSuccess: () => {
            setSteps(1)
            toastHandler({ message: 'Budget Increased', state: 'success' })
            setSelectedBudget?.(undefined)
        },
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value, name } = e.target
        if (name === 'amount') {
            let enteredValue = formatAmountWithoutSymbols(e.target.value)
            enteredValue = enteredValue.replaceAll(/\D/g, '')
            setFieldDetails(prev => ({ ...prev, [name]: enteredValue }))
            return
        }
        setFieldDetails(prev => ({ ...prev, [name]: value }))
    }

    const handleSendRequest = () => {
        updateBudgetFn({
            allocated_amount: Number(fieldDetails?.amount) / 100,
        })
    }
    return (
        <section className='w-[35rem]'>
            <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                <div className='flex items-center w-[66%] justify-between h-full'>
                    <h2 className='font-semibold text-[#31254B] text-xl'>Increase budget</h2>
                    <div
                        className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer mr-8'
                        onClick={() => closeModal(false)}
                    >
                        <AiOutlineClose fontSize={25} />
                    </div>
                </div>
            </div>

            <div className='px-7 mb-10'>
                <div className='w-full mt-10'>
                    <label htmlFor='budget' className='text-sm'>
                        {' '}
                        Select budget *
                    </label>
                    <div className='flex justify-between items-center border-[#DADCE0] bg-[#F9F9F9] text-gray1 border mt-3 p-3 rounded-md'>
                        {selectedBudget?.name} (Balance: {formatAmount(selectedBudget?.available_amount)})
                        <ChevronDown />
                    </div>
                </div>
                <div className='w-full mt-5'>
                    <label htmlFor='budget' className='text-sm'>
                        {' '}
                        Amount *
                    </label>
                    <div className='flex items-center border-[#DADCE0] border mt-2  rounded-md'>
                        <span className='flex items-center justify-center text-[#838383] ml-2'>
                            <TbCurrencyNaira fontSize={30} />
                        </span>
                        <Input
                            value={formatAmount(fieldDetails?.amount).replaceAll('₦', '')}
                            name='amount'
                            className='h-[3.5rem] border-none'
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='w-full mt-7'>
                    <label htmlFor='budget' className='text-sm flex justify-between'>
                        {' '}
                        Purpose *<span>{fieldDetails?.purpose?.length ?? '0'}/120</span>
                    </label>
                    <Textarea
                        name='purpose'
                        maxLength={120}
                        cols={10}
                        rows={5}
                        className='mt-2 h-[3.5rem] border-[#DADCE0]'
                        onChange={handleChange}
                        value={fieldDetails?.purpose}
                    />
                </div>
            </div>
            <div className='flex justify-end mx-6 mb-10 gap-5 mt-16'>
                <Button className='bg-transparent hover:bg-transparent min-w-[90px] text-[black] '>Cancel</Button>
                <Button onClick={handleSendRequest} loading={isLoading}>
                    Increase Budget
                </Button>
            </div>
        </section>
    )
}
