import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { accountSidebarPermissions } from '@/layout/LayoutComponents/utils'
import NotAuthorized from '@/layout/NotAuthorized'
import Accounts from '@/modules/dashboard/Accounts/Accounts'
import AccountDetailsPage from '@/modules/dashboard/Accounts/partials/AccountsDetailsPage'
import { Route } from 'react-router-dom'

export const accountsRoutes = {
    baseUrl: 'accounts',
    subRoute: 'accounts/:accountsSubRoute',
    accountsDetails: ':accountId',
}
export const useAccountsRoutes = () => {
    const hasPermission = useHasAccessPermisson(accountSidebarPermissions)

    if (!hasPermission) return <Route path='not-authorized' element={<NotAuthorized />} />
    return (
        <Route path={accountsRoutes.subRoute}>
            <Route index element={<Accounts />} />
            <Route path={accountsRoutes.accountsDetails} element={<AccountDetailsPage />} />
            accountId
        </Route>
    )
}
