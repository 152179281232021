import React, { useMemo, useState } from 'react'
import { UploadInvoiceBill } from './UploadInvoiceBill'
import { fileEmptyState, FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { CreateBill } from './CreateBill'
import { BillType } from '@/services/bill-payment/types'
import CreateNewVendor from '../Vendors/CreateNewVendor/CreateNewVendor'
import { useAuthContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetOrgCategories } from '@/services/categories/queries'
import { useGetListOfBanks } from '@/services/payout/queries'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    setUploadBillModal: boolean
}

export const CreateBillFlow = ({ closeModal, setUploadBillModal }: Props) => {
    const [docFile, setDocFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [scannedBill, setScannedBill] = useState<BillType>()
    const [addVendor, setAddVendor] = useState(false)
    const { selectedAccount } = useAuthContext()

    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])

    const { data: org_categories } = useGetOrgCategories()
    const categories = useMemo(() => org_categories ?? [], [org_categories])

    const { data: bank_details } = useGetListOfBanks()
    const allBankList = useMemo(() => bank_details ?? [], [bank_details])

    return (
        <div>
            {setUploadBillModal && (
                <UploadInvoiceBill
                    closeModal={closeModal}
                    docFile={docFile}
                    setDocFile={setDocFile}
                    setShowCreateModal={setShowCreateModal}
                    setScannedBill={setScannedBill}
                />
            )}
            {showCreateModal && (
                <CreateBill
                    bill={scannedBill as BillType}
                    closeModal={setShowCreateModal}
                    docFile={docFile}
                    setDocFile={setDocFile}
                    setAddVendor={setAddVendor}
                />
            )}
            {addVendor && (
                <CreateNewVendor
                    setCloseModal={setAddVendor}
                    orgMembers={orgMembers}
                    categories={categories}
                    allBankList={allBankList}
                />
            )}
        </div>
    )
}
