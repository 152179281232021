import { CancelIcon, SendReminder } from '@/assets/assets.export'
import { toastHandler } from '@/components/utils/Toast'
import { useSendReminderToAddBank } from '@/services/payout/mutations'
import { PayrollListType } from '@/services/payroll/types'
import { motion } from 'framer-motion'
import React, { useRef } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { modalVariants } from '../../Transactions/utils'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    employeesPayrollData: PayrollListType['list']
    modalOpen: boolean
}

type subProps = {
    name: string
    id: string
    sendReminderFn: () => void
}
export default function BanksNotLinked({ closeModal, employeesPayrollData }: Props) {
    const ref = useRef<HTMLDivElement>(null)

    const { mutate: sendReminderFn, isLoading } = useSendReminderToAddBank({
        onSuccess: () => {
            toastHandler({ message: 'Reminder Sent', state: 'success' })
        },
    })
    return (
        <ModalWrapper variants={modalVariants} className='justify-end right-8'>
            <motion.section className='h-[100vh] overflow-scroll w-[30rem]' ref={ref}>
                <motion.div
                    variants={{
                        open: {
                            opacity: 100,
                        },
                        closed: {
                            opacity: 0,
                        },
                    }}
                    className='flex items-center my-4 border-b border-[#DADCE0] w-full pb-2'
                >
                    <h2 className='flex-1 text-center font-semibold text-xl'>No linked bank account</h2>
                    <CancelIcon
                        className='w-9 h-9 bg-[#F2F2F2] rounded-full p-2 inline-block mr-3 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </motion.div>
                <motion.ul
                    className=' mt-12 font-medium flex flex-col gap-6'
                    variants={{
                        open: {
                            clipPath: 'inset(0% 0% 0% 0% round 10px)',
                            transition: {
                                type: 'spring',
                                bounce: 0,
                                duration: 0.7,
                                delayChildren: 0.3,
                                staggerChildren: 0.05,
                            },
                        },
                        closed: {
                            clipPath: 'inset(10% 50% 90% 50% round 10px)',
                            transition: {
                                type: 'spring',
                                bounce: 0,
                                duration: 0.3,
                            },
                        },
                    }}
                >
                    {employeesPayrollData
                        ?.filter(payroll => !payroll?.bank?.is_primary)
                        ?.map(payroll => (
                            <motion.li
                                variants={{
                                    open: {
                                        opacity: 1,
                                        y: 4,
                                        x: 0,
                                        transition: { type: 'spring', stiffness: 300, damping: 0 },
                                    },
                                    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
                                }}
                            >
                                <div className={`${isLoading ? 'opacity-40' : 'opacity-100'}`}>
                                    <ListBankCards
                                        name={`${payroll?.account.user.first_name} ${payroll?.account.user.last_name}`}
                                        id={payroll?.account?.id}
                                        sendReminderFn={() => {
                                            if (isLoading) return
                                            sendReminderFn({ account: payroll?.account.id })
                                        }}
                                    />
                                </div>
                            </motion.li>
                        ))}
                </motion.ul>
            </motion.section>
        </ModalWrapper>
    )
}

const ListBankCards = ({ name, sendReminderFn }: subProps) => {
    return (
        <div className='border-b pb-3 border-slate-300'>
            <div className='flex justify-between items-center  mx-6'>
                <h2 className='w-[35%] truncate'>{name}</h2>
                <span className='inline-block h-10 w-[1px] bg-slate-200'></span>
                <div className='flex items-center gap-3 text-sm cursor-pointer' onClick={() => sendReminderFn()}>
                    <SendReminder />
                    Send Reminder
                </div>
            </div>
        </div>
    )
}
