import { OrgBenefitProps } from '@/types/benefits'
import { createQuery } from '../api/query'
import { QueryOrgBenefitsCategories, QueryOrgBenefitsVendors } from './types'

export const orgBenefitCategories = ['get-benefit-categories']
export const orgBenefitVendors = ['get-benefit-vendors']
export const orgBenefits = ['get-benefit-for-org']
export const orgBenefitsByID = (arg: string) => ['get-benefit-for-org', arg]

export const useGetBenefitCategories = createQuery<QueryOrgBenefitsCategories>({
    url: '/benefit-categories',
    key: orgBenefitCategories,
})

export const useGetBenefitVendors = createQuery<QueryOrgBenefitsVendors>({
    url: '/benefit-vendors',
    key: orgBenefitVendors,
})
export const useGetOrgBenefits = createQuery<OrgBenefitProps[]>({
    url: 'get-benefits-for-org',
    key: orgBenefits,
})
export const useGetOrgBenefitsById = createQuery<OrgBenefitProps>({
    url: 'get-benefit-by-id',
    key: orgBenefitsByID,
})
