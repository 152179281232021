import { ProfileEditIcon, TrashIcon } from '@/assets/assets.export'
import bizpendlogo from '@/assets/bizpend.svg'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import AddNewItemModal from '@/components/Modals/Invoice/AddNewItemModal'
import CreateNewCustomer from '@/components/Modals/Vendors/CreateNewCustomer/CreateNewCustomer'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, useCurrencyContext } from '@/context'
import useSingleFileUpload from '@/hooks/useSingleFileUpload'
import { useGetInvoiceItem } from '@/services/invoice/queries'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { PlusSquare } from 'lucide-react'
import React, { useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { v4 as uuidv4 } from 'uuid'
import { AddItemProps, itemDefault } from './utils'
import { useUploadCompanyLogo } from '@/services/auth/mutations'
import { CreateInvoiceProps } from './CreateInvoiceForm'
import { InvoiceItemType } from '@/types/invoice'

export const InvoiceDetailsForm = ({
    setFormState,
    formState,
    orgCustomers,
    setLogoFile,
    logoFile,
    sub_total,
}: CreateInvoiceProps) => {
    const { selectedCurrencyInfo } = useCurrencyContext()
    const currencySymbol = selectedCurrencyInfo?.(formState?.currency)?.symbol
    const [openSelectContainer, setOpenSelectContainer] = useState(false)
    const [addCustomer, setAddCustomer] = useState(false)
    const [editItem, setEditItem] = useState(false)
    const [openAddItem, setOpenAddItem] = useState(false)
    const [editItemProps, setEditItemProps] = useState<AddItemProps>()

    const calendarRef1 = useRef<DatePicker>(null)
    const calendarRef2 = useRef<DatePicker>(null)
    const logo = useRef<HTMLInputElement>(null)
    const { selectedAccount } = useAuthContext()

    const { mutate: uploadLogoFn, isLoading: isUploading } = useUploadCompanyLogo({
        onSuccess: () => {
            toastHandler({ message: 'upload successful', state: 'success' })
        },
    })
    const { handleFile: uploadLogoDocFn } = useSingleFileUpload({
        setFileUpload: setLogoFile,
    })

    const upLoadLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formdata = new FormData()
        formdata.append('file', e.target.files?.[0] ?? '')
        uploadLogoFn(formdata)
    }

    const { data, isLoading } = useGetInvoiceItem({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })

    const allOrgInvoiceItem = useMemo(() => data?.results ?? ([] as AddItemProps[]), [data])

    return (
        <div className='border border-[#DADCE0] rounded-xl p-4  w-full overflow-y-scroll'>
            <div className='flex lg:items-stretch flex-col md:flex-row gap-5'>
                <div className='w-full lg:w-[55%]'>
                    <div className={`flex flex-col gap-x-5 gap-y-5`}>
                        <div className='flex flex-col gap-3'>
                            <label className='flex items-center gap-1 text-sm'>
                                Customer <span className='text-[red]'>*</span>
                            </label>
                            <Select
                                name='customer'
                                onValueChange={value => {
                                    setFormState(prevState => ({
                                        ...prevState,
                                        ['customer']: value,
                                    }))
                                }}
                                value={formState.customer}
                                onOpenChange={setOpenSelectContainer}
                                open={openSelectContainer}
                            >
                                <SelectTrigger
                                    className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px] w-full'
                                    id='customer'
                                >
                                    {formState?.customer ? <SelectValue /> : <span>Select customer</span>}
                                </SelectTrigger>
                                <SelectContent className='pb-3 max-h-[220px] overflow-scroll' id={`customer_content`}>
                                    <button
                                        className='flex items-center gap-1 pl-2 py-1.5 hover:bg-[#EFEFF9] w-full text-sm'
                                        onClick={() => {
                                            setAddCustomer(true)
                                            setOpenSelectContainer(false)
                                        }}
                                    >
                                        <PlusSquare size={15} /> Add new customer
                                    </button>

                                    {orgCustomers?.map(customer => (
                                        <SelectItem value={customer?.id}>{customer?.name}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className='flex flex-col md:flex-row gap-5'>
                            <div className='flex flex-col gap-3 lg:w-1/2'>
                                <label className='flex items-center gap-1 text-sm'>
                                    Issue Date <span className='text-[red]'>*</span>
                                </label>

                                <CustomDatePicker
                                    ref={calendarRef1}
                                    placeholderText='DD/MM/YYYY'
                                    className='w-[70%]'
                                    onChange={date => {
                                        setFormState(prev => ({
                                            ...prev,
                                            issue_date: date! as unknown as string,
                                        }))
                                    }}
                                    wrapperClassName='border-2'
                                    selected={formState.issue_date as unknown as Date}
                                />
                            </div>
                            <div className='flex flex-col gap-3 lg:w-1/2'>
                                <label className='flex items-center gap-1 text-sm'>
                                    Due Date <span className='text-[red]'>*</span>
                                </label>
                                <CustomDatePicker
                                    ref={calendarRef2}
                                    className='w-[70%]'
                                    placeholderText='DD/MM/YYYY'
                                    onChange={date =>
                                        setFormState(prev => ({
                                            ...prev,
                                            due_date: date! as unknown as string,
                                        }))
                                    }
                                    wrapperClassName='border-2'
                                    selected={formState.due_date as unknown as Date}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`w-full lg:w-[45%] ${isUploading ? 'opacity-50' : ''}`}>
                    <input
                        type='file'
                        hidden
                        id='logo'
                        ref={logo}
                        accept='image/*'
                        onChange={e => {
                            uploadLogoDocFn(e)
                            upLoadLogo(e)
                        }}
                    />

                    <div
                        className='bg-[#F9F9F9] p-5 text-center rounded-2xl h-full max-h-[180px] flex flex-col justify-between cursor-pointer items-center'
                        onClick={() => logo.current?.click()}
                    >
                        <img
                            src={
                                logoFile && logoFile?.fileUrl.length > 0
                                    ? logoFile?.fileUrl
                                    : selectedAccount?.org?.logo ?? bizpendlogo
                            }
                            alt='Logo'
                            className='w-[8rem] h-[6rem] object-contain'
                        />

                        <button className='text-brand font-medium mt-1'>Change logo</button>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <h2 className='text-sm text-[#5E5E5E] font-medium mb-3'>Item(s)</h2>
                <div className='border border-[#DADCE0] rounded-lg w-full overflow-y-scroll'>
                    <table className='text-sm text-[#202020] min-w-[40rem] md:w-full'>
                        <thead className='border-b-[0.5px] border-[#DADCE0] bg-[#F9F9F9]'>
                            <tr>
                                <th className='w-[45%] text-left py-3 pl-4'>Item(s)</th>
                                <th className='text-left py-3'>Quantity</th>
                                <th className='text-right py-3'>Unit cost ({currencySymbol})</th>
                                <th className='text-right py-3'>Total cost ({currencySymbol})</th>
                                <th className='text-transparent'> action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <div className='py-3 px-[10px]'> */}
                            {formState?.item?.map(item => (
                                <>
                                    <div className='mt-3'></div>
                                    <tr>
                                        <td className='pt-[7px] pl-[10px]'>
                                            <div>
                                                <p className='font-semibold mb-1'>{item?.name}</p>
                                                <p className='text-[#5E6470] w-[90%]'>{item?.description}</p>
                                            </div>
                                        </td>
                                        <td className='pt-[7px]'>{item?.quantity}</td>
                                        <td className='text-right py-[7px]'>
                                            {currencySymbol} {formatAmount(item?.unit_cost).replaceAll('₦', '')}
                                        </td>
                                        <td className='text-right py-[7px]'>
                                            {currencySymbol}{' '}
                                            {formatAmount(Number(item?.unit_cost) * Number(item?.quantity)).replaceAll(
                                                '₦',
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            <div className='flex items-center gap-5 ml-3'>
                                                <ProfileEditIcon
                                                    className='h-5 cursor-pointer'
                                                    onClick={() => {
                                                        setEditItem(true)
                                                        setEditItemProps(item)
                                                    }}
                                                />
                                                <TrashIcon
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        setFormState(field => {
                                                            return {
                                                                ...field,
                                                                item: field.item.filter(
                                                                    data =>
                                                                        (data?._id ?? data?.id ?? data?.uniq_id) !==
                                                                        (item?._id ?? item?.id ?? item?.uniq_id)
                                                                ),
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            ))}
                            {/* </div> */}
                        </tbody>
                    </table>
                    <div className='p-4  flex items-center justify-center'>
                        <button
                            className='text-xs font-medium text-[#454ADE] bg-[#EFEFF9] w-[115px] text-center rounded-[20px]  px-2 py-1'
                            onClick={() => {
                                setOpenAddItem(true)
                                setFormState(prev => ({
                                    ...prev,
                                    item:
                                        prev?.item?.length > 0
                                            ? [...prev.item, { ...itemDefault, uniq_id: uuidv4() }]
                                            : [{ ...itemDefault, uniq_id: uuidv4() }],
                                }))
                            }}
                            disabled={isLoading}
                        >
                            Add new item
                        </button>
                    </div>
                    <div className='p-4 border-t border-b border-[#DADCE0]  mb-3 min-w-[40rem] lg:w-full'>
                        <p className='text-right text-sm font-medium mb-1'>Subtotal</p>
                        <p className='text-right text-sm font-semibold text-[#5E5E5E] mb-3'>
                            {currencySymbol} {formatAmount(sub_total).replaceAll('₦', '')}
                        </p>
                        <p className='text-right text-sm text-[#5E5E5E] mb-1 mt-2'>Tax</p>

                        <div className='flex border border-[#DADCE0] max-w-fit min-w-[125px] ml-auto h-[3rem] pl-1 rounded-lg overflow-hidden bg-white'>
                            <span className='flex items-center justify-center text-[#838383] ml-2'>
                                {currencySymbol}
                            </span>

                            <input
                                name={`tax`}
                                type='text'
                                placeholder={`Tax`}
                                className='outline-none h-full ml-1 placeholder:text-sm'
                                required
                                min={0}
                                value={formatAmount(formState?.tax).replaceAll('₦', '')}
                                onChange={e => {
                                    const value_entered = formatAmountWithoutSymbols(e.target.value)

                                    setFormState(prevState => ({
                                        ...prevState,
                                        tax: Number(value_entered),
                                    }))
                                }}
                            />
                        </div>
                    </div>
                    <div className='px-4 mx-[10px] ml-0 lg:ml-[10px] mb-4  pt-2 border-[#DADCE0] min-w-[40rem] lg:w-full'>
                        <p className='text-right text-sm font-medium mb-1'>Total</p>
                        <p className='text-right text-sm font-semibold text-[#5E5E5E]'>
                            {currencySymbol}{' '}
                            {formatAmount(Number(sub_total) + Number(formState.tax ?? 0)).replaceAll('₦', '')}
                        </p>
                    </div>
                </div>
            </div>

            {(openAddItem || editItem) && (
                <AddNewItemModal
                    setFormState={setFormState}
                    totalItems={formState?.item as AddItemProps[]}
                    setCloseModal={setOpenAddItem}
                    setEditModal={setEditItem}
                    formState={formState}
                    selectedItem={editItem ? editItemProps : formState?.item.slice(-1)[0]}
                    update={editItem}
                    orgItems={allOrgInvoiceItem as InvoiceItemType[]}
                />
            )}
            {addCustomer && <CreateNewCustomer setCloseModal={setAddCustomer} />}
        </div>
    )
}
