import { TeamsProp } from '@/types/employee'
import { EmployeeType } from '@/types/org'

export type FormValues = {
    team_id: string
    name: string
    amount: string
    purpose?: string
    reset: string
    start_date?: string
    end_date?: string
    manager: Record<'label' | 'value', string>[]
    category: string
    users: string
    method: string
    allocatedAmount?: { member: EmployeeType; amount: string }[]
    distribute_budgets: 'true' | 'false'
    teamUsers?: TeamsProp | undefined
    unused_budget?: string
}

export const createBudgetFields = [
    {
        title: 'Budget name',
        fieldType: 'text',
        placeholder: 'Enter budget name',
        name: 'name',
    },
    {
        title: 'Budget amount',
        fieldType: 'amount',
        placeholder: 'Enter amount you want to allocate',
        name: 'amount',
    },

    {
        title: 'Budget renewal',
        fieldType: 'dropdown',
        fieldOptions: [
            { name: 'Monthly', desc: 'monthly' },
            { name: 'Weekly', desc: 'weekly' },
            { name: 'One Off', desc: 'one-off' },
        ],
        placeholder: 'Reset budget',
        name: 'reset',
    },
    {
        title: 'Category',
        fieldType: 'dropdown',
        placeholder: 'Select category',
        name: 'category',
    },
    {
        title: 'What happens to unused budget?',
        fieldType: 'dropdown',
        fieldOptions: [
            {
                name: "<b className='font-bold'> Rollover the unused amount to increase next month's budget</b><p className='block'>This means if there’s N15,000 left unspent in this budget by the end of the month, the users get N600,000 + N15,000 (N615,000) on  the next renewal</p>",
                desc: '1',
            },

            {
                name: "<b className=''> Reset the budget to the initial allocated value</b><p className='block hide'>This means if there’s  N15,000 left unspent in this budget by the end of the month, the users still  get N600,000 on the next renewal</p>",
                desc: '2',
            },
        ],
        placeholder: 'What happens to unused budget?',
        name: 'unused_budget',
    },
    {
        title: 'Budget start date',
        fieldType: 'date',
        placeholder: 'Start budget from',
        name: 'start_date',
    },
    {
        title: 'Budget end date',
        fieldType: 'date',
        placeholder: 'End budget on',
        name: 'end_date',
    },
    {
        title: 'Budget manager',
        fieldType: 'select',
        // fieldOptions: [
        //     { name: 'Monthly', desc: 'monthly' },
        //     { name: 'Quaterly', desc: 'quaterly' },
        //     { name: 'Bi-yearly', desc: 'bi-yearly' },
        // ],
        placeholder: 'Who will manage this budget?',
        name: 'manager',
    },
    {
        title: 'Description*',
        fieldType: 'textarea',
        placeholder: 'Describe the budget and give more insight on it',
        name: 'purpose',
    },
]
