import { useGetTransactions } from '@/services/transactions/queries'
import TransactionHistoryTable from '../../Transactions/components/TransactionHistoryTable'
import ProgressBar from '@/components/ProgressBar'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { useAuthContext } from '@/context'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { accountSidebarPermissions } from '@/layout/LayoutComponents/utils'

export default function TransactionHistory() {
    const { accountId } = useParams()
    const { selectedAccount } = useAuthContext()

    const hasPermission = useHasAccessPermisson(accountSidebarPermissions)
    const viewOrgTransaction = hasPermission

    const { data: trasactiondata, isLoading } = useGetTransactions({
        queryParams: {
            limit: '12',
            bank_account: accountId as string,
            account_type: viewOrgTransaction ? 'Org' : 'UserOrgAccount',
            id: viewOrgTransaction ? (selectedAccount?.org?.id as string) : (selectedAccount?.id as string),
        },
    })
    const transactionsData = useMemo(
        () => (trasactiondata && trasactiondata?.results?.length > 0 ? [...trasactiondata.results] : []),
        [trasactiondata]
    )
    if (isLoading) return <ProgressBar />
    return (
        <>
            <div className='w-full py-4 block bg-white rounded-2xl p-4 '>
                {
                    <div className={`overflow-y-scroll transition-all rounded-lg  `}>
                        <TransactionHistoryTable transactions={transactionsData} />
                    </div>
                }
            </div>
        </>
    )
}
