import { CiSearch } from 'react-icons/ci'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useAuthContext, useInvoiceContext } from '@/context'
import { debounce } from 'lodash'
import InvoiceTable from '../../components/InvoiceTable'
import { useGetAllInvoice } from '@/services/invoice/queries'
import ProgressBar from '@/components/ProgressBar'
import { useNavigate } from 'react-router-dom'
import notFound from '@/assets/not-found.svg'
import { getSortString } from '@/components/Table/utils'
import { ColumnSort } from '@/types/utils'
import FilterInvoicesChip from '../FilterInvoicesChip'
// import downArrow from '@/assets/down-arrow.svg'
import filter from '@/assets/filter.svg'
import FilterInvoices from '@/components/Modals/Invoice/AdvancedFilter'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { invoiceSidebarPermissions } from '@/layout/LayoutComponents/utils'
import NotAuthorized from '@/layout/NotAuthorized'

import { CurrencySelectFilter } from '@/components/CurrencyFilter'

const InvoicePartial = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [currency, setCurrency] = useState('all')

    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)
    const navigate = useNavigate()
    const [filterInvoicesModal, setFilterInvoicesModal] = useState(false)
    const { filterInvoices: filterFormState } = useInvoiceContext()

    const hasPermission = useHasAccessPermisson(invoiceSidebarPermissions)

    const isAdvanceFiltered =
        Object.values({ ...filterFormState.period }).some(value => !!value) || !!filterFormState.status
    // .some(data => !!data)

    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const {
        sort,
        filterInvoices,
        setFilterInvoices,
        setPageNumberClicked,
        pageNumber,
        pageNumberClicked,
        setPaginationDetails,
    } = useInvoiceContext()

    const {
        data: invoice_data,
        isLoading,
        isRefetching,
        refetch: refectchOrgInvoice,
    } = useGetAllInvoice({
        enabled: hasPermission,
        queryParams: {
            page: pageNumber.toString(),
            currency: currency === 'all' ? '' : currency,
            limit: '12',
            org: selectedAccount?.org?.id as string,
            sort: getSortString(sort as ColumnSort),
            search: searchQuery,
            status: filterInvoices?.status,
            start_date: (filterInvoices?.period?.from ?? filterInvoices?.period?.time_in_words) as unknown as string,
            end_date: (filterInvoices?.period?.to ?? new Date()) as unknown as string,
        },

        onSuccess: data => {
            if (data?.pagination) {
                setPaginationDetails(data?.pagination)
            }
            setFilterInvoices(prev => ({ ...prev, filter_now: false }))
            setBackSpace(false)
        },
    })

    const allOrgInvoice = useMemo(() => invoice_data?.results ?? [], [invoice_data])

    useEffect(() => {
        if (pageNumber && pageNumberClicked) {
            refectchOrgInvoice()
            setPageNumberClicked(false)
        }
    }, [pageNumber, pageNumberClicked])

    useEffect(() => {
        if (sort || currency) {
            refectchOrgInvoice()
        }
    }, [sort, currency])
    const debouncedSearch = useRef(
        debounce(() => {
            refectchOrgInvoice()
        }, 300)
    ).current

    useEffect(() => {
        if (backspaceClicked) {
            refectchOrgInvoice()
            setBackSpaceClicked(false)
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [searchQuery])
    React.useEffect(() => {
        if (filterInvoices.filter_now) {
            refectchOrgInvoice()
        }
    }, [filterInvoices.filter_now, refectchOrgInvoice, backspaceClicked])

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }
    const { enabled: isInvoiceEnabled } = useAccessToModule({ module: ModuleNameEnum.INVOICE })

    if (!hasPermission) return <NotAuthorized />
    if (isLoading) return <ProgressBar />
    return (
        <section className={`h-screen overflow-scroll ${allOrgInvoice.length > 0 ? '' : 'pb-6'} `}>
            <div className='block lg:flex justify-between mb-6 lg:mb-0'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Invoice lists</h1>
                    <p className='text-gray7 text-sm'>Create and issue invoices to your customers</p>
                </div>
                <button
                    className='bg-[#454ADE] text-white px-2 h-[40px] rounded-md text-sm flex items-center justify-center gap-2 min-w-full xs:min-w-[115px] text-center'
                    onClick={() => {
                        if (!isInvoiceEnabled) {
                            setShowUpgradeModal(true)
                            setCurrentModule(ModuleNameEnum.INVOICE)

                            return
                        }
                        navigate('/dashboard/invoice/create_invoice')
                    }}
                >
                    Create invoice
                </button>
            </div>
            <div className='w-full py-4 block bg-white rounded-2xl p-4 '>
                <div className='flex items-center justify-between mb-6'>
                    <div className='border border-[#DADCE0] h-[45px] w-[60%] lg:w-[30%] rounded-lg flex items-center overflow-hidden'>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 lg:mx-0'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            className='h-full w-[60%] outline-none placeholder:text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder='Search by name'
                        />
                    </div>
                    <div className='flex items-center gap-2'>
                        <div
                            className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'
                            onClick={() => setFilterInvoicesModal(true)}
                        >
                            <img src={filter} alt='button' />
                        </div>
                        {/* <div className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'>
                            <img src={downArrow} alt='button' />
                        </div> */}
                    </div>
                </div>

                <CurrencySelectFilter currency={currency} setCurrency={setCurrency} />
                <section className='mb-4'>
                    <FilterInvoicesChip />
                </section>
                <div className={isRefetching ? 'opacity-30' : 'opacity-100'}>
                    {allOrgInvoice.length === 0 && isRefetching ? (
                        <ProgressBar />
                    ) : (allOrgInvoice.length === 0 && searchQuery?.length < 1 && !backspace && !isAdvanceFiltered) ||
                      (allOrgInvoice.length > 0 && searchQuery?.length >= 0 && !backspace) ? (
                        <div className='overflow-x-scroll'>
                            <InvoiceTable data={allOrgInvoice} />
                        </div>
                    ) : (searchQuery.length > 0 || isAdvanceFiltered) && allOrgInvoice?.length === 0 ? (
                        <div className='h-450px] flex flex-col text-center justify-center items-center pb-10'>
                            <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                            <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                            <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                {allOrgInvoice &&
                                    'Invoices not found in this record. Please try a different filter or contact support for assistance.'}
                            </span>
                        </div>
                    ) : (
                        isRefetching && <ProgressBar />
                    )}
                </div>
            </div>
            {filterInvoicesModal && <FilterInvoices closeModal={setFilterInvoicesModal} />}
        </section>
    )
}

export default InvoicePartial
