import { Tabs, TabTriggersContainer, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import InvoiceDetails from './InvoiceDetails'
import InvoiceTimeLine from './InvoiceTimeLine'
import { useGetInvoiceByID } from '@/services/invoice/queries'
import { useInvoiceContext } from '@/context'
import ProgressBar from '@/components/ProgressBar'
import { BsArrowLeft } from 'react-icons/bs'
import PageContent from '@/layout/PageContent'

const routeHelper = {
    details: 'details',
    timeline: 'timeline',
}
const InvoiceDetailsWrapper = () => {
    const navigate = useNavigate()
    const { setInvoiceDetails } = useInvoiceContext()

    const { invoice_id } = useParams()

    const { data: invoice_details, isLoading } = useGetInvoiceByID({
        queryParams: {
            invoice_id: invoice_id as string,
        },
    })
    useEffect(() => {
        setInvoiceDetails(invoiceDetails)
    }, [invoice_details])

    const invoiceDetails = useMemo(() => invoice_details, [invoice_details])

    const queryParams = new URLSearchParams(location.search)
    const paramDetails = queryParams.get('path') as string

    const navigateToPath = (path: string) => {
        const params = new URLSearchParams(location.search)
        params.set('path', path)
        navigate(`?${params.toString()}`)
    }

    if (isLoading) return <ProgressBar />
    return (
        <div>
            <PageContent>
                <div className='my-5 inline-flex items-center cursor-pointer' onClick={() => navigate(-1)}>
                    <BsArrowLeft />
                    <p className='ml-2 font-semibold text-lg'>back</p>
                </div>
            </PageContent>
            <Tabs value={paramDetails}>
                <TabsList className=' flex items-center gap-6 lg:w-[93%] border-none ml-2 '>
                    <TabTriggersContainer className='mx-1 lg:mx-auto'>
                        <TabsTrigger
                            value={routeHelper.details}
                            id='details'
                            onClick={() => navigateToPath(routeHelper.details)}
                            className={`relative border-none p-2 px-4 ${
                                routeHelper.details === paramDetails ? '!text-white' : ''
                            } text-sm`}
                        >
                            {routeHelper.details === paramDetails && (
                                <motion.div
                                    layoutId='timeline'
                                    className='bg-tabBg rounded !text-white absolute inset-0 w-full p-2 h-full text-xs'
                                />
                            )}
                            <motion.span layout='position' className='relative'>
                                Details
                            </motion.span>
                        </TabsTrigger>
                        <TabsTrigger
                            value={routeHelper.timeline}
                            id='timeline'
                            onClick={() => navigateToPath(routeHelper.timeline)}
                            className={`relative border-none p-2 px-4 ${
                                routeHelper.timeline === paramDetails ? '!text-white' : ''
                            } text-sm`}
                        >
                            {routeHelper.timeline === paramDetails && (
                                <motion.div
                                    layoutId='timeline'
                                    className='bg-tabBg rounded !text-white absolute inset-0 w-full p-2 h-full text-xs'
                                />
                            )}
                            <motion.span layout='position' className='relative'>
                                Timeline
                            </motion.span>
                        </TabsTrigger>
                    </TabTriggersContainer>
                </TabsList>
                <TabsContent value={routeHelper.details}>
                    <InvoiceDetails invoiceDetails={invoiceDetails} />
                </TabsContent>
                <TabsContent value={routeHelper.timeline}>
                    <InvoiceTimeLine invoiceDetails={invoiceDetails} />
                </TabsContent>
            </Tabs>
        </div>
    )
}

export default InvoiceDetailsWrapper
