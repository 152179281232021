import { useState, useEffect } from 'react'


import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { TbCurrencyNaira } from 'react-icons/tb'
import { capitalizeText } from '@/utils/string'

import { Control, Controller, UseFormWatch } from 'react-hook-form'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { useResolveBankMutation } from '@/services/payout/mutations'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { LoaderIcon } from 'react-hot-toast'

import { calculateTransferFee, formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import SelectWIthChip from '@/components/SelectWithChip'
import { formProps } from './utils'
import CSVTransferRootModal from './CSVTransferRootModal'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { AlertCircleIcon } from 'lucide-react'
import { QueryOrgMembers } from '@/services/employees/types'
import { QueryOrgVendors } from '@/services/vendors/types'
import { QueryGetAllBanks, UserAccount } from '@/services/payout/types'

type Props = {
    watch: UseFormWatch<formProps>
    control: Control<formProps, any>
    selectedEmployees: Record<'label' | 'value' | 'amount', string>[]
    setSelectedEmployees: React.Dispatch<React.SetStateAction<Record<'label' | 'value' | 'amount', string>[]>>
    setResolvedBank: React.Dispatch<React.SetStateAction<Record<'account_number' | 'account_name', string>>>
    allBankList: QueryGetAllBanks
    values: () => formProps
    orgMembers?: QueryOrgMembers
    orgVendors?: QueryOrgVendors
    isOrgMemberLoading: boolean
    isOrgVendorsLoading: boolean
}
export default function TransferPage1({
    watch,
    control,
    selectedEmployees,
    setSelectedEmployees,
    isOrgMemberLoading,
    isOrgVendorsLoading,
    orgMembers,
    orgVendors,
    setResolvedBank,
    allBankList,
    values,
}: Props) {
    const description = watch('desc')
    const recipient = watch('recipient')
    const employeeNum = watch('employeeNum')
    const accout_name = watch('accnt_name')
    const accout_number = watch('accnt_number')

    const [orgMembersData, setOrgMembersData] = useState<
        Record<'label' | 'value' | 'amount' | 'isDisabled' | 'bankAccounts', string | boolean | UserAccount[]>[]
    >([])

    const [openBulkTransferModal, setOpenBulkTransferModal] = useState(false)

    const {
        mutate: confirmBankFn,
        data: resolvedBankDetails,
        isLoading: isBankResolving,
        isSuccess: isBankResolved,
    } = useResolveBankMutation({
        onSuccess: (data) => {
            // console.log(arg)
            setResolvedBank(data)
        },
    })

    useEffect(() => {
        if (accout_name && accout_number?.length === 10) {
            confirmBankFn({
                account_number: accout_number,
                bank_code: accout_name,
                account_name: resolvedBankDetails?.account_name ?? '',
            })
        }
    }, [accout_name, accout_number])
    useEffect(() => {
        if (orgMembers) {
            setOrgMembersData(
                orgMembers
                    .filter(members => members.bank)
                    .map(member => ({
                        label: `${capitalizeText(member?.user?.first_name)} ${capitalizeText(member?.user?.last_name)}`,
                        value: member?.id,
                        amount: '0',
                        isDisabled: !member.bank,
                        bankAccounts: member?.bankAccounts,
                    }))
            )
        }
    }, [orgMembers])

    return (
        <>
            <div className=''>
                <h3 className='font-medium tracking-wide text-lg mb-1'>Enter transfer amount</h3>
            </div>
            <div className='mt-5 flex flex-col gap-2 mb-2'>
                <label htmlFor='firstName' className='text-[#202020] text-[14px] font-medium '>
                    Amount <span className='text-[red]'>*</span>
                </label>

                <Controller
                    name='amount'
                    rules={{
                        required: true,
                        minLength: 2,
                    }}
                    control={control}
                    render={({ field }) => (
                        <div className='flex border border-[#DADCE0] w-full h-[48px] rounded-lg mt-2 overflow-hidden'>
                            <div className='w-[10%] flex items-center justify-center text-[#838383]'>
                                <TbCurrencyNaira fontSize={25} />
                            </div>
                            <Input
                                {...field}
                                value={formatAmount(field.value).replaceAll('₦', '')}
                                onChange={e => {
                                    e.target.value = formatAmountWithoutSymbols(e.target.value)
                                    field.onChange(e)
                                }}
                                className='border-none placeholder:relative placeholder:top-[.1rem] placeholder:text-[#A8A8A8]/90 placeholder:text-xl h-[3rem] pl-0'
                                placeholder='0.00'
                            />
                        </div>
                    )}
                />
            </div>
            <span className='text-[12px] text-[#838383] mb-1 block'>
                Fee:{' '}
                {Number(values().amount) > 0
                    ? formatAmount(calculateTransferFee(values().amount) * 100)
                    : formatAmount(0)}
            </span>
            <span className='text-[12px] text-[#838383] mb-5 block'>
                Transfer starts at ₦1000 as the minimum amount.
            </span>
            <div>
                <h6 className='text-[#202020] text-sm mb-3'>
                    Select recipient type <span className='text-[red]'>*</span>
                </h6>
                <div>
                    <Controller
                        name='recipient'
                        control={control}
                        render={({ field }) => (
                            <RadioGroup className='flex  flex-col gap-3' {...field} onValueChange={field.onChange}>
                                <label
                                    htmlFor={'employee'}
                                    className='flex gap-2 items-center text-[#5E5E5E] text-base cursor-pointer'
                                >
                                    <RadioGroupItem value={'employees'} id={'employee'} />
                                    Users
                                </label>
                                <label
                                    htmlFor={'vendor'}
                                    className='flex gap-2 items-center text-[#5E5E5E] text-base cursor-pointer'
                                >
                                    <RadioGroupItem value={'vendors'} id={'vendor'} />
                                    Vendors
                                </label>
                                <label
                                    htmlFor={'recipient'}
                                    className='flex gap-2 items-center text-[#5E5E5E] text-base cursor-pointer'
                                >
                                    <RadioGroupItem value={'recipient'} id={'recipient'} />
                                    External recipient
                                </label>
                            </RadioGroup>
                        )}
                    />
                </div>
            </div>
            {recipient === 'employees' && (
                <div className='mt-4'>
                    <h6 className='text-[#202020] text-sm mb-3'>How many users do you want to transfer to? *</h6>
                    <div>
                        <Controller
                            name='employeeNum'
                            defaultValue='single'
                            control={control}
                            render={({ field }) => (
                                <RadioGroup className='flex  flex-col gap-3' {...field} onValueChange={field.onChange}>
                                    <label
                                        htmlFor={'single'}
                                        className='flex gap-2 items-center text-[#5E5E5E] text-base cursor-pointer'
                                    >
                                        <RadioGroupItem value={'single'} id={'single'} />
                                        Single user
                                    </label>
                                    <label
                                        htmlFor={'multiple'}
                                        className='flex gap-2 items-center text-[#5E5E5E] text-base cursor-pointer'
                                    >
                                        <RadioGroupItem value={'multiple'} id={'multiple'} />
                                        Multiple users
                                    </label>
                                </RadioGroup>
                            )}
                        />
                    </div>
                </div>
            )}
            {(recipient === 'employees' || recipient === 'vendors') && (
                <div className='mt-5 flex flex-col gap-2 mb-5'>
                    <label htmlFor='firstName' className='text-[#202020] text-[14px] font-medium'>
                        Select {employeeNum === 'multiple' ? 'users' : recipient === 'employees' ? 'user' : 'Vendor'}{' '}
                        <span className='text-[red]'>*</span>
                    </label>

                    <Controller
                        name={recipient === 'employees' ? 'employee' : 'vendor'}
                        control={control}
                        render={({ field }) =>
                            employeeNum === 'multiple' && recipient === 'employees' ? (
                                <div>
                                    <SelectWIthChip
                                        options={orgMembersData.map(members_data => ({
                                            label: members_data?.label as string,
                                            value: members_data?.value as string,
                                            isDisabled: members_data?.isDisabled as boolean,
                                            bankAccounts: members_data?.bankAccounts,
                                        }))}
                                        placeholder='Click to see users'
                                        onChange={members_details => {
                                            members_details.length < 6 ? setSelectedEmployees(members_details) : null
                                        }}
                                        value={selectedEmployees}
                                    />
                                    <p
                                        className='underline text-brand text-xs mt-2 font-semibold cursor-pointer'
                                        onClick={() => setOpenBulkTransferModal(true)}
                                    >
                                        Make transfer to more than 5 users
                                    </p>
                                </div>
                            ) : (
                                <Select {...field} onValueChange={field.onChange}>
                                    <SelectTrigger
                                        className={`w-full h-[3.5rem] border-[#DADCE0] bg-base_gray_100 text-[13px] text-[#202020]`}
                                    >
                                        <SelectValue
                                            placeholder={`Select ${recipient === 'employees' ? 'User' : 'Vendor'}`}
                                        />
                                    </SelectTrigger>
                                    <SelectContent className='w-[25rem]'>
                                        {recipient === 'employees' ? (
                                            isOrgMemberLoading ? (
                                                <SelectItem
                                                    value={'empty'}
                                                    key={'id_members'}
                                                    className='cursor-pointer'
                                                >
                                                    Please Wait For Users To load
                                                </SelectItem>
                                            ) : (
                                                <>
                                                    {orgMembers &&
                                                        orgMembers.map(member => (
                                                            <div className='flex items-center justify-between w-full'>
                                                                <SelectItem
                                                                    value={member?.id}
                                                                    key={member?.id}
                                                                    className='cursor-pointer'
                                                                    disabled={!member?.bank}
                                                                >
                                                                    <div className='flex justify-between items-center w-[20rem] '>
                                                                        <span className='inline-block whitespace-nowrap'>
                                                                            {capitalizeText(member?.user?.first_name)}{' '}
                                                                            {capitalizeText(member?.user?.last_name)}
                                                                        </span>
                                                                    </div>
                                                                </SelectItem>
                                                                {!member?.bank && (
                                                                    <TooltipProvider delayDuration={10}>
                                                                        <Tooltip>
                                                                            <TooltipTrigger className='mr-4'>
                                                                                <AlertCircleIcon size={15} />
                                                                            </TooltipTrigger>

                                                                            <TooltipContent>
                                                                                <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                                                    User doesn't have a bank added
                                                                                </p>
                                                                            </TooltipContent>
                                                                        </Tooltip>
                                                                    </TooltipProvider>
                                                                )}
                                                            </div>
                                                        ))}
                                                </>
                                            )
                                        ) : recipient === 'vendors' && isOrgVendorsLoading ? (
                                            <SelectItem value={'empty'} className='cursor-pointer'>
                                                Please Wait For Vendors To load
                                            </SelectItem>
                                        ) : (
                                            <>
                                                {orgVendors &&
                                                    orgVendors.vendors.map(vendor => (
                                                        <SelectItem
                                                            value={vendor?.id}
                                                            key={vendor?.id}
                                                            className='cursor-pointer'
                                                        >
                                                            <div className='flex justify-between items-center w-[22rem] '>
                                                                <span className='inline-block whitespace-nowrap'>
                                                                    {capitalizeText(vendor?.name)}{' '}
                                                                </span>
                                                            </div>
                                                        </SelectItem>
                                                    ))}
                                            </>
                                        )}
                                    </SelectContent>
                                </Select>
                            )
                        }
                    />
                </div>
            )}
            {recipient === 'recipient' && (
                <>
                    <div className=' relative my-5'>
                        <label className='text-[14px] font-medium  pb-3 inline-block' htmlFor='acct_name'>
                            Bank name <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='accnt_name'
                            control={control}
                            render={({ field }) => (
                                <Select {...field} onValueChange={field.onChange}>
                                    <SelectTrigger className='w-full h-[3.5rem] border-[#DADCE0] bg-base_gray_100 placeholder:text-[base_gray_100]  text-[14px]'>
                                        <SelectValue placeholder={'Select bank name'} className='' />
                                    </SelectTrigger>
                                    <SelectContent className='w-[27rem] h-[20rem] overflow-scroll'>
                                        {allBankList?.map(bank => (
                                            <SelectItem value={bank.code} key={bank.code} className='cursor-pointer'>
                                                {bank.name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </div>

                    <div className='relative'>
                        <label className='text-[14px] font-medium  pb-3 inline-block' htmlFor='accnt_number'>
                            Account number <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='accnt_number'
                            rules={{
                                required: true,
                                maxLength: 10,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    placeholder='Enter account number'
                                    className='h-[3.5rem] border-[#DADCE0]'
                                    type='number'
                                    maxLength={10}
                                    {...field}
                                />
                            )}
                        />
                    </div>

                    {isBankResolving && (
                        <div className='flex gap-3 text-[#838383] text-sm items-center mt-3'>
                            <span>Fetching bank name</span>
                            <LoaderIcon className='animate-spin w-4 h-4' />
                        </div>
                    )}

                    {accout_number?.length === 10 && isBankResolved && (
                        <>
                            <div className='flex items-center gap-2 text-[#19C37D] mt-3'>
                                <IoIosCheckmarkCircle className='' />
                                <label htmlFor='' className='text-sm font-medium'>
                                    {resolvedBankDetails?.account_name}
                                </label>
                            </div>
                        </>
                    )}
                </>
            )}

            <div className='mt-5 flex flex-col gap-2 mb-5'>
                <label className='flex items-center gap-1'>
                    Memo
                    <span className='text-[red]'>*</span>
                    <span className='ml-auto text-xs'>{description?.length ?? '0'}/120</span>
                </label>

                <Controller
                    name='desc'
                    rules={{
                        required: true,
                        maxLength: 120,
                    }}
                    control={control}
                    render={({ field }) => (
                        <Textarea
                            placeholder='reason for transfer'
                            className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                            maxLength={120}
                            {...field}
                        />
                    )}
                />
            </div>
            {openBulkTransferModal && <CSVTransferRootModal closeModal={setOpenBulkTransferModal} />}
        </>
    )
}
