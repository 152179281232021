import { useAuthContext } from '@/context'
import { useEffect, useState } from 'react'
import { useMutateSaveAccountDetails, useResolveBankMutation } from '@/services/payout/mutations'

import { SelectTrigger, Select, SelectContent, SelectValue, SelectItem } from '@/components/Select'
import { QueryGetAllBanks } from '@/services/payout/types'
import { Input } from '@/components/Input'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { Button } from '@/components/Button'
import { LoaderIcon } from 'lucide-react'

type Props = {
    allBankList: QueryGetAllBanks
    setKycInfoStep: React.Dispatch<React.SetStateAction<number>>
}
export default function PaymentInformation({ allBankList, setKycInfoStep }: Props) {
    const [bankDetails, setBankDetails] = useState<{
        account_name: string
        bank_code: string | undefined
        account_number: string | undefined
    }>({
        bank_code: undefined,
        account_number: undefined,
        account_name: '',
    })

    const [acctNumberErr, setAcctNumberErr] = useState('')
    const [bankSelectionErr, setBankSelectionError] = useState(false)
    const { mutate: addBankFn, isLoading: isAddingBankLoading } = useMutateSaveAccountDetails({
        onSuccess: () => {
            setKycInfoStep(3)
        },
    })

    const { selectedAccount } = useAuthContext()
    const {
        mutate: confirmBankFn,
        data: resolvedBankDetails,
        isLoading: isBankResolving,
        isSuccess: isBankResolved,
    } = useResolveBankMutation({
        onError: (error: any) => {
            if (error.response.data.error[0].msg == 'Bank code is required') {
                setBankSelectionError(true)
            }
        },
    })

    const handleAddBankDetails = () => {
        addBankFn({
            account_number: bankDetails.account_number,
            bank_code: bankDetails.bank_code,
            is_primary: true,
            account: selectedAccount?.id,
            account_name: resolvedBankDetails?.account_name,
        })
    }

    useEffect(() => {
        const { account_number, bank_code } = bankDetails
        if (account_number?.length === 10 && bank_code) {
            confirmBankFn({
                account_number: bankDetails.account_number,
                bank_code: bankDetails.bank_code,
                account_name: bankDetails?.account_name,
            })
            setAcctNumberErr('')
            setBankSelectionError(false)
            return
        }
        if (account_number?.length !== 10 && account_number && account_number?.length > 0) {
            setAcctNumberErr('Enter a valid account number')
        } else setAcctNumberErr('')
        if (!bank_code && account_number?.length == 10) {
            setBankSelectionError(true)
        }
    }, [bankDetails.account_number, bankDetails.bank_code])

    return (
        <form>
            <div className='lg:min-h-[59vh] overflow-scroll'>
                <section className='flex flex-row  gap-7 mt-9 '>
                    <div className=' relative flex-1'>
                        <label className='text-sm text-gray7 pb-1 font-medium inline-block' htmlFor='acct_name'>
                            Bank name
                        </label>
                        <Select
                            name='accnt_number'
                            onValueChange={data => {
                                setBankDetails(prev => ({ ...prev, bank_code: data }))
                                setBankSelectionError(false)
                            }}
                            value={bankDetails?.bank_code}
                        >
                            <SelectTrigger className='w-full h-[45px] border-[#DADCE0] bg-base_gray_100 placeholder:text-[base_gray_100]  text-[14px]'>
                                <SelectValue placeholder={'Select bank name'} className='' />
                            </SelectTrigger>
                            <SelectContent className='w-[27rem] h-[20rem] overflow-scroll'>
                                {allBankList?.map(bank => (
                                    <SelectItem value={bank.code} key={bank.code} className='cursor-pointer'>
                                        {bank.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        {bankSelectionErr && (
                            <p className='text-xs text-[red] mt-2 -bottom-5 left-0'>Please select a bank</p>
                        )}
                    </div>
                    <div className='relative flex-1'>
                        <label className='text-sm text-gray7 pb-1 font-medium inline-block' htmlFor='accnt_number'>
                            Account number
                        </label>
                        <Input
                            placeholder='Enter account number'
                            id='accnt_number'
                            className='h-[45px] border-[#DADCE0]'
                            type='number'
                            value={bankDetails?.account_number as string}
                            onChange={e => setBankDetails(prev => ({ ...prev, account_number: e.target.value }))}
                            maxLength={10}
                        />

                        {bankDetails?.account_number && bankDetails?.account_number?.length > 0 && (
                            <span className='text-xs text-[red] -bottom-5 left-0'>{acctNumberErr}</span>
                        )}
                    </div>
                </section>
                {isBankResolving && (
                    <div className='flex gap-3 text-[#838383] text-sm items-center mt-2'>
                        <span>Fetching bank name</span>
                        <LoaderIcon className='animate-spin w-4 h-4' />
                    </div>
                )}
                {isBankResolved && (
                    <>
                        <div className='flex items-center gap-2 text-[#19C37D] mt-2'>
                            <IoIosCheckmarkCircle className='' />
                            <label htmlFor='' className='text-sm font-medium'>
                                {resolvedBankDetails?.account_name}
                            </label>
                        </div>
                    </>
                )}
                <div className=' mt-12 flex justify-end gap-6'>
                    <Button
                        type='button'
                        className=' ml-auto  bg-transparent hover:bg-transparent min-w-[100px] text-[#202020]'
                        onClick={() => setKycInfoStep(1)}
                    >
                        Back
                    </Button>

                    <Button
                        type='button'
                        className=''
                        loading={isAddingBankLoading}
                        disabled={!resolvedBankDetails?.account_name || isBankResolving}
                        onClick={() => handleAddBankDetails()}
                    >
                        Add bank
                    </Button>
                </div>
            </div>
        </form>
    )
}
