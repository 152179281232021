import { useEffect, useMemo, useRef, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { useAuthContext } from '@/context'
import notFound from '@/assets/not-found.svg'
import UserContractTable from './components/UserContractTable'
import { useGetOrgContractPayroll } from '@/services/payroll/queries'
import ProgressBar from '@/components/ProgressBar'
import { QueryOrgContractPayRollType } from '@/services/payroll/types'
import { debounce } from 'lodash'

export default function UserContractPayProfile() {
    const [searchQuery, setSearchQuery] = useState('')
    const { selectedAccount } = useAuthContext()

    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)

    const {
        data: user_contract_payroll,
        isLoading,
        isRefetching,
        refetch,
    } = useGetOrgContractPayroll({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            employee: selectedAccount?.id as string,
            search: searchQuery,
        },
        onSuccess: () => {
            setBackSpace(false)
        },
    })
    const userContract = useMemo(
        () => user_contract_payroll ?? ({} as QueryOrgContractPayRollType),
        [user_contract_payroll]
    )

    const debouncedSearch = useRef(
        debounce(() => {
            refetch()
        }, 300)
    ).current

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }
    useEffect(() => {
        if (backspaceClicked) {
            refetch()
            setBackSpaceClicked(false)
        }

        if (searchQuery) {
            debouncedSearch()
        }
        // console.log('here')
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, debouncedSearch, refetch, searchQuery])

    if (isLoading) return <ProgressBar />
    return (
        <section>
            <div className='w-full py-6 block'>
                <div className='flex items-center justify-between mb-6'>
                    {
                        <div className='border border-[#DADCE0] h-[56px] w-full md:w-[35%] rounded-lg flex items-center overflow-hidden '>
                            <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 md:mx-0'>
                                <CiSearch fontSize={25} />
                            </div>
                            <input
                                type='text'
                                placeholder='Search by Contract title'
                                className='h-full w-full outline-none'
                                onChange={e => setSearchQuery(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                    }
                    <div className=''>&nbsp;</div>
                </div>

                {userContract?.payrolls?.length === 0 && isRefetching ? (
                    <ProgressBar />
                ) : (userContract?.payrolls?.length === 0 && searchQuery?.length < 1 && !backspace) ||
                  (userContract?.payrolls &&
                      userContract?.payrolls?.length > 0 &&
                      searchQuery?.length >= 0 &&
                      !backspace) ? (
                    <section
                        className={` transition-all rounded-lg ${isRefetching ? 'opacity-30' : 'opacity-100'} overflow-x-scroll `}
                    >
                        <UserContractTable contractHistory={userContract} />
                    </section>
                ) : (
                    <div className='h-[300px] flex flex-col text-center justify-center items-center'>
                        {searchQuery && userContract?.payrolls?.length === 0 ? (
                            <>
                                <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                    "Oops! It seems we couldn't find any matching results for your search. Please try
                                    again with different keywords or refine your filter criteria for better results.
                                </span>
                            </>
                        ) : (
                            <ProgressBar />
                        )}
                    </div>
                )}
            </div>
        </section>
    )
}
