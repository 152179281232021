import { Tabs, TabTriggersContainer, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import PlansAndPricing from './PlansAndPricing'
import PlanUsage from './PlanUsage'

const route_const = {
    plan_pricing: 'plan-pricing',
    plan_usage: 'plan-usage',
}
export default function PricingPage() {
    const { company_subRoute } = useParams()
    const base_route = '/dashboard/settings/company/'
    const navigate = useNavigate()
    return (
        <Tabs defaultValue={company_subRoute} value={company_subRoute}>
            <TabsList className={`border-none  flex justify-between  items-center `}>
                <TabTriggersContainer className='lg:w-full  ml-0 lg:mx-auto'>
                    <TabsTrigger
                        value={route_const.plan_pricing}
                        onClick={() => {
                            navigate(`${base_route}${route_const.plan_pricing}`)
                        }}
                        className={`relative border-none px-3 ${
                            route_const.plan_pricing === company_subRoute ? '!text-white' : ''
                        } text-sm`}
                    >
                        {route_const.plan_pricing === company_subRoute && (
                            <motion.div
                                layoutId='directory_btn'
                                className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                            />
                        )}
                        <motion.span layout='position' className='z-20 relative'>
                            Subscription
                        </motion.span>
                    </TabsTrigger>

                    <TabsTrigger
                        value={route_const.plan_usage}
                        onClick={() => {
                            navigate(`${base_route}${route_const.plan_usage}`)
                        }}
                        className={`relative border-none px-3 ${
                            route_const.plan_usage === company_subRoute ? '!text-white' : ''
                        } text-sm`}
                    >
                        {route_const.plan_usage === company_subRoute && (
                            <motion.div
                                layoutId='directory_btn'
                                className={'bg-tabBg rounded  text-sm  w-full left-[1px] top-1  bottom-1 absolute'}
                            />
                        )}
                        <motion.span layout='position' className='z-20 relative'>
                            Plan usage
                        </motion.span>
                    </TabsTrigger>
                </TabTriggersContainer>
            </TabsList>
            <TabsContent value={route_const.plan_pricing}>
                <PlansAndPricing />
            </TabsContent>
            <TabsContent value={route_const.plan_usage}>
                <PlanUsage />
            </TabsContent>
        </Tabs>
    )
}
