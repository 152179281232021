import walletIcon from '@/assets/wallet-icon.svg'
import { useMemo, useState } from 'react'

import expensesIcon2 from '@/assets/expenses-dashboard.svg'
import transactionIcon from '@/assets/new-transaction.svg'
import transactionIcon2 from '@/assets/transaction.svg'
import employeesIcon from '@/assets/users.svg'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import FundWalletRoot from '@/components/Modals/FundWallet/FundWalletRoot'
import { useAuthContext } from '@/context'
import { useGetOrgInsight } from '@/services/auth/queries'
import { OverviewInsight } from '@/services/auth/types'
import { formatAmount } from '@/utils/money'
import { noop } from '@tanstack/react-table'
import { ArrowUpRight, PlusSquare } from 'lucide-react'
import GetStarted from '../../GetStartedCards'
import TotalExpenses from '../../TotalExpenses'

import TransferFundsRoot from '@/components/Modals/Overview/TransferFunds/TransferFundsRoot'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import ShowBankDetails from '@/components/Modals/BankDetails/ViewBankDetails'
import { useGetOrgSettings } from '@/services/settings/queries'
import { useGetorgVirtualAccount } from '@/services/virtual-account/queries'

const OverviewInsights = () => {
    const [showModal, setShowModal] = useState(false)
    const [showTransferModal, setShowTransferModal] = useState(false)
    const [showBankDetails, setShowbankDetails] = useState(false)

    const { selectedAccount } = useAuthContext()

    const { data: orgVirtualAccount } = useGetorgVirtualAccount({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })

    const { data, isLoading } = useGetOrgInsight({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })
    const { data: wallet_access_data } = useGetOrgSettings({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })
    const walletAccessUsers = useMemo(() => wallet_access_data ?? [], [wallet_access_data])
    const isAllowedToSeeBalance = walletAccessUsers?.[0]?.wallet_access?.some(
        priviledgedUser => priviledgedUser?.id === selectedAccount?.id
    )

    const orgInsightData = useMemo(() => data ?? ({} as OverviewInsight), [data])
    const percentage = (Number(orgInsightData?.total_expenses) / 30000000) * 100

    const handleClick = () => {
        setShowModal(true)
    }

    return (
        <>
            <div>
                <div className='flex justify-between items-center mb-4'>
                    <span className='font-semibold text-xl'>Key insights</span>

                    <div className='bg-[#fff] border border-[#DADCE0] rounded-lg'>
                        <Select onValueChange={noop} value='7 days'>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[9rem]'>
                                <SelectItem value='7 days'>Last 7 days</SelectItem>
                                <SelectItem value='1 month'>Last 1 Month</SelectItem>
                                <SelectItem value='3 months'>Last 3 Months</SelectItem>
                                <SelectItem value='6 months'>Last 6 Months</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                {!isAllowedToSeeBalance ? (
                    <section className='flex items-center gap-4 mb-8'>
                        <div className='px-4 py-5 rounded-2xl border border-[#DADCE0] bg-white flex flex-col h-[180px]  w-full'>
                            <div className='flex gap-4 items-center'>
                                <img src={expensesIcon2} alt='' />
                                <div>
                                    <p className='text-[#5E5E5E] text-sm font-medium'>Total expense</p>
                                    <p className='text-[#202020] font-semibold text-xl'>
                                        {formatAmount(orgInsightData?.total_expenses)}
                                    </p>
                                </div>
                            </div>
                            <div className='h-[16px] w-full mt-10 rounded flex items-center bg-[#f5f5f5] relative'>
                                <div
                                    className='h-full bg-[#19C37D] rounded-l'
                                    style={{ width: `${percentage > 39 ? '40' : percentage}%` }}
                                ></div>
                                <p className='text-[12px] text-[#5E5E5E] translate-y-7 w-[40%] text-left absolute'>
                                    On Track
                                </p>

                                {percentage > 39 && (
                                    <div
                                        className='h-full  bg-[#DEB145]'
                                        style={{ width: `${percentage > 69 ? '30' : Number(percentage - 39)}%` }}
                                    ></div>
                                )}
                                <p className='text-[12px] w-[30%]  text-[#5E5E5E] translate-y-7 text-center absolute left-[40%]'>
                                    Warning
                                </p>
                                {percentage > 69 && (
                                    <div
                                        className={`h-full bg-[#CF491E] ${
                                            percentage > 100 ? 'rounded-r' : ''
                                        } overflow-hidden rounded-r `}
                                        style={{ width: `${percentage > 100 ? '30' : Number(percentage - 69)}%` }}
                                    ></div>
                                )}
                                <p className='text-[12px] w-[30%]  text-[#5E5E5E] translate-y-7 text-right absolute left-[70%]'>
                                    Critical
                                </p>
                            </div>
                        </div>
                        <div className='w-1/4'>
                            <div className='p-4 rounded-lg border border-[#DADCE0] bg-white flex gap-4 items-center mb-4'>
                                <img src={employeesIcon} alt='' />
                                <div>
                                    <p className='text-[#5E5E5E] text-sm font-medium'>Users</p>
                                    <p className='text-[#202020] font-semibold text-xl'>
                                        {orgInsightData?.no_of_employees}
                                    </p>
                                </div>
                            </div>
                            <div className='p-4 rounded-lg border border-[#DADCE0] bg-white flex gap-4 items-center'>
                                <img src={transactionIcon2} alt='' />
                                <div>
                                    <p className='text-[#5E5E5E] text-sm font-medium'>Transaction count</p>
                                    <p className='text-[#202020] font-semibold text-xl'>
                                        {' '}
                                        {orgInsightData?.transactions}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    <section className='grid grid-cols-2 gap-[20px] mb-6'>
                        {/*==== Wallet balanace ====*/}
                        <div className='col-span-1 bg-[#454ADE] text-white px-[22px] py-10 flex flex-col bg-wallet bg-cover bg-left-bottom rounded-lg '>
                            <div className='flex justify-between'>
                                <div className='mb-10 flex items-center gap-8'>
                                    <div className='hidden xl:block'>
                                        <img src={walletIcon} alt='icon' className='min-w-[3rem]' />
                                    </div>
                                    <div>
                                        <h3 className='mb-1 font-semibold text-xl 2xl:text-2xl text-[#DADCE0]'>
                                            Wallet balance
                                        </h3>
                                        {
                                            <p className='text-[30px] xl:text-[40px] font-extrabold'>
                                                {isLoading ? (
                                                    <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0'>
                                                        <Skeleton />
                                                    </SkeletonTheme>
                                                ) : (
                                                    formatAmount(orgInsightData?.wallet_balance)
                                                )}
                                            </p>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <button
                                        className='text-right font-medium text-xs xl:text-sm'
                                        onClick={() => setShowbankDetails(true)}
                                    >
                                        View bank details
                                    </button>
                                </div>
                            </div>

                            <div className='grid grid-cols-2 gap-7 xl:gap-[50px] py-2 text-lg xl:text-xl'>
                                <button
                                    className='bg-[#6469F0] py-4 rounded flex items-center justify-center '
                                    onClick={handleClick}
                                >
                                    <PlusSquare className='mr-2' size={20} />
                                    Fund wallet
                                </button>
                                <button
                                    className=' py-4 rounded text-white bg-black flex items-center justify-center '
                                    onClick={() => setShowTransferModal(true)}
                                >
                                    <ArrowUpRight className='mr-2' size={20} />
                                    Transfer
                                </button>
                            </div>
                        </div>

                        <div className='col-span-1  grid-rows-2 gap-[20px]'>
                            {/*==== Total expenses ====*/}
                            <TotalExpenses orgInsight={orgInsightData} />

                            <div className='row-span-1 grid grid-cols-2 gap-4 xl:gap-[20px] h-fit '>
                                {/*==== No. of employees ====*/}
                                <div className='col-span-1 bg-[#fff] rounded-xl border border-[#DADCE0] p-3 xl:p-4 flex gap-4 items-center py-3 xl:py-6'>
                                    <div className=''>
                                        <img src={employeesIcon} alt='icon' className='w-10 xl:w-[3rem]' />
                                    </div>
                                    <div>
                                        <h3 className='text-[#5e5e5e] mb-1'>Users</h3>
                                        <p className='text-xl'>{orgInsightData?.no_of_employees}</p>
                                    </div>
                                </div>

                                {/*==== Cards issued ====*/}
                                <div className='col-span-1 bg-[#fff] rounded-xl border border-[#DADCE0] p-3 xl:p-4 flex gap-4 items-center py-3 xl:py-6'>
                                    <div className=''>
                                        <img src={transactionIcon} alt='icon' className='w-10 xl:w-[3rem]' />
                                    </div>
                                    <div>
                                        <h3 className='text-[#5e5e5e] mb-1'>Transactions</h3>
                                        <p className='text-xl'>
                                            {orgInsightData?.transactions}{' '}
                                            {/* <span className='text-sm font-medium'> (This month)</span> */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {/* Fund wallet modal  */}

                {showModal && <FundWalletRoot setShowModal={setShowModal} />}
                {showTransferModal && <TransferFundsRoot closeModal={setShowTransferModal} />}
            </div>
            <section className='w-full'>
                <GetStarted orgInsight={orgInsightData} />
            </section>

            {showBankDetails && (
                <ShowBankDetails closeModal={setShowbankDetails} bankDetails={orgVirtualAccount?.[0]} />
            )}
        </>
    )
}

export default OverviewInsights
