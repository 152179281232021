import { useState } from 'react'
import BusinessSubscriptionInfo from './BusinessSubscriptionpartials/BusinessSubscriptionInfo'
import BusinessSubscriptionUsage from './BusinessSubscriptionpartials/BusinessSubscriptionUsage'

export default function BusinessSubscription() {
    const [step, setStep] = useState(1)
    return (
        <section className='mt-10 lg:mr-5'>
            <div>
                <h1 className='flex items-center gap-3 font-medium'>Subscription</h1>
                <p className='text-sm text-gray1 mt-1'>Manage and monitor the subscription of this business </p>
            </div>

            <section className='flex flex-col lg:flex-row mt-10 gap-8'>
                <div
                    className='w-48 h-fit bg-white flex flex-col gap-4 rounded-xl p-5  text-sm items-start shadow-sm
                    '
                >
                    {['Information', 'Features usage'].map((data, idx) => (
                        <button onClick={() => setStep(idx + 1)} className={step === idx + 1 ? 'text-brand' : ''}>
                            {data}
                        </button>
                    ))}
                </div>

                {step === 1 && <BusinessSubscriptionInfo />}
                {step === 2 && <BusinessSubscriptionUsage />}
            </section>
        </section>
    )
}
