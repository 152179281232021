import React from 'react'
import ModalWrapper from '../ModalWrapper'
import { Button } from '@/components/Button'
import { TransactionType } from '@/types/transactions'
import { useMutatateRemoveTransactionNote } from '@/services/transactions/mutations'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    transaction?: TransactionType
}

export const RemoveNoteModal = ({ closeModal, transaction }: Props) => {
    const { mutate: removeNoteFn, isLoading } = useMutatateRemoveTransactionNote({
        queryParams: {
            transaction_id: (transaction?.id ?? transaction?._id) as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Note removed successfully', state: 'success' })
            closeModal(false)
        },
    })
    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>Are you sure you want to remove this note?</p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <button type='button' className='min-h-[50px] px-5 mx-3' onClick={() => closeModal(false)}>
                        Cancel
                    </button>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isLoading}
                        onClick={() => {
                            removeNoteFn({})
                        }}
                    >
                        Yes, remove this note
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
