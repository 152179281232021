import { prodEnvironment } from '@/utils/utils'

export const filterType = {
    ngn: 'NGN',
    usd: 'USD',
    cad: 'CAD',
}
export const filterAccountBtn = [
    {
        desc: 'NGN Accounts',
        id: filterType.ngn,
    },
    {
        desc: 'USD Accounts',
        id: filterType.usd,
        hidden: prodEnvironment(),
    },
    {
        desc: 'CAD Accounts',
        id: filterType.cad,
        hidden: prodEnvironment(),
    },
]
