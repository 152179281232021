import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useState } from 'react'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { useVerifyVaultPassword } from '@/services/vault/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { useNavigate } from 'react-router-dom'
import { VaultItem } from '@/services/vault/types'
import locked from '@/assets/ic-folder-locked-dark.svg'
import { ActivateVaultModal } from './ActivateVaultModal'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    folder: VaultItem
}

export const VerifyVaultPassword = ({ closeModal, folder }: Props) => {
    const [formState, setFormState] = useState({
        password: '',
    })
    const [reset, setReset] = useState(false)
    const navigate = useNavigate()
    const isDisabled = formState.password.length <= 0

    const { mutate: verifyFn, isLoading: isVerifing } = useVerifyVaultPassword({
        onSuccess: () => {
            toastHandler({ message: `Password correct`, state: 'success' })
            navigate(`?folder=${folder?.id}${folder.parent !== folder.vault ? `&parent=${folder.parent}` : ''}`)
            closeModal(false)
        },
    })

    const handleButton = () => {
        verifyFn({
            id: folder.vault,
            password: formState.password,
        })
    }

    return (
        <ModalWrapper
            variants={undefined}
            formId='verifyPasswordForm'
            closeModal={() => closeModal(false)}
            shouldConfirmClose
        >
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <div className='flex items-center gap-3'>
                        <img src={locked} alt='' />
                        <span className='flex-1 text-xl font-medium'>{folder.name}</span>
                    </div>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <form id='verifyPasswordForm' className='mt-4 px-6' onSubmit={e => e.preventDefault()}>
                    <p className=' font-medium'>
                        You are trying to access a file in a personal folder, kindly input your password
                    </p>
                    <div className='pt-5 overflow-y-scroll'>
                        <div className={`flex flex-col flex-1 gap-3`}>
                            <label className='flex items-center gap-1 text-sm text-gray7'>
                                Password
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='text'
                                placeholder={`Enter your password`}
                                name='name'
                                value={formState.password}
                                onChange={e => {
                                    setFormState(prev => ({ ...prev, password: e.target.value }))
                                }}
                                className='border-[#DADCE0] h-[3rem]'
                            />
                        </div>
                        <p className='text-sm font-semibold mt-6 mb-5 text-center'>
                            Forgot password?{' '}
                            <span className='text-brand' onClick={() => setReset(true)}>
                                Reset here
                            </span>
                        </p>
                    </div>
                </form>
                <div className='flex items-center border-t border-[#DADCE0] px-5 py-3 justify-between'>
                    <Button
                        className='w-full'
                        disabled={isDisabled || isVerifing}
                        onClick={handleButton}
                        loading={isVerifing}
                    >
                        Confirm
                    </Button>
                </div>
                {reset && <ActivateVaultModal closeModal={setReset} vault_id={folder.vault} resetPassword />}
            </main>
        </ModalWrapper>
    )
}
