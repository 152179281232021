import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import ModalWrapper from '../ModalWrapper'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { TransactionType } from '@/types/transactions'
import { capitalizeText } from '@/utils/string'
import { useMutateRepeatTransaction, useMutateRetryTransaction } from '@/services/transactions/mutations'
import { toastHandler } from '@/components/utils/Toast'
import usePersistedState from '@/hooks/usePersistedState'
import { useSendTransferOtp } from '@/services/payout/mutations'
import { useEffect, useState } from 'react'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    transactionDetails?: TransactionType
}
const RedoTransaction = ({ closeModal, transactionDetails }: Props) => {
    const [timer, setTimer] = usePersistedState({ key: 'payrollTimer', defaultValue: 120 })
    const { mutate: sendTransferOtpFn, isLoading: isResendingOtp } = useSendTransferOtp({})
    const [otp, setOtp] = useState('')
    const transaction_details = [
        { name: 'Transaction ID', desc: transactionDetails?.transaction_id },
        {
            name: 'Amount',
            desc:
                transactionDetails?.ownerTransactionType === 'CREDIT'
                    ? `+ ${formatAmount(transactionDetails?.credit?.amount)}`
                    : `- ${formatAmount(transactionDetails?.debit?.amount)}`,
        },
        { name: 'Fee', desc: formatAmount(transactionDetails?.fee) },

        {
            name: 'Date',
            desc: (
                <>
                    <span className='block whitespace-nowrap'>{format(new Date(), 'PP hh:mm aa')}</span>
                </>
            ),
        },

        {
            name: 'Initiated by',
            desc: `${transactionDetails?.initiated_by?.first_name} ${transactionDetails?.initiated_by?.last_name}`,
        },
        {
            name: 'Destination',
            desc: transactionDetails?.credit?.account?.user?.first_name
                ? `${capitalizeText(transactionDetails?.credit?.account?.user?.first_name)} ${capitalizeText(
                      transactionDetails?.credit?.account?.user?.last_name
                  )}`
                : transactionDetails?.metadata?.recipient
                  ? capitalizeText(transactionDetails?.metadata?.recipient?.name)
                  : 'Wallet Funding',
        },
    ]

    const { mutate: retryTransactionFn, isLoading: isRetryingTransaction } = useMutateRetryTransaction({
        queryParams: {
            transaction_id: transactionDetails?._id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Transaction retry in progress', state: 'success' })
            closeModal(false)
        },
    })

    const { mutate: repeatTransactionFn, isLoading: isRepeatingTransaction } = useMutateRepeatTransaction({
        queryParams: {
            transaction_id: transactionDetails?._id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Transaction repeat in progress', state: 'success' })
            closeModal(false)
        },
    })

    const handleClick = () => {
        if (transactionDetails?.status === 'COMPLETED') {
            repeatTransactionFn({})
            return
        }
        retryTransactionFn({})
    }
    useEffect(() => {
        sendTransferOtpFn({})
    }, [])
    return (
        <ModalWrapper variants={undefined}>
            <main className='min-h-[30vh] h-[38rem] w-screen md:w-[38rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 '>
                    <span className='flex-1 text-center text-xl font-medium'>
                        {transactionDetails?.status === 'COMPLETED' ? 'Repeat' : 'Retry'} Transaction
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='overflow-scroll'>
                    <div className='px-[23px] lg:px-[46px] mt-4 mb-4 '>
                        <div className='bg-[#EFEFF9] p-5 rounded-xl text-[#202020] flex flex-col gap-1 mb-6'>
                            {transaction_details.map(field => (
                                <p className='text-base font-semibold'>
                                    {field.name}
                                    <span className='font-medium'>: {field.desc}</span>
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
                <section className='px-[23px] lg:px-[46px]'>
                    <p className='font-medium text-[#202020] text-[14px]'>
                        An OTP has been sent to your email to verify this action. Kindly enter OTP to proceed
                    </p>
                    <form>
                        <div className='flex flex-col md:flex-row mt-4 '>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                    Enter OTP
                                </label>
                                <input
                                    className='h-[3.5rem] text-dark rounded-md border border-gray1 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed'
                                    type='number'
                                    placeholder='Enter your OTP'
                                    onChange={e => {
                                        const { value } = e.target
                                        setOtp(value)
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                    <p className='mt-4 text-sm font-medium'>
                        I did not receive OTP.{' '}
                        <button
                            className='text-[#454ADE] font-semibold disabled:text-[#A8A8A8]'
                            onClick={() => {
                                sendTransferOtpFn({})
                                setTimer(120)
                            }}
                            disabled={timer > 0}
                        >
                            {' '}
                            Resend
                        </button>{' '}
                        {timer !== 0 && (
                            <span className='text-[#A8A8A8]'>in {timer < 10 ? `0${timer}` : timer} secs</span>
                        )}
                    </p>
                </section>
                <section className='flex items-center justify-end border-t border-[#DADCE0] p-4 pr-12 fixed bottom-0 bg-white w-full pb-2 rounded-b-md'>
                    <Button
                        className='bg-[#19C37D] text-white px-8  rounded-lg text-[14px] min-w-[130px]'
                        loading={isRepeatingTransaction || isRetryingTransaction}
                        onClick={handleClick}
                        disabled={otp.length < 4 || isResendingOtp}
                    >
                        Proceed
                    </Button>
                </section>
            </main>

            {/* <main>
                <div className='p-10 pb-7 w-[33rem]'>
                    <h1 className='text-xl font-semibold text-[#202020] mb-3'>Confirm transaction</h1>
                    <div className='bg-[#F9F9F9] rounded-xl p-5 pb-4  mb-8'>
                        <div className='mb-4'>
                            <h3 className='text-[#5E5E5E] font-semibold'>Amount</h3>

                            <p className='text-xl font-medium'>{transaction_details?.[1].desc}</p>
                        </div>
                        <h3 className='text-[#5E5E5E] font-semibold mb-3'>Receipient</h3>

                        <div className='flex gap-5 gap-y-3 flex-wrap'>
                            <div>
                                <h3 className=' font-semibold'>Bank name</h3>
                                <p className='text-[#5E5E5E] text-base'>{'' ?? 'N/A'}</p>
                            </div>
                            <div>
                                <h3 className='font-semibold'>Account number</h3>
                                <p className='text-base text-[#5E5E5E]'>{'' ?? ' N/A'}</p>
                            </div>
                            <div>
                                <h3 className=' font-semibold'>Account name</h3>
                                <p className=' text-[#5E5E5E] text-base'>{'' ?? 'N/A'}</p>
                            </div>
                        </div>
                    </div>
                    <section className=''>
                        <p className='mt-[10px] font-medium text-[#202020] text-[14px]'>
                            An OTP has been sent to your email to verify this action. Kindly enter OTP to proceed
                        </p>
                        <form>
                            <div className='flex flex-col md:flex-row mt-6 '>
                                <div className='flex flex-col flex-1 gap-3'>
                                    <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                        Enter OTP
                                    </label>
                                    <input
                                        className='h-[3.5rem] text-dark rounded-md border border-gray1 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed'
                                        type='number'
                                        placeholder='Enter your OTP'
                                        onChange={e => {
                                            const { value } = e.target
                                            setOtp(value)
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                        <p className='mt-4 text-sm font-medium'>
                            I did not receive OTP.{' '}
                            <button
                                className='text-[#454ADE] font-semibold disabled:text-[#A8A8A8]'
                                onClick={() => {
                                    sendTransferOtpFn({})
                                    setTimer(120)
                                }}
                                disabled={timer > 0}
                            >
                                {' '}
                                Resend
                            </button>{' '}
                            {timer !== 0 && (
                                <span className='text-[#A8A8A8]'>in {timer < 10 ? `0${timer}` : timer} secs</span>
                            )}
                        </p>
                    </section>
                    <div className='flex justify-end gap-6 mt-12'>
                        <Button
                            className='bg-transparent min-w-[100px] text-[#202020] hover:bg-transparent'
                            onClick={() => {
                                closeModal(false)
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className='bg-[#19C37D] hover:bg-[#255541] min-w-[100px] px-4'
                            loading={isRepeatingTransaction || isRetryingTransaction}
                            type='submit'
                            disabled={otp.length < 4 || isResendingOtp}
                            onClick={handleClick}
                        >
                            Confirm transfer
                        </Button>
                    </div>
                </div>
            </main> */}
        </ModalWrapper>
    )
}

export default RedoTransaction
