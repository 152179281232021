import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { usePaystack } from '@/hooks/usePaystackPayment'
import { useVerifyFundingMutation } from '@/services/funding/mutation'
import { calculateFundingFee, formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { useState } from 'react'
import { TbCurrencyNaira } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

interface Step1Props {
    toggleStep: (step: number) => void
    setAmount: React.Dispatch<React.SetStateAction<number>>
    amount: number
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const accepptedAmt = 25000
const minAmount = 1000
const Step1 = ({ setAmount, amount, setShowModal }: Step1Props) => {
    const { selectedAccount } = useAuthContext()
    const navigate = useNavigate()
    const { mutate: verifyFundingFn } = useVerifyFundingMutation({
        onSuccess: () => {
            setShowModal(false)
        },
    })
    const kybCompleted = selectedAccount?.org?.kyb_completed
    const [fee, setFee] = useState<number>(0)
    const [inAmount, setInAmount] = useState<number>(0)
    const { initializePayment } = usePaystack({
        customConfig: {
            metadata: {
                base_amt: amount,
                fee: fee,
            },
            fee,
            amount: (Number(amount) + fee) * 100,
            email: selectedAccount?.user?.email as string,
            publicKey: import.meta.env.VITE_PAYSTACK_PUB_KEY,
        },
    })

    const onError = () => {
        throw new Error('An Error Occured')
    }

    const handleFundWallet = () => {
        if (amount <= 0) {
            toastHandler({ message: 'Please enter an amount', state: 'error' })
            return
        }

        initializePayment((reference: any) => {
            verifyFundingFn({ reference: reference?.reference, org: selectedAccount?.org?.id })
            toastHandler({ message: 'Successfully funded your account', state: 'success' })
        }, onError)
    }

    return (
        <aside className='pb-12 flex flex-col p-4'>
            <div className='flex-1'>
                <div className='mb-2 mt-4'>
                    <label className='text-[12px] font-medium'>
                        Amount to fund <span className='text-[red]'>*</span>{' '}
                    </label>
                    <div className='flex border border-[#DADCE0] w-full h-[48px] rounded-lg mt-2 overflow-hidden'>
                        <div className='w-[10%] flex items-center justify-center text-[#838383]'>
                            <TbCurrencyNaira fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='0.00'
                            className='outline-none w-[90%] h-full'
                            required
                            min={1}
                            value={formatAmount(inAmount).replaceAll('₦', '')}
                            onChange={e => {
                                const value_entered = Number(formatAmountWithoutSymbols(e.target.value))

                                if (!kybCompleted && Number(value_entered / 100) > accepptedAmt) return
                                setInAmount(value_entered)
                                setAmount(value_entered / 100)
                                setFee(calculateFundingFee(value_entered / 100))
                            }}
                        />
                    </div>
                    {amount > 0 && (
                        <span className='text-[12px] text-[#838383]'>
                            Fee: {formatAmount(calculateFundingFee(amount) * 100)}
                        </span>
                    )}
                </div>

                {amount < minAmount && <span className='text-errorRed text-xs mb-2 block'>Minimum amount: ₦1,000</span>}
            </div>

            <button
                className='bg-[#454ADE] w-full h-[48px] rounded-lg text-white disabled:opacity-40'
                onClick={handleFundWallet}
                disabled={amount < minAmount}
            >
                Next
            </button>

            {!kybCompleted && (
                <p className='text-red-500 text-xs mt-2 font-medium max-w-md'>
                    The maximum amount that can be funded is 25,000 Naira, as your KYB isn't
                    <button
                        onClick={() => {
                            navigate('/dashboard/settings/company/kyb')
                        }}
                        className='underline hover:opacity-60 mx-1'
                    >
                        complete kyb
                    </button>
                </p>
            )}
        </aside>
    )
}

export default Step1
