import React from 'react'
import { TransactionStatus } from '../const'
import { capitalizeText } from '@/utils/string'

interface TransactionStatusBadgeProps {
    status: TransactionStatus
}

const TransactionStatusBadge: React.FC<TransactionStatusBadgeProps> = ({ status }) => {
    let badgeColor: string

    switch (status) {
        case TransactionStatus.successful:
            badgeColor = 'bg-[#EFFFED] text-[#19C37D]'
            break
        case TransactionStatus.failed:
            badgeColor = 'bg-[#FFEDED] text-[#CF491E]'
            break
        default:
            badgeColor = 'bg-[#EFEFF9] text-[#202020]/70'
            break
    }

    return (
        <div className={`px-5 py-1 rounded-2xl max-w-fit inline-flex items-center justify-center ${badgeColor}`}>
            {capitalizeText(status === TransactionStatus.successful ? 'successful' : status)}
        </div>
    )
}

export default TransactionStatusBadge
