import React, { useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { TransactionType } from '@/types/transactions'
import { Button } from '@/components/Button'
import { Textarea } from '@/components/Textarea'
import { useMutateAddTransactionNote } from '@/services/transactions/mutations'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    transaction?: TransactionType
}
export const AddNoteModal = ({ closeModal, transaction }: Props) => {
    const [note, setNote] = useState('')

    const { mutate: addTransactionNoteFn, isLoading } = useMutateAddTransactionNote({
        queryParams: {
            transaction_id: (transaction?.id ?? transaction?._id) as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Note added successfully', state: 'success' })
            closeModal(false)
        },
    })
    return (
        <ModalWrapper variants={undefined}>
            <main className='w-screen lg:w-[32rem] overflow-scroll '>
                <section className='flex items-center border-b border-[#DADCE0] p-3 px-5 justify-between '>
                    <span className=' text-center text-lg font-medium ml-2'>Add note</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-2 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <section className='p-4 px-7'>
                    <h2 className='font-medium'>Add note to this transaction</h2>
                    <p className='text-[#5e5e5e] text-sm'>You can only add one note per transaction</p>
                    <div>
                        <div className='flex items-center justify-between mt-5'>
                            <h3 className='font-medium '>Note</h3>
                            <p className='text-sm text-[#5e5e5e]'>
                                <span>{note?.length}</span>/120
                            </p>
                        </div>

                        <Textarea
                            placeholder=' Explain further if you wish to.'
                            className='h-24 mt-2 border-[#DADCE0] placeholder:text-[#A8A8A8]  w-full  text-dark rounded-xl border  py-2 px-3 text-sm'
                            value={note}
                            onChange={e => {
                                const { value } = e.target
                                setNote(value)
                            }}
                            maxLength={120}
                        />
                    </div>
                </section>
                <div className='m-4'>
                    <Button className='w-full' loading={isLoading} onClick={() => addTransactionNoteFn({ note })}>
                        Add note
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
