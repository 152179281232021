import { useAuthContext } from '@/context'
import { useLogoutUser } from '@/services/auth/mutations'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Logout() {
    const { logoutUser } = useAuthContext()
    const navigate = useNavigate()
    const { mutate: logoutFn } = useLogoutUser({
        onSuccess: () => {
            navigate('/login')
        },
    })

    useEffect(() => {
        logoutUser()
        logoutFn({})
    }, [])
    return <></>
}
