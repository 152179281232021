import { TeamsProp } from '@/types/employee'
import { createQuery } from '../api/query'
import { getInviteEmployeeDataProps, QueryOrgMembers, QueryTeamsInOrg } from './types'
import { keyApprovalPolicy, keyOrgMembers, keyOrgTeams, keyPendingEmployee, keyUpcomingBirthdays } from './mutations'
import { EmployeeType } from '@/types/org'
import { User } from '@/types/user'
import { ExpenseApprovalPolicy } from '../settings/types'

export const keyQueryTeamsByOrdId = (employeeId: string) => [`/get-teams-by-org-id`, employeeId]

export const useGetInviteDetails = createQuery<getInviteEmployeeDataProps>({
    key: ['invite-details'],
    url: '/get-invite-details',
})
export const useGetOrgMembers = createQuery<QueryOrgMembers>({
    key: keyOrgMembers,
    url: '/get-org-members',
})

export const useGetOrgMemberById = createQuery<EmployeeType[]>({
    key: keyQueryTeamsByOrdId,
    url: '/org-member-by-id',
})

export const useGetPendingInvites = createQuery<QueryOrgMembers>({
    key: keyPendingEmployee,
    url: '/get-org-members',
})

export const useGetTeamsInOrg = createQuery<QueryTeamsInOrg>({
    key: keyOrgTeams,
    url: '/get-teams-in-org',
})

export const getTeamByIdKey = (arg: string) => ['get-team-by-id', arg]

export const useGetTeamById = createQuery<TeamsProp>({
    key: getTeamByIdKey,
    url: '/get-team-by-id',
})

export const useGetUpcomingBirthday = createQuery<User[]>({
    key: keyUpcomingBirthdays,
    url: '/upcoming-birthdays',
})

export const useGetUserApprovalPolicies = createQuery<ExpenseApprovalPolicy[]>({
    key: keyApprovalPolicy,
    url: '/get-user-approval-polices',
})
