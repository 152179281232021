import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'

import { useGetBenefitCategories, useGetBenefitVendors } from '@/services/benefits/queries'
import { Control, Controller, UseFormWatch } from 'react-hook-form'
import { BenefitForm } from './helper'
import { useEffect } from 'react'
import { CgSpinner } from 'react-icons/cg'
import { OrgBenefitsVendors } from '@/types/benefits'

type Props = {
    control: Control<BenefitForm, any>
    watch: UseFormWatch<BenefitForm>
    setSelectedCategoryVendors: React.Dispatch<React.SetStateAction<OrgBenefitsVendors[]>>
}
const NewBenefitStep1 = ({ control, watch, setSelectedCategoryVendors }: Props) => {
    const selectedCategory = watch('category')

    const { data: orgBenefitCategories, isLoading: isLoadingCAtegories } = useGetBenefitCategories({})
    const {
        data: orgBenefitVendors,
        isLoading: isLoadingVendors,
        isRefetching,
        refetch,
    } = useGetBenefitVendors({
        enabled: !!selectedCategory,
        queryParams: {
            category: selectedCategory,
        },
        onSuccess: data => {
            setSelectedCategoryVendors(data)
        },
    })
    useEffect(() => {
        refetch()
    }, [selectedCategory])

    return (
        <div className='p-5 md:p-8 md:px-12 relative'>
            <h1 className='text-base md:text-xl font-semibold text-[#202020] mb-9'>
                Select benefit category and vendor
            </h1>
            <div className={`flex flex-col gap-4 mb-10 ${isLoadingCAtegories ? 'opacity-50' : 'opacity-100'}`}>
                <label htmlFor='firstName' className='text-[#202020] text-[14px] font-medium'>
                    Category <span className=''>*</span>
                </label>
                <Controller
                    name={'category'}
                    control={control}
                    render={({ field }) => (
                        <Select {...field} onValueChange={field.onChange}>
                            <SelectTrigger className='w-full h-[3.5rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                <SelectValue placeholder={'Select benefit category'} />
                            </SelectTrigger>
                            <SelectContent className='w-[39.4rem]'>
                                {orgBenefitCategories?.map(category => (
                                    <SelectItem value={category?.id}>{category?.label}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    )}
                />
            </div>
            {isLoadingVendors || isRefetching ? (
                <div>
                    <CgSpinner className='animate-spin w-8 h-8' />
                </div>
            ) : selectedCategory ? (
                <div className='flex flex-col gap-4'>
                    <label htmlFor='firstName' className='text-[#202020] text-[14px] font-medium'>
                        Select vendor <span className=''>*</span>
                    </label>
                    <Controller
                        name={'vendor'}
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                onValueChange={field.onChange}
                                className='flex gap-x-8 gap-y-4 justify-between flex-wrap'
                                disabled={!selectedCategory}
                            >
                                {orgBenefitVendors && orgBenefitVendors?.length > 0
                                    ? orgBenefitVendors?.map(vendor => (
                                          <label
                                              htmlFor={vendor?.id}
                                              className='flex items-center justify-between w-[45%] px-5 py-4 rounded-xl cursor-pointer'
                                              style={{ boxShadow: '0px 2px 100px 0px rgba(47, 47, 47, 0.05)' }}
                                          >
                                              <span className='cursor-pointer'>
                                                  {/* <img src={relianceHMO} alt='' /> */}
                                                  {vendor?.name}
                                              </span>
                                              <RadioGroupItem value={vendor?.id} id={vendor?.id} />
                                          </label>
                                      ))
                                    : 'No vendor found for this category'}
                            </RadioGroup>
                        )}
                    />
                </div>
            ) : null}
        </div>
    )
}

export default NewBenefitStep1
