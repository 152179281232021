
import TransactionHistoryTable from '@/modules/dashboard/Transactions/components/TransactionHistoryTable'
import { TransactionType } from '@/types/transactions'

type Props = {
    transactions: TransactionType[]
}
export default function PayHistoryTransactions({ transactions }: Props) {
    return (
        <section className='mt-[1.2rem]'>
            <div className={` transition-all rounded-lg `}>
                <TransactionHistoryTable transactions={transactions} />
            </div>
        </section>
    )
}
