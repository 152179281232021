import { PlansCheckmark } from '@/assets/assets.export'
import { Button } from '@/components'
import UpgradePlanModal from '@/components/Modals/Upgrade-plan'
import StatusColor from '@/components/utils/StatusColor'
import { useAuthContext } from '@/context'
import { useUpgradePlan } from '@/services/billing/mutations'
import { useState } from 'react'

type Props = {
    title: string
    desc: string
    amount?: string
    active?: boolean
    plans: string[]
    btn_title: string
    plan_id: string
    small?: boolean
}

export default function PlansCard({ title, desc, amount, active, plans, btn_title, plan_id, small }: Props) {
    const { selectedAccount } = useAuthContext()
    const orgSubscription = selectedAccount?.org?.subscription

    const [openSuccessModal, setopenSuccessModal] = useState(false)
    const { mutate: upgradePlanFn, isLoading } = useUpgradePlan({
        onSuccess: () => {
            setopenSuccessModal(true)
        },
    })
    const status = orgSubscription?.status

    return (
        <section className={`bg-white rounded-xl ${small ? 'p-3' : 'p-5 py-6'} min-h-[30rem]`}>
            <div className={`flex justify-between items-center ${small ? 'mb-4' : 'mb-6'}`}>
                <div>
                    <h4 className={`font-semibold ${small ? 'text-sm' : 'text-lg'}`}>{title}</h4>
                    <p className={`text-[#5E5E5E] ${small ? 'text-xs' : 'text-sm'} `}>{desc}</p>
                </div>
                {active && <StatusColor status={status as string} className='text-xs' />}
            </div>
            {amount && (
                <div className={`${small ? 'text-lg mb-4' : 'text-3xl mb-6'} font-semibold `}>
                    {amount}
                    <span className={`font-normal ${small ? 'text-xs' : 'text-sm'}`}>/ month</span>
                </div>
            )}
            <Button
                className={`w-full mb-6 disabled:bg-[#F9F9F9] disabled:text-[#A8A8A8] ${small ? 'text-xs' : 'text-sm'}`}
                disabled={active}
                loading={isLoading}
                size={small ? 'lg' : 'default'}
                onClick={() => {
                    upgradePlanFn({ plan_id })
                }}
            >
                {btn_title}
            </Button>
            <ul className='flex flex-col gap-2.5'>
                {plans.map(plan => (
                    <li className='flex items-center gap-2 text-xs font-medium  '>
                        <PlansCheckmark className='w-3 h-3' /> {plan}
                    </li>
                ))}
            </ul>
            {openSuccessModal && (
                <UpgradePlanModal planId={plan_id} initialStep={2} closeModal={() => setopenSuccessModal(false)} />
            )}
        </section>
    )
}
