import AssignLeaveModal from '@/components/Modals/Payroll/LeaveRequest/AssignLeaveModal'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext, usePayrollContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetLeaveAnalytics, useGetLeaveById } from '@/services/payroll/queries'
import { LeaveRequestType } from '@/services/payroll/types'
import { capitalizeText } from '@/utils/string'
import { format, startOfDay } from 'date-fns'
import { ArrowLeft } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LeavePermissionWrapper from './LeavePermissionWrapper'
import { LeaveType } from '@/types/payroll'

export default function LeaveMoreDetails() {
    const navigate = useNavigate()
    const { assignLeaveModal, setAssignLeaveModal } = usePayrollContext()
    const { selectedAccount } = useAuthContext()

    const { leave_id } = useParams()
    const { data, isLoading, isRefetching, isFetching } = useGetLeaveById({
        queryParams: {
            leave_id: leave_id as string,
        },
    })

    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const orgMembers = useMemo(() => org_members ?? [], [org_members])

    const leaveData = useMemo(() => data, [data])

    const { data: leaveAnalytics, isLoading: isGettingLeaveAnalytics } = useGetLeaveAnalytics({
        enabled: !!leaveData?.id,
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            account: (leaveData?.account?._id ?? leaveData?.account?.id) as string,
        },
    })

    const calculateDifference = (date1: string, date2: string) => {
        const parsedDate1 = new Date(date1)
        const parsedDate2 = new Date(date2)

        if (!isNaN(parsedDate1.getTime()) && !isNaN(parsedDate2.getTime())) {
            const timeDifference = parsedDate2.getTime() - parsedDate1.getTime()
            const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
            return daysDifference
        }
    }

    const leave_details = [
        {
            name: 'Name',
            desc: `${leaveData?.account?.user?.first_name} ${leaveData?.account?.user?.last_name}`,
        },
        { name: 'Type', desc: `${capitalizeText(leaveData?.leave_type)}` },
        {
            name: 'Leave period',
            desc:
                leaveData?.start_date &&
                leaveData?.end_date &&
                `${format(startOfDay(new Date(leaveData?.start_date?.slice(-1)?.[0])), 'dd/MM/yyyy')} - ${format(
                    new Date(leaveData?.end_date?.slice(-1)?.[0]),
                    'dd/MM/yyyy'
                )}`,
        },
        {
            name: 'Days count',
            desc:
                leaveData?.start_date &&
                `${calculateDifference(
                    leaveData?.start_date?.slice(-1)?.[0] as unknown as string,
                    leaveData?.end_date?.slice(-1)?.[0] as unknown as string
                )} day(s)`,
        },
        {
            name: 'Description',
            desc: `${capitalizeText(leaveData?.reason) ?? 'N/A'}`,
        },
        {
            name: 'Status',
            desc: (
                <span
                    className={`${
                        leaveData?.status === 'approved'
                            ? 'text-[#19C37D]'
                            : leaveData?.status === 'pending'
                              ? 'text-[#A89011]'
                              : 'text-[#CF491E]/90'
                    }`}
                >
                    {capitalizeText(leaveData?.status)}
                </span>
            ),
        },
        {
            name: 'Request date',
            desc: leaveData?.createdAt && format(new Date(leaveData?.createdAt), 'dd/MM/yyyy'),
        },
        {
            name: 'Approved date',
            desc: leaveData?.approval_date ? format(new Date(leaveData?.approval_date), 'dd/MM/yyyy') : 'N/A',
        },
        {
            name: 'Approver(s)',
            desc: (
                <span>
                    {leaveData?.approvers?.map(approver => (
                        <span className='block text-sm' key={approver?.id}>
                            {`${approver?.account?.user?.first_name} ${approver?.account?.user?.last_name}`}
                        </span>
                    ))}
                </span>
            ),
        },
    ]

    const leaveAnalyticsData = [
        {
            name: LeaveType.CONFERENCE,
            desc:
                leaveAnalytics?.user_leave_days?.leave_types?.find(leave => leave.leave_type === LeaveType.CONFERENCE)
                    ?.days_remaining ?? leaveAnalytics?.org_leave_days?.[LeaveType.CONFERENCE],
        },
        {
            name: LeaveType.PARENTAL,
            desc:
                leaveAnalytics?.user_leave_days?.leave_types?.find(leave => leave.leave_type === LeaveType.PARENTAL)
                    ?.days_remaining ?? leaveAnalytics?.org_leave_days?.[LeaveType.PARENTAL],
        },
        {
            name: LeaveType.SICK_DAYS,
            desc:
                leaveAnalytics?.user_leave_days?.leave_types?.find(leave => leave.leave_type === LeaveType.SICK_DAYS)
                    ?.days_remaining ?? leaveAnalytics?.org_leave_days?.[LeaveType.SICK_DAYS],
        },
        {
            name: LeaveType.VACATION,
            desc:
                leaveAnalytics?.user_leave_days?.leave_types?.find(leave => leave.leave_type === LeaveType.VACATION)
                    ?.days_remaining ?? leaveAnalytics?.org_leave_days?.[LeaveType.VACATION],
        },
        {
            name: LeaveType.MISCELLANEOUS,
            desc:
                leaveAnalytics?.user_leave_days?.leave_types?.find(
                    leave => leave.leave_type === LeaveType.MISCELLANEOUS
                )?.days_remaining ?? leaveAnalytics?.org_leave_days?.[LeaveType.MISCELLANEOUS],
        },
    ]

    if (isLoading) return <ProgressBar />

    return (
        <div className='h-screen overflow-scroll'>
            <section className='mx-[1.4rem] lg:mx-[2.7rem] py-3 pt-2 mb-6 '>
                <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                    <ArrowLeft />
                    back
                </div>
                <section className=' bg-white rounded-xl px-7 py-6 pb-11 mb-24'>
                    <div className='border-b border-[#DADCE0] pb-3 flex flex-col lg:flex-row gap-5 lg:gap-0 items-start lg:items-center justify-between'>
                        <h2 className=' font-semibold text-lg'>
                            {leave_details[0].desc} - {leave_details[1].desc}
                        </h2>
                    </div>
                    {false && (
                        <div className='bg-[#F8F1DE] p-3 mt-6 rounded-xl'>
                            <h1 className='text-[#202020] font-semibold'>Attention. Missing details</h1>
                            <p className='text-sm text-[#5E5E5E] mt-1'>
                                This user has exhausted their Vacation leave allowance
                                <span className='font-semibold'> (15 days)</span>.
                            </p>
                        </div>
                    )}
                    <div className='flex flex-wrap max-w-[60rem] gap-x-[5rem] gap-y-8 mt-8 '>
                        {leave_details.map((detail, idx) => (
                            <>
                                <div
                                    className={`'flex flex-col ${detail.name === 'Description' ? 'w-[80%]' : ''}`}
                                    key={idx}
                                >
                                    <h4 className='text-md font-semibold mb-1'>{detail.name}</h4>
                                    <span className='text-[#5E5E5E] text-[1rem]'>{detail.desc}</span>
                                </div>
                                {detail.name === 'Description' && <br className='hidden lg:block' />}
                            </>
                        ))}
                    </div>
                    <LeavePermissionWrapper loading={isRefetching || isFetching} leaveData={leaveData} />

                    {isGettingLeaveAnalytics ? (
                        <ProgressBar />
                    ) : (
                        <div className='mt-16'>
                            <div className='border-b border-[#DADCE0] pb-3'>
                                <h2 className=' font-semibold text-lg'>Leave balance</h2>
                            </div>
                            <div className='mt-6'>
                                <div className='grid grid-cols-2 md:grid-cols-5 gap-4 w-ful lg:w-[90%]'>
                                    {leaveAnalyticsData?.map(leaveData => (
                                        <div className='border border-[#DADCE0] bg-[#F4FAFF] rounded-lg py-[10px] px-4 flex flex-col gap-3 '>
                                            <span className='text-[#5E5E5E]'>{capitalizeText(leaveData?.name)}</span>
                                            <span className='text-3xl font-semibold '>
                                                {leaveData?.desc} <span className='text-base font-medium'>days</span>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </section>
                {assignLeaveModal && (
                    <AssignLeaveModal
                        closeModal={setAssignLeaveModal}
                        orgMembers={orgMembers}
                        edit={true}
                        leaveData={leaveData as LeaveRequestType}
                    />
                )}
            </section>
        </div>
    )
}
