import { EmployeeType } from '@/types/org'
import { capitalize } from 'lodash'
import React from 'react'
import { capitalizeText } from '../../../../../utils/string'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { X } from 'lucide-react'
import { useAuthContext } from '@/context'
import { useMutationRevokeOwnerAccess } from '@/services/settings/mutations'
import { CgSpinner } from 'react-icons/cg'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    orgMember: EmployeeType
    setSelectedMembers: React.Dispatch<React.SetStateAction<string[]>>
    editWalletAccess: boolean
    setting_id: string
}
export default function WalletAccessCard({ orgMember, setSelectedMembers, editWalletAccess, setting_id }: Props) {
    const { selectedAccount } = useAuthContext()
    const { mutate: removeUsersToWalletFn, isLoading: isRemovingUsers } = useMutationRevokeOwnerAccess({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            setting_id: setting_id as string,
            account: orgMember?.id,
        },
        onSuccess: () => {
            setSelectedMembers(orgMembers => orgMembers.filter(member => member !== orgMember.id))
            toastHandler({ message: 'User removed successfully', state: 'success' })
        },
    })

    const handleRemoveMember = () => {
        removeUsersToWalletFn({})
    }
    return (
        <div
            className={`flex bg-[#EFEFF9] rounded-lg w-[27rem] mb-2 p-3 items-center font-medium ${
                editWalletAccess ? 'w-[27rem]' : 'max-w-[20rem] gap-5'
            } ${isRemovingUsers ? 'opacity-50' : 'opacity-100'}`}
        >
            <div className='whitespace-nowrap flex-1 gap-5 text-sm max-w-[11rem]'>
                {capitalize(orgMember?.user.first_name)} {capitalizeText(orgMember?.user?.last_name)}
            </div>
            <div className='flex-1 flex justify-between items-center'>
                <Select defaultValue='view'>
                    <SelectTrigger className=' btn_truncate border-none bg-[#D5D5F5] text-[#5E5E5E] text-xs truncate gap-2 py-3 max-w-[9rem]'>
                        <SelectValue className='truncate' />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value='view'>View Only</SelectItem>
                        <SelectItem value='view_nd_deposit'>View & deposit only</SelectItem>
                        <SelectItem value='view_nd_deposit_nd_transfer'>View, deposit and Transfer</SelectItem>
                    </SelectContent>
                </Select>
                {editWalletAccess && (
                    <span className=' text-right'>
                        {isRemovingUsers ? (
                            <CgSpinner className='animate-spin ml-auto bg-[#FFEDED] text-[#CF491E] box-content rounded-full p-2 w-4 cursor-pointer h-4 flex-1' />
                        ) : (
                            <X
                                className='ml-auto bg-[#FFEDED] text-[#CF491E] box-content rounded-full p-2 w-4 cursor-pointer h-4 flex-1'
                                onClick={handleRemoveMember}
                            />
                        )}
                    </span>
                )}
            </div>
        </div>
    )
}
