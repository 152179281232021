import { createColumnHelper, ColumnDef, ColumnSort } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import { useState } from 'react'
import { ApprovalType, ExpenseType } from '@/types/expense'
import { useAuthContext, useExpenseContext } from '@/context'
import { format } from 'date-fns'
import { ExpenseStatus } from '../const'
import ExpenseStatusBadge from './ExpenseStatus'
import { Pagination } from '@/types/utils'
import { formatAmountNoSymbol } from '@/utils/money'
import { useNavigate } from 'react-router-dom'
import { capitalizeText } from '@/utils/string'
import ExpenseFormRoot from '@/components/Modals/Expense/CreateExpense/ExpenseFormRoot'
import TransactionEmptyStateIcon from '@/assets/v2dashboard/recent-transactions.svg'

interface ExpenseListProps {
    expenses: ExpenseType[]
    pagination_data?: Pagination
    setDeclineExpense?: (userId: string) => void
    setApproveExpense?: (userId: string) => void
    hasAPrimaryBank: boolean
}

const ExpenseListTable = ({
    expenses,
    setDeclineExpense,
    setApproveExpense,
    pagination_data,
    hasAPrimaryBank,
}: ExpenseListProps) => {
    const navigate = useNavigate()

    const columnHelper = createColumnHelper<ExpenseType>()

    const {
        setSort,
        setPageNumber,
        pageNumber,
        setPageNumberClicked,
        setSelectedExpense,
        selectedExpense,
        categories,
    } = useExpenseContext()
    const [editModal, setEditModal] = useState(false)

    const handleSort = (sort: ColumnSort) => {
        setSort(sort)
    }

    const { selectedAccount } = useAuthContext()

    //context api to sort the table
    const columns: ColumnDef<ExpenseType, any>[] = [
        columnHelper.accessor('expense_id', {
            header: () => {
                return <p>Expense ID</p>
            },
            cell: cell => cell.renderValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('merchant_name', {
            header: () => {
                return <p>Merchant Name</p>
            },
            cell: cell => cell.renderValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <p>Date</p>,
            cell: cell => format(new Date(cell.renderValue()), 'dd/MM/yyyy'),
            enableSorting: true,
        }),
        columnHelper.accessor('category.label', {
            header: () => <p className='max-w-[7rem]'>Category</p>,
            cell: cell => <div className='max-w-[7rem] truncate'>{cell.renderValue()}</div>,

            enableSorting: false,
        }),
        columnHelper.accessor('amount', {
            header: () => <p>Amount</p>,
            cell: cell => (
                <span>
                    <span className='font-medium'>{cell.row.original?.currency?.symbol ?? '₦'}</span>
                    {formatAmountNoSymbol(cell.renderValue())}
                </span>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('submitter.user', {
            header: () => <p>Created by</p>,
            cell: cell => (
                <span>
                    {capitalizeText(cell.renderValue()?.first_name) ?? ''}{' '}
                    {`${capitalizeText(cell.renderValue()?.last_name?.[0]) ?? 'N/A'}.`}
                </span>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('approvers', {
            header: () => <p>Approved by</p>,
            cell: cell => {
                const approversLength = cell.renderValue()?.length > 0
                const isAllUnApproved = cell.renderValue().every((approver: ApprovalType) => !approver.approved)
                const partiallyApprovedbrev = cell.renderValue().filter((approver: ApprovalType) => approver.approved)
                return approversLength ? (
                    <>
                        {!isAllUnApproved
                            ? partiallyApprovedbrev?.map((approver: ApprovalType) => (
                                  <span className='block'>
                                      {approver?.account?.user?.first_name
                                          ? `${capitalizeText(approver?.account.user.first_name)} ${capitalizeText(
                                                approver?.account.user?.last_name?.[0]
                                            )}`
                                          : 'No Name'}
                                  </span>
                              ))
                            : 'N/A'}
                    </>
                ) : (
                    'Auto'
                )
            },

            enableSorting: false,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            enableSorting: true,
            cell: cell => {
                return <ExpenseStatusBadge status={cell.renderValue()} />
            },
        }),
        columnHelper.display({
            id: 'actions',
            cell: cell => (
                <TableActions
                    actions={[
                        {
                            label: 'Approve',
                            method: () => setApproveExpense?.(cell?.row?.original?.id),
                            hidden:
                                cell?.row.original?.status == ExpenseStatus.reimbursed ||
                                cell?.row.original?.status !== ExpenseStatus.pending ||
                                !cell?.row.original?.approvers?.find(
                                    account => account?.account?.id === selectedAccount?.id
                                ),
                        },
                        {
                            label: 'Decline',
                            method: () => {
                                setDeclineExpense?.(cell?.row?.original?.id)
                            },
                            hidden:
                                cell?.row.original?.status == ExpenseStatus.reimbursed ||
                                cell?.row.original?.status == ExpenseStatus.denied ||
                                cell?.row.original?.status == ExpenseStatus.approved ||
                                !cell?.row.original?.approvers.find(
                                    account => account?.account?.id === selectedAccount?.id
                                ),
                        },
                        {
                            label: cell.row.original?.status === ExpenseStatus.draft ? 'Edit Details' : 'More details',
                            method: () => {
                                setSelectedExpense(cell?.row?.original)
                                handleMoreDetails(
                                    cell?.row?.original,
                                    cell.row.original?.status === ExpenseStatus.draft ? true : false
                                )
                            },
                        },
                        {
                            label: cell.row.original?.status === ExpenseStatus.draft && 'More details',
                            method: () => {
                                setSelectedExpense(cell?.row?.original)
                                handleMoreDetails(cell?.row?.original)
                            },
                            hidden: cell.row.original?.status !== ExpenseStatus.draft,
                        },
                    ]}
                />
            ),
        }),
    ]

    const handleMoreDetails = (expense: ExpenseType, editModalPopUp?: boolean) => {
        if (editModalPopUp) {
            setEditModal(true)
        } else navigate(`/dashboard/expenses/expense_list/${expense.id}`)
    }

    const handleRowClick = (expense: ExpenseType) => {
        navigate(`/dashboard/expenses/expense_list/${expense.id}`)
    }

    return (
        <div className='rounded-lg'>
            <Table
                data={expenses}
                columns={columns}
                handleSort={handleSort}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                pagination_data={pagination_data}
                setIsPageNumberClicked={setPageNumberClicked}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                emptyStateTitle='No expenses'
                emptyStateDescription='No expense has been submitted yet'
                emptyStateImg={TransactionEmptyStateIcon}
            />

            {editModal && (
                <ExpenseFormRoot
                    setShowModal={setEditModal}
                    selectedExpense={selectedExpense!}
                    categories={categories}
                    hasAPrimaryBank={hasAPrimaryBank}
                    edit={true}
                />
            )}
        </div>
    )
}

export default ExpenseListTable
