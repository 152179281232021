import Table from '@/components/Table'
// import CheckBox from '@/components/Table/CheckBox'
import TableActions from '@/components/Table/TableActions'
import StatusColor from '@/components/utils/StatusColor'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { format, formatDistance } from 'date-fns'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import filter from '@/assets/filter.svg'
// import downArrow from '@/assets/down-arrow.svg'
import { ColumnSort } from '@/types/utils'
import { usePayrollContext } from '@/context'
import { QueryOrgContractPayRollType } from '@/services/payroll/types'
import { ContractPayrollType } from '@/types/payroll'
import { getCompletedMileStone, getCompletedMileStoneAmount } from '../../../utils'
import PayHistoryEmptyState from '@/assets/pay-history-empty-state.svg'

type Props = { contractHistory: QueryOrgContractPayRollType }

export default function UserContractTable({ contractHistory }: Props) {
    const navigate = useNavigate()
    const columnHelper = createColumnHelper<ContractPayrollType>()
    const { setSort, setUserContractPayroll } = usePayrollContext()
    const [rowSelection, setRowSelection] = React.useState({})
    const [pageNumber, setPageNumber] = useState(1)

    const handleMoreDetails = (data: ContractPayrollType) => {
        navigate(`/dashboard/contract/my_contracts/${data?.id}`)
        setUserContractPayroll(data)
    }
    const handleRowClick = (data: ContractPayrollType) => {
        navigate(`/dashboard/contract/my_contracts/${data?.id}`)
        setUserContractPayroll(data)
    }

    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    const columns: ColumnDef<ContractPayrollType, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('contract_title', {
            header: () => <p>Contract title</p>,
            cell: cell => <>{capitalizeText(cell.renderValue())}</>,
            enableSorting: false,
        }),
        columnHelper.accessor('start_date', {
            header: () => <>Pay period</>,
            cell: cell => {
                return (
                    <div>
                        <p>
                            {cell.row.original.start_date &&
                                format(new Date(cell.row.original.start_date), 'dd/MM/yyyy')}{' '}
                            - {cell.row.original.end_date && format(new Date(cell.row.original.end_date), 'dd/MM/yyyy')}{' '}
                        </p>
                        <p className='text-[#838383] text-sm mt-1'>
                            {cell.row.original.end_date &&
                                formatDistance(
                                    new Date(cell.row.original.start_date),
                                    new Date(cell.row.original.end_date)
                                )}
                        </p>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('milestones', {
            header: () => <p> Milestones</p>,
            cell: cell => (
                <div>
                    <p>{cell.renderValue()?.length}</p>
                    <p className='text-[#838383] text-sm mt-1'>
                        {getCompletedMileStone(cell?.row.original.milestones).length}/
                        {cell?.row.original.milestones?.length} completed
                    </p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('total_pay', {
            header: () => <p>Total pay</p>,
            cell: cell => (
                <span>
                    {formatAmount(cell.renderValue())}
                    <span className='text-[#838383] text-sm mt-1 block'>
                        Paid {formatAmount(getCompletedMileStoneAmount(cell.row?.original?.milestones))}{' '}
                    </span>
                </span>
            ),
            enableSorting: true,
        }),

        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            cell: cell => {
                const checkIfAllMileStoneHasBeenPaid = cell.row.original?.milestones.every(
                    milestone => milestone?.payment_status === 'paid'
                )
                return (
                    <div className=''>
                        <StatusColor status={checkIfAllMileStoneHasBeenPaid ? 'closed' : cell.renderValue()} />
                    </div>
                )
            },
            enableSorting: true,
        }),

        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={filter} alt='button' />
                    </div>
                    {/* <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={downArrow} alt='button' />
                    </div> */}
                </div>
            ),
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                method: () => {
                                    handleMoreDetails(row?.row.original)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    return (
        <section className='h-full'>
            <Table
                data={contractHistory?.payrolls}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                pagination_data={contractHistory?.pagination}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                rowClickable={true}
                handleSort={handleSort}
                emptyStateDescription='Monitor payments that are being paid to your bank account'
                emptyStateImg={PayHistoryEmptyState}
                emptyStateTitle='No Payment made yet!'
            />
        </section>
    )
}
