import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
// import downArrow from '@/assets/down-arrow.svg'
import filter from '@/assets/filter.svg'
import React from 'react'
// import CheckBox from '@/components/Table/CheckBox'
import VendorsEmptyState from '@/assets/vendors-empty-state.svg'

import { Vendor } from '@/types/vendor'
import { capitalizeText } from '@/utils/string'
import { ColumnSort, Pagination } from '@/types/utils'
import { useVendorContext } from '@/context'
import { useNavigate } from 'react-router-dom'
import FilterVendors from '@/components/Modals/Vendors/AdvancedFilter'
import StatusColor from '@/components/utils/StatusColor'
import { format } from 'date-fns'

interface VendorTableProps {
    vendors: Vendor[]
    pagination_data: Pagination
}
export enum VendorStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    SUSPENDED = 'SUSPENDED',
}

const VendorListTable = ({ vendors, pagination_data }: VendorTableProps) => {
    const navigate = useNavigate()

    const columnHelper = createColumnHelper<Vendor>()
    const [rowSelection, setRowSelection] = React.useState({})

    const [filterVendorModal, setFilterVendorModal] = React.useState(false)

    const { setSelectedVendor, setSort } = useVendorContext()
    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    //context api to sort the table
    const columns: ColumnDef<Vendor, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('name', {
            header: () => {
                return <p className='whitespace-nowrap text-[95%] '>Vendor name</p>
            },
            cell: cell => {
                return (
                    <div>
                        <p className='font-normal mb-1 text-sm'>{capitalizeText(cell.renderValue())}</p>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('email', {
            header: () => <p className='whitespace-nowrap text-[95%]'>Email Address</p>,
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>{cell.renderValue()}</p>
                </div>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('category.label', {
            header: () => <p>Category</p>,
            cell: cell => <span className='font-normal whitespace-nowrap'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
        columnHelper.accessor('owner.user.first_name', {
            header: () => <p>Vendor owner</p>,
            cell: cell => {
                return (
                    <>
                        <span className='font-normal whitespace-nowrap'>{capitalizeText(cell.renderValue())}</span>
                        <span className='font-normal whitespace-nowrap'>
                            {' '}
                            {capitalizeText(cell.row.original.owner?.user?.last_name)}
                        </span>
                    </>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <p>Date</p>,
            cell: cell => format(new Date(cell.renderValue()), 'dd/MM/yyyy'),
            enableSorting: true,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            enableSorting: false,
            cell: cell => {
                return <StatusColor status={cell.row.original.status} />
            },
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div
                        className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'
                        onClick={() => setFilterVendorModal(true)}
                    >
                        <img src={filter} alt='button' />
                    </div>
                    {/* <div
                        className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'
                        // onClick={() => {
                        //     downloadTableFileFn({ expenses })
                        // }}
                    >
                        <img src={downArrow} alt='button' />
                    </div> */}
                </div>
            ),

            cell: cell => (
                <TableActions
                    actions={[
                        {
                            label: 'More details',
                            method: () => {
                                setSelectedVendor(cell?.row?.original)
                                navigate(`/dashboard/vendor_management/vendors/${cell.row.original.id}`)
                            },
                        },
                    ]}
                />
            ),
        }),
    ]
    const handleRowClick = (vendor: Vendor) => {
        setSelectedVendor(vendor)
        navigate(`/dashboard/vendor_management/vendors/${vendor?.id}`)
    }

    return (
        <div>
            <Table
                data={vendors}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                pagination_data={pagination_data}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                handleSort={handleSort}
                emptyStateImg={VendorsEmptyState}
                emptyStateDescription='Effortlessly Organize Vendor Information and Track Transactions in One Place'
                emptyStateTitle='Centralized Vendor Details and management'
            />

            {filterVendorModal && <FilterVendors closeModal={setFilterVendorModal} />}
            {/* {isModalOpen && <VendorDetails vendor={selectedVendor!} closeModal={setIsModalOpen} />} */}
        </div>
    )
}

export default VendorListTable
