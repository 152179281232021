import { PaymentProfile } from '@/types/payroll'
// import CheckBox from '@/components/Table/CheckBox'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import Table from '@/components/Table'
// import TableActions from '@/components/Table/TableActions'
import { format } from 'date-fns'
import { formatAmount } from '@/utils/money'
import StatusColor from '@/components/utils/StatusColor'

type Props = {
    salaries: PaymentProfile[]
}
export default function SalariesTable({ salaries }: Props) {
    const columnHelper = createColumnHelper<PaymentProfile>()
    const [rowSelection, setRowSelection] = useState({})
    const columns: ColumnDef<PaymentProfile, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('account', {
            header: () => <p>User</p>,
            cell: cell => (
                <>
                    {cell.renderValue().user?.first_name} {cell.renderValue().user.last_name?.[0]}.
                </>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('current_period_start', {
            header: () => <>Pay period</>,
            cell: cell => {
                return (
                    <div className='`'>
                        <div className='flex gap-2'>
                            <span>{format(new Date(cell.renderValue()), 'dd/MM/yyyy')}</span>
                            {'  '} -{'  '}
                            <span>{format(new Date(cell.row.original?.current_period_end), 'dd/MM/yyyy')}</span>
                        </div>
                        <span className='text-xs font-normal'>worked {cell.row.original?.days_worked} days</span>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('gross_salary', {
            header: () => <p>Gross salary</p>,
            cell: cell => (
                <div>
                    <p>{formatAmount(cell.renderValue().$numberDecimal)}</p>
                    <p className='text-xs font-normal'>{cell.row.original?.pay_frequency}</p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('computed_deduction', {
            header: () => <p>Deductions</p>,
            cell: cell => <span>{formatAmount(cell.renderValue().$numberDecimal)}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('computed_bonus', {
            header: () => <p>Bonuses</p>,
            cell: cell => <span>{formatAmount(cell.renderValue().$numberDecimal)}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('net_salary', {
            header: () => <p>Net salary</p>,
            cell: cell => <span>{formatAmount(cell.renderValue().$numberDecimal)}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('current_payout', {
            header: () => <p>Current payout</p>,
            cell: cell => <span>{formatAmount(cell.renderValue().$numberDecimal)}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            cell: cell => (
                <span>
                    <StatusColor status={cell.renderValue().toUpperCase()} />
                </span>
            ),
            enableSorting: true,
        }),
        // columnHelper.display({
        //     id: 'actions',
        //     cell: cell => {
        //         return (
        //             <TableActions
        //                 actions={[
        //                     {
        //                         label: 'View payroll details',
        //                         method: () => {
        //                             console.log('here')
        //                         },
        //                     },
        //                 ]}
        //             />
        //         )
        //     },
        // }),
    ]

    return (
        <section className='mt-[1.2rem]'>
            <div className={` transition-all rounded-lg `}>
                <section className='h-full text-gray7 font-medium'>
                    <Table
                        data={salaries}
                        columns={columns}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        rowClickable={true}
                        // handleSort={handleSort}
                    />
                </section>
            </div>
        </section>
    )
}
