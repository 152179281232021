import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import downArrow from '@/assets/down-arrow.svg'
import { MdDelete } from 'react-icons/md'
// import CheckBox from '@/components/Table/CheckBox'
import React, { useState, useMemo } from 'react'
import { capitalizeText } from '@/utils/string'
import { useGetTeamById } from '@/services/employees/queries'
import { useEmployeesContext } from '@/context'
import { TeamMembersProp } from '@/types/employee'
import TeamMemberPromptRemoval, {
    TeamLeadSelectBox,
} from '../../../../components/Modals/Employee/TeamMemberPromptRemoval'
import AddMemberModal from '@/components/Modals/Employee/AddMemberModal/AddMemberModal'
import { CrownLogo } from '@/assets/assets.export'
import ProgressBar from '@/components/ProgressBar'
import { useParams } from 'react-router-dom'
import MakeTeamLeadPrompt from '@/components/Modals/Employee/MakeTeamLeadPrompt'
import { CheckCheckIcon } from 'lucide-react'
import { useMutateRemoveMemberFromTeam } from '@/services/employees/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { debounce } from 'lodash'

export type EmployeeType = {
    name: string
    role: string
    team: string
    emailAddress: string
    date: string
}

export type Props = {
    openModal: boolean
    backspaceClicked: boolean
    backspace: boolean
    onCloseModal: React.Dispatch<React.SetStateAction<boolean>>
    setBackSpace: React.Dispatch<React.SetStateAction<boolean>>
    setBackSpaceClicked: React.Dispatch<React.SetStateAction<boolean>>
    searchQuery: string
}
const SingleEmployeeTeamTable = ({
    onCloseModal,
    openModal,
    searchQuery,
    backspaceClicked,
    setBackSpace,

    setBackSpaceClicked,
}: Props) => {
    const [rowSelection, setRowSelection] = useState({})
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [makeTeamLead, setMakeTeamLead] = useState(false)
    const [selectTeamLead, setSelectTeamLead] = useState(false)
    const [userDetails, setUserDetails] = useState<TeamMembersProp>()

    const { team_id } = useParams()

    const { teamsObj, setAmountOfEmployee, setTeamsObj } = useEmployeesContext()
    // console.group(backspace)
    const {
        data: team_data,
        isLoading,
        refetch,
    } = useGetTeamById({
        queryParams: {
            search: searchQuery,
            team_id: (teamsObj?.id ?? team_id) as string,
        },
        onSuccess: members => {
            setAmountOfEmployee(prev => ({ ...prev, current_team_size: members.users.length }))
            setTeamsObj(members)
            setBackSpace(false)
        },
    })

    const teamData = useMemo(
        () =>
            team_data?.users.sort((member1, member2) => Number(member2?.isTeamLead) - Number(member1?.isTeamLead)) ??
            [],
        [team_data]
    )
    const filteredTeamData = teamData.filter(team => !team.account || team.account.user)

    const { mutate: deleteMemberFn, isLoading: isDeleteMemberLoading } = useMutateRemoveMemberFromTeam({
        queryParams: {
            team_id: teamsObj?.id as string,
            member: userDetails?.account?.id as string,
        },
        onSuccess: () => {
            setIsDeleteDialogOpen(false)
            toastHandler({ message: 'User removed', state: 'success' })
            if (userDetails?.isTeamLead) {
                setSelectTeamLead(true)
            }
        },
    })

    const debouncedSearch = React.useRef(
        debounce(() => {
            refetch()
        }, 300)
    ).current

    //=====refetches the expense when the advanced filter is used
    React.useEffect(() => {
        if (backspaceClicked) {
            refetch()
            setBackSpaceClicked(false)
        }

        if (searchQuery) {
            debouncedSearch()
        }

        return () => {
            debouncedSearch.cancel()
        }
    }, [refetch, backspaceClicked, searchQuery, debouncedSearch])

    const columnHelper = createColumnHelper<TeamMembersProp>()

    const columns: ColumnDef<TeamMembersProp, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table.getIsAllRowsSelected(),
        //                 indeterminate: table.getIsSomeRowsSelected(),
        //                 onChange: table.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.display({
            id: 'user',
            header: () => <p>Members </p>,
            cell: cell => {
                return (
                    <div className='flex items-center gap-1'>
                        <span className='text-[#838383] text-[.7rem] p-2 w-6 h-6 bg-[#D9D9D9] rounded-full inline-flex items-center justify-center mr-2'>
                            {' '}
                            {`${cell.row.original?.account?.user?.first_name[0].toUpperCase()}${cell.row.original?.account?.user?.last_name?.[0].toUpperCase()} `}
                        </span>
                        <span>
                            {' '}
                            {`${capitalizeText(
                                cell.row.original?.account?.user?.first_name.toLowerCase()
                            )} ${capitalizeText(cell.row.original?.account?.user?.last_name.toLowerCase())}`}
                        </span>
                        <span>{cell.row.original?.isTeamLead && <CrownLogo />}</span>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('account.role.name', {
            header: () => <p>Role </p>,
            cell: cell => (
                <>
                    <p>{capitalizeText(cell?.renderValue()) ?? 'N/A'} </p>
                </>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('account.user.email', {
            header: () => <p>Created by</p>,
            cell: cell => (
                <>
                    <p>{cell?.renderValue() ? cell?.renderValue()?.toLowerCase() : 'N/A'}</p>
                </>
            ),
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={downArrow} alt='button' />
                    </div>
                </div>
            ),
            cell: row => {
                // if (row?.row.original?.isTeamLead) {
                //     return
                // }
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'Remove Member',
                                icon: MdDelete,
                                method: () => {
                                    setIsDeleteDialogOpen(true)
                                    setUserDetails(row?.row.original)
                                },
                                actionColor: 'text-[#CF491E]',
                            },
                            // {}
                            {
                                label: 'Make Team lead',
                                icon: CheckCheckIcon,
                                method: () => {
                                    setMakeTeamLead(true)
                                    setUserDetails(row?.row.original)
                                },
                                actionColor: '',
                                hidden: row.row.original?.isTeamLead,
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    if (isLoading) return <ProgressBar />

    return (
        <div className='mt-10'>
            <Table
                data={filteredTeamData}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                // handleRowClicked={handleRowClick}
                // rowClickable={true}
            />

            {isDeleteDialogOpen && (
                <TeamMemberPromptRemoval
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    userToDelete={userDetails!}
                    loading={isDeleteMemberLoading}
                    deleteMemberFn={deleteMemberFn}
                />
            )}

            {makeTeamLead && <MakeTeamLeadPrompt setIsDeleteDialogOpen={setMakeTeamLead} userDetails={userDetails!} />}

            {openModal && <AddMemberModal setAddMemberModal={onCloseModal} currentTeamData={teamData} />}

            {selectTeamLead && <TeamLeadSelectBox closeModal={setSelectTeamLead} />}
        </div>
    )
}

export default SingleEmployeeTeamTable
