import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { FormValues } from './utils'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { Input } from '@/components'
import { TbCurrencyNaira } from 'react-icons/tb'
import { capitalizeText } from '@/utils/string'
import { useEffect, useState } from 'react'

type Props = {
    setFormState: React.Dispatch<React.SetStateAction<FormValues>>
    formState: FormValues
}
const CreateBudget2 = ({ setFormState, formState }: Props) => {
    const [distributedAmount, setDistributedAmount] = useState(0)
    const [distributedAmountPercentage, setDistributedAmountPercentage] = useState(0)

    const calculateDistributedBudget = () => {
        const usersGivenAmount =
            formState?.allocatedAmount &&
            formState?.allocatedAmount.reduce((accumulator, data) => {
                return Number(accumulator) + Number(data.amount)
            }, 0)
        const percentageCalclation = usersGivenAmount && (usersGivenAmount / Number(formState.amount)) * 100

        percentageCalclation && setDistributedAmountPercentage(percentageCalclation)
        usersGivenAmount && setDistributedAmount(Number(formState.amount) - usersGivenAmount)
    }

    useEffect(() => {
        calculateDistributedBudget()
    }, [formState.allocatedAmount])
    // console.log(formState.allocatedAmount)

    return (
        <div className=''>
            {/* <div className='mt-4 mb-8'>
                <h1 className='text-[#31254B] text-2xl mb-1 font-bold'>Budget Information</h1>
                <p className='text-[#202020]'>Distribute budget amount to budget users.</p>
            </div>
            <div className='flex flex-col flex-1 gap-3 mb-5'>
                <label className='flex items-center gap-1 text-base'>
                    Would you like to distribute this budget to each assigned user?
                </label>
                <RadioGroup
                    onValueChange={value => {
                        setFormState(prevState => ({
                            ...prevState,
                            distribute_budgets: value as 'true' | 'false',
                        }))
                    }}
                    defaultValue={formState.distribute_budgets?.toString()}
                    className='flex flex-col gap-4'
                >
                    <label
                        htmlFor={'true'}
                        className='flex gap-2 items-center text-[#5E5E5E] font-medium  cursor-pointer'
                        // key={types}
                    >
                        <RadioGroupItem value={'true'} id={'true'} />
                        Yes
                    </label>
                    <label
                        htmlFor={'false'}
                        className='flex gap-2 items-center text-[#5E5E5E] font-medium  cursor-pointer'
                    >
                        <RadioGroupItem value={'false'} id={'false'} />
                        No
                    </label>
                </RadioGroup>
            </div> */}

            {
                <div className='flex flex-col flex-1 gap-3'>
                    <label className='flex items-center gap-1 text-base'>Distribution method</label>
                    <RadioGroup
                        onValueChange={value => {
                            setFormState(prevState => ({
                                ...prevState,
                                method: value,
                            }))
                        }}
                        defaultValue={formState.method}
                        // value={formState.method}
                        className='flex flex-col gap-4'
                    >
                        <label
                            htmlFor={'automated'}
                            className='flex gap-2 items-center text-[#5E5E5E] font-medium  cursor-pointer'
                            // key={types}
                        >
                            <RadioGroupItem value={'automated'} id={'automated'} />
                            Automated
                        </label>
                        <label
                            htmlFor={'manual'}
                            className='flex gap-2 items-center text-[#5E5E5E] font-medium  cursor-pointer'
                        >
                            <RadioGroupItem value={'manual'} id={'manual'} />
                            Manual
                        </label>
                    </RadioGroup>
                </div>
            }
            {formState.method === 'manual' && (
                <div className='bg-[#F9F9F9] rounded-2xl p-4 mt-8' key={formState.method}>
                    <div className='bg-white flex items-center justify-between text-[#5E5E5E] p-4 rounded-2xl'>
                        <div className=''>
                            <h2 className='font-medium mb-1'>Allocated budget</h2>
                            <p>{formatAmount(formState.amount)}</p>
                        </div>
                        <div className=''>
                            <h2 className='font-medium mb-1'>Distributed</h2>
                            <p>
                                {formatAmount(Number(formState.amount) - distributedAmount)} ({' '}
                                {distributedAmountPercentage.toFixed(2)}%)
                            </p>
                        </div>
                        <div className=''>
                            <h2 className='font-medium mb-1'>Undistributed</h2>
                            <p>
                                {formatAmount(distributedAmount)} (
                                {100 - Number(distributedAmountPercentage.toFixed(2))}%)
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 pt-5'>
                        {formState?.allocatedAmount &&
                            formState?.allocatedAmount.map((member, idx) => (
                                <div className='grid grid-cols-2 gap-6 items-center' key={idx}>
                                    <p className='bg-[#F2F2F2] px-5 py-3 text-[#A8A8A8] rounded-lg text-sm '>
                                        {capitalizeText(member?.member?.user?.last_name)}{' '}
                                        {capitalizeText(member?.member?.user?.first_name)}
                                    </p>
                                    <Input
                                        type={'text'}
                                        name={`${member?.member?.user?.first_name}`}
                                        placeholder='Enter amount you want to allocate'
                                        className='border-[#DADCE0]  h-[3rem] flex-1 bg-white'
                                        leftIcon={<TbCurrencyNaira fontSize={35} color={'#838383'} />}
                                        value={formatAmount(member.amount).replaceAll('₦', '')}
                                        onChange={e => {
                                            const value = formatAmountWithoutSymbols(e.target.value)
                                            setFormState(prev => ({
                                                ...prev,
                                                allocatedAmount:
                                                    prev?.allocatedAmount &&
                                                    prev?.allocatedAmount.map(data => {
                                                        if (data.member.id === member.member?.id) {
                                                            return { member: data.member, amount: value }
                                                        }
                                                        return data
                                                    }),
                                            }))
                                        }}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default CreateBudget2
