import { Switch } from '@headlessui/react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { NotificationsProp } from './utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'
import { AlertCircleIcon, ChevronDown } from 'lucide-react'
import { Button } from '@/components'
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from '@/components/Tooltips'

type Props = {
    title: string
    description: string
    field_name_push: keyof NotificationsProp
    field_name_email: keyof NotificationsProp
}
type FormProps = { system: boolean; promotional: boolean }

export default function CompanyNotifications() {
    const form = useForm<FormProps>()
    const processSubmit = (values: FormProps) => {
        console.table(values)
    }
    return (
        <section>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(processSubmit)}>
                    <div className='flex flex-col md:flex-row items-start md:items-center justify-between mt-3 gap-5'>
                        <div>
                            <h1 className='font-medium text-base '>Notifications</h1>
                            <p className='text-sm text-gray7'>Stay updated: Personalize your notifications.</p>
                        </div>
                        <div>
                            <h2 className='text-sm font-medium mb-1'>Email Notifications</h2>
                            <div className=' gap-8 flex'>
                                <Controller
                                    control={form.control}
                                    name={'system'}
                                    render={({ field }) => (
                                        <div>
                                            <TooltipProvider delayDuration={10}>
                                                <Tooltip>
                                                    <TooltipTrigger
                                                        className='mr-2 flex items-center mb-1 gap-1'
                                                        type='button'
                                                    >
                                                        <h4 className='text-gray7 text-xs '>System</h4>
                                                        <AlertCircleIcon size={10} />
                                                    </TooltipTrigger>

                                                    <TooltipContent>
                                                        <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                            Get notified via email when listed actions are carried out
                                                        </p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                            <Switch
                                                checked={field.value}
                                                onChange={field.onChange}
                                                className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                                                    field.value ? 'bg-brand' : 'bg-[#EFEFF9]'
                                                }`}
                                            >
                                                <span
                                                    className={`${
                                                        !field.value
                                                            ? 'translate-x-1 bg-brand'
                                                            : 'translate-x-6 bg-white'
                                                    } inline-block h-4 w-4 transform rounded-full  transition`}
                                                />
                                            </Switch>
                                        </div>
                                    )}
                                />
                                <Controller
                                    control={form.control}
                                    name={'promotional'}
                                    render={({ field }) => (
                                        <div>
                                            <TooltipProvider delayDuration={10}>
                                                <Tooltip>
                                                    <TooltipTrigger
                                                        className='mr-2 flex items-center mb-1 gap-1'
                                                        type='button'
                                                    >
                                                        <h4 className='text-gray7 text-xs '>Promotional</h4>
                                                        <AlertCircleIcon size={10} />
                                                    </TooltipTrigger>

                                                    <TooltipContent>
                                                        <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                            Stay updated with our latest offers and features.
                                                        </p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>

                                            <Switch
                                                checked={field.value}
                                                onChange={field.onChange}
                                                className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                                                    field.value ? 'bg-brand' : 'bg-[#EFEFF9]'
                                                }`}
                                            >
                                                <span
                                                    className={`${
                                                        !field.value
                                                            ? 'translate-x-1 bg-brand'
                                                            : 'translate-x-6 bg-white'
                                                    } inline-block h-4 w-4 transform rounded-full  transition`}
                                                />
                                            </Switch>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='bg-white rounded-lg p-4 mt-7 mr-5'>
                        <Accordion type='single' collapsible>
                            <AccordionItem value='item-1' className='border-none'>
                                <AccordionTrigger righticon={<ChevronDown className='w-4 h-4' />}>
                                    Expenses
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className='space-y-10 mt-6'>
                                        <NotificationsCard
                                            title='Submitted request'
                                            description='Get notified via email when any expense request is submitted by anyone in your company.'
                                            field_name_email='submitted_request_email'
                                            field_name_push='submitted_request_push'
                                        />
                                        <NotificationsCard
                                            title='Expense response'
                                            description='Get notified via email when any expense request is approved or declined by an admin in your company.'
                                            field_name_email='expense_response_email'
                                            field_name_push='expense_response_push'
                                        />
                                        <NotificationsCard
                                            title='Reimbursements'
                                            description='Get notified via email when any reimbursement is made in your company'
                                            field_name_email='reimbursements_email'
                                            field_name_push='reimbursements_push'
                                        />
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className='bg-white rounded-lg p-4 mt-7 mr-5'>
                        <Accordion type='single' collapsible>
                            <AccordionItem value='item-1' className='border-none'>
                                <AccordionTrigger righticon={<ChevronDown className='w-4 h-4' />}>
                                    Users
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className='space-y-10 mt-6'>
                                        <NotificationsCard
                                            title='New user'
                                            description='Get notified via email when any new user is onboarded'
                                            field_name_email='new_user_email'
                                            field_name_push='new_user_push'
                                        />
                                        <NotificationsCard
                                            title='User role'
                                            description='Get notified via email when a user’s role changes'
                                            field_name_email='user_role_email'
                                            field_name_push='user_role_push'
                                        />
                                        <NotificationsCard
                                            title='Invites'
                                            description='Get notified via email when new invites are sent out'
                                            field_name_email='invites_email'
                                            field_name_push='invites_push'
                                        />
                                        <NotificationsCard
                                            title='User suspension'
                                            description='Get notified via email when a user is suspended'
                                            field_name_email='user_suspension_email'
                                            field_name_push='user_suspension_push'
                                        />
                                        <NotificationsCard
                                            title='User deactivation'
                                            description='Get notified via email when a user is deactivated'
                                            field_name_email='user_deactivation_email'
                                            field_name_push='user_deactivation_push'
                                        />
                                        <NotificationsCard
                                            title='New team'
                                            description='Get notified via email when a new team is created'
                                            field_name_email='new_team_email'
                                            field_name_push='new_team_push'
                                        />
                                        <NotificationsCard
                                            title='Team and user'
                                            description='Get notified via email when a user is added to or removed from a team'
                                            field_name_email='user_team_email'
                                            field_name_push='user_team_push'
                                        />
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className='bg-white rounded-lg p-4 mt-7 mr-5'>
                        <Accordion type='single' collapsible>
                            <AccordionItem value='item-1' className='border-none'>
                                <AccordionTrigger righticon={<ChevronDown className='w-4 h-4' />}>
                                    Transactions
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className='space-y-10 mt-6'>
                                        <NotificationsCard
                                            title='Incoming transactions'
                                            description='Get notified via email when money is added to your company’s wallet.'
                                            field_name_email='incoming_transaction_email'
                                            field_name_push='incoming_transaction_push'
                                        />
                                        <NotificationsCard
                                            title='Outgoing transactions'
                                            description='Get notified via email when money leaves your company’s wallet'
                                            field_name_email='outgoing_transaction_email'
                                            field_name_push='outgoing_transaction_push'
                                        />
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className='bg-white rounded-lg p-4 mt-7 mr-5'>
                        <Accordion type='single' collapsible>
                            <AccordionItem value='item-1' className='border-none'>
                                <AccordionTrigger righticon={<ChevronDown className='w-4 h-4' />}>
                                    Payroll
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className='space-y-10 mt-6'>
                                        <NotificationsCard
                                            title='Salary due'
                                            description='Get notified via email when any user is due for salary payment.'
                                            field_name_email='salary_due_email'
                                            field_name_push='salary_due_push'
                                        />
                                        <NotificationsCard
                                            title='Salary processed'
                                            description='Get notified via email when any salary is being processed'
                                            field_name_email='salary_processed_email'
                                            field_name_push='salary_processed_push'
                                        />
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className='bg-white rounded-lg p-4 mt-7 mr-5'>
                        <Accordion type='single' collapsible>
                            <AccordionItem value='item-1' className='border-none'>
                                <AccordionTrigger righticon={<ChevronDown className='w-4 h-4' />}>
                                    Contracts
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className='space-y-10 mt-6'>
                                        <NotificationsCard
                                            title='New contract'
                                            description=' Get notified via email when any new contract is created (contractor and owner) '
                                            field_name_email='new_contract_email'
                                            field_name_push='new_contract_push'
                                        />
                                        <NotificationsCard
                                            title='Contract milestone'
                                            description='Get notified via email when a milestone changes status (Completed, Ongoing, or Approved) -(user)'
                                            field_name_email='contract_milestone_email'
                                            field_name_push='contract_milestone_push'
                                        />
                                        <NotificationsCard
                                            title='Contract renewal'
                                            description='Get notified via email when a contract is being renewed (Owner and contractor)'
                                            field_name_email='contract_renewal_email'
                                            field_name_push='contract_renewal_push'
                                        />
                                        <NotificationsCard
                                            title='Contract termination'
                                            description='Get notified via email when a contract is terminated (Owner and contractor)'
                                            field_name_email='contract_termination_email'
                                            field_name_push='contract_termination_push'
                                        />
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>

                    <div className='flex justify-end mr-5 mt-6 mb-1'>
                        <Button type='submit' size={'lg'}>
                            Save changes
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </section>
    )
}

const NotificationsCard = ({ title, description, field_name_push, field_name_email }: Props) => {
    const form = useFormContext<NotificationsProp>()
    return (
        <div className='flex flex-row items-center justify-between gap-3'>
            <div className='space-y-0.5 font-medium'>
                <h3 className='text-base text-gray7'>{title}</h3>
                <p className='text-xs font-normal lg:font-medium lg:text-sm'>{description}</p>
            </div>
            <div className=' gap-8 hidden'>
                <Controller
                    control={form.control}
                    name={field_name_push}
                    render={({ field }) => (
                        <div>
                            <h4 className='text-gray7 text-xs  mb-1'>Push</h4>
                            <Switch
                                checked={field.value}
                                onChange={field.onChange}
                                className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                                    field.value ? 'bg-brand' : 'bg-[#EFEFF9]'
                                }`}
                            >
                                <span
                                    className={`${
                                        !field.value ? 'translate-x-1 bg-brand' : 'translate-x-6 bg-white'
                                    } inline-block h-4 w-4 transform rounded-full  transition`}
                                />
                            </Switch>
                        </div>
                    )}
                />
                <Controller
                    control={form.control}
                    name={field_name_email}
                    render={({ field }) => (
                        <div>
                            <h4 className='text-gray7 text-xs font-medium mb-1'>Email</h4>
                            <Switch
                                checked={field.value}
                                onChange={field.onChange}
                                className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                                    field.value ? 'bg-brand' : 'bg-[#EFEFF9]'
                                }`}
                            >
                                <span
                                    className={`${
                                        !field.value ? 'translate-x-1 bg-brand' : 'translate-x-6 bg-white'
                                    } inline-block h-4 w-4 transform rounded-full  transition`}
                                />
                            </Switch>
                        </div>
                    )}
                />
            </div>
        </div>
    )
}
