export const teamBadge = {
    a: '#57E1FF',
    b: '#FF9152',
    c: '#5C9BFF',
    d: '#F4DD09',
    e: '#93F',
    f: '#AD381E',
    g: '#FC52FF',
    h: '#FFD6C9',
    i: '#A2AD1E',
    j: '#45CFB6',
    k: '#DFFFC9',
    l: '#FFC9D7',
    m: '#D6C9FF',
    n: 'purple',
    o: 'black',
    p: 'red',
    q: '#5C9BFF',
    r: '#FF9152',
    s: '#DEB145',
    t: '#19C37D',
    u: '#454ADE',
    v: '#D28209',
    w: '#330066',
    x: '#45778C',
    y: '#ba0b32',
    z: '#fe02a2',
}
