import { FlagIcon, PDFIcon, ProfileEditIcon, ReceiptIcon, RetryICon, UnFlagIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import FlagTransactionModal from '@/components/Modals/Transactions/FlagTransaction/FlagTransactionModal'
import FlagTransaction from '@/components/Modals/Transactions/FlagTrasaction/FlagTrasaction'
import RedoTransaction from '@/components/Modals/Transactions/RedoTransactionModal'
import ProgressBar from '@/components/ProgressBar'
import ThreeDotsPopOver from '@/components/ThreeDotsPopOver'
import { useTransactionContext } from '@/context'
import { useGeTransactionById } from '@/services/transactions/queries'
import { formatAmountNoSymbol } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { ArrowLeft, Eye } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TransactionStatusBadge from '../../Transactions/components/TransactionStatusBadge'
import { TransactionStatus } from '../../Transactions/const'
import { AddNoteModal } from '@/components/Modals/Transactions/AddNoteModal'
import { RemoveNoteModal } from '@/components/Modals/Transactions/RemoveNoteModal'
import { useGetOrgCategories } from '@/services/categories/queries'
import { TransactionTypeType } from '@/types/transactions'
import { TbNotebook } from 'react-icons/tb'

export default function TransactionDetails() {
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const [openRedoModal, setOpenRedoModal] = useState(false)
    const [addNoteModal, setAddNoteModal] = useState(false)
    const [removeNoteModal, setRemoveNoteModal] = useState(false)

    const [flagTransaction, setFlagTransaction] = useState<'FLAG' | 'UNFLAG'>()
    const { selectedTransactions } = useTransactionContext()
    const { transaction_id } = useParams()

    const [showFlagModal, setShowFlagModal] = useState(false)
    const { data, isLoading } = useGeTransactionById({
        queryParams: {
            transaction_id: transaction_id as string,
        },
    })
    const { data: categories } = useGetOrgCategories()
    const allCategories = useMemo(() => categories ?? [], [categories])

    const transactionData = useMemo(() => data ?? selectedTransactions, [data, selectedTransactions])

    const initiated_by = `${transactionData?.initiated_by?.first_name ?? ''} ${transactionData?.initiated_by?.last_name ?? ''}`

    const transaction_details = [
        { name: 'Transaction ID', desc: transactionData?.transaction_id },
        {
            name: 'Amount',
            desc:
                transactionData?.ownerTransactionType === 'CREDIT'
                    ? `+ ${transactionData?.currency?.symbol ?? '₦'}${formatAmountNoSymbol(transactionData?.credit?.amount)}`
                    : `- ${transactionData?.currency?.symbol ?? '₦'}${formatAmountNoSymbol(transactionData?.debit?.amount)}`,
        },
        {
            name: 'Fee',
            desc: `${transactionData?.currency?.symbol ?? '₦'}${formatAmountNoSymbol(transactionData?.fee)}`,
        },

        {
            name: 'Date',
            desc: (
                <>
                    <span className='block whitespace-nowrap'>
                        {transactionData?.createdAt && format(new Date(transactionData.createdAt), 'PP hh:mm aa')}
                    </span>
                </>
            ),
        },
        {
            name: 'Transaction type',
            desc: transactionData?.type,
        },
        ...(transactionData?.initiated_by ? [{ name: 'Initiated by', desc: initiated_by }] : []),
        {
            name: 'Destination',
            desc: transactionData?.credit?.account?.user?.first_name
                ? `${capitalizeText(transactionData?.credit?.account?.user?.first_name)} ${capitalizeText(
                      transactionData?.credit?.account?.user?.last_name
                  )}`
                : transactionData?.metadata?.recipient
                  ? capitalizeText(transactionData?.metadata?.recipient?.name)
                  : 'Wallet Funding',
        },
        {
            name: 'Status',
            desc: (
                <span
                    className={`${
                        transactionData?.status === 'COMPLETED'
                            ? 'text-[#19C37D]'
                            : transactionData?.status === 'PROCESSING'
                              ? 'text-[#202020]/70'
                              : 'text-[#cf1e33]'
                    }`}
                >
                    {transactionData?.status === 'COMPLETED'
                        ? 'Successful'
                        : transactionData?.status === 'PROCESSING'
                          ? 'Processing'
                          : 'Unsuccessful'}
                </span>
            ),
        },
    ]

    if (isLoading) return <ProgressBar />
    return (
        <>
            <section className='mx-[1.4rem] md:mx-[2.7rem] py-3 pt-2 mb-6 pb-10 lg:pb-24 md:pb-3'>
                <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                    <ArrowLeft />
                    back
                </div>
                <section className='grid grid-cols-1 lg:grid-cols-6 gap-5'>
                    <div
                        className={`bg-white px-5 py-3 pb-6 rounded-xl ${transactionData?.category?.label || transactionData?.receipt_url ? 'col-span-4' : 'col-span-6'}`}
                    >
                        <div className='border-b border-[#DADCE0] pb-2 flex items-center justify-between '>
                            <h2 className='font-semibold text-lg opacity-90'>Transaction details</h2>

                            <ThreeDotsPopOver>
                                {(transactionData?.type === TransactionTypeType.TRANSFER ||
                                    transactionData?.type === TransactionTypeType.CONTRACT_PAYMENT) && (
                                    <a
                                        className='gap-3 cursor-pointer items-center whitespace-nowrap flex '
                                        href={transactionData?.receipt_url as string}
                                        download={`bizpend_transaction.pdf`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <ReceiptIcon className='font-medium' />
                                        Download pdf
                                    </a>
                                )}
                                {transactionData?.type === TransactionTypeType.TRANSFER && (
                                    <button
                                        className='flex gap-3 items-center whitespace-nowrap mt-2 cursor-pointer disabled:opacity-30'
                                        onClick={() => {
                                            setOpenRedoModal(true)
                                        }}
                                        disabled={transactionData?.status === 'PROCESSING'}
                                    >
                                        <RetryICon className='text-xl' />{' '}
                                        {transactionData?.status === 'COMPLETED' ? 'Repeat' : 'Retry'} transaction
                                    </button>
                                )}
                                <button
                                    className='flex gap-3 items-center whitespace-nowrap mt-2 cursor-pointer disabled:opacity-30 text-sm'
                                    onClick={() => {
                                        setAddNoteModal(true)
                                    }}
                                >
                                    <TbNotebook className='text-xl' /> Add note
                                </button>
                            </ThreeDotsPopOver>
                        </div>
                        <div className='mt-4'>
                            <h4 className='font-medium text-md mb-2'>Transaction description</h4>
                            <span className='text-[#5E5E5E] text-[1rem]'> {transactionData?.description ?? 'N/A'}</span>
                        </div>
                        <div className='flex flex-wrap max-w-[60rem] gap-x-[5rem] mt-8 gap-y-[1.3rem]'>
                            {transaction_details.map((transaction, idx) => (
                                <div className='flex flex-col w-[10rem]' key={idx}>
                                    <h4 className='text-md font-medium'>{transaction.name}</h4>
                                    <span className='text-[#5E5E5E] text-[1rem]'>{transaction.desc}</span>
                                </div>
                            ))}
                        </div>
                        {transactionData?.note && (
                            <div className='bg-[#EFEFF9] py-2 px-4 rounded-xl flex items-center w-fit gap-10  justify-between mt-6  '>
                                <div className=''>
                                    <h3 className='text-brand text-sm font-semibold'>Note</h3>
                                    <p className='text-gray7 min-w-fit max-w-[900px]'>{transactionData?.note?.text}</p>
                                </div>
                                <button
                                    className='text-xs font-medium whitespace-nowrap hover:underline'
                                    onClick={() => setRemoveNoteModal(true)}
                                >
                                    Remove note
                                </button>
                            </div>
                        )}
                        {transactionData?.status !== 'PROCESSING' && (
                            <div className='flex items-center gap-4 mt-4'>
                                <Button className='bg-transparent text-[#CF491E] hover:bg-transparent px-2'>
                                    {transactionData?.flagged ? (
                                        <div
                                            className='flex items-center gap-1'
                                            onClick={() => {
                                                setFlagTransaction('UNFLAG')
                                                setShowFlagModal(true)
                                            }}
                                        >
                                            <UnFlagIcon />
                                            Unflag transaction
                                        </div>
                                    ) : (
                                        <div
                                            className='flex items-center gap-4'
                                            onClick={() => {
                                                setFlagTransaction('FLAG')
                                                setShowFlagModal(true)
                                            }}
                                        >
                                            <FlagIcon /> Flag transaction
                                        </div>
                                    )}
                                </Button>
                            </div>
                        )}
                    </div>
                    {(transactionData?.category?.label || transactionData?.receipt_url) && (
                        <div className='col-span-4 lg:col-span-2 flex flex-col gap-4'>
                            {transactionData?.category?.label && (
                                <div className=' bg-white px-5 py-3 pb-24 lg:pb-6 rounded-xl h-fit'>
                                    <div className='border-b border-[#DADCE0] pb-2 flex items-center justify-between'>
                                        <h2 className='font-semibold text-lg opacity-90'>Transaction category</h2>
                                        <button aria-label='Icon'>
                                            <ProfileEditIcon />
                                        </button>
                                    </div>
                                    <ul className='mt-6 text-sm'>
                                        <li className='flex gap-4'>
                                            <span className='font-medium text-gray7 w-48'>Bizpend category</span>
                                            <span className='font-semibold'>{transactionData?.category?.label}</span>
                                        </li>
                                        <li className='flex gap-4 mt-2'>
                                            <span className=' font-medium text-gray7 w-48'>GL code category</span>
                                            <span className='font-semibold'>N/A</span>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            {transactionData?.receipt_url && (
                                <div className=' bg-white px-5 py-3 pb-24 lg:pb-6 rounded-xl h-fit'>
                                    <h2 className='font-semibold text-lg opacity-90 border-b border-gray5 pb-2'>
                                        Receipt
                                    </h2>

                                    <div className='flex mt-5 gap-8 '>
                                        {transactionData?.receipt_url.includes('pdf') ? (
                                            <PDFIcon className='w-20 h-20' />
                                        ) : (
                                            <img
                                                src={transactionData?.receipt_url}
                                                alt='Bolt receipt'
                                                className='w-32 max-h-28 object-cover'
                                            />
                                        )}

                                        <span className='flex items-center gap-4'>
                                            <a
                                                href={transactionData?.receipt_url}
                                                download={`${transactionData?.transaction_id}`}
                                                target='_blank'
                                                className='flex items-center gap-2 font-medium text-gray7 text-sm '
                                            >
                                                <ReceiptIcon className='text-gray7 w-5 h-5' /> Download
                                            </a>
                                            <a
                                                href={transactionData?.receipt_url}
                                                target='_blank'
                                                className='flex items-center gap-2 font-medium text-gray7 text-sm '
                                            >
                                                <Eye className='text-gray7 w-5 h-5' /> View
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </section>
            </section>

            {showFlagModal && (
                <FlagTransactionModal
                    transactionId={transactionData?._id ?? transactionData?.id ?? ''}
                    closeModal={setShowFlagModal}
                    flagDetails={flagTransaction}
                />
            )}

            {openRedoModal && <RedoTransaction closeModal={setOpenRedoModal} transactionDetails={transactionData} />}
            {transactionData?.expenses && transactionData?.expenses.length > 0 && (
                <section className='bg-white rounded-xl px-6 py-6 pb-36 lg:pb-6 mb-[12rem]  mx-[1.4rem] md:mx-[2.7rem] '>
                    <section className='rounded-xl border border-[#DADCE0]  mx-2 overflow-y-scroll'>
                        <table className=' leading-normal border-collapse  rounded-xl w-full'>
                            <tr className='flex gap-6 text-left bg-[#f6f6f6] text-[#838383] font-light w-full rounded-t-xl'>
                                <th className='w-[7rem] px-4 py-4 font-light  text-left text-[12px]'>Expense ID</th>
                                <th className='w-[7rem] px-4 py-4 font-light  text-left text-[12px]'>Date</th>
                                <th className='w-[7rem] px-4 py-4 font-light  text-left text-[12px]'>Category </th>
                                <th className='w-[8rem] px-2 py-4 font-light  text-left text-[12px]'>Amount </th>
                                <th className='w-[7rem] px-4 py-4 font-light  text-left text-[12px]'>Created by </th>
                                <th className='w-[7rem] px-4 py-4 font-light  text-left text-[12px]'>Approver </th>
                                <th className='w-[7rem] px-4 py-4 font-light  text-left text-[12px]'>Status </th>
                            </tr>
                            {transactionData?.expenses.map(expenseData => (
                                <tr className='flex gap-6 text-[.8rem]'>
                                    <td className='w-[7rem] px-4 py-4'>{expenseData?.expense_id}</td>
                                    <td className='w-[7rem] px-4 py-4'>
                                        {' '}
                                        {transactionData && format(new Date(transactionData.createdAt), 'PP')}
                                    </td>
                                    <td className='w-[7rem] px-4 py-4'>
                                        {
                                            allCategories.filter(
                                                cat =>
                                                    cat.id ===
                                                    (transactionData.expenses[0].category as unknown as string)
                                            )[0]?.label
                                        }
                                    </td>
                                    <td className='w-[8rem] px-4 py-4'>
                                        {transactionData?.ownerTransactionType === 'CREDIT'
                                            ? `+ ${transactionData?.currency?.symbol ?? '₦'}${formatAmountNoSymbol(transactionData?.credit?.amount)}`
                                            : `- ${transactionData?.currency?.symbol ?? '₦'}${formatAmountNoSymbol(transactionData?.debit?.amount)}`}
                                    </td>
                                    <td className='w-[7rem] px-4 py-4'>
                                        {transactionData.initiated_by?.first_name}{' '}
                                        {transactionData.initiated_by?.last_name}
                                    </td>
                                    <td className='w-[7rem] px-4 py-4'>
                                        {transactionData.expenses[0].approvers.length > 0 ? (
                                            <>
                                                {/* {approver?.account.user.first_name!} {approver?.account.user.last_name!} */}
                                            </>
                                        ) : (
                                            'Auto-approval'
                                        )}
                                    </td>
                                    <td className='w-[7rem] px-4 py-4'>
                                        <TransactionStatusBadge
                                            status={transactionData?.status.toLowerCase() as TransactionStatus}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </section>
                    {openModal && (
                        <FlagTransaction
                            setIsDeleteDialogOpen={setOpenModal}
                            trasactionId={transactionData?.id ?? ''}
                        />
                    )}
                </section>
            )}
            {addNoteModal && <AddNoteModal closeModal={setAddNoteModal} transaction={transactionData} />}
            {removeNoteModal && <RemoveNoteModal closeModal={setRemoveNoteModal} transaction={transactionData} />}
        </>
    )
}
