import { createQuery } from '../api/query'
import {
    QueryExpenseCategoriesResponseType,
    QueryExpenseSummaryResponseType,
    QueryExpensesResponseType,
    QueryExpenseByCategoryResponseType,
    QueryExpenseByMonth,
    QueryTopSpenders,
} from './types'
import { ExpenseType } from '@/types/expense'

export const keyQueryOrgExpenses = [`/expenses-by-org`]
export const keyQueryPendingOrgExpenses = [`/pending-expenses-by-org`]
export const getExpenseByIdKey = (arg: string) => ['/expense-id', arg]

export const useQueryOrgExpenses = createQuery<QueryExpensesResponseType>({
    key: keyQueryOrgExpenses,
    url: '/expenses-by-org',
})
export const useQueryOrgPendingExpenses = createQuery<QueryExpensesResponseType>({
    key: [`/pending-expenses-by-org`],
    url: '/expenses-by-org',
})

export const getAllOrgExpensesKey = (arg: string) => ['/get-expense-categories', arg]
export const useGetAllCategories = createQuery<QueryExpenseCategoriesResponseType>({
    key: getAllOrgExpensesKey,
    url: '/expense-categories',
})

export const useGetExpenseById = createQuery<ExpenseType>({
    key: getExpenseByIdKey,
    url: '/expense-by-id',
})

export const useGetExpenseSummary = createQuery<QueryExpenseSummaryResponseType>({
    key: ['/get-expense-summary'],
    url: '/get-expense-summary',
})

export const useGetExpenseByCategory = createQuery<QueryExpenseByCategoryResponseType>({
    key: ['/expense-by-category'],
    url: '/expense-by-category',
})

export const useGetExpenseByMonth = createQuery<QueryExpenseByMonth>({
    key: ['/get-expense-by-month'],
    url: '/get-expense-by-month',
})

export const useGetTopSpenders = createQuery<QueryTopSpenders>({
    key: ['/top-spenders'],
    url: '/top-spenders',
})
