import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../ModalWrapper'
import Step1 from './Step1'

interface FundWalletRootProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const FundWalletRoot = ({ setShowModal }: FundWalletRootProps) => {
    const [step, setStep] = useState(1)
    const [amount, setAmount] = useState(0)

    return (
        <ModalWrapper>
            <div
                className={`py-4 px-2 ${step === 1 ? 'border-b border-[#DADCE0]' : ''}  flex justify-end min-w-[calc(100vw-50px)] sm:min-w-[500px]`}
            >
                <div className='flex items-center w-[100%] justify-between h-full'>
                    {step === 1 && <h2 className='font-semibold'>Fund your main wallet</h2>}
                    <div
                        className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer ml-auto'
                        onClick={() => setShowModal(false)}
                    >
                        <AiOutlineClose fontSize={20} className='ml-auto inline-block' />
                    </div>
                </div>
            </div>

            <div className='h-full'>
                {step === 1 && (
                    <Step1 toggleStep={setStep} setAmount={setAmount} amount={amount} setShowModal={setShowModal} />
                )}
                {/* {step === 2 && <FundPrompt amount={amount} setShowModal={setShowModal} />} */}
                {/* {step === 3 && <Step2 toggleStep={setStep} />}
                {step === 4 && <Step3 toggleStep={setStep} />} */}
            </div>
        </ModalWrapper>
    )
}

export default FundWalletRoot
