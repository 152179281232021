interface EmptyStateProps {
    title: string
    description: string
    image?: string
    SvgComponent?: any
}
const EmptyStates: React.FC<EmptyStateProps> = ({ title, description, image, SvgComponent }) => {
    return (
        <div className='h-[550px] flex flex-col text-center justify-center items-center'>
            <span className='text-xl font-semibold text-[#5E5E5E]'>{title}</span>
            {SvgComponent && <SvgComponent className='mb-6' />}
            <span className='text-[#202020] text-sm w-[90%] mx-auto mt-3 font-medium'>{description}</span>
            {image && <img src={image} alt='widget' className='mt-6 h-[170px]' />}
        </div>
    )
}

export default EmptyStates
