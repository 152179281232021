import { EventEnum } from './enum'
import { ReactComponent as Clock } from '@/assets/time.svg'
import { ReactComponent as Pending } from '@/assets/pending.svg'
import { ReactComponent as Thumbsup } from '@/assets/approved.svg'
import { ReactComponent as Check } from '@/assets/check.svg'
import { Pencil } from 'lucide-react'

export type TimeLineType = {
    events: TimelineEventType[]
    createdAt: string
    id: string
    module: string
    module_type: string
    org: string
    updatedAt: string
}

export type TimelineEventType = { date: string; description: string; event_type: EventEnum; initiator: string }

export const TimelineIcon = {
    [EventEnum.APPROVED]: Thumbsup,
    [EventEnum.PAID]: Thumbsup,
    [EventEnum.CREATED]: Clock,
    [EventEnum.DRAFTED]: Pencil,

    [EventEnum.CANCELLED]: Thumbsup,
    [EventEnum.DECLINED]: Thumbsup,
    [EventEnum.DENIED]: Thumbsup,

    [EventEnum.PROCESSING]: Pending,
    [EventEnum.SCHEDULED]: Pending,
    [EventEnum.REIMBURSED]: Check,
}

// export type ApproversTimelinePolicyType=
