import { Button } from '@/components/Button'
import ModalWrapper from '../../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { formatAmount } from '@/utils/money'
import { useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { Plus } from 'lucide-react'
import { CgClose } from 'react-icons/cg'
import { PaymentProfile } from '@/types/payroll'
import { PayloadType } from '@/services/api/mutation'
import { UseMutateFunction } from '@tanstack/react-query'
import { Tabs, TabsList, TabsTrigger, TabTriggersContainer, TabsContent } from '@/components/Tabs/Tabs'
import { cn } from '@/lib/styling'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    manualPayrollData: PaymentProfile[]
    generatePayroll: UseMutateFunction<unknown, unknown, PayloadType, unknown>
    isGeneratingPayroll: boolean
}

export const GenerateManualPayroll = ({
    closeModal,
    manualPayrollData,
    generatePayroll,
    isGeneratingPayroll,
}: Props) => {
    const [formState, setFormState] = useState<PaymentProfile[]>([])
    const [addAllEligible, setAddAllEligible] = useState(false)

    const eligibleEmployees = manualPayrollData?.filter(profile => profile?.eligible)
    const inEligibleEmployees = manualPayrollData?.filter(profile => !profile?.eligible)

    const deleteEmployee = (index: number) => {
        const updatedForm = [...formState]
        updatedForm.splice(index, 1)
        setFormState(updatedForm)
    }

    const generatePayrollFn = () => {
        generatePayroll({
            profiles: formState.map(profile => profile._id),
        })
    }

    return (
        <ModalWrapper variants={undefined}>
            <main className='max-h-[45rem] w-screen lg:w-[35rem] overflow-hidden'>
                <section className='flex items-center justify-between border-b border-[#DADCE0] p-4 '>
                    <span className='flex-1 text-center text-xl font-medium'>Generate manual salary</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='max-h-[32rem] overflow-scroll'>
                    <section className='mt-4 px-4 lg:px-6'>
                        <Tabs defaultValue='eligible'>
                            <TabsList className='border-none  overflow-y-scroll  overflow-x-scroll w-full'>
                                <TabTriggersContainer className='grid grid-cols-2 gap-6 lg:w-full'>
                                    <TabsTrigger
                                        value='eligible'
                                        className={cn(
                                            'group px-3 w-full py-2 flex gap-4 bg-white data-[state=active]:bg-[#EFEFF9] items-center justify-center rounded-lg border-none'
                                        )}
                                    >
                                        <p className='text-gray7 group-data-[state=active]:text-brand font-medium'>
                                            Eligible users
                                        </p>
                                        <span className='py-1 p-3 text-white bg-gray7 group-data-[state=active]:bg-brand rounded-full font-semibold'>
                                            {eligibleEmployees?.length}
                                        </span>
                                    </TabsTrigger>
                                    <TabsTrigger
                                        value='ineligible'
                                        className={cn(
                                            'group px-3 w-full py-2 flex gap-4 bg-white data-[state=active]:bg-[#EFEFF9] items-center justify-center rounded-lg border-none'
                                        )}
                                    >
                                        <p className='text-gray7 group-data-[state=active]:text-brand font-medium'>
                                            Ineligible users
                                        </p>
                                        <span className='py-1 p-3 text-white bg-gray7 group-data-[state=active]:bg-brand rounded-full font-semibold'>
                                            {inEligibleEmployees?.length}
                                        </span>
                                    </TabsTrigger>
                                </TabTriggersContainer>
                            </TabsList>
                            <TabsContent value={'eligible'}>
                                <div className='my-4'>
                                    <h1 className='text-lg font-semibold'>Add users to generate manual payroll</h1>
                                    <p className='text-base mt-1'>Here are a list of users due for payment</p>
                                </div>
                                <div className='border border-[#DADCE0] h-[45px] rounded-lg flex items-center overflow-hidden'>
                                    <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                                        <CiSearch fontSize={25} />
                                    </div>
                                    <input
                                        type='text'
                                        placeholder='Search users'
                                        className='h-full w-[60%] outline-none placeholder:text-sm'
                                    />
                                </div>
                                <section className=''>
                                    <div className={`flex gap-2 item-center text-[#5E5E5E] mt-3`}>
                                        <input
                                            type='checkbox'
                                            name='asPDF'
                                            id='asPDF'
                                            className='accent-[#454ADE] cursor-pointer'
                                            onChange={e => {
                                                setAddAllEligible(e.target.checked)
                                                if (e.target.checked) {
                                                    setFormState(eligibleEmployees)
                                                } else {
                                                    setFormState([])
                                                }
                                            }}
                                            checked={addAllEligible}
                                        />
                                        <label
                                            htmlFor='asPDF'
                                            className='text-sm font-medium text-brand cursor-pointer'
                                        >
                                            Add all eligible users
                                        </label>
                                    </div>
                                    {formState.length > 0 && (
                                        <div className='flex flex-wrap gap-[10px] mt-4'>
                                            {formState.map((selected, idx) => (
                                                <div className='px-3 py-2 text-gray7 flex gap-4 bg-offwhite items-center rounded-lg'>
                                                    <p>
                                                        {selected.employee.user.first_name}{' '}
                                                        {selected.employee.user.last_name}
                                                    </p>
                                                    <CgClose
                                                        color='#CF491E'
                                                        onClick={() => deleteEmployee(idx)}
                                                        className='cursor-pointer'
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div className='border-t border-gray8 mt-4'>
                                        {eligibleEmployees
                                            ?.filter(val => !formState.find(emp => emp._id === val._id))
                                            ?.map((profile, i) => (
                                                <div className='py-4 grid grid-cols-2 border-b border-gray8' key={i}>
                                                    <div className='border-r border-gray8'>
                                                        <p className='font-semibold'>
                                                            {profile.employee.user.first_name}{' '}
                                                            {profile.employee.user.last_name}
                                                        </p>
                                                        <p className='text-xs text-gray7 font-medium'>
                                                            Days worked -{' '}
                                                            {/* {differenceInDays(new Date(), new Date(profile.start_date))} */}
                                                            {profile?.days_worked}
                                                        </p>
                                                    </div>
                                                    <div className='pl-5 flex justify-between items-center'>
                                                        <div className=''>
                                                            <p className='font-medium'>
                                                                {formatAmount(profile.current_payout)}
                                                                {formatAmount(profile.net_salary)}
                                                            </p>
                                                            <p className='text-xs text-gray7 font-medium'>
                                                                Total payout
                                                            </p>
                                                        </div>
                                                        <button
                                                            className='h-8 w-8 bg-[#EFEFF9] rounded-lg flex items-center justify-center'
                                                            onClick={() => setFormState([...formState, profile])}
                                                        >
                                                            <Plus color='#454ADE' height={20} />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </section>
                                <div className='sticky bg-white bottom-0 w-full flex items-center justify-end gap-4 px-6 py-2 rounded-b-2xl border-t border-gray8'>
                                    <Button
                                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] disabled:opacity-30'
                                        disabled={formState.length === 0}
                                        onClick={generatePayrollFn}
                                        loading={isGeneratingPayroll}
                                    >
                                        Generate payroll
                                    </Button>
                                </div>
                            </TabsContent>
                            <TabsContent value={'ineligible'} className='text-brand font-semibold '>
                                <div className='h-96 grid place-items-center'>Coming soon 🤗</div>
                            </TabsContent>
                        </Tabs>
                    </section>
                </div>
            </main>
        </ModalWrapper>
    )
}
