type CustomError = {
    data: {
        error: {
            location: string
            msg: string
            path: string
        }[]
        message: string
    }
}

import { toastHandler } from '@/components/utils/Toast'

const handleResponseError = (error: CustomError) => {
    if (error && error.data) {
        const { error: errors, message } = error.data

        if (message) {
            toastHandler({ message, state: 'error' })
            return
        }

        if (typeof errors === 'string') {
            toastHandler({ message: errors, state: 'error' })
            return
        }

        errors.forEach(error => {
            return toastHandler({ message: error.msg, state: 'error' })
        })
    } else {
        console.log(error)
    }
}

export default handleResponseError
