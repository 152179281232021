import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { toastHandler } from '@/components/utils/Toast'
import { useDeleteVaultItem, useMoveToTrash } from '@/services/vault/mutations'
import { VaultItem } from '@/services/vault/types'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    file: VaultItem
    fileType: string
}

export const DeleteModal = ({ closeModal, fileType, file }: Props) => {
    const { mutate: moveToTrashFn, isLoading: isMoving } = useMoveToTrash({
        onSuccess: () => {
            toastHandler({
                message: `${fileType === 'file' ? 'File' : 'Folder'} moved to trash.`,
                state: 'success',
            })
            closeModal(false)
        },
    })
    const { mutate: deleteFn, isLoading: isDeleting } = useDeleteVaultItem({
        queryParams: {
            id: file.id,
        },
        onSuccess: () => {
            toastHandler({
                message: `${fileType === 'file' ? 'File' : 'Folder'} deleted sucessfully`,
                state: 'success',
            })
            closeModal(false)
        },
    })
    return (
        <ModalWrapper variants={undefined}>
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5 truncate'>
                        Delete {file.deleted ? 'forever' : fileType === 'file' ? 'file' : 'folder'} - {file.name}
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='p-7 pt-5'>
                    <p className='font-medium'>
                        {file.deleted && (
                            <>
                                Are you sure you want to permanently delete this{' '}
                                {fileType === 'file' ? 'file' : 'folder'}? This action cannot be undone, and the{' '}
                                {fileType === 'file' ? 'file' : 'folder'} will be permanently removed.
                            </>
                        )}
                        {!file.deleted && (
                            <>
                                Deleting this {fileType === 'file' ? 'file' : 'folder'} will move it to the trash, where
                                it will be permanently removed after 30 days unless restored.
                            </>
                        )}
                    </p>
                </div>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        className='bg-errorRed'
                        onClick={() => {
                            file.deleted ? deleteFn({}) : moveToTrashFn({ itemId: file.id })
                        }}
                        loading={isMoving || isDeleting}
                        disabled={isMoving || isDeleting}
                    >
                        Yes, Delete
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
