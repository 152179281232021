import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import PersonalInfoKYCModal from './PersonalInfoKYCModal'

import KYCSuccessModal from './KYCSuccessModal'
import StartKYCModal from './StartKYCModal'
import { FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import PaymentInformation from './PaymentInformation'
import { useGetListOfBanks } from '@/services/payout/queries'

export interface PersonalDetailsFormValues {
    phone_number: string | undefined
    address: string
    city: string
    postal_code: string
    state: string
    country: string
    date_of_birth: Date | string
    gender: string
    place_of_birth: string
    bvn: string
}

export interface IdentityDetailsFormValues {
    id_type: string
    id_number: string
    proof: File | undefined
    id_type_file: FileUploadFormatProps | undefined
    profile_file: FileUploadFormatProps | undefined
}
export type OverallKYC = IdentityDetailsFormValues & PersonalDetailsFormValues

const kycConsts: OverallKYC = {
    address: '',
    city: '',
    phone_number: '',
    postal_code: '',
    state: '',
    id_type: '',
    id_number: '',
    proof: undefined,
    country: '',
    date_of_birth: new Date(),
    gender: '',
    place_of_birth: '',
    bvn: '',
    id_type_file: {} as FileUploadFormatProps,
    profile_file: {} as FileUploadFormatProps,
}
type Props = {
    setShowKYCModalOnce: Dispatch<SetStateAction<boolean>>
}

export default function KYCInfoContainer({ setShowKYCModalOnce }: Props) {
    const [kycInfoStep, setKycInfoStep] = useState(1)
    const [overallKYCForrmDetails, setOverallKYCForrmDetails] = useState<OverallKYC>(kycConsts)

    const { data } = useGetListOfBanks()
    const allBankList = useMemo(() => data ?? [], [data])

    return (
        <ModalWrapper variants={undefined} className='rounded-md '>
            <section
                className={`${kycInfoStep > 0 && '  max-h-[95vh] pb-7 '} w-screen lg:w-[730px] overflow-scroll  flex flex-col`}
            >
                <div className='flex-1'>
                    {kycInfoStep < 3 && kycInfoStep !== 0 && (
                        <div className='flex items-center bg-[#F9F9F9] p-4 py-2 absolute top-0 w-full rounded-t-md z-50'>
                            <h1 className='flex-1 text-center font-semibold text-2xl text-[#31254B]'>Complete KYC</h1>
                            <AiOutlineClose
                                className='bg-[#F2F2F2] text-[#838383] inline-block w-12 h-12 p-3 rounded-full cursor-pointer'
                                onClick={() => {
                                    setShowKYCModalOnce(false)
                                }}
                            />
                        </div>
                    )}
                    <main className={`${kycInfoStep !== 0 && 'mt-[5rem]'} mx-[1rem] lg:mx-[2rem] text-[#202020]`}>
                        {kycInfoStep < 3 && kycInfoStep !== 0 && (
                            <section className='flex'>
                                <div className='flex-1'>
                                    <h2 className='text-xl lg:text-2xl font-medium'>
                                        {kycInfoStep === 1 ? 'Complete personal information' : 'Add payout information'}
                                    </h2>
                                    <p className='text-[#5E5E5E] text-sm my-1 lg:my-3'>
                                        {kycInfoStep === 1
                                            ? ' We appreciate your cooperation in providing the required information.'
                                            : 'Add your bank details to receive reimbursemnts and salaries directly'}
                                    </p>
                                </div>
                                <div>
                                    <span className='py-2 px-4 bg-[#EFEFF9] rounded-2xl flex items-center gap-2'>
                                        {kycInfoStep}/ 2 <span className='font-semibold'> steps</span>
                                    </span>
                                </div>
                            </section>
                        )}

                        {kycInfoStep === 0 ? (
                            <StartKYCModal setKycInfoStep={setKycInfoStep} />
                        ) : kycInfoStep === 1 ? (
                            <PersonalInfoKYCModal
                                kycInfoStep={kycInfoStep}
                                setKycInfoStep={setKycInfoStep}
                                overallKYCForrmDetails={overallKYCForrmDetails}
                                setOverallKYCForrmDetails={setOverallKYCForrmDetails}
                            />
                        ) : kycInfoStep === 2 ? (
                            <PaymentInformation allBankList={allBankList} setKycInfoStep={setKycInfoStep} />
                        ) : (
                            <KYCSuccessModal setShowKYCModal={setShowKYCModalOnce} />
                        )}
                    </main>
                </div>
            </section>
        </ModalWrapper>
    )
}
