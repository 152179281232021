import { EmployeeType, OrgType } from '@/types/org'
import React, { createContext, useState } from 'react'

export type defaultImpersonationprops = {
    impersonationMode: boolean
    setImpersonationMode: React.Dispatch<React.SetStateAction<boolean>>
    user?: EmployeeType
    setUser: React.Dispatch<React.SetStateAction<EmployeeType | undefined>>
    orgDetails?: OrgType
    setOrgDetails: React.Dispatch<React.SetStateAction<OrgType | undefined>>
}

const defaultImpersonationState: defaultImpersonationprops = {
    impersonationMode: false,
    setImpersonationMode: () => undefined,
    user: undefined,
    setUser: () => undefined,
    orgDetails: undefined,
    setOrgDetails: () => undefined,
}

export const ImpersonationContext = createContext(defaultImpersonationState)

export const ImpersonationContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [impersonationMode, setImpersonationMode] = useState<boolean>(false)
    const [orgDetails, setOrgDetails] = useState<OrgType>()
    const [user, setUser] = useState<EmployeeType>()

    const value = {
        impersonationMode,
        setImpersonationMode,
        user,
        setUser,
        orgDetails,
        setOrgDetails,
    }
    return <ImpersonationContext.Provider value={value}>{children}</ImpersonationContext.Provider>
}
