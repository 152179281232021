import React from 'react'
import { capitalizeText } from '@/utils/string'
import { BillStatus } from '@/types/bill-payments'

interface BillStatusBadgeProps {
    status: BillStatus
    allowBg?: boolean
}

const BillStatusBadge: React.FC<BillStatusBadgeProps> = ({ status, allowBg = false }) => {
    let badgeColor: string

    switch (status) {
        case BillStatus.approved:
            badgeColor = 'bg-[#EFFFED] text-[#19C37D]'
            break
        case BillStatus.draft:
            badgeColor = 'text-[purple] bg-[purple]/10'
            break
        case BillStatus.paid:
            badgeColor = 'bg-[#EBF5FF] text-[#5C9BFF]'
            break
        case BillStatus.declined:
            badgeColor = 'bg-[#FFEDED] text-[#CF491E]'
            break
        case BillStatus.pending:
            badgeColor = 'bg-[#FDFCDB] text-[#DEB145]'
            break
        case BillStatus.scheduled:
            badgeColor = 'bg-[#F8F1DE] text-[#A89011]'
            break
        default:
            badgeColor = 'bg-[#EFEFF9] text-[#202020]/70'
            break
    }

    return (
        <div
            className={`px-5 py-1 rounded-2xl max-w-fit inline-flex items-center justify-center ${allowBg && badgeColor}`}
        >
            {capitalizeText(status)}
        </div>
    )
}

export default BillStatusBadge
