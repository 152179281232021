import { PaydayIcon, RecentContractIcon, UsersIcon } from '@/assets/assets.export'
import { useAuthContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetOrgContractPayroll, useGetPayrollByDate } from '@/services/payroll/queries'
import { QueryOrgContractPayRollType } from '@/services/payroll/types'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { differenceInDays, format } from 'date-fns'
import { useMemo } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function NewInflowSection() {
    const { selectedAccount } = useAuthContext()
    const { data: org_members, isLoading: isLoadingMembers } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const orgMembers = useMemo(() => org_members ?? [], [org_members])

    const { data: orgPayRollByDate, isLoading: isLoadingPayroll } = useGetPayrollByDate({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })
    const { data: org_contract_payroll, isLoading: isLoadingContract } = useGetOrgContractPayroll({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })
    const orgContractPayroll = useMemo(
        () => org_contract_payroll ?? ({} as QueryOrgContractPayRollType),
        [org_contract_payroll]
    )
    const isSkeleton = isLoadingContract || isLoadingMembers || isLoadingPayroll

    return (
        <section className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mb-32'>
            <section className={`bg-white rounded-xl p-4 ${isSkeleton ? '' : 'md:min-h-[25rem]'}`}>
                <div className='flex items-center justify-between rounded text-dark'>
                    <div className=''>
                        <span className=' font-semibold text-base'>Newest users</span>
                        <p className='text-sm text-gray7 mt-1'>See the newest users in your company this month</p>
                    </div>
                </div>
                <div className='flex flex-col gap-3 mt-6 md:h-3/4' id='newest-users'>
                    {isSkeleton ? (
                        <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                            <Skeleton />
                        </SkeletonTheme>
                    ) : (
                        <>
                            {orgMembers?.length > 1 ? (
                                orgMembers?.slice(0, 4)?.map(user => (
                                    <div className='border border-gray5 p-4 py-5 rounded-xl flex items-center justify-between'>
                                        <div className='flex items-center gap-3'>
                                            <div>
                                                <h5 className='text-sm font-semibold'>
                                                    {capitalizeText(user?.user?.first_name)}{' '}
                                                    {capitalizeText(user?.user?.last_name)}
                                                </h5>
                                                <p className='text-xs text-gray7'>{capitalizeText(user?.role?.name)}</p>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className=''>
                                                <p className={`text-sm font-semibold`}>
                                                    {user?.createdAt && format(new Date(user?.createdAt), 'dd/MM/yyyy')}
                                                </p>
                                                {/* <p
                                                    className={`${
                                                        user.user?.is_kyc_complete ? 'text-brightGreen' : 'text-gold'
                                                    } text-xs text-right`}
                                                >
                                                    {user.user?.is_kyc_complete ? 'Incomplete KYC' : 'Incomplete KYC'}
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                    <UsersIcon />
                                    <h2 className='text-gray7 font-medium mt-5'>No new users</h2>
                                    <p className='text-sm'>No new users this month</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
            <section className={`bg-white rounded-xl p-4 ${isSkeleton ? '' : 'md:min-h-[25rem]'} hidden`}>
                <div className='flex items-center justify-between rounded text-dark'>
                    <div className=''>
                        <span className=' font-semibold text-base'>Upcoming pay days</span>
                        <p className='text-sm text-gray7 mt-1'>See upcoming salaries for the month</p>
                    </div>
                </div>
                <div className='flex flex-col gap-3 mt-6 h-3/4'>
                    {isSkeleton ? (
                        <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                            <Skeleton />
                        </SkeletonTheme>
                    ) : (
                        <>
                            {Array.isArray(orgPayRollByDate) ? (
                                orgPayRollByDate?.map(salary => {
                                    const paydueDateFromNow =
                                        salary?.pay_period?.end &&
                                        differenceInDays(new Date(salary?.pay_period?.end), new Date())
                                    return (
                                        <div className='border border-gray5 p-4 py-5 rounded-xl flex items-center justify-between'>
                                            <div className='flex items-center gap-3'>
                                                <div>
                                                    <h5 className='text-sm font-semibold'>
                                                        {salary?.pay_period?.start &&
                                                            format(
                                                                new Date(salary?.pay_period?.start),
                                                                'dd/MM/yyyy'
                                                            )}{' '}
                                                        -
                                                        {salary?.pay_period?.end &&
                                                            format(new Date(salary?.pay_period?.end), 'dd/MM/yyyy')}
                                                    </h5>
                                                    <p className='text-xs text-gray7'>
                                                        {salary?.payrollData?.[0]?.numberOfEmployees} users
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='flex'>
                                                <div className=''>
                                                    <p className={`text-sm font-semibold`}>
                                                        {formatAmount(salary?.payrollData[0]?.cumulativeNetSalary)}
                                                    </p>
                                                    <p
                                                        className={`${
                                                            Number(paydueDateFromNow) < 5
                                                                ? 'text-errorRed'
                                                                : 'text-brand'
                                                        } text-xs text-right`}
                                                    >
                                                        Due in {paydueDateFromNow} days
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                    <PaydayIcon />
                                    <h2 className='text-gray7 font-medium mt-5'>No upcoming pay days</h2>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
            <section className={`bg-white rounded-xl p-4 ${isSkeleton ? '' : 'md:min-h-[25rem]'} hidden`}>
                <div className='flex items-center justify-between rounded text-dark'>
                    <div className=''>
                        <span className=' font-semibold text-base'>Recent contracts</span>
                        <p className='text-sm text-gray7 mt-1'>See the latest contracts created in this month</p>
                    </div>
                </div>
                <div className='flex flex-col gap-3 mt-6 h-3/4'>
                    {isSkeleton ? (
                        <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                            <Skeleton />
                        </SkeletonTheme>
                    ) : (
                        <>
                            {orgContractPayroll?.payrolls?.length > 0 ? (
                                orgContractPayroll?.payrolls?.slice(0, 2)?.map(contract => (
                                    <div className='border border-gray5 p-4 py-5 rounded-xl flex flex-col gap-2'>
                                        <div>
                                            <h5 className='text-xs text-gray7'>Title</h5>
                                            <p className='whitespace-nowrap truncate text-sm font-semibold'>
                                                {capitalizeText(contract.contract_title)}
                                            </p>
                                        </div>
                                        <div className='flex gap-4 justify-between'>
                                            <div>
                                                <h5 className='text-xs text-gray7'>Issued by</h5>
                                                <p className='whitespace-nowrap truncate text-sm font-semibold'>
                                                    {'N/A'}
                                                </p>
                                            </div>
                                            <div>
                                                <h5 className='text-xs text-gray7'>Contractor</h5>
                                                <p className='whitespace-nowrap truncate text-sm font-semibold'>
                                                    {contract.employee?.user?.first_name}
                                                    {contract.employee?.user?.last_name}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className='text-xs text-gray7'>Amount</h5>
                                            <p className='whitespace-nowrap truncate text-sm font-semibold'>
                                                {formatAmount(contract?.total_pay)}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                    <RecentContractIcon />
                                    <h2 className='text-gray7 font-medium mt-5'>No recent contracts</h2>
                                    <p className='text-sm'>No contracts created this month</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
        </section>
    )
}
