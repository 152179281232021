export const capitalizeText = (text?: string) => {
    if (!text) return
    const textToBeTransformed = text.toLowerCase()
    return textToBeTransformed[0].toUpperCase() + textToBeTransformed.slice(1, textToBeTransformed.length)
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const checkifReceiptIsPdf = (receipt?: string) => {
    return !!receipt?.split('.').slice(-1)[0]
}
