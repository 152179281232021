export enum FILE_TYPE {
    image = 'image',
    pdf = 'pdf',
}

export enum EventEnum {
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    REIMBURSED = 'REIMBURSED',
    CREATED = 'CREATED',
    PAID = 'PAID',
    SCHEDULED = 'SCHEDULED',
    PROCESSING = 'PROCESSING',
    DENIED = 'DENIED',
    CANCELLED = 'CANCELLED',
}
