import PayrollEmptyState from '@/assets/payroll-empty-state.svg'
import AddToPayrollModal from '@/components/Modals/Payroll/AddToPayroll/AddToPayrollModal'
import MakePaymentProfileActive from '@/components/Modals/Payroll/MakePaymentProfileActive/MakePaymentProfileActive'
import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import { useAuthContext, usePayrollContext } from '@/context'
import { QueryOrgMembers } from '@/services/employees/types'
import { useGetPayrollSettingsForOrg } from '@/services/payroll/queries'
import { PaymentProfile } from '@/types/payroll'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { ColumnDef, ColumnSort, createColumnHelper } from '@tanstack/react-table'
import React, { useState } from 'react'

type Props = {
    orgMembers: QueryOrgMembers
    payrolls: PaymentProfile[]
}

export default function PayrollListTable({ payrolls, orgMembers }: Props) {
    const columnHelper = createColumnHelper<PaymentProfile>()
    const [rowSelection, setRowSelection] = React.useState({})
    const { setSort } = usePayrollContext()
    const { selectedAccount } = useAuthContext()
    const [openModal, setOpenModal] = useState(false)
    const [openActiveModal, setOpenActiveModal] = useState(false)
    const [editPayroll, setEditPayroll] = useState(false)
    const [payrollDetails, setPayrollDetails] = useState<PaymentProfile | undefined>()

    const { data: orgPayrollSettings } = useGetPayrollSettingsForOrg({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    const handleRowClick = (data: PaymentProfile) => {
        setPayrollDetails(data)
        setOpenModal(true)
        setEditPayroll(true)
    }

    const handleSetActive = (data: PaymentProfile) => {
        setPayrollDetails(data)
        setOpenActiveModal(true)
    }

    const columns: ColumnDef<PaymentProfile, any>[] = [
        columnHelper.accessor('employee.employee_id', {
            header: () => <p>User ID</p>,
            cell: cell => cell.renderValue(),
            enableSorting: true,
        }),
        columnHelper.accessor('employee.user', {
            header: () => <>Name</>,
            cell: cell => {
                return (
                    <>
                        {capitalizeText(cell?.renderValue()?.first_name as string)}{' '}
                        {capitalizeText(cell?.renderValue()?.last_name as string)}
                    </>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('pay_frequency', {
            header: () => <p>Pay frequency</p>,
            cell: cell => capitalizeText(cell.renderValue()),
            enableSorting: false,
        }),
        columnHelper.accessor('gross_salary', {
            header: () => <p>Gross salary</p>,
            cell: cell => formatAmount(cell.renderValue()),
            enableSorting: true,
        }),
        columnHelper.accessor('computed_deduction', {
            header: () => <p>Deductions</p>,
            cell: cell => (
                <span>
                    {/* {JSON.stringify(cell.renderValue())} */}
                    {formatAmount(cell.renderValue())}
                </span>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('computed_bonus', {
            header: () => <p>Bonuses</p>,
            cell: cell => <span>{formatAmount(cell.renderValue())}</span>,
            enableSorting: true,
        }),

        columnHelper.display({
            id: 'actions',

            cell: row => (
                <TableActions
                    actions={[
                        {
                            label: 'Edit Profile',
                            method: () => {
                                handleRowClick(row?.row.original as unknown as PaymentProfile)
                            },
                        },
                        {
                            label: `${row?.row.original.active ? 'Deactivate' : 'Activate'} profile`,
                            method: () => {
                                handleSetActive(row?.row.original as unknown as PaymentProfile)
                            },
                        },
                    ]}
                />
            ),
        }),
    ]

    return (
        <section className='h-full'>
            <Table
                data={payrolls}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleSort={handleSort}
                handleRowClicked={handleRowClick}
                emptyStateImg={PayrollEmptyState}
                rowClickable={true}
                emptyStateTitle='Start Building Your Payroll List'
                emptyStateDescription=" You can either import users by clicking the  “Add to payroll ” button or manually add users to the payroll. Let's build your payroll list."
            />

            {openModal && (
                <AddToPayrollModal
                    orgPayrollSettings={orgPayrollSettings}
                    closeModal={setOpenModal}
                    setEditPayRoll={setEditPayroll}
                    orgMembers={orgMembers}
                    payrollDetails={payrollDetails}
                    editPayroll={editPayroll}
                />
            )}
            {openActiveModal && (
                <MakePaymentProfileActive closeModal={setOpenActiveModal} payrollDetails={payrollDetails} />
            )}
        </section>
    )
}
