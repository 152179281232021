import ProgressBar from '@/components/ProgressBar'
import { Tooltip, TooltipContent, TooltipProvider } from '@/components/Tooltips'

import { useAuthContext } from '@/context'
import { useGetOrgUsageRecord } from '@/services/billing/queries'
import { UsageRecordType } from '@/types/billing'
import { TooltipTrigger } from '@radix-ui/react-tooltip'
import { AlertCircleIcon } from 'lucide-react'
import { useMemo } from 'react'

export default function PlanUsage() {
    const { selectedAccount } = useAuthContext()

    const { data, isLoading } = useGetOrgUsageRecord({
        queryParams: {
            org: selectedAccount?.org?.id as string,
        },
    })

    const orgUsageRecord = useMemo(() => data ?? ({} as UsageRecordType), [data])
    const modules = orgUsageRecord?.modules
    const plan = selectedAccount?.org?.business_plan?.plan

    const renewableUsage = [
        {
            name: 'Expense management',
            details: [
                {
                    name: 'Expense reports',
                    desc: `${modules?.expenses?.allocated - modules?.expenses?.available} / ${modules?.expenses?.allocated}`,
                },

                {
                    name: 'OCR scanning',
                    desc: `${modules?.ocr_scan?.allocated - modules?.ocr_scan?.available} / ${modules?.ocr_scan?.allocated}`,
                },
            ],
        },

        {
            name: 'Invoicing',
            details: [
                {
                    name: 'Invoice count',
                    desc: `${modules?.invoices?.allocated - modules?.invoices?.available} / ${modules?.invoices?.allocated}`,
                },
            ],
        },
    ]
    const nonRenewableUsage = [
        {
            name: 'Users',
            details: [
                {
                    name: 'User count',
                    desc: `${modules?.people?.allocated - modules?.people?.available} / ${modules?.people?.allocated}`,
                },
            ],
        },
        {
            name: 'Partners',
            details: [
                {
                    name: 'Vendor count',
                    desc: `${modules?.vendors?.allocated - modules?.vendors?.available} / ${modules?.vendors?.allocated}`,
                },
                {
                    name: 'Customer count',
                    desc: `${modules?.customers?.allocated - modules?.customers?.available} / ${modules?.customers?.allocated}`,
                },
            ],
        },
        {
            name: 'Budget',
            details: [
                {
                    name: 'Budget count',
                    desc: `${modules?.budgets?.allocated - modules?.budgets?.available} / ${modules?.budgets?.allocated}`,
                },
            ],
        },
    ]

    if (isLoading) return <ProgressBar />
    return (
        <section className='flex-1 bg-white rounded-xl p-5 shadow-sm mb-6'>
            <h3 className='font-medium text-md pb-2 flex items-center gap-2'>
                Features usage{' '}
                <span className='rounded-xl bg-green/20 text-green p-1 px-2 text-xs'>
                    {typeof plan === 'string' ? 'N/A' : plan?.name}
                </span>
            </h3>

            <div className='mt-5 grid grid-cols-2 gap-4'>
                <div className=''>
                    <h2 className='font-medium text-sm mb-5 flex items-center gap-2'>
                        Renewable
                        <TooltipProvider delayDuration={10}>
                            <Tooltip>
                                <TooltipTrigger className='' type='button'>
                                    <AlertCircleIcon className='' size={15} />
                                </TooltipTrigger>

                                <TooltipContent className='max-w-xs'>
                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                        {' '}
                                        These features automatically renew at the end of each subscription period. You
                                        can count on continued access to this feature without interruption.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </h2>
                    {renewableUsage?.map(data => (
                        <div className='mb-6'>
                            <h3 className='font-medium text-base mb-1'>{data.name}</h3>
                            <div className='flex flex-wrap gap-8'>
                                {data.details.map(detail => (
                                    <div>
                                        <span className='text-[#838383] font-normal text-sm'>{detail.name}</span>
                                        <div className='flex gap-3 items-center  truncate'>
                                            <span className='font-medium text-[.9rem] truncate max-w-[12rem]'>
                                                {detail.desc}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='border-l border-gray2 pl-5'>
                    <h2 className='font-medium text-sm mb-5 flex items-center gap-2'>
                        Non-renewable
                        <TooltipProvider delayDuration={10}>
                            <Tooltip>
                                <TooltipTrigger className='' type='button'>
                                    <AlertCircleIcon className='' size={15} />
                                </TooltipTrigger>

                                <TooltipContent className='max-w-xs'>
                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                        These features do not automatically renew at the end of each subscription
                                        period. Please note that access to this feature will cease once the subscription
                                        expires unless plan is upgraded
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </h2>
                    {nonRenewableUsage?.map(data => (
                        <div className='mb-6'>
                            <h3 className='font-medium text-base mb-1'>{data.name}</h3>
                            <div className='flex flex-wrap gap-8'>
                                {data.details.map(detail => (
                                    <div>
                                        <span className='text-[#838383] font-normal text-sm'>{detail.name}</span>
                                        <div className='flex gap-3 items-center  truncate'>
                                            <span className='font-medium text-[.9rem] truncate max-w-[12rem]'>
                                                {detail.desc}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
