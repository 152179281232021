import { TransactionType } from '@/types/transactions'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { FlagIcon } from 'lucide-react'
import TransactionStatusBadge from '../../Transactions/components/TransactionStatusBadge'
import { TransactionStatus } from '../../Transactions/const'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext } from '@/context'

type Props = {
    transactions: TransactionType[]
}

export const LinkedBillTransactionsTable = ({ transactions }: Props) => {
    const { selectedAccount } = useAuthContext()
    const transactionsData = transactions?.[0] as TransactionType
    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const reimburser = org_members?.find(
        memb => memb?.user?._id === (transactions?.[0]?.initiated_by as unknown as string)
    )
    return (
        <section className='bg-white rounded-xl py-6'>
            <section className='rounded-xl border border-[#DADCE0]  mx-2 mb-1 overflow-y-scroll'>
                <table className='w-full leading-normal rounded-xl'>
                    <tr className='flex gap-6 text-left bg-[#f6f6f6] text-[#838383] font-light rounded-t-xl overflow-hidden'>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Transaction ID
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Date & time
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Amount </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Initiated by{' '}
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Destination
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Type</th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Status </th>
                    </tr>
                    {transactionsData && (
                        <tr className='flex gap-6 text-[.9rem] '>
                            <td className='w-[7rem] px-4 py-4'>
                                {transactionsData?.transaction_id}
                                {transactionsData?.flagged && <FlagIcon className='h-4 w-4' />}
                            </td>
                            <td className='w-[7rem] px-4 py-4'>
                                <p className='font-normal mb-1 text-sm'>
                                    {format(new Date(transactionsData?.createdAt ?? ''), 'dd/MM/yyyy')}
                                </p>
                                <p className='font-normal mb-1 text-sm'>
                                    {format(new Date(transactionsData?.createdAt ?? ''), 'hh:mm a')}
                                </p>
                            </td>
                            <td className='w-[7rem] px-4 py-4'>
                                <span className='font-normal whitespace-nowrap'>
                                    {transactionsData.ownerTransactionType === 'DEBIT' ? (
                                        <span className='text-[#CF491E]'>
                                            {formatAmount(transactionsData.debit.amount)}
                                        </span>
                                    ) : (
                                        <span className='text-[#19C37D]'>
                                            {formatAmount(transactionsData.credit.amount)}
                                        </span>
                                    )}
                                </span>
                            </td>
                            <td className='w-[7rem] px-4 py-4'>
                                {reimburser ? (
                                    <>
                                        {reimburser?.user?.first_name} {reimburser?.user?.last_name}
                                    </>
                                ) : (
                                    'N/A'
                                )}
                            </td>
                            <td className='w-[7rem] px-4 py-4'>N/A </td>
                            <td className='w-[7rem] px-4 py-4'>{transactionsData?.type} </td>
                            <td className='w-[7rem] px-4 py-4 whitespace-nowrap'>
                                <TransactionStatusBadge
                                    status={transactionsData.status.toLowerCase() as unknown as TransactionStatus}
                                />
                            </td>{' '}
                        </tr>
                    )}
                    {!transactionsData && (
                        <tr className='text-center text-[#A8A8A8] h-12 flex items-center justify-center'>
                            No data availabe yet
                        </tr>
                    )}
                </table>
            </section>
        </section>
    )
}
