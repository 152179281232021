import { ExpenseType } from '@/types/expense'
import { RoleEnum } from '@/types/org'

export type filter_by_Props = {
    [key: string]: any
}

export const modalVariants = {
    hidden: {
        opacity: 0,
        x: '100%',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.3,
        },
    },
}

export const checkIfAmountIsValidWithOCR = (expense: ExpenseType) => {
    if (!expense?.ai_scan_results?.amount) return
    if (expense.scanned_with_ai && expense?.ai_scan_results?.amount !== expense?.amount) {
        return true
    }
    return false
}

//----------------------------------------------------
const date = new Date()
export const FilterEmployeeUtils = {
    role_types: ['ALL', RoleEnum.EMPLOYEE, RoleEnum.CONTRACTOR, RoleEnum.ADMIN, RoleEnum.OWNER],
    periods: [
        {
            period: '2 days ago',
            type: '2days',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2)}`
            },
        },
        {
            period: '1 month ago',
            type: '1_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 1, date.getDate() - 1)}`
            },
        },
        {
            period: '3 months ago',
            type: '3_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 3, date.getDate() - 1)}`
            },
        },
        {
            period: '6 months ago',
            type: '6_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 6, date.getDate() - 1)}`
            },
        },
        {
            period: '1 year ago',
            type: '1_year',
            getDate: () => {
                return `${new Date(date.getFullYear() - 1, date.getMonth() - 1, date.getDate() - 1)}`
            },
        },
    ],
    filter_by: [
        { 'Expense category': { type: 'expense_category' } },
        { 'Created by': { type: 'created_by' } },
        { 'Approved by': { type: 'approved_by' } },
        { 'Reimbursed  by': { type: 'reimbursed_by' } },
    ],

    amount: [
        {
            amount: 'Less than ₦50,000.00',
            type: 'lessthan_50k',
            method: () => {
                return {
                    max: 49999,
                    min: 0,
                }
            },
        },
        {
            amount: '₦50,000.00 - ₦200,000.00 ',
            type: 'lessthan_200k',
            method: () => {
                return {
                    max: 199999,
                    min: 50000,
                }
            },
        },
        {
            amount: '₦201,000.00 - ₦500,000.00 ',
            type: 'lessthan_500k',
            method: () => {
                return {
                    max: 499999,
                    min: 201000,
                }
            },
        },
        {
            amount: '₦501,000.00 - ₦1,000,000.00 ',
            type: 'lessthan_1m',
            method: () => {
                return {
                    max: 499999,
                    min: 201000,
                }
            },
        },
        { amount: '₦1,001,000.00 - ₦5,000,000.00', type: 'lessthan_5m' },
        { amount: '₦5,001,000.00 - ₦10,000,000.00', type: 'lessthan_10m' },
        { amount: '₦10,000,000.00+', type: 'greaterthan_10m' },
    ],
}
