import { createQuery } from '../api/query'
import { keyGetBankDetails } from './mutations'
import { QueryGetAllBanks, UserAccount } from './types'

export const useGetListOfBanks = createQuery<QueryGetAllBanks>({
    key: ['/bank-list'],
    url: '/get-list-of-banks',
})

export const useGetAccountsForUser = createQuery<UserAccount[]>({
    url: '/get-accounts-for-user',
    key: keyGetBankDetails,
})
