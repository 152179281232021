import { EmployeeType } from '@/types/org'
import { PaymentProfile } from '@/types/payroll'
import { User } from '@/types/user'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import moment from 'moment'

type Props = {
    orgMembers: EmployeeType[]
    payrollDetails?: PaymentProfile
    totalBonus: number
    totalDeductions: number
    formState: {
        employee: string | EmployeeType
        employment_type: string
        pay_day: any
        start_date: string | Date
        gross_salary: string
        net_salary: any
        pay_frequency: string
        prorate: string
    }
    editType?: string
}
export const EditPayrollView = ({
    orgMembers,
    payrollDetails,
    formState,
    totalBonus,
    totalDeductions,
    editType,
}: Props) => {
    const payoutName =
        editType === 'payout'
            ? orgMembers.find(
                  memb => (memb.id as unknown as string) === (payrollDetails?.employee as unknown as string)
              )?.user
            : ({} as User)

    const employee_name =
        editType && editType === 'payout'
            ? (((payoutName?.first_name as string) + ' ' + payoutName?.last_name) as string)
            : (((payrollDetails?.employee?.user?.first_name as string) +
                  ' ' +
                  payrollDetails?.employee?.user?.last_name) as string)
    const type = payrollDetails?.employment_type
    const gross_salary = payrollDetails?.gross_salary
    const pay_frequency = payrollDetails?.pay_frequency
    const start_date = payrollDetails?.start_date
    return (
        <div>
            <div className='rounded-2xl px-7 py-6 bg-offwhite flex flex-col gap-3 mb-5'>
                <div className='flex justify-between'>
                    <p className='text-gray7'>User</p> <p className='font-semibold'>{employee_name ?? ''}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray7'>Employment type</p>{' '}
                    <p className='font-semibold'>{capitalizeText(type ?? '')}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray7'>Gross salary</p>{' '}
                    <p className='font-semibold'>{formatAmount(gross_salary ?? '')}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray7'>Frequency</p>{' '}
                    <p className='font-semibold'>{capitalizeText(pay_frequency ?? '')}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray7'>Start date</p>{' '}
                    <p className='font-semibold'> {moment(start_date).format('DD/MM/YYYY')}</p>
                </div>
            </div>
            <div className='rounded-2xl px-7 py-6 bg-offwhite flex flex-col gap-3'>
                <div className='flex justify-between'>
                    <p className='text-gray7'>Gross salary</p>{' '}
                    <p className='font-semibold'>{formatAmount(gross_salary ?? '')}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray7'>Total deductions</p>{' '}
                    <p className='font-semibold'>{formatAmount(totalDeductions)}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray7'>Total bonuses</p>{' '}
                    <p className='font-semibold'>{formatAmount(totalBonus)}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray7'>Total payout for this month</p>{' '}
                    <p className='font-semibold'>{formatAmount(formState.net_salary as string)}</p>
                </div>
            </div>
        </div>
    )
}
