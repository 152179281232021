import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NotAuthorized = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate(-1)
        }, 2000)

        return () => clearTimeout(timeout)
    }, [navigate])

    return (
        <div className='flex justify-center items-center h-screen bg-gray-100'>
            <div className='bg-white shadow-lg rounded-lg p-8 max-w-md text-center'>
                <h1 className='text-4xl font-bold text-red-500 mb-4'>403 - Not Authorized</h1>
                <p className='text-gray-600 mb-6'>You don't have permission to view this page.</p>
                <p className='text-gray-500'>Redirecting to the previous page in a few seconds...</p>
            </div>
        </div>
    )
}

export default NotAuthorized
