import { Button } from '@/components'

import { useMutationToggleApprovalPolicyStatus } from '@/services/settings/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { ExpenseApprovalPolicy } from '@/services/settings/types'
import ModalWrapper from '../ModalWrapper'
import { SupportedPolicyConsts } from '@/types/approval-policy'

type PaymentProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    policy: ExpenseApprovalPolicy
    module: SupportedPolicyConsts
}

const TogglePolicyPrompt = ({ closeModal, policy, module }: PaymentProps) => {
    const { mutate: togglePolicyStatus, isLoading } = useMutationToggleApprovalPolicyStatus({
        queryParams: {
            policy_id: policy?._id as string,
            module: module,
        },
        onSuccess: () => {
            closeModal(false)
            toastHandler({ message: 'Approval Policy Status updated successfully', state: 'success' })
        },
    })

    return (
        <ModalWrapper>
            <main className='h-[19.5rem] lg:h-auto w-screen lg:w-[30rem]'>
                <div className='mt-5'>
                    <p className='text-gray7 font-medium text-base mb-5  mx-auto px-5 leading-7'>
                        Setting this policy to active will make any active policy inactive, as you can only have one
                        active policy per time.
                    </p>
                    <div className='flex gap-5 justify-end pr-10 mb-4'>
                        <Button
                            className='bg-transparent text-[#202020] px-5 py-2 h-[2rem] rounded-lg text-xs hover:bg-transparent min-w-[100px]'
                            onClick={() => closeModal(false)}
                        >
                            Cancel
                        </Button>

                        <Button
                            className='bg-brand text-white px-6 h-[2rem] rounded-lg text-[14px] min-w-[100px] py-2'
                            loading={isLoading}
                            onClick={() => togglePolicyStatus({ active: !policy?.active })}
                        >
                            Proceed
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default TogglePolicyPrompt
