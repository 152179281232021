import { Button, Textarea } from '@/components'
import React, { useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { toastHandler } from '@/components/utils/Toast'
import { Invoice } from '@/types/invoice'
import { useSendInvoiveReminder } from '@/services/invoice/mutations'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    invoice: Invoice
}
export default function InvoiceReminder({ invoice, closeModal }: Props) {
    const { mutate: sendReminderFn, isLoading } = useSendInvoiveReminder({
        queryParams: {
            invoice_id: invoice?._id ?? (invoice?.id as string),
        },
        onSuccess: () => {
            toastHandler({ message: 'Invoice reminder sent', state: 'success' })
            closeModal(false)
        },
    })

    const [formState, setFormState] = useState({
        message: '',
        asPDF: false,
        copy: false,
    })
    const disableButton = () => {
        const res = Object.values({ ...formState })
            .map((value, key) => (key !== 3 ? true : !!value))
            .some(data => data === false)
        return res
    }

    const handleSubmit = () => {
        sendReminderFn({
            invoice_id: invoice?._id ?? invoice?.id,
            message: formState?.message,
            attach_pdf: formState?.asPDF,
            send_admin_email: formState?.copy,
        })
    }
    return (
        <ModalWrapper variants={undefined}>
            <section className='w-screen lg:w-[30rem]  overflow-scroll pb-8'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end '>
                    <div className='flex items-center w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B]'>Send reminder</h2>
                        <div className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'>
                            <AiOutlineClose fontSize={25} onClick={() => closeModal(false)} />
                        </div>
                    </div>
                </div>
                <div className={`mx-4 mt-0 pt-2 overflow-y-scroll  h-[18rem]`}>
                    <div className='my-5 mx-1'>
                        <label
                            htmlFor='message'
                            className='text-[#202020] text-sm mb-3 font-medium flex items-center gap-1'
                        >
                            Message <span className='text-[#5E5E5E]'>(optional)</span>
                            <span className='ml-auto text-xs'>{formState.message?.length ?? '0'}/120</span>
                        </label>
                        <Textarea
                            className='border border-[#DADCE0] pt-4  text-18px resize-none'
                            maxLength={120}
                            value={formState.message}
                            placeholder='Enter message to customer'
                            onChange={e =>
                                setFormState(prev => ({
                                    ...prev,
                                    message: e.target.value! as unknown as string,
                                }))
                            }
                        />
                    </div>
                    <div className=''>
                        <>
                            <div className={`flex gap-2 item-center text-[#5E5E5E]`}>
                                <input
                                    type='checkbox'
                                    name='copy'
                                    id='copy'
                                    className='accent-[#454ADE] cursor-pointer'
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            copy: e.target.checked,
                                        }))
                                    }
                                    checked={formState.copy}
                                />
                                <label htmlFor='copy' className='text-sm font-medium cursor-pointer'>
                                    Send a copy to issuer at{' '}
                                    <span className='text-brand'>{invoice?.issuer?.user?.email}</span>
                                </label>
                            </div>

                            <div className={`flex gap-2 item-center text-[#5E5E5E] mt-3`}>
                                <input
                                    type='checkbox'
                                    name='asPDF'
                                    id='asPDF'
                                    className='accent-[#454ADE] cursor-pointer'
                                    onChange={e =>
                                        setFormState(prev => ({
                                            ...prev,
                                            asPDF: e.target.checked,
                                        }))
                                    }
                                    checked={formState.asPDF}
                                />
                                <label htmlFor='asPDF' className='text-sm font-medium cursor-pointer'>
                                    Attach invoice as PDF
                                </label>
                            </div>
                        </>
                    </div>
                </div>

                <div className='flex pt-5 items-center justify-end gap-4 px-6 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg'>
                    <Button
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        loading={isLoading}
                        onClick={handleSubmit}
                        disabled={disableButton()}
                    >
                        Send invoice
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
