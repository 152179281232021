import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
// import downArrow from '@/assets/down-arrow.svg'
import filter from '@/assets/filter.svg'
import React, { useState } from 'react'
// import CheckBox from '@/components/Table/CheckBox'
import { capitalizeText } from '@/utils/string'
import { ColumnSort, Pagination } from '@/types/utils'
import { useVendorContext } from '@/context'
import { useNavigate } from 'react-router-dom'
import FilterVendors from '@/components/Modals/Vendors/AdvancedFilter'
import { Customers } from '@/types/customers'
import DeleteCustomer from '@/components/Modals/Vendors/DeleteCustomer/DeleteCustomer'
import CustomersEmptyState from '@/assets/customerEmptyState.svg'
interface CustomerTableProps {
    customers: Customers[]
    pagination_data: Pagination
}

const CustomerListTable = ({ customers, pagination_data }: CustomerTableProps) => {
    const navigate = useNavigate()

    const columnHelper = createColumnHelper<Customers>()
    const [rowSelection, setRowSelection] = React.useState({})

    const [promptCustomer, setSelectedPromptCustomer] = useState<Customers>()
    const [filterCustomerModal, setFilterCustomerModal] = React.useState(false)
    const [promptModal, setPromptModal] = React.useState(false)

    const { setSelectedCustomer, setSort } = useVendorContext()
    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    //context api to sort the table
    const columns: ColumnDef<Customers, any>[] = [
        columnHelper.accessor('name', {
            header: () => {
                return <p className='whitespace-nowrap text-[95%] '>Customer name</p>
            },
            cell: cell => cell.renderValue(),
            enableSorting: true,
        }),
        columnHelper.accessor('email', {
            header: () => <p className='whitespace-nowrap text-[95%]'>Email Address</p>,
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>{cell.renderValue()}</p>
                </div>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('contact', {
            header: () => <p>Contact name</p>,
            cell: cell => {
                const contactName = cell.row.original.contact
                return (
                    <div>
                        <span className=''>{contactName.first_name}</span>{' '}
                        <span className=''>{contactName.last_name}</span>
                    </div>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('address', {
            header: () => <p>Address</p>,
            cell: cell => {
                const city = cell.row.original?.city

                return (
                    <>
                        <span className=''>
                            {capitalizeText(cell?.renderValue())}
                            {city.length > 0 && ','} {city}
                        </span>
                    </>
                )
            },
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div
                        className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'
                        onClick={() => setFilterCustomerModal(true)}
                    >
                        <img src={filter} alt='button' />
                    </div>
                </div>
            ),

            cell: cell => (
                <TableActions
                    actions={[
                        {
                            label: 'More details',
                            method: () => {
                                setSelectedCustomer(cell?.row?.original)
                                navigate(`${cell.row.original.id}`)
                            },
                        },
                        {
                            label: 'Delete Customer',
                            method: () => {
                                setSelectedCustomer(cell?.row?.original)
                                setSelectedPromptCustomer(cell?.row?.original)
                                setPromptModal(true)
                            },
                        },
                    ]}
                />
            ),
        }),
    ]
    const handleRowClick = (customer: Customers) => {
        setSelectedCustomer(customer)
        setSelectedPromptCustomer(customer)
        navigate(`${customer?.id}`)
    }

    return (
        <div>
            <Table
                data={customers}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                pagination_data={pagination_data}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                handleSort={handleSort}
                emptyStateImg={CustomersEmptyState}
                emptyStateTitle='No customers yet'
                emptyStateDescription='Create and manage customers you do business with'
            />

            {promptModal && (
                <DeleteCustomer customer={promptCustomer} closeModal={setPromptModal} fromDetails={false} />
            )}
            {filterCustomerModal && <FilterVendors closeModal={setFilterCustomerModal} />}
        </div>
    )
}

export default CustomerListTable
