import { ProfileEditIcon } from '@/assets/assets.export'
import { IsOwner } from '@/utils/permissions'
import { capitalizeText } from '@/utils/string'
import { complianceDetailsType } from '../utils'

type Props = {
    setEditComplianceDetails: React.Dispatch<React.SetStateAction<boolean>>
    compliance_details: complianceDetailsType
    setStep: React.Dispatch<React.SetStateAction<number>>
    status: string
}

type Array = []

export default function CompanyCompliancePage({
    setEditComplianceDetails,
    compliance_details,
    setStep,
    status,
}: Props) {
    return (
        <section className='flex-1 bg-white rounded-xl p-5 shadow-sm'>
            <div className='flex justify-between border-b border-gray5 pb-2 items-center'>
                <h3 className='font-medium text-md'>{compliance_details?.name}</h3>
                {IsOwner() && !(status === 'VERIFIED' || status === 'IN_PROGRESS') && (
                    <span
                        className='flex items-center gap-2 text-[#5E5E5E]  rounded-md cursor-pointer text-sm'
                        onClick={() => {
                            setEditComplianceDetails(true)
                            setStep(1)
                        }}
                    >
                        <ProfileEditIcon className='w-4 h-4' />
                        <button type='button'>Edit</button>
                    </span>
                )}
            </div>

            <div className=' mt-5'>
                <div className='flex flex-wrap gap-x-[4.5rem] gap-5 gap-y-7'>
                    {compliance_details?.deet.map(data => {
                        if (
                            data?.desc === 'null' ||
                            (status !== 'UNVERIFIED' && (data?.desc === 'undefined' || !data?.desc))
                        )
                            return
                        return (
                            <div className='flex flex-col flex-wrap    '>
                                <span className='text-[#838383] font-normal text-sm'>{data.name}</span>
                                <div className='flex gap-3 items-center mt-2 truncate'>
                                    <span className='font-medium text-[.9rem] truncate max-w-[12rem]'>
                                        {typeof data?.desc === 'string' ? (
                                            capitalizeText(data.desc)
                                        ) : (data?.desc as any)?.length ? (
                                            <>
                                                {(data?.desc as Array).map((officer, idx) => (
                                                    <div
                                                        className='px-2 mb-1 py-1 text-gray7 text-sm  flex flex-col gap-4 bg-offwhite rounded-lg'
                                                        key={idx}
                                                    >
                                                        <p className='truncate max-w-[12rem]'>
                                                            {(officer as any)?.email}{' '}
                                                        </p>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>{(data?.desc as any)?.main?.addressLine_1}</>
                                        )}
                                        {typeof data?.desc === 'number' && data?.desc}
                                        {/* {} */}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
