import { createMutation } from '../api/mutation'

export const useMutateFlagTransaction = createMutation({
    url: '/flag-transaction',
    keysToRefetch: [['/org-transaction'], ['/org-transaction', 'id']],
    method: 'POST',
})

export const useMutateRetryTransaction = createMutation({
    url: '/retry-transaction',
    keysToRefetch: [['/org-transaction'], ['/org-transaction', 'id']],
    method: 'POST',
})

export const useMutateRepeatTransaction = createMutation({
    url: '/repeat-transaction',
    keysToRefetch: [['/org-transaction'], ['/org-transaction', 'id']],
    method: 'POST',
})

export const useMutateAddTransactionNote = createMutation({
    url: '/add-transaction-note',
    keysToRefetch: [['/org-transaction'], ['/org-transaction', 'id']],
    method: 'POST',
})

export const useMutatateRemoveTransactionNote = createMutation({
    url: '/remove-transaction-note',
    keysToRefetch: [['/org-transaction'], ['/org-transaction', 'id']],
    method: 'POST',
})
