import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import InvoicePartial from './partials/Invoice/InvoicePartial'
import { motion } from 'framer-motion'
import InvoiceAnalytics from './partials/InvoiceAnalytics/InvoiceAnalytics'
import InvoiceItemPartial from './partials/InvoiceItems/InvoiceItems'
import InvoiceSettings from './partials/InvoiceSettings'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { invoiceSidebarPermissions } from '@/layout/LayoutComponents/utils'
import NotAuthorized from '@/layout/NotAuthorized'
import CurrencyFilter from '@/components/CurrencyFilter'
import Customers from '../Customers/Customers'

const invoice_routes_const = {
    list: 'invoice_list',
    analytics: 'invoice_analytics',
    items: 'items',
    settings: 'settings',
    customers: 'customers',
}
export default function Invoices() {
    const { invoice_subRoute } = useParams()
    const navigate = useNavigate()

    const showCurrencyFilter = location.pathname.includes(invoice_routes_const.analytics)

    const hasPermission = useHasAccessPermisson(invoiceSidebarPermissions)

    if (!hasPermission) return <NotAuthorized />
    return (
        <section className='h-full'>
            <section className='mt-7'>
                <Tabs defaultValue={invoice_subRoute} value={invoice_subRoute}>
                    <TabsList className='border-none'>
                        <div className='flex flex-col lg:flex-row justify-between mx-auto lg:items-center lg:w-[93%]'>
                            <TabTriggersContainer className='lg:w-full mx-0 ml-1'>
                                <TabsTrigger
                                    value={invoice_routes_const.list}
                                    onClick={() => navigate(`/dashboard/invoice/${invoice_routes_const.list}`)}
                                    className={`relative border-none ${
                                        invoice_routes_const.list === invoice_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {invoice_routes_const.list === invoice_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}

                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        Invoices
                                    </motion.span>
                                </TabsTrigger>
                                <TabsTrigger
                                    value={invoice_routes_const.analytics}
                                    onClick={() => navigate(`/dashboard/invoice/${invoice_routes_const.analytics}`)}
                                    className={`relative border-none ${
                                        invoice_routes_const.analytics === invoice_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {invoice_routes_const.analytics === invoice_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        Analytics
                                    </motion.span>{' '}
                                </TabsTrigger>
                                <TabsTrigger
                                    value={invoice_routes_const.items}
                                    onClick={() => navigate(`/dashboard/invoice/${invoice_routes_const.items}`)}
                                    className={`relative border-none ${
                                        invoice_routes_const.items === invoice_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {invoice_routes_const.items === invoice_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        Items
                                    </motion.span>{' '}
                                </TabsTrigger>
                                <TabsTrigger
                                    value={invoice_routes_const.customers}
                                    onClick={() => navigate(`/dashboard/invoice/${invoice_routes_const.customers}`)}
                                    className={`relative border-none ${
                                        invoice_routes_const.customers === invoice_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {invoice_routes_const.customers === invoice_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        Customers
                                    </motion.span>{' '}
                                </TabsTrigger>
                                <TabsTrigger
                                    value={invoice_routes_const.settings}
                                    onClick={() => navigate(`/dashboard/invoice/${invoice_routes_const.settings}`)}
                                    className={`relative border-none ${
                                        invoice_routes_const.settings === invoice_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {invoice_routes_const.settings === invoice_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        Settings
                                    </motion.span>{' '}
                                </TabsTrigger>
                            </TabTriggersContainer>
                            {showCurrencyFilter && <CurrencyFilter />}
                        </div>
                    </TabsList>
                    <TabsContent value={invoice_routes_const.list}>
                        <PageContent>
                            <InvoicePartial />
                        </PageContent>
                    </TabsContent>

                    <TabsContent value={invoice_routes_const.analytics}>
                        <PageContent>
                            <InvoiceAnalytics />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={invoice_routes_const.items}>
                        <PageContent>
                            <InvoiceItemPartial />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={invoice_routes_const.settings}>
                        <PageContent>
                            <InvoiceSettings />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={invoice_routes_const.customers}>
                        <Customers />
                    </TabsContent>
                </Tabs>
            </section>
        </section>
    )
}
