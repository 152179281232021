import {
    BudgetIcon,
    NigeriaFlag,
    SalariesUpcoming,
    TotalOutgoingIcon,
    WalletBalanceBg,
    WalletIcon,
} from '@/assets/assets.export'

import { ReactComponent as MoneyIcon } from '@/assets/money.svg'
import { ReactComponent as TransactionIcon } from '@/assets/new-transaction.svg'
import ShowBankDetails from '@/components/Modals/BankDetails/ViewBankDetails'
import FundWalletRoot from '@/components/Modals/FundWallet/FundWalletRoot'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { useAuthContext } from '@/context'
import useFeatureFlag from '@/lib/feature-flags/useFeatureFlag'
import { useGetOrgInsight, useGetUserInsight } from '@/services/auth/queries'
import { OverviewInsight } from '@/services/auth/types'
import { useGetOrgBudget } from '@/services/budget/queries'
import { useGetPayrollListForOrg } from '@/services/payroll/queries'
import { PayrollSingleList } from '@/services/payroll/types'
import { useGetorgVirtualAccount } from '@/services/virtual-account/queries'
import { isAdmin } from '@/utils/permissions'
import { capitalizeText } from '@/utils/string'
import { motion } from 'framer-motion'
import { AlertCircleIcon, ArrowUpRight, PlusSquare } from 'lucide-react'
import { useMemo, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { formatAmount } from '../../../../../utils/money'
import { cumulativeBalanceForUser, cumulativePayroll } from '../../utils'
import { AdminHelperSteps, UserHelperSteps } from '../HelperSteps'
import { QuickActions } from '../QuickActions'
import { QueryOrgBudgetType } from '@/services/budget/types'
import { WalletCard } from './WalletCard/WalletCard'

type Props = { isPriviledgedAdmin: boolean; isLoadingPrivilegedAdmin: boolean }
export default function KeyInsights({ isPriviledgedAdmin, isLoadingPrivilegedAdmin }: Props) {
    const { selectedAccount } = useAuthContext()
    const adminRoute = isAdmin(selectedAccount!)
    const [showModal, setShowModal] = useState(false)
    const [option, setOption] = useState('current' as string)
    const [showBankDetails, setShowbankDetails] = useState(false)

    // feature flag
    const walletFlagEnabled = useFeatureFlag('show_virtual_account')

    const { data, isLoading } = useGetOrgInsight({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })

    const orgInsightData = useMemo(() => data ?? ({} as OverviewInsight), [data])
    const { data: userInsight, isLoading: isLoadingUserData } = useGetUserInsight({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })
    const { data: orgVirtualAccount } = useGetorgVirtualAccount({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })
    const userInsightData = useMemo(() => userInsight ?? ({} as OverviewInsight), [userInsight])

    const { data: org_budget, isLoading: IsloadingBudgets } = useGetOrgBudget({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })
    const { data: payroll_list, isLoading: isLoadingPayroll } = useGetPayrollListForOrg({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })
    const orgBudget = useMemo(() => org_budget ?? [], [org_budget])
    const budgetBalance = cumulativeBalanceForUser(
        (orgBudget as QueryOrgBudgetType)?.budgets,
        selectedAccount?.user?.id as string
    )
    const upcomingSalary = cumulativePayroll(
        payroll_list?.list as PayrollSingleList[],
        selectedAccount?.user?.id as string
    )
    const isSkeleton =
        isLoading || isLoadingPayroll || isLoadingUserData || isLoadingPrivilegedAdmin || IsloadingBudgets

    return (
        <>
            {adminRoute && <AdminHelperSteps setShowModal={setShowModal} />}
            {!adminRoute && <UserHelperSteps />}
            <section
                className={`bg-white rounded-xl ${isPriviledgedAdmin ? 'p-1 py-3 md:p-3 lg:p-4' : 'p-1 py-3 md:p-3 lg:p-6'}`}
            >
                <h2 className='font-semibold text-lg text-dark mb-4'>Key insights</h2>
                {isSkeleton ? (
                    <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                        <Skeleton />
                    </SkeletonTheme>
                ) : (
                    <>
                        {!isPriviledgedAdmin ? (
                            <section className=''>
                                <div className=' text-sm flex-1'>
                                    <h5 className='text-gray7 '>This month’s metrics</h5>
                                    <div className='grid gap-4 grid-cols-2 md:grid-cols-4 mt-4'>
                                        <div className='flex items-center gap-3'>
                                            <span>
                                                <TransactionIcon className='w-11/12 h-11/12' />
                                            </span>
                                            <div>
                                                <h5 className='text-gray7'>My transactions</h5>
                                                <p className='font-medium text-lg lg:text-xl'>
                                                    {formatAmount(userInsightData?.transactions ?? 0)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-0.5'>
                                            <span>
                                                <MoneyIcon className='w-9/12 h-9/12' />
                                            </span>
                                            <div>
                                                <h5 className='text-gray7'>My expenses</h5>
                                                <p className='font-medium text-lg lg:text-xl'>
                                                    {formatAmount(userInsightData?.total_expenses)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <span>
                                                <BudgetIcon className='w-[2.4rem] h-[2.4rem] stroke-[#45B0DE] bg-[#45B0DE]/10 rounded-full p-2.5' />
                                            </span>
                                            <div>
                                                <h5 className='text-gray7'>Budget balance</h5>
                                                <p className='font-medium text-lg lg:text-xl'>
                                                    {formatAmount(budgetBalance)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <span>
                                                <SalariesUpcoming className='w-[90%] h-[90%]' />
                                            </span>
                                            <div>
                                                <h5 className='text-gray7'>My Upcoming salary</h5>
                                                <p className='font-medium text-lg lg:text-xl'>
                                                    {formatAmount(upcomingSalary)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='border-t border-gray5 mt-5 pt-5  w-full'>
                                        <div className='flex items-center gap-5'>
                                            <div>
                                                <h5 className='text-gray7'>Users</h5>
                                                <p className='text-xl font-medium mt-1'>
                                                    {orgInsightData?.no_of_employees}
                                                </p>
                                            </div>
                                            <div>
                                                <h5 className='text-gray7'>Teams</h5>
                                                <p className='text-xl font-medium mt-1'>{orgInsightData?.teams}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        ) : (
                            <>
                                <section className='flex flex-col md:flex-row gap-4 xl:gap-8'>
                                    <div className='relative md:max-w-xl text-white hidden'>
                                        <div className='h-full rounded-xl overflow-hidden xl:min-w-[33rem] '>
                                            {!WalletBalanceBg && (
                                                <span className='absolute block w-full h-full z-0 bg-[#3B3BAB] rounded-xl'></span>
                                            )}
                                            <WalletBalanceBg className='w-full h-full z-20 scale-110 md:scale-[2] xl:scale-110 rounded-xl overflow-hidden' />
                                            {/* <img
                                            src={WalletBalanceCard}
                                            alt='Illustrator for wallet balalnce background'
                                            className='w-full h-full z-20 scale-110 rounded-xl overflow-hidden'
                                        /> */}
                                        </div>
                                        <div className='absolute inset-0 m-5 xl:m-7 flex flex-col justify-between'>
                                            <div className='flex justify-between items-center'>
                                                <div className='flex rounded-lg'>
                                                    <TooltipProvider delayDuration={0}>
                                                        <Tooltip>
                                                            <TooltipTrigger className='mr-2 relative'>
                                                                <AlertCircleIcon size={15} />
                                                            </TooltipTrigger>

                                                            <TooltipContent className='max-w-[300px] bg-white text-gray7 shadow-2xl p-4'>
                                                                <h3 className='font-medium text-sm mb-2 text-dark'>
                                                                    {capitalizeText(option)} balance
                                                                </h3>
                                                                <p className='text-xs rounded leading-6'>
                                                                    {option === 'current'
                                                                        ? 'Your current balance is the total amount of funds in your wallet at this moment. It includes all recent deposits, transactions, and any pending amounts that have not yet been processed.'
                                                                        : 'The available balance is the portion of your current balance that is available for you to use. It differs from the current balance as it excludes funds that are pending clearance. For instance, when you fund your wallet, these funds will appear in your current balance instantly, but they will only be included in your available balance the next day at 10 AM. This delay is to ensure all transactions are processed and cleared appropriately.'}
                                                                </p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                    <div className='flex sm:gap-2 w-[8rem] sm:w-[10.7rem] rounded-lg text-xs sm:text-base'>
                                                        <div
                                                            className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#D5D5F5] px-2 py-1 rounded cursor-pointer relative w-full`}
                                                            onClick={() => setOption('current')}
                                                        >
                                                            {option == 'current' && (
                                                                <motion.span
                                                                    layoutId='expense_timeline__animation'
                                                                    className='bg-gradient-to-bl from-[#EFEFF966] to-[#E2E3FF3B] rounded-md z-0 text-center text-[#F9F9F9] inset-0 absolute inline-block w-full h-full '
                                                                />
                                                            )}
                                                            <motion.span layout='position' className='z-20 sticky'>
                                                                Current
                                                            </motion.span>
                                                        </div>
                                                        <div
                                                            id='available_balance'
                                                            className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#D5D5F5] px-2 py-1 rounded cursor-pointer relative w-full`}
                                                            onClick={() => setOption('available')}
                                                        >
                                                            {option == 'available' && (
                                                                <motion.span
                                                                    layoutId='expense_timeline__animation'
                                                                    className='bg-gradient-to-bl from-[#EFEFF966] to-[#E2E3FF3B] rounded-md text-[#F9F9F9] inset-0 absolute inline-block w-full h-full '
                                                                />
                                                            )}
                                                            <motion.span
                                                                layout='position'
                                                                className={`inset-0 absolute inline-block w-full h-full px-2 py-1`}
                                                            >
                                                                Available
                                                            </motion.span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className='w-14 h-8'>
                                                    <NigeriaFlag className='w-full' />
                                                </button>
                                            </div>
                                            <div className='flex items-center gap-3 md:gap-5'>
                                                <span className='bg-black rounded-full p-3'>
                                                    <WalletIcon className='w-4 h-4 md:w-8 md:h-8' />
                                                </span>
                                                <div>
                                                    <h4 className='text-gray5 font-medium text-xs md:text-base'>
                                                        Wallet balance
                                                    </h4>

                                                    <p
                                                        id='wallet_balance'
                                                        className='text-xl lg:text-2xl xl:text-3xl font-bold md:mt-2'
                                                    >
                                                        {isLoading ? (
                                                            <SkeletonTheme
                                                                baseColor='transparent'
                                                                highlightColor='#DADCE0'
                                                            >
                                                                <Skeleton />
                                                            </SkeletonTheme>
                                                        ) : option === 'current' ? (
                                                            formatAmount(orgInsightData?.current_balance)
                                                        ) : (
                                                            formatAmount(orgInsightData?.available_balance)
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='flex text-xs md:text-sm gap-8 items-end justify-between'>
                                                {
                                                    <button
                                                        onClick={() => setShowModal(true)}
                                                        className='px-9 md:px-10 py-2 md:py-3 xl:py-4 rounded-md md:rounded-lg flex items-center justify-center border border-white'
                                                    >
                                                        <PlusSquare className='mr-2 w-4 h-4 md:w-5 md:h-5' />
                                                        Fund wallet
                                                    </button>
                                                }
                                                <button className='hidden py-4 rounded-lg text-white bg-black flex items-center justify-center '>
                                                    <ArrowUpRight className='mr-2' size={20} />
                                                    Transfer
                                                </button>
                                                {walletFlagEnabled && (
                                                    <button
                                                        className='text-right font-medium text-xs xl:text-sm'
                                                        onClick={() => setShowbankDetails(true)}
                                                    >
                                                        View bank details
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='md:border-l border-gray5 pl-2 lg:pl-6 text-xs flex-1 hidden'>
                                        <h5 className='text-gray7 '>This month’s metrics</h5>
                                        <div className='grid grid-cols-2 mt-4'>
                                            <div className='flex flex-col gap-4'>
                                                <div className='flex items-center gap-3'>
                                                    <span>
                                                        <TransactionIcon className='w-8 h-8 sm:w-10 sm:h-10' />
                                                    </span>
                                                    <div>
                                                        <h5 className='text-gray7'>Total incoming</h5>
                                                        <p className='font-medium text-base sm:text-lg lg:text-xl'>
                                                            {orgInsightData?.transactions ?? 0}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center gap-3'>
                                                    <span>
                                                        <MoneyIcon className='w-8 h-8 sm:w-10 sm:h-10' />
                                                    </span>
                                                    <div>
                                                        <h5 className='text-gray7'>Total expense</h5>
                                                        <p className='font-medium text-base sm:text-lg lg:text-xl'>
                                                            {formatAmount(orgInsightData?.total_expenses)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center gap-3'>
                                                    <span>
                                                        <TotalOutgoingIcon className='w-8 h-8 sm:w-10 sm:h-10' />
                                                    </span>
                                                    <div>
                                                        <h5 className='text-gray7'>Total outgoing</h5>
                                                        <p className='font-medium text-base sm:text-lg lg:text-xl'>
                                                            {orgInsightData?.transactions ?? 0}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className='flex items-center gap-3'>
                                                <span>
                                                    <SalariesUpcoming className='w-8 h-8 sm:w-10 sm:h-10' />
                                                </span>
                                                <div>
                                                    <h5 className='text-gray7'>Upcoming salaries</h5>
                                                    <p className='font-medium text-base sm:text-lg lg:text-xl'>N/A</p>
                                                </div>
                                            </div> */}
                                            </div>
                                            <div className='flex flex-col gap-4'>
                                                {/* <div className='flex items-center gap-3'>
                                                <span>
                                                    <TotalOutgoingIcon className='w-8 h-8 sm:w-10 sm:h-10' />
                                                </span>
                                                <div>
                                                    <h5 className='text-gray7'>Total outgoing</h5>
                                                    <p className='font-medium text-base sm:text-lg lg:text-xl'>
                                                        {orgInsightData?.transactions}
                                                    </p>
                                                </div>
                                            </div> */}
                                                <div className='flex items-center gap-3'>
                                                    <span>
                                                        <BudgetIcon className='w-8 h-8 sm:w-10 sm:h-10 stroke-[#45B0DE] bg-[#45B0DE]/10 rounded-full p-[7px]' />
                                                    </span>
                                                    <div>
                                                        <h5 className='text-gray7'>Budget balance</h5>
                                                        <p className='font-medium text-base sm:text-lg lg:text-xl'>
                                                            N/A
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className='flex items-center gap-3'>
                                                <span>
                                                    <BillPaymentsIcon className='w-8 h-8 sm:w-10 sm:h-10' />
                                                </span>
                                                <div>
                                                    <h5 className='text-gray7'>Bill payments</h5>
                                                    <p className='font-medium text-base sm:text-lg lg:text-xl'>
                                                        {formatAmount(orgInsightData?.total_expenses)}
                                                    </p>
                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                        <div className='border-t border-gray5 mt-5 pt-5  w-full'>
                                            <div className='flex items-center gap-5'>
                                                <div>
                                                    <h5 className='text-gray7'>Users</h5>
                                                    <p className='text-xl font-medium mt-1'>
                                                        {orgInsightData?.no_of_employees}
                                                    </p>
                                                </div>
                                                <div>
                                                    <h5 className='text-gray7'>Teams</h5>
                                                    <p className='text-xl font-medium mt-1'>{orgInsightData?.teams}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <WalletCard orgInsightData={orgInsightData} />
                                    <QuickActions />
                                </section>
                            </>
                        )}
                    </>
                )}
                {showModal && <FundWalletRoot setShowModal={setShowModal} />}
                {showBankDetails && (
                    <ShowBankDetails closeModal={setShowbankDetails} bankDetails={orgVirtualAccount?.[0]} />
                )}
            </section>
        </>
    )
}

/*



*/
