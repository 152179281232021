import notFound from '@/assets/not-found.svg'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CiSearch } from 'react-icons/ci'

import { useGetorgContractPayrollHistory } from '@/services/payroll/queries'
import { useAuthContext } from '@/context'
import ProgressBar from '@/components/ProgressBar'
import { OrgContractPayHistory } from '@/types/payroll'
import OrgContractTable from '../../Contract/partials/ContractPayroll/components/OrgContractTable'
import { debounce } from 'lodash'

const UserContractPayHistory = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const { selectedAccount } = useAuthContext()
    // const navigate = useNavigate()

    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)
    const {
        data: contract_payhistory,
        isLoading,
        isRefetching,
        refetch,
    } = useGetorgContractPayrollHistory({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            owner: selectedAccount?.id as string,
            search: searchQuery,
        },
        onSuccess: () => {
            setBackSpace(false)
        },
    })
    const contractPayHistory = useMemo(
        () => contract_payhistory ?? ({} as OrgContractPayHistory),
        [contract_payhistory]
    )

    const debouncedSearch = useRef(
        debounce(() => {
            refetch()
        }, 300)
    ).current

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }
    useEffect(() => {
        if (backspaceClicked) {
            refetch()
            setBackSpaceClicked(false)
        }

        if (searchQuery) {
            debouncedSearch()
        }
        // console.log('here')
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, debouncedSearch, refetch, searchQuery])

    if (isLoading) return <ProgressBar />
    return (
        <div className='w-full py-6 block'>
            <div className='flex items-center justify-between mb-6'>
                {
                    <div className='border border-[#DADCE0] h-[56px] w-[60%] md:w-[35%] rounded-lg flex items-center overflow-hidden '>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='Search by title'
                            className='h-full w-full outline-none'
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                }
                <div className=''>&nbsp;</div>
            </div>

            {contractPayHistory?.history?.length === 0 && isRefetching ? (
                <ProgressBar />
            ) : (contractPayHistory?.history?.length === 0 && searchQuery?.length < 1 && !backspace) ||
              (contractPayHistory?.history &&
                  contractPayHistory?.history?.length > 0 &&
                  searchQuery?.length >= 0 &&
                  !backspace) ? (
                <section
                    className={` transition-all rounded-lg ${isRefetching ? 'opacity-30' : 'opacity-100'} overflow-x-scroll`}
                >
                    <OrgContractTable contractHistory={contractPayHistory} />
                </section>
            ) : (
                <div className='h-[300px] flex flex-col text-center justify-center items-center'>
                    {searchQuery && contractPayHistory?.history?.length === 0 ? (
                        <>
                            <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                            <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                            <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                "Oops! It seems we couldn't find any matching results for your search. Please try again
                                with different keywords or refine your filter criteria for better results.
                            </span>
                        </>
                    ) : (
                        <ProgressBar />
                    )}
                </div>
            )}
        </div>
    )
}

export default UserContractPayHistory
