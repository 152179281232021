import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App.tsx'
import './index.css'
import { queryClient } from './queryClient.ts'
import { isDevelopmentEnvironment } from './utils/utils.ts'

Sentry.init({
    dsn: 'https://79bebb67d702fe9b7241c10cdfed3ee0@o4506303248269312.ingest.us.sentry.io/4507206391693312',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/(?:[a-z0-9-]+\.)?bizpend\.com(?:\/api)?/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    enabled: !isDevelopmentEnvironment(),
    environment: import.meta.env.VITE_APP_ENV,
})

posthog.init(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
    ui_host: 'https://eu.i.posthog.com',
    on_request_error: (err: any) => {
        console.error('POSTHOG ERROR:', err)
    },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <PostHogProvider client={posthog}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </BrowserRouter>
        </PostHogProvider>
    </React.StrictMode>
)
