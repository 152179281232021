import { toastHandler } from '@/components/utils/Toast'

export function prodEnvironment() {
    return import.meta.env.VITE_APP_ENV === 'production'
}

export function isStagingEnvironment() {
    return import.meta.env.VITE_APP_ENV === 'staging'
}

export function isDevelopmentEnvironment() {
    return import.meta.env.VITE_APP_ENV === 'development'
}

export const handleCopyClick = async (text?: string) => {
    try {
        await navigator.clipboard.writeText(text ?? 'Nothing to copy')
        toastHandler({ message: 'Copied to clipboard!', state: 'success' })
    } catch (err) {
        toastHandler({ message: 'Copy to clipboard failed.', state: 'error' })
    }
}

export const downloadFile = async (fileUrl: string, fileName?: string): Promise<void> => {
    try {
        const response = await fetch(fileUrl)
        const blob = await response.blob()

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', fileName || 'downloaded-file')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(link.href)
    } catch (error) {
        console.error('Error downloading file:', error)
    }
}
