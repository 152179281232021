import React, { FormEvent, useState } from 'react'
import { CalendarIcon, CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../../ModalWrapper'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { capitalizeText } from '@/utils/string'
import { Button } from '@/components'
import { FilterInvoiceUtils, modalVariants } from './utils'
import { useInvoiceContext } from '@/context'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { advancedFilteringProps } from '@/context/InvoiceReminder'
import { isAfter } from 'date-fns'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function FilterInvoices({ closeModal }: Props) {
    const { setFilterInvoices: setFilterFormState, filterInvoices: filterFormState } = useInvoiceContext()
    const [errInDate, setErrInDate] = useState<string | null>(null)

    const checkIfDateIsPastToday = (date: Date, type: string) => {
        const result = isAfter(new Date(date), new Date())
        if (result) {
            setErrInDate('Date cannot be past today')
            return
        }
        setFilterFormState(prev => ({
            ...prev,
            period: { ...prev.period, [type]: date },
        }))
        setErrInDate('')
    }

    const handleFilterForm = (event: FormEvent<HTMLFormElement | HTMLSelectElement | HTMLInputElement>) => {
        event.preventDefault()
        setFilterFormState(prev => ({ ...prev, filter_now: true }))
        closeModal(false)
    }

    const handleClearFilter = () => {
        setFilterFormState(() => ({ ...advancedFilteringProps, filter_now: true }))
        closeModal(false)
    }

    return (
        <ModalWrapper
            className='justify-end right-8 rounded-none z-50'
            variants={modalVariants}
            modalClassName='rounded-none'
        >
            <main className='h-[100vh] overflow-scroll w-[25rem] relative'>
                <div className='flex items-center  border-b border-[#DADCE0] py-2 pb-3 fixed bg-[white] w-full z-50'>
                    <h1 className='flex-1 text-xl font-medium text-center text-[#31254B]'>Advanced filter</h1>
                    <CancelIcon
                        className='bg-[#F2F2F2] rounded-full p-2 w-[2rem] h-[2rem] mr-4 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>
                <form className='mx-6 mt-14 h-[92vh] flex flex-col justify-between' onSubmit={handleFilterForm}>
                    <div className=''>
                        <div className='border-b border-[#DADCE0] py-2 pb-10'>
                            <p className='text-[#838383] text-lg mb-7'>Filter by:</p>
                            <span className='text-lg font-medium mb-4 inline-block'>Status</span>
                            <RadioGroup
                                onValueChange={status => setFilterFormState(prev => ({ ...prev, status }))}
                                defaultValue={filterFormState.status}
                                className='grid grid-cols-3 gap-4'
                            >
                                {FilterInvoiceUtils.status_types.map(types => (
                                    <label
                                        htmlFor={types.name}
                                        className='flex gap-2 items-center text-[#5E5E5E] font-medium text-sm cursor-pointer'
                                        key={types.name}
                                    >
                                        <RadioGroupItem value={types.value} id={types.name} />
                                        {capitalizeText(types.name)}
                                    </label>
                                ))}
                            </RadioGroup>
                        </div>
                        <div className='mt-7'>
                            <span className='text-lg font-medium mb-4 inline-block'>Period</span>
                            <div className='mb-5'>
                                <div className='flex gap-5 '>
                                    <label className='relative cursor-pointer flex items-center ' htmlFor='from_date '>
                                        <DatePicker
                                            selected={filterFormState?.period?.from}
                                            onChange={date => checkIfDateIsPastToday(date!, 'from')}
                                            placeholderText='From'
                                            className='border-[#DADCE0]  placeholder:text-[#A8A8A8] h-[3rem] text-dark rounded-md border focus:border-brand bg-transparent py-2 px-3 text-sm  cursor-pointer w-[9.3rem] '
                                        />
                                        <CalendarIcon className='absolute right-3 -z-20' />
                                    </label>
                                    <hr className='w-[60px] relative top-[1.5rem] text-[#A8A8A8] bg-[#A8A8A8]' />
                                    <label className='relative cursor-pointer flex items-center ' htmlFor='from_date '>
                                        <DatePicker
                                            selected={filterFormState?.period?.to}
                                            onChange={date => checkIfDateIsPastToday(date!, 'to')}
                                            placeholderText='To'
                                            className='border-[#DADCE0]  placeholder:text-[#A8A8A8] h-[3rem] text-dark rounded-md border focus:border-brand bg-transparent py-2 px-3 text-sm  cursor-pointer w-[9.3rem]'
                                        />
                                        <CalendarIcon className='absolute right-3 -z-20' />
                                    </label>
                                </div>
                                {!!errInDate && <span className='text-[red] text-xs mt-1 block'> {errInDate}</span>}
                            </div>
                            <RadioGroup
                                onValueChange={period =>
                                    setFilterFormState(prev => ({
                                        ...prev,
                                        period: { ...prev.period, time_in_words: period },
                                    }))
                                }
                                value={filterFormState?.period?.time_in_words}
                            >
                                {FilterInvoiceUtils.periods.map(types => (
                                    <label
                                        htmlFor={types.type}
                                        key={types.type}
                                        className={`flex gap-5 items-center text-[#5E5E5E] font-medium text-sm ${
                                            !!filterFormState.period?.from || !!filterFormState.period?.to
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        }`}
                                    >
                                        <RadioGroupItem
                                            value={types.getDate()}
                                            id={types.type}
                                            disabled={!!filterFormState.period?.from || !!filterFormState.period?.to}
                                        />
                                        {capitalizeText(types.period)}
                                    </label>
                                ))}
                            </RadioGroup>
                        </div>
                    </div>

                    <div className='flex gap-3 justify-end mt-10 mb-5'>
                        <Button
                            className='bg-transparent text-[#5E5E5E] hover:bg-transparent'
                            type='button'
                            onClick={handleClearFilter}
                        >
                            Clear filters
                        </Button>
                        <Button className='min-w-[130px]'>Apply filters</Button>
                    </div>
                </form>
            </main>
        </ModalWrapper>
    )
}
