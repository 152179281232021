import { createMutation } from '../api/mutation'
import { keyUser } from '../auth/queries'
import { orgCustomersKey } from './queries'
import { Customers } from '@/types/customers'

const key = ['/org-customers']

export const useMutationCreateCustomer = createMutation({
    url: '/customer',
    keysToRefetch: [orgCustomersKey, keyUser],
    method: 'POST',
})

export const useMutationDeleteCustomer = createMutation({
    url: '/customer',
    keysToRefetch: [orgCustomersKey],
    method: 'DELETE',
})

export const useMutationUpdateCustomer = createMutation<Customers>({
    url: '/customer',
    keysToRefetch: [orgCustomersKey],
    method: 'PATCH',
})

export const useMutationSuspendCustomer = createMutation<Customers>({
    url: '/suspend-customer',
    keysToRefetch: [orgCustomersKey, key],
    method: 'POST',
})

export const useMutationActivateCustomer = createMutation<Customers>({
    url: '/activate-customer',
    keysToRefetch: [orgCustomersKey, key],
    method: 'POST',
})
