import LinkedTransactionsTable from '../components/LinkedTransactionsTable'

export default function LinkedTransactions() {
    return (
        <section className='bg-white rounded-xl mx-[1.2rem] lg:mx-[2.9rem] pt-2 pb-6 mb-[5rem] px-7'>
            {/* <div>
                <span className='text-[#838383] text-sm'>Total spend</span>
                <span className='block font-medium'>₦ 12,000,029.00</span>
            </div> */}
            <h4 className='mt-10 border-b border-[#DADCE0] pb-4 font-medium'>Linked transaction(s)</h4>
            <div className='mb-32 overflow-y-scroll'>
                <LinkedTransactionsTable />
            </div>
        </section>
    )
}
