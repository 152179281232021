import PaystackCheckout from '@/assets/Paystack_Logo 1.svg'
import { Button } from '@/components'
import CheckoutPaymentModal from '@/components/Modals/Invoice/CheckoutPaymentModal'
import { useInitiateInvoiceCheckout } from '@/services/invoice/mutations'
import { Invoice } from '@/types/invoice'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export default function OnlineInvoiceCheckout({ invoiceDetails }: { invoiceDetails?: Invoice }) {
    const [openModal, setOpenModal] = useState(false)
    const [amount, setAmount] = useState(
        Number(Number(invoiceDetails?.total_amount) - Number(invoiceDetails?.paid)).toString() || ''
    )

    const { invoice_id } = useParams()

    const { mutate: initiateInvoiceCheckout, isLoading: initiateInvoiceCheckoutLoading } = useInitiateInvoiceCheckout({
        onSuccess: (data: any) => {
            window.location.href = data.checkout_url
        },
    })

    const handlePayment = async () => {
        initiateInvoiceCheckout({
            invoice_id: invoice_id as string,
            amount: Number(amount) / 100,
        })
    }

    return (
        <div className='border border-[#DADCE0] grid place-items-center p-4 rounded-2xl mt-4 text-sm md:text-base'>
            <img src={PaystackCheckout} alt='paystack icon' className='w-[6rem] md:w-[10rem]' />

            <p className='text-[#5E5E5E] my-4'> You will be redirected to paystack to make this payment.</p>
            <Button
                className='bg-[#19C37D] hover:bg-[#19C37D]/80 min-w-[120px] md:min-w-[165px] min-h-[40px] md:min-h-[50px]'
                onClick={() => {
                    setOpenModal(true)
                }}
                disabled={invoiceDetails?.status === 'PAID'}
            >
                {invoiceDetails?.status === 'PAID' ? 'Invoice has alredy been paid' : `Make payment now`}{' '}
            </Button>

            {openModal && (
                <CheckoutPaymentModal
                    loading={initiateInvoiceCheckoutLoading}
                    handleBtnClick={() => handlePayment()}
                    invoiceDetails={invoiceDetails}
                    closeModal={() => setOpenModal(false)}
                    amount={amount}
                    setAmount={setAmount}
                />
            )}
        </div>
    )
}
