import { useAuthContext } from '@/context'
import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const RedirectHandler = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { loggedIn, setRedirectURL } = useAuthContext()

    const params = new URLSearchParams(location.search)
    const redirectUrl = params.get('to') || '/'

    useEffect(() => {
        setRedirectURL(redirectUrl)
        if (!loggedIn) {
            navigate('/login', { state: { redirect: redirectUrl }, replace: true })
        } else {
            navigate(redirectUrl, { replace: true })
        }
    }, [loggedIn, navigate, location.search])

    return null
}

export default RedirectHandler
