import { Vendor } from '@/types/vendor'
import { createQuery } from '../api/query'
import { QueryOrgVendors } from './types'

export const orgVendorsKey = ['/org-vendors']
export const orgVendorKey = (arg: string) => ['/org-vendor', arg]

export const useGetOrgVendors = createQuery<QueryOrgVendors>({
    url: '/vendors',
    key: orgVendorsKey,
})

export const useGetOrgVendor = createQuery<Vendor>({
    url: '/vendor',
    key: orgVendorKey,
})
