import React, { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { OverallKYC, PersonalDetailsFormValues } from './KYCInfoContainer'
import { ArrowLeft } from 'lucide-react'
import { useAuthContext } from '@/context'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import DatePicker from 'react-datepicker'
import { useMutateKYC } from '@/services/auth/mutations'

export type KYCModalProps = {
    kycInfoStep: number
    setKycInfoStep: React.Dispatch<React.SetStateAction<number>>
    overallKYCForrmDetails: OverallKYC
    setOverallKYCForrmDetails: React.Dispatch<React.SetStateAction<OverallKYC>>
}

export default function PersonalInfoKYCModal({
    setKycInfoStep,
    overallKYCForrmDetails,
    setOverallKYCForrmDetails,
}: KYCModalProps) {
    const { selectedAccount } = useAuthContext()
    const dateOfBirth = useRef<DatePicker>(null)
    const {
        handleSubmit,
        formState: { isValid },
        control,
    } = useForm<PersonalDetailsFormValues>()

    const { mutate, isLoading } = useMutateKYC({
        queryParams: { org_account_id: selectedAccount?.id as string },
        onSuccess: () => {
            setKycInfoStep(2)
        },
    })

    const submitPersonalFormDetails = (values: PersonalDetailsFormValues) => {
        const formdata = new FormData()
        formdata.append('date_of_birth', overallKYCForrmDetails?.date_of_birth as string)
        formdata.append('phone_number', overallKYCForrmDetails?.phone_number as string)
        formdata.append('country_code', '+234')
        formdata.append('street', overallKYCForrmDetails?.address)
        formdata.append('city', overallKYCForrmDetails?.city)
        formdata.append('state', overallKYCForrmDetails?.state)
        formdata.append('country', overallKYCForrmDetails?.country)
        formdata.append('postal_code', overallKYCForrmDetails?.postal_code)
        formdata.append('address', overallKYCForrmDetails?.address)
        formdata.append('place_of_birth', overallKYCForrmDetails?.place_of_birth)
        formdata.append('gender', overallKYCForrmDetails?.gender)
        formdata.append('bvn', overallKYCForrmDetails?.bvn)

        mutate(formdata)
        setOverallKYCForrmDetails(prev => ({ ...prev, ...values }))
    }
    return (
        <form onSubmit={handleSubmit(submitPersonalFormDetails)}>
            <div className='max-h-[59vh] overflow-scroll'>
                <section className='flex mt-6 gap-4 lg:gap-10'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='firstName' className='text-[#202020] text-[15px] font-medium'>
                            First name <span className='text-[red]'>*</span>
                        </label>

                        <Input
                            placeholder='Enter your first name'
                            className='bg-[#f8f8f8] text-[#A8A8A8] border-[#DADCE0] placeholder:text-[#A8A8A8]/90 h-[3rem]'
                            value={selectedAccount?.user?.first_name}
                            disabled
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='firstName' className='text-[#202020] text-[15px] font-medium'>
                            Last name <span className='text-[red]'>*</span>
                        </label>

                        <Input
                            placeholder='Enter your last name'
                            className='bg-[#f8f8f8] text-[#A8A8A8] border-[#DADCE0] placeholder:text-[#A8A8A8]/90 h-[3rem]'
                            value={selectedAccount?.user?.last_name}
                            disabled
                        />
                    </div>
                </section>
                <section className='flex mt-4 lg:mt-6 gap-4 lg:gap-10'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[15px] font-medium'>
                            Email address
                        </label>

                        <Input
                            placeholder='Enter your email address'
                            type='email'
                            className='bg-[#f8f8f8] text-[#A8A8A8] border-[#DADCE0] h-[3rem]'
                            value={selectedAccount?.user?.email}
                            disabled
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='firstName' className='text-[#202020] text-[15px] font-medium'>
                            Phone Number <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='phone_number'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            defaultValue={overallKYCForrmDetails?.phone_number}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder='Please enter a valid phone number '
                                    className='border-[#DADCE0] placeholder:text-[#A8A8A8]/90 h-[3rem]'
                                />
                            )}
                        />
                    </div>
                </section>
                <section className='flex flex-col flex-1 gap-3 mt-4 lg:mt-6'>
                    <label htmlFor='address' className='text-[#202020] text-[15px] font-medium'>
                        Address line <span className='text-[red]'>*</span>
                    </label>
                    <Controller
                        name='address'
                        rules={{
                            required: true,
                            minLength: 2,
                        }}
                        defaultValue={overallKYCForrmDetails?.address}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder='Kindly enter your residential address'
                                className='border-[#DADCE0] placeholder:text-[#A8A8A8]/90 h-[3rem]'
                            />
                        )}
                    />
                </section>
                <section className='flex mt-4 lg:mt-6 gap-4 lg:gap-10'>
                    <div className='flex flex-col flex-1 gap-3 '>
                        <label htmlFor='address' className='text-[#202020] text-[15px] font-medium'>
                            City <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='city'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            defaultValue={overallKYCForrmDetails?.city}
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    className='flex w-full h-[3rem] text-dark rounded-md border border-gray1/40 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed '
                                    placeholder='City '
                                />
                            )}
                        />
                    </div>

                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='address' className='text-[#202020] text-[15px] font-medium'>
                            State <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='state'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            defaultValue={overallKYCForrmDetails?.state}
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    className='flex w-full h-[3rem] text-dark rounded-md border border-gray1/40 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed'
                                    placeholder='Please enter your State '
                                />
                            )}
                        />
                    </div>
                </section>
                <section className='mt-4 lg:mt-6 flex gap-4 lg:gap-10'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='address' className='text-[#202020] text-[15px] font-medium'>
                            Country <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='country'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            defaultValue={overallKYCForrmDetails?.country}
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    className='flex w-full h-[3rem] text-dark rounded-md border border-gray1/40 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed'
                                    placeholder='Country '
                                />
                            )}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='address' className='text-[#202020] text-[15px] font-medium'>
                            Postal code <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='postal_code'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            defaultValue={overallKYCForrmDetails?.postal_code}
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    className='flex w-full h-[3rem] text-dark rounded-md border border-gray1/40 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed'
                                    placeholder='Postal Code '
                                />
                            )}
                        />
                    </div>
                </section>
                <section className='mt-4 lg:mt-6 flex gap-4 lg:gap-10'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='address' className='text-[#202020] text-[15px] font-medium '>
                            Date of Birth <span className='text-[red]'>*</span>
                        </label>
                        <CustomDatePicker
                            selected={new Date(overallKYCForrmDetails?.date_of_birth as string) ?? ''}
                            onChange={date =>
                                setOverallKYCForrmDetails(prev => ({
                                    ...prev,
                                    date_of_birth: date! as unknown as string,
                                }))
                            }
                            dateFormatCalendar='yyyy'
                            maxDate={new Date()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            placeholderText='type DOB here'
                            ref={dateOfBirth}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='address' className='text-[#202020] text-[15px] font-medium '>
                            Gender <span className='text-[red]'>*</span>
                        </label>
                        <Select
                            name='gender'
                            onValueChange={gender => setOverallKYCForrmDetails(prev => ({ ...prev, gender }))}
                            defaultValue={overallKYCForrmDetails?.gender}
                        >
                            <SelectTrigger className='w-full h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                <SelectValue placeholder={'Your Gender'} />
                            </SelectTrigger>
                            <SelectContent className='w-[19.5rem]'>
                                <SelectItem value='Female'>Male</SelectItem>
                                <SelectItem value='Male'>Female</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </section>
                <section className='mt-4 lg:mt-6 flex gap-4 lg:gap-10'>
                    <div className='flex flex-col flex-1 gap-3 max-w-[19.7rem]'>
                        <label htmlFor='firstName' className='text-[#202020] text-[15px] font-medium'>
                            Place Of Birth <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='place_of_birth'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            defaultValue={overallKYCForrmDetails?.place_of_birth}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder='Please enter your place of birth '
                                    className='border-[#DADCE0] placeholder:text-[#A8A8A8]/90 h-[3rem]'
                                />
                            )}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3 max-w-[19.7rem]'>
                        <label htmlFor='firstName' className='text-[#202020] text-[15px] font-medium'>
                            BVN <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='bvn'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            defaultValue={overallKYCForrmDetails?.bvn}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder='BVN'
                                    className='border-[#DADCE0] placeholder:text-[#A8A8A8]/90 h-[3rem]'
                                />
                            )}
                        />
                    </div>
                </section>
            </div>
            <div className='mx-[0rem] mt-12 flex justify-end'>
                <button
                    type='button'
                    className='  mr-5 flex gap-1 active:scale-95 px-4 min-h-[50px] items-center rounded-md text-sm font-medium transition-colors focus:ring-2 focus:ring-slate-400 focus:ring-offset-2'
                >
                    <ArrowLeft /> go back
                </button>

                <Button className='' type='submit' disabled={!isValid} loading={isLoading}>
                    Next
                </Button>
            </div>
        </form>
    )
}
