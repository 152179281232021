export const highestMonth = (data: { name: string; total: number }[]) => {
    let highestTotal = -Infinity
    let highestMonthData = {
        name: '',
        total: 0,
    }
    data.forEach(monthData => {
        const outgoingTotal = monthData.total
        if (outgoingTotal > highestTotal) {
            highestTotal = outgoingTotal
            highestMonthData = monthData
        }
    })

    return highestMonthData
}

export const lowestMonth = (data: { name: string; total: number }[]) => {
    let lowestTotal = Infinity
    let highestMonthData = {
        name: '',
        total: 0,
    }
    data.forEach(monthData => {
        const outgoingTotal = monthData.total
        if (outgoingTotal < lowestTotal) {
            lowestTotal = outgoingTotal
            highestMonthData = monthData
        }
    })

    return highestMonthData
}

