// import CheckBox from '@/components/Table/CheckBox'
import InvoiceEmptyState from '@/assets/invoice-empty-state.svg'
import InvoiceReminder from '@/components/Modals/Invoice/InvoiceReminder'
import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import StatusColor from '@/components/utils/StatusColor'
import { useInvoiceContext } from '@/context'
import { Invoice } from '@/types/invoice'
import { ColumnSort } from '@/types/utils'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { format, formatDistance } from 'date-fns'
import { DownloadIcon, MoreHorizontal } from 'lucide-react'
import React, { useState } from 'react'
import { BsArrowCounterclockwise } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { formatAmount } from '../../../../utils/money'

type InvoiceTableProps = {
    data: Invoice[]
}
const InvoiceTable = ({ data }: InvoiceTableProps) => {
    const columnHelper = createColumnHelper<Invoice>()
    const { setSort, setPageNumber, pageNumber, setPageNumberClicked, pagination_details } = useInvoiceContext()
    const [resendInvoice, setResendInvoice] = useState(false)
    const [invoiceDetails, setInvoiceDetails] = useState<Invoice>()
    const navigate = useNavigate()

    const [rowSelection, setRowSelection] = React.useState({})

    const { invoice_subRoute } = useParams()
    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    const columns: ColumnDef<Invoice, any>[] = [
        columnHelper.accessor('invoice_id', {
            header: () => <p>Invoice ID</p>,
            cell: cell => <span>{cell.renderValue()}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('customer.name', {
            header: () => <>Customer</>,
            cell: cell => cell.renderValue(),
            enableSorting: true,
        }),
        columnHelper.accessor('issue_date', {
            header: () => <p>Issue Date</p>,
            cell: cell => {
                return (
                    <div className=''>
                        <p className='mt-1 text-[#5E5E5E]'>{format(new Date(cell.renderValue()), 'dd/MM/yyyy')} </p>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('due_date', {
            header: () => <p>Due date</p>,
            cell: cell => {
                return (
                    <div>
                        <span>{format(new Date(cell.renderValue()), 'dd/MM/yyyy')}</span>
                        <span className='text-[#5E5E5E] hidden'>
                            {formatDistance(new Date(cell.row.original?.issue_date), new Date(cell.renderValue()))}
                        </span>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('total_amount', {
            header: () => <p> Amount</p>,
            cell: cell => {
                return (
                    <div className=''>
                        <span className='font-medium'>{cell.row.original?.currency?.symbol ?? '₦'}</span>
                        {formatAmount(Number(cell.renderValue()))?.replaceAll('₦', '')}
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            cell: cell => {
                const status = cell.row.original.status
                return <StatusColor status={status} />
            },
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: row => {
                const invoicePdf = row?.row?.original?.invoice_attachment_url
                return (
                    <TableActions
                        actions={[
                            {
                                icon: MoreHorizontal,
                                label: 'More details',
                                method: () => {
                                    handleRowClick(row?.row.original)
                                },
                            },
                            {
                                label: 'Download',
                                method: () => {
                                    window.open(`${invoicePdf}`, '_blank', 'rel=noopener noreferrer')
                                },
                                icon: DownloadIcon,
                                hidden: !invoicePdf,
                            },
                            {
                                icon: BsArrowCounterclockwise,
                                label: 'Resend Invoice',
                                method: () => {
                                    setInvoiceDetails(row?.row?.original)
                                    setResendInvoice(true)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    const handleRowClick = (invoice: Invoice) => {
        navigate(`/dashboard/invoice/${invoice_subRoute}/${invoice._id}?path=details`)
    }
    return (
        <section className='h-full'>
            <Table
                data={data}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                handleSort={handleSort}
                emptyStateDescription='Create and issue invoices to your customers by clicking the “Create invoice” button at the top-right corner'
                emptyStateTitle='No invoice created or issued yet'
                emptyStateImg={InvoiceEmptyState}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                pagination_data={pagination_details}
                setIsPageNumberClicked={setPageNumberClicked}
            />
            {resendInvoice && <InvoiceReminder closeModal={setResendInvoice} invoice={invoiceDetails!} />}
        </section>
    )
}

export default InvoiceTable
