import blueCheck from '@/assets/blue-check.svg'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { formatAmount } from '@/utils/money'
import { Control, Controller } from 'react-hook-form'
import { BenefitForm } from './helper'
import { useRef } from 'react'

type Props = {
    control: Control<BenefitForm, any>
}
export const plans = [
    {
        name: 'Red Beryl',
        price: 3000,
        list: [
            'Resuscitative care for accident and emergency cases, including basic radiological and laboratory investigations',
            'Routine Ultrasound Scans (Obstetrics; Abdominal, Pelvic, Abdominopelvic, Thyroid, Prostate, Bladder, and Brain Ultrasound Scans)',
            'CT Scan Covered (1 SESSION PER ANNUM)',
            'ICU and ICU-related Care Covered (FOR 24 HOURS)',
        ],
        id: 'red_beryl',
    },
    {
        name: 'Alexandrite',
        price: 4400,
        list: [
            'CT Scan Covered (1 SESSION PER ANNUM)',
            'G-6PD Screening',
            'ICU and ICU-related Care COVERED (FOR 48 HOURS)',
            'Wellness Check: X-ray, BMI Checks',
            'Mental illness care with certified psychiatrists (8 Sessions per year)',
        ],
        id: 'alexandrite',
    },
    {
        name: 'Diamond',
        price: 4400,
        list: [
            'ICU and ICU-related Care COVERED (FOR 72 HOURS)',
            'CT Scan COVERED (2 SESSION PER ANNUM)',
            'ICU and ICU-related Care COVERED (FOR 48 HOURS)',
            'Wellness Check: X-ray, BMI Checks',
            'Mental illness care with certified psychiatrists (12 Sessions per year)',
        ],
        id: 'diamond',
    },
    {
        name: 'Red Diamond',
        price: 4400,
        list: [
            'CT Scan Covered (3 SESSION PER ANNUM)',
            'G-6PD Screening',
            'ICU and ICU-related Care COVERED (FOR 7 DAYS)',
            'PSA Check (For Men ≥ 40 years of age)',
            'Mental illness care with certified psychiatrists (12 Sessions per year)',
        ],
        id: 'red_diamond',
    },
    {
        name: 'Emerald',
        price: 4400,
        list: [
            'CT Scan Covered (4 SESSION PER ANNUM)',
            'ICU and ICU-related Care COVERED (FOR 10 DAYS)',
            'Wellness Check: X-ray, BMI Checks',
            'Mental illness care with certified psychiatrists (15 Sessions per year)',
        ],
        id: 'emerald',
    },
]
const pdfLink = 'https://d1sb16rjggut15.cloudfront.net/Benefits/reliance_20lives.pdf'

const NewBenefitStep2 = ({ control }: Props) => {
    const ref = useRef<HTMLButtonElement>(null)
    return (
        <div className='p-8 px-4 md:px-12'>
            <h1 className='text-lg font-semibold text-[#202020] mb-8'>Choose your desired plan</h1>
            <Controller
                name={'selectedPlan'}
                control={control}
                render={({ field }) => (
                    <RadioGroup
                        {...field}
                        onValueChange={data => {
                            // ref?.current?.click()
                            field.onChange(data)
                        }}
                        className='flex gap-x-8 gap-y-4 justify-between flex-wrap'
                    >
                        {plans.map(plan => (
                            <label
                                className='border border-[#DADCE0] rounded-xl px-3 md:pl-8 md:pr-[26px] pt-5 pb-6 mb-8'
                                key={plan?.id}
                                htmlFor={plan.id}
                                // onClick={_ => {
                                //     const selectedID = (e.target as HTMLElement)
                                //         ?.closest(`#plan_parent-${plan?.id}`)
                                //         ?.querySelector('input')
                                //     if (plan?.id === selectedID?.value) {
                                //         console.log(selectedID)
                                //         selectedID.checked = true
                                //     console.log(plan?.id, selectedID?.value)
                                //     selectedID?.click()
                                //     console.log('here ' + plan?.id)
                                //     }
                                //     console.log(ref?.current?.nextSibling, plan?.id)
                                //     ref.current.nextSibling.select()
                                //     ref.current.nextSibling.checked = true
                                // }}
                                id={`plan_parent-${plan?.id}`}
                            >
                                <div className='flex'>
                                    <div className=''>
                                        <h1 className='text-base md:text-lg font-semibold text-[#202020] mb-3'>
                                            <label className='cursor-pointer' htmlFor={plan.id}>
                                                {plan.name}
                                            </label>
                                        </h1>
                                        <div className='bg-[#EFEFF9] rounded-full py-2 px-3 text-brand text-xs w-[200px] mb-5'>
                                            <span className='text-base md:text-lg font-semibold'>
                                                {formatAmount(plan.price * 100)}
                                            </span>{' '}
                                            / year / life
                                        </div>
                                        <div className='flex flex-col gap-3'>
                                            {plan.list.map((text, i) => (
                                                <div key={i} className='flex items-center gap-4 text-sm font-medium'>
                                                    <img src={blueCheck} alt='' />
                                                    <p>{text}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='relative'>
                                        <RadioGroupItem
                                            ref={ref}
                                            value={plan.id}
                                            id={plan.id}
                                            className='sc scale-150'
                                        />
                                    </div>
                                </div>
                                <a
                                    href={pdfLink}
                                    target='_blank'
                                    className='text-right underline text-brand font-semibold mt-6 cursor-pointer'
                                >
                                    Download comprehensive list
                                </a>
                            </label>
                        ))}
                    </RadioGroup>
                )}
            />
        </div>
    )
}

export default NewBenefitStep2
