import animationData from '@/assets/upgrade-animation.json'

export const storagePlan = [
    {
        id: '1',
        name: '1GB',
        description: 'Current Plan',
        price: '500000',
        frequency: 'Month',
    },
    {
        id: '2',
        name: '2GB',
        description: 'Recommended',
        price: '800000',
        frequency: 'Month',
    },
    {
        id: '3',
        name: '5GB',
        description: 'Recommended',
        price: '1200000',
        frequency: 'Month',
    },
]

export const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}
