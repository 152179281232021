import { Button } from '@/components'

type PaymentProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    next: React.Dispatch<React.SetStateAction<number>>
}

const ContractPrompt = ({ closeModal, next }: PaymentProps) => {
    return (
        <main className='h-[16rem] w-screen lg:w-[34rem]'>
            <div className='pt-7 lg:pt-14'>
                <p className='text-[#5E5E5E] font-semibold text-lg mb-10 w-[85%] mx-auto'>
                    This milestone for this contract has been marked as completed. Would you like to make payment for
                    the completed milestone now?
                </p>
                <div className='flex gap-5 justify-end pr-10'>
                    <Button
                        className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-xs hover:bg-transparent min-w-[100px]'
                        onClick={() => closeModal(false)}
                    >
                        Cancel
                    </Button>

                    <Button
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        onClick={() => next(2)}
                    >
                        Pay now
                    </Button>
                </div>
            </div>
        </main>
    )
}

export default ContractPrompt
