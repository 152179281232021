import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useUpdatePayrollSettings } from '@/services/payroll/mutations'
import { useState } from 'react'
import { payrollGenerationDays } from '../../../helper'
import { toastHandler } from '@/components/utils/Toast'

type EditProps = {
    setEditPayrollGenSettings: React.Dispatch<React.SetStateAction<boolean>>
    id: string
    monthly_lead_days?: number
    weekly_lead_days?: number
    biweekly_lead_days?: number
}

const PayrollGenerationEdit = ({
    setEditPayrollGenSettings,
    id,
    biweekly_lead_days,
    monthly_lead_days,
    weekly_lead_days,
}: EditProps) => {
    const [payrollGenSettings, setPayrollGenSettings] = useState({
        monthly: monthly_lead_days?.toString(),
        weekly: weekly_lead_days?.toString(),
        bi_weekly: biweekly_lead_days?.toString(),
        fulltime_employee_generation: 'automatic',
        partime_employee_generation: 'manual',
        contract_employee_generation: 'manual',
    })
    const { mutate: updatePayrollSettingsFn, isLoading } = useUpdatePayrollSettings({
        queryParams: {
            setting_id: id as string,
        },
        onSuccess() {
            setEditPayrollGenSettings(false)
            toastHandler({ message: 'Changes saved', state: 'success' })
        },
    })

    return (
        <div className='p-6 rounded-lg border border-[#DADCE0] mb-5'>
            <div className='flex justify-between items-center mb-6'>
                <div className=''>
                    <h2 className='text-lg font-medium'>Payroll generation</h2>
                    <p className='text-sm text-[#5E5E5E] mt-1'>
                        Select the day you want payroll to be generated for monthly, bi-weekly or weekly salaries.
                    </p>
                    <p className='font-medium text-sm mt-4 text-[#5E5E5E]'>
                        When should automated payroll be generated?
                    </p>
                </div>
            </div>
            <div className='flex gap-5 lg:gap-12 mb-11 flex-col lg:flex-row'>
                <div className='lg:w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Monthly</h2>
                    <Select
                        name='monthly'
                        onValueChange={data => setPayrollGenSettings(prev => ({ ...prev, monthly: data }))}
                        value={payrollGenSettings.monthly}
                    >
                        <SelectTrigger
                            className={`h-[3rem] border-[#DADCE0] bg-base_gray_100  text-base font-medium w-full`}
                            disabled={isLoading}
                        >
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent className='text-sm w-[19rem]'>
                            {payrollGenerationDays.map(option => (
                                <SelectItem value={option.id} className='cursor-pointer font-normal'>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className='lg:w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Biweekly</h2>
                    <Select
                        name='bi_weekly'
                        onValueChange={data => setPayrollGenSettings(prev => ({ ...prev, bi_weekly: data }))}
                        value={payrollGenSettings.bi_weekly}
                    >
                        <SelectTrigger
                            className={`h-[3rem] border-[#DADCE0] bg-base_gray_100  text-base font-medium w-full`}
                            disabled={isLoading}
                        >
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent className='text-sm w-[19rem]'>
                            {payrollGenerationDays.map(option => (
                                <SelectItem value={option.id} className='cursor-pointer font-normal'>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className='lg:w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Weekly</h2>
                    <Select
                        name='weekly'
                        onValueChange={data => setPayrollGenSettings(prev => ({ ...prev, weekly: data }))}
                        value={payrollGenSettings.weekly}
                    >
                        <SelectTrigger
                            className={`h-[3rem] border-[#DADCE0] bg-base_gray_100  text-base font-medium w-full`}
                            disabled={isLoading}
                        >
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent className='text-sm w-[19rem]'>
                            {payrollGenerationDays.map(option => (
                                <SelectItem value={option.id} className='cursor-pointer font-normal'>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className='flex gap-3 justify-end'>
                <Button
                    className='bg-transparent hover:bg-transparent hover:border hover:border-[#838383] text-[#202020]'
                    onClick={() => setEditPayrollGenSettings(false)}
                    disabled={isLoading}
                >
                    cancel
                </Button>
                <Button
                    loading={isLoading}
                    onClick={() => {
                        updatePayrollSettingsFn({
                            monthly_lead_days: payrollGenSettings?.monthly,
                            weekly_lead_days: payrollGenSettings?.weekly,
                            biweekly_lead_days: payrollGenSettings?.bi_weekly,
                        })
                    }}
                >
                    Save changes
                </Button>
            </div>
        </div>
    )
}

export default PayrollGenerationEdit
