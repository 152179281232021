import Overview from './Overview'
import KeyInsights from './components/partials/KeyInsights'
import NewInflowSection from './components/partials/NewInflowSection'
import RecentExpenses from './components/partials/RecentExpenses'
import RecentTransactions from './components/partials/RecentTransactions'
import TransactionsGraph from './components/partials/TransactionsGraph'
// import BillRequests from './components/partials/BillRequests'
import KYCInfoContainer from '@/components/Modals/Employee/KYCForInvitedEmployees/KYCInfoContainer'
import { useAuthContext } from '@/context'
import { IsAdminWithWalletAccess, isAdmin } from '@/utils/permissions'
// import UpcomingEvents from './components/UpcomingEvents'

export default function OverviewDashboard() {
    const { showKYCModalOnce, setShowKYCModalOnce, selectedAccount } = useAuthContext()
    const { isLoadingPrivilegedAdmin, isPriviledgedAdmin } = IsAdminWithWalletAccess()

    const hasAdminPrivilege = isAdmin(selectedAccount!)
    return (
        <>
            {hasAdminPrivilege && (
                <section className='md:m-2 lg:m-4 xl:m-6 overflow-scroll'>
                    <KeyInsights
                        isPriviledgedAdmin={isPriviledgedAdmin}
                        isLoadingPrivilegedAdmin={isLoadingPrivilegedAdmin}
                    />
                    <RecentTransactions isPriviledgedAdmin={isPriviledgedAdmin} />
                    <TransactionsGraph />
                    <RecentExpenses />
                    {/* <BillRequests /> */}
                    <NewInflowSection />
                    {/* <UpcomingEvents /> */}
                    {showKYCModalOnce && <KYCInfoContainer setShowKYCModalOnce={setShowKYCModalOnce} />}
                </section>
            )}
            {!hasAdminPrivilege && <Overview />}
        </>
    )
}
