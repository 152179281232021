import Logo from '@/assets/bizpendlogo.png'
import blueTick from '@/assets/blue-tick.svg'
import dashboard from '@/assets/dashboard.png'
import { Button, Input } from '@/components'
import { useResetPassword } from '@/services/auth/mutations'
import { CheckIcon, EyeIcon, EyeOffIcon } from 'lucide-react'
import { FormEvent, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

type FormProps = {
    password: string
    confirm_password: string
}

const Password_regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/

const SetPassword = () => {
    const navigate = useNavigate()

    const { mutate: createNewPasswordFn, isLoading } = useResetPassword({
        onSuccess: () => {
            navigate('/login')
        },
    })

    const { control, watch } = useForm<FormProps>()

    const [queryParameter] = useSearchParams()
    const token = queryParameter.get('token') as string
    const email = queryParameter.get('email') as string

    const confirm_password = watch('confirm_password')
    const password = watch('password')

    const isPasswordValid = password === confirm_password && Password_regex.test(password)

    const handleNewPassword = (e: FormEvent) => {
        e.preventDefault()

        createNewPasswordFn({
            password: password,
            token,
            email: email.trim().replace(' ', '+'),
        })
    }
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const handleShowPassword1 = () => {
        setShowPassword1(prev => !prev)
    }
    const handleShowPassword2 = () => {
        setShowPassword2(prev => !prev)
    }

    const checkIfUpperCase =
        password === undefined || password.length < 1 ? false : /[A-Z]|[\u0080-\u024F]/.test(password)
    const checkIfLowerCase =
        password === undefined || password.length < 1 ? false : /[a-z]|[\u0080-\u024F]/.test(password)
    const checkIfHasNumber = password === undefined || password.length < 1 ? false : /[0123456789]/.test(password)
    const checkIfPassWordEqual =
        password?.length > 0 && confirm_password?.length > 0 ? password === confirm_password : false

    return (
        <section className='w-full h-screen grid grid-cols-4 relative'>
            <div className='overflow-auto overscroll-y-contain fixed w-1/4 lg:col-span-1 border-r border-[#DADCE0] h-full hidden lg:block bg-[#EFEFF9]'>
                <div className='p-8 py-4 border-b border-[#DADCE0]'>
                    <div className='cursor-pointer' onClick={() => navigate('/login')}>
                        <img src={Logo} alt='logo' className='w-[137px]' />
                    </div>
                </div>

                <div className='py-12 h-[88vh] flex flex-col justify-between'>
                    <div className='px-8 pb-12'>
                        <div className='flex gap-3 items-start mb-8'>
                            <img src={`${blueTick}`} alt='tick' />
                            <div className='flex flex-col gap-2'>
                                <span className={`text-base font-bold text-[#202020]`}>Enter your password</span>
                                <p className={`text-[#5E5E5E] pr-12 text-sm font-medium`}>
                                    Provide a password for security reasons
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='px-4 overflow-hidden absolute bottom-0 left-0'>
                        <img src={dashboard} alt='sign-up image' className='w-full rounded-md' />
                    </div>
                </div>
            </div>
            <form className='col-span-4 lg:col-start-2 lg:col-span-3 bg-[#FAFAFD]' onSubmit={handleNewPassword}>
                <div className='w-[90%] md:w-[45%] max-w-[1100px] mx-auto my-12'>
                    <section className='pt-4'>
                        <h2 className='text-[#31254B] text-2xl mb-2 font-semibold'>Create new password</h2>
                        <p className='text-[#202020] mb-6 text-sm'>Create and confirm your new passowrd</p>

                        <div className='flex flex-col md:flex-row mb-4'>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                    Password
                                </label>
                                <Controller
                                    name='password'
                                    rules={{
                                        required: true,
                                        minLength: 6,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type={showPassword1 ? 'text' : 'password'}
                                            placeholder='Enter your password'
                                            className='h-16'
                                            rightIcon={
                                                showPassword1 ? (
                                                    <EyeIcon onClick={handleShowPassword1} className='cursor-pointer' />
                                                ) : (
                                                    <EyeOffIcon
                                                        onClick={handleShowPassword1}
                                                        className='cursor-pointer'
                                                    />
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row my-8'>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                    Confirm password
                                </label>
                                <Controller
                                    name='confirm_password'
                                    rules={{
                                        required: true,
                                        minLength: 6,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type={showPassword2 ? 'text' : 'password'}
                                            placeholder='confirm password'
                                            className='h-16'
                                            rightIcon={
                                                showPassword2 ? (
                                                    <EyeIcon onClick={handleShowPassword2} className='cursor-pointer' />
                                                ) : (
                                                    <EyeOffIcon
                                                        onClick={handleShowPassword2}
                                                        className='cursor-pointer'
                                                    />
                                                )
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <ul className='text-[14px] text-[#838383] flex flex-col gap-2'>
                            <li className='flex gap-2 items-center font-medium'>
                                <CheckIcon
                                    className={`rounded-full border-2 p-1 scale-75 ${
                                        password?.length > 7 ? 'text-[#19C37D]' : 'text-[#838383]'
                                    }`}
                                    strokeWidth={4}
                                />
                                <span className={`${password?.length > 7 ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                    Your password must be a minimum of 8 characters long.
                                </span>
                            </li>
                            <li className='flex gap-2 items-center font-medium'>
                                <CheckIcon
                                    className={`rounded-full border-2 p-1 scale-75 ${
                                        checkIfUpperCase ? 'text-[#19C37D]' : 'text-[#838383]'
                                    }`}
                                    strokeWidth={4}
                                />
                                <span className={`${checkIfUpperCase ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                    Your password must contain at least one uppercase letter (A-Z).
                                </span>
                            </li>
                            <li className='flex gap-2 items-center font-medium'>
                                <CheckIcon
                                    className={`rounded-full border-2 p-1 scale-75 ${
                                        checkIfLowerCase ? 'text-[#19C37D]' : 'text-[#838383]'
                                    }`}
                                    strokeWidth={4}
                                />
                                <span className={`${checkIfLowerCase ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                    Your password must contain at least one lowercase letter (a-z)
                                </span>
                            </li>
                            <li className='flex gap-2 items-center font-medium'>
                                <CheckIcon
                                    className={`rounded-full border-2 p-1 scale-75 ${
                                        checkIfHasNumber ? 'text-[#19C37D]' : 'text-[#838383]'
                                    }`}
                                    strokeWidth={4}
                                />
                                <span className={`${checkIfHasNumber ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                    Your password must contain at least one numeric character (0-9)
                                </span>
                            </li>

                            <li className='flex gap-2 items-center font-medium'>
                                <CheckIcon
                                    className={`rounded-full border-2 p-1 scale-75 ${
                                        checkIfPassWordEqual ? 'text-[#19C37D]' : 'text-[#838383]'
                                    }`}
                                    strokeWidth={4}
                                />
                                <span className={`${checkIfPassWordEqual ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                    New password must match
                                </span>
                            </li>
                        </ul>

                        <div className='mt-[110px] flex justify-end gap-6'>
                            <Button disabled={!isPasswordValid} variant='default' loading={isLoading}>
                                Create passowrd
                            </Button>
                        </div>
                    </section>
                </div>
            </form>
        </section>
    )
}

export default SetPassword
