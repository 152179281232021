import { Button } from '@/components'
import ModalWrapper from '../ModalWrapper'
import { SupportedPolicyConsts } from '@/types/approval-policy'

type PaymentProps = {
    action: () => void
    closeModal: () => void
    module: SupportedPolicyConsts
}

const CreateNewPolicyPrompt = ({ action, module, closeModal }: PaymentProps) => {
    return (
        <ModalWrapper>
            <main className='h-[14.5rem] lg:h-auto w-screen lg:w-[35rem]'>
                <div className='mt-5'>
                    <p className='text-gray7 font-medium text-base mb-5  mx-auto px-5 leading-7 '>
                        Creating a new {module.toLowerCase()} policy will render the existing one inactive. Only one{' '}
                        {module.toLowerCase()} policy can be active at any given time. This is aimed at ensuring clarity
                        and simplicity in managing your {module.toLowerCase()} policies.
                    </p>
                    <div className='flex gap-5 justify-end pr-10 mb-4'>
                        <Button
                            className='bg-transparent text-[#202020] px-5 py-2 h-[2rem] rounded-lg text-xs hover:bg-transparent min-w-[100px]'
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>

                        <Button
                            className='bg-brand text-white px-6 h-[2rem] rounded-lg text-[14px] min-w-[100px] py-2'
                            onClick={() => {
                                action()
                            }}
                        >
                            Proceed
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default CreateNewPolicyPrompt
