import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserSettingsHeading, subRouteHelper } from '../utils'
import { GreaterThan } from '@/assets/assets.export'

type Props = {
    children: React.JSX.Element[]
}

export default function UserSettingsContainer({ children }: Props) {
    const { settings_subRoute } = useParams()
    const navigate = useNavigate()

    const userPageindex = settings_subRoute && subRouteHelper[settings_subRoute as keyof typeof subRouteHelper]

    return (
        <main className='bg-white min-h-[100vh] overflow-scroll'>
            <section className='font-medium pr-6 pb-4 pt-4 ml-6 lg:ml-[2.9rem] mx-auto flex gap-1'>
                <span onClick={() => navigate(-1)} className='cursor-pointer'>
                    Account settings
                </span>
                <span className='text-[#838383] flex gap-1'>
                    {' '}
                    <GreaterThan />
                    {UserSettingsHeading[settings_subRoute as keyof typeof UserSettingsHeading]}
                </span>
            </section>
            <hr className='text-[#DADCE0] ' />
            <section className='pt-4 mb-[8rem] ml-6 lg:ml-[3rem]  mx-auto '>
                {userPageindex !== undefined && typeof userPageindex !== 'string' ? children[userPageindex] : null}
            </section>
        </main>
    )
}
