import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { Select, SelectContent, SelectItem, SelectValue, SelectTrigger } from '@/components/Select'
import { Button } from '@/components/Button'

type Props = {
    closeModal: () => void
}
export default function AssignAdminSetupModal({ closeModal }: Props) {
    return (
        <ModalWrapper>
            <section className='min-h-[15rem] w-[30rem] overflow-scroll'>
                <div className='flex items-center border-b border-[#DADCE0] p-3 px-4 justify-between'>
                    <h2 className='text-center text-base font-medium opacity-80'>
                        Select finance admin to setup accounting
                    </h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='mx-4 mt-5'>
                    <h3 className='mb-2 text-sm text-gray7'>Select finance admin</h3>
                    <Select name='admin' defaultValue='pecs'>
                        <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                            <SelectValue placeholder={''} />
                        </SelectTrigger>
                        <SelectContent className=''>
                            <SelectItem value='pecs'>Peculiar ugbo</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className='border-t border-gray4 flex mt-6 justify-end items-center pt-2.5 px-4'>
                    <Button size={'lg'}>Next</Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
