import { ProfileEditIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import { useAccountingContext } from '@/context'
import useSingleFileUpload, { fileEmptyState, FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { useUploadGlCode } from '@/services/accounting/mutations'
import { useGetGLCode } from '@/services/accounting/queries'
import { OrgGlCodeType } from '@/types/gl-code'
import { Trash2 } from 'lucide-react'
import { useMemo, useRef, useState } from 'react'

type GlProps = {
    changeStep: () => void
}
export default function ManageGLCode() {
    const { setHasGlCode, setOrgGlCode, orgGlCode, hasGlCode } = useAccountingContext()

    const [step, setStep] = useState(hasGlCode ? 2 : 1)

    const { data } = useGetGLCode({
        enabled: (orgGlCode?.codes?.length as number) < 1,
        onSuccess: data => {
            if (data?.codes?.length > 0) {
                setHasGlCode(true)
                setOrgGlCode(data)
            }
        },
    })
    const glCodes = useMemo(() => orgGlCode ?? data ?? ({} as OrgGlCodeType), [data, orgGlCode])

    return (
        <section className='mb-5 bg-white rounded-xl p-7 flex-1'>
            <h2 className='font-medium text-md border-b border-gray5 pb-2'>Manage GL codes</h2>
            {step === 1 && <UploadGLCode changeStep={() => setStep(2)} />}
            {step === 2 && <HasExistingGLCode glCodes={glCodes} changeStep={() => setStep(1)} />}
        </section>
    )
}
const HasExistingGLCode = ({ changeStep, glCodes }: GlProps & { glCodes: OrgGlCodeType }) => {
    return (
        <section className='mt-5'>
            <div className='flex justify-between items-center p-4 border border-gray5 rounded-lg'>
                <div>
                    <span className='text-sm font-medium block'>Kuda_MFB_Sage_GL codes.xls</span>
                    <span className='text-gray7 text-xs block font-medium mt-1'>240.77kb</span>
                </div>
                <div className='flex items-center gap-4'>
                    <button className='flex flex-col items-center justify-center text-errorRed'>
                        <Trash2 width={17} strokeWidth={1.5} />
                        <span className='text-[.7rem] font-medium'>Delete</span>
                    </button>
                    <button className='flex flex-col items-center justify-center' onClick={() => changeStep()}>
                        <ProfileEditIcon width={17} strokeWidth={1.5} />
                        <span className='text-[.7rem] font-medium text-[#555555]'>Change</span>
                    </button>
                </div>
            </div>
            <span className='inline-block bg-green/10 text-green rounded-md p-2 mt-5 text-xs border border-green'>
                {glCodes?.codes?.length} GL codes imported successfully
            </span>

            <div className='flex gap-3 flex-wrap'>
                {glCodes?.codes?.map(code => (
                    <span
                        key={code?.id}
                        className='inline-block bg-gray7/10 text-gray7 rounded p-1.5 px-2 mt-5 text-xs '
                    >
                        {code?.code}
                    </span>
                ))}
            </div>
        </section>
    )
}

const UploadGLCode = ({ changeStep }: GlProps) => {
    const [glCodeFile, setGlCodeFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const glCodeRef = useRef<HTMLInputElement>(null)

    const { handleFile: uploadProofFn } = useSingleFileUpload({
        fileUpload: glCodeFile,
        setFileUpload: setGlCodeFile,
    })
    const { mutate: uploadGlCodeFn, isLoading } = useUploadGlCode({
        onSuccess: () => {
            changeStep?.()
        },
    })

    return (
        <>
            <h3 className='mt-4'>Upload GL codes</h3>
            <p className='text-gray7 text-sm mt-1'>
                These codes will be used to map the categories in your accounting software, ensuring accurate and
                organized financial records
            </p>
            <h4 className='text-sm border-b border-gray5 pb-2 font-medium mt-6'>Instructions</h4>
            <ul className='text-gray7 list-decimal list-inside space-y-1 mt-3 text-sm'>
                <li>
                    Download csv template{' '}
                    <a
                        className='font-medium text-brand'
                        href='/gl-sample.csv'
                        download='Gl-sample.csv'
                        target='_blank'
                        rel='noreferrer'
                    >
                        here
                    </a>
                </li>
                <li>Fill in your GL codes: Open the template and fill in your GL codes.</li>
                <li>Upload the completed file once you’ve filled in the template.</li>
                <li>Click the “Import GL codes” button after uploading the file</li>
            </ul>
            <div className='bg-[#EFEFF9] rounded-lg p-4 mt-5 w-fit'>
                <h5 className='font-medium text-sm'>Note</h5>
                <p className='text-xs text-gray7'>
                    Ensure the GL codes match the categories in your accounting software (e.g., QuickBooks, Xero) for
                    smooth integration.
                </p>
            </div>

            <label
                htmlFor={'gl-code'}
                className='cursor-pointer upload flex items-center justify-center border border-dashed rounded-lg border-[#DADCE0] h-[7.6rem] mt-8'
            >
                <h1 className='text-center text-base text-[#A8A8A8] font-medium my-auto'>
                    {glCodeFile?.file_name && glCodeFile?.file_name?.length > 0
                        ? glCodeFile?.file_name
                        : ' Upload GL code'}
                    <br />
                    {glCodeFile?.file_name && glCodeFile?.file_name?.length > 0 ? (
                        <span className='text-sm'>
                            {glCodeFile?.size} |{' '}
                            <button
                                className='font-semibold text-xs text-brand'
                                onClick={() => glCodeRef?.current?.click()}
                            >
                                Change document
                            </button>
                        </span>
                    ) : (
                        <span className='text-sm'>{'Max 2MB | Accepts .xls and .xlsx file only'}</span>
                    )}
                </h1>
                <input
                    type='file'
                    id={'gl-code'}
                    ref={glCodeRef}
                    onChange={e => {
                        uploadProofFn(e)
                    }}
                    accept='.xls, .xlsx, .csv'
                    hidden
                />
            </label>

            <div className='flex justify-end mt-8'>
                <Button
                    onClick={() => {
                        const formdata = new FormData()
                        formdata.append('file', (glCodeFile?.fileBeforeFormData as Blob) ?? '')
                        uploadGlCodeFn(formdata)
                    }}
                    disabled={glCodeFile && glCodeFile?.file_name?.length < 1}
                    loading={isLoading}
                >
                    Import GL codes
                </Button>
            </div>
        </>
    )
}
