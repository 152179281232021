import { VaultItem } from '@/services/vault/types'
import { FileContainer } from './FileContainer'
import { FileEmptyState } from '../FileEmptyState'

type FilesProps = {
    files: VaultItem[]
}

export const FileLists = ({ files }: FilesProps) => {
    return (
        <>
            {files.length > 0 ? (
                files?.map(file => <FileContainer key={file.id} file={file} />)
            ) : (
                <FileEmptyState type='file' />
            )}
        </>
    )
}
