import { useAuthContext } from '@/context'
import { useGetUserAccountData } from '@/services/auth/queries'
import { UserTransactionExpenseType } from '@/services/auth/types'
import { formatAmount } from '@/utils/money'
import { useEffect, useMemo, useState } from 'react'
import expensesIcon2 from '@/assets/expenses-dashboard.svg'
import userTeamsIcon from '@/assets/userTeamsIcon.svg'
import userTransIcon from '@/assets/userTransIcon.svg'
import userTransCount from '@/assets/userTransCount.svg'
import userRequestsIcon from '@/assets/userRequestsIcon.svg'
import { subDays, subMonths, format } from 'date-fns'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'

import { capitalizeText } from '@/utils/string'

const UserOverviewInsights = () => {
    const sevenDaysAgo = subDays(new Date(), 7)
    const { selectedAccount } = useAuthContext()
    const [insightDate, setInsightDate] = useState(sevenDaysAgo.toDateString())
    const { data, refetch, isLoading, isRefetching } = useGetUserAccountData({
        queryParams: {
            end_date: format(new Date(), 'yyyy-MM-dd'),
            start_date: format(new Date(insightDate), 'yyyy-MM-dd'),
        },
    })

    const userInsightData = useMemo(() => data ?? ({} as UserTransactionExpenseType), [data])

    const percentage = (Number(userInsightData?.totalExpenseAmount) / 30000000) * 100
    useEffect(() => {
        refetch()
    }, [insightDate])

    return (
        <>
            <div className='mb-5'>
                <h2 className='text-2xl lg:text-4xl text-[#202020] font-extrabold'>
                    Hi {capitalizeText(selectedAccount?.user.last_name)},
                </h2>
            </div>

            <div
                className={`${isLoading || isRefetching ? 'opacity-50' : ''} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8`}
            >
                <div className='col-span-2 border border-[#DADCE0] bg-white rounded-xl px-5 py-7 lg:py-5  '>
                    <div className='flex items-center justify-between'>
                        <div className='flex gap-2 lg:gap-4 items-center'>
                            <img src={expensesIcon2} alt='' />
                            <div>
                                <p className='text-[#5E5E5E] text-sm font-medium'>My expenses</p>
                                <p className='text-[#202020] font-semibold text-base sm:text-xl'>
                                    {formatAmount(userInsightData?.totalExpenseAmount)}
                                </p>
                            </div>
                        </div>
                        <div className='bg-[#fff] rounded-lg'>
                            <Select onValueChange={setInsightDate} value={insightDate}>
                                <SelectTrigger className='w-full border border-[#DADCE0]'>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className='w-[9rem]'>
                                    <SelectItem value={sevenDaysAgo.toDateString()}>Last 7 days</SelectItem>
                                    <SelectItem value={subMonths(new Date(), 1).toDateString()}>
                                        Last 1 Month
                                    </SelectItem>
                                    <SelectItem value={subMonths(new Date(), 3).toDateString()}>
                                        Last 3 Months
                                    </SelectItem>
                                    <SelectItem value={subMonths(new Date(), 6).toDateString()}>
                                        Last 6 Months
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <div className='h-[16px] w-full mt-10 rounded flex items-center bg-[#f5f5f5] relative'>
                        <div
                            className='h-full bg-[#19C37D] rounded-l'
                            style={{ width: `${percentage > 39 ? '40' : percentage}%` }}
                        ></div>
                        {percentage > 39 && (
                            <div
                                className='h-full  bg-[#DEB145]'
                                style={{ width: `${percentage > 69 ? '30' : Number(percentage - 39)}%` }}
                            ></div>
                        )}
                        {percentage > 69 && (
                            <div
                                className={`h-full bg-[#CF491E] ${
                                    percentage > 100 ? 'rounded-r' : ''
                                } overflow-hidden rounded-r `}
                                style={{ width: `${percentage > 100 ? '30' : Number(percentage - 69)}%` }}
                            ></div>
                        )}
                    </div>
                </div>
                <div className='col-span-2 grid grid-cols-2 gap-2 lg:gap-4'>
                    <div className='px-2 lg:px-4 py-4 lg:py-[18px] rounded-lg border border-[#DADCE0] bg-white flex gap-2 sm:gap-4 items-center'>
                        <img src={userTransIcon} className='w-8 h-8 sm:w-auto sm:h-auto' alt='' />
                        <div>
                            <p className='text-[#5E5E5E] text-sm font-medium'>Transactions </p>
                            <p className='text-[#202020] font-semibold text-base sm:text-xl'>
                                {formatAmount(userInsightData?.totalTransactionsAmount)}{' '}
                            </p>
                        </div>
                    </div>
                    <div className='px-2 lg:px-4 py-4 lg:py-[18px] rounded-lg border border-[#DADCE0] bg-white flex gap-2 sm:gap-4 items-center'>
                        <img src={userTransCount} className='w-8 h-8 sm:w-auto sm:h-auto' alt='' />
                        <div>
                            <p className='text-[#5E5E5E] text-sm font-medium'>Transaction counts </p>
                            <p className='text-[#202020] font-semibold text-base sm:text-xl'>
                                {userInsightData?.numberOfTransactions}{' '}
                            </p>
                        </div>
                    </div>
                    <div className='px-2 lg:px-4 py-4 lg:py-[18px] rounded-lg border border-[#DADCE0] bg-white flex gap-2 sm:gap-4 items-center'>
                        <img src={userTeamsIcon} className='w-8 h-8 sm:w-auto sm:h-auto' alt='' />
                        <div>
                            <p className='text-[#5E5E5E] text-sm font-medium'>Teams </p>
                            <p className='text-[#202020] font-semibold text-base sm:text-xl'>
                                {' '}
                                {userInsightData?.totalUserTeams}{' '}
                            </p>
                        </div>
                    </div>
                    <div className='px-2 lg:px-4 py-4 lg:py-[18px] rounded-lg border border-[#DADCE0] bg-white flex gap-2 sm:gap-4 items-center'>
                        <img src={userRequestsIcon} className='w-8 h-8 sm:w-auto sm:h-auto' alt='' />
                        <div>
                            <p className='text-[#5E5E5E] text-sm font-medium'>Expense counts</p>
                            <p className='text-[#202020] font-semibold text-base sm:text-xl'>
                                {userInsightData?.totalExpenses} <span className='font-medium text-[10px]'></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserOverviewInsights
