import { ApprovalType } from '@/types/expense'

export const ApproversWrapper = ({ approvers }: { approvers: ApprovalType[] }) => {
    return (
        <div>
            {approvers?.map(approver => (
                <p className='whitespace-normal truncate'>
                    {approver?.account?.user?.first_name ?? 'N/A'} {approver?.account?.user?.last_name ?? ''}{' '}
                </p>
            ))}
            {approvers?.length === 0 && 'Auto-approval'}
        </div>
    )
}
