import { CancelIcon } from '@/assets/assets.export'
import { modalVariants } from '../Employee/utils'
import ModalWrapper from '../ModalWrapper'
import { ArrowLeft } from 'lucide-react'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
export const DeclineBudgetRequest = ({ closeModal }: Props) => {
    const handleBlockCard = () => {}
    return (
        <ModalWrapper variants={modalVariants} className='justify-end right-8'>
            <section className='h-[100vh] overflow-scroll'>
                <div className='flex items-center my-4 border-b border-[#DADCE0] w-full pb-2'>
                    <h2 className='flex-1 text-center font-semibold text-xl'>Decline budget request</h2>
                    <CancelIcon
                        className='w-9 h-9 bg-[#F2F2F2] rounded-full p-2 inline-block mr-3 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>

                <section className='w-[27rem] mx-4 my-7 '>
                    <div className='flex cursor-pointer gap-2 items-center mb-7' onClick={() => closeModal(false)}>
                        <ArrowLeft />
                        go back
                    </div>
                    <div>
                        <h2 className='font-medium tracking-wide text-xl mb-1'>Confirm budget request decline</h2>
                        <p className='text-[#5E5E5E] text-xs'>
                            Are you sure you want to decline this request? Please provide a reason below.
                        </p>
                    </div>
                    <div className='mt-5 flex flex-col gap-2 mb-5'>
                        <label htmlFor='block_card' className='text-[#202020] text-[14px] font-medium'>
                            Reason <span className='text-[red]'>*</span>
                        </label>
                        <Select name='block_card'>
                            <SelectTrigger className='w-full h-[3.5rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                <SelectValue placeholder={'Why are you declining this budget request'} />
                            </SelectTrigger>
                            <SelectContent className='w-[27rem] max-h-[200px] overflow-scroll'>
                                <SelectItem value='charges'>Suspicious Charges</SelectItem>
                                <SelectItem value='fraud'>Fraudulent Activity</SelectItem>
                                <SelectItem value='expired'>Expired</SelectItem>
                                <SelectItem value='other'>Others</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className='mt-8 flex flex-col gap-2 mb-'>
                        <label htmlFor='firstName' className='text-[#202020] text-[14px] font-medium '>
                            Additional note (Optional)
                        </label>
                        <textarea
                            name='card_name'
                            placeholder=' Explain further if you wish to.'
                            className='h-[192px] border-[#DADCE0] placeholder:text-[#A8A8A8] placeholder:font-normal flex w-full  text-dark rounded-md border focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm focus:outline-none disabled:cursor-not-allowed resize-none'
                        />
                    </div>
                    <Button
                        className='mt-[4rem] w-full bg-[#CF491E] hover:bg-[#CF491E]/80 '
                        // loading={isLoading}
                        onClick={handleBlockCard}
                    >
                        Yes, block this card
                    </Button>
                </section>
            </section>
        </ModalWrapper>
    )
}
