/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion/index2'
import { ChevronDown } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { CancelIcon, PDFIcon } from '@/assets/assets.export'
import { useVaultContext } from '@/context'
import { signedUrlContext } from '@/services/vault/types'
import axios from 'axios'
import { toastHandler } from '@/components/utils/Toast'
import { useCreateVaultItem } from '@/services/vault/mutations'
import { convertToBytesText } from './AddNewFileModal'

export const UploadingPopup = () => {
    const [open, setOpen] = useState(true)
    const [dismiss, setDismiss] = useState(false)
    const { signedURLData, setSignedUrlData } = useVaultContext()

    if (signedURLData?.length === 0 || !signedURLData || dismiss) return <></>

    return (
        <div
            className={`px-4 rounded-xl absolute top-14 right-0 bg-white z-[10000] border border-gray8 shadow-xl ${open ? '' : 'pb-1'}`}
        >
            <Accordion type='single' collapsible defaultValue='item-1'>
                <AccordionItem value='item-1' className='border-none w-[352px]'>
                    <AccordionTrigger className=' pt-2 pb-1'>
                        <span
                            className='w-full flex items-center justify-between text-base font-medium'
                            onClick={() => setOpen(prev => !prev)}
                        >
                            Files uploading
                            <div className='flex items-center gap-4'>
                                <button
                                    className='text-[10px] font-semibold text-gray7 bg-[#F2F2F2] px-2 rounded border border-gray7'
                                    onClick={() => setDismiss(true)}
                                >
                                    Dismiss
                                </button>
                                <ChevronDown
                                    color='#838383'
                                    className={`w-5 h-5 transition-all ${open ? '-rotate-180' : ''}`}
                                />
                            </div>
                        </span>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className='flex gap-3 flex-col mt-4'>
                            {signedURLData?.map((data, i) => (
                                <SingleFileUploading file={data} key={i} setSignedUrlData={setSignedUrlData} />
                            ))}
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

const SingleFileUploading = ({
    file,
    setSignedUrlData,
}: {
    file: signedUrlContext
    setSignedUrlData: React.Dispatch<React.SetStateAction<signedUrlContext[] | undefined>>
}) => {
    const [showProgress, setShowProgress] = useState(false)
    const [loaded, setLoaded] = useState('0 KB')
    const fileName = file?.file?.name.split('.')?.[0]
    const { mutate: createItemFn } = useCreateVaultItem({})

    const uploadStarted = useRef(false)

    const uploadSignedFile = () => {
        setShowProgress(true)
        axios
            .put(file.signedUrl, file?.file, {
                headers: {
                    'Content-Type': file?.file.type as string,
                    'Content-Length': String(file.file?.size),
                },
                onUploadProgress: ({ loaded, total }) => {
                    setLoaded(convertToBytesText(loaded))
                    if (loaded === total) {
                        setShowProgress(false)
                        toastHandler({ message: 'File uploaded successfully', state: 'success' })
                    }
                },
            })
            .then(res => {
                if (res.status === 200) {
                    createItemFn({
                        name: file.file.name?.split('.')[0],
                        type: 'file',
                        path: file.path,
                        size: file.file.size,
                        mimeType: file.file.type,
                        vault: file.vault,
                        parent: file.parent,
                        category: file.category,
                    })
                    const timeoutId = setTimeout(() => {
                        setSignedUrlData(prev => prev?.filter(urlFile => urlFile.id !== file.id))
                    }, 3000)

                    return () => clearTimeout(timeoutId)
                }
            })
            .catch(error => console.log(error))
    }
    useEffect(() => {
        if (!uploadStarted.current) {
            uploadStarted.current = true
            uploadSignedFile()
        }
    }, [])

    return (
        <div className='border border-gray8 rounded-lg flex items-center justify-between  p-4'>
            <div className='flex gap-3 items-center'>
                <PDFIcon className='h-8 w-7' />
                <div className=''>
                    <p className='text-sm font-semibold mb-1'>
                        {fileName.length > 20
                            ? `${fileName.substring(0, 21)}... .${file?.file?.name.split('.')?.[1]}`
                            : file.file.name}
                    </p>
                    <p className='text-[10px] text-gray7 font-medium'>
                        {/* Failed | <span className='font-semibold text-brand'>Retry</span> */}
                        {showProgress ? 'Uploading' : 'Completed'} |{' '}
                        <span className='font-semibold text-brand'>
                            {loaded} of {convertToBytesText(file.file.size)}
                        </span>
                    </p>
                </div>
            </div>
            <span
                className='cursor-pointer'
                onClick={() => {
                    setSignedUrlData(prev => prev?.filter(urlFile => urlFile.id !== file.id))
                }}
            >
                {<CancelIcon className='rounded-full bg-[#F9F0EF]  text-red p-[0.35rem] h-7 w-7 !stroke-[#872D10]' />}
            </span>
        </div>
    )
}
