import { DeleteIcon, SendReminder } from '@/assets/assets.export'
// import { Button } from '@/components'
import { useMutationDeleteInvite, useMutationSendInviteReminder } from '@/services/employees/mutations'
import { EmployeeType } from '@/types/org'
import React, { useEffect, useState } from 'react'
import { GrCheckmark } from 'react-icons/gr'
import loader from '@/assets/loadericon.svg'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips/index'
import { capitalizeText } from '@/utils/string'

const defaultState: { send: string | JSX.Element; delete: string | JSX.Element; type: string } = {
    send: 'Send reminder',
    delete: ' Delete invite',
    type: 'type_none',
}
const inviteConsts = {
    type_none: 'type_none',
    type_send: 'type_send',
    type_delete: 'type_delete',
    send: 'send',
    delete: 'delete',
}
type Props = {
    inviteDetails: EmployeeType
    InactiveEmp: EmployeeType[]
}

export default function SingleInvite({ inviteDetails, InactiveEmp }: Props) {
    const [inviteProcess, setInviteProcess] = React.useState(defaultState)

    const [inviteReminder, setInviteReminder] = useState(false)
    const [removeDeleteBanner, setRemoveDeleteBanner] = useState(false)
    const [deleteInviteTag, setDeleteInviteTag] = useState(false)

    const { mutate: deleteInvite, isLoading: isDeleteInviteLoading } = useMutationDeleteInvite({
        queryParams: {
            account: inviteDetails?.id,
        },
        refetch: [['/pending-invites']],
        onSuccess: () => {
            setDeleteInviteTag(true)
            setRemoveDeleteBanner(false)
            setInviteProcess(defaultState)
        },
    })

    const { mutate: inviteReminderFn, isLoading: isInviteReminderLoading } = useMutationSendInviteReminder({
        queryParams: {
            account: inviteDetails?.id,
        },
        onSuccess: () => setInviteReminder(true),
    })

    const handleInviteEmployee = (msg: string, type: string) => {
        if (msg === inviteConsts.send && type === inviteConsts.type_none) {
            setInviteProcess({ send: 'Send a reminder', delete: 'No', type: inviteConsts.type_send })
        } else if (msg === inviteConsts.delete && type === inviteConsts.type_send) {
            setInviteProcess(defaultState)
        } else if (type === inviteConsts.type_none && msg === inviteConsts.delete) {
            setInviteProcess({ send: 'No', delete: 'Yes, Delete this!', type: inviteConsts.type_delete })
        } else if (type === inviteConsts.type_delete && msg === inviteConsts.send) {
            setInviteProcess(defaultState)
        } else if (type === inviteConsts.type_delete && msg === 'delete') {
            //------deletes the invite that was sentv---------
            deleteInvite({})
            setRemoveDeleteBanner(true)
        } else if (type === inviteConsts.type_send && msg === 'send') {
            inviteReminderFn({})
        }
    }

    useEffect(() => {
        setInviteReminder(false)
        setRemoveDeleteBanner(false)
        setDeleteInviteTag(false)
    }, [InactiveEmp.length])
    return (
        <div
            className={`${
                isDeleteInviteLoading ? 'opacity-60' : 'opacity-100'
            } flex justify-between gap-5 border-b border-b-[#DADCE0]/80 items-center py-5 mr-3 w-[93vw] lg:w-[30rem] overflow-x-scroll max-h-[5.2rem] hover:px-2 hover:bg-[#F9F9F9]`}
        >
            <div className='truncate'>
                <span className='block text-[16px] font-semibold text-[#202020] mb-2 truncate '>
                    {inviteDetails?.email ?? 'N/A'}
                </span>
                <span className='block text-gray1 truncate text-[14px]'>
                    {capitalizeText(inviteDetails.role.name)} invited by {inviteDetails?.org?.org_name ?? 'N/A'}
                </span>
            </div>
            <div className='flex gap-5 justify-center items-center pl-5 mr-3 border-l border-l-[#DADCE0]/80 '>
                {!inviteReminder && !deleteInviteTag ? (
                    isDeleteInviteLoading || isInviteReminderLoading || removeDeleteBanner ? (
                        <img src={loader} className='animate-spin mx-7' />
                    ) : (
                        <>
                            <TooltipProvider delayDuration={10}>
                                <Tooltip>
                                    <TooltipTrigger>
                                        <button
                                            className={`cursor-pointer text-center max-w-[120px] rounded-full ${
                                                inviteConsts.type_send === inviteProcess.type
                                                    ? ' text-white rounded-md px-2 py-3 bg-brand min-w-[145px] '
                                                    : inviteProcess.send === 'Send reminder'
                                                      ? ''
                                                      : 'hover:bg-[#FFEDED] p-2'
                                            } `}
                                            onClick={() => handleInviteEmployee('send', inviteProcess.type)}
                                            disabled={isDeleteInviteLoading}
                                        >
                                            {isInviteReminderLoading && <img src={loader} className='animate-spin' />}
                                            {isInviteReminderLoading ? (
                                                ''
                                            ) : inviteProcess.send === 'Send reminder' ? (
                                                <SendReminder />
                                            ) : (
                                                inviteProcess.send
                                            )}
                                        </button>
                                    </TooltipTrigger>
                                    {inviteProcess.send === 'Send reminder' && (
                                        <TooltipContent>
                                            <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                Send reminder
                                            </p>
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            </TooltipProvider>

                            {/* <CancelIcon className=''/> */}
                            <TooltipProvider delayDuration={10}>
                                <Tooltip>
                                    <TooltipTrigger>
                                        <button
                                            className={`cursor-pointer text-center  rounded-full ${
                                                inviteConsts.type_delete == inviteProcess.type
                                                    ? 'text-[white] rounded-md px-2 py-3 bg-errorRed min-w-[145px]'
                                                    : inviteProcess.delete === ' Delete invite'
                                                      ? ''
                                                      : 'hover:bg-[#FFEDED] p-2'
                                            }`}
                                            onClick={() => handleInviteEmployee('delete', inviteProcess.type)}
                                            disabled={isInviteReminderLoading}
                                        >
                                            {isDeleteInviteLoading && <img src={loader} className='animate-spin' />}
                                            {isDeleteInviteLoading ? (
                                                ''
                                            ) : inviteProcess.delete === ' Delete invite' ? (
                                                <DeleteIcon />
                                            ) : (
                                                inviteProcess.delete
                                            )}
                                        </button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {inviteProcess.delete === ' Delete invite' && (
                                            <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                Delete invite
                                            </p>
                                        )}
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </>
                    )
                ) : (
                    <span className='flex gap-2 items-center min-w-[150px] '>
                        <GrCheckmark />
                        {inviteReminder ? 'Reminder sent!' : ' Invite deleted!'}
                    </span>
                )}
            </div>
        </div>
    )
}
