import { useSearchParams } from 'react-router-dom'
import LeaveDaysSettings from './LeaveDaysSettings'
import LeavePolicy from './LeavePolicy'

const route_helpers = {
    days: 'Leave days',
    policy: 'Leave approval policy',
}
const LeaveSettings = () => {
    const [searchParams, setParams] = useSearchParams()
    const settingsType = searchParams.get('settings') ?? 'Leave days'

    return (
        <section className='flex flex-col xl:flex-row mt-10 gap-8'>
            <div className='w-full lg:w-52 h-fit bg-white flex flex-col gap-4 rounded-xl p-5  text-sm items-start shadow-sm'>
                {Object.values(route_helpers).map(data => (
                    <button
                        onClick={() => setParams({ settings: data })}
                        className={`${settingsType === data ? 'text-brand font-semibold' : ''}`}
                    >
                        {data}
                    </button>
                ))}
            </div>
            <div className='bg-white flex-1 rounded-xl p-5 w-full '>
                {settingsType === route_helpers.days && <LeaveDaysSettings />}
                {settingsType === route_helpers.policy && <LeavePolicy />}
            </div>
        </section>
    )
}

export default LeaveSettings
