import { ProfileEditIcon } from '@/assets/assets.export'
import ProgressBar from '@/components/ProgressBar'
import { useGetOrgUsageRecord } from '@/services/billing/queries'
import { UsageRecordType } from '@/types/billing'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export default function BusinessSubscriptionUsage() {
    const { user_id: org_id } = useParams()

    const { data, isLoading } = useGetOrgUsageRecord({
        queryParams: {
            org: org_id as string,
        },
    })

    const orgUsageRecord = useMemo(() => data ?? ({} as UsageRecordType), [data])
    const modules = orgUsageRecord?.modules

    const usage_record = [
        {
            name: 'Users',
            details: [
                {
                    name: 'User count',
                    desc: `${modules?.people?.allocated - modules?.people?.available} / ${modules?.people?.allocated}`,
                },
            ],
        },
        {
            name: 'Expense management',
            details: [
                {
                    name: 'Expense reports',
                    desc: `${modules?.expenses?.allocated - modules?.expenses?.available} / ${modules?.expenses?.allocated}`,
                },

                {
                    name: 'OCR scanning',
                    desc: `${modules?.ocr_scan?.allocated - modules?.ocr_scan?.available} / ${modules?.ocr_scan?.allocated}`,
                },
            ],
        },
        {
            name: 'Partners',
            details: [
                {
                    name: 'Vendor count',
                    desc: `${modules?.vendors?.allocated - modules?.vendors?.available} / ${modules?.vendors?.allocated}`,
                },
                {
                    name: 'Customer count',
                    desc: `${modules?.customers?.allocated - modules?.customers?.available} / ${modules?.customers?.allocated}`,
                },
            ],
        },
        {
            name: 'Invoicing',
            details: [
                {
                    name: 'Invoice count',
                    desc: `${modules?.invoices?.allocated - modules?.invoices?.available} / ${modules?.invoices?.allocated}`,
                },
            ],
        },
        {
            name: 'Budget',
            details: [
                {
                    name: 'Budget count',
                    desc: `${modules?.budgets?.allocated - modules?.budgets?.available} / ${modules?.budgets?.allocated}`,
                },
            ],
        },
    ]
    if (isLoading) return <ProgressBar />
    return (
        <section className='flex-1 bg-white rounded-xl p-5 shadow-sm mb-6'>
            <div className='flex justify-between border-b border-gray5 pb-2 items-center'>
                <h3 className='font-medium text-md'>Features usage</h3>

                <span className='flex items-center gap-2 text-[#5E5E5E]  rounded-md cursor-pointer text-sm'>
                    <ProfileEditIcon className='w-4 h-4' />
                    <button type='button'>Edit</button>
                </span>
            </div>

            <div className=' mt-5'>
                <div className=''>
                    {usage_record?.map(data => (
                        <div className='mb-6'>
                            <h3 className='font-medium text-lg mb-1'>{data.name}</h3>
                            <div className='flex flex-wrap gap-8'>
                                {data.details.map(detail => (
                                    <div>
                                        <span className='text-[#838383] font-normal text-sm'>{detail.name}</span>
                                        <div className='flex gap-3 items-center  truncate'>
                                            <span className='font-medium text-[.9rem] truncate max-w-[12rem]'>
                                                {detail.desc}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
