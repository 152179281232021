import React, { useRef, useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { CancelIcon, PDFIcon } from '@/assets/assets.export'
import useSingleFileUpload, { fileEmptyState, FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { Button } from '@/components/Button'
import { toastHandler } from '@/components/utils/Toast'
import { useMutationCreateBill } from '@/services/bill-payment/mutations'
import { BillType } from '@/services/bill-payment/types'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    docFile: FileUploadFormatProps | undefined
    setDocFile: React.Dispatch<React.SetStateAction<FileUploadFormatProps | undefined>>
    setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>
    setScannedBill: React.Dispatch<React.SetStateAction<BillType | undefined>>
}
export const UploadInvoiceBill = ({ closeModal, docFile, setDocFile, setShowCreateModal, setScannedBill }: Props) => {
    const [step, setStep] = useState(1)

    // const [docFile, setDocFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const { handleFile: uploadDocFn } = useSingleFileUpload({
        setFileUpload: setDocFile,
    })
    const { mutate: createBill, isLoading: isBillScanned } = useMutationCreateBill({
        onSuccess: data => {
            setScannedBill(data as unknown as BillType)
            setShowCreateModal(true)
            closeModal(false)
        },
        onError: () => {
            toastHandler({ message: 'File is invalid', state: 'error' })
            setStep(1)
        },
    })

    //async operation to upload the scanned receipt to the server
    const handleUploadReceipt = () => {
        setStep(2)
        const formdata = new FormData()
        formdata.append('file', docFile?.fileBeforeFormData as File)

        if (docFile?.file) {
            createBill(formdata)
        }
        return
    }

    const docRef = useRef<HTMLInputElement>(null)
    return (
        <ModalWrapper
            formId='uploadBillForm'
            shouldConfirmClose
            closeModal={() => closeModal(false)}
            variants={undefined}
        >
            <main className='h-auto w-screen lg:w-[36rem]'>
                {step === 1 && (
                    <>
                        <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                            <span className='flex-1 text-xl font-medium'>Upload invoice</span>
                            <CancelIcon
                                className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                                onClick={() => closeModal(false)}
                            />
                        </section>
                        <div className='p-7'>
                            <div className='mb-2 lg:mb-6'>
                                <h1 className='text-dark font-medium text-lg'>Create bill now</h1>
                                <p className='text-gray7 text-sm'>Upload invoice and create a bill</p>
                            </div>
                            <form id='uploadBillForm' onSubmit={e => e.preventDefault}>
                                <div className='flex flex-col gap-3'>
                                    <div
                                        className={`flex w-full items-center ${
                                            docFile?.file_name ? '' : ' justify-center'
                                        } gap-[128px] border border-dashed border-[#DADCE0] rounded-lg p-4 `}
                                    >
                                        <label htmlFor='first_doc' className='cursor-pointer w-full'>
                                            {!docFile?.file_name && (
                                                <>
                                                    <p className='text-[#A8A8A8] text-base font-medium text-center'>
                                                        Upload invoice
                                                        <br />
                                                        Max 2MB | Accepts JPEG, PNG, and PDF formats
                                                    </p>
                                                </>
                                            )}
                                            {docFile?.file_name && (
                                                <div className='bg-white flex justify-between items-center rounded-lg w-full'>
                                                    <div className='flex items-center gap-4'>
                                                        <div className=''>
                                                            {docFile?.fileType === 'pdf' ? (
                                                                <PDFIcon className='w-[4.5rem] h-24 inline-block rounded-lg' />
                                                            ) : (
                                                                <img
                                                                    src={docFile.fileUrl}
                                                                    alt=''
                                                                    className='w-[4.5rem] h-24 rounded-lg'
                                                                />
                                                            )}
                                                        </div>

                                                        <div className='text-lg font-semibold'>
                                                            <h2 className='text-[#838383] truncate w-[150px] lg:w-auto'>
                                                                {docFile.file_name.slice(0, 20)}.{docFile.fileType}
                                                            </h2>
                                                            <p className='text-[#202020]'>{docFile.size}</p>
                                                        </div>
                                                    </div>

                                                    <span
                                                        className='cursor-pointer'
                                                        onClick={() => setDocFile(fileEmptyState)}
                                                    >
                                                        {
                                                            <CancelIcon className='rounded-full bg-[#F9F0EF]  text-red p-[0.35rem] h-10 w-10 !stroke-[#872D10]' />
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            <input
                                                type='file'
                                                name='file'
                                                hidden
                                                id='first_doc'
                                                ref={docRef}
                                                onChange={e => {
                                                    uploadDocFn(e)
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='flex justify-end gap-6 mt-2 border-t-[1px] border-[#DADCE0] py-2 pr-5 '>
                            <Button
                                className='bg-brand min-w-[100px] px-4'
                                loading={isBillScanned}
                                type='submit'
                                onClick={handleUploadReceipt}
                                disabled={isBillScanned || !docFile?.file_name}
                            >
                                Upload invoice
                            </Button>
                        </div>
                    </>
                )}
                {step === 2 && (
                    <>
                        <div className='w-full h-[24rem] max-w-[100%] overflow-hidden flex flex-col justify-center items-center px-5'>
                            <div className='h-[185px] border-b-2 border-[#A8A8A8] w-[350px] lg:w-[450px] my-8 flex items-center gap-4 overflow-hidden justify-center '>
                                <div className='w-[64px] h-[195px] rounded-t-[1rem] mb-auto bg-[#F5D484] bar-1'></div>
                                <div className='w-[64px] h-[195px] rounded-t-[1rem] mb-auto bg-[#7E82FB] bar-2'></div>
                                <div className='w-[64px] h-[195px] rounded-t-[1rem] mb-auto bg-[#FFAB90] bar-3'></div>
                                <div className='w-[64px] h-[195px] rounded-t-[1rem] mb-auto bg-[#9FDA96] bar-4'></div>
                            </div>

                            <p className='text-center'>Extracting invoice data</p>
                        </div>
                    </>
                )}
            </main>
        </ModalWrapper>
    )
}
