import React, { Dispatch, SetStateAction } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { Button } from '@/components'
import { UserOrgAccount } from '@/types/org'
import { capitalizeText } from '@/utils/string'
import { useRemoveUsersFromBenefits } from '@/services/benefits/mutations'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    handleBeneficiaryRemoval: (id: string) => void
    beneficiary: UserOrgAccount
    closeModal: Dispatch<SetStateAction<boolean>>
    benefit_id: string
}
export default function BeneficiaryRemovalPrompt({ beneficiary, closeModal, benefit_id }: Props) {
    const { mutate: removeUserFn, isLoading: isRemovingUser } = useRemoveUsersFromBenefits({
        queryParams: {
            benefit_id: benefit_id as string,
            account: beneficiary?.id as string,
        },
        onSuccess: () => {
            toastHandler({ state: 'success', message: 'Beneficiary removed' })
            closeModal(false)
        },
    })
    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[37rem] max-h-[55vh] overflow-scroll p-5 px-8'>
                <h2 className='text-[#CF491E] text-2xl mb-5'>Attention</h2>
                <p className='whitespace-pre-wrap mb-5 text-lg font-medium'>
                    Removing{' '}
                    <span className='text-brand'>
                        {' '}
                        {capitalizeText(beneficiary?.user?.first_name)} {capitalizeText(beneficiary?.user?.last_name)}
                    </span>{' '}
                    from this benefit will suspend any benefits they already have. Do you still want to proceed?
                </p>
                <div className='flex justify-end gap-4 mt-10'>
                    <Button variant={'outline'} onClick={() => closeModal(false)}>
                        Cancel
                    </Button>
                    <Button variant={'error'} onClick={() => removeUserFn({})} loading={isRemovingUser}>
                        Yes, remove
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
