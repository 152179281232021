import vaultImg from '@/assets/v2dashboard/vault-init.svg'
import { Button } from '@/components'
import { CreateVault } from '@/components/Modals/Vault/CreateVault'
import { useAuthContext } from '@/context'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'
import { useState } from 'react'

type NoVaultProps = {
    setShowVaultPassword: React.Dispatch<React.SetStateAction<boolean>>
    setVaultId: React.Dispatch<React.SetStateAction<string>>
}
export const NoVaultComponent = ({ setVaultId, setShowVaultPassword }: NoVaultProps) => {
    const [createVault, setCreateVault] = useState(false)
    const { enabled: isVaultEnabled } = useAccessToModule({ module: ModuleNameEnum.VAULT })

    const { setShowUpgradeModal, setCurrentModule } = useAuthContext()

    return (
        <div className='flex items-center justify-center flex-col gap-5 h-full'>
            <img src={vaultImg} alt='' className='mt-10 mb-5' />
            <p className='text-gray7 font-semibold text-[20px] mb-5 max-w-[415px] text-center'>
                You have not activated vault yet. Kindly click the button below to start storing files and folders here
            </p>
            <Button
                onClick={() => {
                    if (!isVaultEnabled) {
                        setShowUpgradeModal(true)
                        setCurrentModule(ModuleNameEnum.VAULT)
                        return
                    }
                    setCreateVault(true)
                }}
            >
                Activate vault
            </Button>
            {createVault && (
                <CreateVault
                    closeModal={setCreateVault}
                    setShowVaultPassword={setShowVaultPassword}
                    setVaultId={setVaultId}
                />
            )}
        </div>
    )
}
