import { Input, SelectTrigger, Select, SelectContent, SelectItem, SelectValue, Textarea, Button } from '@/components'
import { Controller, useFormContext } from 'react-hook-form'
import { BizCompanyComplianceType, kyb } from '../utils'
import { emailRegex } from '@/utils/string'
import { useNavigate } from 'react-router-dom'
import { ErrorText } from './ErrorText'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
}
export const BusinessInfo = ({ setStep }: Props) => {
    const navigate = useNavigate()
    const { control, watch, trigger } = useFormContext<BizCompanyComplianceType>()
    const watchIndustry = watch('industry')
    const watchSubIndustry = watch('sub_industry')

    const subIndustry = kyb.industries.find(industry => industry.title === watchIndustry)
    const handleNext = async () => {
        const isValid = await trigger([
            'legal_business_name',
            'industry',
            'sub_industry',
            'number_of_employees',
            'business_email',
            'business_phone',
            'website',
            'description',
        ])
        if (isValid) {
            setStep(prev => prev + 1)
        }
    }

    return (
        <>
            <section>
                <div className='flex justify-between border-b border-gray5 pb-2 items-center'>
                    <h3 className='font-medium text-md'>Business Information</h3>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2  gap-x-10 gap-5 gap-y-5 mt-4'>
                    <Controller
                        name='legal_business_name'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => {
                            return (
                                <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                    <label
                                        htmlFor={'legal_business_name'}
                                        className='text-gray7 font-normal text-sm mb-1'
                                    >
                                        Legal business name
                                    </label>
                                    <Input
                                        {...field}
                                        placeholder='Enter legal business name'
                                        className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                    />
                                    {errors?.legal_business_name && <ErrorText />}
                                </div>
                            )
                        }}
                    />
                    <Controller
                        name='industry'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                <label htmlFor={'industry'} className='text-gray7 font-normal text-sm mb-1'>
                                    Industry
                                </label>
                                <Select {...field} name={'industry'} onValueChange={field.onChange}>
                                    <SelectTrigger className='h-12 border-gray5 text-sm'>
                                        {!watchIndustry && <span className='text-gray1'>Select Industry</span>}
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        {kyb.industries.map(industry => (
                                            <SelectItem value={industry.title}>{industry.title}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                {errors?.industry && <ErrorText />}
                            </div>
                        )}
                    />

                    {Number(subIndustry?.children?.length) > 0 && (
                        <Controller
                            name='sub_industry'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field, formState: { errors } }) => (
                                <div className='flex-1 flex flex-col gap-1 w-full md:w-auto'>
                                    <label htmlFor={'sub_industry'} className='text-gray7 font-normal text-sm mb-1'>
                                        Sub Industry
                                    </label>
                                    <Select
                                        {...field}
                                        name={'sub_industry'}
                                        onValueChange={field.onChange}
                                        disabled={!watchIndustry}
                                    >
                                        <SelectTrigger className='h-12 border-gray5 text-sm'>
                                            {!watchSubIndustry && (
                                                <span className='text-gray1'>Select sub industry</span>
                                            )}
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                            {subIndustry?.children?.map(sub => (
                                                <SelectItem value={sub}>{sub}</SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    {errors?.sub_industry && <ErrorText />}
                                </div>
                            )}
                        />
                    )}
                    <Controller
                        name='number_of_employees'
                        rules={{
                            required: true,
                            min: 1,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label htmlFor={'number_of_employees'} className='text-gray7 font-normal text-sm mb-1'>
                                    Number of employees
                                </label>
                                <Input
                                    {...field}
                                    type='number'
                                    placeholder='Enter number of employees'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {errors?.number_of_employees && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name='business_email'
                        rules={{
                            required: true,
                            pattern: emailRegex,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label htmlFor={'number_of_employees'} className='text-gray7 font-normal text-sm mb-1'>
                                    Business email address
                                </label>
                                <Input
                                    {...field}
                                    type='email'
                                    placeholder='Enter business email address'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {errors?.business_email && <ErrorText message='Please enter a valid email address.' />}
                            </div>
                        )}
                    />
                    <Controller
                        name='business_phone'
                        rules={{
                            pattern: /^\d{11}$/,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label htmlFor={'number_of_employees'} className='text-gray7 font-normal text-sm mb-1'>
                                    Business phone number
                                </label>
                                <Input
                                    {...field}
                                    type='number'
                                    placeholder='Enter business phone number'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {errors?.business_phone && (
                                    <ErrorText message='Please enter a valid 11-digit number.' />
                                )}
                            </div>
                        )}
                    />
                    <Controller
                        name='website'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label htmlFor={'website'} className='text-gray7 font-normal text-sm mb-1'>
                                    Website url
                                </label>
                                <Input
                                    {...field}
                                    type='text'
                                    placeholder='Enter website url'
                                    className='min-w-full h-12 w-full placeholder:pl-2 border-gray5'
                                />
                                {errors?.website && <ErrorText />}
                            </div>
                        )}
                    />
                    <Controller
                        name='description'
                        rules={{
                            required: true,
                        }}
                        control={control}
                        render={({ field, formState: { errors } }) => (
                            <div className='flex-1 flex flex-col gap-1 w-full md:w-auto relative'>
                                <label htmlFor={'description'} className='text-gray7 font-normal text-sm mb-1'>
                                    Company description
                                </label>

                                <Textarea
                                    {...field}
                                    className='w-full min-w-full resize-none h-12 placeholder:pl-2 border-gray5'
                                    placeholder={`Enter company description`}
                                    name={'description'}
                                    maxLength={120}
                                />
                                {errors?.description && <ErrorText />}
                            </div>
                        )}
                    />
                </div>
            </section>
            <div className='flex items-center justify-end gap-6 mt-10'>
                <Button variant={'outline'} className='min-w-[120px]  whitespace-nowrap ' onClick={() => navigate(-1)}>
                    Back
                </Button>
                <Button onClick={handleNext} className=' whitespace-nowrap min-w-[130px]'>
                    Next
                </Button>
            </div>
        </>
    )
}
