import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, usePayrollContext } from '@/context'
import { useGetOrgBudget } from '@/services/budget/queries'
import { useCreateContract, useUpdateContract } from '@/services/payroll/mutations'
import { Budget } from '@/types/budget'
import { EmployeeType } from '@/types/org'
import { ContractPayrollType, MilestoneType } from '@/types/payroll'
import { formatAmount, formatAmountWithSymbolOfChoice, formatAmountWithoutSymbols } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { motion } from 'framer-motion'
import { AlertCircleIcon } from 'lucide-react'
import React, { useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import { BsPlusSquare } from 'react-icons/bs'
import { TbCurrencyNaira } from 'react-icons/tb'
import { v4 as uuidv4 } from 'uuid'
import ModalWrapper from '../../ModalWrapper'
import MilestoneExtrafield from '../AddToPayroll/Milestone'
import { addToPayrollForContract, defaultMileStone } from '../utils'
import { QueryOrgBudgetType } from '@/services/budget/types'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    orgMembers: EmployeeType[]
    updateContract?: boolean
    openUpdateMileStoneDetails?: boolean
    setUpdateContract?: React.Dispatch<React.SetStateAction<boolean>>
    setUpdateMileStoneDetails?: React.Dispatch<React.SetStateAction<boolean>>
    formProps?: ContractPayrollType
}

export default function AddContractToPayrollModal({
    closeModal,
    orgMembers,
    setUpdateContract,
    updateContract,
    formProps,
    openUpdateMileStoneDetails,
    setUpdateMileStoneDetails,
}: Props) {
    const {
        control,
        watch,
        handleSubmit,
        formState: { isValid },
        getValues,
    } = useForm<ContractPayrollType>({
        defaultValues: {
            contract_title: formProps?.contract_title,
            employee: (formProps?.employee?.id ?? formProps?.employee?._id) as unknown as EmployeeType,
            employment_type: formProps?.employment_type,
            total_pay: formProps?.total_pay,
            start_date: formProps?.start_date
                ? (new Date(formProps?.start_date as string) as unknown as string)
                : undefined,
            end_date: formProps?.end_date ? (new Date(formProps?.end_date as string) as unknown as string) : undefined,
            milestones: formProps?.milestones,
            attact_budget: formProps?.budget?.manager ? 'link' : 'no_link',
            budget: (formProps?.budget?._id ?? formProps?.budget?.id) as unknown as Budget,
        },
    })

    const { selectedAccount } = useAuthContext()
    const { setSelectedContract } = usePayrollContext()
    const calendarRef1 = useRef<DatePicker>(null)
    const calendarRef2 = useRef<DatePicker>(null)

    const [mileStoneDetails, setMileStoneDetails] = useState<MilestoneType[]>(() => {
        return (getValues()?.milestones ?? [])?.map(milestone => {
            milestone.amount = formatAmountWithSymbolOfChoice(formatAmount(milestone?.amount), ['₦', ','])
            return milestone
        })
    })

    // useEffect(()=>{

    // },[mileStoneDetails])

    // const [mileStoneTotalAmount, setMileStomeAmount] = useState(0)
    const mileStoneTotalAmount = 0
    const { mutate: createContractFn, isLoading } = useCreateContract({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess() {
            toastHandler({ message: 'Contract created', state: 'success' })
            closeModal(false)
        },
    })

    const { mutate: updateContractFn, isLoading: isUpdatingContract } = useUpdateContract({
        queryParams: {
            contract_id: formProps?.id as string,
        },
        onSuccess() {
            toastHandler({ message: 'Contract updated', state: 'success' })
            setUpdateContract?.(false)
            closeModal(false)
            setUpdateMileStoneDetails?.(false)
            setSelectedContract(undefined)
        },
    })

    //gets the employee id
    const watchEmployee = watch('employee')
    const { data: org_budget } = useGetOrgBudget({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            // account: watchEmployee as unknown as string,
        },
    })
    const orgBudget = useMemo(() => org_budget ?? [], [org_budget]) as QueryOrgBudgetType
    const selectedBudget = watch('budget')
        ? orgBudget.budgets.find(budget => budget._id === (watch('budget') as unknown as string))
        : undefined
    const totalPay = watch('total_pay')

    const handleCreateContract = (values: any) => {
        const data_to_server = {
            ...values,
            end_date: (values?.end_date as Date).toDateString(),
            start_date: (values?.start_date as Date).toDateString(),
            total_pay: formatAmountWithSymbolOfChoice(formatAmount(values?.total_pay), ['₦', ',']),
            milestones: mileStoneDetails.map(milestone => ({
                amount: milestone.amount,
                due_date: milestone.due_date,
                title: milestone.title,
                payment_status: milestone.payment_status,
                progress_status: milestone.progress_status,
            })),
            employment_type: 'contract', //made this constant since this is what we offer now
            pay_frequency: 'milestones', //made this constant since this is what we offer now
        }

        if (updateContract) {
            updateContractFn(data_to_server)
            return
        }
        // console.log(data_to_server)
        createContractFn(data_to_server)
    }

    const total_pay = watch('total_pay')
    const start_date = watch('start_date')
    const end_date = watch('end_date')
    const checkifMileStoneFieldsAreFilled = mileStoneDetails?.every(
        data => !!data.amount && !!data?.due_date && data?.title
    )

    const watchLinkBudget = watch('attact_budget')

    return (
        <ModalWrapper
            variants={undefined}
            closeModal={() => {
                setUpdateContract?.(false)
                closeModal(false)
                setUpdateMileStoneDetails?.(false)
            }}
            formId='addContractToPayroll'
            shouldConfirmClose
        >
            <form
                onSubmit={handleSubmit(handleCreateContract)}
                id='addContractToPayroll'
                className='w-screen lg:w-[55rem] min-h-[70vh] max-h-[95vh] overflow-scroll relative overflow-x-hidden'
            >
                <div className='border-b border-[#DADCE0] py-4 px-2  '>
                    <div className='flex items-center justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B] text-center flex-1'>
                            {updateContract ? 'Update' : 'New'} Contract
                        </h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer mr-8'
                            onClick={() => {
                                setUpdateContract?.(false)
                                closeModal(false)
                                setUpdateMileStoneDetails?.(false)
                            }}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-x-3 gap-y-5 lg:gap-x-5 lg:gap-5 my-10 mt-5 mb-14 mx-4 lg:mx-6'>
                    {!openUpdateMileStoneDetails &&
                        addToPayrollForContract.map(fieldDetails => {
                            if (fieldDetails?.name === 'budget' && watchLinkBudget !== 'link') return
                            return (
                                <div
                                    className={`flex flex-col flex-1 gap-3 ${
                                        fieldDetails.name === 'contract_title' ? 'col-span-2' : ''
                                    } ${fieldDetails?.name === 'budget' ? 'col-span-2' : ''}`}
                                >
                                    <label htmlFor='firstName' className='text-[#202020] text-[15px] font-medium'>
                                        {fieldDetails.title} <span className='text-[red]'>*</span>
                                    </label>
                                    <Controller
                                        name={fieldDetails.name as keyof ContractPayrollType}
                                        rules={{
                                            required: true,
                                            minLength: 2,
                                        }}
                                        control={control}
                                        render={({ field }) =>
                                            fieldDetails.fieldType === 'dropdown' ? (
                                                <Select
                                                    {...field}
                                                    onValueChange={field.onChange}
                                                    value={field.value as keyof ContractPayrollType}
                                                >
                                                    <SelectTrigger
                                                        className={`h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]`}
                                                    >
                                                        {(field?.value as string)?.length > 0 ? (
                                                            <SelectValue />
                                                        ) : (
                                                            <span>{fieldDetails.placeholder}</span>
                                                        )}
                                                    </SelectTrigger>
                                                    <SelectContent className='min-w-[25rem] max-h-[200px] overflow-scroll'>
                                                        {fieldDetails.name === 'employee'
                                                            ? orgMembers.map(member => (
                                                                  <div className='flex items-center justify-between w-full'>
                                                                      <SelectItem
                                                                          value={member.id}
                                                                          disabled={!member?.bank}
                                                                          className='w-full'
                                                                      >
                                                                          {capitalizeText(member?.user?.first_name)}{' '}
                                                                          {capitalizeText(member?.user?.last_name)}
                                                                      </SelectItem>
                                                                      {!member?.bank && (
                                                                          <TooltipProvider delayDuration={10}>
                                                                              <Tooltip>
                                                                                  <TooltipTrigger className='mr-4'>
                                                                                      <AlertCircleIcon size={15} />
                                                                                  </TooltipTrigger>

                                                                                  <TooltipContent>
                                                                                      <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                                                          User doesn't have a bank added
                                                                                      </p>
                                                                                  </TooltipContent>
                                                                              </Tooltip>
                                                                          </TooltipProvider>
                                                                      )}
                                                                  </div>
                                                              ))
                                                            : fieldDetails?.name === 'budget' &&
                                                                watchLinkBudget === 'link'
                                                              ? orgBudget?.budgets?.map(budget => {
                                                                    const allowedBudget = budget.accounts.filter(
                                                                        account =>
                                                                            account.account ===
                                                                                (watchEmployee as unknown as string) ||
                                                                            account._id ===
                                                                                (watchEmployee as unknown as string)
                                                                    )
                                                                    return (
                                                                        <div className='flex items-center justify-between'>
                                                                            <SelectItem
                                                                                value={budget?._id}
                                                                                className='w-full'
                                                                                disabled={
                                                                                    allowedBudget.length == 0 &&
                                                                                    budget.accounts.length !== 0
                                                                                }
                                                                            >
                                                                                {budget.name} (Balance:{' '}
                                                                                {formatAmount(budget?.available_amount)}
                                                                                )
                                                                            </SelectItem>
                                                                            {allowedBudget.length == 0 &&
                                                                                budget.accounts.length !== 0 && (
                                                                                    <div className=''>
                                                                                        <TooltipProvider
                                                                                            delayDuration={10}
                                                                                        >
                                                                                            <Tooltip>
                                                                                                <TooltipTrigger className='mr-2'>
                                                                                                    <AlertCircleIcon
                                                                                                        size={15}
                                                                                                    />
                                                                                                </TooltipTrigger>

                                                                                                <TooltipContent>
                                                                                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                                                                        This user is not
                                                                                                        part of the
                                                                                                        users of this
                                                                                                        budget
                                                                                                    </p>
                                                                                                </TooltipContent>
                                                                                            </Tooltip>
                                                                                        </TooltipProvider>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    )
                                                                })
                                                              : null}
                                                    </SelectContent>
                                                </Select>
                                            ) : fieldDetails.fieldType === 'date' ? (
                                                <CustomDatePicker
                                                    {...field}
                                                    ref={field?.name === 'start_date' ? calendarRef1 : calendarRef2}
                                                    placeholderText={fieldDetails.placeholder}
                                                    onChange={field.onChange}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    selected={field.value as unknown as Date}
                                                    value={field.value as unknown as string}
                                                />
                                            ) : fieldDetails.fieldType === 'amount' ? (
                                                <div className='flex border border-[#DADCE0] w-full h-[3rem] rounded-lg overflow-hidden bg-white'>
                                                    <span className='flex items-center justify-center text-[#838383] ml-2'>
                                                        <TbCurrencyNaira fontSize={20} />
                                                    </span>
                                                    <input
                                                        {...field}
                                                        type='text'
                                                        placeholder={`Enter Amount`}
                                                        className='outline-none w-[90%] h-full ml-1 pl-2 placeholder:text-sm '
                                                        required
                                                        min={0}
                                                        value={formatAmount(
                                                            field.value as keyof ContractPayrollType
                                                        ).replaceAll('₦', '')}
                                                        onChange={e => {
                                                            const value_entered = formatAmountWithoutSymbols(
                                                                e.target.value
                                                            )
                                                            field.onChange(value_entered)
                                                        }}
                                                    />
                                                </div>
                                            ) : fieldDetails.fieldType === 'radio' ? (
                                                <RadioGroup
                                                    {...field}
                                                    value={field.value as string}
                                                    className='flex gap-8 disabled:opacity-50'
                                                    onValueChange={value => {
                                                        field.onChange(value)
                                                    }}
                                                >
                                                    {fieldDetails?.fieldOptions?.map(details => (
                                                        <label
                                                            htmlFor={details.id}
                                                            className={`flex gap-2 items-center  $ `}
                                                        >
                                                            <RadioGroupItem
                                                                type='button'
                                                                value={details.id}
                                                                id={details.id}
                                                            />
                                                            {details.value}
                                                        </label>
                                                    ))}
                                                </RadioGroup>
                                            ) : (
                                                <Input
                                                    {...field}
                                                    value={field.value as string}
                                                    placeholder={fieldDetails?.placeholder}
                                                    className='border-[#DADCE0] placeholder:text-[#A8A8A8]/90 h-[3rem]'
                                                />
                                            )
                                        }
                                    />
                                </div>
                            )
                        })}
                    {!!totalPay && !!selectedBudget && (
                        <p className='-mt-4 col-span-2 text-sm text-[#bd9436]'>
                            {Number(totalPay) > Number(selectedBudget?.available_amount) &&
                                'The available amount of the selected budget is less than the contract total payment'}
                        </p>
                    )}
                    <h2 className='text-gray1 border-b border-gray4 font-medium pb-2 col-span-2 mt-6'>Milestone</h2>
                    <div className='col-span-2'>
                        <button
                            className={`mb-0 flex gap-2 items-center text-[#5E5E5E] py-3 px-5 rounded-lg border border-[#A8A8A8] text-sm ${
                                mileStoneDetails?.length == 0 ? '' : 'mb-6'
                            }`}
                            type='button'
                            onClick={() => {
                                setMileStoneDetails([
                                    ...mileStoneDetails,
                                    {
                                        ...defaultMileStone,
                                        uniqueId: uuidv4(),
                                    },
                                ])
                            }}
                        >
                            Add {mileStoneDetails?.length > 0 && 'another'} Milestone
                            <BsPlusSquare color='#5E5E5E' />
                        </button>
                        <div
                            className={`flex flex-col gap-x-5 gap-y-1 flex-wrap bg-[#F9F9F9] rounded-xl ${
                                mileStoneDetails?.length == 0 ? '' : 'py-2'
                            }`}
                        >
                            {mileStoneDetails?.map((milestone, idx) => (
                                <MilestoneExtrafield
                                    total_pay={total_pay}
                                    key={idx}
                                    fieldDetail={milestone}
                                    setFieldDetails={setMileStoneDetails}
                                    mileStones={mileStoneDetails}
                                    date_details={{
                                        start_date,
                                        end_date,
                                    }}
                                />
                            ))}
                        </div>
                    </div>

                    <motion.p
                        initial={false}
                        animate={{
                            opacity: Number(mileStoneTotalAmount) > Number(total_pay) ? 1 : 0,
                            x: Number(mileStoneTotalAmount) > Number(total_pay) ? 0 : 100,
                        }}
                        className='bg-[#FFEDED] text-[#5E5E5E] text-sm col-span-2 p-3 rounded-md'
                    >
                        Total milestone amount
                        <span className='font-semibold text-[#202020]'>
                            {' '}
                            ({formatAmount(mileStoneTotalAmount)})
                        </span>{' '}
                        cannot be less than "total pay" amount{' '}
                        <span className='font-semibold text-[#202020]'>({formatAmount(total_pay)})</span>
                    </motion.p>
                </div>
                <div className='flex pt-5 items-center justify-end gap-4 px-6 py-2  fixed bottom-0 right-0 left-0 bg-white w-full rounded-b-lg'>
                    <Button
                        className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-base hover:bg-transparent min-w-[100px]'
                        onClick={() => {
                            setUpdateContract?.(false)
                            closeModal(false)
                            setUpdateMileStoneDetails?.(false)
                        }}
                    >
                        cancel
                    </Button>
                    <Button
                        type='submit'
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        disabled={
                            !isValid ||
                            !checkifMileStoneFieldsAreFilled ||
                            Number(mileStoneTotalAmount) > Number(total_pay) ||
                            mileStoneDetails.length < 1
                        }
                        loading={isLoading || isUpdatingContract}
                    >
                        {updateContract ? 'Update' : 'Create'} Contract
                    </Button>
                </div>
            </form>
        </ModalWrapper>
    )
}
