import { DotIcon, ProfileEditIcon } from '@/assets/assets.export'
import { Button, Input } from '@/components'

import { toastHandler } from '@/components/utils/Toast'
import { useChangePassword } from '@/services/auth/mutations'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useState } from 'react'

export default function UserSecuritySettings() {
    const [showPassword, setShowPassword] = useState(false)
    const [changePassword, setChangePassword] = useState(false)

    const [password, setPassword] = useState({ old_password: '', new_password: '' })

    const { mutate: changePasswordFn, isLoading } = useChangePassword({
        onSuccess: () => {
            toastHandler({ message: 'Password Changed', state: 'success' })
            setChangePassword(false)
        },
    })
    return (
        <>
            <section className='font-medium mr-6 mt-5 text-xl'>
                <h3> Security </h3>
                <p className='text-[#838383] text-sm mt-1'>Manage security: change your passwords</p>
            </section>
            <section className='mr-6 mt-5 border rounded-xl p-3 border-[#DADCE0] pt-4'>
                <div className='flex justify-between'>
                    <span>Password settings</span>

                    {!changePassword && (
                        <span
                            className='flex items-center gap-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-2 border px-4 cursor-pointer'
                            onClick={() => setChangePassword(true)}
                        >
                            <ProfileEditIcon />
                            <button type='button'>Edit</button>
                        </span>
                    )}
                </div>
                {!changePassword && (
                    <div>
                        <span className='text-[#838383] text-sm mb-2 inline-block'>Password</span>
                        <span className='flex gap-1'>
                            {Array(7)
                                .fill('')
                                .map(() => (
                                    <DotIcon className='h-[.7rem] w-[.7rem]' />
                                ))}
                        </span>
                    </div>
                )}
                {changePassword && (
                    <>
                        <div className='mt-4'>
                            <label htmlFor='password' className='text-[#838383] text-sm mb-2 inline-block'>
                                Enter current password
                            </label>
                            <div className='w-[25rem] mt-2'>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    id='password'
                                    onChange={e => setPassword(prev => ({ ...prev, old_password: e.target.value }))}
                                    rightIcon={
                                        showPassword ? (
                                            <EyeOffIcon
                                                onClick={() => setShowPassword(prev => !prev)}
                                                className='cursor-pointer'
                                            />
                                        ) : (
                                            <EyeIcon
                                                onClick={() => setShowPassword(prev => !prev)}
                                                className='cursor-pointer'
                                            />
                                        )
                                    }
                                    className='h-[3rem] border-[#DADCE0]'
                                />
                            </div>
                        </div>
                        <div className='mt-7'>
                            <label htmlFor='password' className='text-[#838383] text-sm mb-2 inline-block'>
                                Enter new password
                            </label>
                            <div className='w-[25rem] mt-2'>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    id='password'
                                    onChange={e => setPassword(prev => ({ ...prev, new_password: e.target.value }))}
                                    rightIcon={
                                        showPassword ? (
                                            <EyeOffIcon
                                                onClick={() => setShowPassword(prev => !prev)}
                                                className='cursor-pointer'
                                            />
                                        ) : (
                                            <EyeIcon
                                                onClick={() => setShowPassword(prev => !prev)}
                                                className='cursor-pointer'
                                            />
                                        )
                                    }
                                    className='h-[3rem] border-[#DADCE0]'
                                />
                            </div>
                        </div>
                        <div className='flex gap-5'>
                            <Button
                                className='bg-transparent text-[#202020] ml-auto mt-6 min-w-[120px] hover:bg-transparent'
                                onClick={() => setChangePassword(false)}
                            >
                                Cancel
                            </Button>

                            <Button
                                className='mt-6 min-w-[120px]'
                                loading={isLoading}
                                onClick={() =>
                                    changePasswordFn({
                                        newPassword: password.new_password,
                                        currentPassword: password.old_password,
                                    })
                                }
                            >
                                Save changes
                            </Button>
                        </div>
                    </>
                )}
            </section>
            {/* <section className='mr-6 mt-5 border rounded-xl p-4 border-[#DADCE0] pt-4'>
                <div className='flex justify-between mb-3'>
                    <span className='font-medium text-xl'>2-Factor Authentication settings</span>
                    <ToggleSwitch />
                </div>
                <div className='flex justify-between text-[#5E5E5E]'>
                    <p className='text-sm w-[65%]'>
                        2-FA adds significantly more security to your account by requiring not only your email and
                        password when signing in, but also a verification code sent to your mobile phone.
                    </p>
                    <span className='font-medium text-sm'>Enable 2-FA</span>
                </div>
            </section> */}
        </>
    )
}
