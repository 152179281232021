import { useState, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import { toastHandler } from '../utils/Toast'

type Props = {
    toggleFor?: string
    checked?: boolean
}

export default function ToggleSwitch({ toggleFor, checked }: Props) {
    const [enabled, setEnabled] = useState(checked)
    const handleToggle = () => {
        if (enabled) {
            toastHandler({ message: 'Cannot toggle off active policy', state: 'error' })
            return
        }
    }
    useEffect(() => {
        setEnabled(checked)
    }, [checked])
    return (
        <Switch
            checked={checked}
            onChange={handleToggle}
            className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                enabled ? 'bg-brightGreen' : 'bg-[#EFEFF9]'
            }`}
        >
            <span className='sr-only'>{toggleFor ?? 'Enable notifications'}</span>
            <span
                className={`${
                    enabled ? 'translate-x-1 bg-white' : 'translate-x-6 bg-white'
                } inline-block h-4 w-4 transform rounded-full  transition`}
            />
        </Switch>
    )
}
