export type filter_by_Props = {
    [key: string]: any
}

export const modalVariants = {
    hidden: {
        opacity: 0,
        x: '100%',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.3,
        },
    },
}

//----------------------------------------------------
export enum InvoiceStatus {
    PAID = 'PAID',
    PARTLY_PAID = 'PARTLY PAID',
    UNPAID = 'UNPAID',
    OVERDUE = 'OVERDUE',
    PENDING = 'PENDING',
    DRAFT = 'DRAFT',
}

const date = new Date()
export const FilterInvoiceUtils = {
    status_types: [
        { name: 'all', value: '' },
        { name: 'paid', value: 'PAID' },
        // { name: 'partly paid', value: 'PARTLY PAID' },
        { name: 'unpaid', value: 'UNPAID' },
        { name: 'overdue', value: 'OVERDUE' },
        { name: 'pending', value: 'PENDING' },
        { name: 'draft', value: 'DRAFT' },
    ],
    periods: [
        {
            period: '24 Hours ago',
            type: '24hrs',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)}`
            },
        },
        {
            period: 'Last 7 days',
            type: '7days',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)}`
            },
        },
        {
            period: 'Last month',
            type: 'last_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 1, date.getDate() - 1)}`
            },
        },
        {
            period: 'Last 6 months',
            type: '6_month',
            getDate: () => {
                return `${new Date(date.getFullYear(), date.getMonth() - 6, date.getDate() - 1)}`
            },
        },
    ],
}

export const filter_consts = {
    transaction_type: 'transaction_type',
    initiated_by: 'initiated_by',
    reimbursable: 'reimbursable',
    initial: 'initial',
    max: 'max',
    min: 'min',
}
