import { useAuthContext } from '@/context'

import { useGetExpenseApprovalPolicies, useGetOrgSettings } from '@/services/settings/queries'
import { SupportedPolicyConsts } from '@/types/approval-policy'
import { EmployeeType, UserOrgAccount } from '@/types/org'
import { useMemo } from 'react'

export const isAdminRoute = () => window.location.pathname.includes('admin')

export const isAdmin = (account: UserOrgAccount) =>
    ['ADMIN', 'OWNER', 'FINANCE_ADMIN'].includes(account?.role?.name ?? 'USER')

//
export const IsAdminWithWalletAccess = () => {
    const { selectedAccount } = useAuthContext()
    let isPriviledgedAdmin = undefined
    const { data: wallet_access_data, isLoading: isLoadingPrivilegedAdmin } = useGetOrgSettings({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
        },
    })

    const walletAccessUsers = useMemo(() => wallet_access_data ?? [], [wallet_access_data])
    isPriviledgedAdmin = walletAccessUsers?.[0]?.wallet_access?.some(
        priviledgedUser => priviledgedUser?.id === selectedAccount?.id
    )
    return { isPriviledgedAdmin, isLoadingPrivilegedAdmin }
}

export const IsOwner = (account?: UserOrgAccount) => {
    const currentAccount = useAuthContext()
    return account ? account?.role?.name === 'OWNER' : currentAccount?.role?.name === 'OWNER'
}

export const IsKYBVerified = () => {
    const { selectedAccount } = useAuthContext()
    return selectedAccount?.org?.kyb_completed
}

export const IsOrgCreator = (account?: UserOrgAccount) => {
    const { selectedAccount } = useAuthContext()
    return account
        ? account?.org?.email === account?.user?.email
        : selectedAccount?.org?.email === selectedAccount?.user?.email
}

export const findOrgCreator = (accounts: EmployeeType[]) => {
    return accounts?.find(account => account?.user?.email === accounts?.[0]?.org?.email)
}

export const useGetIfHasApprovalPolicy = (module: SupportedPolicyConsts, org_id: string) => {
    const { data, isLoading } = useGetExpenseApprovalPolicies({
        queryParams: {
            org_id: org_id,
            module: module,
        },
    })

    return {
        hasPolicy: Number(data?.length) > 0,
        loading: isLoading,
    }
}
