import { createMutation } from '../api/mutation'
import { keyUser } from '../auth/queries'
import {
    getLeaveAnalytics,
    // getLeaveByIdKey,
    getOrgContractPayroll,
    getOrgLeaveSettings,
    getOrgPayrollHistory,
    getuserLeaveHistoryKey,
    orgLeaveRequests,
    orgPayRollByDateKey,
    orgPayRollKey,
    orgPayrollListKey,
    orgPayrollSettingsKey,
} from './queries'

export const useAddEmployeeToPayroll = createMutation({
    url: '/payment-profile',
    method: 'POST',
    keysToRefetch: [orgPayRollKey, keyUser],
})

export const useUpdatePayroll = createMutation({
    url: '/payment-profile',
    method: 'PATCH',
    keysToRefetch: [orgPayRollKey],
})

export const useUpdatePayrollItem = createMutation({
    url: '/update-payroll',
    method: 'PATCH',
    keysToRefetch: [orgPayrollListKey],
})

export const useCreatePayrollFromDoc = createMutation({
    url: '/payment-profiles',
    method: 'POST',
    keysToRefetch: [orgPayRollKey],
})

export const useRunPayroll = createMutation({
    url: '/run-payroll',
    method: 'POST',
    keysToRefetch: [
        orgPayRollKey,
        orgPayRollByDateKey,
        getOrgContractPayroll,
        ['get-org-contract-payroll-by-id'],
        keyUser,
    ],
})
export const useRunManualSalary = createMutation({
    url: '/run-manual-salary',
    method: 'POST',
    keysToRefetch: [orgPayRollKey, orgPayRollByDateKey],
})

export const useGeneratePayrollOtp = createMutation({
    url: '/generate-payroll-otp',
    method: 'POST',
    keysToRefetch: [['eligible-payroll-employees']],
})

export const useUpdatePayrollSettings = createMutation({
    url: '/update-payroll-setting',
    method: 'PATCH',
    keysToRefetch: [orgPayrollSettingsKey],
})

export const useGeneratePayroll = createMutation({
    url: '/generate-payroll-for-org',
    method: 'POST',
    keysToRefetch: [orgPayrollListKey],
})

export const useAssignLeave = createMutation({
    url: '/create-leave',
    method: 'POST',
    keysToRefetch: [orgLeaveRequests],
})

export const useUpdateLeave = createMutation({
    url: '/update-leave',
    method: 'PATCH',
    keysToRefetch: [orgLeaveRequests, ['get-leave-by-id']],
})

export const useCancelLeave = createMutation({
    url: '/cancel-leave',
    method: 'PATCH',
    keysToRefetch: [orgLeaveRequests, ['get-leave-by-id']],
})

export const useDeclineLeave = createMutation({
    url: '/decline-leave',
    method: 'PATCH',
    keysToRefetch: [orgLeaveRequests, ['get-leave-by-id']],
})

export const useRequestLeave = createMutation({
    url: '/request-leave',
    method: 'POST',
    keysToRefetch: [getuserLeaveHistoryKey],
})

export const useUpdateOrgLeaveSettings = createMutation({
    url: '/update-leave-setting',
    method: 'PATCH',
    keysToRefetch: [getOrgLeaveSettings],
})

export const useCreateContract = createMutation({
    url: '/create-contract',
    method: 'POST',
    keysToRefetch: [getOrgContractPayroll],
})

export const useUpdateContract = createMutation({
    url: '/update-contract',
    method: 'PATCH',
    keysToRefetch: [getOrgContractPayroll, ['get-org-contract-payroll-by-id']],
})

export const useMakePaymentContract = createMutation({
    url: '/make-payment-for-contract',
    method: 'POST',
    keysToRefetch: [getOrgContractPayroll, ['get-org-contract-payroll-by-id'], getOrgPayrollHistory],
})

export const useUpdateMilestoneStatus = createMutation({
    url: '/update-milestone-status',
    method: 'PATCH',
    keysToRefetch: [getOrgContractPayroll, ['get-org-contract-payroll-by-id']],
})

export const useDeleteMilestone = createMutation({
    url: '/delete-milestone',
    method: 'DELETE',
    keysToRefetch: [getOrgContractPayroll, ['get-org-contract-payroll-by-id']],
})

export const useTerminateContract = createMutation({
    url: '/terminate-contract',
    method: 'POST',
    keysToRefetch: [getOrgContractPayroll, ['get-org-contract-payroll-by-id']],
})

export const useApproveLeave = createMutation({
    url: 'approve-leave',
    method: 'POST',
    keysToRefetch: [['get-leave-by-id'], getLeaveAnalytics],
})

export const useMilestonePaymentOtp = createMutation({
    url: '/generate-milestone-otp',
    method: 'POST',
})
