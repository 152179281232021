import React, { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { useAuthContext } from '@/context'
import { useGetPayrollSettingsForOrg } from '@/services/payroll/queries'
import { useUpdatePayrollSettings } from '@/services/payroll/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { defaultAdditionProps } from '@/types/payroll'

type Props = {
    // setCustomReason?: React.Dispatch<React.SetStateAction<string>>
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    type: string
}
export default function CustomReasonModal({ closeModal, type }: Props) {
    const [newReason, setNewReason] = useState('')
    const { selectedAccount } = useAuthContext()
    const [deductions, setDeductions] = useState<defaultAdditionProps[]>([])
    const [bonuses, setBonuses] = useState<defaultAdditionProps[]>([])

    // const defaultAdditions = {
    //     // reason: '',
    //     amount: 0,
    //     type: '',
    //     id: uuidv4(),
    //     // amountType: '',
    // }

    const { data: orgPayrollSettings } = useGetPayrollSettingsForOrg({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
        onSuccess() {
            setDeductions(orgPayrollSettings?.default_deductions as defaultAdditionProps[])
            setBonuses(orgPayrollSettings?.default_bonuses as defaultAdditionProps[])
        },
    })

    const { mutate: updatePayrollSettingsFn, isLoading: isUpdating } = useUpdatePayrollSettings({
        queryParams: {
            setting_id: orgPayrollSettings?.id as string,
        },
        onSuccess() {
            closeModal(false)
            toastHandler({ message: 'Reason saved', state: 'success' })
        },
    })

    const handleClick = () => {
        let default_deductions = deductions.map(data => ({
            name: data?.name,
            type: data.type,
            amount: data.type === 'amount' ? Number(data?.amount) / 100 : data.amount,
            org: selectedAccount?.org.id,
        }))
        let default_bonuses = bonuses.map(data => ({
            name: data?.name,
            type: data.type,
            amount: data.type === 'amount' ? Number(data?.amount) / 100 : data.amount,
            org: selectedAccount?.org.id,
        }))
        if (type === 'bonus') {
            default_bonuses = [
                ...bonuses.map(data => ({
                    name: data?.name,
                    type: data.type,
                    amount: data.type === 'amount' ? Number(data?.amount) / 100 : data.amount,
                    org: selectedAccount?.org.id,
                })),
                {
                    // ...defaultAdditions,
                    // id: uuidv4(),
                    name: newReason,
                    type: 'amount',
                    amount: 0,
                    // field_type: 'bonus',
                    org: selectedAccount?.org.id,
                    // reason: newReason,
                },
            ]
        } else {
            default_deductions = [
                ...deductions.map(data => ({
                    name: data?.name,
                    type: data.type,
                    amount: data.type === 'amount' ? Number(data?.amount) / 100 : data.amount,
                    org: selectedAccount?.org.id,
                })),
                {
                    // ...defaultAdditions,
                    // id: uuidv4(),
                    name: newReason,
                    type: 'amount',
                    amount: 0,
                    // field_type: 'deduction',
                    // reason: newReason,
                    org: selectedAccount?.org.id,
                },
            ]
        }

        updatePayrollSettingsFn({ default_deductions, default_bonuses })
        // console.log({ default_deductions, default_bonuses })
    }
    return (
        <ModalWrapper variants={undefined}>
            <div className='w-[30rem] max-h-[30vh]  shadow-xl p-4 text-sm z-50'>
                <h2 className='font-medium text-base'>Add new reason for {type}</h2>
                <div className='flex justify-between mt-3'>
                    <p> Reason *</p>
                    <span>{newReason.length}/30</span>
                </div>
                <div className='mt-2'>
                    <Input
                        className='h-[3rem]'
                        placeholder='E.g equipment damage, loan repayment'
                        onChange={e => {
                            setNewReason(e.target.value)
                        }}
                        maxLength={30}
                    />
                </div>
                <div className='flex justify-end mt-7 gap-4'>
                    <Button
                        className='min-w-[120px]  min-h-[40px]'
                        variant={'outline'}
                        onClick={() => {
                            closeModal(false)
                        }}
                        disabled={isUpdating}
                    >
                        Cancel
                    </Button>

                    <Button
                        className='min-w-[120px] min-h-[40px] disabled:opacity-30'
                        onClick={handleClick}
                        loading={isUpdating}
                        disabled={isUpdating || newReason.length === 0}
                    >
                        Add reason
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
