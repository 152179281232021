import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { format } from 'date-fns'
import { ExpenseType } from '@/types/expense'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { ExpenseFieldProps } from './utils'
import { PDFIcon } from '@/assets/assets.export'
import { CategoryType } from '@/types/categories'
import { useAuthContext } from '@/context'
import { ADMIN_EXPENSE_PERMISSIONS } from '@/utils/permisson-helper'

type Props = {
    setPreview: React.Dispatch<React.SetStateAction<number>>
    formProps: ExpenseFieldProps
    budget?: () => string
    file?: FileUploadFormatProps
    category?: CategoryType
    selectedExpense?: ExpenseType
}

export default function PreviewExpense({ setPreview, formProps, category, budget, file, selectedExpense }: Props) {
    const { selectedAccount } = useAuthContext()
    const orgExpensePermissions = ADMIN_EXPENSE_PERMISSIONS.every(perm =>
        selectedAccount?.role?.permissions?.includes(perm)
    )
    const previewDetails = [
        { name: 'Merchant name', desc: formProps?.merchant },
        { name: 'Expense date', desc: formProps?.date && format(new Date(formProps?.date), 'dd/MM/yyyy') },
        { name: 'Category', desc: category?.label },
        {
            name: 'Amount',

            desc: formatAmount(formatAmountWithoutSymbols(formProps?.amount)),
        },
        { name: 'Receipt', desc: selectedExpense?.receipt ?? file?.file_name ? 'Attached' : 'N/A' },
        {
            name: 'Expense type',
            desc: formProps?.expense_type === 'reimbursable' ? 'Reimbursable' : 'Non-reimbursable',
        },
        { name: 'Budget', desc: budget?.() ?? 'N/A', id: 'budget' },
        {
            name: 'Expense description',
            desc: formProps?.description,
            id: 'description',
        },
    ]

    const checkifReceiptIsPdf = selectedExpense?.receipt?.split('.').slice(-1)[0] === 'pdf'

    return (
        <section className='overflow-scroll mx-6 mt-8'>
            <div className='mb-4 inline-flex items-center cursor-pointer' onClick={() => setPreview(1)}>
                <BsArrowLeft />
                <p className='ml-2'>go back</p>
            </div>
            <div className={`grid grid-cols-2 ${file?.file_name ? 'lg:grid-cols-4' : 'lg:grid-cols-3'} pb-8`}>
                {previewDetails?.map(expenseDetail => {
                    if (!orgExpensePermissions && expenseDetail?.id === 'budget') return
                    if (formProps?.attact_budget === 'no_link' && expenseDetail?.id) return
                    return (
                        <div
                            key={expenseDetail?.id}
                            className={`py-3 px-5  rounded-lg flex flex-col justify-center space-y-2 ${
                                expenseDetail?.id ? 'lg:col-span-3' : ''
                            }`}
                        >
                            <h2 className='text-[#838383]'>{expenseDetail?.name}</h2>
                            <p className='text-lg font-medium'>{expenseDetail?.desc}</p>
                        </div>
                    )
                })}
                {(selectedExpense?.receipt ?? file?.fileUrl) && (
                    <div
                        className={`lg:row-start-1 lg:col-start-3 col-span-2 lg:row-end-4 flex lg:justify-end mb-4 lg:mb-0`}
                    >
                        {file?.fileType || checkifReceiptIsPdf ? (
                            <PDFIcon className='max-w-[8rem] max-h-[8rem]' />
                        ) : (
                            <img src={selectedExpense?.receipt ?? file?.fileUrl} alt='Receipt' />
                        )}
                    </div>
                )}
            </div>
        </section>
    )
}
