import { PayrollListType, PayrollSingleList } from '@/services/payroll/types'
import { Budget } from '@/types/budget'
import React, { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import PayrollProcess1 from './PayrollProcess1'
import PayrollProcess2 from './PayrollProcess2'

type ConfirmationProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    payrollDetails: PayrollListType | PayrollSingleList
    single: boolean
}

export const PayrollProcessRoot = ({ closeModal, payrollDetails, single }: ConfirmationProps) => {
    const [steps, setSteps] = useState(1)
    const [budgetId, setBudgetId] = useState('')
    const [selectedBudget, setSelectedBudget] = useState<Budget>()
    return (
        <ModalWrapper>
            <div>
                {steps === 1 && (
                    <PayrollProcess1
                        budgetId={budgetId}
                        setSteps={setSteps}
                        closeModal={closeModal}
                        setBudgetId={setBudgetId}
                        totalPayrollAmount={single ? payrollDetails?.net_salary : payrollDetails?.summary?.total_payout}
                        selectedBudget={selectedBudget}
                        setSelectedBudget={setSelectedBudget}
                    />
                )}

                {steps === 2 && (
                    <PayrollProcess2
                        setSteps={setSteps}
                        closeModal={closeModal}
                        budgetId={budgetId}
                        payrollDetails={payrollDetails as PayrollListType}
                        single={single}
                    />
                )}
                {/*
                {steps === 3 && (
                    <RequestBudgetIncrease
                        closeModal={closeModal}
                        setSteps={setSteps}
                        selectedBudget={selectedBudget}
                        setSelectedBudget={setSelectedBudget}
                    />
                )} */}
            </div>
        </ModalWrapper>
    )
}
