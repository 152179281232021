import ProgressBar from '@/components/ProgressBar'
import { useGetAllRequestAccess } from '@/services/auth/queries'
import { QueryOrgRequestAccessRes } from '@/services/auth/types'
import { useMemo, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import DirectoryRequestAccessTable from '../components/DirectoryRequestAccessTable'

export default function BusinessDirectoryRequestAccess() {
    const { data, isLoading } = useGetAllRequestAccess()
    const requestAccessData = useMemo(() => data ?? [], [data])
    const [searchQuery, setSearchQuery] = useState('')
    function filterBusinesses(query: string, organizations: QueryOrgRequestAccessRes): QueryOrgRequestAccessRes {
        return organizations.filter(
            org => org.id.includes(query) || org.org_name.toLowerCase().includes(query.toLowerCase())
        )
    }

    const filteredBusinesses = filterBusinesses(searchQuery, requestAccessData)

    if (isLoading) return <ProgressBar />
    return (
        <>
            <section>
                <div className='my-7'>
                    <div className=''>
                        <h1 className='text-xl font-semibold'>Request Access</h1>
                        <p className='text-base text-[#5E5E5E] font-medium mt-2'>Manage Access Requests</p>
                    </div>
                </div>
                <div className='border border-[#DADCE0] h-[56px] w-auto lg:w-[35rem] rounded-lg flex items-center overflow-hidden'>
                    <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                        <CiSearch fontSize={25} />
                    </div>
                    <input
                        type='text'
                        placeholder='Search by Org name'
                        className='flex-1 outline-none bg-[#FAFAFD]'
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className='mt-5 pb-32 overflow-x-scroll'>
                    {<DirectoryRequestAccessTable requestAccessData={filteredBusinesses} />}
                </div>
            </section>
        </>
    )
}
