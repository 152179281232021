import { Tabs, TabsList, TabsTrigger, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

const org_view_route = {
    personal: 'personal',
    company: 'company',
}
export const SwitchOrgInViewMode = () => {
    const orgViewSubroute = location.pathname.includes(org_view_route.personal)
        ? org_view_route.personal
        : org_view_route.company

    const parentRoute = location.pathname.includes('expenses/settings')
        ? '/dashboard/expenses/expense_list'
        : location.pathname.split('/').slice(0, -1).join('/')

    const navigate = useNavigate()
    return (
        <Tabs defaultValue={org_view_route.company} value={orgViewSubroute}>
            <TabsList className=' flex items-center gap-6 lg:w-[93%] border-none '>
                <TabTriggersContainer className='mx-1 lg:mx-auto'>
                    <TabsTrigger
                        value={org_view_route.company}
                        id='company'
                        onClick={() => navigate(`${parentRoute}/${org_view_route.company}`)}
                        className={`relative border-none text-xs whitespace-nowrap`}
                    >
                        {org_view_route.company === orgViewSubroute && (
                            <motion.div
                                layoutId='org_view_tab'
                                className='absolute bottom-0 w-full h-0.5 text-xs bg-[#313131]'
                            />
                        )}
                        <motion.span layout='position' className='z-20 w-full'>
                            Company view
                        </motion.span>
                    </TabsTrigger>
                    <TabsTrigger
                        value={org_view_route.personal}
                        id='personal'
                        onClick={() => navigate(`${parentRoute}/${org_view_route.personal}`)}
                        className={`relative border-none text-xs whitespace-nowrap`}
                    >
                        {org_view_route.personal === orgViewSubroute && (
                            <motion.div
                                layoutId='org_view_tab'
                                className='absolute bottom-0 w-full h-0.5 text-xs bg-[#313131]'
                            />
                        )}
                        <motion.span layout='position' className='z-20 w-full'>
                            Personal view
                        </motion.span>
                    </TabsTrigger>
                </TabTriggersContainer>
            </TabsList>
        </Tabs>
    )
}
