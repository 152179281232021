import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { MuiChipsInput } from 'mui-chips-input'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useAuthContext, useEmployeesContext, useRolesContext } from '@/context'
import { useMutationInviteEmployee } from '@/services/employees/mutations'
import { ArrowLeft } from 'lucide-react'
import { toastHandler } from '@/components/utils/Toast'
import { MultipleInviteMemberSuccesful } from './InviteMemberSuccesful'
import { EmployeeStatus } from '@/types/employee'
import { AnimatePresence, motion } from 'framer-motion'

type inviteProps = { id: number; email_query: string; role: string; employee_type: string }

const emailRegexExp =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export default function MultipleInvite({
    setSelectedTabIndex,
}: {
    setSelectedTabIndex: Dispatch<SetStateAction<number>>
}) {
    const [chips, setChips] = React.useState<string[]>([])
    const [inviteDetails, setInviteDetails] = React.useState<inviteProps[]>([])
    const [confirmEmail, setConfirmEmail] = useState(false)
    const [disableBtn, setDisableBtn] = useState(false)
    const [steps, setSteps] = useState(1)

    const { orgMembers } = useEmployeesContext()
    const { selectedAccount } = useAuthContext()

    const {
        mutate: inviteEmployeesFn,
        isLoading: isEmployeeInvited,
        isSuccess,
    } = useMutationInviteEmployee({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: () => {
            setSelectedTabIndex(1)
            toastHandler({ message: 'Invites sent successfully', state: 'success' })
        },
    })

    useEffect(() => {
        setDisableBtn(
            inviteDetails.every(invite => {
                if (steps === 1) {
                    return emailRegexExp.test(invite.email_query) && invite.role.length > 0
                }
                if (steps === 2) {
                    return (
                        emailRegexExp.test(invite.email_query) &&
                        invite.role.length > 0 &&
                        invite.employee_type.length > 0
                    )
                }
            })
        )
    }, [inviteDetails, steps])

    const handleSendMultipleInvite = () => {
        // if (steps === 1) {
        //     setSteps(2)
        //     return
        // }
        inviteEmployeesFn({
            invite_list: inviteDetails.map(invite => ({ email: invite?.email_query, role: invite?.role })),
        })
    }

    const handleChange = (newChips: string[]) => {
        setChips(newChips)
    }

    const handleInviteDetailsChange = (prop: inviteProps) => {
        setInviteDetails(prev => {
            const previous = prev.filter(previodDetails => previodDetails?.id !== prop?.id)
            if (previous.length > 0) {
                return [
                    ...previous,
                    { id: prop.id, email_query: prop.email_query, role: prop.role, employee_type: prop.employee_type },
                ]
            }
            return [
                // ...prev,
                { id: prop.id, email_query: prop.email_query, role: prop.role, employee_type: prop.employee_type },
            ]
        })
    }

    const formerEmployeeEmails =
        orgMembers
            ?.filter(member => member?.status === EmployeeStatus.DEACTIVATED)
            ?.map(member => member?.email)
            ?.filter(email => chips?.some(chipDetail => chipDetail === email)) ?? []

    if (isSuccess) return <MultipleInviteMemberSuccesful />
    return (
        <section className=' p-5 text-base marker mt-3  mx-auto'>
            {confirmEmail && (
                <>
                    <div>
                        <h2 className='text-[1.2rem] font-normal text-[#202020]/90 mb-2'>
                            Confirm Invitations and Assign Roles
                        </h2>
                        <p className='text-sm text-[#5E5E5E]'>
                            Confirm the email addresses you wish to invite and effortlessly assign roles to each member.
                        </p>
                    </div>

                    <div
                        className={`${formerEmployeeEmails?.length > 0 ? 'mb-0' : 'mb-[6rem]'} flex flex-col gap-5 mt-8`}
                    >
                        {chips.map((email, idx) => (
                            <InviteSelectHandler
                                email={email}
                                index={idx}
                                key={idx}
                                handleChange={handleInviteDetailsChange}
                                step={steps}
                            />
                        ))}
                    </div>
                </>
            )}
            {!confirmEmail && (
                <>
                    <div className=' '>
                        <h2 className='text-base font-semibold text-[#202020]/90 '>Invite Multiple Users</h2>
                    </div>
                    <div className={`${formerEmployeeEmails?.length > 0 ? '' : 'mb-20'}`}>
                        <h2 className='text-base font-medium text-[#202020]/90 mb-3 mt-3'>
                            Enter one or more email addresses
                        </h2>
                        <MuiChipsInput value={chips} onChange={handleChange} className='w-full rounded-2xl' />
                    </div>
                </>
            )}
            <AnimatePresence>
                {formerEmployeeEmails?.length > 0 && (
                    <motion.p
                        initial={{ opacity: 0, y: 10, x: 10 }}
                        animate={{ opacity: 1, y: 0, x: 0 }}
                        exit={{ opacity: 0, y: -10, x: -10 }}
                        transition={{ ease: 'easeInOut', type: 'tween' }}
                        className='text-xs font-medium text-black/80 bg-[#D5D5F5] rounded-xl p-3 mt-5 leading-5 mb-20'
                    >
                        {formerEmployeeEmails?.map(email => (
                            <span className='block text-blue-900' key={email}>
                                {email}
                            </span>
                        ))}
                        {formerEmployeeEmails?.length === 1 &&
                            'This email address belongs to a deactivated user. Inviting them will reactivate their account and they will have access to their financial records based on their previously assigned roles'}
                        {formerEmployeeEmails?.length > 1 &&
                            'These email addresses belong to deactivated users, Inviting them will reactivate their account and they will have access to their financial records based on their previously assigned roles'}
                    </motion.p>
                )}
            </AnimatePresence>

            <div className='shadow-[0_-2px_16px_0px_rgba(0,0,0,0.1)] absolute bottom-0 right-0 left-0 bg-white py-4 rounded-b-xl'>
                <div className='w-[80%] mx-auto'>
                    {!confirmEmail ? (
                        <Button className='ml-auto' onClick={() => setConfirmEmail(true)} disabled={chips.length < 1}>
                            Confirm emails
                        </Button>
                    ) : (
                        <div className='ml-auto flex gap-9'>
                            <button
                                className='flex items-center ml-auto'
                                onClick={() => {
                                    setSteps(1)
                                    setConfirmEmail(false)
                                }}
                                disabled={isEmployeeInvited}
                            >
                                <ArrowLeft />
                                go back
                            </button>
                            <Button
                                className=''
                                onClick={handleSendMultipleInvite}
                                disabled={!disableBtn}
                                loading={isEmployeeInvited}
                            >
                                {steps === 1 ? 'Send Invite(s)' : ' Send Invite(s)'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

const InviteSelectHandler = ({
    email,
    handleChange,
    index,
    step,
}: {
    email: string
    index: number
    step: number
    handleChange: (props: inviteProps) => void
}) => {
    const [inviteQueryText, setInviteQueryText] = useState({ email_query: email, role: '', employee_type: '' })

    useEffect(() => {
        handleChange({ ...inviteQueryText, id: index })
    }, [inviteQueryText.email_query, inviteQueryText.role, inviteQueryText.employee_type])

    const { roles } = useRolesContext()
    return (
        <div className='border border-[#DADCE0] h-[56px]  rounded-lg flex items-center overflow-hidden px-4'>
            <input
                type='email'
                placeholder='Enter invitee email address'
                aria-label='enter invite email'
                className='flex-1 outline-none '
                onChange={e => setInviteQueryText({ ...inviteQueryText, email_query: e.target.value })}
                value={inviteQueryText?.email_query}
            />
            {step == 1 && (
                <Select onValueChange={e => setInviteQueryText({ ...inviteQueryText, role: e })}>
                    <SelectTrigger
                        variant='lg'
                        className={`max-w-[7rem] h-0 py-5 border-0 bg-[#F9F9F9] whitespace-nowrap  ${
                            !inviteQueryText?.role ? 'text-[#A8A8A8]' : 'text-[#202020]'
                        }`}
                    >
                        <SelectValue placeholder='role' />
                    </SelectTrigger>
                    <SelectContent className='w-max'>
                        {roles
                            .filter(role => role.name !== 'OWNER')
                            .map(role => (
                                <SelectItem value={role.id} key={role.id}>
                                    {role.name === 'EMPLOYEE' ? (
                                        'User'
                                    ) : (
                                        <>
                                            {role.name[0]}
                                            {role.name.toLowerCase().slice(1)}
                                        </>
                                    )}
                                </SelectItem>
                            ))}
                    </SelectContent>
                </Select>
            )}

            {/* {step == 2 && (
                <Select
                    onValueChange={e => {
                        setInviteQueryText({ ...inviteQueryText, employee_type: e })
                    }}
                >
                    <SelectTrigger
                        variant='lg'
                        className={`w-fit h-0 py-5 border-0 bg-[#F9F9F9]  ${
                            !inviteQueryText?.employee_type ? 'text-[#A8A8A8]' : 'text-[#202020]'
                        }`}
                    >
                        <SelectValue placeholder='User type' />
                    </SelectTrigger>

                    <SelectContent className='w-fit'>
                        <SelectItem value={'contract'}>Contract</SelectItem>
                        <SelectItem value={'user'}>User</SelectItem>
                    </SelectContent>
                </Select>
            )} */}
        </div>
    )
}
