import { formatAmount } from '@/utils/money'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import PayHistoryTransactions from './PayHistoryTransactions'
import { useAuthContext, usePayrollContext } from '@/context'
import { format } from 'date-fns'
import { useGetPyrollByPeriod } from '@/services/payroll/queries'
import ProgressBar from '@/components/ProgressBar'
import { PaymentProfile } from '@/types/payroll'
import { TransactionType } from '@/types/transactions'
import SalariesTable from './SalariesTable'

export const PayHistoryDetails = () => {
    const navigate = useNavigate()
    const [failedAlert, setFailedAlert] = useState(false)
    const { selectedAccount } = useAuthContext()

    const { selectedPayHistory } = usePayrollContext()

    const [searchParams] = useSearchParams()
    const start_date = searchParams.get('start')
    const end_date = searchParams.get('end')
    const pay_date = searchParams.get('pay_date')

    const {
        data: payHistoryDetails,
        isLoading,
        isRefetching,
    } = useGetPyrollByPeriod({
        enabled: !!start_date && !!end_date,
        queryParams: {
            org_id: selectedAccount?.org.id as string,
            start: start_date as string,
            end: end_date as string,
            pay_date: pay_date as string,
        },
    })

    const transaction_details = [
        { name: 'Users', desc: selectedPayHistory?.employees_paid },
        {
            name: 'Total salary',
            desc: formatAmount(payHistoryDetails?.total_salaries),
        },
        { name: 'Total payout', desc: formatAmount(selectedPayHistory?.total_payout) },

        {
            name: 'Pay day',
            desc: selectedPayHistory?.pay_date && format(new Date(selectedPayHistory?.pay_date), 'dd/MM/yyyy'),
        },
        {
            name: 'Status',
            desc: 'All Paid',
        },
        {
            name: 'Pay period',
            desc: (
                <>
                    Pay period:{' '}
                    <span>
                        {selectedPayHistory?.current_period?.start &&
                            format(new Date(selectedPayHistory?.current_period?.start as string), 'dd/MM/yyyy')}
                    </span>
                    {'  '} -{'  '}
                    <span>
                        {selectedPayHistory?.current_period?.end &&
                            format(new Date(selectedPayHistory?.current_period?.end as string), 'dd/MM/yyyy')}
                    </span>
                </>
            ),
        },
        {
            name: 'Linked budget',
            desc: <>{selectedPayHistory?.budget?.name ?? 'N/A'}</>,
        },
    ]
    if (isLoading || isRefetching) return <ProgressBar />

    return (
        <div>
            <div className='bg-[#F9F9F9] py-[30px] px-[40px] mb-[80px]'>
                <div className='mb-8 inline-flex items-center cursor-pointer' onClick={() => navigate(-1)}>
                    <BsArrowLeft />
                    <p className='ml-2 font-semibold text-lg'>back</p>
                </div>
                <section className=' bg-white rounded-xl px-7 py-6 mb-4'>
                    <div className='border-b border-[#DADCE0] pb-3 flex items-center justify-between'>
                        <h2 className=' font-semibold text-xl'>Pay history</h2>
                    </div>
                    <div className='flex flex-wrap gap-x-[5rem] mt-8 gap-y-4'>
                        {transaction_details.map((transaction, idx) => (
                            <>
                                <div className='flex flex-col min-w-[5rem]' key={idx}>
                                    <h4 className='text-md font-medium'>{transaction.name}</h4>
                                    <span className='text-[#5E5E5E] text-[1rem]'>{transaction.desc}</span>
                                </div>
                                {idx == 3 && <p className='w-full'></p>}
                                {idx == 5 && <p className='w-full'></p>}
                            </>
                        ))}
                    </div>
                </section>
                {failedAlert && (
                    <div className='bg-[#FFEDED] p-3 rounded-lg flex justify-between items-center'>
                        {' '}
                        <div className='text-sm'>
                            <p className='font-semibold'>Note</p>
                            <p>
                                Some salaries were not paid due to failed transactions. Click{' '}
                                <span className='text-brand font-semibold cursor-pointer'>here</span> to see them.
                            </p>
                        </div>
                        <button className='font-medium' onClick={() => setFailedAlert(false)}>
                            Dismiss
                        </button>
                    </div>
                )}
                <section className=' bg-white rounded-xl px-7 py-6 my-8'>
                    <h2 className=' font-semibold text-xl border-b border-[#DADCE0] pb-3'>Salaries for this payroll</h2>
                    <SalariesTable salaries={payHistoryDetails?.payroll_items as PaymentProfile[]} />
                </section>

                <section className=' bg-white rounded-xl px-7 py-6 mt-8 mb-24'>
                    <h2 className=' font-semibold text-xl border-b border-[#DADCE0] pb-3'>Linked transactions</h2>
                    <PayHistoryTransactions transactions={payHistoryDetails?.transactions as TransactionType[]} />
                </section>
            </div>
        </div>
    )
}
