import React, { ReactNode } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { LucideIcon, MoreHorizontal } from 'lucide-react'

type TableActionProps = {
    rotate?: boolean
    actions: {
        label: string | ReactNode
        icon?: LucideIcon
        method: (arg?: unknown) => void
        hidden?: boolean
        actionColor?: string
    }[]
}

const TableActions: React.FC<TableActionProps> = ({ actions, rotate }: TableActionProps) => {
    return (
        <div onClick={e => e.stopPropagation()}>
            <Menu as='div' className='relative inline-block text-left'>
                {/* Dropdown trigger */}
                <Menu.Button className='inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-[#A8A8A8] hover:bg-gray-50 focus:outline-none focus:ring-offset-gray-100'>
                    <MoreHorizontal className={`h-5 w-5 ${rotate ? 'rotate-90' : ''}`} aria-hidden='true' />
                </Menu.Button>
                {/* Dropdown menu */}

                <Transition
                    as={React.Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    <Menu.Items className='origin-top-left -top-12 z-[100] absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <div>
                            {actions.map((action, idx) => (
                                <Menu.Item key={idx}>
                                    {({ active }) => (
                                        <button
                                            className={`${
                                                active ? 'bg-accent text-gray' : 'text-gray-700'
                                            } group flex items-center w-full px-4 py-3 text-sm font-medium  ${
                                                action.hidden ? 'hidden' : 'inline-block'
                                            } ${action.actionColor ?? ''}
`}
                                            onClick={e => {
                                                e.stopPropagation()
                                                action.method()
                                            }}
                                        >
                                            {action.icon ? (
                                                <action.icon
                                                    className={`mr-3 h-5 w-5 ${
                                                        active ? 'text-accent' : 'text-gray-400'
                                                    }`}
                                                    aria-hidden='true'
                                                />
                                            ) : (
                                                ''
                                            )}
                                            {action.label}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default TableActions
