import { PaymentProfile } from '@/types/payroll'
import ModalWrapper from '../../ModalWrapper'
import { Button } from '@/components/Button'
import { useUpdatePayroll } from '@/services/payroll/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'

interface ActivePaymentProfileProps {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    payrollDetails?: PaymentProfile
}

export default function MakePaymentProfileActive({ closeModal, payrollDetails }: ActivePaymentProfileProps) {
    const { selectedAccount } = useAuthContext()

    const { mutate: updatePayrollFn, isLoading: isUpdatingPayroll } = useUpdatePayroll({
        queryParams: { payroll_id: (payrollDetails?.id ?? payrollDetails?._id) as string },
        onSuccess() {
            closeModal(false)
            toastHandler({
                message: payrollDetails?.active ? 'Payment profile deactivated' : 'Payment profile activated',
                state: 'success',
            })
        },
    })
    const userName = payrollDetails?.employee.user.first_name + ' ' + payrollDetails?.employee.user.last_name
    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-screen lg:w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>
                    Are you sure you want to {payrollDetails?.active ? 'deactivate' : 'activate'} the payment profile of{' '}
                    <span className='text-brand font-semibold'> {userName}</span>
                </p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isUpdatingPayroll}
                        onClick={() => {
                            updatePayrollFn({
                                active: !payrollDetails?.active,
                                org: selectedAccount?.org.id,
                            })
                        }}
                    >
                        Yes, {payrollDetails?.active ? 'Deactivate' : 'Activate'} Payment Profile
                    </Button>
                    <button type='button' className='min-h-[50px] px-5 mx-3' onClick={() => closeModal(false)}>
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}
