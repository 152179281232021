import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import UserContractPayProfile from '../Contract/partials/ContractPayroll/UserContractPayProfile'
import UserContractPayHistory from './partials/UserContractPayHistory'

const contract_routes_const = {
    my_contracts: 'contract_list',

    pay_history: 'pay_history',
}
export default function UserContract() {
    const { contract_subRoute } = useParams()
    const navigate = useNavigate()

    const navigateRoute = (path: string) => {
        const queryParams = location.search
        navigate(`/dashboard/contract/${path}${queryParams}`)
    }

    return (
        <section className='h-full bg-white px-2 md:px-0'>
            <section className='mt-5'>
                <Tabs defaultValue={contract_subRoute} value={contract_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            <TabsTrigger
                                value={contract_routes_const.my_contracts}
                                onClick={() => navigateRoute(`${contract_routes_const.my_contracts}`)}
                                className={`relative border-none ${
                                    contract_routes_const.my_contracts === contract_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {contract_routes_const.my_contracts === contract_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    My Contracts
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={contract_routes_const.pay_history}
                                onClick={() => navigateRoute(`${contract_routes_const.pay_history}`)}
                                className={`relative border-none ${
                                    contract_routes_const.pay_history === contract_subRoute ? '!text-white' : ''
                                } text-sm`}
                            >
                                {contract_routes_const.pay_history === contract_subRoute && (
                                    <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                )}
                                <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                    Pay history
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>

                    <TabsContent value={contract_routes_const.pay_history}>
                        <PageContent>
                            <UserContractPayHistory />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={contract_routes_const.my_contracts}>
                        <PageContent>
                            <UserContractPayProfile />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
        </section>
    )
}
