import { advancedFilteringProps } from '@/modules/dashboard/Employees/const'
import { memberAdvancedFilteringProps } from '@/modules/dashboard/Expenses/const'
import { TeamsProp, formProps } from '@/types/employee'
import { EmployeeType } from '@/types/org'
import React, { createContext, useState } from 'react'
// import { ColumnSort } from '@tanstack/react-table'

type inviteQueryProps = { role: string; email_query: string }

type filterTeamProps = 'bySearch' | 'bySort'
type filterOrgMembersProps = 'bySearch' | 'bySort'
type SortColumn = { id: string | undefined; desc: boolean | undefined }

type numbersInEmployeeProps = { teams?: number; members?: number; pendingInvites?: number; current_team_size?: number }

export type defaultExpenseProps = {
    inviteQueryText: inviteQueryProps
    setInviteQueryText: React.Dispatch<React.SetStateAction<inviteQueryProps>>
    showTeamBreadCrumbs: boolean | undefined
    setShowTeamBreadCrumbs: React.Dispatch<React.SetStateAction<boolean | undefined>>
    teamsObj: TeamsProp | undefined
    setTeamsObj: React.Dispatch<React.SetStateAction<TeamsProp | undefined>>
    amountOfEmployee: numbersInEmployeeProps | undefined
    setAmountOfEmployee: React.Dispatch<React.SetStateAction<numbersInEmployeeProps | undefined>>
    setOrgMembers: React.Dispatch<React.SetStateAction<EmployeeType[] | undefined>>
    orgMembers: EmployeeType[] | undefined
    setPendingOrgMembers: React.Dispatch<React.SetStateAction<EmployeeType[] | undefined>>
    pendingOrgMembers: EmployeeType[] | undefined
    totalTeams: TeamsProp[] | undefined
    setTotalTeams: React.Dispatch<React.SetStateAction<TeamsProp[] | undefined>>
    setFilterTeamsBy: React.Dispatch<
        React.SetStateAction<Partial<Record<filterTeamProps, any> | undefined> | undefined>
    >
    filterOrgMembersBy: Partial<Record<filterOrgMembersProps, any> | undefined> | undefined
    setFilterOrgMembersBy: React.Dispatch<
        React.SetStateAction<Partial<Record<filterOrgMembersProps, any> | undefined> | undefined>
    >
    filterTeamsBy: Partial<Record<filterTeamProps, any> | undefined> | undefined
    sort: SortColumn | undefined
    setSort: React.Dispatch<React.SetStateAction<SortColumn | undefined>>
    filterEmployee: formProps
    setFilterEmployee: React.Dispatch<React.SetStateAction<formProps>>
}

const defaultEmployeesState: defaultExpenseProps = {
    inviteQueryText: { role: '', email_query: '' },
    setInviteQueryText: () => undefined,
    showTeamBreadCrumbs: undefined,
    setShowTeamBreadCrumbs: () => undefined,
    teamsObj: undefined,
    setTeamsObj: () => undefined,
    totalTeams: undefined,
    setTotalTeams: () => undefined,
    amountOfEmployee: undefined,
    setAmountOfEmployee: () => undefined,
    setOrgMembers: () => undefined,
    orgMembers: undefined,
    setPendingOrgMembers: () => undefined,
    pendingOrgMembers: undefined,
    filterTeamsBy: undefined,
    setFilterTeamsBy: () => undefined,
    filterOrgMembersBy: undefined,
    setFilterOrgMembersBy: () => undefined,
    sort: undefined,
    setSort: () => undefined,
    filterEmployee: memberAdvancedFilteringProps,
    setFilterEmployee: () => undefined,
}

export const EmployeesContext = createContext(defaultEmployeesState)

export const EmployeesContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [inviteQueryText, setInviteQueryText] = useState<inviteQueryProps>({
        role: '',
        email_query: '',
    })

    const [showTeamBreadCrumbs, setShowTeamBreadCrumbs] = useState<boolean>()
    const [amountOfEmployee, setAmountOfEmployee] = useState<numbersInEmployeeProps>()
    const [orgMembers, setOrgMembers] = useState<EmployeeType[]>()
    const [pendingOrgMembers, setPendingOrgMembers] = useState<EmployeeType[]>()
    const [totalTeams, setTotalTeams] = useState<TeamsProp[]>()
    const [teamsObj, setTeamsObj] = useState<TeamsProp>()
    const [filterTeamsBy, setFilterTeamsBy] = useState<Partial<Record<filterTeamProps, any> | undefined>>()
    const [filterOrgMembersBy, setFilterOrgMembersBy] =
        useState<Partial<Record<filterOrgMembersProps, any> | undefined>>()
    const [sort, setSort] = useState<SortColumn>()
    const [filterEmployee, setFilterEmployee] = useState<formProps>(advancedFilteringProps)

    const value = {
        inviteQueryText,
        setInviteQueryText,
        showTeamBreadCrumbs,
        setShowTeamBreadCrumbs,
        teamsObj,
        setTeamsObj,
        amountOfEmployee,
        setAmountOfEmployee,
        orgMembers,
        setOrgMembers,
        pendingOrgMembers,
        setPendingOrgMembers,
        totalTeams,
        setTotalTeams,
        filterTeamsBy,
        setFilterTeamsBy,
        filterOrgMembersBy,
        setFilterOrgMembersBy,
        sort,
        setSort,
        filterEmployee,
        setFilterEmployee,
    }
    return <EmployeesContext.Provider value={value}>{children}</EmployeesContext.Provider>
}
