import { Button } from '@/components'
import EditExistingVendor from '@/components/Modals/Vendors/EditVendorModal/EditVendorModal'
import ProgressBar from '@/components/ProgressBar'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, useVendorContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetListOfBanks } from '@/services/payout/queries'
import { useMutationActivateVendor, useMutationSuspendVendor } from '@/services/vendors/mutations'
import { useGetOrgVendor } from '@/services/vendors/queries'
import { Vendor } from '@/types/vendor'
// import downArrow from '@/assets/down-arrow.svg'
import sendUp from '@/assets/direct-up.svg'
import { format } from 'date-fns'
import { ArrowLeft } from 'lucide-react'
import { useMemo, useState } from 'react'
import { Menu } from '@headlessui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalizeText } from '@/utils/string'
import TransferVendorModal from '@/components/Modals/Vendors/TransferToVendor/TransferToVendorModal'
import { useGetOrgCategories } from '@/services/categories/queries'

export default function VendorDetails() {
    const [openModal, setOpenModal] = useState(false)
    const [openTransferModal, setOpenTransferModal] = useState(false)
    const navigate = useNavigate()
    const { selectedVendor } = useVendorContext()
    const { selectedAccount } = useAuthContext()
    const { vendor_id } = useParams()

    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })

    const { mutate: suspendVendor, isLoading: isSuspendVendorLoading } = useMutationSuspendVendor({
        queryParams: {
            vendor_id: vendor_id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'vendor suspended', state: 'success' })
        },
    })

    const { mutate: activateVendor, isLoading: isActivateVendorLoading } = useMutationActivateVendor({
        queryParams: {
            vendor_id: vendor_id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'vendor unsuspended', state: 'success' })
        },
    })

    const handleActivateVendor = () => {
        activateVendor({})
    }
    const handleSuspendVendor = () => {
        suspendVendor({})
    }

    const orgMembers = useMemo(() => employees ?? [], [employees])

    const { data: org_categories } = useGetOrgCategories()
    const categories = useMemo(() => org_categories ?? [], [org_categories])

    const { data: bank_details } = useGetListOfBanks()
    const allBankList = useMemo(() => bank_details ?? [], [bank_details])

    const { data, isLoading } = useGetOrgVendor({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            id: vendor_id as string,
        },
    })
    const vendorDetails = useMemo(
        () =>
            data ?? {
                ...selectedVendor,
            },
        [data, selectedVendor]
    )
    const { bankAccount, category, description, createdAt, email, name, owner, status } = vendorDetails

    const vendor_details = [
        { name: 'Vendor name', desc: name },
        { name: 'Category', desc: category?.label },
        { name: 'Email address', desc: email },
        {
            name: 'Vendor owner',
            desc: `${owner?.user?.first_name} ${owner?.user?.last_name}`,
        },
        {
            name: 'Created',
            desc:
                (selectedVendor?.createdAt || vendorDetails?.createdAt) &&
                format(new Date((selectedVendor?.createdAt as string) ?? (createdAt as string)), 'dd/MM/yyyy'),
        },
        {
            name: 'Status',

            desc: (
                <span
                    className={`${
                        selectedVendor?.status === 'SUSPENDED' || status === 'SUSPENDED'
                            ? 'text-errorRed'
                            : selectedVendor?.status === 'ACTIVE' || status === 'ACTIVE'
                              ? 'text-[#19C37D]'
                              : 'text-[#A8A8A8]/90'
                    }`}
                >
                    {capitalizeText(status)}
                </span>
            ),
        },
        { name: 'Account name', desc: bankAccount?.bank_name },
        { name: 'Account number', desc: bankAccount?.account_number },
        // { name: 'Account name', desc: bankAccount?.account },
    ]

    if (isLoading && !selectedVendor) return <ProgressBar />
    return (
        <section className='mx-[1.2rem] lg:mx-[2.9rem] p-3 pt-2 px-0'>
            <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                <ArrowLeft />
                back
            </div>
            <section className=' bg-white rounded-xl p-7 py-6 '>
                <div className='border-b border-[#DADCE0] pb-3 flex items-center justify-between'>
                    <h2 className=' font-semibold text-xl'>{name ?? selectedVendor?.name}</h2>
                    <Menu as='div' className='relative'>
                        <Menu.Button className=''>
                            <p className='text-[#6E72FF] font-medium'>More options</p>
                        </Menu.Button>
                        <Menu.Items className='w-[260px] flex flex-col absolute right-0 py-[13px] px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                            {
                                <Menu.Item>
                                    <span
                                        className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2'
                                        onClick={() => {
                                            if (selectedVendor?.status === 'SUSPENDED') {
                                                toastHandler({
                                                    message: 'Cannot pay a suspended vendor',
                                                    state: 'error',
                                                })
                                                return
                                            }
                                            setOpenTransferModal(true)
                                        }}
                                    >
                                        <div className='h-[32px] w-[32px] border border-[#DADCE0] rounded-[5px] flex items-center justify-center'>
                                            <img src={sendUp} alt='button' />
                                        </div>
                                        Send money
                                    </span>
                                </Menu.Item>
                            }
                            {/* <Menu.Item>
                                <span className='px-4 py-2 cursor-pointer flex items-center gap-2 hover:bg-[#f3f3f3]'>
                                    <div className='h-[32px] w-[32px] border border-[#DADCE0] rounded-[5px] flex items-center justify-center p-1 '>
                                        <img src={downArrow} alt='button' />
                                    </div>
                                    Download vendor details
                                </span>
                            </Menu.Item> */}
                        </Menu.Items>
                    </Menu>
                </div>
                <div className='mt-3'>
                    <h4 className='font-medium text-md mb-1'>Vendor description</h4>
                    <p className='text-[#5E5E5E] text-sm'>
                        {description ?? selectedVendor?.description}
                        {/* <span className='text-[#454ADE] cursor-pointer ml-1'>View more</span> */}
                    </p>
                </div>
                <div className='flex flex-wrap max-w-[60rem] gap-x-[5rem] mt-8 gap-y-[1.3rem]'>
                    {vendor_details.map((vendor, idx) => {
                        const { desc, name } = vendor
                        return (
                            <div className='flex flex-col w-[10rem]' key={idx}>
                                <h4 className='text-md font-medium'>{name}</h4>
                                <span className='truncate'>{desc}</span>
                            </div>
                        )
                    })}
                </div>
                <div className='flex justify-end max-w-[53rem] gap-4 mt-7'>
                    <Button
                        className='bg-transparent hover:bg-transparent text-[#202020] disabled:bg-transparent'
                        onClick={() => {
                            setOpenModal(true)
                        }}
                        disabled={isSuspendVendorLoading || isActivateVendorLoading}
                    >
                        Edit
                    </Button>
                    {selectedVendor?.status === 'SUSPENDED' || status === 'SUSPENDED' ? (
                        <Button
                            className='bg-[#5E5E5E] hover:bg-[#5E5E5E]/70'
                            onClick={handleActivateVendor}
                            loading={isActivateVendorLoading}
                        >
                            Unsuspend
                        </Button>
                    ) : (
                        <Button
                            className='bg-[#5E5E5E] hover:bg-[#5E5E5E]/70'
                            onClick={handleSuspendVendor}
                            loading={isSuspendVendorLoading}
                        >
                            Suspend
                        </Button>
                    )}
                </div>
            </section>
            {openModal && (
                <EditExistingVendor
                    allBankList={allBankList}
                    categories={categories}
                    orgMembers={orgMembers}
                    setCloseModal={setOpenModal}
                    vendor_details={data ?? selectedVendor}
                />
            )}
            {openTransferModal && (
                <TransferVendorModal closeModal={setOpenTransferModal} vendorDetails={vendorDetails as Vendor} />
            )}
        </section>
    )
}
