import { useEffect, useMemo, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useAuthContext, useBillPaymentContext } from '@/context'
import { LinkedBillTransactionsTable } from '../../components/LinkedBillTransactionsTable'
import { useGetBill, useGetScheduledBill } from '@/services/bill-payment/queries'
import ProgressBar from '@/components/ProgressBar'
import { BillType } from '@/services/bill-payment/types'
import CreateNewVendor from '@/components/Modals/Vendors/CreateNewVendor/CreateNewVendor'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetOrgCategories } from '@/services/categories/queries'
import { useGetListOfBanks } from '@/services/payout/queries'
import BillDetailsTimeLine from '../../components/BillPaymentTimeline'
import { BillDetails } from '../../components/BillDetails'

export const BillPaymentDetails = () => {
    const navigate = useNavigate()
    const [showTimeLine, setShowTimeline] = useState(false)
    const [addVendor, setAddVendor] = useState(false)

    const { setSelectedBillRequest, selectedBillRequest } = useBillPaymentContext()
    const { selectedAccount } = useAuthContext()
    const { bill_id } = useParams()

    const {
        data: bill_details,
        isLoading,
        isRefetching,
    } = useGetBill({
        queryParams: {
            id: bill_id as string,
        },
    })
    const billDetails = useMemo(() => bill_details ?? selectedBillRequest, [bill_details])

    const { isLoading: isLoadingSchedule } = useGetScheduledBill({
        queryParams: {
            id: (bill_details?._id ?? bill_details?.id) as string,
        },
        enabled: bill_details?.status === 'SCHEDULED',
    })

    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])

    const { data: org_categories } = useGetOrgCategories()
    const categories = useMemo(() => org_categories ?? [], [org_categories])

    const { data: bank_details } = useGetListOfBanks()
    const allBankList = useMemo(() => bank_details ?? [], [bank_details])

    useEffect(() => {
        setSelectedBillRequest(billDetails)
    }, [bill_details])

    if (isLoading || isRefetching || (billDetails?.status === 'SCHEDULED' ? isLoadingSchedule : false))
        return <ProgressBar />

    return (
        <div>
            <div className='bg-[#F9F9F9] py-[30px] px-[20px] lg:px-[40px] mb-[80px]'>
                <div className='mb-8 inline-flex items-center cursor-pointer' onClick={() => navigate(-1)}>
                    <BsArrowLeft />
                    <p className='ml-2 font-semibold text-lg'>back</p>
                </div>
                <>
                    <div className='bg-white px-5 py-6 mb-4 rounded-xl'>
                        <div className='bg-[#F9F9F9] flex gap-4 p-2 w-[11.2rem] rounded-lg mb-6'>
                            <div
                                className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#D5D5F5] px-2 py-1 rounded cursor-pointer relative `}
                                onClick={() => setShowTimeline(false)}
                            >
                                {!showTimeLine && (
                                    <motion.span
                                        layoutId='expense_timeline__animation'
                                        className='bg-[#D5D5F5] rounded-md z-0 text-[#202020] inset-0 absolute inline-block w-full h-full '
                                    />
                                )}
                                <motion.span layout='position' className='z-20 sticky'>
                                    Details
                                </motion.span>
                            </div>
                            <div
                                className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#D5D5F5] px-2 py-1 rounded cursor-pointer relative w-full`}
                                onClick={() => setShowTimeline(true)}
                            >
                                {showTimeLine && (
                                    <motion.span
                                        layoutId='expense_timeline__animation'
                                        className='bg-[#D5D5F5] rounded-md  text-[#202020] inset-0 absolute inline-block w-full h-full '
                                    />
                                )}
                                <motion.span
                                    layout='position'
                                    className='text-[#202020] inset-0 absolute inline-block w-full h-full px-2 py-1'
                                >
                                    Timeline
                                </motion.span>
                            </div>
                        </div>

                        {!showTimeLine && (
                            <BillDetails billDetails={billDetails as BillType} setAddVendor={setAddVendor} />
                        )}
                        {showTimeLine && <BillDetailsTimeLine bill={billDetails!} />}
                    </div>
                    {!showTimeLine && (
                        <>
                            <div className='p-3 lg:p-5 bg-white rounded-xl mb-12'>
                                <h1 className='pl-5 border-b border-[#DADCE0] text-lg text-[#202020] py-1'>
                                    Linked transaction
                                </h1>
                                {<LinkedBillTransactionsTable transactions={billDetails?.transactions ?? []} />}
                            </div>
                            <div className='p-3 lg:p-5 bg-white rounded-xl mb-12 hidden'>
                                <h1 className='pl-5 border-b border-[#DADCE0] text-lg text-[#202020] py-1'>
                                    Linked Expense(s)
                                </h1>
                                {<LinkedBillTransactionsTable transactions={[]} />}
                            </div>
                        </>
                    )}
                </>
            </div>
            {addVendor && (
                <CreateNewVendor
                    setCloseModal={setAddVendor}
                    orgMembers={orgMembers}
                    categories={categories}
                    allBankList={allBankList}
                />
            )}
        </div>
    )
}
