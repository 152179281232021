import { createMutation } from '../api/mutation'
import { AllBillsKey } from './queries'

export const useMutationCreateBill = createMutation({
    url: '/bill',
    method: 'POST',
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
    keysToRefetch: [AllBillsKey],
})

export const useMutationSaveBill = createMutation({
    url: '/save-bill',
    method: 'POST',
    keysToRefetch: [AllBillsKey, ['bill-payment']],
})
export const useMutationUpdateBill = createMutation({
    url: '/bill',
    method: 'PATCH',
})
export const useMutationDeleteBill = createMutation({
    url: '/bill',
    method: 'DELETE',
})

export const useMutationApproveBill = createMutation({
    url: '/approve-bill',
    method: 'POST',
    keysToRefetch: [AllBillsKey, ['bill-payment']],
})

export const useMutationPayBill = createMutation({
    url: '/pay-bill',
    method: 'POST',
    keysToRefetch: [AllBillsKey, ['bill-payment']],
})

export const useMutationScheduleBill = createMutation({
    url: '/scheduled-payment',
    method: 'POST',
    keysToRefetch: [AllBillsKey, ['bill-payment']],
})

export const useMutationDeclineBill = createMutation({
    url: '/decline-bill',
    method: 'PATCH',
    keysToRefetch: [AllBillsKey, ['bill-payment']],
})
