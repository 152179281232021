import React, { useEffect, useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { Button, Input, Textarea } from '@/components'
import { AddItemProps, FormStateType } from '@/modules/dashboard/Invoices/components/utils'

import { formatAmount, formatAmountNoSymbol, formatAmountWithoutSymbols } from '@/utils/money'
import { useMutateCreateINvoiceItem } from '@/services/invoice/mutations'
import { useAuthContext, useCurrencyContext } from '@/context'
import { CurrencySelectFilter } from '@/components/CurrencyFilter'
import { InvoiceItemType } from '@/types/invoice'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { AlertCircleIcon } from 'lucide-react'
// import { capitalizeText } from '@/utils/string'

type Props = {
    totalItems: AddItemProps[]
    selectedItem?: AddItemProps
    update?: boolean
    setFormState: React.Dispatch<React.SetStateAction<FormStateType>>
    formState?: Partial<FormStateType>
    setCloseModal: React.Dispatch<React.SetStateAction<boolean>>
    setEditModal?: React.Dispatch<React.SetStateAction<boolean>>
    orgItems: InvoiceItemType[]
    createItem?: boolean
}
export default function AddNewItemModal({
    update,
    setCloseModal,
    setFormState,
    selectedItem,
    formState,
    setEditModal,
    orgItems,
    createItem,
}: Props) {
    const { selectedCurrencyInfo } = useCurrencyContext()
    const [currency, setCurrency] = useState(selectedCurrencyInfo()?.id)

    const currencySymbol = createItem
        ? selectedCurrencyInfo?.(currency)?.symbol
        : selectedCurrencyInfo?.(formState?.currency)?.symbol

    const currentItem = update ? selectedItem : formState?.item?.slice(-1)[0]
    const { selectedAccount } = useAuthContext()

    const { mutate, isLoading } = useMutateCreateINvoiceItem({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: () => {
            setCloseModal(false)
            setEditModal?.(false)
        },
    })

    const [immutableObject, setImmutableObject] = useState(currentItem)
    const [availableItems, setAvailableItems] = useState<InvoiceItemType[]>([])
    const [isSelected, setIsSelected] = useState(false)

    //did this here to have a value of the innitial object before editing so if a user decides not to edit any longer
    useEffect(() => {
        const copiedObject = { ...currentItem }
        Object.freeze(copiedObject)
        setImmutableObject({ ...copiedObject } as AddItemProps)
    }, [])

    const handleAddItems = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target

        const value_entered = name === 'unit_cost' ? formatAmountWithoutSymbols(value) : value

        if (name === 'name') {
            setIsSelected(false)
            setAvailableItems(
                value === '' || value === ' '
                    ? []
                    : orgItems?.filter?.(item => item.name.toLowerCase().includes(value.toLowerCase()))
            )
        }

        setFormState(field => {
            return {
                ...field,
                item:
                    field &&
                    field?.item?.map(data => {
                        if (
                            (data?._id ?? data?.id ?? data?.uniq_id) ===
                            (currentItem?._id ?? currentItem?.id ?? currentItem?.uniq_id)
                        ) {
                            if (name === 'quantity' || name === 'unit_cost') {
                                data['total_cost'] = Number(data.unit_cost) * Number(data.quantity)
                            }

                            data[name as keyof AddItemProps] = value_entered
                            return data
                        }
                        return data
                    }),
            }
        })
    }
    const handleOptionPick = (selected: InvoiceItemType) => {
        if (selected?.currency?._id !== selectedCurrencyInfo?.(formState?.currency)?.id) return
        setFormState(field => {
            return {
                ...field,
                item:
                    field &&
                    field?.item?.map(data => {
                        if (
                            (data?._id ?? data?.id ?? data?.uniq_id) ===
                            (currentItem?._id ?? currentItem?.id ?? currentItem?.uniq_id)
                        ) {
                            data.name = selected.name
                            data.description = selected.description
                            data['total_cost'] = Number(selected.unit_cost) * Number(selected.quantity)
                            // data.quantity = selected.quantity
                            data.unit_cost = selected.unit_cost?.$numberDecimal
                            data.id = selected.id

                            return data
                        }
                        return data
                    }),
            }
        })
        setIsSelected(true)
    }

    const handleSubmitItems = () => {
        if (orgItems?.some(item => item?.id === currentItem?.id)) {
            setCloseModal(false)
            setEditModal?.(false)
            return
        }

        mutate({
            name: currentItem?.name,
            description: currentItem?.description,
            unit_cost: Number(currentItem?.unit_cost) / 100,
            quantity: Number(currentItem?.quantity),
            currency,
        })
    }

    const handleClose = () => {
        setCloseModal(false)
        if (update) {
            setFormState(field => {
                const updatedItem = field?.item?.filter(item => item.uniq_id !== currentItem?.uniq_id) as AddItemProps[]
                return {
                    ...field,
                    item: [...updatedItem, immutableObject] as AddItemProps[],
                }
            })

            setEditModal?.(false)
            setCloseModal(false)
            return
        }

        setFormState(field => {
            return {
                ...field,
                item: field?.item?.slice(0, -1),
            }
        })
    }
    const disableButton = () => {
        const res = Object.entries({ ...formState?.item?.[formState?.item?.length - 1] })
            .map(([key, value]) => {
                if (key === 'description' || key === 'total_cost' || key === 'unit_cost') {
                    return true
                }

                if (key === 'quantity' && Number(value) < 1) {
                    return false
                }
                return !!value
            })
            .every(val => !!val)
        return res
    }

    const hasCurrencyMismatch = (currencyId: string) => currencyId !== selectedCurrencyInfo?.(formState?.currency)?.id

    useEffect(() => {
        setFormState(field => {
            return {
                ...field,
                item: field?.item?.map(data => {
                    if (
                        (data?._id ?? data?.id ?? data?.uniq_id) ===
                        (currentItem?._id ?? currentItem?.id ?? currentItem?.uniq_id)
                    ) {
                        data['total_cost'] = Number(data.unit_cost) * Number(data.quantity)
                        return data
                    }
                    return data
                }),
            }
        })
    }, [JSON.stringify(formState?.item), selectedItem?._id, selectedItem?.id, selectedItem?.uniq_id, setFormState])

    return (
        <ModalWrapper variants={undefined} closeModal={handleClose} formId='addNewItem' shouldConfirmClose>
            <section className='w-screen lg:w-[38rem] max-h-[90vh] overflow-scroll pb-8'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end '>
                    <div className='flex items-center w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B]'>{update ? 'Update' : 'Add New'} Item</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={handleClose}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <form onSubmit={e => e.preventDefault()} id='addNewItem'>
                    <section className='flex p-6 gap-[1rem]'>
                        <div className='flex flex-col flex-1 gap-3 w-3/4'>
                            <label className='flex items-center gap-1'>
                                Item name
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='text'
                                placeholder='Enter item name '
                                name='name'
                                className='border-[#DADCE0]  h-[3rem]'
                                value={currentItem?.name}
                                onChange={handleAddItems}
                            />
                        </div>

                        {!createItem && (
                            <div className='flex flex-col flex-1 gap-3 max-w-[10rem]'>
                                <label className='flex items-center gap-1'>
                                    Quantity
                                    <span className='text-[red]'>*</span>
                                </label>
                                <input
                                    type='number'
                                    placeholder='Quantity'
                                    name='quantity'
                                    className='border border-[#DADCE0]  h-[3rem] rounded-md placeholder:text-sm p-3 outline-none focus:outline-brand'
                                    onChange={handleAddItems}
                                    value={currentItem?.quantity}
                                />
                            </div>
                        )}
                    </section>
                    {availableItems?.length > 0 && !isSelected && (
                        <section className='flex p-6 gap-[1rem] -mt-8'>
                            <div
                                className='p-5 rounded-lg flex flex-col gap-4 w-full max-h-60 overflow-y-scroll scrollbar'
                                style={{ boxShadow: '0px 2px 100px 0px #2F2F2F0D' }}
                            >
                                {availableItems?.map(item => (
                                    <div
                                        className={`font-medium flex justify-between items-center  ${hasCurrencyMismatch(item?.currency?._id ?? '') ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'}`}
                                        onClick={() => handleOptionPick(item)}
                                        key={item._id ?? item.id}
                                    >
                                        <div className='flex flex-col gap-2'>
                                            <h2 className='text-base'>
                                                {item.name
                                                    .split(new RegExp(`(${currentItem?.name})`, 'gi'))
                                                    .map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={
                                                                part.toLowerCase() === currentItem?.name.toLowerCase()
                                                                    ? { backgroundColor: '#FDFCDB' }
                                                                    : {}
                                                            }
                                                        >
                                                            {part}
                                                        </span>
                                                    ))}
                                            </h2>
                                            <p className='text-xs text-gray7'>{item.description}</p>
                                        </div>
                                        <div className='flex flex-col items-end gap-2'>
                                            <p className='text-base'>
                                                <span>{item?.currency?.symbol}</span>

                                                {formatAmountNoSymbol(item.unit_cost?.$numberDecimal)}
                                            </p>
                                            {hasCurrencyMismatch(item?.currency?._id ?? '') && (
                                                <TooltipProvider delayDuration={10}>
                                                    <Tooltip>
                                                        <TooltipTrigger className='mr-2' type='button'>
                                                            <AlertCircleIcon size={15} />
                                                        </TooltipTrigger>

                                                        <TooltipContent>
                                                            <p className='max-w-[15rem] bg-[#EFEFF9] p-2.5 text-[#202020] text-xs rounded backdrop-blur-xl'>
                                                                All invoice items must be in the same currency as the
                                                                invoice
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    )}
                    <section>
                        <div className='flex flex-col flex-1 gap-3 px-6'>
                            <label className='flex items-center justify-between'>
                                <span className='flex items-center gap-1'>Description</span>
                            </label>
                            <Textarea
                                name='description'
                                placeholder='Describe item or service'
                                className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                                maxLength={120}
                                value={currentItem?.description}
                                onChange={handleAddItems}
                            />
                        </div>
                    </section>
                    <section className='flex p-6 gap-[1rem] mb-10 items-center'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>
                                Unit cost
                                <span className='text-[red]'>*</span>
                            </label>

                            <Input
                                type={'text'}
                                name='unit_cost'
                                placeholder={'0.00'}
                                className='border-[#DADCE0]  h-[3rem] disabled:bg-[#F9F9F9]'
                                leftIcon={<span className='text-gray7/80'>{currencySymbol}</span>}
                                value={formatAmount(currentItem?.unit_cost).replaceAll('₦', '')}
                                onChange={handleAddItems}
                            />
                        </div>
                        {createItem && (
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Currency
                                    <span className='text-[red]'>*</span>
                                </label>

                                <CurrencySelectFilter
                                    hideAllFilter
                                    currency={currency}
                                    setCurrency={setCurrency}
                                    triggerClassName='flex-1 w-full h-12 py-3.5'
                                />
                            </div>
                        )}
                        {!createItem && (
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Total Cost
                                    <span className='text-[red]'>*</span>
                                </label>

                                <Input
                                    type={'text'}
                                    placeholder={'0.00'}
                                    name={'total_cost'}
                                    className='border-[#DADCE0] w-[15.8rem] h-[3rem] disabled:bg-[#F9F9F9]'
                                    leftIcon={<span className='text-gray7/80'>{currencySymbol}</span>}
                                    value={formatAmount(currentItem?.total_cost).replaceAll('₦', '')}
                                    disabled
                                />
                            </div>
                        )}
                    </section>
                </form>
                <div className='flex pt-5 items-center justify-end gap-4 px-6 pb-3 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg'>
                    <Button
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        onClick={() => {
                            handleSubmitItems()
                        }}
                        loading={isLoading}
                        disabled={!disableButton() || Number(currentItem?.quantity) <= 0}
                    >
                        {update ? 'Update' : 'Add'} Item
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
