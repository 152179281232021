import { Button } from '@/components'
import ModalWrapper from '../ModalWrapper'
import { TeamsProp } from '@/types/employee'
import { toastHandler } from '@/components/utils/Toast'
import { useMutationDeleteTeam } from '@/services/employees/mutations'

interface TeamRemovalProps {
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    team?: TeamsProp
}

export default function DeleteTeam({ setIsDeleteDialogOpen, team }: TeamRemovalProps) {
    const { mutate: deleteTeam, isLoading: isTeamDeleting } = useMutationDeleteTeam({
        queryParams: {
            team_id: team?._id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Team Deleted', state: 'success' })
            setIsDeleteDialogOpen(false)
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-screen md:w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>
                    Are you sure you want to delete <span className='text-[#454ADE]'>{team?.name}</span>{' '}
                </p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isTeamDeleting}
                        onClick={() => {
                            deleteTeam({})
                        }}
                    >
                        Yes, delete team
                    </Button>
                    <button
                        type='button'
                        className='min-h-[50px] px-5 mx-3'
                        onClick={() => setIsDeleteDialogOpen(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}
