import { useMemo } from 'react'
import TransactionHistoryTable from '../../Transactions/components/TransactionHistoryTable'
import { useGetCustomerTransactions } from '@/services/transactions/queries'
import ProgressBar from '@/components/ProgressBar'
import { useParams } from 'react-router-dom'

export default function LinkedTransactionsTable() {
    const { customer_id } = useParams()

    // const {
    //     data,
    //     isLoading: isTransactionsLoading,
    //     isFetching: isTransactionsFetched,
    // } = useGetVendorTransactions({
    //     queryParams: {
    //         vendor_id: customer_id as string,
    //     },
    // })
    const {
        data,
        isLoading: isTransactionsLoading,
        isFetching: isTransactionsFetched,
    } = useGetCustomerTransactions({
        queryParams: {
            id: customer_id as string,
        },
    })

    const transactionsData = useMemo(() => data ?? [], [data])

    if (isTransactionsLoading) return <ProgressBar />
    return (
        <section className='mt-[1.2rem]'>
            <div className={` transition-all rounded-lg ${isTransactionsFetched ? 'opacity-30' : 'opacity-100'} `}>
                <TransactionHistoryTable transactions={transactionsData} />
            </div>
        </section>
    )
}
