import { Button } from '@/components'
import { ArrowLeft } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
// import downArrow from '@/assets/down-arrow.svg'
import { useMemo } from 'react'
import { useAuthContext, usePayrollContext } from '@/context'

import MilestonesTable from '@/modules/dashboard/Payroll/components/MilestonesTable'
import { useGetOrgContractPayrollById } from '@/services/payroll/queries'
import ProgressBar from '@/components/ProgressBar'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { MilestoneType } from '@/types/payroll'
import { useTerminateContract } from '@/services/payroll/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { contractSidebarPermissions } from '@/layout/LayoutComponents/utils'

export default function UserContractMoreDetails() {
    const navigate = useNavigate()
    const { userContractPayroll, setUserContractPayroll } = usePayrollContext()

    const hasPermission = useHasAccessPermisson(contractSidebarPermissions)

    const { contract_id } = useParams()
    const { selectedAccount } = useAuthContext()

    const {
        data: user_contract_by_id_data,
        isLoading,
        isRefetching,
    } = useGetOrgContractPayrollById({
        queryParams: {
            id: contract_id as string,
            employee: selectedAccount?.id as string,
        },
    })

    const selectedPayrollContract = useMemo(
        () => userContractPayroll ?? user_contract_by_id_data,
        [userContractPayroll, user_contract_by_id_data]
    )
    const { mutate: terminateContractFn, isLoading: isTerminatingContract } = useTerminateContract({
        queryParams: {
            contract_id: selectedPayrollContract?.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'contract terminated', state: 'success' })
            setUserContractPayroll(undefined)
        },
    })

    const contract_details = [
        {
            name: 'title',
            desc: capitalizeText(selectedPayrollContract?.contract_title),
        },
        {
            name: 'Date created',
            desc:
                selectedPayrollContract?.createdAt &&
                format(new Date(selectedPayrollContract.createdAt as unknown as Date), 'dd/MM/yyyy'),
        },
        {
            name: 'Total pay',
            desc: formatAmount(selectedPayrollContract?.total_pay),
        },
        {
            name: 'Start date',
            desc:
                selectedPayrollContract?.start_date &&
                format(new Date(selectedPayrollContract?.start_date as unknown as Date), 'dd/MM/yyyy'),
        },
        {
            name: 'End date',
            desc:
                selectedPayrollContract?.end_date &&
                format(new Date(selectedPayrollContract?.end_date as unknown as Date), 'dd/MM/yyyy'),
        },
    ]
    if (isLoading && !userContractPayroll?.id) return <ProgressBar />
    return (
        <div className='h-screen overflow-scroll'>
            <section className='mx-[2.7rem] py-3 pt-2 mb-6 '>
                <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                    <ArrowLeft />
                    back
                </div>
                <section className=' bg-white rounded-xl px-7 py-6 pb-11'>
                    <div className='border-b border-[#DADCE0] pb-3 flex items-center justify-between'>
                        <h2 className=' font-semibold text-lg'>{contract_details[0].desc}</h2>
                    </div>
                    <div
                        className={`flex flex-wrap max-w-[50rem] gap-x-[5rem] gap-y-8 mt-8 ${
                            isRefetching ? 'opacity-50' : 'opacity-100'
                        }`}
                    >
                        {contract_details
                            .filter((_, i) => i > 0)
                            .map((detail, idx) => (
                                <>
                                    <div
                                        className={`'flex flex-col ${detail.name === 'Description' ? 'w-[80%]' : ''}`}
                                        key={idx}
                                    >
                                        <h4 className='text-md font-semibold mb-1'>{detail.name}</h4>
                                        <span className='text-[#5E5E5E] text-[1rem]'>{detail.desc}</span>
                                    </div>
                                </>
                            ))}
                    </div>
                    <div className='mt-16'>
                        <div className='border-b border-[#DADCE0] pb-3'>
                            <h2 className=' font-semibold text-lg'>Contract Milestones</h2>
                        </div>
                        <div className='my-6 px-2 pb-10'>
                            <MilestonesTable
                                contract={selectedPayrollContract}
                                data={selectedPayrollContract?.milestones as MilestoneType[]}
                            />
                        </div>
                    </div>
                    {hasPermission && selectedPayrollContract?.status !== 'cancelled' ? (
                        <div className='flex justify-between mt-10'>
                            <Button className='bg-transparent text-[#202020] border border-[#202020] hover:bg-transparent  min-w-[130px] min-h-[35px] hover'>
                                Edit
                            </Button>
                            <div className='flex gap-5'>
                                <Button className='bg-[#CF491E]  min-w-[130px] min-h-[35px] bg-transparent text-errorRed hover:bg-transparent hover:border-[#CF491E] hover:border'>
                                    Extend contract
                                </Button>
                                <Button
                                    className=' min-w-[130px] min-h-[35px] hover bg-[#CF491E] text-white hover:bg-[#ac573b]'
                                    loading={isTerminatingContract}
                                    onClick={() => {
                                        terminateContractFn({})
                                    }}
                                >
                                    Terminate contract
                                </Button>
                            </div>
                            {/* </div> */}
                        </div>
                    ) : (
                        ''
                    )}
                </section>
            </section>
        </div>
    )
}
