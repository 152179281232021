import { Button } from '@/components'
import ModalWrapper from '../ModalWrapper'
import { useEmployeesContext } from '@/context'
import { useMutateMakeTeamLead } from '@/services/employees/mutations'
import { TeamMembersProp } from '@/types/employee'
import { toastHandler } from '@/components/utils/Toast'

interface TeamMemberProps {
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    userDetails: TeamMembersProp
}

export default function MakeTeamLeadPrompt({ setIsDeleteDialogOpen, userDetails }: TeamMemberProps) {
    const { teamsObj } = useEmployeesContext()

    const { mutate: makeTeamLeadFn, isLoading: isDeleteMemberLoading } = useMutateMakeTeamLead({
        queryParams: {
            team_id: teamsObj?.id as string,
            account: userDetails?.account?.id,
        },
        onSuccess: () => {
            setIsDeleteDialogOpen(false)
            toastHandler({ message: 'Changes made', state: 'success' })
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>
                    Are you sure you want to make{' '}
                    <span className='text-[#454ADE]'>
                        {userDetails?.account?.user?.first_name} {userDetails?.account?.user?.last_name}
                    </span>{' '}
                    team lead ?
                </p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isDeleteMemberLoading}
                        onClick={() => {
                            makeTeamLeadFn({})
                        }}
                    >
                        Yes
                    </Button>
                    <button
                        type='button'
                        className='min-h-[50px] px-5 mx-3'
                        onClick={() => setIsDeleteDialogOpen(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}
