import ModalWrapper from '../ModalWrapper'
import { useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { CancelIcon, FolderIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { useMoveVaultItem } from '@/services/vault/mutations'
import { VaultItem } from '@/services/vault/types'
import { useGetVaultItems } from '@/services/vault/queries'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    file: VaultItem
    actionType: string
}

export const MoveFileModal = ({ closeModal, actionType, file }: Props) => {
    const [newFolder, setNewFolder] = useState('')
    const { mutate: moveFn, isLoading: isMoving } = useMoveVaultItem({
        onSuccess: () => {
            toastHandler({ message: `Successfully moved ${file.type}`, state: 'success' })
            closeModal(false)
        },
    })

    const { data } = useGetVaultItems({
        queryParams: {
            vault: file?.vault as string,
            parent: file?.parent as string,
        },
    })

    const handleButton = () => {
        if (actionType === 'move') {
            moveFn({
                itemId: file?.id as string,
                newParentId: newFolder,
            })
        }
    }
    return (
        <ModalWrapper variants={undefined}>
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>
                        {actionType === 'move' ? 'Move' : 'Duplicate'} {file.type} - {file.name}
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='p-7 pt-5'>
                    <div className={`flex flex-col flex-1`}>
                        <label className='mb-3' htmlFor='folder'>
                            {actionType === 'move' ? `New ${file.type} destination` : 'Duplicate'}
                        </label>
                        <Select
                            name='folder'
                            onValueChange={value => {
                                setNewFolder(value)
                            }}
                            value={newFolder}
                        >
                            <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                                {newFolder ? (
                                    <SelectValue />
                                ) : (
                                    <span>
                                        Select the folder you want to {actionType === 'move' ? 'move' : 'duplicate'}{' '}
                                        this {file.type} to
                                    </span>
                                )}
                            </SelectTrigger>
                            <SelectContent className='max-h-[12rem] overflow-y-scroll w-[27.5rem]'>
                                {file.vault !== file.parent && (
                                    <SelectItem value={file?.vault as string} className='pl-3'>
                                        <div className='flex items-center gap-3'>
                                            <FolderIcon className={`w-4 h-4`} />
                                            <div className='flex flex-col items-start'>
                                                <h6 className='text sm'>Root Vault</h6>
                                            </div>
                                        </div>
                                    </SelectItem>
                                )}
                                {data
                                    ?.filter(item => item.type === 'folder')
                                    ?.filter(item => item.id !== file?.id)
                                    .map(num => (
                                        <SelectItem value={num?.id} className='pl-3' key={num?.id}>
                                            <div className='flex items-center gap-3'>
                                                <FolderIcon className={`w-4 h-4`} />
                                                <div className='flex flex-col items-start'>
                                                    <h6 className='text sm'>{num?.name}</h6>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button onClick={handleButton} disabled={!newFolder || isMoving} loading={isMoving}>
                        {actionType === 'move' ? 'Move' : 'Duplicate'} {file.type}
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
