import { Button } from '@/components'
import ModalWrapper from '../ModalWrapper'
import { toastHandler } from '@/components/utils/Toast'
import { InvoiceItemType } from '@/types/invoice'
import { useMutateDeleteINvoiceItem } from '@/services/invoice/mutations'

interface Props {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    itemDetails?: InvoiceItemType
}

export default function DeleteItemPrompt({ closeModal, itemDetails }: Props) {
    const { mutate: deleteItemFn, isLoading } = useMutateDeleteINvoiceItem({
        queryParams: {
            id: itemDetails?.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Item deleted', state: 'success' })
            closeModal(false)
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0]  px-8 py-8 rounded-[1.3rem]'>
                <p>Are you sure you want to delete this item</p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isLoading}
                        onClick={() => {
                            deleteItemFn({})
                        }}
                    >
                        Yes, delete item
                    </Button>
                    <button type='button' className='min-h-[50px] px-5 mx-3' onClick={() => closeModal(false)}>
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}
