import EmployeesListTable from '../../Employees/components/EmployeesListTable'
import { CiSearch } from 'react-icons/ci'
import { useImpersonationContext } from '@/context'
import { RoleEnum } from '@/types/org'
import ProgressBar from '@/components/ProgressBar'
import { QueryOrgMembers } from '@/services/employees/types'
import { findOrgCreator } from '@/utils/permissions'
import { format } from 'date-fns'
import { useGetCompanyKYB } from '@/services/auth/queries'
import { useState } from 'react'
import { useGetOrg } from '@/services/imitating-users/queries'
import { useParams } from 'react-router-dom'

type Props = {
    orgMembersData: QueryOrgMembers
    loading: boolean
}
const BusinessDirectorySummary = ({ orgMembersData, loading }: Props) => {
    const { user_id: org_id } = useParams()
    const { orgDetails: org_details } = useImpersonationContext()

    const ownersCount = orgMembersData.filter(members => members?.role.name === RoleEnum.OWNER)
    const adminCount = orgMembersData.filter(members => members?.role.name === RoleEnum.ADMIN)
    const financeadminCount = orgMembersData.filter(members => members?.role.name === RoleEnum.FINANCE_ADMIN)
    const usersCount = orgMembersData.filter(members =>
        [RoleEnum.EMPLOYEE, RoleEnum.CONTRACTOR].includes(members?.role.name)
    )
    const { data: company_kyb, isLoading } = useGetCompanyKYB({
        queryParams: {
            org_id: org_details?._id as string,
        },
    })
    const { data } = useGetOrg({
        queryParams: {
            id: org_id as string,
        },
    })

    const orgCreator = findOrgCreator(ownersCount) ?? ownersCount?.[0]
    const orgDetails = data

    const businessDetails = [
        { name: 'Total users', desc: orgMembersData?.length },
        { name: 'Owners', desc: ownersCount?.length },
        { name: 'Finance admins', desc: financeadminCount?.length },
        { name: 'Normal admins', desc: adminCount?.length },
        { name: 'Other Users', desc: usersCount?.length },
        { name: 'Industry', desc: company_kyb?.industry },
        { name: 'Owner', desc: `${orgCreator?.user?.first_name} ${orgCreator?.user?.last_name}` },
        {
            name: 'Plan',
            desc: `${typeof orgDetails?.business_plan?.plan === 'string' ? 'N/A' : orgDetails?.business_plan?.plan?.name}`,
        },
        { name: 'Date joined', desc: orgDetails?.createdAt && format(new Date(orgDetails?.createdAt), 'dd/MM/yyyy') },
    ]

    const [searchQuery, setSearchQuery] = useState('')

    function filterMembers(query: string, members: QueryOrgMembers): QueryOrgMembers {
        return members.filter(member =>
            (member?.user?.first_name + ' ' + member?.user?.last_name).toLowerCase().includes(query.toLowerCase())
        )
    }

    const filteredMembers = filterMembers(searchQuery, orgMembersData)
    if (loading || isLoading) return <ProgressBar />
    return (
        <>
            <div className='my-7'>
                <div className=''>
                    <section className='bg-white rounded-xl p-5 shadow-sm '>
                        <h1 className='text-base font-semibold pb-3 border-b border-gray5'>
                            {orgDetails?.org_name ?? orgMembersData?.[0]?.org?.org_name}
                        </h1>
                        <div className='mt-5'>
                            <div className='flex flex-wrap max-w-xl gap-4 lg:gap-y-6 lg:gap-x-16'>
                                {businessDetails?.map(data => {
                                    return (
                                        <div className='flex flex-col flex-wrap    '>
                                            <span className=' font-medium text-base'>{data.name}</span>
                                            <div className='flex gap-3 items-center mt-2 truncate'>
                                                <span className='text-[#838383] font-medium text-sm truncate max-w-[12rem]'>
                                                    {data.desc}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <section className='bg-white rounded-lg p-3'>
                <h2 className='text-xl font-medium mb-4'>Users</h2>
                <div className='border border-[#DADCE0] h-10 w-96 rounded flex items-center overflow-hidden'>
                    <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                        <CiSearch fontSize={25} />
                    </div>
                    <input
                        type='text'
                        placeholder='Search users by name'
                        className='flex-1 outline-none text-sm'
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className={`mt-5 ${loading ? 'opacity-50' : 'opacity-100'} overflow-x-scroll`}>
                    <EmployeesListTable employees={filteredMembers} />
                </div>
            </section>
        </>
    )
}

export default BusinessDirectorySummary
