import React from 'react'

import { CancelIcon, NoBankSVG } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'

import { OrgVirtualAccountType } from '@/types/virtual-account'
import { CopyIcon } from 'lucide-react'
import { Button } from '@/components'
import { useAuthContext } from '@/context'
import { useMutateGenerateorgVirtualAccount } from '@/services/virtual-account/mutations'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    bankDetails?: OrgVirtualAccountType
}

export default function ShowBankDetails({ closeModal, bankDetails }: Props) {
    const { selectedAccount, setShowKYCModalOnce } = useAuthContext()
    const {
        mutate: generateOrgVirtualFn,
        isLoading,
        isError,
    } = useMutateGenerateorgVirtualAccount({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Bank details generated successfully', state: 'success' })
        },
    })

    const handleCopyClick = async (text?: string) => {
        try {
            await navigator.clipboard.writeText(text ?? 'Nothing to copy')
            toastHandler({ message: 'Copied to clipboard!', state: 'success' })
        } catch (err) {
            toastHandler({ message: 'Copy to clipboard failed.', state: 'error' })
        }
    }
    return (
        <ModalWrapper variants={undefined}>
            <main className='min-h-[15rem] w-[25rem] overflow-scroll '>
                <section className='flex items-center border-b border-[#DADCE0] p-4 '>
                    <span className='flex-1 text-center text-xl font-medium'>Bank Details</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>

                {bankDetails?.bank_name ? (
                    <>
                        <section className='mx-4 flex flex-col gap-5'>
                            <div className='flex justify-between mt-8'>
                                <div>
                                    <h5 className='text-[#5E5E5E]'>Account number</h5>
                                    <p className='font-medium'>{bankDetails?.account_number}</p>
                                </div>
                                <CopyIcon
                                    size={18}
                                    className='cursor-pointer text-[#5E5E5E]'
                                    onClick={() => handleCopyClick(bankDetails?.account_number)}
                                />
                            </div>
                            <div className='flex justify-between'>
                                <div>
                                    <h5 className='text-[#5E5E5E]'>Bank name</h5>
                                    <p className='font-medium'>{bankDetails?.bank_name}</p>
                                </div>
                                <CopyIcon
                                    size={18}
                                    className='cursor-pointer text-[#5E5E5E]'
                                    onClick={() => handleCopyClick(bankDetails?.bank_name)}
                                />
                            </div>
                            <div className='flex justify-between'>
                                <div>
                                    <h5 className='text-[#5E5E5E]'>Account name</h5>
                                    <p className='font-medium'>{bankDetails?.account_name}</p>
                                </div>
                                <CopyIcon
                                    size={18}
                                    className='cursor-pointer text-[#5E5E5E]'
                                    onClick={() => handleCopyClick(bankDetails?.account_name)}
                                />
                            </div>
                        </section>
                        <div className='flex gap-1 items-center justify-center my-5 mt-8 text-[#5E5E5E] font-medium'>
                            {' '}
                            <button
                                className='flex gap-3 items-center'
                                onClick={() =>
                                    handleCopyClick(
                                        `Account Number: ${bankDetails?.account_number}, Bank Name: ${bankDetails?.bank_name}, Account Name: ${bankDetails?.account_name}`
                                    )
                                }
                            >
                                <CopyIcon size={18} className='text-[#5E5E5E]' /> Copy all details{' '}
                            </button>
                        </div>
                    </>
                ) : (
                    <div className='grid place-items-center mt-10'>
                        {!isLoading ? (
                            <NoBankSVG />
                        ) : (
                            <div className='grid place-items-center mt-10 mb-10 text-[#5E5E5E]'>
                                Generating bank details...
                            </div>
                        )}
                        <Button className='mt-10 mb-4' onClick={() => generateOrgVirtualFn({})} loading={isLoading}>
                            Generate bank details
                        </Button>
                        {isError && (
                            <span
                                className='text-xs text-brand underline mb-3 cursor-pointer'
                                onClick={() => setShowKYCModalOnce(true)}
                            >
                                Click here to fill required details
                            </span>
                        )}
                    </div>
                )}
            </main>
        </ModalWrapper>
    )
}
