import { createMutation } from '../api/mutation'
import { glcodeKey } from './queries'

export const useUploadGlCode = createMutation({
    url: '/gl-code',
    method: 'POST',
    keysToRefetch: [glcodeKey],
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useUpdateGlCode = createMutation({
    url: '/gl-code',
    method: 'PATCH',
    keysToRefetch: [glcodeKey],
})

export const useDeleteGlCode = createMutation({
    url: '/gl-code',
    method: 'DELETE',
    keysToRefetch: [glcodeKey],
})
