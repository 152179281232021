import { CiSearch } from 'react-icons/ci'
import { TfiAngleDown } from 'react-icons/tfi'
import ContractPayrollTable from '../../components/ContractPayrollTable'
import { useEffect, useMemo, useRef, useState } from 'react'
import AddContractToPayrollModal from '@/components/Modals/Payroll/AddContractToPayrollModal/AddContractToPayrollModal'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext, usePayrollContext } from '@/context'
import { useGetOrgContractPayroll } from '@/services/payroll/queries'
import ProgressBar from '@/components/ProgressBar'
import { QueryOrgContractPayRollType } from '@/services/payroll/types'
import { debounce } from 'lodash'
import { getSortString } from '@/components/Table/utils'
import { ColumnSort } from '@/types/utils'
import notFound from '@/assets/not-found.svg'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'

const ContractPayroll = () => {
    const [openCreateModal, setCreateModal] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)
    const { enabled: isContractEnabled } = useAccessToModule({ module: ModuleNameEnum.CONTRACTS })

    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const { sortContract } = usePayrollContext()

    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const orgMembers = useMemo(() => org_members ?? [], [org_members])

    const {
        data: org_contract_payroll,
        isLoading,
        refetch: refetchAllOrgContract,
        isRefetching,
    } = useGetOrgContractPayroll({
        queryParams: {
            sort: getSortString(sortContract as ColumnSort),
            org_id: `${selectedAccount?.org.id as string}`,
            search: searchQuery,
        },
        onSuccess: () => {
            setBackSpace(false)
        },
    })
    const orgContractPayroll = useMemo(
        () => org_contract_payroll ?? ({} as QueryOrgContractPayRollType),
        [org_contract_payroll]
    )

    const debouncedSearch = useRef(
        debounce(() => {
            refetchAllOrgContract()
        }, 300)
    ).current

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }
    useEffect(() => {
        if (backspaceClicked) {
            refetchAllOrgContract()
            setBackSpaceClicked(false)
        }
        if (sortContract) {
            refetchAllOrgContract()
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, debouncedSearch, refetchAllOrgContract, searchQuery, sortContract])

    if (isLoading) return <ProgressBar />
    return (
        <section className={``}>
            <div className='block lg:flex justify-between mb-6 lg:mb-0'>
                <div className='mb-6 '>
                    <h1 className='text-dark font-medium text-lg'>All contracts</h1>
                    <p className='text-gray7 text-sm'>
                        This is where you can view contractor history and process payment for contracted users
                    </p>
                </div>
                <button
                    className='bg-[#454ADE] text-white px-3 h-[45px] w-full lg:w-auto rounded-md text-sm flex items-center justify-between gap-2'
                    onClick={() => {
                        if (!isContractEnabled) {
                            setShowUpgradeModal(true)
                            setCurrentModule(ModuleNameEnum.CONTRACTS)
                            return
                        }
                        setCreateModal(true)
                    }}
                >
                    Create new contract
                    <TfiAngleDown />
                </button>
            </div>
            <div className='bg-white rounded-2xl p-4 '>
                <div className='flex items-center justify-between '></div>
                <div className='flex items-center justify-between mb-6'>
                    <div className='border border-[#DADCE0] h-[45px] w-full lg:w-[30%] rounded-lg flex items-center overflow-hidden '>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mr-2'>
                            <CiSearch fontSize={25} className='mx-1' />
                        </div>
                        <input
                            type='text'
                            placeholder='Search by contract and contract title'
                            className='h-full w-full outline-none placeholder:text-xs  placeholder:ml-2'
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>

                {orgContractPayroll?.payrolls?.length === 0 && isRefetching ? (
                    <ProgressBar />
                ) : (orgContractPayroll?.payrolls?.length === 0 && searchQuery?.length < 1 && !backspace) ||
                  (orgContractPayroll?.payrolls?.length > 0 && searchQuery?.length >= 0 && !backspace) ? (
                    <div className={` ${isRefetching ? 'opacity-50' : 'opacity-100'} overflow-x-scroll`}>
                        <ContractPayrollTable data={orgContractPayroll} />
                    </div>
                ) : searchQuery && orgContractPayroll?.payrolls?.length < 1 ? (
                    <div className='h-450px] flex flex-col text-center justify-center items-center pb-10'>
                        <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                        <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                        <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                            Oops! It seems we couldn't find any matching results for your search. Please try again with
                            different keywords or refine your filter criteria for better results.
                        </span>
                    </div>
                ) : (
                    <ProgressBar />
                )}
            </div>

            {openCreateModal && <AddContractToPayrollModal orgMembers={orgMembers} closeModal={setCreateModal} />}
        </section>
    )
}

export default ContractPayroll
