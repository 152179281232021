import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { toastHandler } from '@/components/utils/Toast'
import { categoryProps } from '@/modules/dashboard/Vault/partials/VaultSettings/VaultCategories'
import { useDeleteVaultCategory } from '@/services/vault/mutations'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    category: categoryProps
    setSelectedCategory: React.Dispatch<React.SetStateAction<categoryProps | undefined>>
}

export const DeleteCategoryModal = ({ closeModal, category, setSelectedCategory }: Props) => {
    const { mutate: deleteFn, isLoading } = useDeleteVaultCategory({
        queryParams: {
            id: category.id,
        },
        onSuccess: () => {
            toastHandler({
                message: `Category deleted successfully`,
                state: 'success',
            })
            setSelectedCategory(undefined)
            closeModal(false)
        },
    })
    return (
        <ModalWrapper variants={undefined}>
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>Delete category - {category.name}</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='p-7 pt-5'>
                    <p className='font-medium'>Are you sure you want to delete this ?</p>
                </div>
                <div className='flex items-center p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        className='bg-errorRed'
                        onClick={() => deleteFn({})}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Yes, Delete
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
