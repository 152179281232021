import { BizpendAccounts, DashboardSettingsIcon } from '@/assets/assets.export'
import { ArrowUpRight } from 'lucide-react'
import { PlusSquare } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useMemo, useState } from 'react'
import { useAuthContext } from '@/context'
import { useGetAccounts } from '@/services/bill-payment/queries'
import { formatAmount } from '@/utils/money'
import { OverviewInsight } from '@/services/auth/types'
import FundWalletRoot from '@/components/Modals/FundWallet/FundWalletRoot'
import { useGetDedicatedBankAccount } from '@/services/accounts/queries'
import { DedicatedBankAccountType } from '@/types/accounts'
import FundAccountModal from '@/components/Modals/Accounts/FundAccountNew'
import TransferModal from '@/components/Modals/Accounts/TransferModalNew'
import { useNavigate } from 'react-router-dom'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'

type Props = {
    orgInsightData: OverviewInsight
}

export const WalletCard = ({ orgInsightData }: Props) => {
    const [wallet, setWallet] = useState<string>('')
    const [showRootFundModal, setShowRootFundModal] = useState(false)
    const [openFundModal, setOpenFundModal] = useState(false)
    const [openTransferModal, setOpenTransferModal] = useState(false)
    const { selectedAccount } = useAuthContext()
    const navigate = useNavigate()

    const { data: accounts } = useGetAccounts({
        queryParams: {
            entity_id: selectedAccount?.org?.id as string,
        },
    })

    const selectedWallet = accounts?.find((acct: any) => acct?._id === wallet)

    const handleFund = () => {
        if (wallet.length === 0) {
            setShowRootFundModal(true)
        } else {
            setOpenFundModal(true)
        }
    }

    const { data } = useGetDedicatedBankAccount({
        queryParams: {
            id: wallet as string,
        },
        enabled: !!wallet,
    })

    const bankAccount = useMemo(() => data ?? ({} as DedicatedBankAccountType), [data])

    const accountDetails = [
        {
            name: 'Account number',
            desc: bankAccount?.account_number,
        },
        {
            name: 'Account name',
            desc: bankAccount?.account_name,
        },
        {
            name: 'Bank name',
            desc: bankAccount?.bank_name,
        },
    ]

    const pendindBalance = Number(orgInsightData?.current_balance) - Number(orgInsightData?.available_balance)
    const isPending = wallet?.length === 0 && pendindBalance > 0

    return (
        <div className='border border-gray8 rounded-[16px] h-full p-6 pb-[21px] bg-white md:w-[51%]'>
            <div className={`w-full flex flex-col  justify-between ${isPending ? 'gap-[25px]' : 'gap-[48px]'}`}>
                <div className='flex justify-between items-center'>
                    <div>
                        <Select onValueChange={setWallet} value={wallet}>
                            <SelectTrigger className='w-[168px] border-gray8 text-[#5E5E5E]' id='transactionYear'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='h-fit max-h-[10rem] w-[13rem]'>
                                <SelectItem value={''} className='pl-3'>
                                    <h6 className='text-sm'>Main wallet</h6>
                                </SelectItem>
                                {accounts?.map((acct: any) => (
                                    <SelectItem value={acct?._id} className='pl-3' key={acct?._id}>
                                        <h6 className='text-sm'>{acct?.nickname ?? acct?.account_name}</h6>
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className=''>
                    <div className='flex items-center gap-3'>
                        <BizpendAccounts className={`w-16 h-16 rounded-xl  fill-[#19C37D] bg-[#EFFFED] p-[10px]`} />
                        <div className=''>
                            <p className='text-[#838383] text-sm font-medium'>Balance</p>
                            <div className='text-[32px] font-extrabold flex items-baseline gap-3'>
                                <p>
                                    {wallet?.length === 0 ? (
                                        <>{formatAmount(orgInsightData?.available_balance)}</>
                                    ) : (
                                        formatAmount(selectedWallet?.balance)
                                    )}
                                </p>
                                {wallet !== '' && (
                                    <span
                                        className='text-brand font-medium text-xs leading-4 cursor-pointer hover:underline'
                                        onClick={() => navigate(`/dashboard/accounts/bizpend_accounts/${wallet}`)}
                                    >
                                        View
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {isPending && (
                        <div className='mt-2 flex bg-yellow-200/30 border border-yellow-500 rounded-lg p-2 py-3 w-fit text-xs items-center gap-2'>
                            <BizpendAccounts className={`w-4 h-4 rounded-xl  fill-yellow-500 bg-yellow-200/30`} />
                            <p>Pending: {formatAmount(pendindBalance)}</p>
                            <TooltipProvider delayDuration={10}>
                                <Tooltip>
                                    <TooltipTrigger className='mr-2' type='button'>
                                        <DashboardSettingsIcon />
                                    </TooltipTrigger>

                                    <TooltipContent sideOffset={-80}>
                                        <p className='bg-[#EFEFF9] p-2 text-[#202020] text-xs rounded max-w-[15rem]'>
                                            Funds that will be added to your wallet balance shortly
                                        </p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                    )}
                </div>
                <div
                    className={`border-t border-gray8 pt-4 grid gap-4 w-full ${wallet === '' ? 'grid-cols-1' : 'grid-cols-2'}`}
                >
                    <button
                        className='bg-transparent py-3 rounded-lg flex items-center justify-center border border-gray8 text-[#5E5E5E'
                        onClick={() => {
                            handleFund()
                        }}
                    >
                        <PlusSquare className='mr-2' size={18} color='#5E5E5E' />
                        Fund account
                    </button>
                    {wallet !== '' && (
                        <button
                            className='py-3 rounded-lg text-white bg-brand flex items-center justify-center disabled:opacity-50'
                            onClick={() => setOpenTransferModal(true)}
                            disabled={wallet === ''}
                        >
                            <ArrowUpRight className='mr-2' size={18} />
                            Transfer
                        </button>
                    )}
                </div>
            </div>
            {showRootFundModal && <FundWalletRoot setShowModal={setShowRootFundModal} />}
            {openFundModal && (
                <FundAccountModal
                    accountDetails={accountDetails}
                    account={bankAccount}
                    closeModal={() => setOpenFundModal(false)}
                />
            )}
            {openTransferModal && (
                <TransferModal account={bankAccount} closeModal={() => setOpenTransferModal(false)} />
            )}
        </div>
    )
}
