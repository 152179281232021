import React, { HTMLProps } from 'react'
export default function CheckBox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!)

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type='checkbox'
            ref={ref}
            className={className + ' cursor-pointer accent-[#454ADE] border-[#DADCE0]'}
            {...rest}
        />
    )
}
