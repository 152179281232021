import { CancelIcon, Quickbooks } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { Select, SelectValue, SelectContent, SelectItem, SelectTrigger } from '@/components/Select'
import { Button } from '@/components/Button'
import useSingleFileUpload, { fileEmptyState, FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { useMemo, useRef, useState } from 'react'
import { X } from 'lucide-react'
import { useGetGLCode } from '@/services/accounting/queries'
import { useUploadGlCode } from '@/services/accounting/mutations'
import { OrgGlCodeType } from '@/types/gl-code'
import AccountingSetupSuccessModal from './AccountingSetupSuccessModal'
import { CgSpinner } from 'react-icons/cg'
import { useAccountingContext } from '@/context'

const accountingPlatform = [{ name: 'Quickbooks', icon: Quickbooks }, { name: 'Sage' }]

type Props = {
    closeModal: () => void
    nextStep?: () => void
    prevStep?: () => void
}
export default function AccountingSetupModal({ closeModal }: Props) {
    const [steps, setSteps] = useState(3)

    return (
        <ModalWrapper>
            {steps === 1 && <AccountingPlatform nextStep={() => setSteps(2)} closeModal={closeModal} />}
            {steps === 2 && (
                <ImportGLCodes prevStep={() => setSteps(1)} nextStep={() => setSteps(3)} closeModal={closeModal} />
            )}
            {steps === 3 && (
                <ManageUploadedGLCodes
                    prevStep={() => setSteps(2)}
                    nextStep={() => setSteps(4)}
                    closeModal={closeModal}
                />
            )}
            {steps === 4 && <AccountingSetupSuccessModal closeModal={closeModal} />}
        </ModalWrapper>
    )
}

const AccountingPlatform = ({ closeModal, nextStep }: Props) => {
    return (
        <section className='min-h-[15rem] w-[27rem] overflow-scroll'>
            <div className='flex items-center border-b border-[#DADCE0] p-3 px-4 justify-between'>
                <h2 className='text-center text-lg font-medium opacity-80'>Setup your accounting</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <div className='mx-4 mt-5'>
                <h3 className='mb-2 text-sm text-gray7'>Select your preferred accounting platform</h3>
                <Select name='accounting_platform' defaultValue='quickbooks'>
                    <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E]/50 border-gray3 flex '>
                        <SelectValue placeholder={'Quickbooks, sage, xero etc'} />
                    </SelectTrigger>
                    <SelectContent className=''>
                        {accountingPlatform.map((platform, idx) => (
                            <SelectItem value={platform.name.toLowerCase()} key={idx}>
                                <div className='flex items-center gap-2'>
                                    <Quickbooks className='w-4 h-4' />
                                    {platform.name}
                                </div>
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className='border-t border-gray4 flex mt-6 justify-end items-center pt-2.5 px-4'>
                <Button onClick={nextStep} size={'lg'}>
                    Next
                </Button>
            </div>
        </section>
    )
}

const ImportGLCodes = ({ closeModal, nextStep, prevStep }: Props) => {
    const [glCodeFile, setGlCodeFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const glCodeRef = useRef<HTMLInputElement>(null)

    const { handleFile: uploadProofFn } = useSingleFileUpload({
        fileUpload: glCodeFile,
        setFileUpload: setGlCodeFile,
    })

    const { mutate: uploadGlCodeFn, isLoading } = useUploadGlCode({
        onSuccess: () => {
            nextStep?.()
        },
    })

    return (
        <section className='min-h-[15rem] w-[30rem] overflow-scroll '>
            <div className='flex items-center border-b border-[#DADCE0] p-2 px-4 justify-between'>
                <h2 className='text-center text-base font-medium opacity-80'>Import GL codes</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <div className='p-4'>
                <p className='text-gray7 text-sm mt-1'>
                    These codes will be used to map the categories in your accounting software, ensuring accurate and
                    organized financial records
                </p>
                <h4 className='text-sm border-b border-gray5 pb-2 font-medium mt-6'>Instructions</h4>
                <ul className='text-gray7 list-decimal list-inside space-y-1 mt-3 text-sm'>
                    <li>
                        Download csv template{' '}
                        <a
                            className='font-medium text-brand'
                            href='/gl-sample.csv'
                            download='Gl-sample.csv'
                            target='_blank'
                            rel='noreferrer'
                        >
                            here
                        </a>
                    </li>
                    <li>Fill in your GL codes: Open the template and fill in your GL codes.</li>
                    <li>Upload the completed file once you’ve filled in the template.</li>
                    <li>Click the “Import GL codes” button after uploading the file</li>
                </ul>
                <div className='bg-[#EFEFF9] rounded-lg px-4 py-2 mt-4 w-fit'>
                    <h5 className='font-medium text-sm'>Note</h5>
                    <p className='text-xs text-gray7'>
                        Ensure the GL codes match the categories in your accounting software (e.g., QuickBooks, Xero)
                        for smooth integration.
                    </p>
                </div>

                <label
                    htmlFor={'gl-code'}
                    className='cursor-pointer upload flex items-center justify-center border border-dashed rounded-lg border-[#DADCE0] h-[5rem] mt-5'
                >
                    <span className='text-center text-base text-[#A8A8A8] font-medium my-auto'>
                        {glCodeFile?.file_name && glCodeFile?.file_name?.length > 0
                            ? glCodeFile?.file_name
                            : ' Upload GL code'}
                        <br />
                        {glCodeFile?.file_name && glCodeFile?.file_name?.length > 0 ? (
                            <span className='text-sm'>
                                {glCodeFile?.size} |{' '}
                                <button
                                    className='font-semibold text-xs text-brand'
                                    onClick={() => glCodeRef?.current?.click()}
                                >
                                    Change document
                                </button>
                            </span>
                        ) : (
                            <span className='text-xs'>{'Max 2MB | Accepts .xls and .xlsx file only'}</span>
                        )}
                    </span>
                    <input
                        type='file'
                        id={'gl-code'}
                        ref={glCodeRef}
                        onChange={e => {
                            uploadProofFn(e)
                        }}
                        accept='.xls, .xlsx, .csv'
                        hidden
                    />
                </label>
            </div>

            <div className='border-t border-gray4 flex mt-6 justify-end items-center p-4 gap-4'>
                <Button onClick={prevStep} variant={'outline'} size={'lg'} className='text-xs min-w-[100px] '>
                    back
                </Button>
                <Button
                    onClick={() => {
                        const formdata = new FormData()
                        formdata.append('file', (glCodeFile?.fileBeforeFormData as Blob) ?? '')
                        uploadGlCodeFn(formdata)
                    }}
                    loading={isLoading}
                    disabled={glCodeFile && glCodeFile?.file_name?.length < 1}
                    size={'lg'}
                    className='text-xs'
                >
                    Import GL codes
                </Button>
            </div>
        </section>
    )
}

const ManageUploadedGLCodes = ({ closeModal, nextStep, prevStep }: Props) => {
    const { setHasGlCode, setOrgGlCode } = useAccountingContext()
    const { data, isLoading } = useGetGLCode({
        onSuccess: data => {
            if (data?.codes?.length > 0) {
                setHasGlCode(true)
                setOrgGlCode(data)
            }
        },
    })
    const orgGlCode = useMemo(() => data ?? ({} as OrgGlCodeType), [data])

    if (isLoading)
        return (
            <section className='min-h-[15rem] w-[27rem] grid place-items-center'>
                <CgSpinner color='#454ADE' size={100} className='animate-spin opacity-90' />
            </section>
        )
    return (
        <section className='min-h-[15rem] w-[27rem] overflow-scroll relative'>
            <div className='flex items-center border-b border-[#DADCE0] p-3 px-4 justify-between'>
                <h2 className='text-center text-lg font-medium opacity-80'>Preview uploaded GL codes</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <div className='mx-4 mt-3 mb-20'>
                <span className='inline-block bg-skyBlue/10 text-skyBlue rounded-full p-1.5  text-xs '>
                    {orgGlCode?.codes?.length} GL codes imported successfully
                </span>

                <div className='flex gap-3 mt-3 flex-wrap'>
                    {orgGlCode?.codes?.map(data => (
                        <span
                            key={data?.id}
                            className=' bg-gray7/10 text-gray7 rounded p-1.5 px-2  text-xs flex items-center  gap-1 '
                        >
                            {data?.code}
                            <button>
                                <X size={15} className='text-red-500' />
                            </button>
                        </span>
                    ))}
                </div>
            </div>
            <div className='bg-white border-t border-gray4 flex justify-end items-center py-2 px-4 gap-4 absolute bottom-0 w-full rounded-b-lg'>
                <Button onClick={prevStep} variant={'outline'} size={'lg'} className='text-xs min-w-[100px] '>
                    back
                </Button>
                <Button onClick={nextStep} size={'lg'} className='text-xs'>
                    Start accounting
                </Button>
            </div>
        </section>
    )
}
