import { Button, Input } from '@/components'
import { Control, Controller, FieldValues, UseFormGetValues, UseFormWatch } from 'react-hook-form'
import { emailPattern } from '@/const/regex'
import { useForgotPassword } from '@/services/auth/mutations'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

interface Step1Props {
    toggleStep: (step: number) => void
    control: Control<FieldValues, any>
    values: UseFormGetValues<FieldValues>
    watch: UseFormWatch<FieldValues>
}

const emailRegexExp =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

const ResetStep1 = ({ toggleStep, control, watch, values }: Step1Props) => {
    const { mutate: forgotPasswordFn, isLoading } = useForgotPassword({
        onSuccess: () => {
            toggleStep(2)
        },
    })

    const watchEmail = emailRegexExp.test(watch('email'))

    const nextStep = () => {
        forgotPasswordFn({ email: values()?.email })
    }

    const navigate = useNavigate()
    return (
        <div className='w-[90%] md:w-[45%] max-w-[1100px] mx-auto my-12'>
            <div className='flex justify-end mb-8'>
                <span className='py-2 px-4 bg-[#EFEFF9] rounded-2xl flex items-center gap-2'>
                    1 / 3 <span className='font-semibold'> steps</span>
                </span>
            </div>

            <section className='pt-4'>
                <h2 className='text-[#31254B] text-2xl mb-4 font-semibold'>Reset password</h2>
                <p className='text-[#202020] mb-6 text-base'>
                    Enter the email address associated with your account to change your password
                </p>

                <div className='flex flex-col md:flex-row mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Enter email address
                        </label>
                        <Controller
                            name='email'
                            rules={{
                                required: true,
                                validate: value => {
                                    return emailPattern.test(value)
                                },
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-16'
                                    type='email'
                                    placeholder='Please enter email address'
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='mt-[160px] flex justify-end gap-6'>
                    <button type='button' onClick={() => navigate(-1)} className='flex items-center gap-3 text-[14px]'>
                        <AiOutlineArrowLeft fontSize={15} className='mt-1' />
                        go back
                    </button>
                    <Button disabled={!watchEmail} variant='default' onClick={nextStep} loading={isLoading}>
                        Next
                    </Button>
                </div>
            </section>
        </div>
    )
}

export default ResetStep1
