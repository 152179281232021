import { getFullMonthName } from '@/modules/dashboard/Transactions/components/utils'
import { formatAmount } from '@/utils/money'

type CustomPieTooltipProps = {
    active?: any
    payload?: any
}
type CustomBarTooltipProps = {
    active?: any
    payload?: any
    label?: any
}

export const CustomPieTooltip = ({ active, payload }: CustomPieTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip m-0 p-3 bg-white border border-[#cccccc] whitespace-nowrap rounded-lg z-10'>
                <p className='label'>{`${payload[0].name}`}</p>
                <p className='label'>Amount : {`${formatAmount(payload[0].value)}`}</p>
            </div>
        )
    }

    return null
}
export const CustomPieTooltipNum = ({ active, payload }: CustomPieTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip m-0 p-3 bg-white border border-[#cccccc] whitespace-nowrap rounded-lg z-10'>
                <p className='label'>{`${payload[0].name}`}</p>
                <p className='label'>{payload[0].value}</p>
            </div>
        )
    }

    return null
}

export const CustomBarTooltip = ({ active, payload, label }: CustomBarTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip m-0 p-3 bg-white border border-[#cccccc] whitespace-nowrap rounded-lg'>
                <p className='label'>Month : {getFullMonthName(label)}</p>
                <p className='label'>Total : {`${formatAmount(payload[0].value)}`}</p>
                {payload.length > 1 && <p className='label'>Total2 : {`${formatAmount(payload[1].value)}`}</p>}
            </div>
        )
    }

    return null
}
export const CustomBarTooltip2 = ({ active, payload, label }: CustomBarTooltipProps) => {
    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip m-0 p-3 bg-white border border-[#cccccc] whitespace-nowrap rounded-lg'>
                <p className='label'>Month : {getFullMonthName(label)}</p>
                <p className='label'>Incoming : {`${formatAmount(payload[0].value)}`}</p>
                <p className='label'>Outgoing : {`${formatAmount(payload[1].value)}`}</p>
            </div>
        )
    }

    return null
}
