import { ArrowRight } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

type Props = {
    title: string
    title_exp: string
    to: string
}

export default function CompanySettingsBox({ title, title_exp, to }: Props) {
    const navigate = useNavigate()
    return (
        <section
            className='border-[#DADCE0] border rounded-md p-5 flex items-center justify-between text-[#202020]  max-w-[300px] min-w-full cursor-pointer gap-5'
            onClick={() => navigate(to)}
        >
            <div>
                <h3 className='font-semibold text-lg'>{title}</h3>
                <p className='text-[#838383] text-[14px] mt-3 w-[90%]'>{title_exp}</p>
            </div>
            <div>
                <ArrowRight className='w-[20px] h-[20px]' />
            </div>
        </section>
    )
}
