import { ExpenseType } from '@/types/expense'
import { format } from 'date-fns'
import { formatAmount } from '@/utils/money'
import { FlagIcon } from 'lucide-react'
import TransactionStatusBadge from '../../Transactions/components/TransactionStatusBadge'
import { TransactionStatus } from '../../Transactions/const'
import { TransactionType } from '@/types/transactions'

type Props = {
    expense: ExpenseType
}

export default function LinkedExpenseTransaction({ expense }: Props) {
    const transactionsData = expense?.transactions?.[0] as TransactionType
    return (
        <section className='bg-white rounded-xl py-6'>
            <section className='rounded-xl border border-[#DADCE0]  mx-0 mb-12 overflow-y-scroll'>
                {/* <table className='w-full leading-normal rounded-xl'>
                    <tr className='flex gap-6 text-left bg-[#f6f6f6] text-[#838383] font-light rounded-t-xl overflow-hidden'>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Expense ID</th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Date</th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Category </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Amount </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Created by{' '}
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Approver </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Status </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Type</th>
                    </tr>
                    <tr className='flex gap-6 text-[.9rem] '>
                        <td className='w-[7rem] px-4 py-4'>{expense?.expense_id}</td>
                        <td className='w-[7rem] px-4 py-4'>
                            {expense?.date ? format(new Date(expense?.date), 'dd/MM/yyyy hh:mm a') : 'N/A'}
                        </td>
                        <td className='w-[7rem] px-4 py-4'>{expense?.category?.label}</td>
                        <td className='w-[7rem] px-4 py-4 whitespace-nowrap'>- {formatAmount(expense?.amount)}</td>
                        <td className='w-[7rem] px-4 py-4'>
                            {expense?.submitter?.user?.first_name} {expense?.submitter?.user?.last_name?.[0]}
                        </td>
                        <td className='w-[7rem] px-4 py-4'>
                            {' '}
                            {expense?.approvers.map(approver => (
                                <p className='whitespace-normal truncate'>
                                    {approver?.account?.user?.first_name ?? 'N/A'}{' '}
                                    {approver?.account?.user?.last_name?.[0] ?? ''}{' '}
                                </p>
                            ))}
                            {expense?.approvers?.length === 0 && 'Auto-approval'}
                        </td>
                        <td className='w-[7rem] px-4 py-4 whitespace-nowrap'>
                            <ExpenseStatusBadge status={expense?.status as ExpenseStatus} />
                        </td>{' '}
                        <td className='w-[7rem] px-4 py-4'>{expense.transactions?.type} </td>
                    </tr>
                </table> */}
                <table className='w-full leading-normal rounded-xl'>
                    <tr className='flex gap-6 text-left bg-[#f6f6f6] text-[#838383] font-light rounded-t-xl overflow-hidden'>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Transaction ID
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Date & time
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Amount </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Initiated by{' '}
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Destination
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Type</th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Status </th>
                    </tr>
                    <tr className='flex gap-6 text-[.9rem] '>
                        <td className='w-[7rem] px-4 py-4'>
                            {transactionsData?.transaction_id}
                            {transactionsData?.flagged && <FlagIcon className='h-4 w-4' />}
                        </td>
                        <td className='w-[7rem] px-4 py-4'>
                            <p className='font-normal mb-1 text-sm'>
                                {format(new Date(transactionsData?.createdAt), 'dd/MM/yyyy')}
                            </p>
                            <p className='font-normal mb-1 text-sm'>
                                {format(new Date(transactionsData?.createdAt), 'hh:mm a')}
                            </p>
                        </td>
                        <td className='w-[7rem] px-4 py-4'>
                            <span className='font-normal whitespace-nowrap'>
                                {transactionsData.ownerTransactionType === 'DEBIT' ? (
                                    <span className='text-[#CF491E]'>
                                        {formatAmount(transactionsData.debit.amount)}
                                    </span>
                                ) : (
                                    <span className='text-[#19C37D]'>
                                        {formatAmount(transactionsData.credit.amount)}
                                    </span>
                                )}
                            </span>
                        </td>
                        <td className='w-[7rem] px-4 py-4'>
                            {' '}
                            {expense?.submitter?.user?.first_name} {expense?.submitter?.user?.last_name?.[0]}
                        </td>
                        <td className='w-[7rem] px-4 py-4'>N/A </td>
                        {/* <td className='w-[7rem] px-4 py-4 whitespace-nowrap'>- {formatAmount(expense?.amount)}</td> */}
                        {/* <td className='w-[7rem] px-4 py-4'>
                            {expense?.submitter?.user?.first_name} {expense?.submitter?.user?.last_name?.[0]}
                        </td> */}
                        {/* <td className='w-[7rem] px-4 py-4'>
                            {' '}
                            {expense?.approvers.map(approver => (
                                <p className='whitespace-normal truncate'>
                                    {approver?.account?.user?.first_name ?? 'N/A'}{' '}
                                    {approver?.account?.user?.last_name?.[0] ?? ''}{' '}
                                </p>
                            ))}
                            {expense?.approvers?.length === 0 && 'Auto-approval'}
                        </td> */}
                        <td className='w-[7rem] px-4 py-4'>{transactionsData?.type} </td>
                        <td className='w-[7rem] px-4 py-4 whitespace-nowrap'>
                            <TransactionStatusBadge
                                status={transactionsData.status.toLowerCase() as unknown as TransactionStatus}
                            />
                        </td>{' '}
                    </tr>
                </table>
            </section>
        </section>
    )
}
