import { useNavigate } from 'react-router-dom'
import ModalWrapper from '../ModalWrapper'

import { Button } from '@/components/Button'

export default function CompleteKYBModal() {
    const navigate = useNavigate()
    return (
        <ModalWrapper>
            <section className=' w-[33rem]  '>
                <h2 className='text-errorRed font-medium  p-4 text-lg '>Incomplete KYB</h2>

                <p className='text-sm text-gray7 px-4 leading-6'>
                    To ensure the highest level of security and compliance, we require all users to complete their KYB
                    (Know Your Business) verification.{' '}
                </p>

                <div className='border-t border-gray2/50 pt-4 pb-1 mt-6 flex justify-center gap-4 my-3'>
                    <Button
                        size={'lg'}
                        variant={'outline'}
                        className='min-w-[120px] border-gray4'
                        onClick={() => navigate(-1)}
                    >
                        Go back
                    </Button>
                    <Button size={'lg'} onClick={() => navigate('/dashboard/settings/company/kyb')}>
                        Complete KYB
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
