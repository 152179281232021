import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { formatDate, getDayOfWeek, getPayDay } from '@/modules/dashboard/Payroll/helper'
import { calculatePayrollSum } from '@/modules/dashboard/Payroll/utils'
import { useAddEmployeeToPayroll, useUpdatePayroll, useUpdatePayrollItem } from '@/services/payroll/mutations'
import { EmployeeType } from '@/types/org'
import { PaymentProfile, defaultAdditionProps } from '@/types/payroll'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { AlertCircleIcon, Minus } from 'lucide-react'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { AiOutlineClose } from 'react-icons/ai'
import { BsPlusSquare } from 'react-icons/bs'
import { TbCurrencyNaira } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import ModalWrapper from '../../ModalWrapper'
import { addToPayrollFields } from '../utils'
import BonusesAndDeductions from './BonusesAndDeductions'
import { EditPayrollView } from './EditPayrollView'
import { Plus } from 'lucide-react'
import { QueryPayrollOrgSettings } from '@/services/payroll/types'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    orgMembers: EmployeeType[]
    editPayroll?: boolean
    setEditPayRoll?: React.Dispatch<React.SetStateAction<boolean>>
    payrollDetails?: PaymentProfile
    editType?: string
    userPayrollId?: string
    orgPayrollSettings?: QueryPayrollOrgSettings
}

const defaultAdditions = {
    reason: '',
    amount: 0,
    type: '',
    id: uuidv4(),
    name: '',
    amountType: '',
}

const AddToPayrollModal = ({
    closeModal,
    orgMembers,
    editPayroll,
    setEditPayRoll,
    payrollDetails,
    editType,
    userPayrollId,
    orgPayrollSettings,
}: Props) => {
    const { selectedAccount } = useAuthContext()
    const ref = useRef<HTMLDivElement>(null)
    const calendarRef1 = useRef<DatePicker>(null)

    const formEmptyState = {
        employee: payrollDetails?.employee._id ?? payrollDetails?.employee ?? '',
        employment_type: payrollDetails?.employment_type ?? '',
        pay_day: payrollDetails?.pay_day ?? (0 as any),
        start_date: payrollDetails?.start_date ? new Date(payrollDetails?.start_date) : '',
        gross_salary: payrollDetails?.gross_salary ?? '',
        net_salary: payrollDetails?.net_salary ?? '',
        pay_frequency: payrollDetails?.pay_frequency ?? '',
        prorate: 'full_amount',
    }

    const navigate = useNavigate()

    const [formState, setFormState] = useState(formEmptyState)
    const [previewBonuses, setPreviewBonuses] = useState(0)
    const [previewDeductions, setPreviewDeductions] = useState(0)

    const [enableAddPayrollBtn, setEnableAddPayrollBtn] = useState(false)

    const formatAddons = (addonName: 'deduction' | 'bonus', addon?: defaultAdditionProps[]) => {
        return (
            addon?.map(data => {
                return {
                    reason: data?.name,
                    amount: data.type === 'percentage' ? Number(data?.amount || 0) * 100 : Number(data?.amount),
                    field_type: addonName,
                    id: uuidv4(),
                    name: data?.name?.toLowerCase(),
                    type: data?.type,
                    reccuring: data?.reccuring,
                }
            }) ?? []
        )
    }

    const [deductions, setDeductions] = useState<defaultAdditionProps[]>(() =>
        editPayroll
            ? formatAddons('deduction', payrollDetails?.deductions)
            : formatAddons('deduction', orgPayrollSettings?.default_deductions)
    )
    const [bonuses, setBonuses] = useState<defaultAdditionProps[]>(() =>
        editPayroll
            ? formatAddons('bonus', payrollDetails?.bonuses)
            : formatAddons('bonus', orgPayrollSettings?.default_bonuses)
    )

    const { mutate: updatePayrollFn, isLoading: isUpdatingPayroll } = useUpdatePayroll({
        queryParams: { payroll_id: (payrollDetails?.id ?? payrollDetails?._id) as string },
        onSuccess() {
            closeModal(false)
            setEditPayRoll?.(false)
            toastHandler({ message: 'User Payment Profile Updated', state: 'success' })
        },
    })

    const { mutate: updatePayrollItemFn, isLoading: isUpdatingPayrollItem } = useUpdatePayrollItem({
        queryParams: { payroll_id: userPayrollId as string },
        onSuccess() {
            closeModal(false)
            setEditPayRoll?.(false)
            toastHandler({ message: 'User payout details updated', state: 'success' })
        },
    })
    //
    const { mutate: addEmployeePayrollFn, isLoading } = useAddEmployeeToPayroll({
        queryParams: { org_id: selectedAccount?.org?.id as string },
        onSuccess() {
            closeModal(false)
            setEditPayRoll?.(false)
            toastHandler({ message: 'User Payment Profile Created', state: 'success' })
        },
    })

    const calculateDaysWorked = () => {
        if (formState.pay_frequency === 'monthly' && formState.start_date) {
            return moment(orgPayrollSettings?.monthly_period_end).diff(moment(formState.start_date), 'days')
        } else if (formState.pay_frequency === 'biweekly' && formState.start_date) {
            return moment(orgPayrollSettings?.biweekly_period_end).diff(moment(formState.start_date), 'days')
        } else if (formState.pay_frequency === 'weekly' && formState.start_date) {
            return moment(orgPayrollSettings?.weekly_period_end).diff(moment(formState.start_date), 'days')
        } else {
            return 0
        }
    }

    const calculateCurrentWorkableDays = () => {
        if (formState.pay_frequency === 'monthly') {
            return moment(orgPayrollSettings?.monthly_period_end).diff(
                moment(orgPayrollSettings?.monthly_period_start),
                'days'
            )
        } else if (formState.pay_frequency === 'biweekly') {
            return moment(orgPayrollSettings?.biweekly_period_end).diff(
                moment(orgPayrollSettings?.biweekly_period_start),
                'days'
            )
        } else if (formState.pay_frequency === 'weekly') {
            return moment(orgPayrollSettings?.weekly_period_end).diff(
                moment(orgPayrollSettings?.weekly_period_start),
                'days'
            )
        }
        return 0
    }

    const calculateMaxDate = useMemo(() => {
        if (formState.pay_frequency === 'monthly') {
            return new Date(orgPayrollSettings?.monthly_period_end as string)
        }
        if (formState.pay_frequency === 'biweekly') {
            return new Date(orgPayrollSettings?.biweekly_period_end as string)
        }
        if (formState.pay_frequency === 'weekly') {
            return new Date(orgPayrollSettings?.weekly_period_end as string)
        }
        return new Date()
    }, [formState.pay_frequency, orgPayrollSettings])

    const days_worked = calculateDaysWorked()
    const [payoutDaysWorked, setPayoutDaysWorked] = useState(Number(payrollDetails?.days_worked))
    const current_workable_days = calculateCurrentWorkableDays()

    const can_prorate = days_worked > 0 && days_worked < current_workable_days

    const max_work_days = calculateCurrentWorkableDays()

    const proratedAmount = useMemo(() => {
        const proratedSalary = (Number(formState.gross_salary) / max_work_days) * days_worked

        const totalBonuses = bonuses.reduce((total, bonus) => {
            return total + (bonus.type === 'percentage' ? proratedSalary * (bonus.amount / 100) : bonus.amount)
        }, 0)

        const totalDeductions = deductions.reduce((total, deduction) => {
            return (
                total + (deduction.type === 'percentage' ? proratedSalary * (deduction.amount / 100) : deduction.amount)
            )
        }, 0)

        return proratedSalary + totalBonuses - totalDeductions
    }, [formState.gross_salary, max_work_days, days_worked, bonuses, deductions])

    const handleAddToPayroll = () => {
        const deductionType = deductions.map(data => ({
            name: data?.reason ?? data?.name,
            type: data.type,
            amount: Number(data?.amount) / 100,
            reccuring: data.reccuring,
            // amount: data?.amountType === 'percentage' ? Number(data?.amount) : Number(data?.amount) / 100,
            org: selectedAccount?.org.id,
        }))

        const bonusType = bonuses.map(data => ({
            name: data?.reason ?? data?.name,
            type: data.type,
            amount: Number(data?.amount) / 100,
            reccuring: data.reccuring,
            // amount: data?.amountType === 'percentage' ? Number(data?.amount) : Number(data?.amount) / 100,
            org: selectedAccount?.org.id,
        }))
        const data_to_beSubmitted = {
            ...formState,
            pay_day: Number(formState?.pay_day),
            gross_salary: Number(formState?.gross_salary) / 100,
            net_salary: Number(formState?.net_salary) / 100,
            deductions: deductionType,
            bonuses: bonusType,
            prorate: formState?.prorate === 'full_amount' ? false : true,
            amount_to_prorate: proratedAmount / 100,
            org: selectedAccount?.org.id,
        }

        editPayroll
            ? editType === 'payout'
                ? updatePayrollItemFn({
                      ...data_to_beSubmitted,
                      days_worked: payoutDaysWorked,
                  })
                : updatePayrollFn(data_to_beSubmitted)
            : addEmployeePayrollFn(data_to_beSubmitted)
    }

    useEffect(() => {
        let net_salary = 0
        const grossSalary = Number(formState?.gross_salary)
        const correctDeductions = deductions?.map(deduct => ({
            ...deduct,
            amount: deduct?.type == 'percentage' ? Number(deduct?.amount) / 100 : deduct?.amount,
        }))
        const correctBonuses = bonuses?.map(bonus => ({
            ...bonus,
            amount: bonus?.type == 'percentage' ? Number(bonus?.amount) / 100 : bonus?.amount,
        }))
        const totalDeductions = calculatePayrollSum(correctDeductions, 'amount', grossSalary)
        const totalBonuses = calculatePayrollSum(correctBonuses, 'amount', grossSalary)

        setPreviewBonuses(totalBonuses)
        setPreviewDeductions(totalDeductions)
        net_salary = grossSalary + totalBonuses - totalDeductions

        setFormState(prev => ({ ...prev, net_salary: net_salary.toString() }))
    }, [deductions, bonuses, formState?.gross_salary])

    useEffect(() => {
        if (formState.pay_frequency === 'monthly') {
            setFormState(prev => ({ ...prev, pay_day: orgPayrollSettings?.monthly_payment_day as number }))
        }
        if (formState.pay_frequency === 'biweekly') {
            setFormState(prev => ({
                ...prev,
                pay_day:
                    orgPayrollSettings?.biweekly_payment_days &&
                    (getPayDay(orgPayrollSettings?.biweekly_payment_days).closest as number),
            }))
        }

        if (formState.pay_frequency === 'weekly') {
            setFormState(prev => ({
                ...prev,
                pay_day:
                    orgPayrollSettings?.weekly_payment_day &&
                    (getDayOfWeek(orgPayrollSettings?.weekly_payment_day).last_pay_day.getDate() as number),
            }))
        }
    }, [
        formState.pay_frequency,
        orgPayrollSettings?.biweekly_payment_days,
        orgPayrollSettings?.weekly_payment_day,
        orgPayrollSettings?.monthly_payment_day,
    ])

    useEffect(() => {
        const checkIfAllFieldsAreFilled = Object.values(formState).every(value => value?.toString()?.length > 0)
        const checkifDeductionsAreFilled = deductions.every(
            data => !!data.amount && !!data?.type && (data?.reason ?? data?.name)
        )
        const checkifBonusesAreFilled = bonuses.every(
            data => !!data.amount && !!data?.type && (data?.reason ?? data?.name)
        )

        if (checkifDeductionsAreFilled && checkIfAllFieldsAreFilled && checkifBonusesAreFilled) {
            setEnableAddPayrollBtn(true)
            return
        }
        setEnableAddPayrollBtn(false)
    }, [formState, bonuses, deductions, payrollDetails, enableAddPayrollBtn])

    return (
        <ModalWrapper
            variants={undefined}
            formId='createPaymentProfile'
            closeModal={() => closeModal(false)}
            shouldConfirmClose
        >
            <div ref={ref}>
                <div className='w-screen lg:w-[55rem] min-h-[70vh] max-h-[90vh] lg:max-h-[95vh] overflow-scroll  relative'>
                    <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                        <div className='flex items-center w-full lg:w-[58%] justify-between h-full'>
                            <h2 className='font-semibold text-[#31254B]' id='create_payroll_header'>
                                {editType !== 'payout'
                                    ? editPayroll
                                        ? 'Edit Payment Profile'
                                        : 'Create Payment Profile'
                                    : 'Edit Payout'}
                            </h2>
                            <div
                                className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer mr-8'
                                onClick={() => {
                                    closeModal(false)
                                    setEditPayRoll?.(false)
                                }}
                            >
                                <AiOutlineClose fontSize={25} />
                            </div>
                        </div>
                    </div>
                    <form
                        id='createPaymentProfile'
                        onSubmit={(e: React.FormEvent) => e.preventDefault()}
                        className='max-h-[98%] overflow-scroll'
                    >
                        <div className='p-6 px-3 lg:px-10 pb-3 mb-32'>
                            {editType === 'payout' && (
                                <div className=''>
                                    <EditPayrollView
                                        orgMembers={orgMembers}
                                        payrollDetails={payrollDetails}
                                        formState={formState}
                                        totalBonus={previewBonuses}
                                        totalDeductions={previewDeductions}
                                        editType={editType}
                                    />
                                    <div className='relative my-5'>
                                        <label className='font-normal pb-3 inline-block' htmlFor='accnt_number'>
                                            Days worked
                                            <span>*</span>
                                        </label>
                                        <div className='flex border border-[#DADCE0] rounded-lg gap-6 w-[147px] p-4'>
                                            <button
                                                aria-label='Icon'
                                                disabled={payoutDaysWorked === 1}
                                                className='disabled:opacity-30 disabled:cursor-not-allowed'
                                            >
                                                <Minus
                                                    color='#5E5E5E'
                                                    className='cursor-pointer'
                                                    onClick={() => setPayoutDaysWorked(payoutDaysWorked - 1)}
                                                />
                                            </button>
                                            {payoutDaysWorked}
                                            <button
                                                aria-label='Icon'
                                                disabled={payoutDaysWorked == 30}
                                                className='disabled:opacity-30 disabled:cursor-not-allowed'
                                            >
                                                <Plus
                                                    color='#5E5E5E'
                                                    className='cursor-pointer'
                                                    onClick={() => setPayoutDaysWorked(payoutDaysWorked + 1)}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='bg-[#EBF5FF] py-3 px-2 rounded-lg'>
                                        <p className='text-gray7 font-medium'>
                                            Please note that any additional deductions or bonuses you add to this
                                            current payout will be one-off and will only impact this current payout
                                            cycle, ensuring that the changes made here won't carry forward to affect the
                                            user's next payout.
                                        </p>
                                    </div>
                                </div>
                            )}
                            {editType !== 'payout' && (
                                <>
                                    <div className={`flex flex-col md:flex-row gap-x-5 gap-y-6 flex-wrap`}>
                                        {addToPayrollFields.map(field => (
                                            <div className='flex flex-col flex-1 gap-3'>
                                                <label className='flex items-center gap-1'>
                                                    {field.title}
                                                    <span className='text-[red]'>*</span>
                                                </label>
                                                {field.fieldType === 'amount' && (
                                                    <Input
                                                        type={'text'}
                                                        name={field.name}
                                                        value={formatAmount(
                                                            formState[field.name as keyof typeof formState] as string
                                                        ).replaceAll('₦', '')}
                                                        placeholder={field.placeholder}
                                                        className='border-[#DADCE0] w-[390px]  h-[3rem] disabled:bg-[#F9F9F9]'
                                                        onChange={e => {
                                                            const { name } = e.target
                                                            let value = e.target.value

                                                            if (field.fieldType === 'amount') {
                                                                value = formatAmountWithoutSymbols(value)
                                                            }

                                                            setFormState(prevState => ({
                                                                ...prevState,
                                                                [name]: value,
                                                            }))
                                                        }}
                                                        leftIcon={<TbCurrencyNaira fontSize={25} color={'#838383'} />}
                                                        disabled={field?.name === 'net_salary'}
                                                    />
                                                )}
                                                {field.fieldType === 'dropdown' && (
                                                    <Select
                                                        name={field.name}
                                                        onValueChange={value => {
                                                            setFormState(prevState => ({
                                                                ...prevState,
                                                                [field.name]: value,
                                                            }))
                                                        }}
                                                        value={
                                                            formState[field.name as keyof typeof formState] as string
                                                        }
                                                    >
                                                        {/* {JSON.stringify(formState[field.name as keyof typeof formState])} */}
                                                        <SelectTrigger
                                                            className='w-[390px] h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'
                                                            id={field.name}
                                                        >
                                                            {formState?.[field?.name as keyof typeof formState] ? (
                                                                <SelectValue />
                                                            ) : (
                                                                <span>{field.placeholder}</span>
                                                            )}
                                                        </SelectTrigger>
                                                        <SelectContent
                                                            className='w-[18.9rem] max-h-[200px] overflow-scroll'
                                                            id={`${field.name}_content`}
                                                        >
                                                            {field.name === 'employee'
                                                                ? orgMembers.map(member => (
                                                                      <div
                                                                          className='flex items-center justify-between w-full'
                                                                          key={member.id}
                                                                      >
                                                                          <SelectItem
                                                                              value={member.id}
                                                                              disabled={!member?.bank}
                                                                              className='w-full'
                                                                          >
                                                                              {capitalizeText(member?.user?.first_name)}{' '}
                                                                              {capitalizeText(member?.user?.last_name)}
                                                                          </SelectItem>
                                                                          {!member?.bank && (
                                                                              <TooltipProvider delayDuration={10}>
                                                                                  <Tooltip>
                                                                                      <TooltipTrigger className='mr-4'>
                                                                                          <AlertCircleIcon size={15} />
                                                                                      </TooltipTrigger>

                                                                                      <TooltipContent>
                                                                                          <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                                                              User doesn't have a bank
                                                                                              added
                                                                                          </p>
                                                                                      </TooltipContent>
                                                                                  </Tooltip>
                                                                              </TooltipProvider>
                                                                          )}
                                                                      </div>
                                                                  ))
                                                                : field.fieldOptions?.map(option => (
                                                                      <SelectItem value={option?.desc}>
                                                                          {option?.name}
                                                                      </SelectItem>
                                                                  ))}
                                                        </SelectContent>
                                                    </Select>
                                                )}
                                                {field.fieldType === 'date' && (
                                                    <CustomDatePicker
                                                        {...field}
                                                        ref={calendarRef1}
                                                        placeholderText='DD/MM/YYYY'
                                                        onChange={date =>
                                                            setFormState(prev => ({
                                                                ...prev,
                                                                [field.name]: date! as unknown as string,
                                                            }))
                                                        }
                                                        maxDate={calculateMaxDate}
                                                        selected={
                                                            formState[field.name as keyof typeof formState]
                                                                ? new Date(
                                                                      formState[
                                                                          field.name as keyof typeof formState
                                                                      ] as string
                                                                  )
                                                                : undefined
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <p className='mt-2 text-[#A89011] text-sm'>
                                        {formState.pay_frequency !== '' && (
                                            <>
                                                Please note that your current pay day is set for{' '}
                                                {formState.pay_frequency == 'monthly' && (
                                                    <>
                                                        {orgPayrollSettings?.monthly_payment_day}
                                                        {formatDate(orgPayrollSettings?.monthly_payment_day)} of every
                                                        month.{' '}
                                                    </>
                                                )}
                                                {formState.pay_frequency == 'weekly' && (
                                                    <>every {orgPayrollSettings?.weekly_payment_day} of the week. </>
                                                )}
                                                {formState.pay_frequency == 'biweekly' && (
                                                    <>every second {orgPayrollSettings?.biweekly_payment_day} .</>
                                                )}
                                                You can change that{' '}
                                                <span
                                                    className='text-brand cursor-pointer'
                                                    onClick={() => {
                                                        closeModal(false)
                                                        navigate('/dashboard/payroll/setings?accountType=company')
                                                    }}
                                                >
                                                    here
                                                </span>
                                            </>
                                        )}
                                    </p>
                                </>
                            )}

                            {/* same thing */}
                            {can_prorate && (
                                <section className='bg-[#EFEFF9] p-3 rounded-xl my-8 text-[14px]'>
                                    <p className='text-[#5E5E5E] '>
                                        We noticed the number of days ({days_worked} days) between the selected start
                                        date and the desired pay day are not up to {current_workable_days} working days.
                                        Would you like to prorate?
                                    </p>
                                    <h4 className='mt-3 font-medium mb-1'>
                                        {' '}
                                        Prorated salary = {formatAmount(proratedAmount)}
                                    </h4>
                                    <div className='flex gap-1 mb-1 mt-3'>
                                        <input
                                            type='radio'
                                            name='porate'
                                            id='first'
                                            className='accent-[#454ADE] cursor-pointer'
                                            onChange={() => {
                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    prorate: 'prorate',
                                                }))
                                            }}
                                        />
                                        <label className='text-[#5e5e5e]' htmlFor='first'>
                                            Pay{' '}
                                            <b className='font-medium text-[#000]'>{formatAmount(proratedAmount)}</b>{' '}
                                            for first pay then{' '}
                                            <b className='font-medium text-[#000]'>
                                                {' '}
                                                {formatAmount(formState.net_salary)}
                                            </b>{' '}
                                            subsequently
                                        </label>
                                    </div>
                                    <div className='flex gap-1'>
                                        <input
                                            type='radio'
                                            name='porate'
                                            id='second'
                                            className='accent-[#454ADE] cursor-pointer'
                                            onChange={() => {
                                                setFormState(prevState => ({
                                                    ...prevState,
                                                    prorate: 'full_amount',
                                                }))
                                            }}
                                        />
                                        <label className='text-[#5e5e5e]' htmlFor='second'>
                                            Pay full{' '}
                                            <b className='font-medium text-[#000]'>
                                                {' '}
                                                {formatAmount(formState.net_salary)}
                                            </b>{' '}
                                            for first pay
                                        </label>
                                    </div>
                                </section>
                            )}
                            <section className='mt-10'>
                                <h3 className='text-[#5E5E5E] font-medium border-b border-[#DADCE0] pb-2 mt-4'>
                                    Deductions
                                </h3>
                            </section>
                            <div
                                className={`flex flex-col gap-x-5 gap-y-1 flex-wrap mt-4 bg-[#F9F9F9] rounded-xl 4 ${
                                    deductions.length == 0 ? '' : 'py-2'
                                }`}
                            >
                                {deductions?.map((deduction, idx) => (
                                    <BonusesAndDeductions
                                        fieldDetail={deduction}
                                        setFieldDetails={setDeductions}
                                        key={idx}
                                        name={`Deduction ${idx + 1}`}
                                        net_salary={formState.net_salary}
                                        gross_salary={formState.gross_salary}
                                        defaultBonusDeduc={formatAddons(
                                            'deduction',
                                            orgPayrollSettings?.default_deductions
                                        )}
                                    />
                                ))}
                            </div>

                            <button
                                className={`flex gap-2 items-center text-[#5E5E5E] py-3 px-5 rounded-lg border border-[#A8A8A8] text-sm ${
                                    deductions.length == 0 ? '' : 'mt-6'
                                }`}
                                onClick={() =>
                                    setDeductions([
                                        ...deductions,
                                        {
                                            ...defaultAdditions,
                                            id: uuidv4(),
                                            name: `deductions-${deductions.length}`,
                                            type: 'amount',
                                            field_type: 'deduction',
                                            reccuring: undefined,
                                        },
                                    ])
                                }
                            >
                                Add {deductions.length > 0 && 'another'} deduction
                                <BsPlusSquare color='#5E5E5E' />
                            </button>

                            <section>
                                <h3 className='text-[#5E5E5E] font-medium border-b border-[#DADCE0] pb-2 mt-8'>
                                    Bonuses
                                </h3>
                            </section>
                            <div
                                className={`flex flex-col gap-x-5 gap-y-1 flex-wrap mt-4 bg-[#F9F9F9] rounded-xl ${
                                    bonuses.length == 0 ? '' : 'py-2'
                                }`}
                            >
                                {bonuses?.map((bonus, idx) => (
                                    <BonusesAndDeductions
                                        fieldDetail={bonus}
                                        setFieldDetails={setBonuses}
                                        key={idx}
                                        name={`Bonus ${idx + 1}`}
                                        net_salary={formState.net_salary}
                                        gross_salary={formState.gross_salary}
                                        defaultBonusDeduc={formatAddons(
                                            'deduction',
                                            orgPayrollSettings?.default_bonuses
                                        )}
                                    />
                                ))}
                            </div>
                            <button
                                className={`mb-0 flex gap-2 items-center text-[#5E5E5E] py-3 px-5 rounded-lg border border-[#A8A8A8] text-sm ${
                                    bonuses.length == 0 ? '' : 'mt-6'
                                }`}
                                onClick={() =>
                                    setBonuses([
                                        ...bonuses,
                                        {
                                            ...defaultAdditions,
                                            id: uuidv4(),
                                            name: `bonus-${bonuses.length}`,
                                            type: 'amount',
                                            field_type: 'bonus',
                                            reccuring: undefined,
                                        },
                                    ])
                                }
                            >
                                Add {bonuses.length > 0 && 'another'} bonus
                                <BsPlusSquare color='#5E5E5E' />
                            </button>
                        </div>

                        <div></div>
                    </form>
                </div>
                <div className='flex pt-5 items-center justify-end gap-4 px-6 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg'>
                    <Button
                        className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-base hover:bg-transparent min-w-[100px]'
                        onClick={() => {
                            closeModal(false)
                            setEditPayRoll?.(false)
                        }}
                    >
                        cancel
                    </Button>
                    <Button
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        // disabled={!enableAddPayrollBtn}
                        onClick={handleAddToPayroll}
                        loading={editPayroll ? isUpdatingPayroll || isUpdatingPayrollItem : isLoading}
                    >
                        {editPayroll
                            ? editType === 'payout'
                                ? 'Update user payout'
                                : 'Update Payment Profile'
                            : 'Save Profile'}
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default AddToPayrollModal
