import { CalendarIcon } from '@/assets/assets.export'
import { cn } from '@/lib/styling'
import React, { RefObject } from 'react'
import DatePicker, { ReactDatePicker, ReactDatePickerProps } from 'react-datepicker'

interface Props extends ReactDatePickerProps {
    className?: string
    wrapperClassName?: string
    onIconClicked?: () => void
}
const CustomDatePicker = React.forwardRef<ReactDatePicker, Props>(
    ({ className, wrapperClassName, onIconClicked, disabled, ...props }, ref) => {
        const dateRef = ref as RefObject<DatePicker>

        return (
            <div
                className={cn(
                    'gap-1 flex justify-between items-center border-[#DADCE0] border hover:border-brand hover:border-2 py-2 text-sm  cursor-pointer w-full px-3 h-[3rem] rounded-md',
                    disabled ? 'bg-gray2/10 hover:border-[#DADCE0] hover:border cursor-not-allowed' : '',
                    wrapperClassName
                )}
                onClick={() => {
                    onIconClicked?.()
                    dateRef?.current?.setOpen(true)
                }}
            >
                <DatePicker
                    ref={ref}
                    placeholderText='DD/MM/YYYY'
                    className={cn(
                        'placeholder:text-[#A8A8A8]  text-dark rounded-md  bg-transparent outline-none disabled:bg-gray2/10 disabled:cursor-not-allowed',
                        className
                    )}
                    disabled={disabled}
                    shouldCloseOnSelect={true}
                    onSelect={(_, e) => {
                        e?.stopPropagation()
                        dateRef?.current?.setOpen(false)
                    }}
                    dateFormat={'dd/MM/yyyy'}
                    {...props}
                />
                <CalendarIcon
                    onClick={() => {
                        onIconClicked?.()
                        dateRef?.current?.setOpen(true)
                    }}
                />
            </div>
        )
    }
)
export default CustomDatePicker
