import { useAuthContext } from '@/context'
import { usePaystackPayment } from 'react-paystack'

type Props = {
    amt?: number | string
    fee?: number
    customConfig?: any
}
export const usePaystack = ({ amt, fee, customConfig }: Props) => {
    const { selectedAccount } = useAuthContext()

    const config = {
        reference: new Date().getTime().toString(),
        email: selectedAccount?.user?.email as string,
        amount: (Number(amt) + fee!) * 100, //convert to kobo here
        publicKey: import.meta.env.VITE_PAYSTACK_PUB_KEY,
    }

    const initializePayment = usePaystackPayment(customConfig ? customConfig : config)

    return { initializePayment }
}
