import React, { useState } from 'react'
import { createColumnHelper, ColumnDef, ColumnSort } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import { MdDelete } from 'react-icons/md'
// import CheckBox from '@/components/Table/CheckBox'
import { AiOutlineEdit } from 'react-icons/ai'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { useEmployeesContext } from '@/context'
import { TeamMembersProp, TeamsProp } from '@/types/employee'
import { useNavigate } from 'react-router-dom'
import { teamBadge } from '../teamBadgeColors'
import DeleteTeam from '@/components/Modals/Employee/DeleteTeam'
import CreateTeamModal from '@/components/Modals/Employee/CreateTeamModal/CreateTeamModal'
import { IoMdCheckmark } from 'react-icons/io'

export type EmployeeType = {
    name: string
    role: string
    team: string
    emailAddress: string
    date: string
}
interface EmployeeTeamProps {
    teams: TeamsProp[]
    members?: EmployeeType[]
}

const EmployeeTeamsTable = ({ teams: totalTeamData }: EmployeeTeamProps) => {
    const { setShowTeamBreadCrumbs, setTeamsObj, setFilterTeamsBy, filterTeamsBy } = useEmployeesContext()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [team, setTeam] = useState<TeamsProp>()

    const navigate = useNavigate()
    const columnHelper = createColumnHelper<TeamsProp>()

    const [rowSelection, setRowSelection] = React.useState({})

    const handleSort = (sort: ColumnSort) => {
        if (sort !== filterTeamsBy?.bySort) {
            setFilterTeamsBy(prev => ({ ...prev, bySort: sort }))
        }
    }

    const getTeamLead = (users: TeamMembersProp[]) => {
        let team_lead_data: TeamMembersProp | undefined
        users?.map(user => {
            if (user?.isTeamLead) {
                team_lead_data = user
            }
        })
        return team_lead_data
    }

    const columns: ColumnDef<TeamsProp, any>[] = [
        // columnHelper.display({
        //     id: 'select',
        //     header: ({ table }) => (
        //         <CheckBox
        //             {...{
        //                 checked: table?.getIsAllRowsSelected(),
        //                 indeterminate: table?.getIsSomeRowsSelected(),
        //                 onChange: table?.getToggleAllRowsSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <CheckBox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        //     enableSorting: false,
        // }),
        columnHelper.accessor('name', {
            header: () => <p>Name</p>,
            cell: cell => (
                <span
                    style={{
                        color: teamBadge[cell.renderValue()[0].toLowerCase() as keyof typeof teamBadge],
                        display: 'inline-block',
                    }}
                >
                    {cell.renderValue()}
                </span>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('users', {
            header: () => <p>Users</p>,
            cell: cell => {
                let members_count = { owner: 0, admins: 0, users: 0 }
                cell?.renderValue()?.map((member: TeamMembersProp) => {
                    if (member?.account?.role?.name === 'OWNER') {
                        members_count = { ...members_count, owner: members_count.owner + 1 }
                    } else if (member?.account?.role?.name === 'ADMIN') {
                        members_count = { ...members_count, admins: members_count.admins + 1 }
                    } else {
                        members_count = { ...members_count, users: members_count.users + 1 }
                    }
                })
                return (
                    <>
                        <p>{cell?.renderValue()?.length}</p>
                        <p className='text-[#838383] mt-2'>
                            {members_count?.owner} Owner{members_count?.owner > 1 && 's'}, {members_count?.admins} Admin
                            {members_count?.admins > 1 && 's'}, {members_count?.users} User
                            {members_count?.users > 1 && 's'}
                        </p>
                    </>
                )
            },
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'members',
            header: () => <p>Created by</p>,
            cell: cell => {
                const data = getTeamLead(cell?.row?.original?.users)

                return (
                    <>
                        <p>
                            {data?.account?.user?.first_name} {data?.account?.user?.last_name}
                        </p>
                        <p className='text-[#838383] mt-2'>
                            {data?.account && capitalizeText(data?.account.role?.name)}
                        </p>
                    </>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <p>Date created</p>,
            cell: cell => cell?.renderValue() && format(new Date(cell?.renderValue() as string), 'MMM dd, yyyy'),
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'View Dept',
                                icon: IoMdCheckmark,
                                method: () => {
                                    // console.log(row.row.original)
                                    setShowTeamBreadCrumbs(true)
                                    setTeamsObj(row?.row?.original)
                                    navigate(row?.row?.original?.id ?? row?.row?.original?._id)
                                },
                            },
                            {
                                label: 'Edit Dept',
                                icon: AiOutlineEdit,
                                method: () => {
                                    setOpenEditModal(true)
                                    setTeamsObj(row?.row?.original)
                                    // navigate(row?.row?.original?.id)
                                },
                            },
                            {
                                label: 'Delete',
                                icon: MdDelete,
                                method: () => {
                                    setOpenDeleteModal(true)
                                    setTeam?.(row?.row.original)
                                },
                                actionColor: 'text-[#CF491E]',
                            },
                        ]}
                    />
                )
            },
        }),
    ]
    const handleRowClick = (row: TeamsProp) => {
        setShowTeamBreadCrumbs(true)
        setTeamsObj(row)
        navigate(row?.id ?? row?._id)
    }

    return (
        <>
            {openDeleteModal && <DeleteTeam team={team} setIsDeleteDialogOpen={setOpenDeleteModal} />}
            {openEditModal && <CreateTeamModal closeModal={setOpenEditModal} edit={true} />}
            <Table
                data={totalTeamData}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleSort={handleSort}
                handleRowClicked={handleRowClick}
                rowClickable={true}
            />
        </>
    )
}

export default EmployeeTeamsTable
