import { ImpersoationSVG } from '@/assets/assets.export'
import RevokeImpersonation from '@/components/Modals/Impersonation/RevokeImpersonation'
import AddNewBusinessModal from '@/components/Modals/NewBusiness/AddNewBusinessModal'
import { NotificationsModal } from '@/components/Modals/Notifications/NotificationsModal'
import DropdownSwitchAccount from '@/components/Modals/SwitchAccounts/DropdownSwitch'
import { useAuthContext, useImpersonationContext } from '@/context'
import { useGetOrgNotifications, useGetPersonalNotifications } from '@/services/notifications/queries'
import { NotificationType } from '@/services/notifications/types'
import { MenuIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

type HeaderProps = {
    headerText: string
    setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>
}

const Header = ({ headerText, setShowSidebar }: HeaderProps) => {
    const [showNewBusinessModal, setShowNewBusinessModal] = useState(false)
    const [showRevokeModal, setShowRevokeModal] = useState(false)
    const [orgNotifications, setOrgNotifications] = useState<NotificationType[]>([])
    const [personalNotifications, setPersonalNotifications] = useState<NotificationType[]>([])

    const { impersonationMode } = useImpersonationContext()
    const { selectedAccount } = useAuthContext()

    const { data: orgNots } = useGetOrgNotifications({
        queryParams: {
            org: selectedAccount?.org?.id as string,
        },
        onSuccess: data => {
            setOrgNotifications(data)
        },
    })
    const { data: personalNots } = useGetPersonalNotifications({
        queryParams: {
            account: selectedAccount?.id as string,
        },
        onSuccess: data => {
            setPersonalNotifications(data)
        },
    })

    // console.log({ orgNotifications, personalNotifications })
    useEffect(() => {
        setOrgNotifications(orgNots as NotificationType[])
        setPersonalNotifications(personalNots as NotificationType[])
    }, [orgNots, personalNots])

    return (
        <div className='relative sm:h-[81px] border-b border-[#DADCE0] bg-[#ffffff] flex items-center justify-between px-0 lg:px-[2.9rem] py-3 sm:py-8 z-[1000px]'>
            <div className='flex items-center gap-4 flex-1'>
                <MenuIcon
                    color='#838383'
                    className='cursor-pointer block lg:hidden'
                    onClick={() => {
                        setShowSidebar(true)
                    }}
                />
                <h2 className='block font-medium lg:font-semibold text-lg lg:text-2xl text-center sm:text-left flex-1'>
                    {headerText ?? 'Dashboard'}
                </h2>
                {impersonationMode && (
                    <button onClick={() => setShowRevokeModal(true)}>
                        <ImpersoationSVG />
                    </button>
                )}
            </div>

            <div className='flex items-center gap-3'>
                <div className='flex items-center gap-3'>
                    <div className='py-2 px-3 border-x border-[#DADCE0] hidden'>
                        <NotificationsModal orgNots={orgNotifications} personalNots={personalNotifications} />
                    </div>
                    <DropdownSwitchAccount addNewBusinessModal={setShowNewBusinessModal} />
                </div>
            </div>
            {showRevokeModal && <RevokeImpersonation closeModal={setShowRevokeModal} />}
            {showNewBusinessModal && <AddNewBusinessModal closeNewBusinessModal={setShowNewBusinessModal} />}
        </div>
    )
}

export default Header
