import { Button, Input } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useUpdateVaultPassword } from '@/services/vault/mutations'
import { useGetAllVaults } from '@/services/vault/queries'
import classNames from 'classnames'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

type FormType = {
    current_password: string
    new_password: string
    confirm_password: string
}

export const VaultSecurity = () => {
    const [edit, setEdit] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const handleShowPassword = () => {
        setShowPassword(prev => !prev)
    }

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { isValid },
    } = useForm<FormType>({})

    const newP = watch('new_password')
    const confirmP = watch('confirm_password')
    const dontMatch = confirmP?.length > 0 ? newP !== confirmP : false

    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]

    const { mutate: updateFn, isLoading } = useUpdateVaultPassword({
        onSuccess: () => {
            toastHandler({ message: 'Vault password updated sucessfully', state: 'success' })
            setEdit(false)
        },
    })
    const onSubmit = async (values: FormType) => {
        updateFn({
            id: singleVault?.id,
            current_password: values.current_password,
            new_password: values.new_password,
        })
        reset()
    }

    return (
        <div>
            <div className='flex justify-between gap-3 flex-col lg:flex-row'>
                <div className=''>
                    <h1 className='text-dark font-medium text-lg'>Security</h1>
                    <p className='text-gray7 text-sm'>Manage passwords to secure access to personal folders.</p>
                </div>
                {!edit && (
                    <span
                        className='flex items-center gap-3 h-fit py-2 text-[#5E5E5E] border-[#DADCE0] hover:border-gray7 rounded-md p-0 border px-2 cursor-pointer w-fit'
                        onClick={() => {
                            setEdit(true)
                        }}
                    >
                        <button className='text-sm py-0' type='button'>
                            Change password
                        </button>
                    </span>
                )}
            </div>
            <div
                className={classNames(
                    'w-full border border-gray8 rounded-xl py-4 px-5 mt-8',
                    isLoadingVaults && 'opacity-30'
                )}
            >
                {!edit && (
                    <>
                        <p className='text-sm font-medium text-gray7'>Password</p>
                        <div className='mt-4 flex gap-1 items-center'>
                            {[1, 2, 3, 4, 5, 6].map(idx => (
                                <p key={idx} className='h-3 w-3 bg-gray7 rounded-full'></p>
                            ))}
                        </div>
                    </>
                )}
                {edit && (
                    <form onSubmit={handleSubmit(onSubmit)} className=''>
                        <div className='lg:w-1/2 flex flex-col gap-6'>
                            {fields.map(item => (
                                <fieldset className='flex flex-col flex-1 '>
                                    <label className='mb-3 text-gray7 text-sm' htmlFor={item.field_name}>
                                        {item.header}
                                    </label>
                                    <Controller
                                        name={item.field_name}
                                        rules={{
                                            required: true,
                                            minLength: 2,
                                        }}
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={e => {
                                                    field.onChange(e.target.value)
                                                }}
                                                placeholder={item.placeholder}
                                                className='border-[#DADCE0] h-[2.5rem] placeholder:text-xs'
                                                rightIcon={
                                                    showPassword ? (
                                                        <EyeIcon
                                                            onClick={handleShowPassword}
                                                            className='cursor-pointer'
                                                            color='#5E5E5E'
                                                        />
                                                    ) : (
                                                        <EyeOffIcon
                                                            onClick={handleShowPassword}
                                                            className='cursor-pointer'
                                                            color='#5E5E5E'
                                                        />
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                    {dontMatch && item.field_name !== 'current_password' && (
                                        <p className='text-red-500 text-xs mt-0.5'>Passwords don't match</p>
                                    )}
                                </fieldset>
                            ))}
                        </div>
                        <div className='flex justify-end mt-4 gap-3'>
                            <Button variant={'outline'} className='px-0 min-w-[100px]' onClick={() => setEdit(false)}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!isValid || dontMatch || isLoading}
                                loading={isLoading}
                                variant='default'
                                role='button'
                            >
                                Save password
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

const fields: {
    field_name: 'current_password' | 'new_password' | 'confirm_password'
    header: string
    placeholder: string
}[] = [
    {
        field_name: 'current_password',
        header: 'Current password',
        placeholder: 'Enter current password',
    },
    {
        field_name: 'new_password',
        header: 'New password',
        placeholder: 'Enter new password',
    },
    {
        field_name: 'confirm_password',
        header: 'Confirm password',
        placeholder: 'Confirm new password',
    },
]
