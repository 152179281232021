import React from 'react'
import ModalWrapper from '../../ModalWrapper'
import { Button } from '@/components'
import { UserAccount } from '@/services/payout/types'
import { UserOrgAccount } from '@/types/org'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    handleDeleteAcct: () => void
    bankDetails: UserAccount
    user: UserOrgAccount
}
export default function DeleteBankAccount({ closeModal, handleDeleteAcct, bankDetails }: Props) {
    return (
        <ModalWrapper>
            <section className='w-[30rem] p-6 z-50'>
                <h4 className='text-[#CF491E] text-xl font-medium'>Attention</h4>
                <p className='mt-3 font-medium text-[#202020]'>
                    Deleting this bank account means you won’t be able to receive reimbursements into this account (
                    {bankDetails.bank_name} - {bankDetails.account_name.toUpperCase()} -{' '}
                    {bankDetails?.account_number.slice(6, 10)}) anymore.
                </p>
                <div className='flex justify-end gap-6 mt-10'>
                    <Button
                        className='bg-transparent min-w-[100px] text-[#202020] hover:bg-transparent'
                        onClick={() => closeModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button className=' min-w-[100px] px-4' onClick={handleDeleteAcct}>
                        Yes, continue
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
