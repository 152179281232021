import { Button } from '@/components'
import SelectWIthChip from '@/components/SelectWithChip'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useAddUsersToBenefits } from '@/services/benefits/mutations'
import { useGetOrgMembers } from '@/services/employees/queries'
import { OrgBenefitProps } from '@/types/benefits'
import { RoleEnum } from '@/types/org'
import { capitalizeText } from '@/utils/string'
import { User } from 'lucide-react'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import ModalWrapper from '../../ModalWrapper'

type Props = {
    closeModal: Dispatch<SetStateAction<boolean>>
    benefit?: OrgBenefitProps
}
export default function AddUsersToBenefit({ closeModal, benefit }: Props) {
    const [steps, setSteps] = useState(1)

    const { benefit_id } = useParams()

    const { selectedAccount } = useAuthContext()
    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })

    const { mutate: addBeneficiaries, isLoading } = useAddUsersToBenefits({
        queryParams: {
            benefit_id: benefit?._id ?? (benefit_id as string),
        },
        onSuccess: () => {
            toastHandler({ state: 'success', message: 'Beneficiaries added' })
            closeModal(false)
        },
    })
    const [selectedMembers, setSelectedMembers] = useState<{ label: string; value: string }[]>([])

    const orgMembers = useMemo(() => org_members ?? [], [org_members])
    const handleAddBeneficiaries = () => {
        addBeneficiaries({ users: selectedMembers?.map(member => member?.value) })
    }
    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[40rem] max-h-[90vh] overflow-y-scroll'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                    <div className='flex items-center  justify-between h-full w-full'>
                        <h2 className='font-semibold text-[#31254B] text-2xl flex-1 text-center'>
                            Add user to benefit
                        </h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer mr-8'
                            onClick={() => closeModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='px-6 mt-8'>
                    <div className='flex ml-auto w-fit justify-end bg-[#EFEFF9] rounded-full p-2 px-3 gap-2'>
                        {steps}/2 <span className='font-semibold'> steps</span>
                    </div>
                    <h3 className='text-[21px] font-medium'>Add user to this existing plan</h3>
                    <p className='text-[#5E5E5E] text-sm my-2'>
                        Adding a new user may incur more cost for the next renewal of this plan.
                    </p>
                    <div className='bg-[#F9F9F9] rounded-xl mt-8 p-5'>
                        <div className='flex items-center'>
                            <User stroke='#5E5E5E' size={30} /> 6
                        </div>
                        <h3 className='my-3 font-medium'>Retail Alexandrite</h3>
                        <div className=' mb-4 inline-block font-medium text-[#454ADE] bg-[#EFEFF9] rounded-2xl px-3 p-1'>
                            ₦180,000.00 / year
                        </div>
                        <h4 className='font-medium'>Cost sharing</h4>
                        <p className='text-[#5E5E5E] text-sm my-1 mb-7'>
                            70% company, 30% users (deducted from salary)
                        </p>
                        {steps === 1 && (
                            <SelectWIthChip
                                options={orgMembers
                                    .filter(
                                        members =>
                                            ![RoleEnum.EMPLOYEE, RoleEnum.CONTRACTOR].includes(members?.role?.name)
                                    )
                                    .filter(
                                        members =>
                                            !benefit?.beneficiaries
                                                ?.map(beneficiaries => beneficiaries?.id)
                                                ?.includes(members?._id ?? members?.id)
                                    )
                                    .map(members_data => ({
                                        label: `${capitalizeText(members_data?.user?.first_name)} ${capitalizeText(
                                            members_data?.user?.last_name
                                        )}`,
                                        value: members_data?._id ?? (members_data?.id as string),
                                    }))}
                                placeholder='Select beneficiaries'
                                onChange={members_details => {
                                    setSelectedMembers(members_details)
                                }}
                                value={selectedMembers}
                                className='h-[3.5rem]'
                            />
                        )}
                        {steps === 2 && (
                            <div className='bg-white px-6 py-5 rounded-xl'>
                                <p className='text-sm text-[#454ADE] mb-3 font-medium'>
                                    {selectedMembers.length} new users added to benefits
                                </p>
                                <div className='flex gap-3 '>
                                    {selectedMembers?.map(member => (
                                        <span className='bg-[#F2F2F2] text-xs rounded px-2 p-1'>{member?.label}</span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex justify-end items-center gap-5 px-8 my-5'>
                    <Button
                        variant='outline'
                        size={'lg'}
                        onClick={() => {
                            steps === 2 ? setSteps(1) : closeModal(false)
                        }}
                    >
                        back
                    </Button>
                    <Button
                        onClick={() => {
                            setSteps(2)
                            if (steps === 2) handleAddBeneficiaries()
                        }}
                        loading={isLoading}
                        disabled={selectedMembers?.length < 1}
                    >
                        {selectedMembers?.length > 1 ? 'Confirm Users' : 'Next'}
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
