import { createQuery } from '../api/query'
import {
    BillPaidByMonthResponse,
    BillsByCategory,
    BillsByVendor,
    BillSummaryAnalytics,
    BillType,
    GetBillsResponse,
} from './types'

// export const BillPaymentKey = ['bill-payment']
export const BillPaymentKey = (id: string) => ['bill-payment', id]

export const AllBillsKey = ['all-bills']

export const useGetAllBills = createQuery<GetBillsResponse>({
    url: '/bills',
    key: AllBillsKey,
})

export const useGetBill = createQuery<BillType>({
    url: '/bill',
    key: BillPaymentKey,
})

export const useGetBillsSummary = createQuery<BillSummaryAnalytics>({
    url: '/bills-insights-summary',
    key: ['bills-insights-summary'],
})

export const useGetBillsPaid = createQuery<BillPaidByMonthResponse>({
    url: '/bills-paid-by-month',
    key: ['bills-paid-by-month'],
})

export const useGetBillsByCategory = createQuery<BillsByCategory[]>({
    url: '/bills-by-category',
    key: ['bills-by-category'],
})

export const useGetBillsByTopVendors = createQuery<BillsByVendor[]>({
    url: '/bills-by-vendor',
    key: ['bills-by-vendor'],
})

export const useGetAccounts = createQuery<any>({
    url: '/bank-accounts',
    key: ['bank-accounts'],
})

export const useGetScheduledBill = createQuery({
    url: '/scheduled-payment',
    key: ['scheduled-payment'],
})
