import { useEffect, useMemo, useState } from 'react'
import { motion } from 'framer-motion'
import { useGetAllVaults, useGetFolderItems } from '@/services/vault/queries'
import ProgressBar from '@/components/ProgressBar'
import { VaultItem } from '@/services/vault/types'
import { useSearchParams } from 'react-router-dom'
import { SingleFolderFullView } from './SingleFolderFullView'
import { LayoutGrid, StretchHorizontal } from 'lucide-react'
import { FolderContainer } from '../Folders/FolderContainer'
import { FileContainer } from '../Files/FileContainer'
import { FileEmptyState } from '../FileEmptyState'
import VaultListTable from '../VaultListTable'
import { TrashView } from './TrashView'
import { capitalizeText } from '@/utils/string'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { CiSearch } from 'react-icons/ci'
import filter from '@/assets/filter.svg'

export const ItemsView = () => {
    const [listStyle, setListStyle] = useState(false)

    const [filterVaule, setFilterValue] = useState('')

    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]
    const [searchParams] = useSearchParams()

    const {
        data: vaultItems,
        isLoading: isLoadingVaultItems,
        refetch,
        isRefetching,
    } = useGetFolderItems({
        queryParams: {
            vault: singleVault?.id,
            parent: searchParams.get('folder') ?? singleVault?.id,
            visibility: searchParams.get('access') ?? '',
        },
        enabled: !!vaults,
    })

    const folders = vaultItems?.filter(item => item.type === 'folder')
    const files = vaultItems?.filter(item => item.type === 'file')

    useEffect(() => {
        refetch()
        setListStyle(false)
    }, [searchParams])

    if (isLoadingVaults || isLoadingVaultItems || isRefetching)
        return (
            <div className='w-full overflow-hidden flex items-center justify-center max-h-[600px]'>
                <ProgressBar />
            </div>
        )
    return (
        <>
            {!searchParams.get('folder') ? (
                <>
                    <div className='flex flex-col lg:flex-row items-center gap-5 gap-y-3 justify-between px-3 pb-3 border-b border-gray8 mb-4'>
                        <div className='border border-[#DADCE0] h-[45px] px-2 w-full lg:w-3/5 rounded-lg flex items-center justify-between overflow-hidden bg-white'>
                            <div className='flex gap-1 items-center h-full w-full'>
                                <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 lg:mx-0'>
                                    <CiSearch fontSize={23} />
                                </div>
                                <input
                                    type='text'
                                    className='h-full w-full outline-none placeholder:text-xs'
                                    placeholder='Search by name'
                                />
                            </div>
                        </div>
                        <div className='flex w-full lg:w-2/5 items-center gap-5 justify-between'>
                            <div className='w-4/5'>
                                <Select onValueChange={setFilterValue} value={filterVaule}>
                                    <SelectTrigger
                                        className='w-full h-[45px] border-gray8 text-[#5E5E5E]'
                                        id='transactionYear'
                                    >
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='h-fit max-h-[10rem] w-[13rem]'>
                                        <SelectItem value=''>Name {`->`} ascending</SelectItem>
                                        <SelectItem value='descending'>Name {`->`} descending</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div
                                className='h-[45px] w-[45px] border border-[#DADCE0] rounded flex items-center justify-center p-2.5 cursor-pointer'
                                // onClick={() => setFilterExpenseModal(true)}
                            >
                                <img src={filter} alt='button' />
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center gap-4 justify-between'>
                        <p className='font-medium'>
                            {searchParams.get('path')
                                ? 'Trash'
                                : searchParams.get('access')
                                  ? capitalizeText(
                                        searchParams.get('access') === 'personal'
                                            ? 'Personal Folder'
                                            : 'Shared to others'
                                    )
                                  : listStyle
                                    ? ''
                                    : folders && folders?.length > 0 && 'Folders'}
                        </p>
                        {vaultItems?.length !== 0 && (
                            <>
                                <div className='flex gap-4 p-1 w-[11rem] rounded-lg border border-gray8'>
                                    <div
                                        className={`${'bg-#F9F9F9 text-brand'}bg-[#EFEFF9] px-2 py-1 rounded cursor-pointer relative`}
                                        onClick={() => {
                                            setListStyle(false)
                                        }}
                                    >
                                        {!listStyle && (
                                            <motion.span
                                                layoutId='expense_timeline__animation'
                                                className='bg-[#EFEFF9] rounded-md z-0 text-brand inset-0 absolute inline-block w-full h-full '
                                            />
                                        )}
                                        <motion.span
                                            layout='position'
                                            className={`z-20 sticky flex items-center gap-2 font-semibold ${!listStyle ? 'text-brand' : 'text-gray7'}`}
                                        >
                                            <LayoutGrid size={17} />
                                            Grid
                                        </motion.span>
                                    </div>
                                    <div
                                        className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#EFEFF9] px-2 py-1 rounded cursor-pointer relative w-full`}
                                        onClick={() => {
                                            setListStyle(true)
                                        }}
                                    >
                                        {listStyle && (
                                            <motion.span
                                                layoutId='expense_timeline__animation'
                                                className='bg-[#EFEFF9] rounded-md inset-0 absolute inline-block w-full h-full '
                                            />
                                        )}
                                        <motion.span
                                            layout='position'
                                            className={`${listStyle ? 'text-brand' : 'text-gray7'} inset-0 absolute w-full h-full px-2 py-1 font-semibold`}
                                        >
                                            <p className='flex items-center gap-2'>
                                                <StretchHorizontal size={17} /> List
                                            </p>
                                        </motion.span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='mt-8 2xl:mt-6'>
                        {searchParams.get('path') !== 'trash' ? (
                            <>
                                <div className=''>
                                    {!listStyle &&
                                        (vaultItems?.length !== 0 ? (
                                            <>
                                                <div className='grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-y-3 lg:gap-y-8 gap-x-4 2xl:gap-y-5'>
                                                    {vaultItems
                                                        ?.filter(item => item.type === 'folder')
                                                        ?.map(folder => (
                                                            <FolderContainer key={folder?.id} folder={folder} />
                                                        ))}
                                                </div>
                                                {!searchParams.get('access') && (
                                                    <p className='mt-5 mb-4 font-medium'>Files</p>
                                                )}
                                                <div className='grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-4'>
                                                    {vaultItems
                                                        ?.filter(item => item.type === 'file')
                                                        ?.map(file => <FileContainer key={file.id} file={file} />)}
                                                </div>
                                            </>
                                        ) : (
                                            <FileEmptyState type='file' />
                                        ))}
                                </div>
                                {listStyle && (
                                    <div className='w-full overflow-x-scroll'>
                                        <VaultListTable
                                            data={[...(folders as VaultItem[]), ...(files as VaultItem[])]}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <TrashView listStyle={listStyle} items={[] as VaultItem[]} />
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <SingleFolderFullView items={vaultItems as VaultItem[]} />
                </>
            )}
        </>
    )
}
