import { createQuery } from '../api/query'
import { GroupedApprovalPolicy, OrgSettings } from './types'

export const keyQueryExpenseApprovalPolicies = [`/get-expense-approval-policies`]
export const keyQueryOrgSetting = [`get-org-setting`]

export const useGetExpenseApprovalPolicies = createQuery<GroupedApprovalPolicy[]>({
    key: keyQueryExpenseApprovalPolicies,
    url: '/get-expense-approval-policies',
})
export const useGetOrgSettings = createQuery<OrgSettings[]>({
    key: keyQueryOrgSetting,
    url: '/get-org-setting',
})
