import ProgressBar from '@/components/ProgressBar'
import { useGetDirectoryPlans } from '@/services/billing/queries'
import { useMemo } from 'react'
import PlansCard from '../components/PlansCard'

export default function BusinessBillingPlan() {
    const { data, isLoading } = useGetDirectoryPlans()
    const orgPlans = useMemo(() => data ?? [], [data])
    if (isLoading) return <ProgressBar />
    return (
        <section className='grid grid-cols-1 lg:grid-cols-3 gap-6 px-2 lg:px-0 mb-4'>
            {orgPlans
                ?.sort(a => (a?.archived ? 0 : -1))
                ?.map(plan => <PlansCard plan={plan} business_directory={true} key={plan.id} />)}
        </section>
    )
}
