import ExpenseList from './partials/ExpenseList'
import PageContent from '@/layout/PageContent'
import { Tabs, TabsContent, TabsList, TabTriggersContainer, TabsTrigger } from '@/components/Tabs/Tabs'
import ExpenseAnalytics from './partials/ExpenseAnalytics'
import { useParams, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { SwitchOrgInViewMode } from '@/components/SwitchOrgInView'
import ExpenseApprovalPolicy from './partials/ExpenseApprovalpolicy'
import { AccessPermissionHOC } from '@/hoc/AccessPermissionHOC'
import { ADMIN_EXPENSE_PERMISSIONS } from '@/utils/permisson-helper'
import { useAuthContext } from '@/context'
import NotAuthorized from '@/layout/NotAuthorized'
import CurrencyFilter from '@/components/CurrencyFilter'

const expense_routes_const = {
    list: 'expense_list',
    analytics: 'expense_analytics',
    settings: 'settings',
    quick: 'quick_actions',
}

const Expenses = () => {
    const { expense_subRoute } = useParams()
    const { selectedAccount } = useAuthContext()
    const orgExpensePermissions = ADMIN_EXPENSE_PERMISSIONS.every(perm =>
        selectedAccount?.role?.permissions?.includes(perm)
    )

    const navigate = useNavigate()

    const showCurrencyFilter = location.pathname.includes(expense_routes_const.analytics)

    const orgViewSubroute = location.pathname.includes('company') || location.pathname.includes('expenses/settings')

    if (!orgExpensePermissions && expense_subRoute === expense_routes_const.settings) return <NotAuthorized />
    return (
        <div id='expenses'>
            <AccessPermissionHOC requiredPermissions={ADMIN_EXPENSE_PERMISSIONS}>
                <section className='border-b border-gray4'>
                    <SwitchOrgInViewMode />
                </section>
            </AccessPermissionHOC>

            <section className=' mt-6'>
                <Tabs defaultValue={expense_subRoute} value={expense_subRoute}>
                    <TabsList className='border-none'>
                        <div className='flex flex-col lg:flex-row justify-between mx-auto lg:items-center lg:w-[93%]'>
                            <TabTriggersContainer className='lg:w-full mx-0 ml-1'>
                                <TabsTrigger
                                    value={expense_routes_const.list}
                                    id={expense_routes_const.list}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/expenses/${expense_routes_const.list}${orgViewSubroute ? '/company' : '/personal'}`
                                        )
                                    }
                                    className={`relative border-none ${
                                        expense_routes_const.list === expense_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {expense_routes_const.list === expense_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles '} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        {!orgExpensePermissions && 'My'} Expenses
                                    </motion.span>
                                </TabsTrigger>
                                <TabsTrigger
                                    value={expense_routes_const.analytics}
                                    id={expense_routes_const.analytics}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/expenses/${expense_routes_const.analytics}${orgViewSubroute ? '/company' : '/personal'}`
                                        )
                                    }
                                    className={`relative border-none ${
                                        expense_routes_const.analytics === expense_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {expense_routes_const.analytics === expense_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                        Analytics
                                    </motion.span>
                                </TabsTrigger>
                                {orgExpensePermissions && (
                                    <TabsTrigger
                                        value={expense_routes_const.settings}
                                        onClick={() => navigate(`/dashboard/expenses/${expense_routes_const.settings}`)}
                                        className={`relative border-none ${
                                            expense_routes_const.settings === expense_subRoute ? '!text-white' : ''
                                        } text-sm`}
                                    >
                                        {expense_routes_const.settings === expense_subRoute && (
                                            <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                        )}
                                        <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                            Settings
                                        </motion.span>
                                    </TabsTrigger>
                                )}
                            </TabTriggersContainer>
                            {showCurrencyFilter && <CurrencyFilter />}
                        </div>
                    </TabsList>
                    <TabsContent value={expense_routes_const.list}>
                        <PageContent>
                            <ExpenseList />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={expense_routes_const.quick}>
                        <PageContent>
                            <ExpenseList />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={expense_routes_const.analytics}>
                        <PageContent>
                            <ExpenseAnalytics />
                        </PageContent>
                    </TabsContent>
                    {orgExpensePermissions && (
                        <TabsContent value={expense_routes_const.settings}>
                            <PageContent>
                                <ExpenseApprovalPolicy />
                            </PageContent>
                        </TabsContent>
                    )}
                </Tabs>
            </section>
        </div>
    )
}

export default Expenses
