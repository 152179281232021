export enum DaysOfWeek {
    'sunday' = 1,
    'monday' = 2,
    'teusday' = 3,
    'wednesday' = 4,
    'thursday' = 5,
    'friday' = 6,
    'saturday' = 7,
}

import { parse, format } from 'date-fns'

export const numberDay = (day: string): number => {
    const parsedDay = parse(day, 'EEEE', new Date())
    const dayOfWeek = parsedDay.getDay()
    return (dayOfWeek === 0 ? 7 : dayOfWeek) + 1
}

export const dayFromNumber = (dayNumber: number): string => {
    // Ensure dayNumber is within range
    if (dayNumber < 1 || dayNumber > 7) {
        throw new Error('Invalid day number')
    }
    const adjustedDayNumber = dayNumber === 7 ? 1 : dayNumber + 1

    const formattedDate = format(new Date(2022, 0, adjustedDayNumber), 'EEEE')
    return formattedDate
}
