import { Button } from '@/components'
import { useAuthContext } from '@/context'
import { ReactElement } from 'react'
import { BillType } from '@/services/bill-payment/types'

type Props = {
    component: ReactElement
    bill: BillType
}

export default function WithAuthorizeBillPermission({ component, bill }: Props) {
    const { selectedAccount } = useAuthContext()

    if (bill?.status === 'PAID') return component

    if (bill?.approvers?.length == 0 || bill.status === 'APPROVED') return component

    if (!bill?.approvers?.some(approve => approve?.account?.id === selectedAccount?.id)) {
        return <Button disabled> Cannot authorize this bill</Button>
    }
    return component
}
