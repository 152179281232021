import { ReactComponent as ErrorSVG } from '@/assets/error-header.svg'
import { Undo2 } from 'lucide-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ErrorFallBack() {
    const navigate = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            window.location.href = '/dashboard/overview'
        }, 5000)
        return () => clearInterval(interval)
    }, [])
    return (
        <div role='alert' className='grid place-items-center h-[100vh] bg-error-pattern relative'>
            <div className='flex justify-center items-center flex-col'>
                <h2 className='font-bold text-6xl opacity-90'>Oops!</h2>
                <p className='font-medium my-4 text-lg'>You are lost</p>
                <ErrorSVG />

                <button
                    onClick={() => navigate('/dashboard/overview')}
                    className='border-b-2 pb-0.5 absolute top-10 right-10 flex items-center gap-2 cursor-pointer'
                >
                    <Undo2 className='w-5 h-5 ' />
                    Go Home
                </button>
            </div>
        </div>
    )
}
