import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import useSingleFileUpload, { FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { ChevronRight, Minus, Plus } from 'lucide-react'
import { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import bizpendlogo from '@/assets/bizpend.svg'
import { useUploadCompanyLogo } from '@/services/auth/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { OrgType } from '@/types/org'
import PageContent from '@/layout/PageContent'
import { useNavigate } from 'react-router-dom'

type FormProps = {
    due_day: number
    date_format: string
}
export default function InvoiceAppearanceSettings() {
    const [logoFile, setLogoFile] = useState<FileUploadFormatProps>()

    const navigate = useNavigate()
    const { selectedAccount } = useAuthContext()
    const org = selectedAccount?.org as OrgType
    const { handleFile: uploadLogoDocFn } = useSingleFileUpload({
        setFileUpload: setLogoFile,
    })
    const logo = useRef<HTMLInputElement>(null)
    const { handleSubmit, control } = useForm<FormProps>({
        defaultValues: {
            date_format: 'dd/MM/yyyy',
            due_day: 1,
        },
    })
    const { mutate: uploadLogoFn, isLoading } = useUploadCompanyLogo({
        onSuccess: () => {
            toastHandler({ message: 'upload successful', state: 'success' })
        },
    })

    const onSubmit = (values: FormProps) => {
        console.warn(values)

        const formdata = new FormData()
        formdata.append('file', (logoFile?.fileBeforeFormData as Blob) ?? '')
        uploadLogoFn(formdata)
    }
    return (
        <PageContent>
            <div className='flex items-center gap-2 mt-5'>
                <button onClick={() => navigate(-1)} className='font-medium '>
                    Settings
                </button>
                <ChevronRight size={18} className='text-gray7' />
                <span className='text-sm text-gray1 '>Appearance</span>
            </div>
            <section className='px-4 lg:px-0 mt-10'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Invoice settings</h1>
                    <p className='text-gray7 text-sm'>Manage your invoice preferences</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className=''>
                    <section className='hidden bg-white shadow-sm rounded-lg p-5  mt-8'>
                        <div className='grid grid-cols-3 gap-14'>
                            <fieldset className='flex flex-col flex-1  gap-1'>
                                <label className='text-base mb-1 text-gray7/90 font-medium' htmlFor='date_format'>
                                    Date format
                                </label>
                                <Controller
                                    name='date_format'
                                    control={control}
                                    render={({ field }) => (
                                        <Select {...field} onValueChange={field.onChange} defaultValue='dd/MM/yyyy'>
                                            <SelectTrigger className='border-gray text-base h-[2.5rem] '>
                                                <SelectValue placeholder={''} />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value='dd/MM/yyyy'>DD / MM / YYYY</SelectItem>
                                                <SelectItem value='mm/dd/yyyy'> MM / DD / YYYY</SelectItem>
                                                <SelectItem value='yyyy/mm/dd'> YYYY / MM / DD</SelectItem>
                                                <SelectItem value='MMM dd, yyyy'> MMM DD, YYYY</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    )}
                                />
                            </fieldset>

                            <fieldset className='flex flex-col flex-1  gap-1'>
                                <label className='text-base mb-1 text-gray7/90 font-medium' htmlFor='due_day'>
                                    Default due time
                                </label>
                                <div className='flex items-center gap-3'>
                                    <Controller
                                        name='due_day'
                                        control={control}
                                        render={({ field }) => (
                                            <span className='flex justify-between border border-[#DADCE0] rounded-lg gap-6 w-[147px] p-2'>
                                                <button
                                                    disabled={field.value === 1}
                                                    className='disabled:opacity-30 disabled:cursor-not-allowed'
                                                >
                                                    <Minus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() => field.onChange(Number(field.value) - 1)}
                                                    />
                                                </button>
                                                {field.value}
                                                <button className='disabled:opacity-30 disabled:cursor-not-allowed'>
                                                    <Plus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() => field.onChange(Number(field.value) + 1)}
                                                    />
                                                </button>
                                            </span>
                                        )}
                                    />
                                    days after issue date
                                </div>
                            </fieldset>
                        </div>
                    </section>
                    <section className='bg-white shadow-sm rounded-lg p-5  mt-8'>
                        <div className='mb-2 lg:mb-6'>
                            <h1 className='text-dark font-medium text-lg'>Invoice customization</h1>
                            <p className='text-gray7 text-sm'>Customize invoice appearance</p>
                        </div>
                        <div className='grid grid-cols-3 gap-14'>
                            <fieldset className='flex flex-col flex-1  gap-1'>
                                <label className='text-base mb-1 text-gray7/90 font-medium' htmlFor='date_format'>
                                    Logo
                                </label>
                                <div className='w-32'>
                                    <input
                                        type='file'
                                        hidden
                                        id='logo'
                                        ref={logo}
                                        accept='image/*'
                                        onChange={e => {
                                            uploadLogoDocFn(e)
                                        }}
                                    />

                                    <div
                                        className='bg-[#F9F9F9] p-5 text-center rounded-2xl h-full flex flex-col justify-between cursor-pointer items-center'
                                        onClick={() => logo.current?.click()}
                                    >
                                        <img
                                            src={logoFile?.fileUrl ?? org?.logo ?? bizpendlogo}
                                            alt='Logo'
                                            className='w-48 object-contain'
                                        />

                                        <button type='button' className='text-brand font-medium mt-1 whitespace-nowrap'>
                                            Change logo
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </section>

                    <div className='flex justify-end my-8 pb-8'>
                        <Button loading={isLoading}>Save changes</Button>
                    </div>
                </form>
            </section>
        </PageContent>
    )
}
