import { RoleType } from './roles'
import { User } from './user'

export type TeamMembersProp = {
    createdAt: string
    id: string
    is_org_owner: boolean
    last_name: string
    updatedAt: string
    role: string
    account: {
        _id: string
        id: string
        role: RoleType
        user: User
    }
    isTeamLead: boolean
}

export enum EmployeeStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    DECLINED = 'DECLINED',
    DEACTIVATED = 'DEACTIVATED',
    SUSPENDED = 'SUSPENDED',
}
export type TeamsProp = {
    _id: string
    createdAt: string
    id: string
    users: TeamMembersProp[]
    name: string
    org: string
}

export type formProps = {
    role: string
    period: { from: Date | null; to: Date | null; time_in_words: string }
    filter_now?: boolean
}
