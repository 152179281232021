import React, { createContext, useState } from 'react'
import { Pagination } from '@/types/utils'
import { BillType } from '@/services/bill-payment/types'

export type formProps = {
    status: string
    period: { from: Date | null; to: Date | null; time_in_words: string }
    vendor: string
    due_time: string
    bill_type: string
    amount: { min: string; max: string; amount_in_words: string }
    filter_now?: boolean
    approver: string
}

export const advancedFilteringProps = {
    status: '',
    period: { from: null, to: null, time_in_words: '' },
    vendor: '',
    due_time: '',
    bill_type: '',
    approver: '',
    amount: { min: '', max: '', amount_in_words: '' },
}

export type defaultBillPaymentProps = {
    selectedBillRequest: BillType | undefined
    setSelectedBillRequest: React.Dispatch<React.SetStateAction<BillType | undefined>>
    filterBillRequest: formProps
    setFilterBillRequest: React.Dispatch<React.SetStateAction<formProps>>
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    pagination_details?: Pagination
    setPaginationDetails: React.Dispatch<React.SetStateAction<Pagination | undefined>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
}

const defaultBillPaymentState: defaultBillPaymentProps = {
    selectedBillRequest: undefined,
    setSelectedBillRequest: () => undefined,
    filterBillRequest: advancedFilteringProps,
    setFilterBillRequest: () => undefined,
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    pagination_details: undefined,
    setPaginationDetails: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
}

export const BillPaymentContext = createContext(defaultBillPaymentState)

export const BillPaymentContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedBillRequest, setSelectedBillRequest] = useState<BillType>()
    const [filterBillRequest, setFilterBillRequest] = useState<formProps>(advancedFilteringProps)
    const [pagination_details, setPaginationDetails] = useState<Pagination | undefined>()
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)

    const value = {
        selectedBillRequest,
        setSelectedBillRequest,
        filterBillRequest,
        setFilterBillRequest,
        pagination_details,
        setPaginationDetails,
        pageNumber,
        setPageNumber,
        pageNumberClicked,
        setPageNumberClicked,
    }
    return <BillPaymentContext.Provider value={value}>{children}</BillPaymentContext.Provider>
}
