import React from 'react'

type Props = {
    message?: string
}
export const ErrorText = ({ message }: Props) => {
    return (
        <p className='text-errorRed text-xs font-medium'>
            {message ?? 'Please review the highlighted field(s) for any issues.'}
        </p>
    )
}
