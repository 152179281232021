import React, { useState } from 'react'
import BulkPayroll from './BulkPayrollModal'
import ModalWrapper from '../../ModalWrapper'
import { AiOutlineClose } from 'react-icons/ai'
import { BulkProfileCSVType } from '@/types/payroll'
import BulkPayrollPreviewTable from './BulkPayrollPreviewTable'
import { useGetPayrollSettingsForOrg } from '@/services/payroll/queries'
import { useAuthContext } from '@/context'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
const BulkProfileWrapper = ({ closeModal }: Props) => {
    const [step, setStep] = useState(1)
    const { selectedAccount } = useAuthContext()

    const [selectedProfiles, setSelectedProfiles] = useState<BulkProfileCSVType[]>([])
    const { data: orgPayrollSettings } = useGetPayrollSettingsForOrg({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    return (
        <ModalWrapper variants={undefined}>
            <div className='w-[51rem] max-h-[90vh]'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                    <div className='flex items-center w-[66%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B] text-xl'>Import payroll list in bulk</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer mr-8'
                            onClick={() => closeModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                {step === 1 && (
                    <BulkPayroll
                        selectedProfiles={selectedProfiles}
                        setSelectedProfiles={setSelectedProfiles}
                        nextStep={() => setStep(2)}
                        orgPayrollSettings={orgPayrollSettings}
                    />
                )}
                {step === 2 && (
                    <BulkPayrollPreviewTable
                        closeModal={() => closeModal(false)}
                        selectedProfiles={selectedProfiles}
                        setSelectedProfiles={setSelectedProfiles}
                        orgPayrollSettings={orgPayrollSettings}
                    />
                )}
            </div>
        </ModalWrapper>
    )
}

export default BulkProfileWrapper
