import { CancelIcon, LoaderIcon } from '@/assets/assets.export'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useMutateSaveAccountDetails, useResolveBankMutation } from '@/services/payout/mutations'
import { BankTypes, UserAccount } from '@/services/payout/types'
import React, { useEffect, useState } from 'react'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { useLocation } from 'react-router-dom'
import ModalWrapper from '../../ModalWrapper'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    allBankList: BankTypes[]
    userBankDetails: UserAccount[]
}

export default function AddNewBankContainer({ closeModal, allBankList, userBankDetails }: Props) {
    const { setShowKYCModalOnce } = useAuthContext()
    const location = useLocation()

    const [bankDetails, setBankDetails] = useState<{
        account_name: string
        bank_code: string | undefined
        account_number: string | undefined
    }>({
        bank_code: undefined,
        account_number: undefined,
        account_name: '',
    })

    const [bankAdded, setIsBankAdded] = useState(false)

    const [isPrimaryBank, setIsPrimaryBank] = useState(false)
    const [acctNumberErr, setAcctNumberErr] = useState('')
    const [bankSelectionErr, setBankSelectionError] = useState(false)
    const { mutate: addBankFn, isLoading: isAddingBankLoading } = useMutateSaveAccountDetails({
        onSuccess: () => {
            setIsBankAdded(true)
            toastHandler({ message: 'Bank added successfully', state: 'success' })
            if (location.pathname.includes('dashboard/overview')) {
                closeModal(false)
                setShowKYCModalOnce(true)
            }
        },
    })

    const { selectedAccount } = useAuthContext()
    const {
        mutate: confirmBankFn,
        data: resolvedBankDetails,
        isLoading: isBankResolving,
        isSuccess: isBankResolved,
    } = useResolveBankMutation({
        onError: (error: any) => {
            if (error.response.data.error[0].msg == 'Bank code is required') {
                setBankSelectionError(true)
            }
        },
    })

    const handleAddBankDetails = () => {
        const nipCode = allBankList.find(bank => bank.code === bankDetails.bank_code)?.nipCode
        addBankFn({
            account_number: bankDetails.account_number,
            bank_code: bankDetails.bank_code,
            is_primary: isPrimaryBank,
            account: selectedAccount?.id,
            account_name: resolvedBankDetails?.account_name,
            nipCode: nipCode,
        })
    }
    useEffect(() => {
        if (userBankDetails?.length === 0) {
            setIsPrimaryBank(true)
        }
    }, [userBankDetails?.length])

    useEffect(() => {
        const { account_number, bank_code } = bankDetails
        if (account_number?.length === 10 && bank_code) {
            confirmBankFn({
                account_number: bankDetails.account_number,
                bank_code: bankDetails.bank_code,
                account_name: bankDetails?.account_name,
            })
            setAcctNumberErr('')
            setBankSelectionError(false)
            return
        }
        if (account_number?.length !== 10 && account_number && account_number?.length > 0) {
            setAcctNumberErr('Enter a valid account number')
        } else setAcctNumberErr('')
        if (!bank_code && account_number?.length == 10) {
            setBankSelectionError(true)
        }
    }, [bankDetails.account_number, bankDetails.bank_code])

    return (
        <ModalWrapper variants={undefined} formId='addBank' closeModal={() => closeModal(false)} shouldConfirmClose>
            <main className='h-[30rem] w-screen lg:w-[30rem] overflow-scroll '>
                <section className='flex items-center border-b border-[#DADCE0] p-4 '>
                    <span className='flex-1 text-center text-xl font-medium'>
                        {bankAdded ? 'Successful' : 'Add new bank account'}
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>

                {bankAdded ? (
                    <>
                        <div className='h-[80%] flex flex-col justify-center items-center  gap-3 text-[#5E5E5E] font-medium'>
                            <div>
                                <svg
                                    className='checkmark w-[56px] h-[56px] rounded-full block'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 52 52'
                                >
                                    <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />{' '}
                                    <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
                                </svg>
                            </div>
                            <span className='font-semibold text-[#202020] text-xl'>Bank added successfully!</span>
                            <span className='font-normal text-md max-w-[45%] text-center'>
                                Congratulations! Your new bank has been added.
                            </span>
                        </div>
                    </>
                ) : isAddingBankLoading ? (
                    <div className='h-[80%] flex justify-center items-center  gap-3 text-[#5E5E5E] font-medium'>
                        Adding new bank
                        <LoaderIcon className='animate-spin' />
                    </div>
                ) : (
                    <>
                        <form onSubmit={e => e.preventDefault()} id='addBank'>
                            <section className='flex flex-col mx-4 px-2 gap-7 mt-9 overflow-scroll max-h-[400px] lg:max-h-[90vh]'>
                                <div className=' relative'>
                                    <label className='font-normal pb-3 inline-block' htmlFor='acct_name'>
                                        Bank name
                                    </label>
                                    <Select
                                        name='accnt_number'
                                        onValueChange={data => {
                                            setBankDetails(prev => ({ ...prev, bank_code: data }))
                                            setBankSelectionError(false)
                                        }}
                                        value={bankDetails?.bank_code}
                                    >
                                        <SelectTrigger className='w-full h-[45px] border-[#DADCE0] bg-base_gray_100 placeholder:text-[base_gray_100]  text-[14px]'>
                                            <SelectValue placeholder={'Select bank name'} className='' />
                                        </SelectTrigger>
                                        <SelectContent className='w-[27rem] h-[20rem] overflow-scroll'>
                                            {allBankList.map(bank => (
                                                <SelectItem
                                                    value={bank.code}
                                                    key={bank.code}
                                                    className='cursor-pointer'
                                                >
                                                    {bank.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    {bankSelectionErr && (
                                        <p className='text-xs text-[red] mt-2 -bottom-5 left-0'>Please select a bank</p>
                                    )}
                                </div>
                                <div className='relative'>
                                    <label className='font-normal pb-3 inline-block' htmlFor='accnt_number'>
                                        Account number
                                    </label>
                                    <Input
                                        placeholder='Enter account number'
                                        id='accnt_number'
                                        className='h-[45px] border-[#DADCE0]'
                                        type='number'
                                        value={bankDetails?.account_number as string}
                                        onChange={e =>
                                            setBankDetails(prev => ({ ...prev, account_number: e.target.value }))
                                        }
                                        maxLength={10}
                                    />
                                    {bankDetails?.account_number && bankDetails?.account_number?.length > 0 && (
                                        <span className='text-xs text-[red] -bottom-5 left-0'>{acctNumberErr}</span>
                                    )}
                                </div>
                            </section>
                            <section className='mx-6 mt-3 h-[15%]'>
                                {isBankResolving && (
                                    <div className='flex gap-3 text-[#838383] text-sm items-center'>
                                        <span>Fetching bank name</span>
                                        <LoaderIcon className='animate-spin w-4 h-4' />
                                    </div>
                                )}

                                {isBankResolved && (
                                    <>
                                        <div className='flex items-center gap-2 text-[#19C37D]'>
                                            <IoIosCheckmarkCircle className='' />
                                            <label htmlFor='' className='text-sm font-medium'>
                                                {resolvedBankDetails?.account_name}
                                            </label>
                                        </div>
                                        <div
                                            className={`flex gap-2 item-center text-[#454ADE] mt-8 ${
                                                userBankDetails.length == 0 ? 'opacity-70' : ''
                                            }`}
                                        >
                                            <input
                                                type='checkbox'
                                                name='acct_name'
                                                id='acct_name'
                                                className='accent-[#454ADE] cursor-pointer'
                                                onChange={e => setIsPrimaryBank(e.target.checked)}
                                                checked={userBankDetails.length == 0 ? true : undefined}
                                                disabled={userBankDetails.length === 0}
                                            />
                                            <label htmlFor='acct_name' className='text-sm font-medium cursor-pointer'>
                                                Set as primary bank
                                            </label>
                                        </div>
                                    </>
                                )}
                            </section>
                        </form>

                        <Button
                            className='mx-auto w-[93%] mt-3'
                            disabled={!resolvedBankDetails?.account_name}
                            onClick={() => handleAddBankDetails()}
                        >
                            Add bank
                        </Button>
                    </>
                )}
            </main>
        </ModalWrapper>
    )
}
