import { createQuery } from '../api/query'
import { keyGetAllCards, keyGetUserCards } from './mutations'
import { QueryOrgCards } from './types'

export const useGetAllOrgCards = createQuery<QueryOrgCards>({
    key: keyGetAllCards,
    url: '/get-cards-by-org',
})

export const useGetAllUserCards = createQuery<QueryOrgCards>({
    key: keyGetUserCards,
    url: '/get-user-cards',
})
