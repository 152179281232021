import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../../ModalWrapper'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useResolveBankMutation } from '@/services/payout/mutations'
import { BankTypes } from '@/services/payout/types'
import { useMutationCreateVendor } from '@/services/vendors/mutations'
import { EmployeeType } from '@/types/org'
import { emailRegex } from '@/utils/string'
import { Minus, Plus } from 'lucide-react'
import { LoaderIcon } from 'react-hot-toast'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { CategoryType } from '@/types/categories'

type Props = {
    setCloseModal: React.Dispatch<React.SetStateAction<boolean>>
    orgMembers: EmployeeType[]
    categories: CategoryType[]
    allBankList: BankTypes[]
}
type BankProps = {
    bank_code: string
    account_number: string
}

const defaultState = {
    name: '',
    category: '',
    email: '',
    owner: '',
    description: '',
}
export default function CreateNewVendor({ setCloseModal, categories, orgMembers, allBankList }: Props) {
    const [createVendorProps, setCreateVendorProps] = useState(defaultState)
    const [showBankForm, setShowBankForm] = useState(false)
    const [errMsg, setErrMsg] = useState(false)

    const [bankDetails, setBankDetails] = useState<BankProps>({
        bank_code: '',
        account_number: '',
    })

    const { selectedAccount } = useAuthContext()
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target

        //
        setCreateVendorProps(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const {
        mutate: confirmBankFn,
        data: resolvedBankDetails,
        isLoading: isBankResolving,
        isSuccess: isBankResolved,
    } = useResolveBankMutation({})

    const { mutate: createVendor, isLoading } = useMutationCreateVendor({
        onSuccess: () => {
            toastHandler({ message: 'Vendor Created', state: 'success' })
            setCloseModal(false)
        },
    })
    const handleCreateVendor = () => {
        const { category, description, owner, name, email } = createVendorProps
        // mutate({ ...createVendorProps })

        if (!category || !description || !owner || !name || !email) {
            toastHandler({ message: 'Fill in the form details', state: 'error' })
            return
        }
        if (!resolvedBankDetails || !bankDetails.account_number || !bankDetails.bank_code) {
            setErrMsg(true)
            toastHandler({ message: 'Confirm your bank details', state: 'error' })
            return
        }
        const nipCode = allBankList?.find(bank => bank.code === bankDetails.bank_code)?.nipCode
        createVendor({ ...createVendorProps, ...bankDetails, org_id: selectedAccount?.org?.id, nipCode })
    }

    const checkEmailFormat = emailRegex.test(createVendorProps.email)
    const disableButton = () => {
        const res = Object.values({ ...createVendorProps, ...bankDetails })
            .map(value => !!value)
            .some(data => data === false)
        return res
    }

    useEffect(() => {
        const { account_number } = bankDetails
        if (account_number?.length === 10 && bankDetails?.bank_code) {
            confirmBankFn({
                account_number: bankDetails.account_number,
                bank_code: bankDetails.bank_code,
                account_name: resolvedBankDetails?.account_name,
            })
            return
        }
    }, [bankDetails.account_number, bankDetails?.bank_code])

    return (
        <ModalWrapper
            formId='vendorForm'
            shouldConfirmClose
            variants={undefined}
            closeModal={() => setCloseModal(false)}
        >
            <section className='w-screen lg:w-[44rem] max-h-[85vh] overflow-scroll pb-8'>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end '>
                    <div className='flex items-center w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B]'>Add New Vendor</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setCloseModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <form id='vendorForm' className='' onSubmit={e => e.preventDefault}>
                    <section className='flex p-6 mt-5 gap-[1rem] lg:gap-[2rem]'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>
                                Vendor name
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='text'
                                placeholder='Enter name of vendor'
                                name='name'
                                value={createVendorProps.name}
                                onChange={handleInputChange}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>
                                Category
                                <span className='text-[red]'>*</span>
                            </label>
                            <div>
                                <Select
                                    name='category'
                                    value={createVendorProps.category}
                                    onValueChange={data => setCreateVendorProps(prev => ({ ...prev, category: data }))}
                                >
                                    <SelectTrigger className='w-full h-[3rem] border-[#DADCE0]'>
                                        <SelectValue placeholder='Select expense category' />
                                    </SelectTrigger>
                                    <SelectContent className='max-h-[200px] overflow-scroll'>
                                        {categories?.length > 0 ? (
                                            categories.map(category => (
                                                <SelectItem key={category.id} value={category.id}>
                                                    {category.label}
                                                </SelectItem>
                                            ))
                                        ) : (
                                            <>
                                                <SelectItem value=''>Please wait for a little while</SelectItem>
                                            </>
                                        )}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </section>
                    <section className='flex px-6 gap-[1rem] lg:gap-[2rem]'>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>
                                Email address
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='email'
                                placeholder='Enter email address'
                                name='email'
                                value={createVendorProps.email}
                                onChange={handleInputChange}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>
                                Vendor owner
                                <span className='text-[red]'>*</span>
                            </label>
                            <div>
                                <Select
                                    name='owner'
                                    value={createVendorProps.owner}
                                    onValueChange={data => setCreateVendorProps(prev => ({ ...prev, owner: data }))}
                                >
                                    <SelectTrigger className='w-full h-[3rem] border-[#DADCE0]'>
                                        <SelectValue placeholder='Select Vendor owner' />
                                    </SelectTrigger>
                                    <SelectContent className='max-h-[200px] overflow-scroll'>
                                        {orgMembers?.length > 0 ? (
                                            orgMembers.map(orgMember => (
                                                <SelectItem key={orgMember.id} value={orgMember.id}>
                                                    {orgMember?.user?.first_name} {orgMember?.user?.last_name}
                                                </SelectItem>
                                            ))
                                        ) : (
                                            <>
                                                <SelectItem value=''>Please wait for a little while</SelectItem>
                                            </>
                                        )}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className='flex flex-col flex-1 gap-3 px-6 mt-5'>
                            <label className='flex items-center justify-between'>
                                <span className='flex items-center gap-1'>
                                    Description
                                    <span className='text-[red]'>*</span>
                                </span>
                                <span className='text-xs'>{createVendorProps.description.length} / 120</span>
                            </label>
                            <Textarea
                                name='description'
                                placeholder='Describe vendor'
                                className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                                value={createVendorProps.description}
                                onChange={e => handleInputChange(e)}
                                maxLength={120}
                            />
                        </div>
                    </section>
                    <section
                        className={` mt-5 ${showBankForm ? 'bg-[#F9F9F9] px-6' : 'bg-transparent'} rounded-lg py-6 mx-6 `}
                    >
                        {!showBankForm ? (
                            <div className='flex gap-4 items-center'>
                                <Button
                                    icon={<Plus />}
                                    className='bg-transparent text-[#202020] border-[#DADCE0] border hover:bg-transparent hover:opacity-70 px-4 text-sm'
                                    onClick={() => setShowBankForm(true)}
                                >
                                    Add payment details{' '}
                                </Button>

                                {errMsg && (
                                    <span className='text-xs text-[#CF491E]'>Please add vendor payment details</span>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className='flex justify-between items-center mb-5'>
                                    <h3 className='text-lg font-medium'>Add payment details</h3>
                                    <Minus
                                        className='bg-[#F2F2F2] rounded-full p-2 cursor-pointer'
                                        width={30}
                                        height={30}
                                        onClick={() => setShowBankForm(false)}
                                    />
                                </div>

                                <div className='flex gap-[2rem]'>
                                    <div className='w-1/2 '>
                                        <label className='font-normal pb-3 inline-block text-sm' htmlFor='acct_name'>
                                            Bank name
                                        </label>
                                        <Select
                                            name='accnt_number'
                                            onValueChange={data =>
                                                setBankDetails(prev => ({ ...prev, bank_code: data }))
                                            }
                                            value={bankDetails?.bank_code}
                                        >
                                            <SelectTrigger className='w-full h-[45px] border-[#DADCE0] bg-base_gray_100 placeholder:text-[base_gray_100]  text-[14px]'>
                                                <SelectValue placeholder={'Select bank name'} className='' />
                                            </SelectTrigger>
                                            <SelectContent className='w-[18rem] h-[19rem] overflow-scroll'>
                                                {allBankList?.map(bank => (
                                                    <SelectItem
                                                        value={bank.code}
                                                        key={bank.code}
                                                        className='cursor-pointer'
                                                    >
                                                        {bank.name}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    <div>
                                        <label className='font-normal pb-3 inline-block text-sm' htmlFor='accnt_number'>
                                            Account number
                                        </label>
                                        <Input
                                            placeholder='Enter account number'
                                            id='accnt_number'
                                            className='h-[45px] border-[#DADCE0]'
                                            type='number'
                                            value={bankDetails?.account_number as string}
                                            onChange={e => {
                                                // if (bankDetails.account_number.length > 10) return
                                                setBankDetails(prev => ({ ...prev, account_number: e.target.value }))
                                            }}
                                            maxLength={10}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {isBankResolved && (
                            <div className='flex items-center gap-2 text-[#19C37D] mt-4'>
                                <IoIosCheckmarkCircle className='' />
                                <label htmlFor='' className='text-sm font-medium'>
                                    {resolvedBankDetails?.account_name}
                                    {''}
                                </label>
                            </div>
                        )}
                        {isBankResolving && (
                            <div className='flex gap-3 text-[#838383] text-sm items-center mt-3'>
                                <span>Fetching bank name</span>
                                <LoaderIcon className='animate-spin w-4 h-4' />
                            </div>
                        )}
                    </section>
                </form>
                <section className='mx-6 mt-4'>
                    <Button
                        className='ml-auto'
                        onClick={handleCreateVendor}
                        loading={isLoading}
                        disabled={disableButton() || !resolvedBankDetails?.account_name || !checkEmailFormat}
                    >
                        Add vendor
                    </Button>
                </section>
            </section>
        </ModalWrapper>
    )
}
