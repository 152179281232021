import { BizpendAccounts } from '@/assets/assets.export'
import { DedicatedBankAccountType } from '@/types/accounts'
import { formatAmount } from '@/utils/money'
import { useNavigate } from 'react-router-dom'

type Props = {
    account: DedicatedBankAccountType
}
export default function AccountCard({ account }: Props) {
    const active = account?.setup === 'complete'
    const navigate = useNavigate()

    return (
        <div
            onClick={() => navigate(account?._id)}
            className={`cursor-pointer relative ${active ? 'border-green' : 'border-gray5'} border   bg-white rounded-xl p-4 py-6 z-0`}
        >
            <span className={`backdrop-blur-sm w-12 h-12 absolute -translate-x-1/2 left-1/2 -top-6 rounded-lg`}></span>
            <BizpendAccounts
                className={`z-10 w-12 h-12 absolute -translate-x-1/2 left-1/2 -top-6 rounded-lg ${active ? 'fill-green border-green  bg-green/10' : 'fill-brand border-gray4 bg-brand/10'}  border p-1.5`}
            />
            <ul className='space-y-2'>
                <li className='mt-3 flex justify-between'>
                    <span className='text-gray7 text-sm'>Name</span>{' '}
                    <span className='font-medium text-sm'>{account?.nickname ?? account?.account_name}</span>
                </li>
                <li className='flex justify-between'>
                    <span className='text-gray7 text-sm'>Balance</span>{' '}
                    <span className='font-medium text-sm'>{formatAmount(account?.balance)}</span>
                </li>
                <li className='flex justify-between'>
                    <span className='text-gray7 text-sm'>Transaction counts</span>{' '}
                    <span className='font-medium text-sm'>{account?.transactionsCount}</span>
                </li>
            </ul>
        </div>
    )
}
