import { OverAPeriodIcon, RecentExpensesIcon } from '@/assets/assets.export'
import { ReactComponent as MoneyIcon } from '@/assets/sidebar-expense.svg'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import ProgressBar from '@/components/ProgressBar'
import { CustomBarTooltip, CustomPieTooltip } from '@/components/Tooltips/CustomTooltip'
import {
    useGetBillsByCategory,
    useGetBillsByTopVendors,
    useGetBillsPaid,
    useGetBillsSummary,
} from '@/services/bill-payment/queries'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { getYear, startOfMonth, subMonths, subYears } from 'date-fns'
import { useMemo, useState } from 'react'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Pie,
    PieChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { getFullMonthName } from '../../Transactions/components/utils'

export const BillPaymentAnalytics = () => {
    // endpoints
    const { data: billSummaryData, isLoading: isLoadingBillSummary } = useGetBillsSummary({})
    const { data, isLoading: isLoadingMonthlyBills } = useGetBillsPaid({})
    const { data: billsByCategoryData, isLoading: isLoadingCategoryBills } = useGetBillsByCategory({})
    const { data: billsByVendorsData, isLoading: isLoadingVendorsBills } = useGetBillsByTopVendors({})

    // dates
    const thisYear = new Date().getFullYear()
    const [billsYear, setBillsYear] = useState(thisYear + '')
    const [summaryYear, setSummaryYear] = useState(thisYear + '')
    const [topVendorsYear, setTopVendorsYear] = useState(thisYear + '')
    const [categoryDate, setCategoryDate] = useState(`${startOfMonth(subMonths(new Date(), 0))}`)
    const dateThreeMonthsAgo = startOfMonth(subMonths(new Date(), 3))

    const chartData = data?.monthlyData

    const billsSummary = useMemo(() => billSummaryData, [billSummaryData])
    const billsByCategory = useMemo(() => billsByCategoryData, [billsByCategoryData])
    const billsByVendors = useMemo(() => billsByVendorsData, [billsByVendorsData])
    const sameTotalAmountSpent = billsByCategory?.filter(
        data => data?.totalAmount === billsByCategory?.[0]?.totalAmount
    )
    const percentageIncrease = billsSummary
        ? billsSummary.currentYearAmount === 0 && billsSummary.previousYearAmount === 0
            ? 0
            : ((billsSummary.currentYearAmount - billsSummary.previousYearAmount) / billsSummary.currentYearAmount) *
              100
        : 0

    if (isLoadingMonthlyBills || isLoadingCategoryBills || isLoadingBillSummary || isLoadingVendorsBills)
        return <ProgressBar />

    return (
        <div>
            <section className='p-6 rounded-[20px] mb-6 bg-white'>
                <div className='flex justify-between items-start mb-6'>
                    <span className='font-semibold block text-[20px]'>Bill payment insights</span>

                    <div>
                        <Select name='expenseYear' onValueChange={setSummaryYear} value={summaryYear}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[8rem]'>
                                <SelectItem value={`${thisYear}`}>This year</SelectItem>
                                <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className={`grid gird-cols-1 md:grid-cols-8 gap-[20px]`}>
                    <div className='col-span-3 py-2 flex justify-between flex-col '>
                        <div className='flex gap-6 mb-4 md:mb-0'>
                            <div className='bg-[#EFEFF9] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-brand stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <span className='text-[#5E5E5E] text-lg font-medium block '>Total bill amount</span>
                                <span className='text-3xl lg:text-4xl font-bold block'>
                                    {formatAmount(billsSummary?.totalBillAmount)}
                                    {/* ₦6,000,977,000.20 */}
                                </span>
                            </div>
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='text-[#5e5e5e] text-sm font-semibold'>
                                Spent
                                <span className='text-[#CF491E]  '> {percentageIncrease.toFixed(2)}% </span>
                                more than previous year ({Number(summaryYear) - 1})
                            </p>
                        </div>
                    </div>

                    <div className='col-span-5 grid grid-cols-2 md:grid-cols-3 gap-[10px]'>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#EFF7F9] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <p className='w-[50px] flex items-center justify-center'>
                                    <MoneyIcon className={`fill-[#45B0DE] stroke-[#fff] scale-125`} />
                                </p>
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Total bill count</p>
                                <p className='text-xl lg:text-2xl font-medium '>{billsSummary?.totalBillCount}</p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#FFFEEC] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-[#DEB145] stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Pending</p>
                                <p className='text-xl lg:text-2xl font-medium '>{billsSummary?.pendingBillCount}</p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#FFEFE7] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <p className='w-[50px] flex items-center justify-center'>
                                    <MoneyIcon className={`fill-[#FF9152] stroke-[#fff] scale-125`} />
                                </p>
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Partially Approved</p>
                                <p className='text-xl lg:text-2xl font-medium '>
                                    {billsSummary?.approvedBillCount ?? 0}
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#EFFFED] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-brightGreen stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Approved</p>
                                <p className='text-xl lg:text-2xl font-medium '>
                                    {billsSummary?.approvedBillCount ?? 0}
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-lightBlue rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <p className='w-[50px] flex items-center justify-center'>
                                    <MoneyIcon className={`fill-skyBlue stroke-[#fff] scale-125`} />
                                </p>
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Paid</p>
                                <p className='text-xl lg:text-2xl font-medium '>{billsSummary?.paidBillCount ?? 0}</p>
                            </div>
                        </div>
                        <div className='flex gap-2 md:gap-6'>
                            <div className='bg-[#FFEDED] rounded-full h-[50px] w-[50px] flex items-center justify-center'>
                                <MoneyIcon className={`fill-errorRed stroke-[#fff] scale-125`} />
                            </div>
                            <div className=''>
                                <p className='text-[#5E5E5E] text-sm lg:text-base'>Declined</p>
                                <p className='text-xl lg:text-2xl font-medium '>
                                    {billsSummary?.cancelledBillCount ?? 0}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className={`bg-white rounded-2xl mb-4 pb-8`}>
                <section className='p-2  md:p-4 lg:px-6 pt-6 pb-3'>
                    <div className='flex items-center justify-between mb-6'>
                        <div className='ml-5'>
                            <span className='font-semibold mt-4'>Bill payment overview by time period graph</span>
                            {!!data?.totalAmountYear && (
                                <div className='flex gap-3 items-center mt-2'>
                                    <p>
                                        <span className='px-[10px] bg-brand mr-2 rounded'></span> Invoice type
                                    </p>
                                    <p>
                                        <span className='px-[10px] bg-[#D5D5F5] mr-2 rounded'></span>Utility type
                                    </p>
                                </div>
                            )}
                        </div>
                        <div>
                            {!isLoadingMonthlyBills && (
                                <Select
                                    name='invoiceYear'
                                    onValueChange={data => {
                                        setBillsYear(data)
                                    }}
                                    value={billsYear}
                                >
                                    <SelectTrigger className='w-full'>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-[8rem]'>
                                        <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                        <SelectItem value={`${thisYear}`}>This year</SelectItem>
                                    </SelectContent>
                                </Select>
                            )}
                        </div>
                    </div>
                    <>
                        {/*==== Bar chart ====*/}
                        {data?.totalAmountYear ? (
                            <>
                                <div className={`bg-white p-1 md:p-4 rounded-md`}>
                                    <ResponsiveContainer height={350} width={'100%'} className={'mx-auto'}>
                                        <BarChart data={chartData} margin={{ left: 60 }}>
                                            <CartesianGrid
                                                stroke='#DADCE0'
                                                vertical={false}
                                                strokeDasharray='5 5'
                                                fill='white'
                                            />
                                            <XAxis
                                                dataKey='name'
                                                stroke='#888888'
                                                fontSize={12}
                                                tickLine={false}
                                                axisLine={false}
                                            />
                                            <YAxis
                                                // dataKey='total'
                                                stroke='#888888'
                                                fontSize={12}
                                                tickLine={false}
                                                axisLine={false}
                                                tickFormatter={value => formatAmount(value)}
                                                hide={false}
                                                label={{
                                                    value: 'Bill payments in Naira',
                                                    angle: -90,
                                                    position: 'insideLeft',
                                                    offset: -50,
                                                }}
                                                offset={10}
                                                // domain={[0, 'dataMax + 1']}
                                                type='number'
                                                // domain={[0, 'dataMax + 1']}
                                            />
                                            <Bar dataKey='total' fill='#454ADE' radius={[4, 4, 0, 0]} barSize={20} />
                                            <Bar
                                                dataKey='outgoingTotal'
                                                fill='#D5D5F5'
                                                radius={[4, 4, 0, 0]}
                                                barSize={20}
                                            />
                                            <Tooltip
                                                filterNull={true}
                                                cursor={false}
                                                wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                                                content={<CustomBarTooltip />}
                                            />
                                            <ReferenceLine y={0} stroke='#DADCE0' type='linear' />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className='flex flex-col md:flex-row md:ml-4 gap-4 text-sm md:text-base'>
                                    <div className='px-3 md:px-4 py-3 bg-offwhite md:w-[462px] rounded-lg '>
                                        In the month of
                                        <span className='font-semibold text-brand'>
                                            {data && getFullMonthName(data?.highestMonth?.name?.name)}
                                        </span>
                                        , {thisYear.toString() === billsYear ? 'this' : 'last'} year, you had the
                                        highest bill request amount paid (
                                        <span className='font-semibold'>{formatAmount(data?.highestMonth.total)}</span>
                                        ). While you had the lowest bill request amount paid in the month of{' '}
                                        <span className='font-semibold text-brand'>
                                            {data && getFullMonthName(data?.lowestMonth?.name?.name)}
                                        </span>{' '}
                                        (<span className='font-semibold'>{formatAmount(data?.lowestMonth.total)}</span>
                                        ).
                                    </div>
                                    <div className='px-3 md:px-4 py-3 bg-offwhite md:w-[462px] rounded-lg'>
                                        The average invoice bill request amount paid was{' '}
                                        <span className='font-semibold text-brand'>
                                            {formatAmount(data?.averageBillPaid)}
                                        </span>
                                        . While the total amount paid of bill paid was{' '}
                                        <span className='font-semibold text-brand'>
                                            {formatAmount(data?.totalAmountYear)}
                                        </span>
                                        .
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                                    <OverAPeriodIcon />
                                    {/* <h2 className='text-gray7 font-medium mt-5'>No transactions</h2> */}
                                    <p className='text-sm mt-6'>No Bill payment made yet</p>
                                </div>
                            </>
                        )}
                    </>
                </section>
            </div>
            <section className='p-5 rounded-[20px] bg-white mb-6'>
                <div className='flex items-center justify-between mb-6'>
                    <span className='font-semibold'>Bill payment by category</span>

                    <div>
                        <Select name='expenseCategoryDate' onValueChange={setCategoryDate} value={categoryDate}>
                            <SelectTrigger className='w-full'>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent className='w-[9rem]'>
                                <SelectItem value={`${startOfMonth(subMonths(new Date(), 0))}`}>This month</SelectItem>
                                <SelectItem value={`${startOfMonth(subMonths(new Date(), 1))}`}>Last month</SelectItem>
                                <SelectItem value={`${dateThreeMonthsAgo}`}>Last 3 months</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-5 md:gap-x-[20px] gap-y-[20px] min-h-[100px]'>
                    <div
                        className={`relative border-b md:border-r border-[#DADCE0] w-full col-span-2 p-4 ${billsByCategory && billsByCategory?.length > 0 ? '' : 'hidden'}   `}
                        // style={{ height: `${min_height}` }}
                    >
                        <ResponsiveContainer height={'50%'} className={'min-h-[22rem] relative'}>
                            <PieChart width={800} height={700}>
                                <Pie
                                    data={billsByCategory}
                                    cy={170}
                                    innerRadius={100}
                                    outerRadius={147}
                                    fill='#8884d8'
                                    // paddingAngle={5}
                                    dataKey='totalAmount'
                                    nameKey='category'
                                    height={300}
                                >
                                    {billsByCategory?.map((_, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip offset={1} content={<CustomPieTooltip />} wrapperStyle={{ zIndex: '10' }} />
                            </PieChart>
                        </ResponsiveContainer>
                        {billsByCategory && billsByCategory?.length > 0 && (
                            <h1 className='absolute top-[200px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center'>
                                You spent the most on
                                <br />
                                {sameTotalAmountSpent && sameTotalAmountSpent.length > 1 ? (
                                    sameTotalAmountSpent?.map((data, idx) => (
                                        <span className='font-medium text-xs block max-w-[200px]'>
                                            {data?.category}
                                            {idx === sameTotalAmountSpent?.length - 2 ? ' & ' : ''}
                                        </span>
                                    ))
                                ) : (
                                    <span className='text-sm font-semibold max-w-[200px] block'>
                                        {billsByCategory?.[0].category}
                                    </span>
                                )}
                            </h1>
                        )}
                        <div className='grid grid-cols-3 gap-6 mt-9'>
                            {billsByCategory?.map((data, i) => (
                                <div className='w-full'>
                                    <span
                                        className=' h-1 block mr-2 rounded'
                                        style={{
                                            backgroundColor: `${COLORS[i]}`,
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                    <h2
                                        className='text-sm lg:text-base font-medium mt-1'
                                        style={{ overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}
                                    >
                                        {data.category}
                                    </h2>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div
                        className={`rounded-lg overflow-hidden ${billsByCategory && billsByCategory?.length > 0 ? 'col-span-3' : 'col-span-5'} $ flex flex-col gap-4 mt-4`}
                    >
                        {billsByCategory && billsByCategory?.length > 0 ? (
                            billsByCategory.map(category => (
                                <>
                                    <div className='border border-[#DADCE0] rounded-lg py-4 px-5'>
                                        <div className='text-sm lg:text-base font-semibold flex justify-between items-center'>
                                            <p className='w-1/2'>{category.category}</p>
                                            <div className='flex gap-4 md:gap-12'>
                                                <p className='text-right'>{formatAmount(category.totalAmount)}</p>
                                                <p className='text-xs lg:text-sm text-brand'>{category.percentage}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        ) : (
                            <>
                                <div className='flex items-center justify-center flex-col text-gray7 h-full'>
                                    <RecentExpensesIcon />
                                    <h2 className='text-gray7 font-medium mt-5'>No bill payment</h2>
                                    <p className='text-sm'>No bill has been paid yet yet</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
            <section className='flex justify-between my-8 lg:gap-9'>
                <section className='bg-white rounded-xl p-4 w-full lg:w-1/2 flex flex-col gap-4 h-full min-h-[450px]'>
                    <div className='flex items-center justify-between rounded text-dark mb-4'>
                        <div className=''>
                            <span className=' font-semibold text-base'>Top spenders - Vendors</span>
                            <p className='text-sm text-gray7 mt-1 w-[210px] md:w-auto'>
                                See the top 5 vendors bill payments were made to.
                            </p>
                        </div>

                        <div>
                            <Select onValueChange={dateYear => setTopVendorsYear(dateYear)} value={topVendorsYear}>
                                <SelectTrigger className='w-full'>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className='w-[8rem]'>
                                    <SelectItem value={`${getYear(new Date())}`}>This year</SelectItem>
                                    <SelectItem value={`${getYear(subYears(new Date(), 1))}`}>Last year</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <div className={`flex flex-col gap-4`}>
                        {billsByVendors && billsByVendors?.length > 0 ? (
                            billsByVendors
                                ?.filter((_, i) => i < 5)
                                .map(vendor => (
                                    <>
                                        <div className='border border-[#DADCE0] rounded-lg py-4 px-5'>
                                            <div className='text-sm lg:text-base font-semibold flex justify-between items-center'>
                                                <p className='w-1/2 flex gap-3 items-center'>
                                                    <p className='w-[30px] h-[30px] bg-[#D9D9D9] text-[#838383] flex justify-center items-center rounded-full tracking-wider'>
                                                        {capitalizeText(vendor?.vendor)?.[0]}
                                                        {capitalizeText(vendor?.vendor)?.[1]}
                                                    </p>
                                                    {capitalizeText(vendor.vendor)}{' '}
                                                </p>
                                                <div className='flex gap-3 md:gap-10'>
                                                    <p className='text-right'>{formatAmount(vendor?.totalAmount)}</p>
                                                    <p className='text-xs lg:text-sm text-brand w-[50px] text-right'>
                                                        {vendor?.percentage}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                        ) : (
                            <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                                <OverAPeriodIcon />
                                <p className='text-sm mt-6'>Nothing to see here yet</p>
                            </div>
                        )}
                    </div>
                </section>
                <section></section>
            </section>
        </div>
    )
}

const COLORS = [
    '#57E1FF',
    '#FF9152',
    '#5C9BFF',
    '#F4DD09',
    '#93F',
    '#AD381E',
    '#FC52FF',
    '#FFD6C9',
    '#A2AD1E',
    '#45CFB6',
    '#DFFFC9',
    '#FFC9D7',
    '#D6C9FF',
    'purple',
    'black',
    'red',
]
