import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { ACCESS_PERMISSIONS } from '@/utils/permisson-helper'
import { ReactNode } from 'react'

type Props = {
    children: ReactNode
    requiredPermissions: ACCESS_PERMISSIONS[]
}

export const AccessPermissionHOC = ({ children, requiredPermissions }: Props) => {
    const hasPermission = useHasAccessPermisson(requiredPermissions)

    if (!hasPermission) {
        return null
    }

    return <>{children}</>
}
