import { Button } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import ModalWrapper from '../../ModalWrapper'
import { useUpdateProfile } from '@/services/auth/mutations'

interface RemoveProfilePictureProps {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function RemoveProfilePicture({ closeModal }: RemoveProfilePictureProps) {
    // const [profilePic, setProfilePic] = useState<FileUploadFormatProps>()

    const { mutate: removeProfileImgFn, isLoading } = useUpdateProfile({
        onSuccess: () => {
            toastHandler({ message: 'Profile image removed', state: 'success' })
            closeModal(false)
        },
    })

    const handleREmoveProfile = () => {
        // const formdata = new FormData()
        // fetch('../../../../assets/profile-img.svg')
        //     .then(res => res.blob())
        //     .then(blob => {})

        // formdata.append('file', new File(fileEmptyState as unknown as BlobPart[], 'placeholder'))

        removeProfileImgFn({ profile_photo: '' })
    }
    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>Are you sure you want to remove your profile image</p>

                <div className='mt-10 flex justify-end'>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isLoading}
                        onClick={handleREmoveProfile}
                    >
                        Yes, remove
                    </Button>
                    <button type='button' className='min-h-[50px] px-5 mx-3' onClick={() => closeModal(false)}>
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}
