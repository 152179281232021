import { createMutation } from '../api/mutation'
import { orgInsightkey, userInsightkey } from '../auth/queries'
import { keyQueryOrgExpenses, keyQueryPendingOrgExpenses } from '../expenses/queries'

// const key = [`/expenses-by-org`]

export const useMutateReimburse = createMutation({
    url: '/reimburse-user',
    // keysToRefetch: [key, ['/expense-id']],
    keysToRefetch: [keyQueryOrgExpenses, userInsightkey, orgInsightkey, ['/expense-id'], keyQueryPendingOrgExpenses],
    method: 'POST',
})
