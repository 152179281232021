import { Button } from '@/components'
import ModalWrapper from '../../ModalWrapper'
import { MilestoneType } from '@/types/payroll'
import { useDeleteMilestone } from '@/services/payroll/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { usePayrollContext } from '@/context'

type PaymentProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    milestone: MilestoneType
    contractId?: string
}

const DeleteMilestonePrompt = ({ closeModal, milestone, contractId }: PaymentProps) => {
    const { setSelectedContract } = usePayrollContext()
    const { mutate: deleteMilestone, isLoading } = useDeleteMilestone({
        queryParams: {
            contract_id: contractId as string,
            milestone_id: milestone.id ?? (milestone?._id as string),
        },
        onSuccess: () => {
            closeModal(false)
            toastHandler({ message: 'milestone deleted', state: 'success' })
            setSelectedContract(undefined)
        },
    })
    return (
        <ModalWrapper>
            <main className='h-[14rem] w-[34rem]'>
                <div className='pt-10'>
                    <p className='text-[#5E5E5E] font-semibold text-lg mb-10 w-[85%] mx-auto'>
                        Are you sure you want to delete this milestone? This action is irreversible.
                    </p>
                    <div className='flex gap-5 justify-end pr-10'>
                        <Button
                            className='bg-transparent text-[#202020] px-5 h-[3.5rem] rounded-lg text-xs hover:bg-transparent min-w-[100px]'
                            onClick={() => closeModal(false)}
                        >
                            Cancel
                        </Button>

                        <Button
                            className='bg-errorRed text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] hover:bg-errorRed hover:opacity-90 disabled:bg-errorRed disabled:opacity-30'
                            onClick={() => deleteMilestone({})}
                            loading={isLoading}
                        >
                            Delete milestone
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default DeleteMilestonePrompt
