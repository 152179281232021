import { Invoice, InvoiceSummary } from '@/types/invoice'
import { createQuery } from '../api/query'
import { InvoiceItemKey, InvoiceKey } from './mutations'
import { QueryGetInvoiceitemRes, QueryInvoicesByMonth, QueryOrgInvoiceType, QueryTopCustomers } from './types'
import { TransactionType } from '@/types/transactions'

const InvoiceByIdKey = (id: string) => ['invoice-key', id]
const CheckoutInvoiceByIdKey = (id: string) => ['invoice-key', id]
export const possibleTransactionKey = (id: string) => ['possible-transaction', id]

export const useGetAllInvoice = createQuery<QueryOrgInvoiceType>({
    url: '/get-invoices',
    key: InvoiceKey,
})

export const useGetInvoiceByID = createQuery<Invoice>({
    url: '/get-invoice-by-id',
    key: InvoiceByIdKey,
})

export const useGetCheckoutInvoiceByID = createQuery<Invoice>({
    url: '/get-checkout-invoice-by-id',
    key: CheckoutInvoiceByIdKey,
})

export const useGetInvoiceSummary = createQuery<InvoiceSummary>({
    url: '/invoice-analytics',
    key: ['invoice-analytics'],
})

export const useGetInvoiceByMonth = createQuery<QueryInvoicesByMonth>({
    url: '/get-invoice-by-month',
    key: ['get-invoice-by-month'],
})

export const useGetInvoiceItem = createQuery<QueryGetInvoiceitemRes>({
    url: '/invoice-items',
    key: InvoiceItemKey,
})

export const useGetTopInvoiceCustomers = createQuery<QueryTopCustomers>({
    url: '/top-invoice-customers',
    key: ['top-invoice-customers'],
})
export const useGetPossibleTransactionMatch = createQuery<TransactionType[]>({
    url: '/get-possible-invoice-transactions',
    key: possibleTransactionKey,
})
