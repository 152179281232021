import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { calculatePayrollSum } from '@/modules/dashboard/Payroll/utils'
import { useGetPaymentProfile } from '@/services/payroll/queries'
import { PaymentProfileTypes } from '@/types/payroll'
import { useMemo } from 'react'
import { capitalizeText } from '../../../../../utils/string'
import StatusColor from '@/components/utils/StatusColor'
import { formatAmount } from '../../../../../utils/money'
// import downArrow from '@/assets/down-arrow.svg'
import { format } from 'date-fns'

export default function PaymentProfile() {
    const { selectedAccount } = useAuthContext()
    const { data, isLoading, isRefetching } = useGetPaymentProfile({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            account: selectedAccount?.id as string,
        },
    })
    const paymentProfileDetails = useMemo(() => data ?? ({} as PaymentProfileTypes), [data])

    const profile_details = [
        {
            name: 'Name',
            desc: paymentProfileDetails?.employee?.user.first_name
                ? `${capitalizeText(paymentProfileDetails?.employee?.user.first_name)} ${capitalizeText(
                      paymentProfileDetails?.employee?.user.last_name
                  )}`
                : null,
        },
        {
            name: 'User ID',
            desc: paymentProfileDetails?.employee?.employee_id,
        },
        {
            name: 'Employment type',
            desc: capitalizeText(paymentProfileDetails?.employment_type),
        },
        {
            name: 'Pay frequency',
            desc: capitalizeText(paymentProfileDetails?.pay_frequency),
        },
        {
            name: `${capitalizeText(paymentProfileDetails?.pay_frequency)} gross salary`,
            desc: paymentProfileDetails?.gross_salary ? formatAmount(paymentProfileDetails?.gross_salary) : null,
        },
        {
            name: 'Deductions',
            desc: calculatePayrollSum(
                paymentProfileDetails?.deductions as any[],
                'amount',
                paymentProfileDetails?.gross_salary
            ),
        },
        {
            name: 'Bonuses',
            desc: calculatePayrollSum(
                paymentProfileDetails?.bonuses as any[],
                'amount',
                paymentProfileDetails?.gross_salary
            ),
        },
        {
            name: 'Net salary',
            desc: paymentProfileDetails?.net_salary ? formatAmount(paymentProfileDetails?.net_salary) : null,
        },
        {
            name: 'Last pay',
            desc:
                paymentProfileDetails?.last_payday &&
                format(new Date(paymentProfileDetails?.last_payday), 'dd/MM/yyyy'),
        },
        {
            name: 'Next pay',
            desc:
                paymentProfileDetails?.next_payday &&
                format(new Date(paymentProfileDetails?.next_payday), 'dd/MM/yyyy'),
        },
        {
            name: 'Total pay',
            desc: paymentProfileDetails?.total_pay ? formatAmount(paymentProfileDetails?.total_pay) : null,
        },
        {
            name: 'Payroll status',
            desc: <StatusColor status={paymentProfileDetails?.active ? 'ACTIVE' : 'INACTIVE'} />,
        },
    ]

    // console.log({ bonus: paymentProfileDetails?.bonuses })
    if (isLoading) return <ProgressBar />
    return (
        <div className='border border-[#DADCE0] p-3 rounded-lg  mt-8 px-3 lg:px-8'>
            <div className='border-b border-[#DADCE0] pb-3 flex items-center justify-between mt-5'>
                <h2 className=' font-semibold text-base md:text-lg'>My payment profile</h2>
                {/* <div className='flex gap-2 cursor-pointer items-center'>
                    <div className=' h-5 w-5 md:h-[28px] md:w-[28px] border md:border-2 border-[#DADCE0] rounded md:rounded-[5px] \ items-center justify-center p-0.5 md:p-2  flex '>
                        <img src={downArrow} alt='button' className='scale-100 md:scale-[2] ' />
                    </div>
                    <p className='font-medium md:font-semibold text-sm md:text-base'>Download profile</p>
                </div> */}
            </div>
            <div
                className={`flex flex-wrap max-w-[60rem] gap-4 md:gap-9 lg:gap-x-[5rem] mt-8 gap-y-[1.3rem] ${
                    isRefetching ? 'opacity-50' : 'opacity-100'
                }`}
            >
                {profile_details.map((profile, idx) =>
                    profile.desc ? (
                        <div className='flex flex-col w-[10rem]' key={idx}>
                            <h4 className='text-md font-medium'>{profile.name}</h4>
                            <span className='text-[#5E5E5E] text-[1rem] mt-1'>{profile.desc}</span>
                        </div>
                    ) : (
                        <div className='flex flex-col w-[10rem]' key={idx}>
                            <h4 className='text-md font-medium'>{profile.name}</h4>
                            <span className='text-[#5E5E5E] text-[1rem] mt-1'>{profile.desc}</span>
                        </div>
                    )
                )}
            </div>
        </div>
    )
}
