import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useUpdatePayrollSettings } from '@/services/payroll/mutations'
import { useEffect, useState } from 'react'
import { biWeekly, weekdayIndex } from '../../../utils'
import DatePicker from 'react-datepicker'
import { format, subDays } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { toastHandler } from '@/components/utils/Toast'
import { Minus } from 'lucide-react'
import { Plus } from 'lucide-react'
import { dayFromNumber, numberDay } from './utils'
import { formatDate } from '../../../helper'

type EditProps = {
    setEditPayDaySettings: React.Dispatch<React.SetStateAction<boolean>>
    id: string
    monthlyDate: number | undefined
    biWeeklyDate: string | undefined
    weeklyDate: string | undefined
    biWeeklyDay: string | undefined
}

const PayDaySettingsEdit = ({
    setEditPayDaySettings,
    id,
    biWeeklyDate,
    monthlyDate,
    weeklyDate,
    biWeeklyDay,
}: EditProps) => {
    const [payDaySettings, setPayDaySettings] = useState({
        weekly: weeklyDate?.toString(),
        monthly: monthlyDate?.toString(),
        biWeekly: biWeeklyDate?.toString(),
    })
    const [biweeklyActualDates, setBiweeklyActualDates] = useState({
        biweekly_payment_start_date: '',
        biweekly_payment_day: '',
        calendar_date: '',
    })
    const [showBiweeklyCalendar, setShowBiweeklyCalendar] = useState(false)
    const [payrollWeekDay, setPayrollWeekDay] = useState(numberDay(payDaySettings.weekly!))

    const { mutate: updatePayrollSettingsFn, isLoading } = useUpdatePayrollSettings({
        queryParams: {
            setting_id: id as string,
        },
        onSuccess() {
            setEditPayDaySettings(false)
            toastHandler({ message: 'Changes saved', state: 'success' })
        },
    })

    const filterDays = (date: Date) => {
        return date.getDay() === weekdayIndex(payDaySettings?.biWeekly as string)
    }

    useEffect(() => {
        setBiweeklyActualDates(prev => ({
            ...prev,
            biweekly_payment_start_date: subDays(new Date(biweeklyActualDates?.calendar_date), 14).toString(),
        }))
    }, [biweeklyActualDates?.calendar_date])

    return (
        <div className='p-6 rounded-lg border border-[#DADCE0] mb-5'>
            <div className='flex justify-between items-center mb-6'>
                <div className=''>
                    <h2 className='text-lg font-medium'>Pay day</h2>
                    <p className='text-sm text-[#5E5E5E] font-medium mt-1'>
                        Select pay day for monthly, bi-weekly and weekly pay frequency
                    </p>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row gap-4 lg:gap-12 mb-11'>
                <div className='w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Monthly</h2>
                    <div className='flex items-baseline gap-3'>
                        <div className='flex border border-[#DADCE0] rounded-lg gap-3 w-[99px] h-10 items-center px-2 justify-center'>
                            <button
                                disabled={Number(payDaySettings.monthly) == 1 || isLoading}
                                className='disabled:opacity-30'
                            >
                                <Minus
                                    color='#5E5E5E'
                                    className='cursor-pointer'
                                    onClick={() =>
                                        setPayDaySettings(prev => ({
                                            ...prev,
                                            monthly: (Number(payDaySettings.monthly) - 1).toString(),
                                        }))
                                    }
                                    size={20}
                                />
                            </button>
                            {payDaySettings.monthly}
                            <button
                                disabled={Number(payDaySettings.monthly) == 30 || isLoading}
                                className='disabled:opacity-30'
                            >
                                <Plus
                                    color='#5E5E5E'
                                    className='cursor-pointer'
                                    onClick={() =>
                                        setPayDaySettings(prev => ({
                                            ...prev,
                                            monthly: (Number(payDaySettings.monthly) + 1).toString(),
                                        }))
                                    }
                                    size={20}
                                />
                            </button>
                        </div>
                        <p className='whitespace-nowrap'>
                            {formatDate(Number(payDaySettings.monthly))} day of the month
                        </p>
                    </div>
                </div>
                <div className='w-1/4'>
                    <h2 className='text-[#838383] text-base mb-4'>Weekly</h2>
                    <div className='flex items-baseline gap-3'>
                        <div className='flex border border-[#DADCE0] rounded-lg gap-3 w-[90px] h-10 items-center px-2'>
                            <button disabled={payrollWeekDay == 1 || isLoading} className='disabled:opacity-30'>
                                <Minus
                                    color='#5E5E5E'
                                    className='cursor-pointer'
                                    onClick={() => setPayrollWeekDay(payrollWeekDay - 1)}
                                    size={20}
                                />
                            </button>
                            {payrollWeekDay}
                            <button disabled={payrollWeekDay == 7 || isLoading} className='disabled:opacity-30'>
                                <Plus
                                    color='#5E5E5E'
                                    className='cursor-pointer'
                                    onClick={() => setPayrollWeekDay(payrollWeekDay + 1)}
                                    size={20}
                                />
                            </button>
                        </div>
                        <p className='whitespace-nowrap'>{formatDate(payrollWeekDay)} day of the week</p>
                    </div>
                </div>
                <div className='w-1/4 relative'>
                    <h2 className='text-[#838383] text-base mb-4'>Bi-weekly</h2>
                    <Select
                        name='pay_day'
                        onValueChange={data => {
                            setShowBiweeklyCalendar(true)
                            setPayDaySettings(prev => ({
                                ...prev,
                                biWeekly: data,
                            }))
                        }}
                        value={payDaySettings?.biWeekly?.toString() as string}
                    >
                        <SelectTrigger
                            className={`h-[2.5rem] border-[#DADCE0] bg-base_gray_100  text-base lg:w-full font-medium w-[18rem]`}
                            disabled={isLoading}
                        >
                            <SelectValue placeholder={'Select an option'} />
                        </SelectTrigger>
                        <SelectContent className='text-sm w-[18rem]'>
                            {biWeekly.map(option => (
                                <SelectItem value={option.desc} className='cursor-pointer'>
                                    {option.name}s
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    {!!biweeklyActualDates.calendar_date && (
                        <p className='text-xs font-medium mt-1'>
                            Pay starts - {format(new Date(biweeklyActualDates.calendar_date), 'dd/MM/yyyy')}
                        </p>
                    )}
                    {showBiweeklyCalendar && (
                        <div className='bg-white p-4 w-[19rem] mt-3 rounded-lg absolute -top-40 border border-gray6 z-[50]'>
                            <h3 className='font-bold'>Set first payment date</h3>
                            <p className='text-sm'>
                                Select the first{' '}
                                <span className='text-brand font-semibold'>
                                    {capitalizeText(payDaySettings.biWeekly)}
                                </span>{' '}
                                to pay bi-weekly salaries{' '}
                            </p>

                            <div className='flex justify-center items-center mt-2'>
                                <DatePicker
                                    onChange={date => {
                                        setShowBiweeklyCalendar(false)
                                        setBiweeklyActualDates(prev => ({
                                            ...prev,
                                            calendar_date: date! as unknown as string,
                                        }))
                                    }}
                                    selected={
                                        biweeklyActualDates?.calendar_date
                                            ? new Date(biweeklyActualDates?.calendar_date)
                                            : undefined
                                    }
                                    filterDate={filterDays}
                                    inline
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='flex gap-3 justify-end'>
                <Button
                    className='bg-transparent hover:bg-transparent hover:border hover:border-[#838383] text-[#202020]'
                    onClick={() => setEditPayDaySettings(false)}
                    disabled={isLoading}
                >
                    cancel
                </Button>
                <Button
                    onClick={() => {
                        updatePayrollSettingsFn({
                            monthly_payment_day: payDaySettings?.monthly,
                            weekly_payment_day: dayFromNumber(payrollWeekDay).toLowerCase(),
                            biweekly_payment_day: payDaySettings?.biWeekly,
                            biweekly_payment_start_date:
                                biweeklyActualDates?.biweekly_payment_start_date !== 'Invalid Date'
                                    ? biweeklyActualDates?.biweekly_payment_start_date
                                    : new Date(biWeeklyDay as string),
                        })
                    }}
                    loading={isLoading}
                >
                    Save changes
                </Button>
            </div>
        </div>
    )
}

export default PayDaySettingsEdit
