import { v4 as uuidv4 } from 'uuid'

export const addToPayrollFields = [
    {
        title: 'Name',
        fieldType: 'dropdown',
        placeholder: 'Select user',
        name: 'employee',
    },
    {
        title: 'Employment type',
        fieldType: 'dropdown',
        fieldOptions: [
            { name: 'Full-time', desc: 'full time' },
            { name: 'Part-time', desc: 'part time' },
            { name: 'Contract', desc: 'contract' },
        ],
        placeholder: 'Full time, part time etc.',
        name: 'employment_type',
    },

    {
        title: 'Gross salary',
        fieldType: 'amount',
        placeholder: 'Enter amount',
        name: 'gross_salary',
    },
    {
        title: 'Net salary',
        fieldType: 'amount',
        placeholder: '0.00',
        name: 'net_salary',
    },

    {
        title: 'Pay frequency',
        fieldType: 'dropdown',
        fieldOptions: [
            { name: 'Weekly', desc: 'weekly' },
            { name: 'Bi-weekly', desc: 'biweekly' },
            { name: 'Monthly', desc: 'monthly' },
        ],
        placeholder: 'Monthly , weekly etc.',
        name: 'pay_frequency',
    },
    {
        title: 'Start date',
        fieldType: 'date',
        placeholder: 'Chose start date',
        name: 'start_date',
    },
]
export const defaultAdditions = {
    reason: '',
    amount: '',
    type: '',
    id: uuidv4(),
    name: '',
    amountType: '',
}

export const defaultMileStone = {
    title: '',
    amount: '',
    due_date: '',
    uniqueId: uuidv4(),
}

export const addToPayrollForContract = [
    {
        title: 'Name',
        fieldType: 'dropdown',
        placeholder: 'Select user',
        name: 'employee',
    },
    {
        title: 'Total Pay',
        fieldType: 'amount',
        placeholder: 'Enter Total Pay',
        name: 'total_pay',
    },
    // {
    //     title: 'Employment type',
    //     fieldType: 'dropdown',
    //     fieldOptions: [{ name: 'Contract', desc: 'contract' }],
    //     placeholder: 'Full time, part time etc.',
    //     name: 'employment_type',
    // },

    {
        title: 'Contract title',
        fieldType: 'text',
        placeholder: 'Enter Title',
        name: 'contract_title',
    },
    {
        title: 'Start date',
        fieldType: 'date',
        placeholder: 'Chose start date',
        name: 'start_date',
    },
    {
        title: 'End date',
        fieldType: 'date',
        placeholder: 'Chose End date',
        name: 'end_date',
    },
    {
        title: 'Attach this to a budget?',
        fieldType: 'radio',
        fieldOptions: [
            { value: 'Yes', id: 'link' },
            { value: 'No', id: 'no_link' },
        ],
        name: 'attact_budget',
    },
    {
        title: 'Select budget',
        fieldType: 'dropdown',
        name: 'budget',
    },
    // {
    //     title: 'Pay frequency',
    //     fieldType: 'dropdown',
    //     fieldOptions: [{ name: 'Pay by Milestone', desc: 'milestones' }],
    //     placeholder: 'Monthly , weekly etc.',
    //     name: 'pay_frequency',
    // },
]
