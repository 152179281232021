import { ReactNode } from 'react'

type Props = {
    children: ReactNode
}

const PageContent = ({ children }: Props) => {
    return <div className='lg:w-[93%] mx-auto '>{children}</div>
}

export const PageWrapper = ({ children }: Props) => {
    return <div className='p-5 mx-auto '>{children}</div>
}

export default PageContent
