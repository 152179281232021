import PayrollEmptyState from '@/assets/payroll-empty-state.svg'
import Table from '@/components/Table'
import StatusColor from '@/components/utils/StatusColor'
import { PaymentProfile } from '@/types/payroll'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import React, { useEffect } from 'react'
import { calculateBonusAndDeductionSum } from '../utils'
import CheckBox from '@/components/Table/CheckBox'
import { Edit, MinusSquare, PlusSquare } from 'lucide-react'

type Props = {
    payrolls: PaymentProfile[]
    selectedPayrollDetails: PaymentProfile[]
    setSelectedPayrollDetails: (profile: PaymentProfile[]) => void
    togglePayrollDetail: (payroll: PaymentProfile) => void
}

export default function GeneratePayrollTable({
    payrolls,
    selectedPayrollDetails,
    setSelectedPayrollDetails,
    togglePayrollDetail,
}: Props) {
    const columnHelper = createColumnHelper<PaymentProfile>()
    const [rowSelection, setRowSelection] = React.useState({})

    useEffect(() => {
        if (Object.keys(rowSelection).length) {
            setSelectedPayrollDetails(
                payrolls.filter((_, index) => {
                    return rowSelection[index as keyof typeof rowSelection]
                })
            )
        }
    }, [rowSelection, payrolls, setSelectedPayrollDetails])

    const columns: ColumnDef<PaymentProfile, any>[] = [
        columnHelper.display({
            id: 'select',
            header: () => {
                const allSelected = payrolls.every(payroll =>
                    selectedPayrollDetails.some(
                        profile => (profile._id ?? profile?.id) === (payroll._id ?? payroll?.id)
                    )
                )
                const indeterminate =
                    payrolls.some(payroll =>
                        selectedPayrollDetails.some(
                            profile => (profile._id ?? profile?.id) === (payroll._id ?? payroll?.id)
                        )
                    ) && !allSelected

                return (
                    <CheckBox
                        checked={allSelected}
                        indeterminate={indeterminate}
                        onChange={() => setSelectedPayrollDetails(allSelected ? [] : payrolls)}
                    />
                )
            },
            cell: ({ row }) => {
                const rowId = row.original._id ?? row.original.id
                const isChecked = selectedPayrollDetails.some(profile => (profile._id ?? profile?.id) === rowId)

                return (
                    <CheckBox
                        checked={isChecked}
                        disabled={!row.getCanSelect()}
                        onChange={() => togglePayrollDetail(row.original)}
                    />
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('employee.user', {
            header: () => <>Users</>,
            cell: cell => {
                return (
                    <>
                        {capitalizeText(cell?.renderValue()?.first_name as string)}{' '}
                        {capitalizeText(cell?.renderValue()?.last_name[0] as string)}.
                    </>
                )
            },
            enableSorting: true,
        }),

        columnHelper.accessor('days_worked', {
            header: () => <p>Pay period</p>,
            cell: cell => (
                <div>
                    <p className='font-normal'>
                        worked {cell.renderValue()} day{cell.renderValue() > 1 && 's'}
                    </p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('gross_salary', {
            header: () => <p>Gross salary</p>,
            cell: cell => (
                <div>
                    <p> {formatAmount(cell.renderValue() ?? cell.row.original?.gross_salary)}</p>
                    <p className='font-normal'>{capitalizeText(cell.row.original.pay_frequency)}</p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('deductions', {
            header: () => <p>Deductions</p>,
            cell: cell => {
                return (
                    <span>
                        {formatAmount(
                            calculateBonusAndDeductionSum(cell.renderValue(), 'amount', cell.row.original.gross_salary)
                        )}
                    </span>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('bonuses', {
            header: () => <p>Bonuses</p>,
            cell: cell => {
                return (
                    <span>
                        {formatAmount(
                            calculateBonusAndDeductionSum(cell.renderValue(), 'amount', cell.row.original.gross_salary)
                        )}
                    </span>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('net_salary', {
            header: () => <p>Current payout</p>,
            cell: cell => {
                const calculateNetSalary =
                    Number(
                        cell.row.original?.gross_salary +
                            calculateBonusAndDeductionSum(
                                cell.row.original?.bonuses,
                                'amount',
                                cell.row.original.gross_salary
                            )
                    ) -
                    calculateBonusAndDeductionSum(
                        cell.row.original?.deductions,
                        'amount',
                        cell.row.original.gross_salary
                    )
                return <span>{formatAmount(calculateNetSalary)}</span>
            },
            enableSorting: true,
        }),

        columnHelper.accessor('eligible', {
            header: () => <p>Status</p>,
            cell: cell => (
                <>
                    <StatusColor status={cell.renderValue() ? 'ACTIVE' : 'INACTIVE'} />
                </>
            ),
            enableSorting: true,
        }),

        columnHelper.display({
            id: 'action',
            header: () => <p>Actions</p>,
            cell: cell => {
                const rowId = cell.row.original._id ?? cell.row.original.id
                const isSelected = selectedPayrollDetails.some(profile => (profile._id ?? profile?.id) === rowId)

                return (
                    <div className='flex items-center gap-3'>
                        <button
                            onClick={() => togglePayrollDetail(cell.row.original)}
                            aria-label='Icon'
                            className='transition-all active:scale-95'
                        >
                            {!isSelected ? (
                                <PlusSquare strokeWidth={1} size={25} />
                            ) : (
                                <MinusSquare strokeWidth={1} size={25} />
                            )}
                        </button>
                        <button aria-label='Icon' className='transition-all active:scale-95'>
                            <Edit strokeWidth={1} size={25} />
                        </button>
                    </div>
                )
            },
        }),
    ]

    return (
        <section className='h-full text-gray7 font-medium'>
            <Table
                data={payrolls}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                emptyStateDescription='Add users to your payroll list to run your first payroll'
                emptyStateTitle='Run your first payroll'
                emptyStateImg={PayrollEmptyState}
            />
        </section>
    )
}
