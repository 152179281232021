import { useRef, useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart'

type Props = {
    data: {
        title: string
        value: number
        color: string
    }[]
}
export const PieChartNew = ({ data }: Props) => {
    const [showOverlay, setShowOverlay] = useState(false)
    const modalRef = useRef<HTMLDivElement>(null)
    const [overlayPosition, setOverlayPosition] = useState({ x: 0, y: 0 })
    // const dataEntry = data.map(({ title, ...entry }) => {
    //     return {
    //         ...entry,
    //         tooltip: title,
    //     }
    // })
    return (
        <div className=''>
            <PieChart
                data={data}
                lineWidth={32}
                className='w-[350px] pt-6'
                // radius={90}
                animate
                animationDuration={500}
                animationEasing='ease-in'
                reveal={100}
                segmentsStyle={{ strokeDashoffset: '1px' }}
                onMouseOver={e => {
                    const _wid = window.innerWidth
                    const _hei = window.innerHeight
                    // const _mWid = modalRef?.current?.offsetWidth
                    // const _mHei = modalRef?.current?.offsetHeight
                    const _x = e.clientX
                    const _y = e.clientY
                    setOverlayPosition({ x: _x - _wid / 2, y: _y - _hei / 2 })
                    // setShowOverlay(true)
                }}
                onMouseOut={() => {
                    setShowOverlay(false)
                }}
            />
            {showOverlay && (
                <div
                    className='custom-tooltip m-0 p-3 bg-white border border-[#cccccc] whitespace-nowrap rounded-lg z-10'
                    style={{
                        position: 'absolute',
                        left: `${overlayPosition.x}px`,
                        top: `${overlayPosition.y / 2}px`,
                    }}
                    ref={modalRef}
                >
                    <p className='label'>ytdcdc</p>
                    <p className='label'>jjddc</p>
                </div>
            )}
        </div>
    )
}
