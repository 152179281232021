import { useNavigate, useParams } from 'react-router-dom'
import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import TransactionsHistory from './partials/TransactionsHistory'
import TransactionAnalytics from './partials/TransactionAnalytics'
import { motion } from 'framer-motion'
import { SwitchOrgInViewMode } from '@/components/SwitchOrgInView'
import { AccessPermissionHOC } from '@/hoc/AccessPermissionHOC'
import { ADMIN_TRANSACTION_PERMISSIONS } from '@/utils/permisson-helper'
import CurrencyFilter from '@/components/CurrencyFilter'
import { useCurrencyContext } from '@/context'

const transactions_routes_const = {
    history: 'transactions_history',
    analytics: 'transactions_analytics',
}
const Transactions = () => {
    const { transactions_subRoute } = useParams()
    const { selectedCurrencyInfo } = useCurrencyContext()

    const nairaCurrencyInfo = selectedCurrencyInfo?.()

    const navigate = useNavigate()
    const orgViewSubroute = location.pathname.includes('company') ? '/company' : '/personal'

    const showCurrencyFilter = location.pathname.includes(transactions_routes_const.analytics)

    return (
        <>
            <AccessPermissionHOC requiredPermissions={ADMIN_TRANSACTION_PERMISSIONS}>
                <section className='border-b border-gray4'>
                    <SwitchOrgInViewMode />
                </section>
            </AccessPermissionHOC>
            <section className='text-gray7 mt-6'>
                <Tabs defaultValue={transactions_subRoute} value={transactions_subRoute}>
                    <TabsList className='border-none '>
                        <div className='flex flex-col lg:flex-row justify-between mx-auto lg:items-center lg:w-[93%]'>
                            <TabTriggersContainer className='lg:w-full mx-0 ml-1'>
                                <TabsTrigger
                                    value={transactions_routes_const.history}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/transactions/${transactions_routes_const.history}${orgViewSubroute}`
                                        )
                                    }
                                    className={`relative border-none ${
                                        transactions_routes_const.history === transactions_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {transactions_routes_const.history === transactions_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        Transactions
                                    </motion.span>
                                </TabsTrigger>
                                <TabsTrigger
                                    value={transactions_routes_const.analytics}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/transactions/${transactions_routes_const.analytics}${orgViewSubroute}?currency=${nairaCurrencyInfo?.id}`
                                        )
                                    }
                                    className={`relative border-none ${
                                        transactions_routes_const.analytics === transactions_subRoute
                                            ? '!text-white'
                                            : ''
                                    } text-sm`}
                                >
                                    {transactions_routes_const.analytics === transactions_subRoute && (
                                        <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                    )}
                                    <motion.span layout='position' className='z-20 relative left-1.5 bottom-1.5'>
                                        Analytics
                                    </motion.span>
                                </TabsTrigger>
                            </TabTriggersContainer>
                            {showCurrencyFilter && <CurrencyFilter />}
                        </div>
                    </TabsList>
                    <TabsContent value={transactions_routes_const.history}>
                        <PageContent>
                            <TransactionsHistory />
                        </PageContent>
                    </TabsContent>
                    <TabsContent value={transactions_routes_const.analytics}>
                        <PageContent>
                            <TransactionAnalytics />
                        </PageContent>
                    </TabsContent>
                </Tabs>
            </section>
        </>
    )
}

export default Transactions
