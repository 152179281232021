import { Tabs, TabsList, TabsTrigger, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { useLocation, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

const routeHelper = {
    personal: 'personal',
    company: 'company',
}

type Props = {
    personalRoute: string
    companyRoute: string
}
export const NewViewSwitch = ({ companyRoute, personalRoute }: Props) => {
    const navigate = useNavigate()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)
    const paramDetails = queryParams.get('accountType')

    const isAdminAccess =
        paramDetails === routeHelper?.personal
            ? routeHelper?.personal
            : paramDetails === routeHelper?.company
              ? routeHelper?.company
              : routeHelper.personal

    const navigateToPath = (path: string) => {
        const params = new URLSearchParams(location.search)

        params.set('accountType', path)

        navigate(`${isAdminAccess === routeHelper.personal ? companyRoute : personalRoute}?${params.toString()}`)
    }

    return (
        <Tabs value={isAdminAccess}>
            <TabsList className=' flex items-center gap-6 lg:w-[93%] border-none '>
                <TabTriggersContainer className='mx-1 lg:mx-auto'>
                    <TabsTrigger
                        value={routeHelper.company}
                        id='company'
                        onClick={() => navigateToPath(routeHelper.company)}
                        className={`relative border-none text-xs whitespace-nowrap`}
                    >
                        {routeHelper.company === isAdminAccess && (
                            <motion.div
                                layoutId='org_view_tab'
                                className='absolute bottom-0 w-full h-0.5 text-xs bg-[#313131]'
                            />
                        )}
                        <motion.span layout='position' className='z-20 w-full'>
                            Company view
                        </motion.span>
                    </TabsTrigger>
                    <TabsTrigger
                        value={routeHelper.personal}
                        id='personal'
                        onClick={() => navigateToPath(routeHelper.personal)}
                        className={`relative border-none text-xs whitespace-nowrap`}
                    >
                        {routeHelper.personal === isAdminAccess && (
                            <motion.div
                                layoutId='org_view_tab'
                                className='absolute bottom-0 w-full h-0.5 text-xs bg-[#313131]'
                            />
                        )}
                        <motion.span layout='position' className='z-20 w-full'>
                            Personal view
                        </motion.span>
                    </TabsTrigger>
                </TabTriggersContainer>
            </TabsList>
        </Tabs>
    )
}
