import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useEffect, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Button } from '@/components/Button'
import SelectWIthChip from '@/components/SelectWithChip'
import { QueryOrgMembers } from '@/services/employees/types'
import { capitalizeText } from '@/utils/string'
import { useAuthContext } from '@/context'
import { useUpdateVaultItem } from '@/services/vault/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { VaultItem } from '@/services/vault/types'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    fileType?: string
    orgMembers: QueryOrgMembers
    file: VaultItem
}

export const ShareAccessModal = ({ closeModal, fileType, orgMembers, file }: Props) => {
    const [selectedMembers, setSelectedMembers] = useState<{ label: string; value: string }[]>()
    const [orgMembersData, setOrgMembersData] = useState<{ label: string; value: string }[]>([])
    const { selectedAccount } = useAuthContext()

    const [formState, setFormState] = useState({
        access_type: '',
    })
    const { mutate: shareFn, isLoading: isSharing } = useUpdateVaultItem({
        onSuccess: () => {
            toastHandler({ message: `${capitalizeText(fileType ? 'file' : 'folder')} shared successfully` })
            closeModal(false)
        },
    })
    const isDisabled =
        fileType === 'file'
            ? !selectedMembers || selectedMembers?.length === 0
            : !selectedMembers || selectedMembers?.length === 0 || formState.access_type.length === 0

    useEffect(() => {
        if (orgMembers) {
            setOrgMembersData(
                orgMembers.map(orgMembers => ({
                    label: `${capitalizeText(orgMembers?.user?.first_name)} ${capitalizeText(orgMembers?.user?.last_name)}`,
                    value: orgMembers?.id,
                }))
            )
        }
    }, [orgMembers])
    console.log(orgMembersData)

    return (
        <ModalWrapper variants={undefined}>
            <main className=' w-screen lg:w-[32rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>Share access - FE Contracts 2024</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='p-7 pt-5'>
                    <div className='flex flex-col flex-1 gap-3 mb-4'>
                        <label className='flex items-center gap-1'>
                            Share access with
                            <span className='text-errorRed'>*</span>
                        </label>
                        <div className=''>
                            <SelectWIthChip
                                options={orgMembersData?.filter(memb => memb.value !== selectedAccount?.id)}
                                placeholder='Select from the list of users'
                                onChange={members_details => {
                                    setSelectedMembers(members_details)
                                }}
                                value={selectedMembers}
                            />
                        </div>
                    </div>
                    {fileType === 'folder' && (
                        <div className={`flex flex-col flex-1`}>
                            <label className='mb-3' htmlFor='folder'>
                                Access type
                            </label>
                            <Select
                                name='folder'
                                onValueChange={value => {
                                    setFormState(prevState => ({
                                        ...prevState,
                                        access_type: value,
                                    }))
                                }}
                                value={formState.access_type}
                            >
                                <SelectTrigger className=' text-base  text-[#5E5E5E] border-gray3 flex text-left py-3'>
                                    {formState?.access_type ? <SelectValue /> : <span>Select the access type</span>}
                                </SelectTrigger>
                                <SelectContent className='max-h-[12rem] overflow-y-scroll w-[28.5rem]'>
                                    <SelectItem value={`view only`} className='pl-3'>
                                        <div className=''>
                                            <p className='text-sm font-semibold mb-2'>View only</p>
                                            <p className='text-xs text-gray7 font-medium'>
                                                This ensures they can view the folder's contents but are restricted from
                                                making any changes, such as creating, sharing, or deleting files.
                                            </p>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={`view and edit`} className='pl-3'>
                                        <div className=''>
                                            <p className='text-sm font-semibold mb-2'>View and edit</p>
                                            <p className='text-xs text-gray7 font-medium'>
                                                This allows them to view the folder's contents and make changes,
                                                including creating, sharing, and deleting files
                                            </p>
                                        </div>
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    )}
                </div>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        disabled={isDisabled || isSharing}
                        loading={isSharing}
                        onClick={() => {
                            shareFn({
                                shared_with: selectedMembers?.map(memb => memb.value),
                                id: file.id,
                            })
                        }}
                    >
                        Share {fileType === 'folder' ? 'folder' : 'file'} access
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
