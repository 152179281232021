import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useUpdateProfile } from '@/services/auth/mutations'
import { User } from '@/types/user'
import { isAfter } from 'date-fns'

import { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'

type Props = {
    setCloseEditProfile: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UserProfileEdit({ setCloseEditProfile }: Props) {
    const { selectedAccount } = useAuthContext()
    const [editProfileDetails, setEditProfileDetails] = useState(selectedAccount!.user)
    const dateOfBirth = useRef<DatePicker>(null)

    const { first_name, email, last_name, address, phone, postal_code, date_of_birth, country, city, state } =
        editProfileDetails!

    const profile = {
        first_name: { name: first_name, title: 'First name' },
        last_name: { name: last_name, title: 'Last name' },
        email: { name: email, title: 'Email address' },
        phone_number: { name: phone ?? 'N/A', title: 'Phone number' },
        address: { name: address ?? 'N/A', title: 'Address line' },
        city: { name: city ?? 'N/A', title: 'City' },
        state: { name: state ?? 'N/A', title: 'State' },
        country: { name: country ?? 'N/A', title: 'Country' },
        postal_code: { name: postal_code ?? 'N/A', title: 'Postal code' },
        date_of_birth: { name: date_of_birth ?? 'N/A', title: 'Date of birth' },
    }
    const { mutate: updateProfileFn, isLoading } = useUpdateProfile({
        onSuccess: () => {
            setCloseEditProfile(false)
            toastHandler({ message: 'Profile details updated', state: 'success' })
        },
    })
    const [errInDate, setErrInDate] = useState(false)

    const handleDOB = (date: Date) => {
        // const today = new Date()
        // const selectedDate = new Date(date)
        // today.setHours(0, 0, 0, 0)
        // selectedDate.setHours(0, 0, 0, 0)
        const result = isAfter(new Date(date), new Date())
        if (result) {
            setErrInDate(true)
            return
        }
        setErrInDate(false)
        setEditProfileDetails(prev => ({
            ...prev,
            date_of_birth: date! as unknown as string,
        }))
    }

    const handleEditPersonalInfo = () => {
        updateProfileFn({
            first_name,
            last_name,
            phone,
            address,
            city,
            state,
            country,
            postal_code,
            date_of_birth,
        })
    }

    return (
        <section>
            <div className='flex flex-wrap gap-x-[2rem] gap-5 mt-6'>
                {Object.keys(profile).map(profile_title => (
                    <div className='flex flex-col'>
                        <label htmlFor={profile_title} className='text-[#838383] font-normal text-md mb-3'>
                            {profile[profile_title as keyof typeof profile].title}
                        </label>
                        <div className='flex gap-2'>
                            {profile_title === 'phone_number' && (
                                <Select name='phone_num' value='nigeria_code'>
                                    <SelectTrigger className='h-[2.5rem] border-[#DADCE0] bg-base_gray_100 text-[#202020] text-[14px] w-[6rem] font-medium'>
                                        <SelectValue placeholder={'Call Code'} className='' />
                                    </SelectTrigger>
                                    <SelectContent className='w-[7rem]'>
                                        <SelectItem value={'nigeria_code'} className='cursor-pointer'>
                                            +234
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            )}

                            {profile_title !== 'date_of_birth' && (
                                <Input
                                    id={profile_title}
                                    name={profile_title}
                                    type='text'
                                    disabled={profile_title === 'email'}
                                    className='h-[2.5rem] font-medium disabled:text-[#DADCE0] disabled:bg-[#F9F9F9] border-[#DADCE0]'
                                    value={editProfileDetails[profile_title as keyof User] as string}
                                    onChange={e =>
                                        setEditProfileDetails(prev => ({
                                            ...prev,
                                            [profile_title]: e.target.value,
                                        }))
                                    }
                                />
                            )}
                            {profile_title === 'date_of_birth' && (
                                <label
                                    className={`relative cursor-pointer flex flex-col bg-transparent `}
                                    htmlFor='dob'
                                >
                                    {/* <DatePicker
                                        selected={
                                            editProfileDetails?.date_of_birth
                                                ? new Date(editProfileDetails?.date_of_birth as string)
                                                : null
                                        }
                                        onChange={date => handleDOB(date as Date)}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode='select'
                                        placeholderText='type DOB here'
                                        className='border-[#DADCE0]  placeholder:text-[#A8A8A8] h-[2.5rem] text-dark rounded-md border focus:border-brand bg-transparent py-2 px-3 text-sm  cursor-pointer w-full !pl-8'
                                        showIcon={true}
                                    /> */}
                                    <CustomDatePicker
                                        // {...field}
                                        ref={dateOfBirth}
                                        placeholderText='type DOB here'
                                        onChange={date => handleDOB(date as Date)}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode='select'
                                        selected={
                                            editProfileDetails?.date_of_birth
                                                ? new Date(editProfileDetails?.date_of_birth as string)
                                                : null
                                        }
                                        wrapperClassName='h-[2.5rem]'
                                        // value={field.value as unknown as string}
                                    />
                                    {errInDate && (
                                        <p className='text-[red] text-xs block relative mt-1'>
                                            Date cannot be past today
                                        </p>
                                    )}
                                </label>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className='ml-auto mt-8 flex gap-3 justify-end'>
                <Button
                    className='bg-transparent hover:bg-transparent text-[#202020]'
                    onClick={() => setCloseEditProfile(false)}
                >
                    cancel
                </Button>
                <Button className='' onClick={handleEditPersonalInfo} loading={isLoading}>
                    Save changes
                </Button>
            </div>
        </section>
    )
}
