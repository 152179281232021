import PageContent from '@/layout/PageContent'
import { Tabs, TabsContent, TabsList, TabTriggersContainer, TabsTrigger } from '@/components/Tabs/Tabs'
import { SettingsContextProvider } from '@/context/settings'
import CompanySettings from './partials/CompanySettings/CompanySettings'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import UserSettings from '../UserSettings/UserSettings'
import { useAuthContext } from '@/context'
import { isAdmin } from '@/utils/permissions'

const settings_routes_const = {
    company: 'company',
    user: 'user',
}

const Settings = () => {
    const { settings_subRoute } = useParams()
    const navigate = useNavigate()
    const { selectedAccount } = useAuthContext()
    const hasAdminPermission = isAdmin(selectedAccount!)

    return (
        <SettingsContextProvider>
            <section className=' h-full'>
                {hasAdminPermission && (
                    <Tabs defaultValue={settings_subRoute} value={settings_subRoute}>
                        <TabsList>
                            <TabTriggersContainer className='gap-3 pb-1'>
                                <TabsTrigger
                                    value={settings_routes_const.company}
                                    onClick={() => navigate(`/dashboard/settings/${settings_routes_const.company}`)}
                                    className={`relative border-none p-2 px-4 ${
                                        settings_routes_const.company === settings_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {settings_routes_const.company === settings_subRoute && (
                                        <motion.div
                                            layoutId='selected_btn'
                                            className='bg-tabBg rounded !text-white absolute inset-0 w-full p-2 h-full text-xs'
                                        />
                                    )}
                                    <motion.span layout='position' className='z-20 relative'>
                                        Company
                                    </motion.span>
                                </TabsTrigger>
                                <TabsTrigger
                                    value={settings_routes_const.user}
                                    onClick={() => navigate(`/dashboard/settings/${settings_routes_const.user}`)}
                                    className={`relative border-none p-2 px-4 ${
                                        settings_routes_const.user === settings_subRoute ? '!text-white' : ''
                                    } text-sm`}
                                >
                                    {settings_routes_const.user === settings_subRoute && (
                                        <motion.div
                                            layoutId='selected_btn'
                                            className='bg-tabBg rounded !text-white absolute inset-0 w-full p-1 h-full text-xs'
                                        />
                                    )}
                                    <motion.span layout='position' className='z-20 relative'>
                                        Personal
                                    </motion.span>
                                </TabsTrigger>
                            </TabTriggersContainer>
                        </TabsList>
                        <TabsContent value={settings_routes_const.company}>
                            <PageContent>
                                <>
                                    <CompanySettings />
                                </>
                            </PageContent>
                        </TabsContent>
                        <TabsContent value={settings_routes_const.user}>
                            <PageContent>
                                <UserSettings />
                            </PageContent>
                        </TabsContent>
                    </Tabs>
                )}

                {!hasAdminPermission && (
                    <PageContent>
                        <UserSettings />
                    </PageContent>
                )}
            </section>
        </SettingsContextProvider>
    )
}

export default Settings
