import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useState } from 'react'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { toastHandler } from '@/components/utils/Toast'
import { useCreateVault, useUpdateVault } from '@/services/vault/mutations'
import { useAuthContext } from '@/context'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    setShowVaultPassword: React.Dispatch<React.SetStateAction<boolean>>
    setVaultId: React.Dispatch<React.SetStateAction<string>>
    rename?: boolean
}

export const CreateVault = ({ closeModal, rename = false, setShowVaultPassword, setVaultId }: Props) => {
    const [formState, setFormState] = useState({
        is_private: false,
        name: '',
    })
    const allow = [formState.name].every(entry => entry.length > 0)
    const { selectedAccount } = useAuthContext()

    const { mutate: updateFn, isLoading: isUpdating } = useUpdateVault({
        onSuccess: () => {
            closeModal(false)
        },
    })

    const { mutate: createFn, isLoading: isCreating } = useCreateVault({
        queryParams: {
            owner: selectedAccount?.user.id as string,
            org: selectedAccount?.org.id as string,
        },
        onSuccess: data => {
            toastHandler({ message: `Vault activated successfully`, state: 'success' })
            setVaultId(data?.id)
            setShowVaultPassword(true)
            // closeModal(false)
        },
    })

    const handleButton = () => {
        if (rename) {
            updateFn({
                name: formState.name,
                id: '',
            })
            return
        }

        if (!rename) {
            createFn(formState)
        }
    }
    return (
        <ModalWrapper variants={undefined}>
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>{rename ? 'Rename' : 'Create'} Vault</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='p-7 pt-5 overflow-y-scroll'>
                    <div className={`flex flex-col flex-1 gap-3 mt-2`}>
                        <p className='w-full text-right -mb-10 text-sm text-gray7'>
                            {formState.name.length}/50 characters
                        </p>
                        <label className='flex items-center gap-1'>
                            Vault name
                            <span className='text-[red]'>*</span>
                        </label>
                        <Input
                            type='text'
                            placeholder={`Enter name of Vault`}
                            name='name'
                            value={formState.name}
                            onChange={e => {
                                if (e.target.value.length === 51) return
                                setFormState(prev => ({ ...prev, name: e.target.value }))
                            }}
                            className='border-[#DADCE0]  h-[3rem]'
                        />
                    </div>
                    <div className={`flex gap-2 item-center text-[#454ADE] mt-3`}>
                        <input
                            type='checkbox'
                            name='acct_name'
                            id='acct_name'
                            className='accent-[#454ADE] cursor-pointer'
                            onChange={e => setFormState(prev => ({ ...prev, is_private: e.target.checked }))}
                            checked={formState.is_private}
                        />
                        <label htmlFor='acct_name' className='text-sm font-medium cursor-pointer'>
                            Set vault as private
                        </label>
                    </div>
                </div>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        disabled={rename ? formState.name.length <= 0 || isUpdating : !allow || isCreating}
                        onClick={handleButton}
                        loading={isCreating}
                    >
                        {rename ? 'Update' : 'Create'} Vault
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
