export enum Currency_Code {
    NGN = 'NGN',
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
}

export const CurrencySymbols: Record<Currency_Code, string> = {
    [Currency_Code.NGN]: '₦',
    [Currency_Code.USD]: '$',
    [Currency_Code.EUR]: '€',
    [Currency_Code.GBP]: '£',
} as const

export const CurrencyNames: Record<Currency_Code, string> = {
    [Currency_Code.NGN]: 'Naira',
    [Currency_Code.USD]: 'Dollar',
    [Currency_Code.EUR]: 'Euro',
    [Currency_Code.GBP]: 'Pound',
} as const

export type CurrencyType = {
    flag: string
    createdAt: string
    currency_code: Currency_Code
    id: string
    _id: string
    name: (typeof CurrencyNames)[Currency_Code]
    symbol: (typeof CurrencySymbols)[Currency_Code]
    updatedAt: string
}
