import { ReactNode, createContext, useMemo } from 'react'
import { Category } from '@/types/expense'
import { useGetOrgCategories } from '@/services/categories/queries'

export type CategoriesDefaultType = {
    categories: Category[]
}

const defaultValues: CategoriesDefaultType = {
    categories: [],
}

export const CategoriesContext = createContext(defaultValues)

export default function CategoriesContextProvider({ children }: { children: ReactNode }) {
    const { data } = useGetOrgCategories()
    const categories = useMemo(() => (data as Category[]) ?? [], [data])

    return <CategoriesContext.Provider value={{ categories }}>{children}</CategoriesContext.Provider>
}
