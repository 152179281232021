import { Button } from '@/components'
import EmptyStates from '@/components/EmptyStates'
import { useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import emptyTeams from '@/assets/empty-teams-state.svg'
import notFound from '@/assets/not-found.svg'
import EmployeeTeamsTable from '../../components/EmployeeTeamsTable'
import CreateTeamModal from '@/components/Modals/Employee/CreateTeamModal/CreateTeamModal'
import ProgressBar from '@/components/ProgressBar'
import { useEmployeesContext } from '@/context'

type Props = {
    onKeyDown: (e: any) => void
    isLoading: boolean
}

export default function EmployeeTeams({ onKeyDown, isLoading }: Props) {
    const [searchedInput, setSearchedInput] = useState<string | undefined>()
    const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false)

    const { orgMembers, totalTeams, setFilterTeamsBy } = useEmployeesContext()

    useEffect(() => {
        setFilterTeamsBy(prev => ({ ...prev, bySearch: searchedInput }))
    }, [searchedInput])

    if (!totalTeams) return <ProgressBar />

    return (
        <main className=''>
            <div className='block lg:flex justify-between mb-6 lg:mb-0'>
                <div className='mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Teams</h1>
                    <p className='text-gray7 text-sm'>Create and manage teams in your organization.</p>
                </div>
                <Button
                    className='min-h-[45px] w-full lg:w-auto lg:min-w-[120px] text-sm'
                    onClick={() => setIsCreateTeamModalOpen(true)}
                >
                    Create team
                </Button>
            </div>
            <section className='bg-white rounded-2xl p-4  '>
                <div className='border border-[#DADCE0] h-[45px] w-[70%] lg:w-[30%] rounded-lg flex items-center overflow-hidden'>
                    <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                        <CiSearch fontSize={25} />
                    </div>
                    <input
                        type='text'
                        placeholder='Search teams'
                        className='flex-1 outline-none placeholder:text-xs'
                        onChange={e => setSearchedInput(e.target.value)}
                        onKeyDown={onKeyDown}
                    />
                </div>

                {totalTeams && totalTeams?.length > 0 ? (
                    <section className={`mt-5 overflow-x-scroll ${isLoading ? 'opacity-30' : 'opacity-100'}`}>
                        <EmployeeTeamsTable teams={totalTeams} />
                    </section>
                ) : searchedInput !== undefined && searchedInput?.length >= 0 ? (
                    <div className='flex flex-col w-[30rem] mx-auto items-center mt-5'>
                        <img src={notFound} alt='Not found' className='mt-6 h-[270px]' />
                        <span className='text-3xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                        <span className='text-[#202020] text-[16px] w-full mt-3 font-medium text-center'>
                            Oops! It seems we couldn't find any matching results for your search. Please try again with
                            different keywords or refine your filter criteria for better results.
                        </span>
                    </div>
                ) : (
                    <section className='grid place-items-center '>
                        <EmptyStates
                            title='No Teams yet'
                            description='No team has been created yet'
                            image={emptyTeams}
                        />
                    </section>
                )}

                {isCreateTeamModalOpen && (
                    <CreateTeamModal closeModal={setIsCreateTeamModalOpen} members={orgMembers} />
                )}
            </section>
        </main>
    )
}
