// import CheckBox from '@/components/Table/CheckBox'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import Table from '@/components/Table'
import InvoiceEmptyState from '@/assets/invoice-empty-state.svg'

type OfficersTableProps = {
    data: any[]
}
const OfficersTable = ({ data }: OfficersTableProps) => {
    const columnHelper = createColumnHelper<any>()

    const columns: ColumnDef<any, any>[] = [
        columnHelper.accessor('role', {
            header: () => <p>Role</p>,
            cell: cell => {
                return <>{cell.renderValue() ?? 'N/A'}</>
            },
            enableSorting: true,
        }),
        columnHelper.accessor('fullName', {
            header: () => <p>Name</p>,
            cell: cell => {
                return `${cell.renderValue()?.firstName} ${cell.renderValue()?.lastName}`
            },
            enableSorting: true,
        }),
        columnHelper.accessor('title', {
            header: () => <p>Title</p>,
            cell: cell => <span>{cell.renderValue()}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('email', {
            header: () => <p>Email</p>,
            cell: cell => {
                return <>{cell.renderValue() ?? 'N/A'}</>
            },
            enableSorting: true,
        }),
        columnHelper.accessor('percentageOwned', {
            header: () => <p>Percentage Owned</p>,
            cell: cell => {
                return <>{cell.renderValue() ?? 'N/A'}</>
            },
            enableSorting: true,
        }),
    ]

    return (
        <section className='h-full'>
            <Table
                data={data ?? []}
                columns={columns}
                rowClickable={true}
                emptyStateDescription=''
                emptyStateTitle=''
                emptyStateImg={InvoiceEmptyState}
            />
        </section>
    )
}

export default OfficersTable
