import React from 'react'
import ModalWrapper from '../../ModalWrapper'
import { BlueWarning } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const NoPolicyWarning = ({ setShowModal }: Props) => {
    const navigate = useNavigate()
    return (
        <ModalWrapper shouldConfirmClose closeModal={() => setShowModal(false)}>
            <div className='w-screen lg:w-[25rem] p-6 flex items-center justify-center flex-col'>
                <div className='p-3.5 rounded-full bg-[#EBF5FF]'>
                    <BlueWarning />
                </div>
                <p className='mt-8 text-center font-medium'>
                    Set your expense approval policy before reporting any expenses. It’s quick and easy!
                </p>
                <div className='mt-16 flex gap-4 items-center'>
                    <Button
                        onClick={() => {
                            navigate('/dashboard/expenses/settings?from=noPolicy')
                        }}
                        className='rounded-lg bg-black hover:bg-black'
                    >
                        Set it up now
                    </Button>
                    <Button
                        className='bg-white text-xs px-0 border border-[#838383] text-gray7 hover:bg-white rounded-lg'
                        onClick={() => setShowModal(false)}
                    >
                        Do it later
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
