import PayrollEmptyState from '@/assets/payroll-empty-state.svg'
import AddToPayrollModal from '@/components/Modals/Payroll/AddToPayroll/AddToPayrollModal'
import { PayrollProcessRoot } from '@/components/Modals/Payroll/PayrollProcess/PayrollProcessRoot'
import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import StatusColor from '@/components/utils/StatusColor'
import { useAuthContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetPayrollSettingsForOrg } from '@/services/payroll/queries'
import { PayrollListType } from '@/services/payroll/types'
import { PayFrequency, PaymentProfile } from '@/types/payroll'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import React, { useMemo, useState } from 'react'

type Props = {
    payrolls: PayrollListType['list']
}

export default function RunPayrollListTable({ payrolls }: Props) {
    const columnHelper = createColumnHelper<PayrollListType['list'][0]>()
    const [rowSelection, setRowSelection] = React.useState({})
    const { selectedAccount } = useAuthContext()

    const [openModal, setOpenModal] = useState(false)
    const [openSinglePayModal, setOpenSinglePayModal] = useState(false)
    const [editPayroll, setEditPayroll] = useState(false)
    const [payrollDetails, setPayrollDetails] = useState<PayrollListType['list'][0] | undefined>()
    const [selectedPayrollDeets, setSelectedPayrollDeets] = useState<Partial<PaymentProfile>>()

    const { data: orgPayrollSettings } = useGetPayrollSettingsForOrg({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })
    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const orgMembers = useMemo(() => org_members ?? [], [org_members])

    const columns: ColumnDef<PayrollListType['list'][0], any>[] = [
        columnHelper.accessor('account.user', {
            header: () => <>Users</>,
            cell: cell => {
                return (
                    <>
                        {capitalizeText(cell?.renderValue()?.first_name as string)}{' '}
                        {capitalizeText(cell?.renderValue()?.last_name[0] as string)}.
                    </>
                )
            },
            enableSorting: true,
        }),

        columnHelper.accessor('current_period_start', {
            header: () => <p>Pay period</p>,
            cell: cell => (
                <div>
                    <p>
                        {moment(cell.row.original.current_period_start).format('DD/MM/YYYY')} -{' '}
                        {moment(cell.row.original.current_period_end).format('DD/MM/YYYY')}
                    </p>
                    <p className='font-normal'>
                        worked {cell.row.original.days_worked} day{cell.row.original.days_worked > 1 && 's'}
                    </p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('gross_salary', {
            header: () => <p>Gross salary</p>,
            cell: cell => (
                <div>
                    <p> {formatAmount(cell.renderValue() ?? cell.row.original?.gross_salary)}</p>
                    <p className='font-normal'>{capitalizeText(cell.row.original.pay_frequency)}</p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('computed_deduction', {
            header: () => <p>Deductions</p>,
            cell: cell => {
                return <span>{formatAmount(cell.renderValue())}</span>
            },
            enableSorting: true,
        }),
        columnHelper.accessor('computed_bonus', {
            header: () => <p>Bonuses</p>,
            cell: cell => {
                return <span>{formatAmount(cell.renderValue())}</span>
            },
            enableSorting: true,
        }),
        columnHelper.accessor('net_salary', {
            header: () => <p>Net Salary</p>,
            cell: cell => formatAmount(cell.renderValue()),
            enableSorting: true,
        }),
        columnHelper.accessor('current_payout', {
            header: () => <p>Current payout</p>,
            cell: cell => formatAmount(cell.renderValue()),
            enableSorting: true,
        }),
        columnHelper.accessor('due_status', {
            header: () => <p>Status</p>,
            cell: cell => (
                <>
                    <StatusColor status={cell.renderValue()} />
                </>
            ),
            enableSorting: true,
        }),

        columnHelper.display({
            id: 'actions',
            cell: cell => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'Pay this user',
                                method: () => {
                                    setPayrollDetails(cell?.row?.original)
                                    setOpenSinglePayModal(true)
                                },
                            },
                            {
                                label: 'Edit details',
                                method: () => {
                                    handleRowClick(cell.row.original)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]
    const handleRowClick = (data: PayrollListType['list'][0]) => {
        setPayrollDetails(data)

        setSelectedPayrollDeets({
            employee: data?.payment_profile.employee,
            status: data?.payment_profile.status,
            gross_salary: data?.gross_salary as unknown as string,
            net_salary: data?.net_salary,
            pay_frequency: data?.pay_frequency as PayFrequency,
            currency: data?.currency,
            pay_date: data?.payment_profile.pay_date,
            pay_day: data?.payment_profile.pay_day,
            days_worked: data?.days_worked as unknown as string,
            start_date: data?.current_period_start,
            prorate: data?.prorated,
            current_payout: data?.current_payout as unknown as string,
            computed_bonus: data?.computed_bonus,
            computed_deduction: data?.computed_deduction,
            account: data?.payment_profile.account,
            _id: data?.payment_profile._id,
            employment_type: data?.payment_profile.employment_type,
            bonuses: data?.bonuses,
            deductions: data?.deductions,
            other_deductions: data?.payment_profile.other_deductions,
            id: data?.id,
            payrolls: data?.payment_profile.payrolls,
            createdAt: '',
            updatedAt: '',
        })
        setOpenModal(true)
        setEditPayroll(true)
    }

    return (
        <section className='h-full text-gray7 font-medium'>
            <Table
                data={payrolls}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                emptyStateDescription='Add users to your payroll list to run your first payroll'
                emptyStateTitle='Run your first payroll'
                emptyStateImg={PayrollEmptyState}
            />
            {openModal && (
                <AddToPayrollModal
                    orgPayrollSettings={orgPayrollSettings}
                    closeModal={setOpenModal}
                    setEditPayRoll={setEditPayroll}
                    orgMembers={orgMembers}
                    payrollDetails={selectedPayrollDeets as PaymentProfile}
                    editPayroll={editPayroll}
                    editType='payout'
                    userPayrollId={payrollDetails?.id as string}
                />
            )}
            {openSinglePayModal && (
                <PayrollProcessRoot closeModal={setOpenSinglePayModal} payrollDetails={payrollDetails!} single={true} />
            )}
        </section>
    )
}
