import { ArrowLeft } from 'lucide-react'
import React from 'react'
import downArrow from '@/assets/down-arrow.svg'
import { Button } from '@/components'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function CSVTransfer1({ setStep, closeModal }: Props) {
    return (
        <div className='w-[30rem]'>
            <div className='flex gap-1 my-6 cursor-pointer mb-12' onClick={() => closeModal(false)}>
                <ArrowLeft />
                back
            </div>
            <div className='h-[70vh] overflow-scroll bg-white'>
                <h4 className='font-medium text-lg opacity-80 mb-5'>
                    Step 2: Download the bulk transfer csv template{' '}
                </h4>
                <p className='text-[#5e5e5e] text-base font-medium mb-8'>
                    Follow the steps below to effortlessly transfer money to more than 5 recipients at once.
                </p>
                <ul className='mt-4 text-[#5e5e5e] flex flex-col gap-5'>
                    <li className='flex gap-2'>
                        <span className='mr-2'>1.</span>
                        <span>
                            Click on the <span className='text-[#454ADE] font-medium'>"Download CSV Template"</span>{' '}
                            button below to obtain the pre-formatted CSV file.
                            <span className='font-medium text-[black]'>
                                {' '}
                                Note that this file is already populated with the users you selected
                            </span>
                        </span>
                    </li>
                    <li className='flex gap-2'>
                        <span className='mr-2'>2.</span>
                        Save the template to your preferred location on your computer, ensuring easy access when you're
                        ready to upload the completed file.
                    </li>
                    <li className='flex gap-2'>
                        <span className='mr-2'>3.</span>
                        Open and populate the downloaded template with the transfer amounts for each selected
                        recipients, using a spreadsheet software like Microsoft Excel or Google Sheets.
                    </li>
                    <li className='flex gap-2 mt-8'>
                        {' '}
                        <a
                            className='cursor-pointer w-max border border-brand text-brand py-3 px-5 text-lg font-medium flex items-center gap-3 rounded-lg mx-auto mb-10 hover:scale-95 transition-all duration-150 active:scale-100'
                            href='/payroll_template.xlsx'
                            download='Template.xlsx'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Download CSV Template
                            <img src={downArrow} alt='button' />
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <Button
                    className='w-full'
                    onClick={() => {
                        setStep(2)
                    }}
                >
                    Next
                </Button>
            </div>
        </div>
    )
}
