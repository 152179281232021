import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import ModalWrapper from '../ModalWrapper'
import { useEmployeesContext } from '@/context'
import { TeamMembersProp } from '@/types/employee'
import { useState } from 'react'
import { useMutateMakeTeamLead } from '@/services/employees/mutations'
import { toastHandler } from '@/components/utils/Toast'

interface TeamMemberRemovalProps {
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    userToDelete: TeamMembersProp
    deleteMemberFn: any
    loading: boolean
}

export default function TeamMemberPromptRemoval({
    setIsDeleteDialogOpen,
    userToDelete,
    deleteMemberFn,
    loading,
}: TeamMemberRemovalProps) {
    const { teamsObj } = useEmployeesContext()

    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>
                    Are you sure you want to remove{' '}
                    <span className='text-[#454ADE]'>
                        {userToDelete?.account?.user?.first_name} {userToDelete?.account?.user?.last_name}
                    </span>{' '}
                    {userToDelete.isTeamLead ? (
                        <>
                            who is the <span className='font-semibold'>team lead </span>
                        </>
                    ) : (
                        ''
                    )}
                    from the <span className='text-[#454ADE]'>{teamsObj?.name}</span> team.
                    {userToDelete.isTeamLead && (
                        <p>If yes, you'll be prompted to reassign the team lead to another member</p>
                    )}
                </p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={loading}
                        onClick={() => {
                            deleteMemberFn({})
                        }}
                    >
                        Yes, remove this user
                    </Button>
                    <button
                        type='button'
                        className='min-h-[50px] px-5 mx-3'
                        onClick={() => setIsDeleteDialogOpen(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </ModalWrapper>
    )
}

export const TeamLeadSelectBox = ({ closeModal }: { closeModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { teamsObj } = useEmployeesContext()
    const [teamLead, setTeamLead] = useState('')
    const { mutate: makeTeamLeadFn, isLoading } = useMutateMakeTeamLead({
        queryParams: {
            team_id: teamsObj?.id as string,
            account: teamLead,
        },
        onSuccess: () => {
            closeModal(false)
            toastHandler({ message: 'Changes made', state: 'success' })
        },
    })
    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <div className='flex flex-col flex-1 gap-3 '>
                    <label className='flex items-center gap-1'>
                        Select a new team lead
                        <span className='text-errorRed'>*</span>
                    </label>
                    <div className=''>
                        <Select name='team_lead' value={teamLead} onValueChange={teamLead => setTeamLead(teamLead)}>
                            <SelectTrigger
                                className='w-full h-[4rem] border-[#DADCE0]'
                                variant={teamLead ? 'selected' : 'default'}
                            >
                                <SelectValue placeholder='Select expense category' />
                            </SelectTrigger>
                            <SelectContent className='max-h-[200px] overflow-scroll w-[29.7rem]'>
                                {teamsObj && teamsObj?.users?.length > 0 ? (
                                    teamsObj?.users?.map(memb => (
                                        <SelectItem key={memb.id} value={memb?.account?.id}>
                                            {memb?.account?.user?.first_name} {memb?.account?.user?.last_name}
                                        </SelectItem>
                                    ))
                                ) : (
                                    <>
                                        <SelectItem value=''>No team member selected yet</SelectItem>
                                    </>
                                )}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className=''>
                    <Button
                        className='min-w-[100px] mt-10 ml-auto'
                        onClick={() => makeTeamLeadFn({})}
                        loading={isLoading}
                        disabled={teamLead.length < 1}
                    >
                        Make team lead
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
